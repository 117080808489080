import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';

export type PageVisibilityState = 'visible' | 'hidden';

@Injectable({
  providedIn: 'root',
})
export class PageVisibilityStateService implements OnDestroy {
  private _subscription: Subscription;

  private _state$ = new Subject<PageVisibilityState>();
  public state$ = this._state$.asObservable();

  constructor() {
    this._subscription = fromEvent(document, 'visibilitychange').subscribe(
      () => {
        this._state$.next(document.visibilityState);
      }
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
