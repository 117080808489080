/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonBaseComponent,
  SUI_BUTTON_PREFIX,
} from '../button-base/box-base.component';

@Component({
  selector: `button[sui-box-button]`,
  templateUrl: './box-button.component.html',
  styleUrls: ['./box-button.component.scss'],
  host: {
    class: SUI_BUTTON_PREFIX,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxButtonComponent extends ButtonBaseComponent {}
