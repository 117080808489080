import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Address, BookType, createBukURL, PagingMode } from '@bukio/viewer';

import { EventBusService } from '../../services/event-bus.service';
import { Book } from '../../models/book.model';

interface PageInfo {
  bid: string;
  iid: string;
  page: number;
  pages: number;
}

@Component({
  selector: 'viewer-page-number',
  templateUrl: './page-number.component.html',
  styleUrls: ['./page-number.component.scss'],
})
export class PageNumberComponent implements OnInit, OnDestroy {
  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  @HostBinding('class.no-number') noNumber = false;
  @HostBinding('class.disabled') disabled = false;
  @HostBinding('class.hidden') hidden = false;

  public currentPage?: number;
  public totalPages?: number;

  private jumped = false;
  public jumpedFrom?: PageInfo;
  public pagingFromJumped = 0;

  public pagingMode?: string;
  public isMultiColumnViewer?: boolean;

  private address?: Address;
  private book?: Book;
  private isFixedLayoutBook = false;
  private previousPage?: PageInfo;

  constructor(private eventBusService: EventBusService) {}

  ngOnInit(): void {
    this.eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe((data) => {
        const pagingMode =
          data.settings.pagingMode.value === PagingMode.Scroll
            ? 'scroll'
            : 'page';
        if (this.pagingMode !== pagingMode) {
          this.pagingMode = pagingMode;
          this.disabled = this.pagingMode !== 'page';
          this.resetPageJump();
        }

        this.isMultiColumnViewer = data.settings.multiColumn.value;
      });

    this.eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        filter(() => {
          return this.pagingMode === 'page';
        })
      )
      .subscribe((data) => {
        this.currentPage = data.page;
        this.totalPages = data.pageCount;
        this.noNumber = this.totalPages <= 1;
        if (this.book?.meta.type !== BookType.PDF) {
          this.checkPageJump();
        }
      });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ book }) => {
        this.book = book;
        this.isFixedLayoutBook = book.items.every(
          (item) => item.layout === 'fixed'
        );
        this.resetPageJump();
      });

    this.eventBusService
      .on('LangaugeSelectorComponent:change')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this.resetPageJump();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  checkPageJump(): void {
    if (!this.address || !this.book) {
      return;
    }

    const currentPage = {
      bid: this.address.bid,
      iid: this.address.iid ?? this.book.items[0].iid,
      page: this.currentPage!,
      pages: this.totalPages!,
    };

    const jumpGap = this.isMultiColumnViewer && this.isFixedLayoutBook ? 2 : 1;

    if (this.jumped) {
      this.jumped = false;
    } else if (this.previousPage) {
      if (this.previousPage.bid !== currentPage.bid) {
        this.setJumpedFrom();
      } else if (this.previousPage.iid !== currentPage.iid) {
        const cIndex = this.book.getIndexOfItem(currentPage.iid);
        const pIndex = this.book.getIndexOfItem(this.previousPage.iid);

        if (cIndex < 0 || pIndex < 0) {
          this.setJumpedFrom();
        } else if (Math.abs(pIndex - cIndex) <= jumpGap) {
          if (
            (pIndex < cIndex &&
              this.previousPage.page === this.previousPage.pages &&
              currentPage.page === 1) ||
            (pIndex > cIndex &&
              this.previousPage.page === 1 &&
              currentPage.page === currentPage.pages)
          ) {
            this.countNormalPaging(pIndex < cIndex);
          } else {
            this.setJumpedFrom(this.previousPage);
          }
        } else {
          this.setJumpedFrom(this.previousPage);
        }
      } else if (this.previousPage.page !== currentPage.page) {
        if (Math.abs(this.previousPage.page - currentPage.page) > 1) {
          this.setJumpedFrom(this.previousPage);
        } else {
          this.countNormalPaging(this.previousPage.page < currentPage.page);
        }
      }
    }

    this.previousPage = currentPage;
  }

  setJumpedFrom(data?: PageInfo): void {
    this.jumpedFrom = data;
    this.pagingFromJumped = 0;
  }

  countNormalPaging(toRight: boolean): void {
    if (
      Math.abs(toRight ? ++this.pagingFromJumped : --this.pagingFromJumped) >= 5
    ) {
      this.setJumpedFrom();
    }
  }

  jumpToPreviousPage(): void {
    if (!this.jumpedFrom) {
      return;
    }

    this.jumped = true;

    this.eventBusService.fire('PageNumberComponent:backButtonClick', {
      url: createBukURL(
        this.jumpedFrom.bid,
        this.jumpedFrom.iid,
        (this.jumpedFrom.page - 1) / this.jumpedFrom.pages
      ),
    });

    this.setJumpedFrom();
  }

  resetPageJump(): void {
    this.setJumpedFrom();
    this.previousPage = undefined;
    this.jumped = false;
  }
}
