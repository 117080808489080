import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, switchMap } from 'rxjs';
import * as Url from 'url-parse';

import { BookshelfAPIService } from 'shared/services';
import { AlertService, DialogService } from 'shared/ui';
import { Bookshelf, BookshelfBook } from 'shared/models';

import { ReadingGroupDialogService } from 'reading-group';
import { AddBookComponent } from '../../components/add-book/add-book.component';
import { EditBookshelfComponent } from '../../components/edit-bookshelf/edit-bookshelf.component';
import { DeleteBookshelfComponent } from '../../components/delete-bookshelf/delete-bookshelf.component';
import { EditBooklistComponent } from '../../components/edit-booklist/edit-booklist.component';
import { AppendixComponent } from '../../components/appendix/appendix.component';

function getBookURL(book: any): string {
  // 책 페이지로 이동, /@bid 주소가 뷰어로 가는 책은 고려되지 않음.
  if (book.expired) {
    return `/@${book.bid}`;
  }

  if (!book.url) {
    return `/@${book.bid}/cover`;
  }

  if (/\/@[^plwj][a-z]\d{4}$/.test(book.url)) {
    return `${book.url}/cover`;
  }

  return book.url;
}

type Book = BookshelfBook & {
  permissionType: BookshelfBook['type'];
  urlPath: string;
  urlQueryParams?: Record<string, string | undefined>;
};

@Component({
  selector: 'lib-my-bookshelf-detail-page',
  templateUrl: './my-bookshelf-detail-page.component.html',
  styleUrl: './my-bookshelf-detail-page.component.scss',
})
export class MyBookshelfDetailPageComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('searchInput') searchInput?: ElementRef;
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;
    if (
      !clickedElement.closest('.inner-selectBox') &&
      !clickedElement.closest('.more-button')
    ) {
      this._isDetailButton = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this._screenWidth = window.innerWidth;
  }

  private _bookshelfItemSubject = new Subject<string>();
  public _bookshelfBookListSubject = new Subject<{
    id: string;
    page: number;
  }>();

  public _bookshelf?: Bookshelf;
  public _bookshelvesBookList: Book[] = [];

  private _bookshelf_id?: string;
  public _screenWidth: number = window.innerWidth;
  public _currSearchValue: string = '';
  public _prevSearchValue: string = '';
  public _isSearchButton: boolean = false;
  public _isDetailButton: boolean = false;

  public _currentPage: number = 1;
  public readonly _itemCountPerPage: number = 30;
  public _totalItemCount?: number;
  public _isLoading: boolean = true;

  private _timer?: number;

  public _isApp: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookshelfAPIService: BookshelfAPIService,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _readingGroupDialogService: ReadingGroupDialogService
  ) {}

  ngOnInit(): void {
    this._isLoading = true;
    this._isApp = this._activatedRoute.snapshot.data['isApp'];

    this._bookshelfItemSubject
      .pipe(
        switchMap((id) => {
          return this._bookshelfAPIService.getInfo(id);
        })
      )
      .subscribe((result) => {
        this._bookshelf = result;
      });

    this._bookshelfBookListSubject
      .pipe(
        switchMap(({ id, page }) => {
          this._isLoading = true;

          return this._bookshelfAPIService.getBooks(
            id,
            page * this._itemCountPerPage,
            this._itemCountPerPage,
            this._currSearchValue
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        window.scrollTo(0, 0);

        this._totalItemCount = result.total;
        this._bookshelvesBookList = result.items.map((item) => {
          const [path, query] = getBookURL(item).split('?');

          return {
            ...item,
            permissionType: item.type,
            urlPath: path,
            urlQueryParams: query ? Url.qs.parse(query) : undefined,
          };
        });
      });

    this._activatedRoute.queryParams.subscribe((params) => {
      const q = params['q'];
      this._currSearchValue = !q ? '' : q;

      const id = params['id'];
      this._bookshelf_id = id;

      let p = parseInt(params['p']);
      if (isNaN(p)) {
        p = 1;
      }
      this._currentPage = p - 1;

      this._bookshelfItemSubject.next(id);
      this._bookshelfBookListSubject.next({ id, page: this._currentPage });
    });
  }

  ngAfterViewChecked(): void {
    this.searchInput?.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this._bookshelfItemSubject.complete();
    this._bookshelfBookListSubject.complete();
  }

  public _onClickSearchButton(): void {
    this._isSearchButton = !this._isSearchButton;
  }

  public _clearCurSearchValueAndCloseSearchBar(): void {
    this._onClickSearchButton();
    this._clearCurrSearchValue();
  }

  public _onClickDetailButton(): void {
    this._isDetailButton = !this._isDetailButton;
  }

  public _clearCurrSearchValue(): void {
    this._currSearchValue = '';

    this._onPageChange(0);
  }

  public _changeReadingBookTime(
    inputDate: string,
    dateType: 'date' | 'time'
  ): string {
    const dateString = inputDate;
    const dateObject = new Date(dateString);

    const year = String(dateObject.getFullYear()).substr(2, 4);
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    if (dateType === 'date') {
      return `${year}.${month < 10 ? `0${month}` : month}.${
        date < 10 ? `0${date}` : date
      }.`;
    } else {
      return `${hours}:${minutes}`;
    }
  }

  public _onClickBookAddButton(): void {
    this._dialogService
      .open(AddBookComponent, {
        data: {
          bookshelfTitle: this._bookshelf!.title,
          bookshelf_id: this._bookshelf_id!,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && !result.canceled) {
          this._routeRefreshState();
        }
      });
  }

  public _onClickEditBookshelf(): void {
    this._isDetailButton = false;
    this._dialogService
      .open(EditBookshelfComponent, {
        data: {
          bookshelf_id: this._bookshelf_id!,
          bookshelf_name: this._bookshelf!.title,
          is_private: this._bookshelf!.is_private,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.canceled === 'success') {
          this._bookshelfItemSubject.next(this._bookshelf_id!);
          this._routeRefreshState();
        }
      });
  }

  public _onClickDeleteBookshelf(): void {
    this._isDetailButton = false;
    this._alertService
      .open(DeleteBookshelfComponent, {
        data: {
          bookshelf_id: this._bookshelf_id!,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.canceled === 'success') {
          this._router.navigate(['/bookshelf']);
        }
      });
  }

  public _onClickEditBooklist(): void {
    this._isDetailButton = false;
    this._dialogService
      .open(EditBooklistComponent, {
        data: {
          bookshelf_id: this._bookshelf_id!,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && !result.canceled) {
          this._routeRefreshState();
        }
      });
  }

  public _onClickAppendix(event: Event, id: string): void {
    event.stopPropagation();
    this._dialogService.open(AppendixComponent, {
      data: {
        book_id: id,
      },
    });
  }

  onBookClick(index: number): void {
    const book = this._bookshelvesBookList[index];

    if (book.expired) {
      return;
    }

    if (book.book_type === 'edition') {
      this.goToBook(book);
      return;
    }

    const dialog = this._readingGroupDialogService.openReadingModeSelection(
      book.bid
    );

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.canceled) {
        return;
      }

      this.goToBook(book, result.groupId);
    });
  }

  goToBook(book: Book, groupId?: number): void {
    this._router.navigate([book.urlPath], {
      queryParams: Object.assign(
        { ...book.urlQueryParams },
        groupId && {
          gId: groupId,
        },
        {
          bookshelf: true,
        }
      ),
    });
  }

  // 만료된 책 책장에서 삭제 로직
  public _onClickDeleteBook(id: string): void {
    this._alertService
      .openConfirm(
        '대여 / 구독 기간이 만료되었습니다.',
        '책을 삭제하시겠습니까?',
        '삭제하기'
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._bookshelfAPIService.removeBook(id).subscribe(
            () => {
              this._routeRefreshState();
            },
            (error) => {
              if (error.status === 404) {
                this._bookshelfAPIService
                  .deleteBooks(this._bookshelf_id!, [id])
                  .subscribe(() => {
                    this._routeRefreshState();
                  });
              }
            }
          );
        }
      });
  }

  // 책장내에 책 검색 로직
  public _searchBookItem(): void {
    if (this._currSearchValue === this._prevSearchValue) {
      return;
    }

    this._prevSearchValue = this._currSearchValue;

    clearTimeout(this._timer);

    this._timer = window.setTimeout(() => {
      this._onPageChange(0);
    }, 800);
  }

  // pagination 페이지 이동 로직
  public _onPageChange(page: number): void {
    const queryParams: {
      id?: string;
      p: number;
      q?: string;
    } = {
      id: this._bookshelf_id,
      p: page + 1,
    };

    if (this._currSearchValue !== '') {
      queryParams.q = this._currSearchValue;
    }

    this._router.navigate([], {
      queryParams,
    });
  }

  // refresh 상태에 next인지 router인지에 따라 동작하는 로직
  public _routeRefreshState(): void {
    if (this._currentPage === 0 && this._currSearchValue === '') {
      this._bookshelfBookListSubject.next({
        id: this._bookshelf_id!,
        page: this._currentPage,
      });
    } else {
      this._currSearchValue = '';
      this._onPageChange(0);
    }
  }
}
