import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Address, BookType } from '@bukio/viewer';

import { DialogService } from 'shared/ui';

import { Book } from '../../models/book.model';

import { EventBusService } from '../../services/event-bus.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';

import { PDFPageSliderComponent } from '../pdf-page-slider/pdf-page-slider.component';
import { ThumbnailDialogComponent } from '../../dialogs/thumbnail-dialog/thumbnail-dialog.component';
import { ReadingGroupMembersDialogComponent } from '../../dialogs/reading-group-members-dialog/reading-group-members-dialog.component';
import { HelpDialogComponent } from '../../dialogs/help-dialog/help-dialog.component';
import { UIStateService } from '../../services/ui-state.service';

export enum FooterMenuItem {
  TOC,
  Thumbnail,
  Settings,
  Help,
  ReadingMode,
  GroupMembers,
  GroupMemos,
}

@Component({
  selector: 'viewer-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild(PDFPageSliderComponent) pdfPageSliderComp!: PDFPageSliderComponent;
  @ViewChild('pageInput') pageInputElement!: ElementRef<HTMLInputElement>;

  private _unsubscriber: Subject<void> = new Subject<void>();

  public readonly MenuItem = FooterMenuItem;
  public readonly BookType = BookType;

  private _address?: Address;
  private _book?: Book;

  public _groupMemberCount = 0;
  public _isCommentaryEditorMode = false;
  public _unreadMemoCount?: number;

  get _bookType(): BookType | undefined {
    return this._book?.meta.type;
  }

  constructor(
    private eventBusService: EventBusService,
    private _readingGroupsService: ReadingGroupsService,
    private _dialogService: DialogService,
    private _uiStateService: UIStateService
  ) {}

  ngOnInit(): void {
    this._readingGroupsService.currentGroup$.subscribe((group) => {
      this._groupMemberCount = group ? group.member_count : 0;
      this._unreadMemoCount = group?.memo_unread_count;
    });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;
        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;
      });

    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._address = data.address;
      });
  }

  _onMenuItemClick(menu: FooterMenuItem): void {
    switch (menu) {
      case FooterMenuItem.GroupMembers:
        this._openGroupMembersDialog();
        break;
      case FooterMenuItem.TOC:
      case FooterMenuItem.ReadingMode:
      case FooterMenuItem.Settings:
      case FooterMenuItem.GroupMemos:
        this.eventBusService.fire('FooterComponent:menuClick', { menu });
        break;
      case FooterMenuItem.Thumbnail:
        this._openThumbnailDialog();
        break;
      case FooterMenuItem.Help:
        this._openHelpDialog();
        break;
    }
  }

  private _openGroupMembersDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(ReadingGroupMembersDialogComponent, {
      data: {
        bid: this._book?.meta.bid,
      },
    });
  }

  _openThumbnailDialog(): void {
    if (!this._book || !this._address) {
      return;
    }

    this._dialogService.open(ThumbnailDialogComponent, {
      data: {
        book: this._book,
        currentIid: this._address.iid || this._book.items[0].iid,
      },
    });
  }

  _openHelpDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(HelpDialogComponent, {
      data: {
        isPDFBook: this._book.meta.type === BookType.PDF,
      },
    });
  }
}
