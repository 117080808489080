import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, takeUntil } from 'rxjs';

import { APIError, HighlightsAPIService } from 'shared/services';
import { Highlight } from 'shared/models';
import { ToastService } from 'shared/ui';

import { USER_HIGHLIGHT_STYLES } from '../contents/contents.component';
import { AnnotationListComponent } from '../annotation-list/annotation-list.component';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { EventBusService } from '../../services/event-bus.service';
import { UIStateService } from '../../services/ui-state.service';

const USER_HIGHLIGHT_STYLE_MAP = USER_HIGHLIGHT_STYLES.reduce((map, style) => {
  map[style.styleClass] = style.style;
  return map;
}, {} as { [styleClass: string]: string });

@Component({
  selector: 'viewer-highlight-list',
  templateUrl: './highlight-list.component.html',
  styleUrls: [
    '../annotation-list/annotation-list.component.scss',
    './highlight-list.component.scss',
  ],
})
export class HighlightListComponent
  extends AnnotationListComponent<Highlight>
  implements OnInit, OnDestroy
{
  private _unsubscriber = new Subject<void>();

  public readonly _styleClassStyleMap = USER_HIGHLIGHT_STYLE_MAP;

  public _selectedFilter: string | null = null;

  public __items: AnnotationListComponent<Highlight>['_items'] = [];

  private _bid?: string;

  public _isCommentaryEditorMode = false;

  constructor(
    private _clipboard: Clipboard,
    private _highlightsAPIService: HighlightsAPIService,
    private _toastService: ToastService,
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService,
    private _uiStateService: UIStateService
  ) {
    super();
  }

  override ngOnInit(): void {
    this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;

    this._eventBusService
      .on('ContentsComponent:highlightUpdated')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this.loadItems();
      });

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  @Input()
  set bid(bid: string | undefined) {
    this._bid = bid;
    this.loadItems();
  }

  get bid(): string | undefined {
    return this._bid;
  }

  override loadItems(): void {
    if (!this.bid) {
      return;
    }

    this._highlightsAPIService
      .get(
        this.bid,
        0,
        999,
        this._isCommentaryEditorMode
          ? {
              commentary_id: this._annotationsService.getState().commentaryId,
              filter: 'theirs',
            }
          : {
              group_id: this._annotationsService.getState().groupId,
              filter: 'mine',
            }
      )
      .subscribe((highlights) => {
        this.__items = highlights.map((highlight) => ({
          ...highlight,
          selected: false,
          updatedDateTime: new Date(highlight.created_at).getTime(),
        }));
        this._applyFilter();
      });
  }

  _onCopyMenuClick(item: Highlight): void {
    this._clipboard.copy(item.text);

    this._toastService.open('하이라이트가 복사되었습니다.');
  }

  _onDeleteMenuClick(item: Highlight): void {
    this._deleteItems([item.id]);
  }

  _onCopySelectedItemsButtonClick(): void {
    this._clipboard.copy(
      this._getSelectedItems()
        .map((item) => item.text)
        .join('\n\n')
    );

    this._toastService.open('하이라이트가 복사되었습니다.');
  }

  _onDeleteSelectedItemsButtonClick(): void {
    const ids = this._getSelectedItems().map((item) => item.id);
    this._deleteItems(ids);
  }

  _onFilterChange(): void {
    this._applyFilter();
  }

  _applyFilter(): void {
    this._items = !this._selectedFilter
      ? this.__items
      : this.__items.filter((item) => item.style === this._selectedFilter);
    this._sortItems();
  }

  _onItemClick(item: Highlight): void {
    this.itemClicked.emit(item.url);
  }

  private _deleteItems(ids: string[]): void {
    if (
      this._uiStateService.isCommentaryEditor &&
      !this._uiStateService.canEditCommentary
    ) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    this._annotationsService.deleteHighlightsById(ids).subscribe({
      next: () => {
        this.__items = this.__items.filter(
          (item) => ids.indexOf(item.id) === -1
        );
        this._applyFilter();
        this._toastService.open('하이라이트가 삭제되었습니다.');
      },
      error: (error: APIError) => {
        this._toastService.open(error.message);
      },
    });
  }
}
