import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { environment } from '../../../environments/environment';
import { ReadingGroupDialogService } from 'reading-group';
import { BookshelfAPIService } from 'shared/services';
import { AlertService, DialogService } from 'shared/ui';

import * as Url from 'url-parse';
import { Subject, switchMap } from 'rxjs';
import { OwnedBookSearchResultItem } from 'shared/models';
import { AppendixComponent } from 'bookshelf';

type Book = OwnedBookSearchResultItem & {
  permissionType: string;
  urlPath: string;
  urlQueryParams?: Record<string, string | undefined>;
};

function getBookURL(book: any): string {
  // 책 페이지로 이동, /@bid 주소가 뷰어로 가는 책은 고려되지 않음.
  if (book.expired) {
    return `/@${book.bid}`;
  }

  if (!book.url) {
    return `/@${book.bid}/cover`;
  }

  if (/\/@[^plwj][a-z]\d{4}$/.test(book.url)) {
    return `${book.url}/cover`;
  }

  return book.url;
}

@Component({
  templateUrl: './search-page.component.html',
  styleUrl: './search-page.component.scss',
})
export class SearchPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchInput') searchInput?: ElementRef;
  private _searchBookSubject = new Subject<string>();

  public _searchResultBook: Book[] = [];
  public _inputValue: string = '';
  public _isEmptyValue: boolean = true;

  public _currentPage: number = 0;
  public readonly _itemCountPerPage: number = 30;
  public _totalItemCount?: number;
  public _isLoading: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookshelfAPIService: BookshelfAPIService,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _readingGroupDialogService: ReadingGroupDialogService
  ) {}

  ngOnInit(): void {
    this._searchBookSubject
      .pipe(
        switchMap((query) => {
          return this._bookshelfAPIService.searchInOwnedBooks(
            this._currentPage * this._itemCountPerPage,
            this._itemCountPerPage,
            query
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        this._totalItemCount = result.total;

        this._searchResultBook = result.items.map((item) => {
          const [path, query] = getBookURL(item).split('?');

          return {
            ...item,
            permissionType: item.type,
            urlPath: path,
            urlQueryParams: query ? Url.qs.parse(query) : undefined,
          };
        });
      });

    this._activatedRoute.queryParams.subscribe((params) => {
      const q = params['q'];

      if (!q) {
        this._searchResultBook = [];
        this._inputValue = '';
        return;
      }

      this._inputValue = q;

      let p = parseInt(params['p']);
      if (isNaN(p)) {
        p = 1;
      }
      this._currentPage = p - 1;

      this._isLoading = true;
      this._searchBookSubject.next(q);
    });
  }

  ngAfterViewInit(): void {
    this.searchInput?.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this._searchBookSubject.complete();
  }

  public _searchBook(): void {
    if (this._inputValue === '') {
      return;
    }

    this._router.navigate([], {
      queryParams: { q: this._inputValue },
    });
  }

  public _clearSearchValue(): void {
    this._inputValue = '';
    this._isEmptyValue = true;
  }

  public onInputChange(event: any): void {
    if (event.isComposing) {
      this._isEmptyValue = false;
      return;
    }

    if (!this._inputValue) {
      this._isEmptyValue = true;
    } else {
      this._isEmptyValue = false;
    }
  }

  onBookClick(index: number): void {
    const book = this._searchResultBook[index];

    if (book.expired) {
      return;
    }

    if (book.book_type === 'edition') {
      this.goToBook(book);
      return;
    }

    const dialog = this._readingGroupDialogService.openReadingModeSelection(
      book.bid
    );

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.canceled) {
        return;
      }

      this.goToBook(book, result.groupId);
    });
  }

  goToBook(book: Book, groupId?: number): void {
    this._router.navigate([book.urlPath], {
      queryParams: Object.assign(
        { ...book.urlQueryParams },
        groupId && {
          gId: groupId,
        },
        {
          bookshelf: true,
        }
      ),
    });
  }

  // 만료된 책 책장에서 삭제 로직
  public _onClickDeleteBook(event: any, id: string): void {
    event.stopPropagation();
    this._alertService
      .openConfirm(
        '대여 / 구독 기간이 만료되었습니다.',
        '책을 삭제하시겠습니까?',
        '삭제하기'
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._bookshelfAPIService.removeBook(id).subscribe(() => {
            this._searchResultBook = this._searchResultBook.filter((book) => {
              return book.bid !== id;
            });
          });
        }
      });
  }

  public _routePreviousPage(): void {
    window.history.back();
  }

  public _onClickAppendix(event: Event, id: string): void {
    event.stopPropagation();
    this._dialogService.open(AppendixComponent, {
      data: {
        book_id: id,
      },
    });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }
}
