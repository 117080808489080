<section>
  <header>
    <h2>부록 보기</h2>
    <sui-icon name="엑스" (click)="_closeDialog()"></sui-icon>
  </header>
  <main>
    <ul class="appendix-list" #listContainer>
      <li *ngFor="let subcontent of _subcontents">
        <div class="inner">
          <div class="img-container">
            <img src="{{ subcontent.cover }}" alt="" />
          </div>
          <div class="info">
            <div class="title">{{ subcontent.title }}</div>
            <div class="author">{{ subcontent.author }}</div>
            <button
              sui-box-button
              varSize="medium44"
              (click)="_routeSubcontent(subcontent.bid)"
            >
              <span>바로 읽기</span>
              <sui-icon name="오른쪽 이동"></sui-icon>
            </button>
          </div>
        </div>
      </li>
      <sui-paginator
        [page]="_currentPage"
        [totalItemCount]="_total"
        [itemCountPerPage]="_itemCountPerPage"
        (pageChange)="_onPageChange($event + 1)"
      ></sui-paginator>
    </ul>
  </main>
  <div class="loader-container" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</section>
