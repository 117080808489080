import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { finalize } from 'rxjs';

import { ReadingGroup, ReadingGroupType } from 'shared/models';
import { APIError, ReadingGroupsAPIService } from 'shared/services';
import { ToastService } from 'shared/ui';

const MIN_GROUP_MEMBER_COUNT = 2;
const MAX_PRIVATE_GROUP_MEMBER_COUNT = 300;
const MAX_PUBLIC_GROUP_MEMBER_COUNT = 100;

const memberCountValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const value = control.value;

  if (value == null) {
    return null;
  }

  const type = control.parent?.get('type')?.value;

  if (type === ReadingGroupType.private) {
    return value < MIN_GROUP_MEMBER_COUNT ||
      value > MAX_PRIVATE_GROUP_MEMBER_COUNT
      ? {
          privateGroupMemberCount: true,
        }
      : null;
  } else {
    return value < MIN_GROUP_MEMBER_COUNT ||
      value > MAX_PUBLIC_GROUP_MEMBER_COUNT
      ? {
          publicGroupMemberCount: true,
        }
      : null;
  }
};

const passcodeRequiredValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const type = control.parent?.get('type')?.value;
  return type === ReadingGroupType.private
    ? Validators.required(control)
    : null;
};

@Component({
  selector: 'lib-reading-group-edit',
  templateUrl: './reading-group-edit.component.html',
  styleUrl: './reading-group-edit.component.scss',
})
export class ReadingGroupEditComponent implements OnInit {
  @Input() initialValue?: ReadingGroup;
  @Input() contentId?: string;

  @Output() groupCreated = new EventEmitter<void>();
  @Output() groupEdited = new EventEmitter<void>();

  public _groupFormGroup!: FormGroup;

  public _isSubmitting = false;

  public _descriptionLength = 0;

  constructor(
    private _formBuilder: FormBuilder,
    private _readingGroupAPIService: ReadingGroupsAPIService,
    private _toastService: ToastService
  ) {}

  get _isPrivateGroup(): boolean {
    return this._groupFormGroup.get('type')?.value === ReadingGroupType.private;
  }

  get isChanged(): boolean {
    return this._groupFormGroup.dirty;
  }

  ngOnInit(): void {
    this._groupFormGroup = this._formBuilder.group({
      content_id: [this.contentId, Validators.required],
      name: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(30),
        ],
      ],
      type: [ReadingGroupType.public, Validators.required],
      description: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(200),
        ],
      ],
      max_member_count: [null, [Validators.required, memberCountValidator]],
      passcode: [
        null,
        [passcodeRequiredValidator, Validators.pattern(/\d{4}/)],
      ],
      is_hidden: [false],
    });

    if (this.initialValue) {
      this._groupFormGroup.patchValue({
        content_id: this.initialValue.content.id,
        name: this.initialValue.name,
        type: this.initialValue.type,
        description: this.initialValue.description,
        max_member_count: this.initialValue.max_member_count,
        passcode: this.initialValue.passcode,
        is_hidden: this.initialValue.is_hidden,
      });

      this._groupFormGroup.get('type')?.disable();
    }

    this._groupFormGroup.get('type')?.valueChanges.subscribe((type) => {
      if (type === ReadingGroupType.private) {
        this._groupFormGroup.patchValue({ passcode: null });
      }
      this._groupFormGroup.get('passcode')?.updateValueAndValidity();
    });

    this._groupFormGroup
      .get('description')
      ?.valueChanges.subscribe((description) => {
        this._descriptionLength = description.length;
      });
  }

  _onSubmit(): void {
    if (this.initialValue) {
      this._updateGroup();
    } else {
      this._createGroup();
    }
  }

  private _updateGroup(): void {
    this._isSubmitting = true;

    const formValue = this._groupFormGroup.getRawValue();

    this._readingGroupAPIService
      .update(this.initialValue!.id, {
        name: formValue.name,
        description: formValue.description,
        max_member_count: formValue.max_member_count,
        passcode: formValue.passcode,
        is_hidden: formValue.is_hidden,
      })
      .pipe(finalize(() => (this._isSubmitting = false)))
      .subscribe({
        next: () => {
          this.groupEdited.emit();
        },
        error: (error: APIError) => {
          this._toastService.open(error.message);
        },
      });
  }

  private _createGroup(): void {
    this._isSubmitting = true;

    this._readingGroupAPIService
      .create(this._groupFormGroup.getRawValue())
      .pipe(finalize(() => (this._isSubmitting = false)))
      .subscribe({
        next: () => {
          this.groupCreated.emit();
        },
        error: (error: APIError) => {
          this._toastService.open(error.message);
        },
      });
  }
}
