<div class="wrapper">
  <div class="header">
    <div class="inner-left">
      <sui-icon
        *ngIf="bookshelf.is_private"
        type="solid"
        name="잠금"
      ></sui-icon>
      <h3>
        <span class="title">
          {{ bookshelf.title }}
        </span>
        <span class="total"> ({{ bookshelf.content_count }})</span>
      </h3>
    </div>
    <sui-icon name="화살표_오른쪽"></sui-icon>
  </div>
  <div class="content" [class.empty]="bookshelf.content_count === 0">
    <div class="content-inner">
      <ul>
        <li class="book" *ngFor="let cover of bookshelf.covers | slice : 0 : 6">
          <img src="{{ cover }}" />
        </li>
      </ul>
      <p class="placeholder">책장에 담긴 책이 없습니다.</p>
    </div>
  </div>
</div>
