import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';

@Component({
  selector: 'viewer-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  public languages: string[][] = [];
  public selectedLangCodes: string[] = [];

  constructor(
    private element: ElementRef,
    private eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ address }) => {
        if (address.query?.l) {
          this.selectedLangCodes = address.query.l
            .split(',')
            .map((code: string) => code.trim());
        } else {
          this.selectedLangCodes = [];
        }
      });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ book }) => {
        this.languages = book.meta.locales;

        if (this.selectedLangCodes.length === 0) {
          this.selectedLangCodes = this.languages.map(([code]) => code);
        }

        if (this.languages.length > 0) {
          this.element.nativeElement.classList.remove('disabled');
        } else {
          this.element.nativeElement.classList.add('disabled');
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  toggleLanguage(langCode: string): void {
    const selectedIndex = this.selectedLangCodes.indexOf(langCode);

    if (selectedIndex === -1) {
      this.selectedLangCodes.push(langCode);
      this.languageChanged();
    } else if (this.selectedLangCodes.length > 1) {
      this.selectedLangCodes.splice(selectedIndex, 1);
      this.languageChanged();
    }
  }

  languageChanged(): void {
    this.eventBusService.fire('LangaugeSelectorComponent:change', {
      langs: this.selectedLangCodes,
    });
  }

  isSelectedButton(langCode: string): boolean {
    return this.selectedLangCodes.indexOf(langCode) !== -1;
  }

  get isActive(): boolean {
    return (
      !this.element.nativeElement.classList.contains('disabled') &&
      this.element.nativeElement.classList.contains('active')
    );
  }
}
