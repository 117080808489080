import { Component, Input } from '@angular/core';
import { ReadingGroup } from 'shared/models';

@Component({
  selector: 'viewer-reading-group-summary',
  templateUrl: './reading-group-summary.component.html',
  styleUrl: './reading-group-summary.component.scss',
})
export class ReadingGroupSummaryComponent {
  @Input() group!: ReadingGroup;
}
