import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, from, fromEvent, map, of } from 'rxjs';
import { DrawingMeta } from '../models/drawing-meta';

interface NativeMessageDataMap {
  iosCallback: {
    id: string;
    data: any;
  };
}

interface NativeMessage<T extends keyof NativeMessageDataMap> {
  bukHybrid: {
    type: T;
    data: NativeMessageDataMap[T];
  };
}

function isNativeMessage<T extends keyof NativeMessageDataMap>(
  data: any
): data is NativeMessage<T> {
  return 'bukHybrid' in data;
}

function isIOSCallbackData(data: any): data is NativeMessage<'iosCallback'> {
  return data.type === 'iosCallback';
}

interface NativeFunctionParamsMap {
  loadDrawings: [bid: string]; // => void
  saveDrawings: [];
  setDrawing: [iid: string, drawing: string]; // => void
  getDrawing: [iid: string]; // => string
  openSystemBars: []; // => void
  closeSystemBars: []; // => void
  setKeepScreenOn: [value: boolean]; // => void
  setDrawingMeta: [jsonStr: string]; // => void
  getDrawingList: []; // => json string array string;
  backupDrawings: [bids: string]; // => void
  restoreDrawings: []; // => void
  setScreenCaptureEnabled: [enabled: boolean]; // => void
  getDrawnItemList: []; // => iid string joined by comma
}

class IOSCallbackManager {
  private readonly _messageSubscription: Subscription;

  private _callbacks: {
    [id: string]: (data: any) => void;
  } = {};

  constructor() {
    if (!window.webkit?.messageHandlers.bukHybrid) {
      throw new Error('not ios inapp environment');
    }

    this._messageSubscription = fromEvent<MessageEvent>(
      window,
      'message'
    ).subscribe((event) => {
      if (!isNativeMessage(event.data)) {
        return;
      }

      if (!isIOSCallbackData(event.data.bukHybrid)) {
        return;
      }

      const id = event.data.bukHybrid.data.id;

      if (this._callbacks[id]) {
        this._callbacks[id](event.data.bukHybrid.data.data);
        delete this._callbacks[id];
      }
    });
  }

  post<T extends keyof NativeFunctionParamsMap>(
    type: T,
    params: NativeFunctionParamsMap[T],
    callback: (data: any) => void
  ): void {
    const id = Math.floor(Math.random() * 10000000000).toString();

    this._callbacks[id] = callback;

    window.webkit?.messageHandlers.bukHybrid.postMessage({ id, type, params });
  }

  destroy(): void {
    this._messageSubscription.unsubscribe();
  }
}

@Injectable({
  providedIn: 'root',
})
export class NativeBridgeService implements OnDestroy {
  private _iosCallbackManager?: IOSCallbackManager;

  constructor() {
    try {
      this._iosCallbackManager = new IOSCallbackManager();
    } catch (error) {
      //
    }
  }

  ngOnDestroy(): void {
    this._iosCallbackManager?.destroy();
  }

  // private _call<T extends keyof NativeFunctionParamsMap, Result>(
  //   name: T,
  //   ...args: NativeFunctionParamsMap[T]
  // ): Observable<Result> {
  //   return new Observable<Result>((observer) => {
  //     const start = performance.now();

  //     if (window.bukAndroid) {
  //       observer.next(window.bukAndroid[name](...args));
  //       observer.complete();
  //     } else {
  //       this._iosCallbackManager.post(name, args, (result) => {
  //         console.warn(name, performance.now() - start);
  //         observer.next(result);
  //         observer.complete();
  //       });
  //     }
  //   });
  // }

  private _call<T extends keyof NativeFunctionParamsMap, Result>(
    name: T,
    ...args: NativeFunctionParamsMap[T]
  ): Observable<Result> {
    if (window.bukAndroid) {
      return of(window.bukAndroid[name](...args));
    }

    if (this._iosCallbackManager) {
      return from(
        new Promise<Result>((resolve, reject) => {
          try {
            this._iosCallbackManager!.post(name, args, (result) => {
              resolve(result);
            });
          } catch (error) {
            reject(error);
          }
        })
      );
    }

    throw new Error('no native implements');
  }

  loadDrawings(bid: string): Observable<{ error: string | null }> {
    try {
      return this._call<'loadDrawings', string>('loadDrawings', bid).pipe(
        map((result) => JSON.parse(result))
      );
    } catch (error) {
      return of({ error: null });
    }
  }

  saveDrawings(): Observable<void> {
    try {
      return this._call('saveDrawings');
    } catch (error) {
      return of(undefined);
    }
  }

  setDrawing(iid: string, drawing: string): Observable<void> {
    try {
      return this._call('setDrawing', iid, drawing);
    } catch (error) {
      return of(undefined);
    }
  }

  getDrawing(iid: string): Observable<string> {
    try {
      return this._call('getDrawing', iid);
    } catch (error) {
      return of('');
    }
  }

  openSystmeBars(): Observable<void> {
    try {
      return this._call('openSystemBars');
    } catch (error) {
      return of(undefined);
    }
  }

  closeSystemBars(): Observable<void> {
    try {
      return this._call('closeSystemBars');
    } catch (error) {
      return of(undefined);
    }
  }

  setKeepScreenOn(value: boolean): Observable<void> {
    try {
      return this._call('setKeepScreenOn', value);
    } catch (error) {
      return of(undefined);
    }
  }

  setDrawingMeta(meta: DrawingMeta): Observable<void> {
    try {
      return this._call('setDrawingMeta', JSON.stringify(meta));
    } catch (error) {
      return of(undefined);
    }
  }

  getDrawingList(): Observable<DrawingMeta[]> {
    try {
      return this._call<'getDrawingList', string>('getDrawingList').pipe(
        map((result) => {
          return JSON.parse(result).map((str: string) => JSON.parse(str));
        })
      );
    } catch (error) {
      return of([]);
    }
  }

  getDrawnItemList(): Observable<string[]> {
    try {
      return this._call<'getDrawnItemList', string>('getDrawnItemList').pipe(
        map((result) => {
          return result.split(',');
        })
      );
    } catch (error) {
      return of([]);
    }
  }

  backupDrawings(bids: string[]): Observable<void> {
    try {
      return this._call('backupDrawings', bids.join(','));
    } catch (error) {
      return of(undefined);
    }
  }

  restoreDrawings(): Observable<void> {
    try {
      return this._call('restoreDrawings');
    } catch (error) {
      return of(undefined);
    }
  }

  setScreenCaptureEnabled(enabled: boolean): Observable<void> {
    try {
      return this._call('setScreenCaptureEnabled', enabled);
    } catch (error) {
      return of(undefined);
    }
  }
}
