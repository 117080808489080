<div #barContainer class="bar-container">
  <div class="bar">
    <div class="fill" [style.width]="_percent + '%'"></div>
  </div>
  <!-- <div
    class="crumb"
    *ngIf="_crumbPercent !== undefined"
    [style.left]="'calc(' + _crumbPercent + '% - 6px)'"
    (mousedown)="$event.stopPropagation()"
    (touchstart)="$event.stopPropagation()"
    (click)="_onCrumbClick()"
  ></div> -->
  <div
    class="handle"
    [style.left]="'clamp(0px, ' + _percent + '%, 100% - 14px)'"
  ></div>
  <div
    class="page-preview-box"
    [class.is-visible]="_isDragging"
    [attr.style]="
      'left: calc(' +
        _percent +
        '% - 40px); left: clamp(-5px, ' +
        _percent +
        '% - 40px + 7px, 100% - 80px + 5px);' | safe : 'style'
    "
  >
    <div class="thumbnail">
      <img
        class="thumbnail-image"
        [class.is-blurred]="_isPreviewPageLocked"
        [src]="_previewThumbImageURL"
      />
      <div class="lock" [class.is-visible]="_isPreviewPageLocked">
        <div class="lock-circle">
          <sui-icon class="icon" name="잠금" type="solid"></sui-icon>
        </div>
      </div>
      <div class="page">
        {{ _previewPage }}
      </div>
    </div>
  </div>
</div>
