import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

window.bukApp = {} as any;

Sentry.init({
  dsn: environment.production
    ? 'https://9fee6638171f2399b685dcadf05a6354@o1077493.ingest.us.sentry.io/4507496389410816'
    : undefined,
  release: 'appbukio-' + environment.appVersion,
  integrations: [],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
