import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  HostBinding,
  ViewChild,
  Inject,
  Optional,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { merge, of, Subject, zip } from 'rxjs';
import { takeUntil, map, pairwise, filter } from 'rxjs/operators';

import { Address, BookType, PageInfoChangeEvent, Theme } from '@bukio/viewer';
import { setTransport, flush } from '@amplitude/analytics-browser';

import {
  DialogService,
  SUI_DIALOG_DEFAULT_OPTIONS,
  ToastService,
} from 'shared/ui';
import {
  AmplitudeService,
  ReadingGroupsAPIService,
  SharedPageTitleService,
} from 'shared/services';
import { roundTo } from 'shared/utils';

import { VIEWER_ENVIRONMENT, ViewerModuleEnvironment } from '../viewer.module';

import { logAffiliationToCookie } from '../utils/address';
import { isViewerEmbedded } from '../utils/misc';

import { Book } from '../models/book.model';
import { ReadingGroupWithMyActivityStats } from 'shared/models';

import { IntervalLoggerService } from '../services/interval-logger.service';
import { EventBusService } from '../services/event-bus.service';
import { BukHistoryService } from '../services/buk-history.service';
import { UIStateService } from '../services/ui-state.service';
import { AnnotationsV2Service } from '../services/annotations-v2.service';
import { ReadingGroupsService } from '../services/reading-groups.service';
import { BookService } from '../services/book.service';
import { MemoReadStatusService } from '../services/memo-read-status.service';
import { OnlineReadingMemberService } from '../services/online-reading-member.service';

import { SidebarContainerComponent } from '../components/sidebar-container/sidebar-container.component';
import { MemoPopupComponent } from '../components/memo-popup/memo-popup.component';
import { RightPanelComponent } from '../components/right-panel/right-panel.component';
import { StaticMemoPopupComponent } from '../components/static-memo-popup/static-memo-popup.component';

@Component({
  selector: 'viewer-root',
  templateUrl: './buk.component.html',
  styleUrls: ['./buk.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DialogService,
    UIStateService,
    IntervalLoggerService,
    AnnotationsV2Service,
    ReadingGroupsService,
    OnlineReadingMemberService,
    MemoReadStatusService,
    BookService,
    {
      provide: SUI_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        responsiveBreakpoint: 768,
        dimmed: true,
      },
    },
  ],
})
export class BukComponent implements OnInit, OnDestroy {
  @HostBinding('attr.data-theme') _theme?: Theme;
  @ViewChild('verticalSidebarContainer', { read: SidebarContainerComponent })
  _verticalSidebarContainerComp!: SidebarContainerComponent;

  @ViewChild(MemoPopupComponent, { static: true })
  private _memoPopupComp!: MemoPopupComponent;
  @ViewChild(StaticMemoPopupComponent, { static: true })
  private _staticMemoPopupComp!: StaticMemoPopupComponent;
  @ViewChild(RightPanelComponent, { static: true })
  private _rightPanelComp!: RightPanelComponent;

  @Output() menuOpenedChange = new EventEmitter<boolean>();
  @Output() bookLoad = new EventEmitter<Book>();

  @HostListener(`window:pagehide`, [`$event`])
  pageHide(): void {
    const navType = performance.getEntriesByType(
      'navigation'
    )[0] as PerformanceNavigationTiming;

    if (navType.type !== 'reload') {
      setTransport('beacon');
      this._callExitAmplitudeEvent(this._readingGroup);
      flush();
    }
  }

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();
  private book?: Book;

  private _isMenuOpened = false;
  private _embeddedSlideMode = false;
  private _isSidebarOpened = false;

  private _groupId?: string;

  private _readingGroup: ReadingGroupWithMyActivityStats | null = null;
  private _completionRate?: number;
  private _selectedCommentaryAuthor?: string;

  constructor(
    private element: ElementRef,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private eventBusService: EventBusService,
    private intervalLoggerService: IntervalLoggerService,
    private bukHistoryService: BukHistoryService,
    private uiStateService: UIStateService,
    @Inject(VIEWER_ENVIRONMENT) private _environment: ViewerModuleEnvironment,
    private _toastService: ToastService,
    private _amplitudeService: AmplitudeService,
    private _readingGroupsService: ReadingGroupsService,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    onlineReadingMemberService: OnlineReadingMemberService,
    bookService: BookService,
    private _readingGroupAPIService: ReadingGroupsAPIService,
    @Optional() private _pageTitleService?: SharedPageTitleService
  ) {
    this._groupId = this.activateRoute.snapshot.queryParams['gId'];
  }

  ngOnInit(): void {
    this._readingGroupsService.currentGroup$.subscribe((group) => {
      this._readingGroup = group;
    });

    document.body.classList.add('viewer-fixed');

    this._environment.production && this.intervalLoggerService.start();

    if (isViewerEmbedded()) {
      this.element.nativeElement.style.border = '1px solid #e0e0e0';
    }

    merge(
      this.eventBusService.on('ContentsComponent:bookLoadError'),
      this.eventBusService.on('ContentsComponent:itemLoadError')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._toastService
          .openWarning('책 주소가 유효하지 않습니다.', '홈으로')
          .onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/');
          });
      });

    this.eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        map((data) => data.settings.theme.value)
      )
      .subscribe((theme) => {
        this._theme = theme;
      });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ book }) => {
        if (this._groupId) {
          this._readingGroupsAPIService
            .getById(Number(this._groupId))
            .subscribe((result) => {
              this._callEnterAmplitudeEvent(result, book);
            });
        } else {
          this._callEnterAmplitudeEvent(null, book);
        }

        this.book = book;

        this._embeddedSlideMode = isViewerEmbedded() && book.isHorizontalPDF;

        this._pageTitleService?.setPageTitle(book.meta.title);

        if (this.book.permissionReferer) {
          logAffiliationToCookie(this.book.permissionReferer);
        }

        this.bookLoad.emit(book);
      });

    this.eventBusService
      .on('ContentsComponent:pageClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        if (this._isSidebarOpened) {
          this._rightPanelComp.memoListComp?.close();
        } else if (
          this._memoPopupComp.isOpened ||
          this._staticMemoPopupComp.isOpened
        ) {
          this._memoPopupComp.close();
          this._staticMemoPopupComp.close();
        } else if (this._isMenuOpened) {
          this._closeMenu();
        } else {
          !this._embeddedSlideMode && this._openMenu();
        }
      });

    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        map((data) => data.address),
        pairwise(),
        filter(([addr1, addr2]) => {
          return (
            addr1.bid !== addr2.bid ||
            addr1.iid !== addr2.iid ||
            addr1.anchor !== addr2.anchor
          );
        })
      )
      .subscribe(() => {
        this._closeMenu();
      });

    merge(
      this.eventBusService.on('ContentsComponent:settingsChanged'),
      this.eventBusService.on('ContentsComponent:itemLoad'),
      this.eventBusService.on('ContentsComponent:highlightClick'),
      this.eventBusService.on('ContentsComponent:zoomScaleChange'),
      this.eventBusService.on('ContentsComponent:select')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._rightPanelComp.memoListComp?.close();
        this._memoPopupComp.close();
        this._staticMemoPopupComp.close();
      });

    merge(
      this.eventBusService.on('ContentsComponent:pageClick2'),
      this.eventBusService.on('ContentsComponent:pdfPageScrolled')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._memoPopupComp.close();
        this._staticMemoPopupComp.close();
      });

    merge(
      this.eventBusService.on(
        'AnnotationSettingsFabComponent:anotationsButtonClick'
      ),
      this.eventBusService.on('ContentsComponent:memoIndicatorClick'),
      this.eventBusService.on(
        'ContentsComponent:commentaryPreviewMemoIndicatorClick'
      ),
      this.eventBusService.on('ContentsComponent:select')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._closeMenu();
      });

    this.eventBusService
      .on('ContentsComponent:commentaryPreviewMemoIndicatorClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._rightPanelComp.memoListComp?.close();
        this._memoPopupComp.close();
      });

    this.eventBusService
      .on('ContentsComponent:memoIndicatorClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._staticMemoPopupComp.close();
      });

    this.eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe((event) => {
        this._onPageInfoChange(event);
      });

    this.eventBusService
      .on('ReadingModeDialogComponent:groupChanged')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe((event) => {
        const entered$ =
          event.entered != null
            ? this._readingGroupAPIService.getById(event.entered)
            : of(null);
        const exited$ =
          event.exited != null
            ? this._readingGroupAPIService.getById(event.exited)
            : of(null);

        zip(entered$, exited$).subscribe(([entered, exited]) => {
          if (this.book) {
            this._callEnterAmplitudeEvent(entered, this.book);
          }
          this._callExitAmplitudeEvent(exited);
        });
      });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('viewer-fixed');

    this._callExitAmplitudeEvent(this._readingGroup);

    this.intervalLoggerService.stop();

    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  _onVerticalSidebarOpened(): void {
    this._isMenuOpened = true;
    this.menuOpenedChange.emit(true);
  }

  _onVerticalSidebarClosed(): void {
    this._isMenuOpened = false;
    this.menuOpenedChange.emit(false);
  }

  _onHorizontalSidebarOpened(): void {
    this._isSidebarOpened = true;
  }

  _onHorizontalSidebarClosed(): void {
    this._isSidebarOpened = false;
  }

  _onPageInfoChange(
    event: PageInfoChangeEvent & {
      address: Address;
    }
  ): void {
    if (!this.book) {
      return;
    }

    let percent;

    if (this.book.meta.type === BookType.PDF) {
      percent =
        (this.book.getIndexOfItem(event.address.iid ?? this.book.items[0].iid) +
          1) /
        this.book.items.length;
    } else if (this.book.meta.type === BookType.Document) {
      percent = event.page / event.pageCount;
    } else {
      percent = this.book.getPagePercent(
        event.address.iid ?? this.book.items[0].iid,
        event.page,
        event.pageCount
      );
    }

    this._completionRate = roundTo(4, percent);
  }

  private _openMenu(): void {
    if (this._isMenuOpened || this._embeddedSlideMode) {
      return;
    }

    this._verticalSidebarContainerComp.open(['top', 'bottom']);
  }

  private _closeMenu(): void {
    if (!this._isMenuOpened) {
      return;
    }

    this._verticalSidebarContainerComp.close(['top', 'bottom']);
  }

  _selectCommentary(id: number): void {
    this._selectedCommentaryAuthor = this.book?.meta.commentaries.find(
      (commentary) => commentary.id === id
    )?.author;
  }

  _callEnterAmplitudeEvent(
    readingGroup: ReadingGroupWithMyActivityStats | null,
    book: Book
  ): void {
    if (!readingGroup) {
      this._amplitudeService.logEvent('enter_viewer_single_mode', {
        purchase_at: book.permission.purchased_at,
        category: book.meta.category ? book.meta.category[0].name : null,
        bid: book.meta.bid,
        is_preview: !book.isOwnedByUser,
      });
    } else {
      this._amplitudeService.logEvent('enter_viewer_together_mode', {
        purchase_at: book.permission.purchased_at,
        category: book.meta.category ? book.meta.category[0].name : null,
        bid: book.meta.bid,
        book_title: book.meta.title,
        group_name: readingGroup.name,
        group_type: readingGroup.type,
        group_member_count: readingGroup.member_count,
        group_leader: readingGroup.leader_nickname,
        memo_count: readingGroup.memo_count,
        highlight_count: readingGroup.highlight_count,
        my_memo_count: readingGroup.my_memo_count,
        my_memo_like_count: readingGroup.my_memo_like_count,
        my_memo_copy_count: readingGroup.my_memo_copy_count,
        my_highlights_count: readingGroup.my_highlights_count,
        is_preview: !book.isOwnedByUser,
      });
    }
  }

  _callExitAmplitudeEvent(
    readingGroup: ReadingGroupWithMyActivityStats | null
  ): void {
    if (!this.book) {
      return;
    }

    if (!readingGroup) {
      this._amplitudeService.logEvent('exit_viewer_single_mode', {
        completion_rate: this._completionRate,
        purchase_at: this.book!.permission.purchased_at,
        category: this.book.meta.category
          ? this.book.meta.category[0].name
          : null,

        bid: this.book!.meta.bid,
        commentary_name: this._selectedCommentaryAuthor,
        is_preview: !this.book.isOwnedByUser,
      });
    } else {
      this._amplitudeService.logEvent('exit_viewer_together_mode', {
        completion_rate: this._completionRate,
        purchase_at: this.book!.permission.purchased_at,
        category: this.book.meta.category
          ? this.book.meta.category[0].name
          : null,
        bid: this.book!.meta.bid,
        book_title: this.book!.meta.title,
        group_name: readingGroup.name,
        group_type: readingGroup.type,
        group_member_count: readingGroup.member_count,
        group_leader: readingGroup.leader_nickname,
        memo_count: readingGroup.memo_count,
        highlight_count: readingGroup.highlight_count,
        my_memo_count: readingGroup.my_memo_count,
        my_memo_like_count: readingGroup.my_memo_like_count,
        my_memo_copy_count: readingGroup.my_memo_copy_count,
        my_highlights_count: readingGroup.my_highlights_count,
        is_preview: !this.book.isOwnedByUser,
      });
    }
  }
}
