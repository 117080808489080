import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { APIError, UsersAPIService } from 'shared/services';
import { AlertService, ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class EditProfileComponent
  extends TypedDialog<{ initial: boolean }, { canceled: boolean }>
  implements OnInit, OnDestroy
{
  @ViewChild('fileInput')
  private _fileInput!: ElementRef<HTMLInputElement>;

  private _isDuplicateSubject = new Subject<string>();
  private _myProfileChangeSubject = new Subject<{
    nickname: string;
    avatar_image_url: string;
  }>();
  private _imageUploadSubject = new Subject<any>();

  public _userInfo: any;
  public _baseAvatarImage: any;
  public _nickname: string = '';
  public _isTextLength: number = 0;
  public _uploadImage: any;
  public _isPageInit = true;
  public _isDuplication:
    | 'duplicate'
    | 'not-duplicate'
    | 'test-necessary'
    | 'empty' = 'empty';
  public _isImageChanged: boolean = false;

  constructor(
    private _alertService: AlertService,
    private _usersAPIService: UsersAPIService,
    private _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this._usersAPIService.getUsersProfile().subscribe((result) => {
      this._userInfo = result;
      this._baseAvatarImage = result.avatar_image_url;
      this._uploadImage = result.avatar_image_url;
      this._nickname = result.nickname;

      if (this._data.initial) {
        this._isDuplication = 'not-duplicate';
      }
    });

    this._isDuplicateSubject
      .pipe(
        switchMap((nickname: string) => {
          return this._usersAPIService.checkNicknameDuplication(nickname);
        })
      )
      .subscribe({
        next: (duplicated) => {
          this._isDuplication = duplicated ? 'duplicate' : 'not-duplicate';
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });

    this._myProfileChangeSubject
      .pipe(
        switchMap(({ nickname, avatar_image_url }) => {
          if (nickname === '') {
            nickname = this._userInfo.nickname;
          }
          return this._usersAPIService.changeMyProfile(
            nickname,
            avatar_image_url
          );
        })
      )
      .subscribe(() => {
        this._dialogRef.close({ canceled: false });
      });

    this._imageUploadSubject
      .pipe(
        switchMap((imageFile) => {
          return this._usersAPIService.uploadImage(imageFile);
        })
      )
      .subscribe((result) => {
        this._uploadImage = result[0].url;
        this._isImageChanged = true;
      });
  }

  ngOnDestroy(): void {
    this._isDuplicateSubject.complete();
    this._myProfileChangeSubject.complete();
    this._imageUploadSubject.complete();
  }

  public _closeDialog(): void {
    if (this._data.initial) {
      this._usersAPIService.cancelChangeProfile().subscribe();
    }

    this._dialogRef.close({ canceled: true });
  }

  public _changeNickname(): void {
    this._isTextLength = this._nickname.length;
    this._isPageInit = false;
    if (this._nickname.length > 0) {
      this._isDuplication = 'test-necessary';
    }

    if (this._nickname.length === 0) {
      this._isDuplication = 'empty';
    }
  }

  public _onClickDuplicate(): void {
    if (this._nickname === '') {
      return;
    }

    if (this._nickname.length < 2 || this._nickname.length > 20) {
      return;
    }
    this._isDuplicateSubject.next(this._nickname);
  }

  public _onClickSubmit(): void {
    this._myProfileChangeSubject.next({
      nickname: this._nickname,
      avatar_image_url: this._uploadImage,
    });
  }

  public _onClickUploadImage(): void {
    this._fileInput.nativeElement.click();
  }

  public _onClickDeleteImage(): void {
    this._uploadImage = '';
    if (this._uploadImage !== this._baseAvatarImage) {
      this._isImageChanged = true;
    }
  }

  public _onFileChanged(event: any): void {
    const fileSize = event.target.files[0].size;
    const maxSize = 10 * 1024 * 1024;

    if (fileSize > maxSize) {
      this._alertService.openConfirm(
        undefined,
        '10MB 이하의 이미지 파일만 업로드가 가능합니다.',
        '확인'
      );
    } else {
      this._imageUploadSubject.next(event.target.files);
    }
  }
}
