import { Injectable } from '@angular/core';
import { ImageSharingService as ViewerImageSharingService } from 'viewer';
import { NativeBridgeService } from './native-bridge.service';
import { ToastService } from 'shared/ui';

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = (): void => resolve(reader.result as string);
    reader.onerror = (): void => reject(reader.error);
    reader.readAsDataURL(blob);
  });
}

@Injectable({
  providedIn: 'root',
})
export class ImageSharingService implements ViewerImageSharingService {
  constructor(
    private _nativeBridgeService: NativeBridgeService,
    private _toastService: ToastService
  ) {}

  shareToSNS(blob: Blob, buttonRect: DOMRect): void {
    this._blobToBase64(blob).then((base64ImageURL) => {
      this._nativeBridgeService.shareImage(base64ImageURL, {
        x: Math.round(buttonRect.x),
        y: Math.round(buttonRect.y),
        width: Math.round(buttonRect.width),
        height: Math.round(buttonRect.height),
      });
    });
  }

  download(blob: Blob): void {
    this._blobToBase64(blob).then((base64ImageURL) => {
      this._nativeBridgeService.downloadImage(base64ImageURL);
    });
  }

  private _blobToBase64(blob: Blob): Promise<string> {
    return blobToBase64(blob).catch((reason) => {
      this._toastService.openWarning('이미지 생성 중 오류가 발생하였습니다.');
      throw reason;
    });
  }
}
