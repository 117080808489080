import { Component, Input, ElementRef, AfterContentInit } from '@angular/core';

let uniqueId = 0;

@Component({
  selector: 'sui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
})
export class CheckboxComponent implements AfterContentInit {
  @Input() label?: string;
  @Input() type: 'boxFirst' | 'labelFirst' = 'labelFirst';

  private _id?: string;

  constructor(private _element: ElementRef) {}

  get id(): string | undefined {
    return this._id;
  }

  ngAfterContentInit(): void {
    const checkbox = this._element.nativeElement.querySelector(
      'input[type="checkbox"]'
    );

    if (!checkbox.id) {
      checkbox.id = `sui-checkbox-${uniqueId++}`;
    }

    this._id = checkbox.id;
  }
}
