import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Channel, Library } from 'shared/models';
import { ChannelAPIService, LibraryAPIService } from 'shared/services';

@Injectable({
  providedIn: 'root',
})
export class LibraryResolver {
  constructor(
    private _libraryAPIService: LibraryAPIService,
    private _channelAPIService: ChannelAPIService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<Library | Channel | HttpErrorResponse> {
    if (route.url[0].path[1] === 'c') {
      return this._channelAPIService
        .get(route.url[0].path.replace('@', ''))
        .pipe(catchError((error) => of(error)));
    } else {
      return this._libraryAPIService
        .get(route.url[0].path.replace('@', ''))
        .pipe(catchError((error) => of(error)));
    }
  }
}
