<div #barContainer class="bar-container">
  <div class="bar">
    <div class="fill" [style.width]="_value * 100 + '%'"></div>
  </div>
  <div
    class="handle"
    [style.left]="'clamp(0px, ' + _value * 100 + '%, 100% - 14px)'"
  ></div>
  <div
    class="page-preview-box"
    [class.is-visible]="_isDragging"
    [attr.style]="
      'left: calc(' +
        _value * 100 +
        '% - 40px); left: clamp(-5px, ' +
        _value * 100 +
        '% - 40px + 7px, 100% - 80px + 5px);' | safe : 'style'
    "
  >
    <div class="thumbnail">
      <img
        class="thumbnail-image"
        [class.is-blurred]="_isPreviewPageLocked"
        [src]="_previewThumbImageURL"
      />
      <div class="lock" [class.is-visible]="_isPreviewPageLocked">
        <div class="lock-circle">
          <sui-icon class="icon" name="잠금" type="solid"></sui-icon>
        </div>
      </div>
      <div class="page">
        {{ _previewPage }}
      </div>
    </div>
  </div>
</div>
<div class="page-number">
  <input
    #pageInput
    [value]="_currentPage"
    type="number"
    (blur)="_onPageInputBlur(pageInput.value)"
    (keydown)="
      $event.key === 'Enter' ? pageInput.blur() : $event.stopPropagation()
    "
    (click)="pageInput.select()"
  />
  &nbsp;&nbsp;/&nbsp;&nbsp;{{ _totalPageCount }}
</div>
