<sui-dialog-header class="header" (closeButtonClick)="onCloseButtonClick()">
  읽기 모드 변경
</sui-dialog-header>
<div class="body">
  <div class="left" *ngIf="_modes[_selectedModeIndex] as mode">
    <h1 class="title">
      {{ mode[0] }}
    </h1>
    <h2 class="subtitle">
      <div class="highlight"></div>
      <p>
        <b>{{ mode[0] }} 모드</b>에는 이런 장점이 있어요!
      </p>
    </h2>
    <ul class="description">
      <li *ngFor="let description of mode[1]; index as i">
        <div class="number">{{ i + 1 }}.</div>
        <span [innerHTML]="description"></span>
      </li>
    </ul>
  </div>
  <div class="right">
    <img src="assets/images/reading-mode-selection.png" />
    <div class="select">
      <div
        class="option"
        *ngFor="let mode of _modes; index as i"
        (click)="_onSelectMode(i)"
      >
        <div class="circle" [class.checked]="_selectedModeIndex === i">
          <sui-icon name="체크"></sui-icon>
        </div>
        <span>{{ mode[0] }}</span>
      </div>
    </div>
    <viewer-reading-group-select
      *ngIf="_selectedModeIndex === 1"
      [groups]="_groups"
      [currentGroupId]="_currentGroupId"
      [(selectedGroupId)]="_selectedGroupId"
    ></viewer-reading-group-select>
    <div class="actions">
      <button
        sui-solid-button
        varSize="large"
        [disabled]="
          (_selectedModeIndex === 1 && _selectedGroupId === undefined) ||
          (_selectedModeIndex === 0 && _currentGroupId === undefined)
        "
        (click)="_onOKButtonClick()"
      >
        변경하기
      </button>
    </div>
  </div>
</div>
