import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { Location } from '@angular/common';
import { Subject, Subscription, timer } from 'rxjs';
import { takeUntil, startWith, pairwise, map, switchMap } from 'rxjs/operators';

import { Address, BookType } from '@bukio/viewer';

import {
  NotesAPIService,
  SharedAuthService,
  SharedUserService,
} from 'shared/services';
import { NotesDialogComponent } from 'user';
import { AlertService, DialogService, ToastService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';
import { PageVisibilityStateService } from '../../services/page-visibility-state.service';

import { Book } from '../../models/book.model';
import { AnalyticsService } from '../../services/analytics.service';
import { ThumbnailDialogComponent } from '../../dialogs/thumbnail-dialog/thumbnail-dialog.component';
import { SearchDialogComponent } from '../../dialogs/search-dialog/search-dialog.component';
import { ReadingGroupMembersDialogComponent } from '../../dialogs/reading-group-members-dialog/reading-group-members-dialog.component';
import { HelpDialogComponent } from '../../dialogs/help-dialog/help-dialog.component';
import { UIStateService } from '../../services/ui-state.service';
import { PDFDrawingService } from '../../services/pdf-drawing.service';
import { BookFeaturesStoreService } from '../../services/book-features-store.service';

export enum HeaderMenuItem {
  Bookmark = 'bookmark',
  Back = 'back,',
  TOC = 'info',
  Thumbnail = 'thumbnail',
  Settings = 'setting',
  Help = 'help',
  ReadingMode = 'reading-mode',
  Search = 'search',
  GroupMembers = 'group-members',
  Inbox = 'inbox',
  OpenInNew = 'embed',
  DrawingMode = 'drawing-mode',
}

const CHECK_INBOX_PERIOD = 20 * 1000;
const DRAWING_NOTI_LOCALSTORAGE_KEY = 'viewer-drawing-noti';

@Component({
  selector: 'viewer-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private _unsubscriber = new Subject<void>();

  public readonly MenuItem = HeaderMenuItem;
  public readonly _embedded = self !== top;
  public _book?: Book;
  public _address?: Address;

  public _groupMemberCount = 0;

  private _isUserLoggedIn = false;

  private _noteCheckSubscription?: Subscription;
  public _hasNewNote = false;

  public _bookmarked = false;
  public _isDrawingMode = false;

  public _isCommentaryEditorMode = false;

  public _canSearchText = false;

  constructor(
    private _location: Location,
    private _userService: SharedUserService,
    private _eventBus: EventBusService,
    private _readingGroupsService: ReadingGroupsService,
    private _notesAPIService: NotesAPIService,
    private _pageVisibilityStateService: PageVisibilityStateService,
    private _toastService: ToastService,
    private _analyticsService: AnalyticsService,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _authService: SharedAuthService,
    private _uiStateService: UIStateService,
    @Optional() private _pdfDrawingService: PDFDrawingService,
    private _bookFeaturesStoreService: BookFeaturesStoreService
  ) {}

  ngOnInit(): void {
    this._userService.user
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((user) => (this._isUserLoggedIn = !!user));

    this._uiStateService.isDrawingMode$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((isDrawingMode) => {
        this._isDrawingMode = isDrawingMode;
      });

    this._pageVisibilityStateService.state$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((state) => {
        if (state === 'hidden') {
          this._stopCheckInbox();
        } else {
          const wasCheckingInbox =
            this._readingGroupsService.currentGroup && !this._hasNewNote;
          wasCheckingInbox && this._startCheckInbox();
        }
      });

    this._eventBus
      .on('ContentsComponent:addressChange')
      .pipe(
        takeUntil(this._unsubscriber),
        map((data) => data.address),
        startWith(undefined),
        pairwise()
      )
      .subscribe(([addr1, addr2]) => {
        this._address = addr2;
      });

    this._eventBus
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._book = data.book;
        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;
      });

    this._readingGroupsService.currentGroup$.subscribe((group) => {
      this._groupMemberCount = group ? group.member_count : 0;

      if (group) {
        !this._isCheckingInbox && this._startCheckInbox(true);
      } else {
        this._stopCheckInbox();
      }
    });

    this._eventBus
      .on('ContentsComponent:bookmarkStateChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._bookmarked = data.isActive;
      });

    this._bookFeaturesStoreService.features$.subscribe((features) => {
      this._canSearchText = features.text_search;
    });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
    this._stopCheckInbox();
  }

  get _isPDFMode(): boolean {
    return this._book?.meta.type === BookType.PDF;
  }

  private _startCheckInbox(startImmediately?: boolean): void {
    this._stopCheckInbox();

    this._noteCheckSubscription = timer(
      startImmediately ? 0 : CHECK_INBOX_PERIOD,
      CHECK_INBOX_PERIOD
    )
      .pipe(
        switchMap(() => {
          return this._notesAPIService.checkInbox();
        })
      )
      .subscribe((newNote) => {
        this._hasNewNote = newNote;
        newNote && this._stopCheckInbox();
      });
  }

  private _stopCheckInbox(): void {
    this._noteCheckSubscription?.unsubscribe();
    this._noteCheckSubscription = undefined;
  }

  private get _isCheckingInbox(): boolean {
    return !!this._noteCheckSubscription;
  }

  _onMenuItemClick(item: HeaderMenuItem): void {
    this._analyticsService.trackEvent('button', item);

    switch (item) {
      case HeaderMenuItem.Back:
        this._location.back();
        break;
      case HeaderMenuItem.OpenInNew:
        this._openInNewTab();
        break;
      case HeaderMenuItem.Thumbnail:
        this._openThumbnailDialog();
        break;
      case HeaderMenuItem.Search:
        this._openSearchDialog();
        break;
      case HeaderMenuItem.TOC:
      case HeaderMenuItem.Settings:
      case HeaderMenuItem.ReadingMode:
        this._eventBus.fire('HeaderComponent:menuClick', { menu: item });
        break;
      case HeaderMenuItem.GroupMembers:
        this._openGroupMembersDialog();
        break;
      case HeaderMenuItem.Inbox:
        this._openInboxDialog();
        break;
      case HeaderMenuItem.Bookmark:
        this._onBookmarkButtonClick();
        break;
      case HeaderMenuItem.DrawingMode:
        this._onDrawingModeButtonClick();
        break;
      case HeaderMenuItem.Help:
        this._openHelpDialog();
        break;
    }
  }

  private _onDrawingModeButtonClick(): void {
    if (!this._isUserLoggedIn) {
      this._toastService
        .open('로그인 후 이용할 수 있습니다.', '로그인하기')
        .onAction()
        .subscribe(() => {
          this._authService.login(location.href);
        });
      return;
    }

    if (
      !this._pdfDrawingService ||
      !this._pdfDrawingService.isDeviceSupportsStylus
    ) {
      this._toastService.open(
        '북이오 앱에서 펜 기능을 지원하는 기기로 접속한 경우에만 필기 기능을 이용할 수 있습니다.'
      );

      return;
    }

    if (!this._uiStateService.canUseDrawingMode) {
      this._toastService.open(
        `필기를 불러오는데 실패하였습니다. 필기 기능 이용이 제한됩니다.`
      );
      return;
    }

    if (localStorage.getItem(DRAWING_NOTI_LOCALSTORAGE_KEY) == null) {
      this._alertService
        .openConfirm(
          `앱을 삭제하면 필기 데이터도 삭제됩니다.<br/>앱 삭제는 신중히 진행해 주세요.`
        )
        .afterClosed()
        .subscribe(() => {
          localStorage.setItem(DRAWING_NOTI_LOCALSTORAGE_KEY, '');
        });
    }

    this._uiStateService.isDrawingMode = !this._uiStateService.isDrawingMode;
  }

  private _openHelpDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(HelpDialogComponent, {
      data: {
        isPDFBook: this._book.meta.type === BookType.PDF,
      },
    });
  }

  private _openGroupMembersDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(ReadingGroupMembersDialogComponent, {
      data: {
        bid: this._book?.meta.bid,
      },
    });
  }

  private _openThumbnailDialog(): void {
    if (!this._book || !this._address) {
      return;
    }

    this._dialogService.open(ThumbnailDialogComponent, {
      data: {
        book: this._book,
        currentIid: this._address.iid || this._book.items[0].iid,
      },
    });
  }

  private _openSearchDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(SearchDialogComponent, {
      data: {
        book: this._book,
      },
    });
  }

  private _openInboxDialog(): void {
    this._hasNewNote = false;

    this._stopCheckInbox();

    this._dialogService
      .open(NotesDialogComponent)
      .afterClosed()
      .subscribe(() => {
        !this._isCheckingInbox && this._startCheckInbox();
      });
  }

  private _openInNewTab(): void {
    if (
      this._address &&
      this._book?.canReadItem(this._address.iid || this._book.items[0].iid)
    ) {
      window.open(self.location.href);
    } else {
      const url = this._book?.permission.referer?.toString();
      url && window.open(url);
    }
  }

  private _onBookmarkButtonClick(): void {
    if (!this._address || !this._book) {
      return;
    }

    const currentIid = this._address.iid || this._book.items[0].iid;

    if (!this._isUserLoggedIn) {
      this._toastService
        .open('로그인 후 이용할 수 있습니다.', '로그인하기')
        .onAction()
        .subscribe(() => {
          this._authService.login(location.href);
        });
    } else if (currentIid != null && !this._book.canReadItem(currentIid)) {
      this._toastService.openWarning(
        '해당 기능 사용을 위해서는 구매가 필요합니다.'
      );
    } else {
      this._bookmarked = !this._bookmarked;
      this._eventBus.fire('HeaderComponent:menuClick', {
        menu: HeaderMenuItem.Bookmark,
      });
    }
  }
}
