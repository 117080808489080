<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()"
  >본문 검색</sui-dialog-header
>
<div class="search-bar">
  <div class="prefix-icon">
    <sui-icon name="검색"></sui-icon>
  </div>
  <input
    placeholder="검색어를 입력해 주세요."
    [(ngModel)]="_keyword"
    (keyup.enter)="_onSearch()"
    (keydown)="$event.stopPropagation()"
    enterkeyhint="search"
    #input
  />
  <button
    *ngIf="_keyword"
    (click)="_onClearSearchKeywordButtonClick()"
    class="clear-button"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
        fill="#CCCCCC"
      />
    </svg>
  </button>
</div>
<div
  class="result-header"
  *ngIf="!_isLoading && _currSearchValue.length > 0 && _results.length > 0"
>
  <span class="result-count">{{ _resultCount }}</span
  >건의 검색 결과
</div>
<div class="body">
  <ng-container
    *ngIf="!_isLoading && _currSearchValue.length > 0 && _results.length > 0"
  >
    <div class="group" *ngFor="let group of _results; index as i">
      <div
        class="group-title"
        *ngIf="_data.book.meta.type === 'pdf' && group.itemIndex !== undefined"
      >
        {{ group.itemIndex + 1 }} P
      </div>
      <ul class="items">
        <li
          class="item"
          *ngFor="let r of group.items; index as j"
          (click)="_onResultClick(r, [i, j])"
          [innerHtml]="r.title | mark : _currSearchValue : 'keyword'"
        ></li>
      </ul>
    </div>
  </ng-container>
  <div class="placeholder" *ngIf="!_isLoading">
    <div
      class="no-search-result"
      *ngIf="_currSearchValue.length > 0 && _results.length === 0"
    >
      검색 결과가 없습니다.
    </div>
    <div class="no-search-keyword" *ngIf="_currSearchValue.length === 0">
      <sui-icon type="solid" name="확대"></sui-icon>
      <div class="text-container">
        <div class="highlight"></div>
        <div class="text">
          <b>본문 검색</b>으로 원하는 페이지로<br />이동할 수 있어요!
        </div>
      </div>
    </div>
  </div>
  <div class="loader" [class.is-hidden]="!_isLoading">
    <sui-loader></sui-loader>
  </div>
</div>
