<div class="footer">
  <div class="mobile reading-mode" *ngIf="!_isCommentaryEditorMode">
    <button
      class="mode-button"
      (click)="_onMenuItemClick(MenuItem.ReadingMode)"
    >
      <sui-icon name="뷰어 그룹 변경"></sui-icon>모드 변경
    </button>
    <ng-container *ngIf="_groupMemberCount === 0">혼자 읽기 중</ng-container>
    <ng-container *ngIf="_groupMemberCount > 0">
      <button
        class="member-count-button"
        (click)="_onMenuItemClick(MenuItem.GroupMembers)"
      >
        {{ _groupMemberCount }}</button
      ><span class="member-count-label">명의 독자들과 함께 읽는 중</span>
    </ng-container>
  </div>
  <div class="pdf-control">
    <viewer-pdf-page-slider
      class="slider"
      [totalPageCount]="_totalPageCount"
      [currentPage]="_currentPage"
      (pageChange)="_onSliderPageChange($event)"
    ></viewer-pdf-page-slider>
    <div class="page-number">
      <input
        #pageInput
        [value]="_currentPage"
        type="number"
        (blur)="_onPageInputBlur(pageInput.value)"
        (keydown)="
          $event.key === 'Enter' ? pageInput.blur() : $event.stopPropagation()
        "
        (click)="pageInput.select()"
      />
      &nbsp;&nbsp;/&nbsp;&nbsp;{{ _totalPageCount }}
    </div>
  </div>
  <div class="mobile menus">
    <button class="icon-text-button" (click)="_onMenuItemClick(MenuItem.TOC)">
      <div class="icon">
        <sui-icon name="책 정보" type="solid"></sui-icon>
      </div>
      책 정보
    </button>
    <button
      *ngIf="_isPDFMode"
      class="icon-text-button"
      (click)="_onMenuItemClick(MenuItem.Thumbnail)"
    >
      <div class="icon">
        <sui-icon name="페이지 모아보기"></sui-icon>
      </div>
      페이지 목록
    </button>
    <button
      class="icon-text-button"
      (click)="_onMenuItemClick(MenuItem.Settings)"
    >
      <div class="icon">
        <sui-icon name="환경설정"></sui-icon>
      </div>
      뷰어 설정
    </button>
    <button class="icon-text-button" (click)="_onMenuItemClick(MenuItem.Help)">
      <div class="icon">
        <sui-icon name="도움말" type="solid"></sui-icon>
      </div>
      도움말
    </button>
  </div>
</div>
<div class="inset-spacer"></div>
