<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  <ng-container *ngIf="_currentPage === _Page.List"> 쪽지함 </ng-container>
  <ng-container *ngIf="_currentPage === _Page.Send">
    <div class="sender-header">
      <button sui-icon-button (click)="_onBackButtonClick()">
        <sui-icon name="원형 이동_왼쪽"></sui-icon>
      </button>
      <b>'</b>
      <div class="nickname">{{ _sendTargetUserNickname }}</div>
      <b>'</b>
      <div class="title">&nbsp;님에게 쪽지 보내기</div>
    </div>
  </ng-container>
</sui-dialog-header>
<div class="page note-list" [class.is-hidden]="_currentPage !== _Page.List">
  <lib-note-list
    (responseMenuClick)="_onResponseMenuClick($event)"
  ></lib-note-list>
</div>
<div class="page note-send" *ngIf="_currentPage === _Page.Send">
  <lib-note-send
    [targetUserId]="_sendTargetUserId!"
    (noteSent)="_onNoteSent()"
  ></lib-note-send>
</div>
