import {
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  Observable,
  Subject,
  map,
  of,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CdkMenuTrigger } from '@angular/cdk/menu';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { AlertService, DialogService } from 'shared/ui';
import { MyCommentatorInfo } from 'shared/models';
import { CommentatorsAPIService, SharedUserService } from 'shared/services';

import { Commentary } from '../../models/book.model';

import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { UIStateService } from '../../services/ui-state.service';
import { CommentarySubmissionDialogComponent } from '../../dialogs/commentary-submission-dialog/commentary-submission-dialog.component';

const FAB_WIDTH = 56;
const FAB_MARGIN = 24;

const COMMENTARY_TOOLTIP_LOCALSTORAGE_KEY = 'viewer-commentary-tooltip';

@Component({
  selector: 'viewer-annotation-settings-fab',
  templateUrl: './annotation-settings-fab.component.html',
  styleUrls: ['./annotation-settings-fab.component.scss'],
})
export class AnnotationSettingsFabComponent implements OnInit {
  @ViewChild('dragOverlay', { static: true })
  _dragOverlayElem!: ElementRef<HTMLDivElement>;
  @ViewChild(CdkMenuTrigger)
  menuTrigger!: CdkMenuTrigger;
  @ViewChild(CdkDrag) cdkDrag!: CdkDrag;

  public _minOverlappingCount = 2;
  public _showMine = true;
  public _mode: 'group' | 'single' = 'single';

  public _isMenuOpened = false;
  public _isDraggingFab = false;

  public _multiColumnSettingValue = false;
  public _shouldRepositionButton = false;

  public _showCommentarySelect = true;
  public _selectedCommentaryId?: number;

  public _isGroupMemberFocused = false;

  public _commentaries: Commentary[] = [];
  public _hasOwnedCommentary = false;

  public readonly _menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  @HostBinding('style.right.px') right = 24;

  public _hasUpdate = false;

  public _isCommentaryEditorMode = false;

  private _unsubscriber = new Subject<void>();
  private _myCommentatorInfo: MyCommentatorInfo | null = null;
  public _canSubmitCommentary = false;
  private _bid?: string;

  public _showCommentaryTooltip = false;

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private _eventBusService: EventBusService,
    private _annotationsService: AnnotationsV2Service,
    private _alertService: AlertService,
    private _uiStateService: UIStateService,
    private _userService: SharedUserService,
    private _commentatorsAPIService: CommentatorsAPIService,
    private _dialogService: DialogService
  ) {
    this._annotationsService.state$.subscribe((state) => {
      this._mode = state.groupId ? 'group' : 'single';
      this._minOverlappingCount = state.minOverlappingCount;
      this._showMine = state.showMine;
      this._showCommentarySelect = state.commentaryId != null;
      this._selectedCommentaryId = state.commentaryId;
      this._isGroupMemberFocused =
        state.groupId != null && state.memberId != null;

      if (state.bid && state.bid === this._bid) {
        this._updateCommentaryTooltipVisibility();
      }
    });
  }

  ngOnInit(): void {
    this._userService.user
      .pipe(
        takeUntil(this._unsubscriber),
        switchMap((user) => {
          return user ? this._commentatorsAPIService.getMyInfo() : of(null);
        })
      )
      .subscribe((myCommentatorInfo) => {
        this._myCommentatorInfo = myCommentatorInfo;
        this._updateCanSubmitCommentary();
      });

    this._annotationsService.hasUpdate$.subscribe((hasUpdate) => {
      this._hasUpdate = hasUpdate;
    });

    this._eventBusService
      .on('ContentsComponent:contextMenuClickWithoutRequiredSettings')
      .subscribe(() => {
        this.menuTrigger.open();
      });

    this._eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(
        map((event) => event.settings.multiColumn.value),
        startWith(undefined),
        pairwise()
      )
      .subscribe(([oldValue, newValue]) => {
        this._multiColumnSettingValue = newValue!;

        if (oldValue !== newValue) {
          this._shouldRepositionButton = true;
        }
      });

    this._eventBusService
      .on('ContentsComponent:pageTransitionEnd')
      .subscribe(() => {
        if (this._shouldRepositionButton) {
          this._reposition();
          this._shouldRepositionButton = false;
        }
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .subscribe(({ book }) => {
        this._bid = book.meta.bid;
        this._commentaries = book.meta.commentaries;
        this._hasOwnedCommentary = book.meta.commentaries.some((c) => c.owned);
        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;
        this._updateCanSubmitCommentary();
        this._updateCommentaryTooltipVisibility();
      });
  }

  private _updateCommentaryTooltipVisibility(): void {
    if (localStorage.getItem(COMMENTARY_TOOLTIP_LOCALSTORAGE_KEY) == null) {
      this._showCommentaryTooltip =
        this._mode === 'single' &&
        this._hasOwnedCommentary &&
        this._selectedCommentaryId == null;
    } else {
      this._showCommentaryTooltip = false;
    }
  }

  private _updateCanSubmitCommentary(): void {
    if (!this._bid || !this._myCommentatorInfo) {
      this._canSubmitCommentary = false;
      return;
    }

    this._canSubmitCommentary =
      this._myCommentatorInfo.annotation_requested.findIndex(
        (r) => r.bid === this._bid
      ) !== -1;
  }

  _onCommentaryVisibilityChange(): void {
    if (this._showCommentarySelect) {
      this._selectedCommentaryId = undefined;
    } else {
      this._annotationsService.setState({ commentaryId: undefined });
    }
  }

  _onSelectedCommentaryChange(): void {
    const commentary = this._commentaries.find(
      (c) => c.id === this._selectedCommentaryId
    );

    if (!commentary) {
      return;
    }

    let result$: Observable<boolean | undefined>;

    if (commentary.read) {
      result$ = of(true);
    } else {
      result$ = this._alertService
        .openConfirm(
          undefined,
          '코멘터리를 적용하게 되면 환불이 불가합니다. 코멘터리를 적용하시겠습니까?',
          '적용하기'
        )
        .afterClosed();
    }

    result$.subscribe((ok) => {
      if (ok) {
        this._annotationsService.setState({
          commentaryId: this._selectedCommentaryId,
        });

        commentary!.read = 1;
      } else {
        this._selectedCommentaryId =
          this._annotationsService.getState().commentaryId;
      }
    });
  }

  private _getPageWidth(): number {
    const pdfPageWidth = this._doc
      .querySelector('bukv-pdf-page.bukv_is-active .bukv_images')
      ?.getBoundingClientRect().width;

    if (pdfPageWidth) {
      return pdfPageWidth;
    }

    const epubPageWidth =
      window.innerWidth > 900
        ? 900 * (this._multiColumnSettingValue ? 2 : 1)
        : window.innerWidth;

    return epubPageWidth;
  }

  private _reposition(): void {
    const pageWidth = this._getPageWidth();

    const right =
      window.innerWidth -
      (window.innerWidth / 2 + pageWidth / 2 + FAB_MARGIN + FAB_WIDTH);

    this.right = Math.max(right, FAB_MARGIN);

    this.cdkDrag.reset();

    this._shouldRepositionButton = false;
  }

  _onMinOverlappingCountChange(): void {
    this._annotationsService.setState({
      minOverlappingCount: this._minOverlappingCount,
    });
  }

  _onSettingsChange(): void {
    this._annotationsService.setState({
      showMine: this._showMine,
      minOverlappingCount: this._minOverlappingCount,
    });
  }

  _onOpenBookmarkDialogButtonClick(): void {
    this._eventBusService.fire(
      'AnnotationSettingsFabComponent:anotationsButtonClick'
    );
    this.menuTrigger.close();
  }

  _onDragStarted(): void {
    this._dragOverlayElem.nativeElement.style.display = 'block';

    this._isDraggingFab = true;
    this.menuTrigger.close();
  }

  _onDragEnded(): void {
    this._dragOverlayElem.nativeElement.style.display = 'none';

    setTimeout(() => {
      this._isDraggingFab = false;
    }, 300);
  }

  _onSyncMemoButtonClick(): void {
    if (!this._hasUpdate) {
      return;
    }

    this._annotationsService.syncUpdates();
  }

  _onSubmitCommentaryButtonClick(): void {
    this._dialogService
      .open(CommentarySubmissionDialogComponent, {
        data: {
          bid: this._bid!,
          myCommentatorInfo: this._myCommentatorInfo!,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.submitted) {
          this._canSubmitCommentary = false;
        }
      });
  }

  _closeCommentaryTooltip(): void {
    this._showCommentaryTooltip = false;
    localStorage.setItem(COMMENTARY_TOOLTIP_LOCALSTORAGE_KEY, '');
  }

  _onMenuOpened(): void {
    this._isMenuOpened = true;
    this._showCommentaryTooltip && this._closeCommentaryTooltip();
  }
}
