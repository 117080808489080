import { Component } from '@angular/core';
import { TypedDialog } from 'shared/ui';

@Component({
  selector: 'app-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrl: './offline-dialog.component.scss',
})
export class OfflineDialogComponent extends TypedDialog<void, void> {
  public _onRetryButtonClick(): void {
    navigator.onLine && this._dialogRef.close();
  }
}
