import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sui-rounded-tag',
  templateUrl: './rounded-tag.component.html',
  styleUrl: './rounded-tag.component.scss',
})
export class RoundedTagComponent {
  @Input() color: 'gray' | 'point' = 'gray';

  @HostBinding('class') private get _className(): string {
    return `sui-rounded-tag-color-${this.color}`;
  }
}
