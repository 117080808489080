<div class="sui-book__box">
  <div class="sui-book__cover-box">
    <div class="sui-book__cover-container">
      <ng-content select="sui-book-cover, [sui-book-cover]"></ng-content>
      <ng-content select=".sui-book__cover-overlay"></ng-content>
    </div>
    <div class="sui-book__tags">
      <ng-content select="sui-book-tag"></ng-content>
    </div>
    <div class="sui-book__tail-tags">
      <ng-content select="sui-book-tail-tag"></ng-content>
      <svg
        class="sui-book__tail-tags-tail"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16 16L-6.99382e-07 16L0 0C-3.86258e-07 8.83656 7.16344 16 16 16Z"
          fill="white"
        />
      </svg>
    </div>
    <ng-content select=".sui-book__overlay"></ng-content>
  </div>
  <ng-content select="sui-book-title"></ng-content>
  <ng-content select="sui-book-author"></ng-content>
  <ng-content></ng-content>
</div>
