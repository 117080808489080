const IS_TOUCH_DEVICE = matchMedia('(pointer: coarse)').matches;
const IS_ANDROID =
  !!navigator.userAgent.match(/Android/i) ||
  !!navigator.userAgent.match(/SamsungBrowser/i);

export const CrossBrowsing = Object.freeze<{
  keyboardChangesViewport: boolean;
  noTextSelectionHandle: boolean;
  supportsPinchZoom: boolean;
  touchDevice: boolean;
}>({
  // keyboard가 화면을 밀고 올라가는 경우
  keyboardChangesViewport: IS_ANDROID,
  noTextSelectionHandle: !IS_TOUCH_DEVICE,
  supportsPinchZoom: IS_TOUCH_DEVICE,
  touchDevice: IS_TOUCH_DEVICE,
});
