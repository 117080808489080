<sui-dialog-header (closeButtonClick)="_closeDialog()">
  책장 추가하기
</sui-dialog-header>
<section>
  <main>
    <span class="title">책장 이름 입력</span>
    <div class="input-container">
      <input
        type="text"
        placeholder="책장 이름을 입력해 주세요."
        [(ngModel)]="_inputBookshelfValue"
      />
      <span *ngIf="_inputBookshelfValue.length === 0" class="empty"
        >2-20자 이내</span
      >
      <span
        *ngIf="
          _inputBookshelfValue.length >= 2 && _inputBookshelfValue.length <= 20
        "
        class="pass"
        >2-20자 이내 <sui-icon name="체크"></sui-icon
      ></span>
      <span
        *ngIf="
          (_inputBookshelfValue.length < 2 &&
            _inputBookshelfValue.length !== 0) ||
          _inputBookshelfValue.length > 20
        "
        class="fail"
        >2-20자 이내</span
      >
    </div>
  </main>
  <div class="button-container">
    <div class="inner-left">
      <div class="checkbox">
        <span>비공개 책장</span>
        <sui-checkbox
          ><input type="checkbox" (change)="_changeIsPublic($event)"
        /></sui-checkbox>
      </div>
      <p>체크 시, 해당 책장은 본인만 볼 수 있습니다.</p>
    </div>
    <div class="inner-right">
      <button
        *ngIf="
          _inputBookshelfValue.length < 2 || _inputBookshelfValue.length > 20
        "
        sui-solid-button
        size="large"
        class="inactive"
      >
        저장하기
      </button>
      <button
        *ngIf="
          _inputBookshelfValue.length >= 2 && _inputBookshelfValue.length <= 20
        "
        (click)="_onClickSubmit()"
        sui-solid-button
        size="large"
        class="active"
      >
        저장하기
      </button>
    </div>
  </div>
</section>
