<div class="icon">
  <sui-icon name="필터"></sui-icon>
</div>
<div class="filter-list">
  <div class="filter all">
    <div
      class="circle"
      (click)="_onFilterClick(null)"
      [class.is-active]="null === _selectedFilter"
    >
      <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.68555 7.64841V0.25H10.0008V7.64841H8.68555Z" fill="white" />
        <path d="M6.2041 7.64841V0.25H7.51938V7.64841H6.2041Z" fill="white" />
        <path
          d="M2.32934 7.74873C1.88757 7.74873 1.48931 7.63617 1.13455 7.41104C0.779795 7.18592 0.502014 6.87893 0.301208 6.49008C0.100403 6.10123 0 5.66462 0 5.18026C0 4.68908 0.100403 4.24907 0.301208 3.86021C0.502014 3.47136 0.779795 3.16437 1.13455 2.93925C1.48931 2.71412 1.88757 2.60156 2.32934 2.60156C2.67741 2.60156 2.98865 2.67319 3.26309 2.81645C3.53752 2.95972 3.75506 3.16096 3.91571 3.4202C4.08304 3.67261 4.17341 3.95913 4.18679 4.27976V6.0603C4.17341 6.38775 4.08304 6.67768 3.91571 6.9301C3.75506 7.18251 3.53752 7.38376 3.26309 7.53384C2.98865 7.6771 2.67741 7.74873 2.32934 7.74873ZM2.57031 6.51055C2.93845 6.51055 3.23631 6.38775 3.46389 6.14216C3.69147 5.88975 3.80526 5.5657 3.80526 5.17003C3.80526 4.9108 3.75171 4.68226 3.64462 4.48442C3.54422 4.27976 3.40031 4.12286 3.21289 4.01371C3.03216 3.89774 2.81797 3.83975 2.57031 3.83975C2.32934 3.83975 2.11515 3.89774 1.92773 4.01371C1.74701 4.12286 1.6031 4.27976 1.496 4.48442C1.3956 4.68226 1.3454 4.9108 1.3454 5.17003C1.3454 5.43609 1.3956 5.67144 1.496 5.8761C1.6031 6.07394 1.74701 6.23084 1.92773 6.34682C2.11515 6.45597 2.32934 6.51055 2.57031 6.51055ZM3.73498 7.6464V6.31612L3.94583 5.10863L3.73498 3.92161V2.70389H5.04022V7.6464H3.73498Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
  <div class="filter" *ngFor="let filter of _HighlightFilter; index as i">
    <div
      class="circle"
      [class.is-active]="filter === _selectedFilter"
      [attr.style]="_HighlightStyles[i]"
      (click)="_onFilterClick(filter)"
    ></div>
  </div>
</div>
