<div class="profile">
  <ng-container *ngIf="_profile$ | async as profile">
    <div class="avatar">
      <sui-reading-group-member-avatar
        [avatarImageURL]="profile.avatar_image_url"
      ></sui-reading-group-member-avatar>
      <div class="edit-button">
        <button sui-icon-button (click)="_onEditProfileButtonClick()">
          <sui-icon name="profile modify"></sui-icon>
        </button>
      </div>
    </div>
    <div class="info">
      <div class="nickname">{{ profile.nickname }}</div>
      <div class="follow">
        <button class="type" (click)="_onFollowersClick()">
          팔로워 <span class="number">{{ profile.follower_count }}</span>
        </button>
        <button class="type" (click)="_onFollowingsClick()">
          팔로잉 <span class="number">{{ profile.following_count }}</span>
        </button>
      </div>
    </div>
  </ng-container>
  <div class="noti">
    <div
      class="icon-container"
      [class.is-active]="_hasNewMessage"
      (click)="_onMessageButtonClick()"
    >
      <sui-icon type="solid" name="메세지"></sui-icon>
      <div class="dot"></div>
    </div>
    <div
      class="icon-container"
      [class.is-active]="_hasNewNoti"
      (click)="_onNotiButtonClick()"
    >
      <sui-icon type="solid" name="알림"></sui-icon>
      <div class="dot"></div>
    </div>
  </div>
</div>

<div class="user">
  <div class="user-info">
    <div class="name">{{ _userName }}</div>
    <div class="email">{{ _userEmail }}</div>
  </div>
  <button class="logout-button" (click)="_onLogoutButtonClick()">
    로그아웃
  </button>
</div>

<div class="sections">
  <div class="section">
    <div class="section-title">뷰어</div>
    <div class="section-item toggle-button">
      화면을 켜진 상태로 유지
      <sui-slide-toggle theme="blue">
        <input
          type="checkbox"
          [checked]="_settings?.keepScreenOn"
          (change)="
            _onSettingsChange('keepScreenOn', $any($event.target).checked)
          "
        />
      </sui-slide-toggle>
    </div>
  </div>
  <div class="section">
    <div class="section-title">내 필기 기록</div>
    <div class="section-item toggle-button">
      북이오 서버에 자동 백업하기
      <sui-slide-toggle theme="blue">
        <input
          #backupInput
          type="checkbox"
          [checked]="_settings?.backupDrawing"
          (change)="_onBackupDrawingSettingsChange($any($event.target).checked)"
        />
      </sui-slide-toggle>
    </div>
    <div class="section-item" [routerLink]="['drawing']">
      수동 백업/복원하기
    </div>
  </div>
  <div class="section">
    <div class="section-title">정보</div>
    <div class="section-item">
      버전 {{ _appVersion }} / {{ _webAppVersion }}
    </div>
    <div class="section-item" (click)="_onContactButtonClick()">문의하기</div>
    <div class="section-item" (click)="_onDeleteAccountButtonClick()">
      회원탈퇴
    </div>
  </div>
</div>
