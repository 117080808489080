import { Component, OnInit } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelfAPIService } from 'shared/services';
import { TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-delete-bookshelf',
  templateUrl: './delete-bookshelf.component.html',
  styleUrl: './delete-bookshelf.component.scss',
})
export class DeleteBookshelfComponent
  extends TypedDialog<
    { bookshelf_id: string },
    { canceled: 'success' | 'fail' }
  >
  implements OnInit
{
  private _deleteBookshelfSubject = new Subject<void>();

  constructor(private _bookshelfAPIService: BookshelfAPIService) {
    super();
  }

  ngOnInit(): void {
    this._deleteBookshelfSubject
      .pipe(
        switchMap(() => {
          return this._bookshelfAPIService.deleteBookshelf(
            this._data.bookshelf_id
          );
        })
      )
      .subscribe(() => {
        this._dialogRef.close({ canceled: 'success' });
      });
  }

  public _onClickDelete(): void {
    this._deleteBookshelfSubject.next();
  }
}
