<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()"
  >코멘테이터 상세보기</sui-dialog-header
>
<div class="body">
  <div class="user">
    <div class="left">
      <sui-reading-group-member-avatar
        class="avatar"
        [avatarImageURL]="_data.avatarImageURL"
      ></sui-reading-group-member-avatar>
      <span class="nickname">{{ _data.nickname }}</span>
    </div>
  </div>
  <div class="list">
    <!-- 목록 로드 전 -->
    <div class="no-memos placeholder" *ngIf="!_memos">
      <sui-loader></sui-loader>
    </div>
    <!-- 목록 로드 후: 메모 없음 -->
    <div class="no-memos placeholder" *ngIf="_memos && _memos.length === 0">
      이 책에 남긴 공유 메모 기록이 없습니다.
    </div>
    <!-- 목록 로드 후: 메모 있음 -->
    <ng-container *ngIf="_memos && _memos.length > 0">
      <!-- 목록 헤더 -->
      <div class="list-header">
        <div>
          이 책에 남긴 <span class="number">{{ _memos.length }}</span
          >개의 공유 메모 기록이 있습니다.
        </div>
        <select
          sui-select
          [(ngModel)]="_selectedSortType"
          (ngModelChange)="_onSelectSortType()"
        >
          <option *ngFor="let type of _sortTypes" [value]="type">
            {{ type }}
          </option>
        </select>
      </div>
      <!-- 목록: 데스크탑, 태블릿 -->
      <div class="memo-list desktop">
        <div class="memo-list-left">
          <div
            class="memo-list-item"
            *ngFor="let memo of _memos; index as i"
            (click)="_selectedMemoIndex = i"
            [class.is-selected]="_selectedMemoIndex === i"
          >
            <div class="text">{{ memo.text }}</div>
            <div class="content">{{ memo.content }}</div>
          </div>
        </div>
        <div class="memo-list-right">
          <ng-container *ngIf="_memos[_selectedMemoIndex]">
            <ng-container
              *ngTemplateOutlet="
                memoTpl;
                context: {
                  $implicit: _memos[_selectedMemoIndex],
                  isDesktop: true
                }
              "
            ></ng-container>
          </ng-container>
        </div>
      </div>
      <!-- 목록: 폰 -->
      <div class="memo-list mobile">
        <div class="memo-list-item" *ngFor="let memo of _memos; index as i">
          <ng-container
            *ngTemplateOutlet="
              memoTpl;
              context: { $implicit: memo, isDesktop: false }
            "
          ></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #memoTpl let-memo let-isDesktop="isDesktop">
  <div class="memo">
    <div class="memo-text">{{ memo.text }}</div>
    <viewer-memo
      [menuData]="{ memo: memo }"
      [limitHeight]="isDesktop"
      [content]="memo.content"
    >
      <viewer-memo-actions>
        <button class="icon-button" (click)="_onLikeButtonClick(memo)">
          <sui-icon
            name="추천"
            [type]="memo.is_liked ? 'solid' : 'outline'"
          ></sui-icon>
          {{ memo.like_count }}
        </button>
        <button class="text-button" (click)="_onSeeDetailsButtonClick(memo)">
          본문 상세 확인
        </button>
      </viewer-memo-actions>
    </viewer-memo>
  </div>
</ng-template>
