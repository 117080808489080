import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  Component,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

export type MemoType = 'mine' | 'group' | 'commentary';

@Component({
  selector: 'viewer-memo-type',
  template: `
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'mine'">
        <sui-icon name="메모" type="solid"></sui-icon><span>내 메모</span>
      </ng-container>
      <ng-container *ngSwitchCase="'group'">
        <sui-icon name="메모" type="solid"></sui-icon><span>공유 메모</span>
      </ng-container>
      <ng-container *ngSwitchCase="'commentary'">
        <sui-icon name="코멘터리북(채움)" type="solid"></sui-icon
        ><span>코멘테이터 메모</span></ng-container
      >
    </ng-container>
  `,
})
export class MemoTypeComponent {
  @HostBinding('attr.data-type') @Input() type!: MemoType;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'viewer-memo-tag',
})
export class MemoTagDirective {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'viewer-memo-actions',
})
export class MemoActionsDirective {}

@Component({
  selector: 'viewer-memo-author',
  template: `<sui-reading-group-member-avatar
      class="avatar"
      [avatarImageURL]="avatarImageURL"
      [isOnline]="isOnline"
    >
    </sui-reading-group-member-avatar
    ><span>{{ name }}</span>`,
  // encapsulation: ViewEncapsulation.None,
})
export class MemoAuthorComponent {
  @Input() avatarImageURL?: string;
  @Input() name!: string;
  @Input() isOnline?: boolean;
}

@Component({
  selector: 'viewer-memo',
  templateUrl: './memo.component.html',
  styleUrls: ['./memo.component.scss'],
})
export class MemoComponent {
  @Input() menu?: TemplateRef<unknown>;
  @Input() menuData?: unknown;

  @Input() limitHeight = false;

  public _shortContent?: string;
  private _content!: string;

  @Output() contentClick = new EventEmitter<void>();

  public _menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  @Input() set content(value: string) {
    this._content = value;

    let lines = this._content.split('\n');

    if (lines.length > 3) {
      lines = lines.slice(0, 3);
      // lines[lines.length - 1] = lines[lines.length - 1].slice(0, 18 - 6);
      this._shortContent = lines.join('\n').slice(0, 64);
    } else if (this._content.length > 64) {
      this._shortContent = this._content.slice(0, 64);
    } else {
      this._shortContent = undefined;
    }
  }

  get content(): string {
    return this._content;
  }
}
