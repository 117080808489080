/**
 * 주어진 노드가 HTMLElement 인지 확인
 * @param node 확인할 노드
 * @returns boolean
 */
function isHTMLElementNode(node: Node): node is HTMLElement {
  return 'innerText' in node;
}

/**
 * 주어진 노드가 내용이 있는 텍스트 노드인지 확인
 * @param node 확인할 노드
 * @returns boolean
 */
function isContentTextNode(node: Node): boolean {
  return (
    node.nodeType === Node.TEXT_NODE &&
    !!node.textContent &&
    /\S/.test(node.textContent)
  );
}

/**
 * 주어진 노드가 내용이 있는 HTMLElement인지 확인
 * @param node 확인할 노드
 * @returns boolean
 */
function isContentHTMLElement(node: Node): boolean {
  return isHTMLElementNode(node) && /\S/.test(node.innerText);
}

/**
 * 기준 노드의 형제 엘리먼트 또는 텍스트 노드를 가져옴
 * @param node 기준 노드
 * @returns 형제 엘리먼트 또는 텍스트 노드
 */
function getElementOrTextSibling(node: Node): Element | Text | null {
  let currNode: Node | null = node;

  while ((currNode = currNode.nextSibling)) {
    if (isContentHTMLElement(currNode) || isContentTextNode(currNode)) {
      return currNode as Element | Text;
    }
  }

  return null;
}

/**
 * 기준 노드의 depth와 상관 없이 노드의 다음 엘리먼트 또는 텍스트 노드를 가져옴
 * @param node 기준 노드
 * @returns 다음 엘리먼트 또는 텍스트 노드
 */
function getNextElementOrText(node: Node): Element | Text | null {
  let result = getElementOrTextSibling(node);

  if (result) {
    return result;
  }

  let currElement: Element | null = node.parentElement;

  while (currElement) {
    result = getElementOrTextSibling(currElement);

    if (result) {
      break;
    }

    currElement = currElement.parentElement;
  }

  return result;
}

/**
 * 엘리먼트의 첫 번째 텍스트 노드를 가져옴, 단 첫 번째 텍스트 노드 전에 이미지가 있으면 null을 반환
 * @param element 텍스트를 포함하고 있는 엘리먼트
 * @returns 첫 번째 텍스트 노드
 */
function getFirstChildContentTextNode(element: Element): Node | null {
  const iterator = element.ownerDocument.createNodeIterator(
    element,
    NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
    {
      acceptNode: (node) => {
        return node.nodeType === Node.TEXT_NODE || node.nodeName === 'IMG'
          ? NodeFilter.FILTER_ACCEPT
          : NodeFilter.FILTER_REJECT;
      },
    }
  );

  let node: Node | null;

  while ((node = iterator.nextNode())) {
    if (node.nodeName === 'IMG') {
      return null;
    }

    if (isContentTextNode(node)) {
      return node;
    }
  }

  return null;
}

/**
 * range를 이어지는 다음 글자까지 확장
 * @param range 확장하려는 Range
 * @returns 확장 여부
 */
function extendRange(range: Range): boolean {
  const endContainer = range.endContainer;

  // 다음 엘리먼트에서 선택이 끝난 경우
  // endOffset이 1 이상인 경우를 찾지 못했음, 따라서 다음 엘리먼트의 첫 번째 Text 노드의 첫 번째 글자까지 확장
  if (endContainer.nodeType === Node.ELEMENT_NODE) {
    const textNode = getFirstChildContentTextNode(endContainer as Element);
    if (textNode) {
      range.setEnd(textNode, 1);
      return true;
    }
  } else if (endContainer.nodeType === Node.TEXT_NODE) {
    // 텍스트 노드 내부에서 선택이 끝난 경우
    const textContent = endContainer.textContent;
    const nextNonWhitespaceIndex =
      textContent !== null
        ? textContent.substring(range.endOffset).search(/\S/)
        : -1;

    // 텍스트 노드 내부에서 다음 글자까지 확장이 가능한 경우
    if (nextNonWhitespaceIndex !== -1) {
      const newEndOffset = range.endOffset + nextNonWhitespaceIndex + 1;

      if (newEndOffset <= (endContainer as Text).length) {
        range.setEnd(endContainer, newEndOffset);
        return true;
      }
    }
  }

  // 여러 상황에서 다음 글자까지 확장이 불가능한 경우: 다음으로 오는 첫 번째 텍스트 노드의 첫 번째 글자까지 확장
  const next = getNextElementOrText(endContainer);
  const nextText =
    next &&
    (next.nodeType === Node.TEXT_NODE
      ? next
      : getFirstChildContentTextNode(next as Element));

  if (nextText) {
    const firstNonWhitespaceIndex = nextText.textContent?.search(/\S/);
    const endOffset =
      firstNonWhitespaceIndex != null && firstNonWhitespaceIndex > -1
        ? firstNonWhitespaceIndex + 1
        : 1;
    range.setEnd(nextText, endOffset);
    return true;
  }

  return false;
}

/**
 * range가 페이지 끝에 위치했는지 확인
 * @param range
 * @returns 페이지 끝 위치 여부
 */
export function isRangeAtPageBoundary(range: Range): boolean {
  const _range = range.cloneRange();

  extendRange(_range);

  const clientRects = _range.getClientRects();

  const defaultView = range.startContainer.ownerDocument?.defaultView;

  if (!defaultView) {
    return false;
  }

  const maxX = clientRects[clientRects.length - 1].x;
  const pageWidth = defaultView.innerWidth;

  return maxX > pageWidth && maxX < pageWidth * 2;
}

function createRangeFromArea(doc: Document, rect: DOMRect): Range | null {
  // Firefox
  if (typeof (doc as any).caretPositionFromPoint != 'undefined') {
    const startPosition = (doc as any).caretPositionFromPoint(
      rect.left,
      rect.top
    );
    const endPosition = (doc as any).caretPositionFromPoint(
      rect.right,
      rect.bottom
    );

    if (!startPosition || !endPosition) {
      return null;
    }

    try {
      const range = doc.createRange();
      range.setStart(startPosition.offsetNode, startPosition.offset);
      range.setEnd(endPosition.offsetNode, endPosition.offset);

      return range;
    } catch (error) {
      console.log(error);

      return null;
    }
  }

  // Chrome, Safari, Edge
  if (typeof doc.caretRangeFromPoint != 'undefined') {
    const startRange = doc.caretRangeFromPoint(rect.left, rect.top);
    const endRange = doc.caretRangeFromPoint(rect.right, rect.bottom);

    if (!startRange || !endRange) {
      return null;
    }

    try {
      const range = doc.createRange();
      range.setStart(startRange.startContainer, startRange.startOffset);
      range.setEnd(endRange.startContainer, endRange.startOffset);

      return range;
    } catch (error) {
      console.log(error);

      return null;
    }
  }

  return null;
}

/**
 * 페이지 끝에서 한 텍스트 선택이 다음 페이지까지 넘어가지 않도록 조정 (모바일 사파리에서 주로 발생)
 * @param range
 * @returns 조정 여부
 */
export function modifyRangeWithinViewport(range: Range): boolean {
  const defaultView = range.startContainer.ownerDocument?.defaultView;

  if (!defaultView) {
    return false;
  }

  const pageWidth = defaultView.innerWidth;
  const rects = range.getClientRects();
  let lastIndexOfRectInViewport;

  for (let i = 0; i < rects.length; i++) {
    if (rects[i].right < pageWidth) {
      lastIndexOfRectInViewport = i;
    }
  }

  if (
    lastIndexOfRectInViewport != null &&
    lastIndexOfRectInViewport < rects.length - 1
  ) {
    const lastRangeInViewport = createRangeFromArea(
      defaultView.document,
      rects[lastIndexOfRectInViewport]
    );

    if (lastRangeInViewport) {
      range.setEnd(
        lastRangeInViewport.endContainer,
        lastRangeInViewport.endOffset
      );

      return true;
    }
  }

  return false;
}

export function extendSelectionToWord(
  selection: Selection,
  extensionCount: number
): void {
  const originalTextLength = selection.toString().trim().length;
  let remainingRetries = 20 + extensionCount;

  while (extensionCount > 0 && remainingRetries > 0) {
    selection.modify('extend', 'right', 'word');

    if (selection.toString().trim().length > originalTextLength) {
      extensionCount--;
    } else {
      remainingRetries--;
    }
  }
}
