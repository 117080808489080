<div class="container">
  <div class="header">
    <div class="search-bar">
      <div class="prefix-icon">
        <sui-icon name="검색"></sui-icon>
      </div>
      <input
        placeholder="닉네임(2-30자)을 입력해 주세요."
        [formControl]="_searchFormControl"
        (keydown)="_onSearchBarKeyDown($event)"
      />
      <button
        *ngIf="_searchFormControl.value"
        (click)="_onCancelSearchButtonClick()"
        class="clear-button"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
            fill="#CCCCCC"
          />
        </svg>
      </button>
    </div>
  </div>
  <div class="sidenav">
    <ng-container *ngTemplateOutlet="followingAndGroupsListTpl"></ng-container>
  </div>
  <div class="body">
    <ng-container [ngSwitch]="_currentListType">
      <div class="list" *ngSwitchCase="_ListType.followingAndGroups">
        <ng-container
          *ngTemplateOutlet="followingAndGroupsListTpl"
        ></ng-container>
      </div>
      <div class="list" *ngSwitchCase="_ListType.following">
        <ng-container *ngTemplateOutlet="followingListTpl"></ng-container>
      </div>
      <div class="list" *ngSwitchCase="_ListType.groupMembers">
        <ng-container *ngTemplateOutlet="groupMembersListTpl"></ng-container>
      </div>
      <div class="list" *ngSwitchCase="_ListType.searchResults">
        <ng-container *ngTemplateOutlet="searchResultsListTpl"></ng-container>
      </div>
      <div class="list" *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="introTpl"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #introTpl>
  <div class="intro">
    <sui-icon name="확대" type="solid"></sui-icon>
    <div class="text">
      <span><b>닉네임을 검색</b>해<br />그룹원을 초대해 보세요!</span>
    </div>
  </div>
</ng-template>

<ng-template #followingAndGroupsListTpl>
  <ng-container *ngIf="_following && _myGroups">
    <ul class="top-list">
      <li (click)="_onSelectFollowing()" class="list-item">
        <span class="title">내 팔로잉 {{ _totalFollowingCount }}</span>
        <sui-icon name="오른쪽 이동"></sui-icon>
      </li>
      <li *ngIf="_myGroups.length > 1">
        내 그룹
        <ul class="sub-list">
          <li
            *ngFor="let group of _myGroups; index as i"
            (click)="_onSelectGroup(i)"
            class="list-item"
            [class.is-disabled]="
              group.member_count === 1 || group.id === groupId
            "
          >
            <span class="title"
              >{{ group.name }} ({{ group.member_count - 1 }})</span
            >
            <sui-icon name="오른쪽 이동"></sui-icon>
          </li>
        </ul>
      </li>
    </ul>
    <div (libIntersectionListener)="_onFollowingAndGroupsListScrollEnd()"></div>
  </ng-container>
</ng-template>

<ng-template #followingListTpl>
  <div class="list-header">
    <span
      >내 팔로잉
      <b *ngIf="_totalFollowingCount && _totalFollowingCount > 0">{{
        _totalFollowingCount
      }}</b></span
    >
    <button sui-icon-button (click)="_onListBackButtonClick()">
      <sui-icon name="뒤로가기"></sui-icon>
    </button>
  </div>
  <div class="list-body">
    <ul>
      <li *ngFor="let member of _following">
        <ng-container
          *ngTemplateOutlet="memberListItemTpl; context: { member: member }"
        >
        </ng-container>
      </li>
    </ul>
    <div
      *ngIf="_following"
      (libIntersectionListener)="_onFollowingListScrollEnd()"
    ></div>
    <div *ngIf="_following?.length === 0" class="search-placeholder">
      현재 팔로잉은 0입니다.
    </div>
  </div>
</ng-template>

<ng-template #groupMembersListTpl>
  <div class="list-header">
    <span *ngIf="_selectedGroup"
      >{{ _selectedGroup.name }}
      <b>{{ _selectedGroup.member_count - 1 }}</b></span
    >
    <button sui-icon-button (click)="_onListBackButtonClick()">
      <sui-icon name="뒤로가기"></sui-icon>
    </button>
  </div>
  <div class="list-body">
    <ul>
      <li *ngFor="let member of _selectedGroupMembers">
        <ng-container
          *ngTemplateOutlet="memberListItemTpl; context: { member: member }"
        >
        </ng-container>
      </li>
    </ul>
    <div
      *ngIf="_selectedGroupMembers"
      (libIntersectionListener)="_onGroupMembersListScrollEnd()"
    ></div>
  </div>
</ng-template>

<ng-template #searchResultsListTpl>
  <div class="list-header">
    <span>검색 결과</span>
  </div>
  <div class="list-body">
    <ul>
      <li *ngFor="let member of _searchResults">
        <ng-container
          *ngTemplateOutlet="memberListItemTpl; context: { member: member }"
        >
        </ng-container>
      </li>
    </ul>
    <div
      *ngIf="_searchResults"
      (libIntersectionListener)="_onSearchResultScrollEnd()"
    ></div>
    <div *ngIf="_searchResults?.length === 0" class="search-placeholder">
      검색 결과가 없습니다.
    </div>
  </div>
</ng-template>

<ng-template #memberListItemTpl let-member="member">
  <div class="member-list-item">
    <sui-reading-group-member
      [member]="member"
      (click)="_onReadingGroupMemberClick(member)"
    ></sui-reading-group-member>
    <button
      sui-text-icon-button
      (click)="_onInviteButtonClick(member)"
      *ngIf="member.is_inviteable"
    >
      초대하기
      <sui-icon name="화살표_오른쪽"></sui-icon>
    </button>
  </div>
</ng-template>
