<div
  class="avatar"
  [class.is-blocked]="isBlocked"
  [ngStyle]="{
    'background-image':
      !isBlocked && avatarImageURL ? 'url(' + avatarImageURL + ')' : null
  }"
></div>
<svg
  class="block-icon"
  *ngIf="isBlocked"
  width="8"
  height="8"
  viewBox="0 0 8 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <ellipse cx="3.66493" cy="4.3329" rx="3.55556" ry="3.55556" fill="white" />
  <path
    d="M3.66493 0.777344C1.70384 0.777344 0.109375 2.37181 0.109375 4.3329C0.109375 6.29399 1.70384 7.88846 3.66493 7.88846C5.62602 7.88846 7.22049 6.29399 7.22049 4.3329C7.22049 2.37181 5.62602 0.777344 3.66493 0.777344ZM6.59792 4.3329C6.59792 4.99351 6.37656 5.60225 6.00648 6.09338L1.7488 2.11932C2.26415 1.67315 2.93168 1.40337 3.66493 1.40337C5.28015 1.40337 6.59792 2.71768 6.59792 4.33636V4.3329ZM0.735402 4.3329C0.735402 3.67229 0.956759 3.06355 1.32684 2.57241L5.58106 6.54647C5.06571 6.99265 4.39818 7.26243 3.66493 7.26243C2.04971 7.26243 0.731943 5.94812 0.731943 4.32944L0.735402 4.3329Z"
    fill="black"
  />
</svg>

<div class="online-dot" *ngIf="!isBlocked && isOnline === true"></div>
