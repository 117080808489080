<img class="logo" src="assets/images/logo-symbol.svg" />
<svg
  class="loader"
  width="200"
  height="200"
  viewBox="0 0 200 200"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200C155.228 200 200 155.228 200 100C200 99.5827 199.997 99.1661 199.992 98.75H197.992C197.997 99.166 198 99.5827 198 100C198 154.124 154.124 198 100 198C45.8761 198 2 154.124 2 100C2 45.8761 45.8761 2 100 2V0Z"
    fill="#E6E6E6"
  />
</svg>
