import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { ReadingGroup, ReadingGroupWithMyActivityStats } from 'shared/models';

import { EventBusService } from './event-bus.service';
import { AnnotationsV2Service } from './annotations-v2.service';
import { ReadingGroupsAPIService } from 'shared/services';

@Injectable()
export class ReadingGroupsService implements OnDestroy {
  private _unsubscriber = new Subject<void>();

  private _currentGroup$ =
    new BehaviorSubject<ReadingGroupWithMyActivityStats | null>(null);
  public currentGroup$ = this._currentGroup$.asObservable();

  private _myGroups: ReadingGroup[] = [];

  private _groupUpdateTimeoutId?: number;

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _eventBusService: EventBusService,
    private _annotationsService: AnnotationsV2Service
  ) {
    this._annotationsService.state$
      .pipe(
        takeUntil(this._unsubscriber),
        map((state) => state.groupId),
        distinctUntilChanged()
      )
      .subscribe((groupId) => {
        this._onGroupChanged(groupId);
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ book }) => {
        this._loadGroups(book.meta.bid);
      });
  }

  ngOnDestroy(): void {
    if (this._groupUpdateTimeoutId != null) {
      window.clearTimeout(this._groupUpdateTimeoutId);
    }

    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  get currentGroup(): ReadingGroupWithMyActivityStats | null {
    return this._currentGroup$.getValue();
  }

  get myGroups(): ReadingGroup[] {
    return this._myGroups;
  }

  refreshGroupInfo(): void {
    const groupId = this._annotationsService.getState().groupId;

    if (groupId != null) {
      this._onGroupChanged(groupId);
    }
  }

  private _onGroupChanged(id?: number): void {
    if (this._groupUpdateTimeoutId != null) {
      window.clearTimeout(this._groupUpdateTimeoutId);
    }

    this._groupUpdateTimeoutId = undefined;

    if (id) {
      this._readingGroupsAPIService
        .getById(id)
        .subscribe((group: ReadingGroupWithMyActivityStats) => {
          this._currentGroup$.next(group);
        });

      this._groupUpdateTimeoutId = window.setTimeout(
        () => this._onGroupChanged(id),
        10 * 60 * 1000
      );
    } else {
      this._currentGroup$.next(null);
    }
  }

  private _loadGroups(bid: string): void {
    this._readingGroupsAPIService
      .getJoined(0, 100, bid)
      .subscribe((response) => {
        this._myGroups = response.items;
      });
  }
}
