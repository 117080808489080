<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="10" cy="10" r="9" stroke="#E6E6E6" stroke-width="2" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10H18C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2V0Z"
    fill="#999999"
  />
</svg>
