<sui-dialog-header class="header" (closeButtonClick)="onCloseButtonClick()">
  함께읽기 중인 독자 목록
</sui-dialog-header>
<div class="body">
  <div class="left">
    <viewer-reading-group-summary
      [group]="_group"
    ></viewer-reading-group-summary>
  </div>
  <div class="right">
    <div class="mode-toggle">
      <div
        class="circle"
        [class.checked]="_isSelectionMode"
        (click)="_onSelectionModeToggleButtonClick()"
      >
        <sui-icon name="체크"></sui-icon>
      </div>
      <span (click)="_onSelectionModeToggleButtonClick()"
        >그룹원 1명의 하이라이트만 보기</span
      >
    </div>

    <div class="list-header">
      <ng-container *ngIf="_selectedMemberName === undefined">
        그룹원 목록
      </ng-container>
      <ng-container *ngIf="_selectedMemberName !== undefined">
        <b>{{ _selectedMemberName }}</b
        >님의 하이라이트 보는 중입니다.
      </ng-container>
    </div>
    <div class="list" #listElem>
      <div
        class="list-item"
        *ngFor="let member of _members"
        [class.is-selected]="_selectedMemberId === member.id"
      >
        <div class="member">
          <sui-reading-group-member
            [member]="member"
            [isOnline]="member.isOnline || !!member.is_me"
            [class.is-clickable]="!member.is_me"
            (click)="!member.is_me && _onClickAvatar(member)"
          ></sui-reading-group-member>
          <sui-rounded-tag *ngIf="member.is_owner">그룹장</sui-rounded-tag>
        </div>
        <div
          class="member-actions"
          [class.is-hidden]="
            member.is_me || member.is_blocked || !_isSelectionMode
          "
        >
          <button
            class="selection-button"
            (click)="_onSelectMember(member)"
            [class.is-hidden]="_selectedMemberId === member.id"
          >
            선택하기
          </button>
          <button
            class="selection-button cancel"
            (click)="_onDeselectMember()"
            [class.is-hidden]="_selectedMemberId !== member.id"
          >
            선택취소
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
