import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PDFDrawingService } from '../../services/pdf-drawing.service';
import { UIStateService } from '../../services/ui-state.service';

enum Tool {
  Pen1 = 'pen1',
  Pen2 = 'pen2',
  Eraser = 'eraser',
}

interface PenConfig {
  width: number;
  opacity: number;
  color: string;
}

const PEN_COLORS = [
  '#F8A425',
  '#021523',
  '#D9D9E0',
  '#93A9C0',
  '#F5402B',
  '#01B66F',
  '#1D62A5',
  '#F41BEC',
  '#1DC8E2',
  '#844FFB',
];

function alphaToHex(alpha: number): string {
  const alphaValue = Math.round(alpha * 255);
  const hex = alphaValue.toString(16).padStart(2, '0');
  return hex.toUpperCase();
}

@Component({
  selector: 'viewer-drawing-tool-bar',
  templateUrl: './drawing-tool-bar.component.html',
  styleUrl: './drawing-tool-bar.component.scss',
})
export class DrawingToolBarComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-active') isActive = false;

  private unsubscriber = new Subject<void>();

  public _Tool = Tool;
  public _selectedTool?: Tool;
  public _isRulerOn = false;

  public _canUndo = false;
  public _canRedo = false;

  public readonly _colors = PEN_COLORS;

  public _penConfigs: { [pen: string]: PenConfig } = {
    [Tool.Pen1]: {
      width: 2,
      opacity: 1,
      color: PEN_COLORS[1],
    },
    [Tool.Pen2]: {
      width: 4,
      opacity: 0.3,
      color: PEN_COLORS[0],
    },
  };

  public _selectedPenConfig?: PenConfig;

  constructor(
    private _uiStateService: UIStateService,
    @Optional() private _pdfDrawingService?: PDFDrawingService
  ) {}

  ngOnInit(): void {
    this._uiStateService.isDrawingMode$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((isDrawingMode) => {
        this.isActive = isDrawingMode;

        if (this.isActive && !this._selectedTool) {
          this._onSelectTool(Tool.Pen1);
        }
      });

    this._pdfDrawingService?.historyChange$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data) => {
        this._canUndo = data.canUndo;
        this._canRedo = data.canRedo;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  _onSelectTool(tool: Tool): void {
    if (tool === Tool.Pen1 || tool === Tool.Pen2) {
      if (this._selectedTool === tool) {
        if (this._selectedPenConfig) {
          this._selectedPenConfig = undefined;
        } else {
          this._selectedPenConfig = this._penConfigs[tool];
        }
      } else {
        this._selectedPenConfig = undefined;
      }
    } else if (tool === Tool.Eraser) {
      this._isRulerOn = false;
      this._selectedPenConfig = undefined;
    }

    this._selectedTool = tool;
    this._applyTool();
  }

  _toggleRuler(): void {
    this._isRulerOn = !this._isRulerOn;
    this._applyTool();
  }

  _onClearButtonClick(): void {
    this._pdfDrawingService?.clear();
  }

  _onCloseButtonClick(): void {
    this._uiStateService.isDrawingMode = false;
  }

  _onPenColorClick(color: string): void {
    this._selectedPenConfig!.color = color;
    this._onPenConfigChanged();
  }

  _onPenConfigChanged(): void {
    if (!this._selectedTool) {
      return;
    }

    this._applyTool();
  }

  private _applyTool(): void {
    if (this._selectedTool === Tool.Eraser) {
      this._pdfDrawingService?.setTool('eraser');
    } else if (
      this._selectedTool === Tool.Pen1 ||
      this._selectedTool === Tool.Pen2
    ) {
      this._pdfDrawingService?.setTool('pen', {
        width: this._penConfigs[this._selectedTool].width,
        color:
          this._penConfigs[this._selectedTool].color +
          alphaToHex(this._penConfigs[this._selectedTool].opacity),
        straight: this._isRulerOn,
      });
    }
  }

  _onUndoButtonClick(): void {
    this._pdfDrawingService?.undo();
  }

  _onRedoButtonClick(): void {
    this._pdfDrawingService?.redo();
  }
}
