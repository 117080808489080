<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()"
  >독자 상세보기</sui-dialog-header
>
<div class="body">
  <div class="user">
    <div class="left">
      <sui-reading-group-member-avatar
        class="avatar"
        [avatarImageURL]="_profile?.avatar_image_url"
        [isBlocked]="_isUserBlocked"
        [isOnline]="_isUserOnline"
      ></sui-reading-group-member-avatar>
      <span class="nickname">{{ _profile?.nickname }}</span>
    </div>
    <div class="right">
      <div
        class="text"
        [class.is-clickable]="_profile?.follower_count"
        (click)="_onShowFollowersButtonClick()"
      >
        팔로워 <span class="number">{{ _profile?.follower_count }}</span>
      </div>
      <div
        class="text"
        [class.is-clickable]="_profile?.following_count"
        (click)="_onShowFollowingsButtonClick()"
      >
        팔로잉 <span class="number">{{ _profile?.following_count }}</span>
      </div>
      <button
        class="more-button"
        sui-icon-button
        [cdkMenuTriggerFor]="moreMenu"
        [cdkMenuPosition]="_moreMenuPosition"
      >
        <sui-icon name="더보기"></sui-icon>
      </button>
    </div>
  </div>
  <div class="list">
    <!-- 차단된 그룹원 -->
    <div class="blocked placeholder" *ngIf="_isUserBlocked">
      차단된 그룹원입니다.
    </div>
    <!-- 차단 안된 그룹원 -->
    <ng-container *ngIf="!_isUserBlocked">
      <!-- 목록 로드 전 -->
      <div class="no-memos placeholder" *ngIf="!_memos">
        <sui-loader></sui-loader>
      </div>
      <!-- 목록 로드 후: 메모 없음 -->
      <div class="no-memos placeholder" *ngIf="_memos && _memos.length === 0">
        이 책에 남긴 공유 메모 기록이 없습니다.
      </div>
      <!-- 목록 로드 후: 메모 있음 -->
      <ng-container *ngIf="_memos && _memos.length > 0">
        <!-- 목록 헤더 -->
        <div class="list-header">
          <div>
            이 책에 남긴 <span class="number">{{ _memos.length }}</span
            >개의 공유 메모 기록이 있습니다.
          </div>
          <select
            sui-select
            [(ngModel)]="_selectedSortType"
            (ngModelChange)="_onSelectSortType()"
          >
            <option *ngFor="let type of _sortTypes" [value]="type">
              {{ type }}
            </option>
          </select>
        </div>
        <!-- 목록: 데스크탑, 태블릿 -->
        <div class="memo-list desktop">
          <div class="memo-list-left">
            <div
              class="memo-list-item"
              *ngFor="let memo of _memos; index as i"
              (click)="_selectedMemoIndex = i; _onMemoRead(memo)"
              [class.is-selected]="_selectedMemoIndex === i"
            >
              <div class="text">{{ memo.text }}</div>
              <div class="content">{{ memo.content }}</div>
            </div>
          </div>
          <div class="memo-list-right">
            <ng-container *ngIf="_memos[_selectedMemoIndex]">
              <ng-container
                *ngTemplateOutlet="
                  memoTpl;
                  context: {
                    $implicit: _memos[_selectedMemoIndex],
                    isDesktop: true
                  }
                "
              ></ng-container>
            </ng-container>
          </div>
        </div>
        <!-- 목록: 폰 -->
        <div class="memo-list mobile" suiListIntersectionObserver>
          <div
            class="memo-list-item"
            *ngFor="let memo of _memos; index as i"
            suiListItemIntersectionObserver
            (intersected)="_onMemoRead(memo)"
            [stopAfterIntersection]="true"
          >
            <ng-container
              *ngTemplateOutlet="
                memoTpl;
                context: { $implicit: memo, isDesktop: false }
              "
            ></ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="actions" *ngIf="!_isUserBlocked">
    <button
      sui-box-button
      varSize="medium40::medium54"
      class="follow-button"
      *ngIf="!_profile?.is_following"
      (click)="_onToggleFollowButtonClick()"
    >
      팔로우
    </button>
    <button
      sui-sub-button
      varSize="medium40::medium54"
      *ngIf="_profile?.is_following"
      (click)="_onToggleFollowButtonClick()"
    >
      언팔로우
    </button>
    <button
      varSize="medium40::medium54"
      sui-solid-button
      (click)="_onSendNoteButtonClick()"
    >
      쪽지
    </button>
  </div>
</div>

<ng-template #moreMenu>
  <sui-menu cdkMenu>
    <button
      sui-menu-item
      cdkMenuItem
      *ngIf="!_isUserBlocked"
      (click)="_onBlockUserButtonClick()"
    >
      차단하기
    </button>
    <button
      sui-menu-item
      cdkMenuItem
      *ngIf="_isUserBlocked"
      (click)="_onUnblockUserButtonClick()"
    >
      차단 해제하기
    </button>
  </sui-menu>
</ng-template>

<ng-template #memoTpl let-memo let-isDesktop="isDesktop">
  <div class="memo">
    <div class="memo-text">{{ memo.text }}</div>
    <viewer-memo
      [menu]="othersMenu"
      [menuData]="{ memo: memo }"
      [limitHeight]="isDesktop"
      [content]="memo.content"
    >
      <viewer-memo-actions>
        <button class="icon-button" (click)="_onLikeButtonClick(memo)">
          <sui-icon
            name="추천"
            [type]="memo.is_liked ? 'solid' : 'outline'"
          ></sui-icon>
          {{ memo.like_count }}
        </button>
        <button
          class="icon-button"
          (click)="_onCopyToSingleModeButtonClick(memo)"
        >
          <sui-icon name="복사" type="solid"></sui-icon>
          {{ memo.copy_count }}
        </button>
        <button class="text-button" (click)="_onSeeDetailsButtonClick(memo)">
          본문 상세 확인
        </button>
      </viewer-memo-actions>
    </viewer-memo>
  </div>
</ng-template>

<ng-template #othersMenu let-memo="memo">
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onReportMenuClick(memo)">
      메모/회원 신고하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onBlindMenuClick(memo)">
      메모 차단하기
    </button>
  </sui-menu>
</ng-template>
