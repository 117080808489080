import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import {
  mergeMap,
  takeUntil,
  switchMap,
  first,
  map,
  scan,
} from 'rxjs/operators';
import { clamp, roundTo } from 'shared/utils';
import { CrossBrowsing } from '../../constants/cross-browsing';

function getClientXFromMouseOrTouchEvent(
  event: MouseEvent | TouchEvent
): number {
  return event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
}

interface DragState {
  elementRect: DOMRect;
  clientX: number;
}

export abstract class PageSliderBaseComponent {
  protected abstract _barContainerElement: ElementRef<HTMLDivElement>;

  protected _unsubscriber = new Subject<void>();

  public _value = 0; // 0-1

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  protected _setEventListeners(): void {
    const mousedown$ = fromEvent<MouseEvent | TouchEvent>(
      this._barContainerElement.nativeElement,
      CrossBrowsing.touchDevice ? 'touchstart' : 'mousedown'
    );

    const mousemove$ = fromEvent<MouseEvent | TouchEvent>(
      this._barContainerElement.nativeElement,
      CrossBrowsing.touchDevice ? 'touchmove' : 'mousemove'
    );

    const mouseup$ = fromEvent<MouseEvent | TouchEvent>(
      window,
      CrossBrowsing.touchDevice ? 'touchend' : 'mouseup'
    );

    const dragstart$ = mousedown$.pipe(
      map((event) => {
        return (state: DragState): DragState =>
          Object.assign({}, state, {
            elementRect: (
              event.currentTarget as HTMLElement
            ).getBoundingClientRect(),
            clientX: getClientXFromMouseOrTouchEvent(event),
          });
      })
    );

    const dragging$ = mousedown$.pipe(
      mergeMap(() => {
        return mousemove$.pipe(
          takeUntil(mouseup$),
          map((event) => {
            return (state: DragState): DragState =>
              Object.assign({}, state, {
                clientX: getClientXFromMouseOrTouchEvent(event),
              });
          })
        );
      })
    );

    merge(dragstart$, dragging$)
      .pipe(
        scan((state, changeFn) => changeFn(state), {
          elementRect: {} as any,
          clientX: 0,
        }),
        map((state) => {
          const offsetX = state.clientX - state.elementRect.left;
          return roundTo(4, offsetX / state.elementRect.width);
        }),
        takeUntil(this._unsubscriber)
      )
      .subscribe((newPercent) => {
        this._onDrag(newPercent);
      });

    // 실제로 움직임이 있는 drag start
    mousedown$
      .pipe(
        switchMap(() => mousemove$.pipe(first(), takeUntil(mouseup$))),
        takeUntil(this._unsubscriber)
      )
      .subscribe(() => {
        this._onDragStart();
      });

    mousedown$
      .pipe(
        switchMap(() => mouseup$.pipe(first())),
        takeUntil(this._unsubscriber)
      )
      .subscribe((event) => {
        this._onDragEnd(this._value);
      });
  }

  protected abstract _onDragStart(): void;

  protected _onDrag(newPercent: number): void {
    this._value = clamp(0, newPercent, 1);
    this._changeDetectorRef.detectChanges();
  }

  protected abstract _onDragEnd(newPercent: number): void;
}
