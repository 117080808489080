import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-reading-group-form-field',
  templateUrl: './reading-group-form-field.component.html',
  styleUrl: './reading-group-form-field.component.scss',
})
export class ReadingGroupFormFieldComponent {
  @Input() label = '';
  @Input() required = false;
}
