import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ReadingGroup } from 'shared/models';

import { QueryResult } from './query-result';

@Injectable({
  providedIn: 'root',
})
export class BookReadingGroupsAPIService {
  constructor(private _http: HttpClient) {}

  get(
    bid: string,
    offset: number = 0,
    limit: number = 100,
    q: string = '',
    order_by:
      | 'member_count'
      | 'highlight_count'
      | 'memo_count' = 'member_count',
    public_only: boolean = false
  ): Observable<
    QueryResult<ReadingGroup> & { highlight_count: number; memo_count: number }
  > {
    return this._http.get<
      QueryResult<ReadingGroup> & {
        highlight_count: number;
        memo_count: number;
      }
    >(`/api/v1/books/${bid}/groups`, {
      params: {
        offset,
        limit,
        q,
        order_by,
        public_only: public_only ? 1 : 0,
      },
      withCredentials: true,
    });
  }
}
