import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { User } from 'shared/models';

export abstract class SharedUserService {
  abstract readonly user: Observable<User | null>;
  abstract getUser(): User | null;
}

@Injectable()
export class FakeUserService extends SharedUserService {
  readonly user = new BehaviorSubject<User | null>(null);

  override getUser(): User | null {
    return null;
  }
}
