import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef,
} from '@angular/cdk/overlay';
import {
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
} from '@angular/core';
import { ToastComponent } from './toast.component';
import { ToastContainerComponent } from './toast-container.component';
import { SUI_TOAST_DATA, ToastConfig } from './toast-config';
import { ToastRef } from './toast-ref';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';

export type ToastType = 'info' | 'warning';

/**
 * Service to dispatch Material Design snack bar messages.
 */
@Injectable({ providedIn: 'root' })
export class ToastService implements OnDestroy {
  /**
   * Reference to the current snack bar in the view *at this level* (in the Angular injector tree).
   * If there is a parent snack-bar service, all operations should delegate to that parent
   * via `_openedSnackBarRef`.
   */
  private _snackBarRefAtThisLevel: ToastRef<any> | null = null;

  /** The component that should be rendered as the snack bar's simple component. */
  simpleSnackBarComponent = ToastComponent;

  /** The container component that attaches the provided template or component. */
  snackBarContainerComponent = ToastContainerComponent;

  /** The CSS class to apply for handset mode. */
  // handsetCssClass = 'mat-mdc-snack-bar-handset';

  /** Reference to the currently opened snackbar at *any* level. */
  get _openedSnackBarRef(): ToastRef<any> | null {
    const parent = this._parentSnackBar;
    return parent ? parent._openedSnackBarRef : this._snackBarRefAtThisLevel;
  }

  set _openedSnackBarRef(value: ToastRef<any> | null) {
    if (this._parentSnackBar) {
      this._parentSnackBar._openedSnackBarRef = value;
    } else {
      this._snackBarRefAtThisLevel = value;
    }
  }

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    @Optional() @SkipSelf() private _parentSnackBar: ToastService
  ) {}

  /**
   * Creates and dispatches a snack bar with a custom component for the content, removing any
   * currently opened snack bars.
   *
   * @param component Component to be instantiated.
   * @param config Extra configuration for the snack bar.
   */
  openFromComponent<T, D = any>(
    component: ComponentType<T>,
    config?: ToastConfig<D>
  ): ToastRef<T> {
    return this._attach(component, config) as ToastRef<T>;
  }

  /**
   * Creates and dispatches a snack bar with a custom template for the content, removing any
   * currently opened snack bars.
   *
   * @param template Template to be instantiated.
   * @param config Extra configuration for the snack bar.
   */
  // openFromTemplate(
  //   template: TemplateRef<any>,
  //   config?: MatSnackBarConfig
  // ): MatSnackBarRef<EmbeddedViewRef<any>> {
  //   return this._attach(template, config);
  // }

  /**
   * Opens a snackbar with a message and an optional action.
   * @param message The message to show in the snackbar.
   * @param action The label for the snackbar action.
   * @param config Additional configuration options for the snackbar.
   */
  open(
    message: string,
    actions?: string[] | string,
    config?: ToastConfig
  ): ToastRef<ToastComponent> {
    const _config = { ...config };

    if (typeof actions === 'string') {
      actions = [actions];
    }

    // Since the user doesn't have access to the component, we can
    // override the data to pass in our own message and action.
    _config.data = { message, actions, type: 'info' };

    return this.openFromComponent(this.simpleSnackBarComponent, _config);
  }

  openWarning(
    message: string,
    actions?: string[] | string,
    config?: ToastConfig
  ): ToastRef<ToastComponent> {
    const _config = { ...config };

    if (typeof actions === 'string') {
      actions = [actions];
    }

    // Since the user doesn't have access to the component, we can
    // override the data to pass in our own message and action.
    _config.data = { message, actions, type: 'warning' };

    return this.openFromComponent(this.simpleSnackBarComponent, _config);
  }

  /**
   * Dismisses the currently-visible snack bar.
   */
  dismiss(): void {
    if (this._openedSnackBarRef) {
      this._openedSnackBarRef.dismiss();
    }
  }

  ngOnDestroy(): void {
    // Only dismiss the snack bar at the current level on destroy.
    if (this._snackBarRefAtThisLevel) {
      this._snackBarRefAtThisLevel.dismiss();
    }
  }

  /**
   * Attaches the snack bar container component to the overlay.
   */
  private _attachSnackBarContainer(
    overlayRef: OverlayRef,
    config: ToastConfig
  ): ToastContainerComponent {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: ToastConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      this.snackBarContainerComponent,
      config.viewContainerRef,
      injector
    );
    const containerRef: ComponentRef<ToastContainerComponent> =
      overlayRef.attach(containerPortal);
    containerRef.instance.snackBarConfig = config;
    return containerRef.instance;
  }

  /**
   * Places a new component or a template as the content of the snack bar container.
   */
  private _attach<T>(
    content: ComponentType<T> | TemplateRef<T>,
    userConfig?: ToastConfig
  ): ToastRef<T | EmbeddedViewRef<any>> {
    const config = {
      ...new ToastConfig(),
      ...userConfig,
    };
    const overlayRef = this._createOverlay(config);
    const container = this._attachSnackBarContainer(overlayRef, config);
    const snackBarRef = new ToastRef<T | EmbeddedViewRef<any>>(
      container,
      overlayRef
    );

    if (content instanceof TemplateRef) {
      const portal = new TemplatePortal(content, null!, {
        $implicit: config.data,
        snackBarRef,
      } as any);

      snackBarRef.instance = container.attachTemplatePortal(portal);
    } else {
      const injector = this._createInjector(config, snackBarRef);
      const portal = new ComponentPortal(content, undefined, injector);
      const contentRef = container.attachComponentPortal<T>(portal);

      // We can't pass this via the injector, because the injector is created earlier.
      snackBarRef.instance = contentRef.instance;
    }

    this._animateSnackBar(snackBarRef, config);
    this._openedSnackBarRef = snackBarRef;
    return this._openedSnackBarRef;
  }

  /** Animates the old snack bar out and the new one in. */
  private _animateSnackBar(
    snackBarRef: ToastRef<any>,
    config: ToastConfig
  ): void {
    // When the snackbar is dismissed, clear the reference to it.
    snackBarRef.afterDismissed().subscribe(() => {
      // Clear the snackbar ref if it hasn't already been replaced by a newer snackbar.
      // eslint-disable-next-line eqeqeq
      if (this._openedSnackBarRef == snackBarRef) {
        this._openedSnackBarRef = null;
      }
    });

    if (this._openedSnackBarRef) {
      // If a snack bar is already in view, dismiss it and enter the
      // new snack bar after exit animation is complete.
      this._openedSnackBarRef.afterDismissed().subscribe(() => {
        snackBarRef.containerInstance.enter();
      });
      this._openedSnackBarRef.dismiss();
    } else {
      // If no snack bar is in view, enter the new snack bar.
      snackBarRef.containerInstance.enter();
    }

    // If a dismiss timeout is provided, set up dismiss based on after the snackbar is opened.
    if (config.duration && config.duration > 0) {
      snackBarRef
        .afterOpened()
        .subscribe(() => snackBarRef._dismissAfter(config.duration!));
    }
  }

  /**
   * Creates a new overlay and places it in the correct location.
   * @param config The user-specified snack bar config.
   */
  private _createOverlay(config: ToastConfig): OverlayRef {
    const overlayConfig = new OverlayConfig();

    overlayConfig.positionStrategy = this._overlay
      .position()
      .global()
      .top(window.innerWidth < 1200 ? '55px' : '73px')
      .centerHorizontally();

    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an injector to be used inside of a snack bar component.
   * @param config Config that was used to create the snack bar.
   * @param snackBarRef Reference to the snack bar.
   */
  private _createInjector<T>(
    config: ToastConfig,
    snackBarRef: ToastRef<T>
  ): Injector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    return Injector.create({
      parent: userInjector || this._injector,
      providers: [
        { provide: ToastRef, useValue: snackBarRef },
        { provide: SUI_TOAST_DATA, useValue: config.data },
      ],
    });
  }
}
