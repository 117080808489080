<sui-dialog-header (closeButtonClick)="_closeDialog()">
  팔로잉
</sui-dialog-header>
<div class="body">
  <ul>
    <li *ngFor="let follower of _followersList; index as i">
      <div class="profile" (click)="_navigateOtherUsers(follower.id)">
        <sui-reading-group-member-avatar
          class="profile-container"
          [avatarImageURL]="follower.avatar_image_url"
          [isBlocked]="follower.is_blocked"
        ></sui-reading-group-member-avatar>
        <span>{{ follower.nickname }}</span>
      </div>
      <ng-container *ngIf="!follower.is_me && !follower.is_blocked">
        <div
          class="button unfollow"
          *ngIf="follower.is_follow"
          (click)="_onClickUnFollowButton(follower)"
        >
          언팔로우
        </div>
        <div
          class="button follow"
          *ngIf="!follower.is_follow"
          (click)="_onClickFollowButton(follower)"
        >
          팔로우
        </div>
      </ng-container>
    </li>
  </ul>
  <div
    *ngIf="_followersList"
    (suiIntersectionListener)="_onFollowerListScrollEnd()"
  ></div>
  <div class="placeholder" *ngIf="_followersList?.length === 0">
    팔로잉 목록이 없습니다.
  </div>
</div>
