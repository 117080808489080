<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()"
  >페이지 목록</sui-dialog-header
>
<cdk-virtual-scroll-viewport
  customVirtualScrollStrategy
  [itemHeights]="_thumbnailRowHeights"
  class="body"
>
  <div
    class="thumbnail-row"
    *cdkVirtualFor="let thumbnailViewRow of _thumbnailViewRows; index as i"
    [style.margin-left.px]="_horizontalRowMargin"
    [style.margin-right.px]="_horizontalRowMargin"
  >
    <div
      class="thumbnail-wrap"
      [style.width.%]="100 / _itemCountPerRow"
      [style.padding.px]="_pagePadding"
      *ngFor="let thumbnail of thumbnailViewRow; index as j"
    >
      <div
        class="thumbnail"
        (click)="_onThumbnailClick([i, j])"
        [class.is-locked]="!thumbnail.shared && thumbnail.locked"
        [class.is-shared]="thumbnail.shared"
        [class.is-selected]="
          _currentItemIndexPath[0] === i && _currentItemIndexPath[1] === j
        "
      >
        <div [style.padding-top.%]="thumbnail.ratio"></div>
        <img class="image" [src]="thumbnail.thumbnail!" />
        <div class="circle locked">
          <sui-icon class="icon" name="잠금" type="solid"></sui-icon>
        </div>
        <div class="page-number">{{ i * _itemCountPerRow + j + 1 }}</div>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
