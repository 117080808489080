import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CdkMenuModule } from '@angular/cdk/menu';

import { SharedUIModule } from 'shared/ui';

// Components
import { NoteSendComponent } from './components/note-send/note-send.component';
import { FollowerComponent } from './follower/follower.component';
import { FollowingComponent } from './following/following.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';

// Dialogs
import { NotesDialogComponent } from './dialogs/notes-dialog/notes-dialog.component';
import { NoteSendDialogComponent } from './dialogs/note-send-dialog/note-send-dialog.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { RouterLink } from '@angular/router';
import { SurveyDialogComponent } from './dialogs/survey-dialog/survey-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SharedUIModule,
    FormsModule,
    CdkMenuModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  declarations: [
    // Components
    NoteSendComponent,
    FollowerComponent,
    FollowingComponent,
    EditProfileComponent,
    // Dialogs
    NotesDialogComponent,
    NoteSendDialogComponent,
    NoteListComponent,
    NotificationDialogComponent,
    SurveyDialogComponent,
  ],
  exports: [
    // Components
    NoteSendComponent,
    FollowerComponent,
    FollowingComponent,
    EditProfileComponent,
    // Dialogs
    NotesDialogComponent,
    NoteSendDialogComponent,
    SurveyDialogComponent,
  ],
})
export class UserModule {}
