import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { HighlightStyle, Settings, Theme } from '@bukio/viewer';

import { ToastService, TypedDialog } from 'shared/ui';
import { clamp } from 'shared/utils';

import { HighlightColor } from '../../constants/highlight-colors';

import { MiscSettings } from '../../models/misc-settings';
import { StorageService } from '../../services/storage.service';

import { USER_HIGHLIGHT_STYLES } from '../../components/contents/contents.component';
import { EventBusService } from '../../services/event-bus.service';
import { UIStateService } from '../../services/ui-state.service';

type HighlightColorView = HighlightStyle & { isSelected: boolean };

@Component({
  selector: 'viewer-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent
  extends TypedDialog<
    {
      forceDisableSettingsKeys?: (keyof Settings)[];
      currentSettings: Settings;
      availableFonts: string[];
      onChange?: (property: keyof Settings, value: unknown) => void;
    },
    void
  >
  implements OnInit, OnDestroy
{
  private _unsubscriber = new Subject<void>();

  public _availableFonts: {
    fontFamily: string;
    displayName: string;
  }[] = [];

  public _highlightColors: HighlightColorView[] = [];

  private _miscSettings!: MiscSettings;

  public _themes = [Theme.Light, Theme.Sepia, Theme.Dark];

  public _currentSettings: Settings;

  public _isDrawingMode = false;

  constructor(
    private _storageService: StorageService,
    private _eventBusService: EventBusService,
    private _uiStateService: UIStateService,
    private _toastService: ToastService
  ) {
    super();

    this._currentSettings = this._data.currentSettings;
  }

  ngOnInit(): void {
    this._eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((event) => {
        this._currentSettings = event.settings;
      });

    this._uiStateService.isDrawingMode$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((isDrawingMode) => {
        this._isDrawingMode = isDrawingMode;
      });

    this._miscSettings = this._storageService.loadMiscSettings();

    this._highlightColors = USER_HIGHLIGHT_STYLES.map((style) => ({
      ...style,
      isSelected:
        this._miscSettings.highlightColors.indexOf(
          style.styleClass as HighlightColor
        ) !== -1,
    }));

    this._availableFonts = this._data.availableFonts.map((fontFamily) => {
      return {
        fontFamily,
        displayName:
          {
            'Nanum Gothic': '나눔고딕',
            'Nanum Myeongjo': '나눔명조',
            'KoPub Batang': 'KoPub 바탕',
          }[fontFamily] ?? fontFamily,
      };
    });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onFontSizeButtonClick(value: -1 | 0 | 1): void {
    let newValue;

    if (value === 0) {
      newValue = 100;
    } else {
      const currValue = this._currentSettings.fontSize.value;
      newValue = clamp(50, currValue + 10 * value, 200);
    }

    this._onChangeSetting('fontSize', newValue);
  }

  _onChangeSetting(property: keyof Settings, value: any): void {
    this._data.onChange?.(property, value);
  }

  _onHighlightColorClick(color: HighlightColorView): void {
    if (color.isSelected && this._miscSettings.highlightColors.length === 3) {
      // 최소 선택 개수 미만
      return;
    }

    if (!color.isSelected && this._miscSettings.highlightColors.length === 5) {
      // 최대 선택 개수 초과
      return;
    }

    color.isSelected = !color.isSelected;

    this._onChangeMiscSetting(
      'highlightColors',
      this._highlightColors.reduce((result, color) => {
        if (color.isSelected) {
          result.push(color.styleClass as HighlightColor);
        }
        return result;
      }, [] as HighlightColor[])
    );
  }

  _onChangeMultiColumnSetting(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (this._isDrawingMode && target.checked) {
      this._toastService.openWarning(
        '필기 시에는 한 페이지 보기만 가능합니다.'
      );
      target.checked = false;
      return;
    }

    this._onChangeSetting('multiColumn', target.checked);
  }

  _onChangeMiscSetting(property: keyof MiscSettings, value: any): void {
    this._miscSettings[property] = value;
    this._storageService.saveMiscSettings(this._miscSettings);
  }

  _isForceDisabled(key: keyof Settings): boolean {
    return (
      !!this._data.forceDisableSettingsKeys &&
      this._data.forceDisableSettingsKeys.indexOf(key) !== -1
    );
  }

  _onCloseClick(): void {
    this._dialogRef.close();
  }
}
