import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';

import { TypedDialog } from 'shared/ui';

import { Memo } from 'shared/models';
import { AnnotationsAPIService } from 'shared/services';

import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { EventBusService } from '../../services/event-bus.service';

enum SortType {
  date = '최신 순',
  likes = '추천 많은 순',
  position = '목차 순',
}

function sortByDate(memo1: Memo, memo2: Memo): number {
  return (
    new Date(memo2.created_at).getTime() - new Date(memo1.created_at).getTime()
  );
}

function sortByLikes(memo1: Memo, memo2: Memo): number {
  return memo2.like_count - memo1.like_count;
}

function sortByPosition(memo1: Memo, memo2: Memo): number {
  return memo1.position - memo2.position;
}

@Component({
  selector: 'viewer-commentator-details-dialog',
  templateUrl: './commentator-details-dialog.component.html',
  styleUrl: './commentator-details-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class CommentatorDetailsDialogComponent
  extends TypedDialog<
    {
      bid: string;
      avatarImageURL: string;
      nickname: string;
      commentaryId: number;
    },
    { seeDetailsButtonClicked?: boolean }
  >
  implements OnInit
{
  public _memos?: Memo[];
  public _selectedSortType = SortType.date;
  public readonly _sortTypes = [
    SortType.date,
    SortType.likes,
    SortType.position,
  ];
  public _selectedMemoIndex = 0;

  constructor(
    private _annotationsAPIService: AnnotationsAPIService,
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService
  ) {
    super();
  }

  ngOnInit(): void {
    this._loadMemoList();
  }

  private _loadMemoList(): void {
    this._annotationsAPIService
      .get(this._data.bid, [], {
        commentary_id: this._data.commentaryId,
        filter: 'theirs',
      })
      .pipe(
        map((response) =>
          response.items.flatMap((itemAnnotations) => itemAnnotations.memos)
        )
      )
      .subscribe((memos) => {
        this._memos = memos;
        this._sort();
      });
  }

  _onLikeButtonClick(memo: Memo): void {
    const delta = memo.is_liked === 1 ? -1 : 1;

    memo.like_count += delta;
    memo.is_liked = memo.is_liked ? 0 : 1;

    const request$ =
      delta > 0
        ? this._annotationsService.likeMemo(memo.id)
        : this._annotationsService.unlikeMemo(memo.id);

    request$.subscribe({
      error: () => {
        memo.is_liked = memo.is_liked ? 0 : 1;
        memo.like_count -= delta;
      },
    });
  }

  _onSeeDetailsButtonClick(memo: Memo): void {
    this._eventBusService.fire(
      'CommentatorDetailsDialogComponent:seeDetailsButtonClick',
      {
        url: memo.url,
      }
    );

    this._dialogRef.close({
      seeDetailsButtonClicked: true,
    });
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  _onSelectSortType(): void {
    this._sort();
  }

  _sort(): void {
    this._memos = this._memos!.sort((memo1, memo2) => {
      switch (this._selectedSortType) {
        case SortType.date:
          return sortByDate(memo1, memo2);
        case SortType.likes:
          return sortByLikes(memo1, memo2);
        case SortType.position:
          return sortByPosition(memo1, memo2);
      }
    });

    this._selectedMemoIndex = 0;
  }
}
