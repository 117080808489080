<div class="inset-spacer"></div>
<!-- 데스크탑용 헤더 -->
<div class="header desktop">
  <div class="bar">
    <div class="bar-item icon back">
      <button sui-icon-button (click)="_onMenuItemClick(MenuItem.Back)">
        <sui-icon name="왼쪽 이동"></sui-icon>
      </button>
    </div>
    <div class="bar-item icon">
      <button class="icon-text-button" (click)="_onMenuItemClick(MenuItem.TOC)">
        <div class="icon">
          <sui-icon name="책 정보" type="solid"></sui-icon>
        </div>
        책 정보
      </button>
    </div>
    <div class="bar-item icon" *ngIf="_isPDFMode">
      <button
        class="icon-text-button"
        (click)="_onMenuItemClick(MenuItem.Thumbnail)"
      >
        <div class="icon">
          <sui-icon name="페이지 모아보기"></sui-icon>
        </div>
        페이지 목록
      </button>
    </div>
    <div class="bar-item icon">
      <button
        class="icon-text-button"
        (click)="_onMenuItemClick(MenuItem.Settings)"
      >
        <div class="icon">
          <sui-icon name="환경설정"></sui-icon>
        </div>
        뷰어 설정
      </button>
    </div>
    <div class="bar-item icon">
      <button
        class="icon-text-button"
        (click)="_onMenuItemClick(MenuItem.Help)"
      >
        <div class="icon">
          <sui-icon name="도움말" type="solid"></sui-icon>
        </div>
        도움말
      </button>
    </div>
    <div class="bar-item main">
      <div class="title">{{ _book?.meta?.title }}</div>
      <ng-container *ngIf="!_isCommentaryEditorMode">
        <ng-container *ngIf="_groupMemberCount === 0"
          >혼자 읽기 중</ng-container
        >
        <ng-container *ngIf="_groupMemberCount > 0">
          <button
            class="member-count-button"
            (click)="_onMenuItemClick(MenuItem.GroupMembers)"
          >
            {{ _groupMemberCount }}</button
          >명의 독자들과 함께읽기 중
        </ng-container>
        <button
          class="mode-button"
          (click)="_onMenuItemClick(MenuItem.ReadingMode)"
        >
          <sui-icon name="뷰어 그룹 변경"></sui-icon>모드 변경
        </button>
      </ng-container>
      <div
        class="inbox-button"
        [class.is-active]="_hasNewNote"
        *ngIf="_groupMemberCount > 0"
      >
        <button sui-icon-button (click)="_onMenuItemClick(MenuItem.Inbox)">
          <sui-icon name="메세지" type="solid"></sui-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="floating-buttons" [class.is-pdf-mode]="_isPDFMode">
    <button
      *ngIf="_isPDFMode"
      class="button right"
      sui-icon-button
      (click)="_onMenuItemClick(MenuItem.DrawingMode)"
    >
      <sui-icon
        name="펜"
        [type]="_isDrawingMode ? 'solid' : 'outline'"
      ></sui-icon>
    </button>
    <button
      *ngIf="!_isCommentaryEditorMode"
      class="button right"
      sui-icon-button
      (click)="_onMenuItemClick(MenuItem.Bookmark)"
    >
      <sui-icon
        name="북마크"
        [type]="_bookmarked ? 'solid' : 'outline'"
      ></sui-icon>
    </button>
    <button
      *ngIf="_canSearchText"
      class="button right"
      sui-icon-button
      (click)="_onMenuItemClick(MenuItem.Search)"
    >
      <sui-icon name="검색"></sui-icon>
    </button>
  </div>
</div>

<!-- 모바일용 헤더 -->
<div class="header mobile">
  <button
    class="button left"
    sui-icon-button
    (click)="_onMenuItemClick(MenuItem.Back)"
  >
    <sui-icon name="원형 이동_왼쪽"></sui-icon>
  </button>
  <div class="expand"></div>

  <button
    *ngIf="_isPDFMode"
    class="button right"
    sui-icon-button
    (click)="_onMenuItemClick(MenuItem.DrawingMode)"
  >
    <sui-icon
      name="펜"
      [type]="_isDrawingMode ? 'solid' : 'outline'"
    ></sui-icon>
  </button>

  <button
    *ngIf="!_isCommentaryEditorMode"
    class="button right"
    sui-icon-button
    (click)="_onMenuItemClick(MenuItem.Bookmark)"
  >
    <sui-icon
      name="북마크"
      [type]="_bookmarked ? 'solid' : 'outline'"
    ></sui-icon>
  </button>

  <button
    class="button right"
    sui-icon-button
    *ngIf="_canSearchText"
    (click)="_onMenuItemClick(MenuItem.Search)"
  >
    <sui-icon name="검색"></sui-icon>
  </button>

  <div
    class="button right inbox-button-mobile"
    *ngIf="_groupMemberCount > 0"
    [class.is-active]="_hasNewNote"
  >
    <button
      class="button right"
      sui-icon-button
      (click)="_onMenuItemClick(MenuItem.Inbox)"
    >
      <sui-icon name="메세지" type="solid"></sui-icon>
    </button>
  </div>
</div>
