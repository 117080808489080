<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  [attr.fill]="color === 'gray' ? '#E6E6E6' : '#E0FF00'"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M50 7.21105V38.112C36.3636 40.2097 22.7273 42.3075 9.09091 44.4052C4.10338 45.1716 1.07119 47.0365 0 50V6.51268C13.727 4.39926 27.4569 2.28845 41.184 0.175026C46.9451 -0.711669 50 1.79148 50 7.21105Z"
  />
</svg>

<div class="label"><ng-content></ng-content></div>
