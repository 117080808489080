<input
  class="viewer-pen-width-slider"
  type="range"
  step="0.5"
  min="1"
  max="14"
  [(ngModel)]="width"
  (change)="widthChange.emit(width)"
/>
<div class="value-label">{{ width }}px</div>
