<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  <sui-icon name="원형 이동_왼쪽" (click)="_backPage()"></sui-icon>
  <ng-container>
    <span> {{ _bookshelf?.title }} </span></ng-container
  >
</sui-dialog-header>
<section
  [ngClass]="{ sectionEmpty: _bookshelvesBookList?.items?.length === 0 }"
>
  <div class="header">
    <div class="inner-left">
      <span class="book-count"
        >전체 <b>{{ _totalItemCount }}</b
        >권</span
      >
    </div>
  </div>
  <div class="inner-wrapper">
    <div class="main" #bookContainer>
      <sui-book
        [layout]="'mobile'"
        *ngFor="let book of _bookshelvesBookList?.items; index as i"
        (click)="_routeBookDetailPage(book.bid)"
      >
        <img sui-book-cover [src]="book.cover" />
        <sui-book-title>{{ book.title }}</sui-book-title>
        <sui-book-author>{{ book.author }}</sui-book-author>
        <sui-book-tail-tag
          class="sui-book__tail-tags"
          *ngIf="book.sales_type.includes('rent')"
          >대여</sui-book-tail-tag
        >
        <sui-book-tail-tag
          class="sui-book__tail-tags"
          *ngIf="book.sales_type.includes('free')"
          >무료</sui-book-tail-tag
        >

        <sui-book-tag *ngIf="book.is_commentarybook" theme="green"
          ><sui-icon name="코멘터리"></sui-icon>코멘터리북</sui-book-tag
        >
        <div
          class="sui-book__overlay sui-book--desktop"
          *ngIf="book.has_subcontent"
        >
          <sui-book-sub-content-tag></sui-book-sub-content-tag>
        </div>
        <div
          class="sui-book__cover-overlay sui-book--mobile"
          *ngIf="book.has_subcontent"
        >
          <sui-book-sub-content-tag></sui-book-sub-content-tag>
        </div>
      </sui-book>
      <div
        class="empty"
        *ngIf="_bookshelvesBookList && _bookshelvesBookList.items.length === 0"
      >
        <p>책장에 책이 없습니다.</p>
      </div>
    </div>
    <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
      <sui-paginator
        [itemCountPerPage]="_itemCountPerPage"
        [totalItemCount]="_totalItemCount"
        [page]="_currentPage"
        (pageChange)="_onPageChange($event)"
      ></sui-paginator>
    </div>
  </div>
</section>

<div class="loader" *ngIf="_isLoading">
  <sui-loader></sui-loader>
</div>
