import {
  BookshelvesList,
  WishList,
  OwnLibraryList,
  SubscriptionList,
  Subcontent,
  Bookshelf,
  BookshelfBook,
  OwnedBookSearchResultItem,
} from 'shared/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { QueryResult } from './query-result';
import { stripUndefinedParams } from 'shared/utils';

@Injectable({
  providedIn: 'root',
})
export class BookshelfAPIService {
  constructor(private _http: HttpClient) {}

  //--------------------------------- 책장 관련 ---------------------------------//
  getBookshelvesList(
    offset: number,
    limit: number
  ): Observable<BookshelvesList> {
    const params = {
      offset,
      limit,
    };

    return this._http.get<BookshelvesList>(`/api/v1/bookshelves`, {
      params,
      withCredentials: true,
    });
  }

  getOthersBookshelvesList(
    offset: number,
    limit: number,
    user_id?: string
  ): Observable<BookshelvesList> {
    const params = {
      offset,
      limit,
    };

    return this._http.get<BookshelvesList>(
      `/api/v1/users/${user_id}/bookshelves`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  getInfo(bookshelfId: string): Observable<Bookshelf> {
    return this._http.get<Bookshelf>(`/api/v1/bookshelves/${bookshelfId}`, {
      withCredentials: true,
    });
  }

  getBooks(
    bookshelfId: string,
    offset: number,
    limit: number,
    q?: string
  ): Observable<QueryResult<BookshelfBook>> {
    return this._http.get<QueryResult<BookshelfBook>>(
      `/api/v1/bookshelves/${bookshelfId}/books`,
      {
        params: stripUndefinedParams({
          offset,
          limit,
          q,
        }),
        withCredentials: true,
      }
    );
  }

  createBookshelf(title: string, is_private: 0 | 1): Observable<void> {
    const params = {
      title,
      is_private,
    };

    return this._http.post<void>(`/api/v1/bookshelves`, params, {
      withCredentials: true,
    });
  }

  changeBookshelf(
    bookshelf_id: string,
    title: string,
    is_private: number
  ): Observable<void> {
    const params = {
      title,
      is_private,
    };

    return this._http.put<void>(`/api/v1/bookshelves/${bookshelf_id}`, params, {
      withCredentials: true,
    });
  }

  deleteBookshelf(bookshelf_id: string): Observable<void> {
    return this._http.delete<void>(`/api/v1/bookshelves/${bookshelf_id}`, {
      withCredentials: true,
    });
  }

  deleteBooks(bookshelf_id: string, bookList: string[]): Observable<void> {
    return this._http.post<void>(
      `/api/v1/bookshelves/${bookshelf_id}/books/remove`,
      { content_ids: bookList },
      {
        withCredentials: true,
      }
    );
  }

  insertBooks(bookshelf_id: string, bookList: string[]): Observable<void> {
    return this._http.put<void>(
      `/api/v1/bookshelves/${bookshelf_id}/books`,
      { content_ids: bookList },
      {
        withCredentials: true,
      }
    );
  }

  getSubscriptionList(
    offset: number,
    limit: number
  ): Observable<SubscriptionList> {
    const params = {
      offset,
      limit,
    };

    return this._http.get<SubscriptionList>(
      `/api/v1/bookshelves/subscriptions`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  //--------------------------------- 내 도서 목록 ---------------------------------//

  getOwnedBooks(
    offset: number,
    limit: number,
    includeExpired: boolean
  ): Observable<QueryResult<BookshelfBook>> {
    return this._http.get<QueryResult<BookshelfBook>>(
      `/api/v1/bookshelves/owned/books`,
      {
        params: {
          offset,
          limit,
          include_expired: includeExpired ? 1 : 0,
        },
        withCredentials: true,
      }
    );
  }

  getMyOwnLibraryList(
    offset: number,
    limit: number
  ): Observable<OwnLibraryList> {
    const params = {
      offset,
      limit,
    };

    return this._http.get<OwnLibraryList>(`/api/v1/bookshelves/subscriptions`, {
      params,
      withCredentials: true,
    });
  }

  //--------------------------------- 찜 목록 ---------------------------------//

  checkBookInWishList(bid: string): Observable<boolean> {
    return this._http
      .get<{ id: string; is_in_wishlist: boolean }>(
        `/api/v1/bookshelves/wishlist/${bid}`,
        {
          withCredentials: true,
        }
      )
      .pipe(map((response) => response.is_in_wishlist));
  }

  addToWishList(bids: string | string[]): Observable<void> {
    if (!Array.isArray(bids)) {
      bids = [bids];
    }

    return this._http.put<void>(
      `/api/v1/bookshelves/wishlist`,
      { bids },
      {
        withCredentials: true,
      }
    );
  }

  getWishList(offset: number, limit: number): Observable<WishList> {
    const params = {
      offset,
      limit,
    };

    return this._http.get<WishList>(`/api/v1/bookshelves/wishlist`, {
      params,
      withCredentials: true,
    });
  }

  deleteFromWishList(bid: string): Observable<void> {
    return this._http.delete<void>(`/api/v1/bookshelves/wishlist`, {
      params: { bids: bid },
      withCredentials: true,
    });
  }

  //--------------------------------- 부록 목록 ---------------------------------//

  getSubcontents(
    bid: string,
    offset: number,
    limit: number
  ): Observable<QueryResult<Subcontent>> {
    const params = {
      offset,
      limit,
    };
    return this._http.get<QueryResult<Subcontent>>(
      `/api/v1/books/${bid}/subcontents`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  //--------------------------------- 책 삭제 ---------------------------------//
  removeBook(bid: string): Observable<void> {
    return this._http.delete<void>(`/api/v1/bookshelf/${bid}`, {
      withCredentials: true,
    });
  }

  //--------------------------------- 앱에서 책 검색 ---------------------------------//
  searchInOwnedBooks(
    offset: number,
    limit: number,
    query: string
  ): Observable<QueryResult<OwnedBookSearchResultItem>> {
    return this._http.get<QueryResult<OwnedBookSearchResultItem>>(
      `/api/v1/bookshelf`,
      {
        params: {
          offset,
          limit,
          query,
        },
        withCredentials: true,
      }
    );
  }
}
