<viewer-highlight-filter-toggle
  *ngIf="!_isCommentaryEditorMode"
  [(ngModel)]="_selectedFilter"
  (ngModelChange)="_onFilterChange()"
></viewer-highlight-filter-toggle>
<div class="list-header">
  <div class="left">
    <div class="title">
      이 책에 남긴 <span class="count">{{ _items.length }}</span
      >개의 하이라이트가 있습니다.
    </div>
  </div>
  <div class="right">
    <button
      class="select-all-button"
      [class.is-active]="
        _selectedItemCount > 0 && _selectedItemCount === _items.length
      "
      (click)="_onSelectAllButtonClick()"
    >
      전체 선택<sui-icon name="체크"></sui-icon>
    </button>
    <select
      sui-select
      [(ngModel)]="_selectedSortType"
      (ngModelChange)="_onSortTypeChange()"
    >
      <option *ngFor="let sortType of _sortTypes" [value]="sortType">
        {{ sortType }}
      </option>
    </select>
  </div>
</div>
<div class="list" [class.selection-mode]="_selectedItemCount > 0">
  <div
    class="list-item"
    *ngFor="let item of _items; index as i"
    [class.is-selected]="item.selected"
  >
    <viewer-icon-checkbox class="checkbox"
      ><input
        type="checkbox"
        [(ngModel)]="item.selected"
        (ngModelChange)="_onItemSelectionChange()"
    /></viewer-icon-checkbox>
    <button
      sui-icon-button
      class="more-button"
      [cdkMenuTriggerFor]="menu"
      [cdkMenuPosition]="_menuPosition"
      [cdkMenuTriggerData]="{ item: item }"
    >
      <sui-icon name="더보기"></sui-icon>
    </button>
    <div class="text" (click)="_onItemClick(item)">
      {{ item.text }}
    </div>
    <div class="date">{{ item.created_at | date : 'y.MM.dd. HH:mm' }}</div>
    <div
      class="color"
      [attr.style]="_styleClassStyleMap[item.style]"
      *ngIf="!_isCommentaryEditorMode"
    >
      <sui-icon name="하이라이트"></sui-icon>
    </div>
  </div>
</div>
<div class="bottom-menu" [class.is-active]="_selectedItemCount > 0">
  <div class="selection-count">
    총 <span>{{ _selectedItemCount }}</span
    >개 선택
  </div>
  <div class="buttons">
    <button
      sui-box-button
      varSize="medium48::medium54"
      (click)="_onCopySelectedItemsButtonClick()"
    >
      복사하기</button
    ><button
      sui-solid-button
      varSize="medium48::medium54"
      (click)="_onDeleteSelectedItemsButtonClick()"
    >
      삭제하기
    </button>
  </div>
</div>

<ng-template #menu let-item="item">
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onCopyMenuClick(item)">
      복사하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteMenuClick(item)">
      삭제하기
    </button>
  </sui-menu>
</ng-template>
