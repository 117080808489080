import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchAndRethrowAPIError } from './api-error';

const CREATE_COMMENTARY_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'No permission to submit': '해당 작업을 수행할 권한이 없습니다.',
  'Submission deadline expires': '제출 기한이 만료되었습니다.',
  'Already submitted': '이미 제출되었습니다.',
};

@Injectable({
  providedIn: 'root',
})
export class CommentariesAPIService {
  constructor(private _http: HttpClient) {}

  create(info: {
    bid: string;
    avatar_image_url: string;
    nickname: string;
    tags: string;
    description: string;
  }): Observable<void> {
    return this._http
      .post<void>('/api/v1/commentaries', info, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(CREATE_COMMENTARY_ERROR_MSG_MAP));
  }
}
