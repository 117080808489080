import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ViewerContentsService } from '@bukio/viewer';

import { ContentsDecryptionService } from './contents-decryption.service';

@Injectable()
export class LibContentsService implements ViewerContentsService {
  private _utf8Decoder = new TextDecoder('utf-8');

  constructor(
    private _http: HttpClient,
    private _d: ContentsDecryptionService
  ) {}

  getItem(itemURL: string): Observable<string> {
    const request = new HttpRequest('GET', itemURL, {
      responseType: 'arraybuffer',
    });

    return this._http.request<ArrayBuffer>(request).pipe(
      filter((event) => event instanceof HttpResponse),
      switchMap((event) => {
        event = event as HttpResponse<ArrayBuffer>;

        if (!event.body) {
          return of('');
        }

        const nonce = event.headers.get('x-nonce');

        if (!nonce) {
          return of(this._utf8Decoder.decode(event.body));
        }

        return this._d._3(event.body, nonce);
      })
    );
  }

  getPDFImage(imageURL: string): Observable<Blob> {
    const request = new HttpRequest('GET', imageURL, {
      responseType: 'arraybuffer',
    });

    return this._http.request<ArrayBuffer>(request).pipe(
      filter((event) => event instanceof HttpResponse),
      switchMap((event) => {
        event = event as HttpResponse<ArrayBuffer>;

        if (!event.body) {
          return throwError('empty body');
        }

        const contentType = event.headers.get('content-type');

        if (contentType?.startsWith('image')) {
          return of(new Blob([event.body], { type: contentType }));
        }

        return this._d._2(event.body);
      })
    );
  }
}
