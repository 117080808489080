// @ts-nocheck

import { fabric } from 'fabric';
import 'fabric-history';

/**
 * 손가락 스크롤, 줌인/아웃 허용
 */
(fabric.Canvas.prototype as any).__onTouchStart = (
  fabric.Canvas.prototype as any
)._onTouchStart;

(fabric.Canvas.prototype as any)._onTouchStart = function (
  e: TouchEvent,
): void {
  if (
    e.type === 'touchstart' &&
    (e.touches[0].touchType === 'direct' ||
      e.touches[0].radiusX > 0 ||
      e.touches.length > 1)
  ) {
    return;
  }

  this.__onTouchStart(e);
};

/**
 * Safari 브라우저에서 "Canvas memory use exceeds the maximum limit" warning 발생하는 문제 수정
 */
fabric.Object.prototype.objectCaching = false;

/**
 * 컨트롤 숨김
 */
fabric.Object.prototype.hasControls = false;

/**
 * 손가락으로 그리기 막기
 */
const IS_TOUCH_DEVICE =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

[
  '_onMouseDownInDrawingMode',
  '_onMouseMoveInDrawingMode',
  // '_onMouseUpInDrawingMode',
].forEach((name) => {
  (fabric.Canvas.prototype as any)['$' + name] = (
    fabric.Canvas.prototype as any
  )[name];
  (fabric.Canvas.prototype as any)[name] = function (e): void {
    if (e.touches?.length > 0 && e.touches[0].radiusX === 0) {
      this['$' + name](e);
    }
  };
});

// (fabric.Canvas.prototype as any)._isMainEvent = (function (
//   originalFn: Function,
// ): Function {
//   return function (event: any): boolean {
//     // console.warn('is main event');

//     if (event instanceof TouchEvent) {
//       // console.warn(
//       //   event.type,
//       //   event.touches.length,
//       //   event.touches[0]?.touchType
//       // );

//       if (event.touches.length > 1) {
//         this.didStartMultiTouch = true;
//         return false;
//       }

//       if (event.type === 'touchend' && this.didStartFingerTouch) {
//         this.didStartFingerTouch = false;
//         return false;
//       }

//       if (event.type === 'touchend' && this.didStartMultiTouch) {
//         this.didStartMultiTouch = false;
//         return false;
//       }

//       if (event.touches.length > 0) {
//         this.touchRadiusX = event.touches[0].radiusX;
//         this.touchRadiusY = event.touches[0].radiusY;

//         if (this.touchRadiusX > 0 || this.touchRadiusY > 0) {
//           // console.warn('ignore fingertouch');
//           this.didStartFingerTouch = true;
//           return false;
//         } else {
//           // console.warn('not prevent scroll');
//           // event.preventDefault();
//         }
//       }
//     } else {
//       if (IS_TOUCH_DEVICE) {
//         return false;
//       }
//       // console.warn(event);
//       // console.warn('not a touch event');
//     }

//     return originalFn.call(this, event);
//   };
// })((fabric.Canvas.prototype as any)._isMainEvent);

Object.defineProperty(fabric.PencilBrush.prototype, 'drawStraightLine', {
  get() {
    return this.forceDrawStraightLine || this._drawStraightLine;
  },
  set(drawStraightLine: boolean) {
    this._drawStraightLine = drawStraightLine;
  },
});
