<ng-container *ngIf="_isOnSale">
  <h1 class="dialog-title">미리보기는 여기까지입니다.</h1>
  <p class="dialog-subtitle">다음 내용이 궁금하다면 구매 후 읽어보세요!</p>
  <div class="cover-container">
    <img src="{{ _coverImageURL }}" />
  </div>
  <h2 class="title">{{ _title }}</h2>
  <p class="author">
    {{ _author
    }}<ng-container *ngIf="_publisher"> | {{ _publisher }}</ng-container>
  </p>
  <div class="pricing" *ngIf="_currency">
    <ng-container *ngFor="let type of _PRICING_TYPES">
      <div class="pricing-item" *ngIf="_pricing[type[0]].published">
        <sui-rounded-tag
          >전자책<ng-container *ngIf="_pricing[type[0]].period as period">
            {{ period | periodTranslate }}</ng-container
          >
          {{ type[1] }}</sui-rounded-tag
        >
        <div class="price">
          {{ _pricing[type[0]].discounted | currencyTranslate : _currency }}
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="_hasBookInfoPage"
    class="details-button"
    (click)="_onBookDetailsButtonClick()"
  >
    <div class="circle">
      <sui-icon name="오른쪽 이동"></sui-icon>
    </div>
    책 정보
  </div>
</ng-container>
<ng-container *ngIf="!_isOnSale">
  <sui-icon class="placeholder-icon" name="책 미리보기" type="solid"></sui-icon>
  <h1 class="placeholder-title">미리보기는 여기까지 입니다.</h1>
  <p class="placeholder-description">해당 책은 미판매 책입니다.</p>
</ng-container>
