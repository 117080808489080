import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Survey } from 'shared/models';
import { catchAndRethrowAPIError } from './api-error';

const SUBMIT_SURVEY_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'Not found': '해당 설문조사 대상이 아닙니다.',
  'Already answered': '이미 응답했습니다.',
};

@Injectable({
  providedIn: 'root',
})
export class SurveyAPIService {
  constructor(private _http: HttpClient) {}

  get(): Observable<{ items: Survey[] }> {
    return this._http.get<{ items: Survey[] }>(`/api/v1/survey`, {
      withCredentials: true,
    });
  }

  submit(
    submissionId: number,
    opinionContent: string,
    recommendationLevel?: 0 | 1 | 2
  ): Observable<void> {
    let answers = [recommendationLevel, opinionContent];

    if (recommendationLevel === undefined && opinionContent === '') {
      answers = [];
    } else if (recommendationLevel !== undefined && opinionContent === '') {
      answers = [recommendationLevel];
    }

    return this._http
      .post<void>(
        `/api/v1/survey`,
        {
          id: submissionId,
          answers,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(SUBMIT_SURVEY_ERROR_MSG_MAP));
  }
}
