import { Injectable } from '@angular/core';
import { Settings, SettingValues } from '@bukio/viewer';

import { MiscSettings } from '../models/misc-settings';
import { HighlightColor } from '../constants/highlight-colors';

const APP_KEY_PREFIX = 'viewer';

const KEY_SETTINGS = 'settings';
const KEY_SETTINGS_MISC = 'settings_misc';

const DEFAULT_MISC_SETTINGS = {
  highlightColors: [
    HighlightColor.Yellow,
    HighlightColor.Red,
    HighlightColor.Green,
  ],
};

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  saveSettings(
    settings: Settings,
    excludingKeys: (keyof Settings)[] = []
  ): void {
    const prevSettings = this.loadSettings();

    /**
     * 유저가 직접 변경한 설정만 저장
     */
    const newSettings = Object.entries(settings)
      .filter(([key, setting]) => {
        return (
          !setting.isDisabled &&
          excludingKeys.indexOf(key as keyof Settings) === -1
        );
      })
      .reduce((settingValues, [key, setting]) => {
        settingValues[key] = setting.value;
        return settingValues;
      }, {} as any);

    try {
      this._save(
        KEY_SETTINGS,
        JSON.stringify({ ...prevSettings, ...newSettings })
      );
    } catch (error) {
      console.log('failed to save viewer setting data', error);
    }
  }

  loadSettings(): SettingValues | null {
    try {
      const settings = this._load(KEY_SETTINGS);

      if (!settings) {
        return null;
      }

      return JSON.parse(settings);
    } catch (error) {
      console.log('failed to load viewer setting data', error);

      return null;
    }
  }

  saveMiscSettings(settings: MiscSettings): void {
    try {
      this._save(KEY_SETTINGS_MISC, JSON.stringify(settings));
    } catch (error) {
      console.log('failed to save viewer misc setting data', error);
    }
  }

  loadMiscSettings(): MiscSettings {
    try {
      const settings = this._load(KEY_SETTINGS_MISC);

      if (!settings) {
        return DEFAULT_MISC_SETTINGS;
      }

      return JSON.parse(settings);
    } catch (error) {
      return DEFAULT_MISC_SETTINGS;
    }
  }

  private _getKey(key: string): string {
    return `${APP_KEY_PREFIX}.${key}`;
  }

  private _load(key: string): string | null {
    return localStorage.getItem(this._getKey(key));
  }

  private _save(key: string, value: string): void {
    localStorage.setItem(this._getKey(key), value);
  }

  private _remove(key: string): void {
    localStorage.removeItem(this._getKey(key));
  }
}
