import { parseBukURL } from '@bukio/viewer';

function getDocumentFromRange(range: Range): Document {
  if (range.startContainer instanceof Document) {
    return range.startContainer;
  }

  return range.startContainer.ownerDocument!;
}

export function getIIDFromRange(range: Range): string {
  const rangeDoc = getDocumentFromRange(range);
  const baseBukURL = rangeDoc.documentElement.getAttribute(
    'data-bukv-base-buk-url'
  );

  const address = parseBukURL(baseBukURL!);
  return address.iid ?? '';
}

export function getActiveIframes(
  viewerElem: Element,
  pageElemTagName: string = ''
): HTMLIFrameElement[] {
  const iframes = [] as HTMLIFrameElement[];

  const _iframes = viewerElem.querySelectorAll(
    `${pageElemTagName}.bukv_page.bukv_is-active iframe`
  );

  for (let i = 0; i < _iframes.length; i++) {
    const rect = _iframes[i].getBoundingClientRect();

    if (rect.width * rect.height > 0) {
      iframes.push(_iframes[i] as HTMLIFrameElement);
    }
  }

  return iframes;
}
