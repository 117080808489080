import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'viewer-overlapping-count-select',
  templateUrl: './overlapping-count-select.component.html',
  styleUrl: '../commentary-select/commentary-select.component.scss',
})
export class OverlappingCountSelectComponent {
  public _isOptionsOpened = false;

  public readonly _optionSuffix = '명 이상 공유 하이라이트';
  public readonly _valueOptions = [2, 3, 5, 7];

  @Input() selectedValue!: number;
  @Output() selectedValueChange = new EventEmitter<number>();

  _onOptionClick(value: number): void {
    this._isOptionsOpened = false;

    if (this.selectedValue === value) {
      return;
    }

    this.selectedValue = value;
    this.selectedValueChange.emit(value);
  }
}
