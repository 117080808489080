<ng-content select="viewer-memo-type"></ng-content>
<div class="box">
  <div class="top-buttons">
    <ng-content select="[viewer-memo-top-button]"></ng-content>
    <button
      sui-icon-button
      *ngIf="!!menu"
      class="more-button"
      [cdkMenuTriggerFor]="menu"
      [cdkMenuTriggerData]="menuData"
      [cdkMenuPosition]="_menuPosition"
      (click)="$event.stopPropagation()"
    >
      <sui-icon name="더보기"></sui-icon>
    </button>
  </div>

  <div
    class="text"
    [class.has-limited-height]="limitHeight && !_shortContent"
    (click)="contentClick.emit()"
  >
    <span
      [innerHTML]="
        (limitHeight && _shortContent ? _shortContent + '...' : content)
          | escapeHtml
          | detectLink
      "
    ></span>
    <span
      class="show-all-content-button"
      *ngIf="limitHeight && _shortContent"
      (click)="$event.stopPropagation(); _shortContent = undefined"
      >자세히 보기</span
    >
  </div>

  <div class="info" (click)="$event.stopPropagation()">
    <div class="actions">
      <ng-content select="viewer-memo-actions"></ng-content>
    </div>
    <div class="author">
      <ng-content select="viewer-memo-author"></ng-content>
    </div>
  </div>
</div>

<div class="footer">
  <svg
    class="tail"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.0585938 29.9993V0H30.0545H30.0586V2.84652e-07C30.0572 9.48861e-08 30.0558 0 30.0545 0C13.6541 0 0.327509 13.3845 0.0585938 29.9993Z"
      fill="#F5F5F7"
    />
  </svg>

  <div class="tag">
    <ng-content select="viewer-memo-tag"></ng-content>
  </div>
</div>
