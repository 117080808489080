<div #barContainer class="bar-container">
  <div class="bar">
    <div class="fill" [style.width]="_value * 100 + '%'"></div>
  </div>
  <div
    class="handle"
    [style.left]="'clamp(0px, ' + _value * 100 + '%, 100% - 14px)'"
  ></div>
</div>
<div class="page-number">
  <input
    #input
    [value]="_value * 100 | number : '1.0-0'"
    type="number"
    (blur)="_onPageInputBlur(input.value)"
    (keydown)="$event.key === 'Enter' ? input.blur() : $event.stopPropagation()"
    (click)="input.select()"
  />&nbsp;&nbsp;%
</div>
