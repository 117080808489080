import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { finalize, filter } from 'rxjs/operators';

import { Annotation } from '@bukio/viewer';

import {
  AlertService,
  MenuPositionBottomLeft,
  ToastService,
  TypedDialog,
} from 'shared/ui';
import { APIError } from 'shared/services';
import { ReadingGroupJoinStatus } from 'shared/models';

import { removeReplacementCharacter } from '../../utils/misc';

import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';

export interface NewMemoDialogData {
  annotation: Annotation;
  mergedAnnotations: Annotation[];
}

export interface EditMemoDialogData {
  id: string;
  text: string;
  content: string;
  pinned: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNewMemoDialogData(data: any): data is NewMemoDialogData {
  return 'annotation' in data;
}

@Component({
  selector: 'viewer-memo-dialog',
  templateUrl: './memo-dialog.component.html',
  styleUrls: ['./memo-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MemoDialogComponent
  extends TypedDialog<
    NewMemoDialogData | EditMemoDialogData,
    {
      canceled?: boolean;
      deleted?: boolean;
      updated?: {
        content: string;
        pinned: boolean;
      };
    }
  >
  implements OnInit
{
  @ViewChild('textarea', { static: true })
  _inputComp!: ElementRef<HTMLTextAreaElement>;

  public _memoFormControl: UntypedFormControl;
  public _originalMemo?: string;
  public _text?: string;

  public _memoId?: string;
  public _annotation?: Annotation;
  private _mergedAnnotations?: Annotation[];
  public readonly _moreMenuPosition = MenuPositionBottomLeft;

  public _isSaving = false;
  public _hasEmptyValue = false;

  public _wasPinnedMemo = false;
  public _pinMemo = false;
  public _canPinMemo = false;

  constructor(
    private _annotationService: AnnotationsV2Service,
    private _toastService: ToastService,
    private _alertService: AlertService,
    readingGroupsService: ReadingGroupsService
  ) {
    super();

    this._canPinMemo =
      readingGroupsService.currentGroup?.status ===
      ReadingGroupJoinStatus.owned;

    this._memoFormControl = new UntypedFormControl('', {
      validators: Validators.maxLength(2000),
      updateOn: 'change',
    });

    this._memoFormControl.valueChanges.subscribe((value) => {
      this._hasEmptyValue = !value.trim();
    });
  }

  ngOnInit(): void {
    if (isNewMemoDialogData(this._data)) {
      this._originalMemo = '';
      this._text = removeReplacementCharacter(this._data.annotation.text);

      this._annotation = this._data.annotation;
      this._mergedAnnotations = this._data.mergedAnnotations;

      this._wasPinnedMemo = false;
    } else {
      this._originalMemo = this._data.content;
      this._text = this._data.text;

      this._memoId = this._data.id;

      this._wasPinnedMemo = this._data.pinned;
    }

    this._pinMemo = this._wasPinnedMemo;
    this._memoFormControl.reset(this._originalMemo);

    // this._dialogRef.afterOpened().subscribe(() => {
    //   this._inputComp.nativeElement.focus();
    // });
  }

  onCloseButtonClick(): void {
    const isMemoChanged = this._originalMemo !== this._memoFormControl.value;

    let confirm$: Observable<boolean>;

    if (isMemoChanged) {
      confirm$ = this._alertService
        .openConfirm(
          undefined,
          '변경사항이 저장되지 않았습니다. 계속하시겠습니까?'
        )
        .afterClosed() as Observable<boolean>;
    } else {
      confirm$ = of(true);
    }

    confirm$.pipe(filter((ok) => ok)).subscribe(() => {
      this._dialogRef.close({ canceled: true });
    });
  }

  onSaveButtonClick(): void {
    if (!this._annotation && !this._memoId) {
      return;
    }

    this._isSaving = true;
    const memo = this._memoFormControl.value.trim();

    let request$: Observable<void>;

    if (this._annotation) {
      request$ = this._annotationService.createMemo(
        this._annotation,
        memo,
        this._pinMemo
      );
      this._mergedAnnotations &&
        this._annotationService
          .deleteMemos(this._mergedAnnotations)
          .subscribe();
    } else if (this._memoId) {
      request$ = this._annotationService.updateMemo(
        this._memoId,
        memo,
        this._pinMemo
      );
    }

    request$!
      .pipe(
        finalize(() => {
          this._isSaving = false;
        })
      )
      .subscribe({
        next: () => {
          this._dialogRef.close({
            updated: {
              content: memo,
              pinned: this._pinMemo,
            },
          });
        },
        error: (error) => {
          if (error instanceof APIError) {
            this._toastService.openWarning(error.message);
          }
        },
      });
  }

  _onRemoveButtonClick(): void {
    if (!this._memoId) {
      return;
    }

    this._annotationService.deleteMemosById(this._memoId).subscribe({
      next: () => {
        this._dialogRef.close({ deleted: true });
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }
}
