import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OthersBookshelfOpenerService {
  public isApp = false;

  constructor(private _router: Router) {}

  open(userId: string): void {
    const url = `/bookshelf/${userId}`;

    if (this.isApp) {
      this._router.navigateByUrl(url);
    } else {
      window.open(url, '_blank');
    }
  }
}
