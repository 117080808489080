<div class="title" *ngIf="_data.status === _JoinStatus.owned">
  그룹장이 그룹을 나가면 그룹원 신규 가입과 그룹원 간 새로운 기록 공유가
  불가능해집니다. <br />또한 그룹 내 회원님의 모든 기록이 삭제되며, 삭제된
  기록은 복원이 불가능합니다. <br />그룹을 나가시겠습니까?
</div>
<div class="title" *ngIf="_data.status === _JoinStatus.joined">
  그룹에서 나가면 그룹 내 회원님의 독서 기록이 모두 삭제되며, 삭제된 기록은
  복원이 불가능합니다. <br />그룹을 나가시겠습니까?
</div>
<div class="description">
  나가기 전, 회원님의 메모와 하이라이트 기록을 '혼자 읽기' 모드로 복사할 수
  있습니다.
</div>
<div class="actions">
  <div class="checkboxes">
    <sui-checkbox type="boxFirst">
      나의 메모, 하이라이트 기록 복사하기
      <input type="checkbox" [(ngModel)]="_exitCheckboxCopy" />
    </sui-checkbox>
    <sui-checkbox type="boxFirst">
      위 내용을 모두 확인하였습니다.
      <input type="checkbox" id="confirm" [(ngModel)]="_exitCheckboxConfirm" />
    </sui-checkbox>
  </div>
  <div class="buttons">
    <button
      sui-solid-button
      varSize="medium40:large"
      (click)="_onOKClick()"
      [disabled]="!_exitCheckboxConfirm || _isExiting"
    >
      그룹 나가기
    </button>
  </div>
</div>
