export function stripUndefinedParams(obj: {
  [param: string]: string | number | boolean | undefined;
}): {
  [param: string]: string | number | boolean;
} {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  }

  return obj as {
    [param: string]: string | number | boolean;
  };
}
