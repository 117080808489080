import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Input,
  Inject,
  HostBinding,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { takeUntil, filter } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';

import { EventBusService } from '../../services/event-bus.service';

type ScreenSizeTarget = 'mobile' | 'desktop' | 'any';

const RESPONSIVE_DESKTOP_BREAKPOINT = 1200;

@Component({
  selector: 'viewer-pushable-side-menu',
  templateUrl: './pushable-side-menu.component.html',
  styleUrls: ['./pushable-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PushableSideMenuComponent implements OnInit, OnDestroy {
  private _unsubscriber = new Subject<void>();

  private _sidebarSize?: number;

  private _isSideMenuOpened = false;

  @Input() isEnabledOn: ScreenSizeTarget = 'any';

  @HostBinding('attr.position') @Input() position!:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left';

  constructor(
    private _element: ElementRef,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer: Renderer2,
    private _eventBusService: EventBusService
  ) {}
  ngOnInit(): void {
    this._reset();

    fromEvent(window, 'resize')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this._sidebarSize = undefined;
        this._reset();
      });

    this._eventBusService
      .on('SidebarContainerComponent:opened')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(({ position }) => position === this.position)
      )
      .subscribe(() => {
        this._isSideMenuOpened = true;
        this._isEnabled && this._onSidebarOpen();
      });

    this._eventBusService
      .on('SidebarContainerComponent:closed')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(({ position }) => position === this.position)
      )
      .subscribe(() => {
        this._isSideMenuOpened = false;
        this._isEnabled && this._onSidebarClose();
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  private get _isHorizontalPosition(): boolean {
    return this.position === 'left' || this.position === 'right';
  }

  private get _cssTranslation(): string {
    return this._isHorizontalPosition ? 'translateX' : 'translateY';
  }

  private get _isEnabled(): boolean {
    switch (this.isEnabledOn) {
      case 'any':
        return true;
      case 'desktop':
        return window.innerWidth >= RESPONSIVE_DESKTOP_BREAKPOINT;
      case 'mobile':
        return window.innerWidth < RESPONSIVE_DESKTOP_BREAKPOINT;
    }
  }

  private _reset(): void {
    if (this._isEnabled) {
      if (this._isSideMenuOpened) {
        this._onSidebarOpen();
      } else {
        this._onSidebarClose();
      }
    } else {
      this._onSidebarClose();
    }
  }

  private _onSidebarClose(): void {
    this._renderer.setStyle(
      this._element.nativeElement,
      'transform',
      `${this._cssTranslation}(0)`
    );
  }

  private _onSidebarOpen(): void {
    if (!this._sidebarSize) {
      const sidebar = this._document.querySelector(
        `viewer-sidebar-container .sidebar[position="${this.position}"]`
      ) as HTMLElement;

      if (!sidebar) {
        return;
      }

      this._sidebarSize = this._isHorizontalPosition
        ? sidebar.offsetWidth
        : sidebar.offsetHeight;
    }

    const translationValue =
      (this.position === 'left' || this.position === 'top' ? 1 : -1) *
      this._sidebarSize;

    this._renderer.setStyle(
      this._element.nativeElement,
      'transform',
      `${this._cssTranslation}(${translationValue}px)`
    );
  }
}
