/* eslint-disable @angular-eslint/component-selector */
import { Component, HostBinding, Input } from '@angular/core';

export const SUI_BUTTON_PREFIX = 'sui-button';

const RESPONSIVE_CLASS_LIST_TWO = ['desktop', 'mobile'];
const RESPONSIVE_CLASS_LIST_THREE = ['desktop', 'tablet', 'phone'];

function createClassName(
  className: string,
  responsiveClassName?: string
): string {
  return (
    SUI_BUTTON_PREFIX +
    (responsiveClassName ? `--${responsiveClassName}` : '') +
    `--${className}`
  );
}

/**
 * 사용 가능한 varSize 값
 * medium40, medium44, medium48, medium54, large, mini
 */

@Component({
  template: '',
})
export class ButtonBaseComponent {
  @HostBinding('class') sizeClass = 'normal';

  @Input() set varSize(value: string) {
    if (value == null) {
      value = 'normal';
    }

    const sizes = value.split(':');

    if (sizes.length === 1) {
      this.sizeClass = createClassName(sizes[0]);
    } else if (sizes.length === 2) {
      this.sizeClass = sizes
        .map((size, index) =>
          createClassName(size, RESPONSIVE_CLASS_LIST_TWO[index])
        )
        .join(' ');
    } else {
      sizes.forEach((value, index) => {
        if (value.length === 0) {
          sizes[index] = sizes[index - 1];
        }
      });

      this.sizeClass = sizes
        .map((size, index) =>
          createClassName(size, RESPONSIVE_CLASS_LIST_THREE[index])
        )
        .join(' ');
    }
  }

  @Input() set size(value: 'large' | 'normal' | 'small' | 'withIcon') {
    this.sizeClass = value ?? 'normal';
  }
}
