import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Library } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class LibraryAPIService {
  constructor(private _http: HttpClient) {}

  get(eid: string): Observable<{ item: Library; result: string }> {
    return this._http.get<{ item: Library; result: string }>(
      `/api/1.0/library`,
      {
        params: { eid },
        withCredentials: true,
      }
    );
  }
}
