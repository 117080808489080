import { parseBukURL } from '@bukio/viewer';

export function getRangeFromBukURL(url: string): [number, number] | null {
  const address = parseBukURL(url);

  if (!address.iid || !address.range) {
    return null;
  }

  const [startStr, endStr] = address.range.split('-');
  const start = parseInt(startStr);
  const end = parseInt(endStr);

  return [start, end];
}

export function containsRange(
  parentRange: [number, number],
  childRange: [number, number]
): boolean {
  return parentRange[0] <= childRange[0] && parentRange[1] >= childRange[1];
}

export function getOverlappingRangesAndCount(
  ranges: [number, number][]
): [number, number, number][] {
  const overlaps: [number, number][] = [];

  for (let i = 0; i < ranges.length; i++) {
    for (let j = i + 1; j < ranges.length; j++) {
      const range1 = ranges[i];
      const range2 = ranges[j];

      if (range1[0] <= range2[1] && range2[0] <= range1[1]) {
        const overlapStart = Math.max(range1[0], range2[0]);
        const overlapEnd = Math.min(range1[1], range2[1]);

        const index = overlaps.findIndex((r) => {
          return r[0] === overlapStart && r[1] === overlapEnd;
        });

        if (index === -1) {
          overlaps.push([overlapStart, overlapEnd]);
        }
      }
    }
  }

  return overlaps.map((overlappingRange) => {
    const count = ranges.reduce((count, range) => {
      if (containsRange(range, overlappingRange)) {
        count++;
      }

      return count;
    }, 0);

    return [overlappingRange[0], overlappingRange[1], count];
  });
}

export function mergeOverlappingRanges(
  ranges: [number, number][]
): [number, number][] {
  if (ranges.length <= 1) {
    return ranges;
  }

  ranges.sort((a, b) => a[0] - b[0]);

  const mergedRanges = [ranges[0]];

  for (let i = 1; i < ranges.length; i++) {
    const currentRange = ranges[i];
    const lastMergedRange = mergedRanges[mergedRanges.length - 1];

    if (currentRange[0] <= lastMergedRange[1]) {
      lastMergedRange[1] = Math.max(lastMergedRange[1], currentRange[1]);
    } else {
      mergedRanges.push(currentRange);
    }
  }

  return mergedRanges;
}
