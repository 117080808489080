<ng-template cdkPortalOutlet />
<button class="close-button" (click)="_onCloseButtonClick()">
  <svg
    width="42"
    height="45"
    viewBox="0 0 42 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.48994V34.3008C11.4545 36.1888 22.9091 38.0767 34.3636 39.9647C38.5532 40.6544 41.1002 42.3329 42 45V5.86141C30.4693 3.95933 18.9362 2.0596 7.40546 0.157524C2.56609 -0.640502 0 1.61233 0 6.48994Z"
      fill="#282828"
    />
    <path
      d="M27.5 13.4486L26.0514 12L20.5043 17.5557L14.9486 12L13.5 13.4486L19.0557 19.0043L13.5 24.5514L14.9486 26L20.5043 20.4443L26.0514 26L27.5 24.5514L21.9443 19.0043L27.5 13.4486Z"
      fill="white"
    />
  </svg>
</button>
