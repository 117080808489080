import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PDFTextInfo } from '@bukio/viewer';

import {
  ContentsDecryptionWorkers,
  VIEWER_CONTENTS_DECRYPTION_WORKERS,
} from '../viewer.module';

@Injectable({
  providedIn: 'root',
})
export class ContentsDecryptionService {
  constructor(
    @Inject(VIEWER_CONTENTS_DECRYPTION_WORKERS)
    private _workers: ContentsDecryptionWorkers
  ) {}

  /**
   * PDF 텍스트 복호화 함수
   * @param text 암호화된 PDF 텍스트
   * @param bid
   * @param rand
   */
  _1(text: ArrayBuffer, bid: string, rand: string): Observable<PDFTextInfo[]> {
    return new Observable<PDFTextInfo[]>((observer) => {
      this._workers.pdfText.post([text, bid, rand], (error, result) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(result);
        }
        observer.complete();
      });
    });
  }

  /**
   * PDF 이미지 복호화 함수
   * @param image 암호화된 PDF 이미지
   */
  _2(image: ArrayBuffer): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      this._workers.pdfImage.post(image, (error, result) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(result);
        }

        observer.complete();
      });
    });
  }

  /**
   * 이펍 HTML 복호화 함수
   * @param html 암호화된 HTML
   * @param nonce
   */
  _3(html: ArrayBuffer, nonce: string): Observable<string> {
    return new Observable<string>((observer) => {
      this._workers.bukHTML.post([html, nonce], (error, result) => {
        if (error) {
          observer.error(error);
        } else {
          observer.next(result);
        }

        observer.complete();
      });
    });
  }
}
