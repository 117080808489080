import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sui-book-expired-overlay',
  templateUrl: './book-expired-overlay.component.html',
  styleUrl: './book-expired-overlay.component.scss',
})
export class BookExpiredOverlayComponent {
  @Output() deleteButtonClick = new EventEmitter();

  public onDeleteButtonClick(): void {
    this.deleteButtonClick.emit();
  }
}
