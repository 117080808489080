import { Component, OnInit } from '@angular/core';
import { TypedDialog } from 'shared/ui';

import { NativeBridgeService } from '../../services/native-bridge.service';
import { DrawingMeta } from '../../models/drawing-meta';

@Component({
  selector: 'app-drawing-backup-dialog',
  templateUrl: './drawing-backup-dialog.component.html',
  styleUrl: './drawing-backup-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class DrawingBackupDialogComponent
  extends TypedDialog<void, void>
  implements OnInit
{
  public _books?: (DrawingMeta & { selected: boolean })[];
  public _selectedBookCount = 0;

  constructor(private _nativeBridgeService: NativeBridgeService) {
    super();
  }

  ngOnInit(): void {
    this._nativeBridgeService.getDrawingList().subscribe((books) => {
      this._books = books.map((book) => ({ ...book, selected: false }));
    });
  }

  _onBookClick(index: number): void {
    const book = this._books?.[index];

    if (!book) {
      return;
    }

    book.selected = !book.selected;

    this._selectedBookCount =
      this._books?.filter((book) => book.selected).length ?? 0;
  }

  _onBackupButtonClick(): void {
    const bids = this._books
      ?.filter((book) => book.selected)
      .map((book) => book.bid);

    if (!bids) {
      return;
    }

    this._nativeBridgeService.backupDrawings(bids);
  }
}
