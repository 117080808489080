import { Component, HostBinding, ViewChild } from '@angular/core';

import { ReadingGroup } from 'shared/models';
import { ReadingGroupsAPIService } from 'shared/services';
import { AlertService, TypedDialog } from 'shared/ui';

import { ReadingMode } from '../../components/reading-mode-selection/reading-mode-selection.component';
import { ReadingGroupSelectionComponent } from '../../components/reading-group-selection/reading-group-selection.component';

enum Page {
  modeSelection,
  groupSelection,
  groupCreation,
  groupEdit,
  groupDetails,
  invitation,
}

@Component({
  selector: 'lib-reading-mode-dialog',
  templateUrl: './reading-mode-dialog.component.html',
  styleUrl: './reading-mode-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class ReadingModeDialogComponent extends TypedDialog<
  { bid: string },
  { canceled?: boolean; groupId?: number }
> {
  @ViewChild(ReadingGroupSelectionComponent)
  readingGroupSelectionComp!: ReadingGroupSelectionComponent;

  public readonly _Page = Page;
  public _currentPage = Page.modeSelection;
  public _pageTitles = [
    '',
    '함께 읽을 그룹 선택하기',
    '새로운 그룹 만들기',
    '정보 수정하기',
    '상세보기',
    '그룹원 초대하기',
  ];
  public pageStack: Page[] = [];
  public detailsReadingGroup?: ReadingGroup;

  @HostBinding('class.small-size') get isSmallSize(): boolean {
    return this._currentPage === Page.modeSelection;
  }

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _alertService: AlertService
  ) {
    super();
  }

  _onReadingModeSelected(mode: ReadingMode): void {
    if (mode === ReadingMode.group) {
      this._goToPage(Page.groupSelection);
    } else {
      this._dialogRef.close({ groupId: undefined });
    }
  }

  private _setCurrentPage(page: Page): void {
    this._currentPage = page;
  }

  _goToPage(page: Page): void {
    this.pageStack.push(this._currentPage);
    this._setCurrentPage(page);
  }

  _goBack(skipCheckPage?: boolean): void {
    if (
      !skipCheckPage &&
      (this._currentPage === Page.groupCreation ||
        this._currentPage === Page.groupEdit)
    ) {
      const dialogRef = this._alertService.openConfirm(
        undefined,
        '뒤로가기 시, 작성중인 내용은 저장되지 않습니다.<br/>뒤로가기 하시겠습니까?'
      );

      dialogRef.afterClosed().subscribe((ok) => {
        if (ok) {
          this._goBack(true);
        }
      });

      return;
    }

    const prevPage = this.pageStack.pop();
    prevPage !== undefined && this._setCurrentPage(prevPage);
  }

  get _canGoBack(): boolean {
    return this.pageStack.length > 0;
  }

  get _showHeaderDivider(): boolean {
    return (
      this._currentPage !== Page.modeSelection &&
      this._currentPage !== Page.groupSelection
    );
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close({ canceled: true });
  }

  _onBackButtonClick(): void {
    this._goBack();
  }

  _onEnterGroupButtonClick(groupId: number): void {
    this._dialogRef.close({ groupId });
  }

  _onGroupDetailsButtonClick(group: ReadingGroup): void {
    this.detailsReadingGroup = group;
    this._goToPage(Page.groupDetails);
  }

  _onEditGroupButtonClick(): void {
    this._goToPage(Page.groupEdit);
  }

  _onGroupCreated(): void {
    this._goBack(true);
    this.readingGroupSelectionComp.resetTabs();
  }

  _onGroupExited(): void {
    this._goBack();
    this.readingGroupSelectionComp.resetTabs();
  }

  _onGroupJoined(): void {
    this.readingGroupSelectionComp.refreshMyGroups();
  }

  _onGroupEdited(): void {
    if (!this.detailsReadingGroup) {
      return;
    }

    this._readingGroupsAPIService
      .getById(this.detailsReadingGroup.id)
      .subscribe((group) => {
        this.detailsReadingGroup = group;
        this.readingGroupSelectionComp.updateGroup(group);
        this._goBack(true);
      });
  }
}
