import { fabric } from 'fabric';
import { Subject } from 'rxjs';
import { DrawingPenOption, DrawingTool } from 'viewer';

function penWidthRelativeToViewportScale(
  width: number,
  viewportScale: number
): number {
  return Math.max(width / viewportScale, 1);
}

interface Size {
  width: number;
  height: number;
}

export interface DrawingHistoryChangeEvent {
  canUndo: boolean;
  canRedo: boolean;
}

const ELEMENT_STYLE = {
  position: 'absolute',
  top: '0',
  right: '0',
  bottom: '0',
  left: '0',
  'pointer-events': 'auto',
} as { [key: string]: string };

export class PDFDrawableOverlayView {
  public readonly element: HTMLDivElement;

  public readonly canvas: fabric.Canvas;
  private readonly _pencilBrush: fabric.PencilBrush;

  private _eraserStarted = false;
  private _erasedObjects: fabric.Object[] = [];

  private _isEraserMode = false;
  private _loading = false;

  private readonly _drawingEnd$ = new Subject<void>();
  public readonly drawingEnd$ = this._drawingEnd$.asObservable();

  private readonly _historyChange$ = new Subject<DrawingHistoryChangeEvent>();
  public readonly historyChange$ = this._historyChange$.asObservable();

  public undoStack = [];
  public redoStack = [];

  private _penStarted = false;
  private _wasEmpty = false;

  constructor() {
    this.element = document.createElement('div');

    for (const prop in ELEMENT_STYLE) {
      this.element.style.setProperty(prop, ELEMENT_STYLE[prop]);
    }

    // disable page gesture event
    this.element.addEventListener('touchstart', (event) => {
      if (event.touches[0].radiusX === 0) {
        event.stopPropagation();
      }
    });

    this.element.addEventListener('click', (event) => {
      if (this._penStarted) {
        event.stopPropagation();
      }
    });

    this.element.addEventListener('pointerdown', (event) => {
      this._penStarted = event.pointerType === 'pen';
      if (event.pointerType === 'pen') {
        (event as any).bukvEventHandled = true;
      }
    });

    const canvas = document.createElement('canvas');

    this.element.appendChild(canvas);

    this.canvas = new fabric.Canvas(canvas, {
      perPixelTargetFind: true,
      allowTouchScrolling: true,
      selection: true,
      selectionColor: 'rgba(0, 0, 0, 0)',
      selectionDashArray: [2],
      selectionBorderColor: 'rgb(170, 170, 170)',
      selectionLineWidth: 1,
      preserveObjectStacking: true,
      hoverCursor: 'default',
      // enablePointerEvents: true,
    });

    this.canvas.on('mouse:down', (event) => {
      this._eraserStarted = this._isEraserMode;

      if (this._eraserStarted) {
        this._erasedObjects = [];
        this.canvas.targetFindTolerance = 6;
        this.canvas.offHistory();
      }
    });

    this.canvas.on('mouse:move', (event) => {
      if (!this._eraserStarted) {
        return;
      }

      const target = this.canvas.findTarget(event.e, true);

      if (target) {
        this._erasedObjects.push(target);
        target.opacity = 0.3;
        target.evented = false;
        this.canvas.requestRenderAll();
      }
    });

    this.canvas.on('mouse:up', (event) => {
      if (!this._eraserStarted) {
        return;
      }

      const target = this.canvas.findTarget(event.e, true);

      if (target) {
        this._erasedObjects.push(target);
        target.opacity = 0.3;
      }

      this._eraserStarted = false;

      this.canvas.targetFindTolerance = 1;

      if (this._erasedObjects.length > 0) {
        this.canvas.remove(...this._erasedObjects);
        this.canvas.requestRenderAll();
        this.canvas.fire('eraser:end');
      }

      this.canvas.onHistory(this._erasedObjects.length === 0);
    });

    [
      'object:added',
      'object:removed',
      'object:modified',
      'history:undo',
      'history:redo',
      'eraser:end',
    ].forEach((eventName) => {
      this.canvas.on(eventName, () => {
        if (this.canvas.historyProcessing || this._loading) {
          return;
        }

        this._drawingEnd$.next();
      });
    });

    [
      'history:undo',
      'history:redo',
      'history:clear',
      'history:append',
      'history:reset',
    ].forEach((eventName) => {
      this.canvas.on(eventName, () => {
        this._historyChange$.next({
          canUndo: this.canUndo,
          canRedo: this.canRedo,
        });
      });
    });

    this._pencilBrush = new fabric.PencilBrush(this.canvas);
  }

  get canUndo(): boolean {
    return this.canvas.historyUndo.length > 0;
  }

  get canRedo(): boolean {
    return this.canvas.historyRedo.length > 0;
  }

  get viewportScale(): number {
    return this.canvas.viewportTransform?.[0] ?? 1;
  }

  set viewportScale(scale: number) {
    const prevScale = this.viewportScale;
    const originalPenWidth = this._pencilBrush.width * prevScale;

    this._pencilBrush.width = penWidthRelativeToViewportScale(
      originalPenWidth,
      scale
    );

    this.canvas.setViewportTransform([scale, 0, 0, scale, 0, 0]);
  }

  getDrawingSize(): Size {
    const currScale = this.viewportScale;

    return {
      width: Math.round(this.canvas.getWidth() / currScale),
      height: Math.round(this.canvas.getHeight() / currScale),
    };
  }

  getDrawing(): string | null {
    return this.canvas.isEmpty() && this._wasEmpty ? null : this.canvas.toSVG();
  }

  isEmpty(): boolean {
    return this.canvas.isEmpty();
  }

  load(pageSize: Size, svg?: string): void {
    this._loading = true;
    this._wasEmpty = !svg;

    this.canvas.offHistory();
    this.canvas.clear();

    if (svg) {
      fabric.loadSVGFromString(svg, (objects, options) => {
        objects.forEach((object, index) => {
          this.canvas.add(object);
        });

        const scale = (fabric.util as any).findScaleToFit(
          { width: options.width, height: options.height },
          pageSize
        );

        this.viewportScale = scale;
        this.canvas.renderAll();

        this.canvas.setDimensions(pageSize);
      });
    } else {
      this.viewportScale = 1;
      this.canvas.renderAll();
      this.canvas.setDimensions(pageSize);
    }

    this.canvas.resetHistory();

    this._loading = false;
  }

  clearSelection(): void {
    this.canvas.discardActiveObject();
    this.canvas.requestRenderAll();
  }

  clearDrawing(): void {
    // clear 함수가 객체를 하나씩 지우기 때문에 clear 함수를 실행하는 동안에는 히스토리를 기억하지 않도록 해야함
    this.canvas.offHistory();

    this.canvas.clear();

    this.canvas.onHistory();
  }

  undo(): void {
    this.canvas.undo();
  }

  redo(): void {
    this.canvas.redo();
  }

  setTool(tool: DrawingTool, option?: DrawingPenOption): void {
    this.canvas.isDrawingMode = tool === 'pen';
    this.canvas.selection = tool === 'marquee';
    this.canvas.perPixelTargetFind = tool !== 'marquee';
    this.canvas.forEachObject((object) => {
      if (object.globalCompositeOperation === 'destination-out') {
        return;
      }

      object.selectable = tool === 'marquee';
    });

    if (tool !== 'marquee') {
      this.clearSelection();
    }

    this._isEraserMode = tool === 'eraser';

    if (tool === 'pen') {
      this._pencilBrush.width = penWidthRelativeToViewportScale(
        option?.width ?? 1,
        this.viewportScale
      );
      this._pencilBrush.color = option?.color ?? '#000';
      this._pencilBrush.forceDrawStraightLine = option?.straight ?? false;

      this.canvas.freeDrawingBrush = this._pencilBrush;
    }
  }

  resize(): void {
    const pageSize = {
      width: this.element.offsetWidth,
      height: this.element.offsetHeight,
    };

    if (pageSize.width === 0 || pageSize.height === 0) {
      console.warn('invalid page size');
      return;
    }

    if (!this.canvas.isEmpty()) {
      const scale = (fabric.util as any).findScaleToFit(
        this.getDrawingSize(),
        pageSize
      );

      this.viewportScale = scale;

      this._pencilBrush.width = penWidthRelativeToViewportScale(
        this._pencilBrush.width ?? 1,
        this.viewportScale
      );
    }

    this.canvas.setDimensions(pageSize);
  }
}
