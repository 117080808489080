import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class ChannelAPIService {
  constructor(private _http: HttpClient) {}

  get(eid: string): Observable<Channel> {
    return this._http.get<Channel>(`/api/v1/channels/${eid}`);
  }
}
