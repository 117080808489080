import { Component, OnDestroy, OnInit } from '@angular/core';
import { NativeBridgeService } from '../../services/native-bridge.service';
import { PDFDrawingService } from '../../services/pdf-drawing.service';
import { AppSettingsStoreService } from '../../services/app-settings-store.service';
import { BukJSON } from '@bukio/viewer';

@Component({
  selector: 'app-viewer-page',
  templateUrl: './viewer-page.component.html',
  styleUrl: './viewer-page.component.scss',
})
export class ViewerPageComponent implements OnInit, OnDestroy {
  constructor(
    private _nativeBridgeService: NativeBridgeService,
    private _pdfDrawingService: PDFDrawingService,
    private _appSettignsStoreService: AppSettingsStoreService
  ) {}

  ngOnInit(): void {
    this._nativeBridgeService.closeSystemBars();

    if (this._appSettignsStoreService.get().keepScreenOn) {
      this._nativeBridgeService.setKeepScreenOn(true);
    }
    this._nativeBridgeService.setScreenCaptureEnabled(false);
  }

  ngOnDestroy(): void {
    this._pdfDrawingService.saveDrawings().subscribe();
    this._nativeBridgeService.openSystmeBars();
    this._nativeBridgeService.setKeepScreenOn(false);
    this._nativeBridgeService.setScreenCaptureEnabled(true);
  }

  _onMenuOpenedChange(opened: boolean): void {
    if (opened) {
      this._nativeBridgeService.openSystmeBars();
    } else {
      this._nativeBridgeService.closeSystemBars();
    }
  }

  _onBookLoad(book: BukJSON): void {
    this._nativeBridgeService.setDrawingMeta({
      bid: book.meta.bid,
      title: book.meta.title,
      author: book.meta.author,
      coverImageURL: book.meta.cover,
    });
  }
}
