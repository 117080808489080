<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
</sui-dialog-header>

<!-- 설문조사 동의 여부를 묻는 step -->
<main *ngIf="_surveyStep === 'consent'" class="step1">
  <p class="greet">회원님, 안녕하세요.</p>
  <p class="explanation">
    북이오에 대해 간단한 2문항 설문조사에 <br />
    잠시 참여해주시겠어요?
  </p>
  <div class="button-container">
    <button
      sui-solid-button
      class="yes-button"
      varSize="medium48::large"
      (click)="_moveNextStep('recommentationLevel')"
    >
      네
    </button>
    <button
      sui-box-button
      class="no-button"
      varSize="medium48::large"
      (click)="_submitUserSurvey('consent')"
    >
      아니요
    </button>
  </div>
</main>

<!-- 북이오 만족도 정도를 묻는 step -->
<main *ngIf="_surveyStep === 'recommentationLevel'" class="step2">
  <p class="title">
    북이오를 친구에게 <br />
    추천할 의향이 있으신가요?
  </p>
  <ul class="recommendation-list">
    <li>
      <sui-radio-button
        >전혀 없어요.<input
          type="radio"
          [checked]="_recommendationLevel === 0"
          (change)="_onChangeRecommendationLevel(0)"
      /></sui-radio-button>
    </li>
    <li>
      <sui-radio-button
        >보통이에요.<input
          type="radio"
          [checked]="_recommendationLevel === 1"
          (change)="_onChangeRecommendationLevel(1)"
      /></sui-radio-button>
    </li>
    <li>
      <sui-radio-button
        >너무 추천하고 싶어요.<input
          type="radio"
          [checked]="_recommendationLevel === 2"
          (change)="_onChangeRecommendationLevel(2)"
      /></sui-radio-button>
    </li>
  </ul>
  <div class="button-container">
    <button
      sui-solid-button
      class="next-button"
      [disabled]="_recommendationLevel === undefined"
      varSize="medium48::large"
      (click)="_moveNextStep('opinion')"
    >
      다음(1/2)
    </button>
  </div>
</main>

<!-- 북이오에 대한 의견을 작성하는 step -->
<main *ngIf="_surveyStep === 'opinion'" class="step3">
  <p class="title">
    북이오에 더 바라는 점이 <br />
    있다면 말씀해 주세요.
  </p>
  <p class="explanation">
    고객님의 소중한 의견은 북이오가 더 좋은 <br />
    서비스를 만드는데 큰 도움이 됩니다.
  </p>
  <div class="opinion-box">
    <textarea
      class="input-box"
      placeholder="의견을 작성해 주세요."
      [(ngModel)]="_opinionContent"
      maxlength="2000"
    ></textarea>
    <span class="count">{{ _opinionContent.length }}/2000</span>
  </div>
  <div class="button-container">
    <button
      sui-solid-button
      [disabled]="_opinionContent.length === 0"
      class="submit-button"
      varSize="medium48::large"
      (click)="_submitUserSurvey('opinion')"
    >
      의견 보내기
    </button>
  </div>
</main>
