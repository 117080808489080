import { BookType, parseBukURL } from '@bukio/viewer';
import { Highlighter } from '@bukio/highlighter';

import { clamp } from 'shared/utils';

import { BukJSON } from '../models/book.model';

export function isHorizontalPDFBook(book: BukJSON): boolean {
  return (
    book.meta.type === BookType.PDF &&
    book.items.filter((item) => item.fixedWidth! > item.fixedHeight! + 2)
      .length >
      book.items.length / 2
  );
}

export function isBookOnSale(book: BukJSON): boolean {
  return Object.values(book.meta.pricing).some((pricing) => pricing.published);
}

export function isBookOwnedByUser(book: BukJSON): boolean {
  return (
    book.permission.type === 'admin' || // 북이오 관리자
    book.permission.type === 'library' || // 내 책장에 있는 책
    book.permission.type === 'sample' || // 회원가입시 기본으로 들어있는 책
    book.permission.type === 'subscribe' ||
    book.permission.type === 'ecobag'
  );
}

const ITEM_POSITION_MULTIPLIER = 10000000;
const highlighter = new Highlighter();

function getStartOffsetFromURL(url: string): number {
  const address = parseBukURL(url);
  return (
    (address.range && highlighter.parseAddress(address.range).startOffset) ?? 0
  );
}

export function calculateBukURLPositionInBook(
  book: BukJSON,
  url: string
): number {
  let itemIndex = -1;

  if (book.meta.type === BookType.Document) {
    itemIndex = 0;
  } else {
    const address = parseBukURL(url);
    itemIndex = book.items.findIndex((item) => item.iid === address.iid);
  }

  if (itemIndex === -1) {
    throw new Error('faild to calculate highlight position');
  }

  return itemIndex * ITEM_POSITION_MULTIPLIER + getStartOffsetFromURL(url);
}

export function hasBookInfoPage(bid: string): boolean {
  return /^(\d{1,6}|[^plwjv][a-z]\d{4})$/.test(bid);
}

export function calculatePPageFromPage(
  page: number,
  totalPageCount: number
): number {
  const pPage =
    Math.ceil(
      parseFloat(((page - 1) / totalPageCount).toPrecision(8)) * 10000000
    ) / 10000000;

  return clamp(0, pPage, 0.9999999);
}
