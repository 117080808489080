import { Injectable, OnDestroy } from '@angular/core';
import { Subject, filter, takeUntil, tap, throttleTime } from 'rxjs';

import { MemosAPIService, SocketService } from 'shared/services';

import { ReadingGroupsService } from './reading-groups.service';

@Injectable()
export class MemoReadStatusService implements OnDestroy {
  private _unsubscriber = new Subject<void>();

  private _read$ = new Subject<string>();

  constructor(
    private _memosAPIService: MemosAPIService,
    private _socketService: SocketService,
    private _readingGroupsService: ReadingGroupsService
  ) {
    let buffer = [] as string[];

    this._read$
      .pipe(
        tap((memoId) => {
          buffer.push(memoId);
        }),
        throttleTime(500, undefined, { leading: false, trailing: true })
      )
      .subscribe(() => {
        this._memosAPIService.updateReadStatus(buffer).subscribe();
        buffer = [];
      });

    this._socketService
      .message()
      .pipe(
        takeUntil(this._unsubscriber),
        filter((data) => data.cmd === 'memo_created')
      )
      .subscribe(() => {
        this._readingGroupsService.refreshGroupInfo();
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  read(memoId: string): void {
    this._read$.next(memoId);
  }
}
