<div
  class="bookshelf-add-button"
  (click)="_onClickBookshelfAddButton()"
  [ngClass]="{ app: _isApp }"
>
  <sui-icon name="플러스"></sui-icon>
  <span>책장 추가하기</span>
</div>

<section [ngClass]="{ app: _isApp }">
  <div class="empty" *ngIf="!_isLoading && _bookshelves?.length === 0">
    <p class="content">등록된 책장이 없습니다.</p>
  </div>
  <div class="bookshelves">
    <lib-bookshelf-list-item
      *ngFor="let bookshelf of _bookshelves"
      [bookshelf]="bookshelf"
      [routerLink]="['detail']"
      [queryParams]="{ id: bookshelf.id }"
    ></lib-bookshelf-list-item>
  </div>
  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</section>
