<div class="container" #groupList>
  <section>
    <lib-group-item-box
      *ngFor="let myGroup of _readingGroup"
      [readingGroup]="myGroup"
      (routeButtonClick)="_routeDetailBookPage(myGroup.content.id)"
      [usesContainerQuery]="true"
    >
      <button
        sui-box-button
        size="normal"
        (click)="_onClickDetailPopup(myGroup)"
      >
        상세보기
      </button>
      <button
        sui-solid-button
        size="normal"
        *ngIf="myGroup.status === 'joined' || myGroup.status === 'owned'"
        (click)="_onClickReadTogether(myGroup)"
      >
        함께읽기 시작하기
      </button>
      <button
        sui-solid-button
        *ngIf="myGroup.status !== 'joined' && myGroup.status !== 'owned'"
        varSize="medium40"
        (click)="_onJoinGroupButtonClick(myGroup.id, myGroup.type)"
        [disabled]="myGroup.member_count >= myGroup.max_member_count"
      >
        가입하기
      </button>
    </lib-group-item-box>
  </section>
  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</div>
