<sui-dialog-header (closeButtonClick)="_closeDialog()">
  {{ _data.initial ? '프로필을 설정해 주세요.' : '프로필 편집하기' }}
</sui-dialog-header>
<section>
  <main>
    <div class="profile">
      <div
        class="img-container"
        *ngIf="_uploadImage"
        [ngStyle]="{
          'background-image': 'url(' + _uploadImage + ')'
        }"
      >
        <!-- <img src="{{ _uploadImage }}" alt="프로필" /> -->
        <button class="delete-img-button" (click)="_onClickDeleteImage()">
          <sui-icon name="엑스"></sui-icon>
        </button>
      </div>
      <div
        class="img-container default"
        *ngIf="
          (!_uploadImage && _userInfo?.avatar_image_url === '') ||
          (!_uploadImage && _userInfo?.avatar_image_url !== '')
        "
      >
        <sui-icon
          class="profile-placeholder"
          type="solid"
          name="마이페이지"
        ></sui-icon>
      </div>
      <button class="profile-select" (click)="_onClickUploadImage()">
        사진 선택
      </button>
      <input
        type="file"
        accept="image/png,image/jpeg,image/jpg"
        #fileInput
        style="display: none"
        (change)="_onFileChanged($event)"
      />
    </div>
    <div class="input-form-container">
      <div *ngIf="_data.initial" class="initial-desc">
        북이오에서 사용할 닉네임과 프로필 이미지를 설정해 주세요.<br />지금
        변경을 원하지 않으시는 경우 언제든 '내 서재' 메뉴에서 변경 가능합니다.
      </div>

      <div class="input-form">
        <div class="header">
          <span class="nickname">닉네임 변경</span>
          <p>비속어 / 욕설 사용 시, 서비스 이용에 제한이 될 수 있습니다.</p>
        </div>
        <div class="inner">
          <span class="header-mobile">닉네임 변경</span>
          <div class="inner-container">
            <input
              type="text"
              [(ngModel)]="_nickname"
              (ngModelChange)="_changeNickname()"
              (keyup.enter)="_onClickDuplicate()"
              placeholder="변경할 닉네임을 입력해주세요."
            />
            <button (click)="_onClickDuplicate()">중복 확인</button>
            <div class="alert-message-container">
              <span
                class="text-limit"
                *ngIf="
                  (_isTextLength < 2 || _isTextLength > 20) && !_isPageInit
                "
                >2-20자 이내</span
              >
              <span class="already-use" *ngIf="_isDuplication === 'duplicate'"
                >이미 사용중인 닉네임입니다.</span
              >
              <span class="not-use" *ngIf="_isDuplication === 'not-duplicate'"
                >사용가능한 닉네임 입니다.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="submit-container">
    <button
      *ngIf="_isImageChanged"
      sui-solid-button
      varSize="large"
      [disabled]="
        _isDuplication === 'duplicate' || _isDuplication === 'test-necessary'
      "
      (click)="_onClickSubmit()"
    >
      저장하기
    </button>
    <button
      *ngIf="!_isImageChanged"
      sui-solid-button
      varSize="large"
      [disabled]="_isDuplication !== 'not-duplicate'"
      (click)="_onClickSubmit()"
    >
      저장하기
    </button>
  </div>
</section>
