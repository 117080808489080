// export const environment = {
//   production: true,
//   appVersion: '0.0.0',
//   serverOrigin: 'https://dev.buk.io',
//   serviceOrigin: 'https://dev.buk.io',
//   dashboardOrigin: 'https://dashbaord.dev.buk.io',
//   wsServerOrigin: `https://wsdev.buk.io`,
// };

export const environment = {
  production: false,
  appVersion: '0.0.0',
  serverOrigin: 'https://buk.io',
  serviceOrigin: 'https://buk.io',
  dashboardOrigin: 'https://dashbaord.buk.io',
  wsServerOrigin: `https://ws.buk.io`,
};
