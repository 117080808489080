<div class="numbers-pager">
  <button
    sui-icon-button
    (click)="setNumbersPage('first')"
    [disabled]="maxNumbersPage === 0 || numbersPage === 0"
  >
    <sui-icon name="끝화살표_왼쪽"></sui-icon>
  </button>
  <button
    sui-icon-button
    (click)="setNumbersPage('prev')"
    [disabled]="numbersPage === 0"
  >
    <sui-icon name="화살표_왼쪽"></sui-icon>
  </button>
</div>
<div class="numbers">
  <a
    *ngFor="let number of numbers"
    (click)="_setPage(number)"
    [class.hidden]="!isVisiblePage(number)"
    [class.active]="page === number"
  >
    {{ number + 1 }}
  </a>
</div>
<div class="numbers-pager">
  <button
    sui-icon-button
    (click)="setNumbersPage('next')"
    [disabled]="numbersPage === maxNumbersPage"
  >
    <sui-icon name="화살표_오른쪽"></sui-icon>
  </button>
  <button
    sui-icon-button
    (click)="setNumbersPage('end')"
    [disabled]="maxNumbersPage === 0 || numbersPage === maxNumbersPage"
  >
    <sui-icon name="끝화살표_오른쪽"></sui-icon>
  </button>
</div>
