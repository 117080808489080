import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostBinding,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Address, BookType, createBukURL } from '@bukio/viewer';

import { AlertService, DialogService } from 'shared/ui';

import { Book } from '../../models/book.model';

import { CrossBrowsing } from '../../constants/cross-browsing';

import { EventBusService } from '../../services/event-bus.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';

import { PDFPageSliderComponent } from '../pdf-page-slider/pdf-page-slider.component';
import { PreviewEndDialogComponent } from '../../dialogs/preview-end-dialog/preview-end-dialog.component';
import { ThumbnailDialogComponent } from '../../dialogs/thumbnail-dialog/thumbnail-dialog.component';
import { ReadingGroupMembersDialogComponent } from '../../dialogs/reading-group-members-dialog/reading-group-members-dialog.component';
import { HelpDialogComponent } from '../../dialogs/help-dialog/help-dialog.component';
import { UIStateService } from '../../services/ui-state.service';

export enum FooterMenuItem {
  TOC,
  Thumbnail,
  Settings,
  Help,
  ReadingMode,
  GroupMembers,
}

@Component({
  selector: 'viewer-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @ViewChild(PDFPageSliderComponent) pdfPageSliderComp!: PDFPageSliderComponent;
  @ViewChild('pageInput') pageInputElement!: ElementRef<HTMLInputElement>;

  @HostBinding('class.is-pdf-mode') get _isPDFMode(): boolean {
    return this._book?.meta.type === BookType.PDF;
  }

  private _unsubscriber: Subject<void> = new Subject<void>();

  public readonly MenuItem = FooterMenuItem;

  public _totalPageCount = 1;
  public _currentPage = 1;

  private _address?: Address;
  private _book?: Book;

  public _groupMemberCount = 0;
  public _isCommentaryEditorMode = false;

  constructor(
    private eventBusService: EventBusService,
    private _readingGroupsService: ReadingGroupsService,
    private _alertService: AlertService,
    private _dialogService: DialogService,
    private _uiStateService: UIStateService
  ) {}

  ngOnInit(): void {
    this._readingGroupsService.currentGroup$.subscribe((group) => {
      this._groupMemberCount = group ? group.member_count : 0;
    });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;
        this._totalPageCount = book.items.length;
        this.pdfPageSliderComp.clearCrumb();
        this._onLocationChange(this._address!);

        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;
      });

    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._address = data.address;
        if (this._book?.meta.bid === this._address.bid) {
          this._onLocationChange(this._address);
        }
      });
  }

  _onMenuItemClick(menu: FooterMenuItem): void {
    switch (menu) {
      case FooterMenuItem.GroupMembers:
        this._openGroupMembersDialog();
        break;
      case FooterMenuItem.TOC:
      case FooterMenuItem.ReadingMode:
      case FooterMenuItem.Settings:
        this.eventBusService.fire('FooterComponent:menuClick', { menu });
        break;
      case FooterMenuItem.Thumbnail:
        this._openThumbnailDialog();
        break;
      case FooterMenuItem.Help:
        this._openHelpDialog();
        break;
    }
  }

  private _onLocationChange(address: Address): void {
    let currentIid = address.iid;

    if (!currentIid) {
      currentIid = this._book!.items[0].iid;
    }

    this._currentPage = this._book!.getIndexOfItem(currentIid) + 1;
  }

  private _openGroupMembersDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(ReadingGroupMembersDialogComponent, {
      data: {
        bid: this._book?.meta.bid,
      },
    });
  }

  _openThumbnailDialog(): void {
    if (!this._book || !this._address) {
      return;
    }

    this._dialogService.open(ThumbnailDialogComponent, {
      data: {
        book: this._book,
        currentIid: this._address.iid || this._book.items[0].iid,
      },
    });
  }

  _openHelpDialog(): void {
    if (!this._book) {
      return;
    }

    this._dialogService.open(HelpDialogComponent, {
      data: {
        isPDFBook: this._book.meta.type === BookType.PDF,
      },
    });
  }

  _onSliderPageChange(newPage: number): void {
    const isPageChanged = this._requestPageChange(newPage);

    if (!isPageChanged) {
      this.pdfPageSliderComp.resetPercentToCurrentPage();
      this.pdfPageSliderComp.clearCrumb();
    }
  }

  _onPageInputBlur(newPage: string): void {
    let _newPage = parseInt(newPage);

    if (isNaN(_newPage)) {
      _newPage = this._currentPage;
    } else if (_newPage < 1) {
      _newPage = 1;
    } else if (_newPage > this._totalPageCount) {
      _newPage = this._totalPageCount;
    }

    this.pageInputElement.nativeElement.value = _newPage.toString();

    const isPageChanged = this._requestPageChange(
      _newPage,
      CrossBrowsing.keyboardChangesViewport ? 500 : 0
    );

    if (!isPageChanged) {
      this.pageInputElement.nativeElement.value = this._currentPage.toString();
    } else if (this._currentPage !== _newPage) {
      this.pdfPageSliderComp.setCrumbToCurrentPage();
    }
  }

  /**
   * 사용자(PDFPageSlider 또는 input)로부터 발생한 페이지 변경 요청 처리
   * @param newPage 이동할 페이지
   * @param delay 페이지 이동 전 대기 시간
   * @return 요청한 페이지로 이동 가능한지
   */
  private _requestPageChange(newPage: number, delay: number = 0): boolean {
    const newIid = this._book!.items[newPage - 1]?.iid;

    // 아이템 존재하지 않음
    if (!newIid) {
      return false;
    }

    // 권한 없음
    if (!this._book!.canReadItem(newIid, true)) {
      this._alertService.open(PreviewEndDialogComponent, {
        data: {
          book: this._book!,
        },
      });

      return false;
    }

    window.setTimeout(() => {
      this.eventBusService.fire('FooterComponent:pageChange', {
        url: createBukURL(this._address!.bid, newIid),
      });
    }, delay);

    return true;
  }
}
