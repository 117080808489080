//------------------------- 내 책장 목록 -----------------------//
export interface Bookshelf {
  id: string;
  title: string;
  is_private: 0 | 1;
  is_default: 0 | 1;
  content_count: number;
  covers: string[];
  books?: {
    bid: string;
    cover: string;
  }[];
}

export interface BookshelvesList {
  total: number;
  offset: number;
  limit: number;
  items: Bookshelf[];
}

export interface BookshelfBook {
  bid: string;
  title: string;
  cover: string;
  author: string;
  type: string;
  expiryDate: string;
  expired: number;
  url: string;
  addedDate: string;
  readDate: string;
  has_subcontent: number;
  is_favorited: number;
  is_commentarybook: number;
  isSubcontent: number;
  sales_type: string[];
  book_type: 'buk' | 'pdf' | 'document' | 'edition' | 'video';
  can_submit: number; // boolean
}

export interface SubscriptionList {
  total: number;
  offset: number;
  limit: number;
  items: {
    id: string;
    title: string;
    description: string;
    logo_image_url: string;
    book_count: number;
  }[];
}

//------------------------- 내 도서 목록 -----------------------//

export interface OwnLibraryList {
  total: number;
  offset: number;
  limit: number;
  items: {
    id: string;
    title: string;
    logo_image_url: string;
    description: string;
    book_count: number;
    cp_name: string;
    custom_domains: string[];
  }[];
}

//------------------------- 찜 목록 -----------------------//
export interface WishList {
  total: number;
  offset: number;
  limit: number;
  items: {
    bid: string;
    title: string;
    author: string;
    cover: string;
  }[];
}

//------------------------- 부록 목록 -----------------------//
export interface Subcontent {
  bid: string;
  title: string;
  author: string;
  cover: string;
}

//------------------------- 내가 소유한 책 목록(앱) -----------------------//
export type OwnedBookSearchResultItem = Omit<BookshelfBook, 'sales_type'>;
