<div class="slider">
  <input
    class="viewer-pen-opacity-slider"
    type="range"
    step="0.1"
    min="0.1"
    max="1"
    [(ngModel)]="opacity"
    (change)="opacityChange.emit(opacity)"
  />
  <div class="number" [style.left.px]="19 * (opacity * 10 - 1)">
    {{ opacity * 100 }}%
  </div>
</div>
