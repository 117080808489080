<div
  class="button"
  [class.is-hidden]="_isHidden"
  *ngIf="_latestHistory"
  (click)="_onBackButtonClick()"
>
  <sui-icon class="icon" name="뒤로가기"></sui-icon>
  <span class="number" *ngIf="_latestHistory.displayNumber !== undefined">{{
    _latestHistory.displayNumber
  }}</span>
</div>
