import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUIModule } from 'shared/ui';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReadingGroupModule } from 'reading-group';

import { MyBookshelfMainPageComponent } from './pages/my-bookshelf-main-page/my-bookshelf-main-page.component';
import { BookshelfListItemComponent } from './components/bookshelf-list-item/bookshelf-list-item.component';
import { MyBookshelfDetailPageComponent } from './pages/my-bookshelf-detail-page/my-bookshelf-detail-page.component';
import { MyBookshelfGroupsPageComponent } from './pages/my-bookshelf-groups-page/my-bookshelf-groups-page.component';
import { MyBookshelfExitedGroupsPageComponent } from './pages/my-bookshelf-exited-groups-page/my-bookshelf-exited-groups-page.component';
import { MyBookshelfAllPageComponent } from './pages/my-bookshelf-all-page/my-bookshelf-all-page.component';
import { MyBookshelfAllBooksPageComponent } from './pages/my-bookshelf-all-books-page/my-bookshelf-all-books-page.component';
import { MyBookshelfAllLibrariesPageComponent } from './pages/my-bookshelf-all-libraries-page/my-bookshelf-all-libraries-page.component';
import { MyBookshelfLikesPageComponent } from './pages/my-bookshelf-likes-page/my-bookshelf-likes-page.component';

import { BookshelfListComponent } from './bookshelf-list/bookshelf-list.component';
import { AddBookComponent } from './components/add-book/add-book.component';
import { AddBookshelfComponent } from './components/add-bookshelf/add-bookshelf.component';
import { AppendixComponent } from './components/appendix/appendix.component';
import { DeleteBookshelfComponent } from './components/delete-bookshelf/delete-bookshelf.component';
import { EditBooklistComponent } from './components/edit-booklist/edit-booklist.component';
import { EditBookshelfComponent } from './components/edit-bookshelf/edit-bookshelf.component';
import { MoveBookshelfComponent } from './components/move-bookshelf/move-bookshelf.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedUIModule,
    RouterModule,
    ReadingGroupModule,
  ],
  declarations: [
    MyBookshelfMainPageComponent,
    MyBookshelfDetailPageComponent,
    MyBookshelfGroupsPageComponent,
    MyBookshelfExitedGroupsPageComponent,
    MyBookshelfAllPageComponent,
    MyBookshelfAllBooksPageComponent,
    MyBookshelfAllLibrariesPageComponent,
    MyBookshelfLikesPageComponent,

    BookshelfListComponent,
    BookshelfListItemComponent,
    AddBookComponent,
    AddBookshelfComponent,
    AppendixComponent,
    DeleteBookshelfComponent,
    EditBooklistComponent,
    EditBookshelfComponent,
    MoveBookshelfComponent,
  ],
  exports: [
    MyBookshelfMainPageComponent,
    MyBookshelfDetailPageComponent,
    MyBookshelfGroupsPageComponent,
    MyBookshelfExitedGroupsPageComponent,

    BookshelfListItemComponent,
    BookshelfListComponent,
    AddBookComponent,
    AddBookshelfComponent,
    AppendixComponent,
    DeleteBookshelfComponent,
    EditBooklistComponent,
    EditBookshelfComponent,
    MoveBookshelfComponent,
  ],
})
export class BookshelfModule {}
