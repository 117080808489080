import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BookItem, createBukURL } from '@bukio/viewer';

import { TypedDialog } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { AnalyticsService } from '../../services/analytics.service';

import { Book } from '../../models/book.model';

function chunk<T>(inputArray: T[], chunkSize: number): T[][] {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as T[][]);
  return result;
}

function getScrollbarWidth(): number {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.remove();

  return scrollbarWidth;
}

type ThumbnailView = BookItem & {
  address: string;
  locked: boolean;
  ratio: number;
};

@Component({
  selector: 'viewer-thumbnail-dialog',
  templateUrl: './thumbnail-dialog.component.html',
  styleUrls: ['./thumbnail-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class ThumbnailDialogComponent
  extends TypedDialog<{ book: Book; currentIid: string }, void>
  implements OnInit
{
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  private _virtualScrollViewport!: CdkVirtualScrollViewport;

  public _thumbnailViewRows = [] as ThumbnailView[][];
  public _thumbnailRowHeights = [] as number[];
  public _currentItemIndexPath = [-1, -1];

  public _itemCountPerRow!: number;
  public _horizontalRowMargin!: number;
  public _pagePadding = 8;

  constructor(
    private _element: ElementRef,
    private _eventBusService: EventBusService,
    private _analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this._itemCountPerRow = window.innerWidth < 768 ? 3 : 6;
    this._horizontalRowMargin = window.innerWidth < 768 ? 51 : 42;

    this._thumbnailViewRows = chunk(
      this._data.book.items.map((item, index) => {
        return {
          ...item,
          address: item.iid,
          locked: !this._data.book.canReadItem(item.iid, true),
          ratio: Math.round((item.fixedHeight! / item.fixedWidth!) * 100),
        };
      }),
      this._itemCountPerRow
    );

    this._updateThumbRowHeights();

    const index = this._data.book!.getIndexOfItem(this._data.currentIid);
    if (index === -1) {
      this._currentItemIndexPath = [-1, -1];
    } else {
      this._currentItemIndexPath = [
        Math.floor(index / this._itemCountPerRow),
        index % this._itemCountPerRow,
      ];
    }

    this._dialogRef.afterOpened().subscribe(() => {
      this._scrollToCurrentThumbnail();
    });

    // fromEvent(window, 'resize')
    //   .pipe(takeUntil(this.unsubscriber), debounceTime(500))
    //   .subscribe(() => {
    //     this._updateThumbRowHeights();
    //     setTimeout(() => {
    //       this._scrollToCurrentThumbnail();
    //     });
    //   });
  }

  _onCloseButtonClick(): void {
    this._close();
  }

  _onThumbnailClick([rowIndex, index]: [number, number]): void {
    const thumbnail = this._thumbnailViewRows[rowIndex][index];

    if (!thumbnail) {
      return;
    }

    this._analyticsService.trackEvent(
      'book-info-dialog' /* 이전 통계 값 사용*/,
      'thumbnail'
    );

    if (!thumbnail.locked) {
      this._eventBusService.fire('ThumbnailDialogComponent:thumbnailClick', {
        url: createBukURL(this._data.book.meta.bid) + '/' + thumbnail.address,
      });
    }

    this._close();
  }

  private _scrollToCurrentThumbnail(): void {
    if (this._currentItemIndexPath[0] !== -1) {
      this._virtualScrollViewport.scrollToIndex(this._currentItemIndexPath[0]);
    }
  }

  private _close(): void {
    this._dialogRef.close();
  }

  private _updateThumbRowHeights(): void {
    const scrollbarWidth = getScrollbarWidth();
    const width =
      this._virtualScrollViewport.elementRef.nativeElement.offsetWidth -
      scrollbarWidth -
      this._horizontalRowMargin * 2;
    const thumbWidth = width / this._itemCountPerRow - this._pagePadding * 2;

    this._thumbnailRowHeights = this._thumbnailViewRows.map(
      (thumbnailViewRow, index) => {
        const maxThumbnailHeight =
          Math.max(
            ...thumbnailViewRow.map((thumbnail) => {
              return (thumbnail.ratio / 100) * thumbWidth;
            })
          ) +
          this._pagePadding * 2;

        return maxThumbnailHeight;
      }
    );
  }
}
