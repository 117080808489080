<viewer-memo
  [menu]="myMenu"
  [limitHeight]="limitHeight"
  [content]="memo.content"
>
  <viewer-memo-type type="commentary"></viewer-memo-type>

  <viewer-memo-actions>
    <button class="icon-button" [disabled]="true">
      <sui-icon
        name="추천"
        [type]="memo.is_liked ? 'solid' : 'outline'"
      ></sui-icon>
      {{ memo.like_count }}
    </button>
    <button class="icon-button" [disabled]="true">
      <sui-icon name="복사" type="solid"></sui-icon>
      {{ memo.copy_count }}
    </button>
    <button class="text-button" (click)="_onSeeDetailsButtonClick()">
      본문 상세 확인
    </button>
  </viewer-memo-actions>

  <viewer-memo-author
    [avatarImageURL]="memo.author.avatar_image_url"
    [name]="memo.author.nickname"
  >
  </viewer-memo-author>
</viewer-memo>

<ng-template #myMenu>
  <sui-menu class="viewer-memo-menu" cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onEditMenuClick()">
      수정하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteMenuClick()">
      삭제하기
    </button>
  </sui-menu>
</ng-template>
