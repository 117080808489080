import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  templateUrl: './unsupported-browser-root.component.html',
  styleUrls: ['./unsupported-browser-root.component.scss'],
})
export class UnsupportedBrowserRootComponent {
  constructor(private _location: Location) {}

  _onBackButtonClick(): void {
    this._location.back();
  }
}
