import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lib-reading-group-form-field-hint',
  templateUrl: './reading-group-form-field-hint.component.html',
  styleUrl: './reading-group-form-field-hint.component.scss',
})
export class ReadingGroupFormFieldHintComponent {
  @HostBinding('class.is-valid') @Input() isValid = false;
  @HostBinding('class.is-error') @Input() isError = false;
}
