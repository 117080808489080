<div class="drag-overlay" #dragOverlay></div>
<div
  [cdkMenuTriggerFor]="_isDraggingFab ? null : menu"
  [cdkMenuPosition]="_menuPosition"
  #trigger="cdkMenuTriggerFor"
  cdkDrag
  cdkDragBoundary="viewer-root"
  (cdkDragStarted)="_onDragStarted()"
  (cdkDragEnded)="_onDragEnded()"
  (cdkMenuClosed)="_isMenuOpened = false"
  (cdkMenuOpened)="_onMenuOpened()"
>
  <button class="fab-button" [class.is-menu-opened]="_isMenuOpened">
    <sui-icon name="플러스"></sui-icon>
  </button>
  <div *ngIf="_hasUpdate" class="red-dot"></div>
  <div class="commentary-tooltip" *ngIf="_showCommentaryTooltip">
    <button
      class="close-button"
      (click)="_closeCommentaryTooltip(); $event.stopPropagation()"
    >
      <sui-icon name="엑스"></sui-icon>
    </button>
    <b>플로팅메뉴</b>를 열고<br />
    코멘테이터의 기록을 활성화해 보세요!
    <svg
      class="tail"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      "
    >
      <path
        d="M15,15C15,6.754 8.331,0.052 0.097,0L0,0L15,0L15,15Z"
        style="fill: rgb(224, 255, 0)"
      />
    </svg>
  </div>
</div>

<ng-template #menu>
  <div cdkMenu class="menu-container" (click)="$event.stopPropagation()">
    <!-- 기록 보기 -->
    <div
      class="section"
      *ngIf="
        _mode === 'single' &&
        _commentaries.length > 0 &&
        !_isCommentaryEditorMode
      "
    >
      <div class="section-item">
        <div class="title bold">코멘테이터 기록 보기</div>
        <sui-slide-toggle theme="blue">
          <input
            type="checkbox"
            [(ngModel)]="_showCommentarySelect"
            (ngModelChange)="_onCommentaryVisibilityChange()"
            [disabled]="!_hasOwnedCommentary"
          />
        </sui-slide-toggle>
      </div>
      <viewer-commentary-select
        [class.is-hidden]="!_showCommentarySelect"
        [commentaries]="_commentaries"
        [(selectedCommentaryId)]="_selectedCommentaryId"
        (selectedCommentaryIdChange)="_onSelectedCommentaryChange()"
      ></viewer-commentary-select>
      <div class="section-item" [class.is-hidden]="_hasOwnedCommentary">
        <div class="title placeholder">구매한 코멘터리북이 없습니다</div>
      </div>
    </div>
    <div class="section">
      <div class="section-item" *ngIf="!_isCommentaryEditorMode">
        <div class="title bold">내 기록 보기</div>
        <sui-slide-toggle theme="blue">
          <input
            type="checkbox"
            [(ngModel)]="_showMine"
            (ngModelChange)="_onSettingsChange()"
          />
        </sui-slide-toggle>
      </div>
      <div
        class="section-item clickable"
        (click)="_onOpenBookmarkDialogButtonClick()"
      >
        <div class="title">내 기록 전체 보기</div>
        <sui-icon name="오른쪽 이동"></sui-icon>
      </div>
    </div>
    <!-- 공유 메모 옵션 -->
    <div class="section" *ngIf="_mode === 'group'">
      <div
        class="section-item clickable"
        (click)="_onSyncMemoButtonClick()"
        [class.is-disabled]="!_hasUpdate"
      >
        <div class="title">
          <sui-icon
            name="도움말"
            type="solid"
            tooltip="현재 보고 있는 페이지/챕터에 새로운 공유 메모가 추가되면 '공유 메모 동기화하기' 버튼이 활성화됩니다. 버튼을 누르면 새로고침 후 추가된 공유 메모가 화면에 표시됩니다."
            [options]="{ tooltipClass: 'viewer-tooltip' }"
          ></sui-icon>
          공유 메모 동기화
          <div class="sync-dot" *ngIf="_hasUpdate"></div>
        </div>
        <sui-icon name="새로고침"></sui-icon>
      </div>
      <viewer-overlapping-count-select
        [class.is-disabled]="_isGroupMemberFocused"
        [(selectedValue)]="_minOverlappingCount"
        (selectedValueChange)="_onMinOverlappingCountChange()"
      ></viewer-overlapping-count-select>
    </div>
    <!-- 코멘터리 제출 -->
    <div
      class="section is-inverted"
      *ngIf="_mode === 'single' && _canSubmitCommentary"
    >
      <div
        class="section-item clickable is-center-aligned"
        (click)="_onSubmitCommentaryButtonClick()"
      >
        기록 제출하기
      </div>
    </div>
  </div>
</ng-template>
