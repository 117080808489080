<div class="drag-overlay" #dragOverlay></div>
<div
  #popup
  class="popup"
  cdkDrag
  (cdkDragStarted)="_onDragStarted()"
  (cdkDragReleased)="_onDragReleased()"
  [class.is-active]="_isOpened"
>
  <div class="header">
    <sui-icon name="옮기기" type="solid" cdkDragHandle></sui-icon>
    메모 목록
    <div class="expand"></div>
    <button sui-icon-button (click)="_onCloseButtonClick()">
      <sui-icon name="엑스"></sui-icon>
    </button>
  </div>
  <div class="body" *ngIf="_memo">
    <div class="text-box">
      <div class="text">{{ _memo.text }}</div>
    </div>
    <div class="memos">
      <div class="memo">
        <viewer-memo [limitHeight]="true" [content]="_memo.content">
          <viewer-memo-type type="commentary"></viewer-memo-type>
          <viewer-memo-actions></viewer-memo-actions>
          <viewer-memo-author
            [avatarImageURL]="_memo.avatarImageURL"
            [name]="_memo.nickname"
          ></viewer-memo-author>
        </viewer-memo>
      </div>
    </div>
  </div>
</div>
