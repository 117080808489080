import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

const EXACT_ROUTER_MATCH_OPTION: IsActiveMatchOptions = {
  paths: 'exact',
  queryParams: 'ignored',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  constructor(private _router: Router) {}

  get _isBookshelfPageActive(): boolean {
    return (
      this._router.isActive('/bookshelf', EXACT_ROUTER_MATCH_OPTION) ||
      this._router.isActive('/bookshelf/detail', EXACT_ROUTER_MATCH_OPTION)
    );
  }

  _onClickPurchaseButton(): void {
    window.open(environment.serviceOrigin, '_blank');
  }
}
