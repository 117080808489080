import { RawShippingInfo } from './shipping-info.model';

const VIRTUAL_ACCOUNT_PROVIDERS = ['wta', 'ltikorea'];

interface CPService {
  eid: string;
  title: string;
  logo: string;
}

interface Publisher {
  id: number;
  code: string;
  name: string;
  logo: number; // boolean
  type: 'common' /* | ...  */;
  eid: string;
  country: string;
  currency: string;
  description: string;
  homepageUrl: string | null;
  facebookId: string | null;
  twitterId: string | null;
  followers: number;
}

export interface RawUser {
  id: number;
  displayName: string;
  email: string;
  emails: {
    email: string;
    activated: number; // boolean
  }[];
  country: string;
  language: string;
  activated: number; // boolean
  shipping_addresses: RawShippingInfo[];
  timestamp: string;
  provider: string;
  affiliation: string;
  promoter: boolean;
  ecobag: boolean;
  partner: boolean;
  connected: { [sns: string]: string };
  reward: number;
  services: {
    channel: CPService[];
    ecobag: CPService[];
    imprint: CPService[];
    library: CPService[];
    magazine: CPService[];
    webzine: CPService[];
  };
  isAdmin: number; //boolean
  publishers?: Publisher[];
  libraries?: CPService & { id: number; emptyLogo?: boolean };
  books?: {
    bid: string;
    title: string;
    cover: string;
    date: string;
    publisehrId: number;
    author: string;
    price: number;
    discountedPrice: number;
  };
  group_reader_disabled: string | null;
  avatar_image_url: string;
  nickname: string;
}

export class User {
  id: RawUser['id'];
  affiliation: RawUser['affiliation'];
  name: RawUser['displayName'];
  email: RawUser['email'];
  emails: RawUser['emails'];
  connected: RawUser['connected'];
  provider: RawUser['provider'];
  shipping_addresses: RawUser['shipping_addresses'];
  publishers: RawUser['publishers'];
  isAdmin: RawUser['isAdmin'];
  group_reader_disabled: RawUser['group_reader_disabled'];
  avatar_image_url: RawUser['avatar_image_url'];
  nickname: RawUser['nickname'];

  currency: string;
  isVirtual: boolean; // 타 서비스의 유저 정보와 연동되어 자동으로 생성된 계정

  constructor(rawUser: RawUser) {
    this.id = rawUser.id;
    this.affiliation = rawUser.affiliation;
    this.name = rawUser.displayName;
    this.email = rawUser.email;
    this.emails = rawUser.emails;
    this.connected = rawUser.connected;
    this.provider = rawUser.provider;
    this.shipping_addresses = rawUser.shipping_addresses;
    this.publishers = rawUser.publishers;
    this.isAdmin = rawUser.isAdmin;
    this.group_reader_disabled = rawUser.group_reader_disabled;
    this.avatar_image_url = rawUser.avatar_image_url;
    this.nickname = rawUser.nickname;

    this.currency = 'KRW';
    this.isVirtual = VIRTUAL_ACCOUNT_PROVIDERS.indexOf(rawUser.provider) !== -1;
  }
}

export interface UserProfile {
  id: string;
  nickname: string;
  avatar_image_url: string;
  is_blocked: number;
  following_count: number;
  follower_count: number;
  is_following: number; // boolean
}

export interface UploadedImageInfo {
  mimetype: string;
  originalname: string;
  size: number;
  url: string;
}

export interface BlockedUsers {
  offset: number;
  limit: number;
  total: number;
  items: {
    id: string;
    nickname: string;
    avatar_image_url: string;
  }[];
}

export interface Notification {
  id: string;
  created_at: string;
  type:
    | 'message'
    | 'group_invited'
    | 'user_follow'
    | 'memo_copy'
    | 'memo_like'
    | 'memo_hide'
    | 'memo_show'
    | 'group_kicked'
    | 'group_reader_disabled'
    | 'group_reader_enabled';
  user_id?: string;
  nickname?: string;
  group_name?: string;
  bid?: string;
  url?: string;
  is_follow?: number;
  until?: string; // 함께 읽기 기능 정지 만료일
  button?: string;
  message?: string;
}
