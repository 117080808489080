<div class="divider desktop"></div>
<div class="tabs">
  <div class="tab" *ngFor="let tab of _tabs" (click)="_onSelectTab(tab[0])">
    <div class="tab-title" [class.is-active]="tab[0] === _selectedTab">
      <div class="highlight"></div>
      <span
        >{{ tab[1] }} ({{
          tab[0] === _Tab.joined ? _myGroupsTotal : _allGroupsTotal
        }})</span
      >
    </div>
  </div>
  <button
    sui-box-button
    varSize="medium40"
    (click)="_onCreateGroupButtonClick()"
  >
    새로운 그룹 만들기
  </button>
  <button
    sui-box-button
    varSize="medium40"
    *ngIf="_myGroupsTotal === 0 && _selectedTab === _Tab.joined"
    (click)="_onSelectTab(_Tab.all)"
  >
    그룹 둘러보기
  </button>
</div>
<div class="tab-content">
  <div class="search-controls" *ngIf="_selectedTab === _Tab.all">
    <div class="search-bar">
      <div class="prefix-icon">
        <sui-icon name="검색"></sui-icon>
      </div>
      <input
        placeholder="그룹명을 입력해 주세요."
        [(ngModel)]="_searchKeyword"
        (keydown)="_onSearchBarKeyDown($event)"
      />
      <button
        *ngIf="_searchKeyword"
        (click)="_onClearSearchKeywordButtonClick()"
        class="clear-button"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
            fill="#CCCCCC"
          />
        </svg>
      </button>
    </div>
    <div class="search-options">
      <select
        sui-select
        [(ngModel)]="_orderBy"
        (ngModelChange)="_onSearchOptionsChange()"
      >
        <option *ngFor="let option of _orderByOptions" [ngValue]="option[0]">
          {{ option[1] }}
        </option>
      </select>
      <sui-checkbox>
        공개 그룹만 보기
        <input
          type="checkbox"
          [(ngModel)]="_showPublicGroupOnly"
          (ngModelChange)="_onSearchOptionsChange()"
        />
      </sui-checkbox>
    </div>
  </div>
  <div class="groups" #groupList>
    <div class="group-list-item" *ngFor="let group of _groups; index as i">
      <lib-reading-group-list-item [group]="group">
        <button
          varSize="medium40"
          sui-box-button
          (click)="_onGroupDetailsButtonClick(i)"
        >
          상세보기
        </button>
        @if (group.status === _JoinStatus.joined || group.status ===
        _JoinStatus.owned) {
        <button
          varSize="medium40"
          sui-solid-button
          (click)="_onEnterGroupButtonClick(group.id)"
        >
          함께읽기 시작하기
        </button>
        } @else {
        <button
          varSize="medium40"
          sui-solid-button
          (click)="_onJoinGroupButtonClick(i)"
          [disabled]="
            group.status === _JoinStatus.kicked ||
            group.max_member_count === group.member_count
          "
        >
          가입하기
        </button>
        }
      </lib-reading-group-list-item>
    </div>
    <div *ngIf="_groups" (libIntersectionListener)="_onScrollEnd()"></div>

    <div class="placeholder" *ngIf="_groups && _groups.length === 0">
      <ng-container *ngIf="_selectedTab === _Tab.joined">
        <p>
          함께읽기가 가능한 그룹이 없습니다.<br />새로운 그룹을 만들거나 참여
          가능한 그룹을 둘러보세요.
        </p>
      </ng-container>
      <ng-container *ngIf="_selectedTab === _Tab.all">
        <p>함께읽기가 가능한 그룹이 없습니다.</p>
      </ng-container>
    </div>
  </div>
  <div class="mobile-buttons">
    <button
      varSize="large"
      sui-box-button
      (click)="_onCreateGroupButtonClick()"
    >
      새로운 그룹 만들기
    </button>
    <button
      varSize="large"
      *ngIf="_groups && _groups.length === 0"
      sui-box-button
      (click)="_onSelectTab(_Tab.all)"
    >
      그룹 둘러보기
    </button>
  </div>
</div>
