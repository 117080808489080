import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

const ZOOM_STEP = 25;
const MAX_ZOOM = 300;
const MIN_ZOOM = 100;

@Component({
  selector: 'viewer-fixedlayout-page-control',
  templateUrl: './fixedlayout-page-control.component.html',
  styleUrls: ['./fixedlayout-page-control.component.scss'],
})
export class FixedlayoutPageControlComponent implements OnInit, OnDestroy {
  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  @HostBinding('class.disabled') disabled = false;
  @HostBinding('class.hidden') hidden = false;

  public currentScale = 100;

  constructor(private eventBusService: EventBusService) {}

  ngOnInit(): void {
    if (CrossBrowsing.supportsPinchZoom) {
      this.disabled = true;
    } else {
      this.eventBusService
        .on('ContentsComponent:bookLoad')
        .pipe(takeUntil(this.unsubscribeBroadcast))
        .subscribe(() => {
          this.disabled = true;
        });

      this.eventBusService
        .on('ContentsComponent:itemLoad')
        .pipe(takeUntil(this.unsubscribeBroadcast))
        .subscribe(({ items }) => {
          this.disabled = items.some((item) => item.layout !== 'fixed');
        });
    }

    this.eventBusService
      .on('ContentsComponent:zoomScaleChange')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ scale }) => {
        this.currentScale = scale;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  zoomButtonClick(value: 1 | -1): void {
    let newScale = this.currentScale + value * ZOOM_STEP;

    if (newScale > MAX_ZOOM) {
      newScale = MAX_ZOOM;
    } else if (newScale < MIN_ZOOM) {
      newScale = MIN_ZOOM;
    }

    this.eventBusService.fire('FixedlayoutPageControlComponent:changeZoom', {
      scale: newScale,
    });
  }

  resetZoomButtonClick(): void {
    this.eventBusService.fire('FixedlayoutPageControlComponent:changeZoom', {
      scale: 100,
    });
  }
}
