import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { UserProfile } from 'shared/models';
import { UsersAPIService } from 'shared/services';
import { AlertService, DialogService } from 'shared/ui';
import {
  EditProfileComponent,
  FollowerComponent,
  FollowingComponent,
} from 'user';

import { environment } from '../../../environments/environment';

import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';
import {
  AppSettings,
  AppSettingsStoreService,
} from '../../services/app-settings-store.service';
import { NativeEnvironmentService } from '../../services/native-environment.service';

@Component({
  templateUrl: './settings-main-page.component.html',
  styleUrl: './settings-main-page.component.scss',
})
export class SettingsMainPageComponent implements OnInit {
  @ViewChild('backupInput') backupInputElem?: ElementRef<HTMLInputElement>;

  public _userName?: string;
  public _userEmail?: string;

  public _profile$?: Observable<UserProfile>;

  public _appVersion: string;
  public _webAppVersion: string;

  public _settings?: AppSettings;

  constructor(
    private _userService: UserService,
    private _usersAPIService: UsersAPIService,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _authService: AuthService,
    private _appSettingsStoreService: AppSettingsStoreService,
    private _nativeEnvironmentService: NativeEnvironmentService
  ) {
    this._appVersion = this._nativeEnvironmentService.version ?? '';
    this._webAppVersion = environment.appVersion;
  }

  ngOnInit(): void {
    this._appSettingsStoreService.settings$.subscribe((settings) => {
      this._settings = settings;
    });

    this._userEmail = this._userService.getUser()?.email;
    this._userName = this._userService.getUser()?.name;
    this._getProfile();
  }

  private _getProfile(): void {
    this._profile$ = this._usersAPIService.getUsersProfile();
  }

  _onFollowersClick(): void {
    this._dialogService
      .open(FollowerComponent)
      .afterClosed()
      .pipe(filter((result) => !!result && result.updated))
      .subscribe(() => {
        this._getProfile();
      });
  }

  _onFollowingsClick(): void {
    this._dialogService
      .open(FollowingComponent)
      .afterClosed()
      .pipe(filter((result) => !!result && result.updated))
      .subscribe(() => {
        this._getProfile();
      });
  }

  _onEditProfileButtonClick(): void {
    this._dialogService
      .open(EditProfileComponent, {
        data: {
          initial: false,
        },
      })
      .afterClosed()
      .pipe(filter((result) => !!result && !result.canceled))
      .subscribe(() => {
        this._getProfile();
      });
  }

  _onLogoutButtonClick(): void {
    this._alertService
      .openConfirm('', '로그아웃 하겠습니까?', '로그아웃')
      .afterClosed()
      .pipe(filter((ok) => !!ok))
      .subscribe(() => {
        this._authService.logout(location.origin);
      });
  }

  _onContactButtonClick(): void {
    window.open(`${environment.serviceOrigin}/help/contact`, '_blank');
  }

  _onDeleteAccountButtonClick(): void {
    window.open(`${environment.serviceOrigin}/account/info`, '_blank');
  }

  _onSettingsChange<T extends keyof AppSettings>(
    key: T,
    value: AppSettings[T]
  ): void {
    this._appSettingsStoreService.update(key, value);
  }

  _onBackupDrawingSettingsChange(isOn: boolean): void {
    if (!isOn) {
      this._alertService
        .openConfirm(
          undefined,
          'OFF 상태로 변경 시, 알 수 없는 경로로 필기 유실 문제가 발생한 경우 북이오 서버에 자동 백업된 필기 기록 데이터로 복원이 불가합니다. 상태를 변경하시겠습니까?'
        )
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this._onSettingsChange('backupDrawing', isOn);
          } else {
            this.backupInputElem!.nativeElement.checked = true;
          }
        });
    } else {
      this._onSettingsChange('backupDrawing', isOn);
    }
  }
}
