import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReadingGroup } from 'shared/models';

@Component({
  selector: 'lib-group-item-box',
  templateUrl: './group-item-box.component.html',
  styleUrl: './group-item-box.component.scss',
})
export class GroupItemBoxComponent {
  @Input() readingGroup!: ReadingGroup;
  @Input() usesContainerQuery = false;
  @Output() bookClick = new EventEmitter();

  public _onBookClick(): void {
    this.bookClick.emit();
  }
}
