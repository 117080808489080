import { Injectable } from '@angular/core';
import { Address, PDFTextInfo, ViewerAPIService } from '@bukio/viewer';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import { BukJSON } from '../models/book.model';
import { APIService } from './api.service';
import { isSharedAddress } from '../utils/address';

@Injectable()
export class LibAPIService implements ViewerAPIService {
  constructor(private apiService: APIService) {}

  getBukJSON(address: Address): Observable<BukJSON> {
    const url = address.toString();

    return this.apiService.getBukJSON(address).pipe(
      switchMap((bukJSON) => {
        if (!isSharedAddress(address)) {
          return of(bukJSON);
        }
        // if (!address.range) {
        //   return of(bukJSON);
        // }

        const canReadItem =
          bukJSON.permission.read.indexOf(
            address.iid || bukJSON.items[0]?.iid
          ) === -1;

        if (canReadItem) {
          bukJSON.permission.referer = url;
          return of(bukJSON);
        }

        return this.apiService.getPermissionItems(url).pipe(
          map((response) => {
            if (response.items && response.items.length > 0) {
              bukJSON.permission.referer = url;

              response.items.forEach(({ iid, url }) => {
                const item = bukJSON.items.find((item) => item.iid === iid);
                if (item) {
                  item.url = url;
                }
              });
            }

            bukJSON.permission.code = response.code;

            return bukJSON;
          }),
          catchError((error) => {
            console.warn('perm check error', error);
            return of(bukJSON);
          })
        );
      })
    );
  }

  getPDFText(bid: string): Observable<PDFTextInfo[]> {
    return this.apiService.getPDFText(bid);
  }
}
