import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[viewerStopPropagation]',
})
export class StopPropagationDirective implements OnInit {
  @Input('viewerStopPropagation') event?: string;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.event &&
      this.element.nativeElement.addEventListener(this.event, (e: Event) => {
        e.stopPropagation();
      });
  }
}
