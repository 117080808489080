export interface MyCommentatorInfo {
  is_commentator: number; // boolean
  profile: {
    nickname: string;
    avatar_image_url: string;
    tags: string[];
  } | null;
  annotation_requested: {
    bid: string;
    expired_at: string;
  }[];
  feedback_arrived: number; // boolean
}
