import { Injectable } from '@angular/core';
import { DialogRef, DialogService, TypedDialog } from 'shared/ui';

import { ReadingModeDialogComponent } from '../dialogs/reading-mode-dialog/reading-mode-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ReadingGroupDialogService {
  constructor(private _dialogService: DialogService) {}

  openReadingModeSelection(
    bid: string
  ): DialogRef<
    TypedDialog<{ bid: string }, { canceled?: boolean; groupId?: number }>,
    { canceled?: boolean; groupId?: number }
  > {
    return this._dialogService.open(ReadingModeDialogComponent, {
      data: { bid },
    });
  }
}
