import { ViewerPDFOverlayViewProviderService } from '@bukio/viewer';
import { Observable } from 'rxjs';

export type DrawingTool = 'pen' | 'eraser' | 'marquee';

export interface DrawingPenOption {
  color: string;
  width: number;
  straight: boolean;
}

export abstract class PDFDrawingService extends ViewerPDFOverlayViewProviderService {
  public abstract isDrawingMode: boolean;
  public abstract isDeviceSupportsStylus: boolean;

  public abstract readonly historyChange$: Observable<{
    canUndo: boolean;
    canRedo: boolean;
  }>;

  abstract loadDrawings(bid: string): Observable<void>;
  abstract saveDrawings(): Observable<void>;
  abstract clear(): void;
  abstract undo(): void;
  abstract redo(): void;
  abstract setTool(tool: DrawingTool, option?: DrawingPenOption): void;

  abstract getDrawnItemList(): Observable<string[]>;
  abstract getDrawingURL(iid: string): string | null;
}
