import { Component, Input } from '@angular/core';
import { Bookshelf } from 'shared/models';

@Component({
  selector: 'lib-bookshelf-list',
  templateUrl: './bookshelf-list.component.html',
  styleUrl: './bookshelf-list.component.scss',
})
export class BookshelfListComponent {
  @Input() bookshelf?: Bookshelf;
  @Input() userId: any;
}
