import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil, filter, delay } from 'rxjs/operators';

import { BroadcastService } from '../../services/broadcast.service';
import { EventBusService } from '../../services/event-bus.service';

@Component({
  selector: 'viewer-fullscreen-loader',
  templateUrl: './fullscreen-loader.component.html',
  styleUrls: ['./fullscreen-loader.component.scss'],
})
export class FullscreenLoaderComponent implements OnInit, OnDestroy {
  @HostBinding('class.active') active = true;

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  constructor(private $: BroadcastService, private eventBus: EventBusService) {}

  ngOnInit(): void {
    merge(this.eventBus.on('ContentsComponent:itemLoad'))
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this.active = false;
      });

    this.$.on<boolean>('window.resize.start')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        filter((reload) => reload)
      )
      .subscribe(() => {
        this.active = true;
      });

    this.$.on('window.resize.finish')
      .pipe(takeUntil(this.unsubscribeBroadcast), delay(100))
      .subscribe((reload) => {
        if (!reload) {
          this.active = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }
}
