import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { InviteableReadingGroupMember, ReadingGroup } from 'shared/models';
import {
  APIError,
  OthersBookshelfOpenerService,
  ReadingGroupMembersAPIService,
  ReadingGroupsAPIService,
} from 'shared/services';
import { ToastService } from 'shared/ui';

enum ListType {
  followingAndGroups,
  following,
  groupMembers,
  searchResults,
}

@Component({
  selector: 'lib-reading-group-invitation',
  templateUrl: './reading-group-invitation.component.html',
  styleUrl: './reading-group-invitation.component.scss',
})
export class ReadingGroupInvitationComponent implements OnInit {
  // 초대할 그룹
  @Input() groupId!: number;

  // 팔로잉 수, 그룹 목록
  public _totalFollowingCount?: number;
  public _following?: InviteableReadingGroupMember[];
  public _followingListEnded = false;
  public _isLoadingFollowing = false;

  public _myGroups?: ReadingGroup[];
  private _myGroupsListEnded = false;
  private _isLoadingMyGroups = false;

  // 검색 결과
  private _searchResultSubscription?: Subscription;
  private _searchValue = '';
  private _searchEnded = false;
  public _searchResults?: InviteableReadingGroupMember[];
  public _searchFormControl = new FormControl('', [
    Validators.minLength(2),
    Validators.maxLength(30),
  ]);

  // 그룹원 목록
  public _selectedGroup?: ReadingGroup;
  public _selectedGroupMembers?: InviteableReadingGroupMember[];
  private _selectedGroupMembersListEnded = false;

  // 목록 visibility 컨트롤
  public readonly _ListType = ListType;
  public _currentListType?: ListType;

  constructor(
    private _toastService: ToastService,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _readingGroupMembersAPIService: ReadingGroupMembersAPIService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService
  ) {
    if (window.innerWidth < 768) {
      this._currentListType = ListType.followingAndGroups;
    }
  }

  private get _isLoadingSearchResult(): boolean {
    return (
      !!this._searchResultSubscription && !this._searchResultSubscription.closed
    );
  }

  ngOnInit(): void {
    this._loadMyGroups();
    this._loadFollowings();
  }

  _onSearchBarKeyDown(event: KeyboardEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = 'which' in event ? event.which : (event as any).keyCode;
    const isComposing = key === 229;

    if (event.key === 'Enter' && !isComposing) {
      this._searchFormControl.markAsPristine();

      if (this._searchFormControl.valid) {
        this._onSearch();
      }
    }
  }

  _onSearch(): void {
    const newListType =
      this._searchFormControl.value!.length === 0
        ? window.innerWidth < 768
          ? ListType.followingAndGroups
          : undefined
        : ListType.searchResults;

    this._setListType(newListType);

    if (this._currentListType === ListType.searchResults) {
      this._searchValue = this._searchFormControl.value!;
      this._loadSearchResult(true);
    }
  }

  _onListBackButtonClick(): void {
    this._setListType(ListType.followingAndGroups);
  }

  _onSelectFollowing(): void {
    this._setListType(ListType.following);
  }

  _onSelectGroup(index: number): void {
    const group = this._myGroups?.[index];

    if (!group) {
      return;
    }

    this._setListType(ListType.groupMembers);
    this._selectedGroup = group;
    this._loadSelectedGroupMembers();
  }

  _onCancelSearchButtonClick(): void {
    this._searchFormControl.setValue('');
    this._onSearch();
  }

  _onGroupMembersListScrollEnd(): void {
    this._loadSelectedGroupMembers();
  }

  _onFollowingAndGroupsListScrollEnd(): void {
    this._loadMyGroups();
  }

  _onSearchResultScrollEnd(): void {
    this._loadSearchResult(false);
  }

  _onFollowingListScrollEnd(): void {
    this._loadFollowings();
  }

  _onInviteButtonClick(member: InviteableReadingGroupMember): void {
    this._readingGroupsAPIService.invite(this.groupId, member.id).subscribe({
      next: () => {
        member.is_inviteable = 0;
        this._toastService.open('초대가 완료되었습니다.');
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  private _setListType(listType?: ListType): void {
    this._currentListType = listType;
    this._selectedGroup = undefined;
    this._selectedGroupMembers = undefined;
    this._selectedGroupMembersListEnded = false;
    this._searchResults = undefined;
  }

  private _loadMyGroups(): void {
    if (this._isLoadingMyGroups || this._myGroupsListEnded) {
      return;
    }

    this._isLoadingMyGroups = true;

    this._readingGroupsAPIService
      .getJoined(this._myGroups?.length, 30)
      .subscribe((response) => {
        this._isLoadingMyGroups = false;
        this._myGroupsListEnded = response.items.length < response.limit;
        this._myGroups = (this._myGroups ?? []).concat(response.items);
      });
  }

  private _loadFollowings(): void {
    if (this._isLoadingFollowing || this._followingListEnded) {
      return;
    }

    this._isLoadingFollowing = true;

    this._readingGroupMembersAPIService
      .getInviteableToGroup(this.groupId, this._following?.length, 30, {
        followings: true,
      })
      .subscribe((response) => {
        if (!this._following) {
          this._following = [];
        }
        this._totalFollowingCount = response.total;
        this._following = this._following.concat(response.items);
        this._followingListEnded = response.items.length < response.limit;
        this._isLoadingFollowing = false;
      });
  }

  private _loadSelectedGroupMembers(): void {
    if (!this._selectedGroup || this._selectedGroupMembersListEnded) {
      return;
    }

    this._readingGroupMembersAPIService
      .getInviteableToGroup(
        this.groupId,
        this._selectedGroupMembers?.length,
        30,
        {
          target_group_id: this._selectedGroup.id,
        }
      )
      .subscribe((response) => {
        this._selectedGroupMembersListEnded =
          response.items.length < response.limit;

        if (!this._selectedGroupMembers) {
          this._selectedGroupMembers = [];
        }

        this._selectedGroupMembers = this._selectedGroupMembers.concat(
          response.items
        );
      });
  }

  private _loadSearchResult(reset: boolean): void {
    if (!reset && (this._isLoadingSearchResult || this._searchEnded)) {
      return;
    }

    this._searchResultSubscription?.unsubscribe();

    this._searchResultSubscription = this._readingGroupMembersAPIService
      .getInviteableToGroup(
        this.groupId,
        reset ? 0 : this._searchResults?.length,
        30,
        {
          q: this._searchValue,
        }
      )
      .subscribe({
        next: (response) => {
          this._searchResults = (
            response.offset === 0 || !this._searchResults
              ? []
              : this._searchResults
          ).concat(response.items);
          this._searchEnded = response.items.length < response.limit;
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onReadingGroupMemberClick(member: InviteableReadingGroupMember): void {
    this._othersBookshelfOpenerService.open(member.id);
  }
}
