import { Component, Inject, ViewChild } from '@angular/core';
import { Memo } from 'shared/models';
import { ToastService, TypedDialog } from 'shared/ui';
import { ReadingGroupsService } from '../../services/reading-groups.service';
import {
  MemoSharingImageComponent,
  MemoSharingImageRatio,
} from '../../components/memo-sharing-image/memo-sharing-image.component';
import { BookService } from '../../services/book.service';
import {
  VIEWER_ENVIRONMENT,
  ViewerModuleEnvironment,
} from '../../viewer.module';
import { ImageSharingService } from '../../services/image-sharing.service';

@Component({
  selector: 'viewer-memo-sharing-dialog',
  templateUrl: './memo-sharing-dialog.component.html',
  styleUrl: './memo-sharing-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MemoSharingDialogComponent extends TypedDialog<
  {
    memo: {
      text: Memo['text'];
      content: Memo['content'];
      like_count: Memo['like_count'];
      copy_count: Memo['copy_count'];
    };
    author: Omit<Memo['author'], 'id'>;
  },
  void
> {
  @ViewChild(MemoSharingImageComponent, { static: true })
  private _imageComp!: MemoSharingImageComponent;

  public _bookTitle: string;
  public _coverImageURL: string;

  public readonly _readingMode: 'single' | 'group';
  public _hideCounts: boolean;
  public _imageRatio: MemoSharingImageRatio = '1:1';
  public _isGeneratingImage = false;

  public _isApp: boolean;

  public readonly _imageRatioList: MemoSharingImageRatio[] = [
    '1:1',
    '4:5',
    '9:16',
  ];

  constructor(
    readingGroupsService: ReadingGroupsService,
    bookService: BookService,
    @Inject(VIEWER_ENVIRONMENT) environemnt: ViewerModuleEnvironment,
    private _imageSharingService: ImageSharingService,
    private _toastService: ToastService
  ) {
    super();

    this._isApp = environemnt.isApp;

    this._readingMode =
      readingGroupsService.currentGroup === null ? 'single' : 'group';
    this._hideCounts = this._readingMode === 'single';

    if (bookService.book) {
      this._bookTitle = bookService.book.meta.title;
      this._coverImageURL = bookService.book.meta.cover;
    } else {
      throw new Error('book is null');
    }
  }

  private _generateImage(): Promise<Blob> {
    this._isGeneratingImage = true;

    return this._imageComp
      .toBlob('image/jpeg', 1)
      .catch((error) => {
        this._toastService.openWarning('이미지 생성 중 오류가 발생하였습니다.');
        throw error;
      })
      .finally(() => {
        this._isGeneratingImage = false;
      });
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  _onShareToSNSButtonClick(event: MouseEvent): void {
    const button = event.currentTarget as HTMLButtonElement;
    this._generateImage().then((blob) => {
      this._imageSharingService.shareToSNS(
        blob,
        button.getBoundingClientRect()
      );
    });
  }

  _onSaveButtonClick(): void {
    this._generateImage().then((blob) => {
      this._imageSharingService.download(blob);
    });
  }
}
