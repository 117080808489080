import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Config } from '../constants/config';

import { ViewerEventDataMap } from '../models/viewer-event-data-map.model';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private eventBus = new Subject<{ key: any; data?: any }>();

  fire<T extends keyof ViewerEventDataMap>(
    key: T,
    data?: ViewerEventDataMap[T]
  ): void {
    if (Config.debug) {
      console.log(`>> ${key}\n`, data);
    }

    this.eventBus.next({ key, data });
  }

  on<T extends keyof ViewerEventDataMap>(
    key: T
  ): Observable<ViewerEventDataMap[T]> {
    return this.eventBus.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => event.data)
    );
  }
}
