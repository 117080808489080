<div class="popup">
  <div class="box">
    <div
      class="avatar"
      [style.background-image]="
        _avatarImageURL ? 'url(' + _avatarImageURL + ')' : null
      "
    >
      <div class="icon">
        <sui-icon name="뷰 온"></sui-icon>
      </div>
    </div>
    <div class="nickname">
      {{ _nickname }}
    </div>
    <span>님의 하이라이트 보는 중</span>
    <button
      class="close-button"
      sui-icon-button
      (click)="_onCloseButtonClick()"
    >
      <sui-icon name="엑스"></sui-icon>
    </button>
  </div>
</div>
