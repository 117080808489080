<div class="container">
  <div class="label">
    {{ label }}
    <div class="required-mark" *ngIf="required"></div>
  </div>
  <div class="input-box">
    <div class="input">
      <ng-content select="textarea, input"></ng-content>
    </div>
    <ng-content></ng-content>
    <div class="hint">
      <ng-content select="lib-reading-group-form-field-hint"></ng-content>
    </div>
  </div>
</div>
