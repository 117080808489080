import { Injectable } from '@angular/core';
import {
  UploadedImageInfo,
  UserProfile,
  Reader,
  FollowableReader,
  BlockedUsers,
  Notification,
} from 'shared/models';
import { Observable, catchError, map, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchAndRethrowAPIError } from './api-error';
import { QueryResult } from './query-result';

const FOLLOW_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'User not found': '사용자가 존재하지 않습니다.',
};

const UNFOLLOW_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'User not found': '사용자가 존재하지 않습니다.',
};

const BLOCK_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'User not found': '사용자가 존재하지 않습니다.',
};

const UNBLOCK_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'User not found': '사용자가 존재하지 않습니다.',
};

const DUPLICATE_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'Duplicate nickname': '닉네임이 중복됐습니다.',
};

@Injectable({
  providedIn: 'root',
})
export class UsersAPIService {
  constructor(private http: HttpClient) {}

  // TODO: 내 프로필을 가져오는 API -> getMyProfile
  getUsersProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`/api/v1/users`, {
      withCredentials: true,
    });
  }

  getUserProfile(
    userId: string
  ): Observable<UserProfile & { is_blocked: boolean }> {
    return this.http
      .get<UserProfile & { is_blocked: boolean }>(`/api/v1/users/${userId}`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError());
  }

  getMyFollowings(): Observable<QueryResult<Reader>> {
    return this.http
      .get<QueryResult<Reader>>(`/api/v1/users/followings`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError());
  }

  getMyFollowers(): Observable<QueryResult<Reader>> {
    return this.http
      .get<QueryResult<Reader>>(`/api/v1/users/followers`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError());
  }

  block(userId: string): Observable<void> {
    return this.http
      .put<void>(`/api/v1/users/${userId}/block`, undefined, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(BLOCK_ERROR_MSG_MAP));
  }

  unblock(userId: string): Observable<void> {
    return this.http
      .delete<void>(`/api/v1/users/${userId}/block`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(UNBLOCK_ERROR_MSG_MAP));
  }

  getBlockedUsers(offset: number, limit: number): Observable<BlockedUsers> {
    const params = {
      offset,
      limit,
    };
    return this.http.get<BlockedUsers>(`/api/v1/users/blocked`, {
      params,
      withCredentials: true,
    });
  }

  DeleteBlockedUsers(user_id: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/users/${user_id}/block`, {
      withCredentials: true,
    });
  }

  follow(userId: string): Observable<void> {
    return this.http
      .put<void>(`/api/v1/users/${userId}/follow`, undefined, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(FOLLOW_ERROR_MSG_MAP));
  }

  unfollow(userId: string): Observable<void> {
    return this.http
      .delete<void>(`/api/v1/users/${userId}/follow`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(UNFOLLOW_ERROR_MSG_MAP));
  }

  getFollowers(
    offset: number = 0,
    limit: number = 30
  ): Observable<QueryResult<FollowableReader>> {
    const params = {
      offset,
      limit,
    };
    return this.http
      .get<QueryResult<FollowableReader>>(`/api/v1/users/followers`, {
        params,
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(UNFOLLOW_ERROR_MSG_MAP));
  }

  getOtherFollowers(
    offset: number = 0,
    limit: number = 30,
    user_id: string
  ): Observable<QueryResult<FollowableReader>> {
    const params = {
      offset,
      limit,
    };

    return this.http
      .get<QueryResult<FollowableReader>>(
        `/api/v1/users/${user_id}/followers`,
        {
          params,
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(UNFOLLOW_ERROR_MSG_MAP));
  }

  getFollowings(
    offset: number = 0,
    limit: number = 30
  ): Observable<QueryResult<Reader>> {
    const params = {
      offset,
      limit,
    };

    return this.http
      .get<QueryResult<Reader>>(`/api/v1/users/followings`, {
        params,
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(UNFOLLOW_ERROR_MSG_MAP));
  }

  getOthersFollowings(
    offset: number = 0,
    limit: number = 30,
    user_id: string
  ): Observable<QueryResult<FollowableReader>> {
    const params = {
      offset,
      limit,
    };

    return this.http
      .get<QueryResult<FollowableReader>>(
        `/api/v1/users/${user_id}/followings`,
        {
          params,
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(UNFOLLOW_ERROR_MSG_MAP));
  }

  checkNicknameDuplication(nickname: string): Observable<boolean> {
    return this.http
      .post<boolean>(
        `/api/v1/users/nicknames`,
        {
          nickname,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(
        map(() => false),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return of(true);
          }

          throw error;
        }),
        catchAndRethrowAPIError(DUPLICATE_ERROR_MSG_MAP)
      );
  }

  changeMyProfile(
    nickname: string,
    avatar_image_url: string
  ): Observable<void> {
    const params = {
      nickname,
      avatar_image_url,
    };

    return this.http.put<void>(`/api/v1/users`, params, {
      withCredentials: true,
    });
  }

  cancelChangeProfile(): Observable<void> {
    return this.http.put<void>(
      `/api/v1/users`,
      {
        canceled: true,
      },
      {
        withCredentials: true,
      }
    );
  }

  uploadImage(imageFile: any): Observable<UploadedImageInfo[]> {
    console.log(imageFile);

    const formData: FormData = new FormData();

    formData.append('file', imageFile[0]);

    return this.http.post<UploadedImageInfo[]>(`/api/2.0/image`, formData, {
      withCredentials: true,
    });
  }

  getNotificationList(
    offset: number,
    limit: number
  ): Observable<QueryResult<Notification>> {
    const params = {
      offset,
      limit,
    };

    return this.http.get<QueryResult<Notification>>(`/api/v1/notifications`, {
      params,
      withCredentials: true,
    });
  }

  deleteNotification(notification_ids: string): Observable<string> {
    const params = {
      notification_ids,
    };
    return this.http.delete<string>(`/api/v1/notifications`, {
      withCredentials: true,
      params,
    });
  }
}
