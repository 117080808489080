<div
  class="wrapper"
  [style.background-image]="'url(' + readingGroup.content.image_url + ')'"
  [ngClass]="{ 'container-query': usesContainerQuery }"
>
  <ng-content select="[lib-group-item-box-checkbox]"></ng-content>
  <div class="status">
    <sui-flag-tag
      [color]="readingGroup.type === 'private' ? 'gray' : 'point'"
      >{{ readingGroup.type === 'private' ? '비공개' : '공개' }}</sui-flag-tag
    >
  </div>
  <div
    class="img-wrapper"
    [ngClass]="{ 'container-query': usesContainerQuery }"
  >
    <div
      class="image-container"
      [ngClass]="{ 'container-query': usesContainerQuery }"
    >
      <img
        src="{{ readingGroup.content.image_url }}"
        [ngClass]="{ 'container-query': usesContainerQuery }"
        alt="책 이미지"
        (click)="_onBookClick()"
      />
      <div
        class="book-info"
        (click)="_onBookClick()"
        [ngClass]="{ 'container-query': usesContainerQuery }"
      >
        <span [ngClass]="{ 'container-query': usesContainerQuery }">{{
          readingGroup.content.title
        }}</span>
        <span [ngClass]="{ 'container-query': usesContainerQuery }">{{
          readingGroup.content.author
        }}</span>
      </div>
    </div>
  </div>
  <div class="info-wrapper">
    <div
      class="status"
      [ngClass]="{
        'container-query': usesContainerQuery,
        'hidden-status':
          readingGroup.is_hidden &&
          (readingGroup.status === 'invited' ||
            readingGroup.status === 'kicked')
      }"
    >
      <span class="hidden-tag" *ngIf="readingGroup.is_hidden">숨김그룹</span>
      <span
        class="invited-group-status"
        *ngIf="readingGroup.status === 'invited'"
        >※ 초대된 그룹입니다.</span
      >
      <span class="export-group-status" *ngIf="readingGroup.status === 'kicked'"
        >※ 내보내기된 그룹입니다.</span
      >
    </div>
    <div
      class="info-wrapper-inner"
      [ngClass]="{ 'container-query': usesContainerQuery }"
    >
      <div class="info" [ngClass]="{ 'container-query': usesContainerQuery }">
        <h2 [ngClass]="{ 'container-query': usesContainerQuery }">
          {{ readingGroup.name }}
        </h2>
        <p [ngClass]="{ 'container-query': usesContainerQuery }">
          <span [ngClass]="{ 'container-query': usesContainerQuery }">
            <b>그룹장</b> &nbsp; {{ readingGroup.leader_nickname }}
          </span>
          <span>
            <b [ngClass]="{ 'container-query': usesContainerQuery }">정원</b>
            &nbsp;
            <span
              [class.is-warning]="
                readingGroup.member_count === readingGroup.max_member_count
              "
              >{{ readingGroup.member_count }}</span
            >/{{ readingGroup.max_member_count }}명
          </span>
        </p>
      </div>
      <div
        class="sharing"
        [ngClass]="{ 'container-query': usesContainerQuery }"
      >
        <ul class="sharing-list">
          <li [ngClass]="{ 'container-query': usesContainerQuery }">
            <sui-icon
              [name]="'메모'"
              [ngClass]="{ 'container-query': usesContainerQuery }"
            ></sui-icon>
            <span> 공유 메모 ({{ readingGroup.memo_count }}) </span>
          </li>
          <li>
            <sui-icon [name]="'하이라이트'"></sui-icon>
            <span> 공유 하이라이트 ({{ readingGroup.highlight_count }}) </span>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="button-list"
      [ngClass]="{ 'container-query': usesContainerQuery }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</div>
