import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sui-dialog-header',
  template: `<ng-content></ng-content>
    <button
      class="close-button"
      sui-icon-button
      (click)="closeButtonClick.emit()"
    >
      <sui-icon name="엑스"></sui-icon>
    </button>`,
})
export class DialogHeaderComponent {
  @Output() closeButtonClick = new EventEmitter<void>();
}
