<div class="container" #bookshelf>
  <section class="main-container app">
    <div class="empty-container" *ngIf="_bookshelvesList?.length === 0">
      <p>책장이 없습니다.</p>
    </div>
    <div
      class="bookshelf-list"
      *ngFor="let bookshelf of _bookshelvesList; index as i"
      (click)="_onClickBookshelfves(bookshelf.id)"
    >
      <article class="bookshelf-item">
        <div class="header">
          <div class="inner-left">
            <sui-icon
              *ngIf="bookshelf.is_private"
              type="solid"
              name="잠금"
            ></sui-icon>
            <h3>
              <span class="title">
                {{ bookshelf.title }}
              </span>
              <span class="total"> ({{ bookshelf.content_count }})</span>
            </h3>
          </div>
          <sui-icon name="화살표_오른쪽"></sui-icon>
        </div>
        <div class="content" [class.empty]="bookshelf?.books?.length === 0">
          <div
            class="content-inner"
            [ngClass]="{ empty: bookshelf?.books?.length === 0 }"
          >
            <ul *ngIf="bookshelf?.books?.length !== 0">
              <li *ngFor="let book of bookshelf?.books">
                <img src="{{ book.cover }}" alt="책 이미지" />
              </li>
            </ul>
            <p *ngIf="bookshelf?.books?.length === 0" class="empty-bookshelf">
              책장에 담긴 책이 없습니다.
            </p>
          </div>
        </div>
      </article>
    </div>
  </section>
  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</div>
