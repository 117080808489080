<div class="context-menu">
  <!-- 상위 메뉴: 텍스트 선택 - 코멘터리 뷰어 -->
  <div
    class="buttons-container"
    [class.is-hidden]="_type !== _MenuType.Commentator"
  >
    <div
      class="text"
      [class.is-hidden]="!_context?.annotation"
      (click)="_onRemoveClick()"
    >
      삭제
    </div>
    <div
      class="icon"
      [class.is-hidden]="_context?.annotation"
      (click)="_onCommentaryHighlightClick()"
    >
      <sui-icon style="color: #b1dff9" name="하이라이트"></sui-icon>
    </div>
    <div class="divider"></div>
    <ng-container *ngTemplateOutlet="memoTpl"></ng-container>
    <ng-container *ngTemplateOutlet="selectContinuouslyArrowTpl"></ng-container>
  </div>

  <!-- 상위 메뉴: 텍스트 선택 - 일반 뷰어 -->
  <div
    class="buttons-container"
    [class.is-hidden]="_type !== _MenuType.TextSelection"
  >
    <ng-container *ngTemplateOutlet="colorsTpl"></ng-container>
    <ng-container *ngTemplateOutlet="additionalColorsTpl"></ng-container>
    <div class="divider"></div>
    <ng-container *ngTemplateOutlet="memoTpl"></ng-container>
    <ng-container *ngTemplateOutlet="copyTpl"></ng-container>
    <ng-container *ngTemplateOutlet="shareRootTpl"></ng-container>
    <ng-container *ngTemplateOutlet="selectContinuouslyArrowTpl"></ng-container>
  </div>

  <!-- 상위 메뉴: 하이라이트 선택 -->
  <div
    class="buttons-container"
    [class.is-hidden]="_type !== _MenuType.Highlight"
  >
    <div class="text" (click)="_onRemoveClick()">삭제</div>
    <div class="divider"></div>
    <ng-container *ngTemplateOutlet="colorsTpl"></ng-container>
    <ng-container *ngTemplateOutlet="additionalColorsTpl"></ng-container>
    <div class="divider"></div>
    <ng-container *ngTemplateOutlet="memoTpl"></ng-container>
    <ng-container *ngTemplateOutlet="copyTpl"></ng-container>
    <ng-container *ngTemplateOutlet="shareRootTpl"></ng-container>
  </div>
  <!-- 하위 메뉴: 공유 -->
  <!-- <div class="buttons-container" [class.is-hidden]="_type !== _MenuType.Share">
    <div class="icon" (click)="_onMenuClick(_MenuItem.CopyURL)">
      <sui-icon name="Link"></sui-icon>
    </div>
    <div class="icon" (click)="_onMenuClick(_MenuItem.ShareFacebook)">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8571 11C18.8571 6.66059 15.3394 3.14282 11 3.14282C6.66062 3.14282 3.14285 6.66059 3.14285 11C3.14285 14.6848 5.67961 17.7767 9.1019 18.6257V13.401H7.48169V11H9.1019V9.96537C9.1019 7.29105 10.3121 6.05151 12.9377 6.05151C13.4355 6.05151 14.2944 6.14909 14.6457 6.24671V8.42326C14.4603 8.40375 14.1382 8.39397 13.738 8.39397C12.4497 8.39397 11.9519 8.88199 11.9519 10.1508V11H14.5183L14.0774 13.401H11.9519V18.7996C15.8423 18.3297 18.8571 15.0171 18.8571 11Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="icon" (click)="_onMenuClick(_MenuItem.ShareTwitter)">
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_20_2569)">
          <path
            d="M7.73677 5.50179L12.5763 0H11.4295L7.22732 4.77712L3.87105 0H0L5.07533 7.22386L0 12.9933H1.14688L5.58449 7.94852L9.12895 12.9933H13L7.73648 5.50179H7.73677ZM6.16595 7.2875L5.65172 6.56817L1.56012 0.844358H3.32166L6.62363 5.46365L7.13787 6.18299L11.43 12.1874H9.66849L6.16595 7.28778V7.2875Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_20_2569">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div> -->

  <!-- 하위 메뉴: 추가 색상 선택 -->
  <div class="buttons-container" [class.is-hidden]="_type !== _MenuType.Colors">
    <div
      class="icon arrow"
      (click)="_type = _prevType!"
      (touchstart)="$event.preventDefault(); _type = _prevType!"
    >
      <sui-icon name="화살표_왼쪽"></sui-icon>
    </div>
    <div class="divider"></div>
    <div
      *ngFor="let highlightStyle of _additionalHighlightStyles"
      class="icon"
      [class.is-hidden]="
        _context?.annotation?.styleClass === highlightStyle.styleClass
      "
      (click)="_onHighlightClick(highlightStyle.styleClass)"
    >
      <sui-icon
        name="하이라이트"
        [attr.style]="highlightStyle.style"
      ></sui-icon>
    </div>
  </div>

  <!-- 하위 메뉴: 이어서 선택하기 -->
  <div
    class="buttons-container"
    [class.is-hidden]="_type !== _MenuType.ContinueSelecting"
  >
    <div
      class="icon arrow"
      (click)="_type = _prevType!"
      (touchstart)="$event.preventDefault(); _type = _prevType!"
    >
      <sui-icon name="화살표_왼쪽"></sui-icon>
    </div>
    <div class="divider"></div>
    <div
      class="text"
      (click)="_onMenuClick(_MenuItem.SelectContinuously)"
      (touchstart)="
        $event.preventDefault(); _onMenuClick(_MenuItem.SelectContinuously)
      "
    >
      이어서 선택하기
    </div>
  </div>
</div>

<!-- 반복 사용되는 메뉴 템플릿 -->
<ng-template #colorsTpl>
  <div
    *ngFor="let highlightStyle of _defaultHighlightStyles"
    class="icon"
    [class.is-hidden]="
      _context?.annotation?.styleClass === highlightStyle.styleClass
    "
    (click)="_onHighlightClick(highlightStyle.styleClass)"
  >
    <sui-icon name="하이라이트" [attr.style]="highlightStyle.style"></sui-icon>
  </div>
</ng-template>

<ng-template #memoTpl>
  <div class="text" (click)="_onMenuClick(_MenuItem.Memo)">메모</div>
</ng-template>

<ng-template #copyTpl>
  <div class="text" (click)="_onMenuClick(_MenuItem.CopyText)">복사</div>
</ng-template>

<ng-template #shareRootTpl>
  <div
    class="text"
    *ngIf="_canShareURL"
    (click)="_onMenuClick(_MenuItem.CopyURL)"
  >
    공유
  </div>
</ng-template>

<ng-template #additionalColorsTpl>
  <div
    class="icon"
    (click)="_prevType = _type; _type = _MenuType.Colors"
    (touchstart)="
      $event.preventDefault(); _prevType = _type; _type = _MenuType.Colors
    "
  >
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.94422 6.225C6.25922 5.655 6.68672 5.205 7.23422 4.8825C7.77422 4.56 8.39672 4.395 9.10172 4.395C9.80672 4.395 10.3917 4.5525 10.9092 4.8675C11.4192 5.1825 11.8242 5.625 12.1092 6.195C12.3942 6.765 12.5367 7.4175 12.5442 8.16H13.9392V3.21H12.4617V4.8825C12.4317 4.83 12.4017 4.7775 12.3642 4.725C11.9892 4.185 11.5017 3.7575 10.9092 3.4575C10.3092 3.1575 9.63422 3 8.86922 3C7.93922 3 7.09922 3.2325 6.35672 3.69C5.60672 4.1475 5.02172 4.77 4.59422 5.55C4.16672 6.3225 3.95672 7.2 3.94922 8.1675H5.47172C5.47172 7.44 5.62922 6.795 5.94422 6.225Z"
        fill="white"
        fill-opacity="0.3"
      />
      <path
        d="M13.9388 8.16797V13.1405H12.4613V11.4605C12.4313 11.513 12.4013 11.573 12.3638 11.618C11.9888 12.1655 11.5013 12.593 10.9088 12.893C10.3088 13.193 9.64125 13.3505 8.89125 13.3505C7.94625 13.3505 7.10625 13.1255 6.35625 12.6605C5.60625 12.203 5.02125 11.588 4.59375 10.8155C4.16625 10.043 3.94875 9.16547 3.94875 8.19797C3.94875 8.19047 3.94875 8.17547 3.94875 8.16797H1.96875V15.0005H16.0163V8.16797H13.9313H13.9388Z"
        fill="white"
        fill-opacity="0.3"
      />
      <path
        d="M5.47266 8.17547C5.47266 8.91047 5.63016 9.55547 5.94516 10.118C6.26016 10.6805 6.69516 11.123 7.24266 11.4455C7.79016 11.768 8.42016 11.933 9.12516 11.933C10.1527 11.933 10.9777 11.588 11.6077 10.898C12.2377 10.208 12.5452 9.30047 12.5452 8.17547C12.5452 8.17547 12.5452 8.17547 12.5452 8.16797H5.47266C5.47266 8.16797 5.47266 8.16797 5.47266 8.17547Z"
        fill="white"
        fill-opacity="0.3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.41666 9.58325L8.41666 13.6666H9.58333V9.58325H13.6667V8.41658H9.58333V4.33325H8.41666L8.41666 8.41658H4.33333V9.58325H8.41666Z"
        fill="white"
      />
    </svg>
  </div>
</ng-template>

<ng-template #selectContinuouslyArrowTpl>
  <ng-container *ngIf="_context?.isAtPageBoundary">
    <div class="divider"></div>
    <div
      class="icon arrow"
      (click)="_prevType = _type; _type = _MenuType.ContinueSelecting"
      (touchstart)="
        $event.preventDefault();
        _prevType = _type;
        _type = _MenuType.ContinueSelecting
      "
    >
      <sui-icon name="화살표_오른쪽"></sui-icon>
    </div>
  </ng-container>
</ng-template>
