import { Component, OnInit } from '@angular/core';
import { TypedDialog } from 'shared/ui';

import { Book, BookMeta } from '../../models/book.model';
import { Router } from '@angular/router';
import { hasBookInfoPage } from '../../utils/book';

@Component({
  selector: 'viewer-preview-end-dialog',
  templateUrl: './preview-end-dialog.component.html',
  styleUrl: './preview-end-dialog.component.scss',
})
export class PreviewEndDialogComponent
  extends TypedDialog<{ book: Book }, void>
  implements OnInit
{
  public _coverImageURL!: string;
  public _title!: string;
  public _author?: string;
  public _publisher?: string;
  public _pricing!: BookMeta['pricing'];
  public _currency?: string;
  public _hasBookInfoPage!: boolean;
  public _isOnSale!: boolean;

  public readonly _PRICING_TYPES: [keyof BookMeta['pricing'], string][] = [
    ['buy', '소장'],
    ['rent', '대여'],
    ['rent2', '대여'],
  ];

  constructor(private _router: Router) {
    super();
  }

  ngOnInit(): void {
    this._hasBookInfoPage = hasBookInfoPage(this._data.book.meta.bid);

    this._coverImageURL = this._data.book.meta.cover;
    this._title = this._data.book.meta.title;
    this._author = this._data.book.meta.author;
    this._publisher = this._data.book.meta.publisher?.name;
    this._pricing = this._data.book.meta.pricing;
    this._currency = this._data.book.meta.publisher?.currency;

    this._isOnSale = this._PRICING_TYPES.some(
      (type) => this._data.book.meta.pricing[type[0]].published
    );
  }

  _onBookDetailsButtonClick(): void {
    window.open('/@' + this._data.book.meta.bid, '_blank');
  }
}
