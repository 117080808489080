import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-reading-group-member-avatar',
  templateUrl: './reading-group-member-avatar.component.html',
  styleUrl: './reading-group-member-avatar.component.scss',
})
export class ReadingGroupMemberAvatarComponent {
  @Input() avatarImageURL?: string;
  @Input() isBlocked?: number | boolean;
  @Input() isOnline?: number | boolean;
}
