import { Injectable } from '@angular/core';
import { ContentsSearchResult } from '@bukio/viewer';
import { Observable, Subject, Subscription } from 'rxjs';
import { pairwise, startWith, filter } from 'rxjs/operators';

import { EventBusService } from './event-bus.service';

interface Search {
  result$: Subject<ContentsSearchResult[]>;
  cancelFn?: () => void;
}

class SearchStore {
  private _store: {
    [searchId: string]: Search;
  } = {};

  get(searchId: string): Search {
    return this._store[searchId];
  }

  set(searchId: string, search: Search): void {
    this._store[searchId] = search;
  }

  delete(searchId: string): void {
    this.clear(searchId);
    delete this._store[searchId];
  }

  setCancalFn(searchId: string, fn: Search['cancelFn']): void {
    const search = this.get(searchId);

    if (search) {
      search.cancelFn = fn;
    }
  }

  has(searchId: string): boolean {
    return !!this._store[searchId];
  }

  clear(searchId: string): void {
    const search = this.get(searchId);

    if (search) {
      search.cancelFn?.();
      search.result$.complete();
    }
  }

  destroy(): void {
    Object.keys(this._store).forEach((searchId) => this.clear(searchId));
  }
}

@Injectable({
  providedIn: 'root',
})
export class BukViewerSearchService {
  private _searchStore!: SearchStore;

  private _search$ = new Subject<{ searchId: string; keyword: string }>();
  search$ = this._search$.asObservable();

  constructor(_eventBusService: EventBusService) {
    _eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(
        startWith(undefined),
        pairwise(),
        filter(([e1, e2]) => {
          return e1?.address.bid !== e2?.address.bid;
        })
      )
      .subscribe(() => {
        if (this._searchStore) {
          this._searchStore.destroy();
        }

        this._searchStore = new SearchStore();
      });

    _eventBusService.on('ContentsComponent:searchStart').subscribe((event) => {
      this._searchStore.setCancalFn(event.searchId, event.cancelFn);
    });

    _eventBusService.on('ContentsComponent:searchDone').subscribe((event) => {
      const result = this._searchStore.get(event.searchId);

      if (result) {
        result.result$.next(event.results);
      }
    });
  }

  search(keyword: string): Observable<ContentsSearchResult[]> {
    return new Observable((observer) => {
      const searchId = `${keyword}\n${new Date().getTime()}`;

      const result$ = new Subject<ContentsSearchResult[]>();

      this._searchStore.set(searchId, { result$ });

      const sub: Subscription = result$.subscribe({
        next: (results) => {
          observer.next(results);
          observer.complete();
        },
        complete: () => {
          observer.complete();
        },
      });

      this._search$.next({ searchId, keyword });

      return (): void => {
        sub.unsubscribe();
        this._searchStore.delete(searchId);
      };
    });
  }
}
