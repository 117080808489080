<div class="buttons">
  <ng-container *ngFor="let language of languages; index as i">
    <div
      class="button"
      (click)="toggleLanguage(language[0])"
      [class.active]="isSelectedButton(language[0])"
    >
      {{ language[1] }}
    </div>
    <div class="divider" *ngIf="i !== languages.length - 1"></div>
  </ng-container>
</div>
