<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  <ng-container *ngIf="_currentPage === _Page.Main">도움말</ng-container>
  <div class="dialog-header" *ngIf="_currentPage !== _Page.Main">
    <button sui-icon-button (click)="_onBackButtonClick()">
      <sui-icon name="원형 이동_왼쪽"></sui-icon>
    </button>
    <div class="title">도움말 상세보기</div>
  </div>
</sui-dialog-header>
<div class="body" [class.is-hidden]="_currentPage !== _Page.Main">
  <div class="page main">
    <div class="title">
      <div class="highlight"></div>
      <span class="text"
        >북이오 뷰어는 <br class="mobile" /><b>이렇게 사용해요 !</b></span
      >
    </div>
    <div class="lists">
      <div class="list">
        <div class="list-title">기본 기능</div>
        <div class="list-items">
          <ng-container *ngFor="let page of _pageListBasic">
            <ng-container
              *ngTemplateOutlet="listItem; context: { item: page }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
      <div class="list">
        <div class="list-title">읽기 모드</div>
        <div class="list-items">
          <ng-container *ngFor="let page of _pageListReadingMode">
            <ng-container
              *ngTemplateOutlet="listItem; context: { item: page }"
            ></ng-container>
          </ng-container>
        </div>

        <div class="list-title">독서 기록</div>
        <div class="list-items">
          <ng-container *ngFor="let page of _pageListNotes">
            <ng-container
              *ngTemplateOutlet="listItem; context: { item: page }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
      <div class="list">
        <div class="list-title">독서 기록 보기</div>
        <div class="list-items">
          <ng-container *ngFor="let page of _pageListNoteList">
            <ng-container
              *ngTemplateOutlet="listItem; context: { item: page }"
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="body"
  [class.is-hidden]="_currentPage === _Page.Main"
  [ngSwitch]="_currentPage"
>
  <!-- 책 정보 보기 -->
  <div class="page" *ngSwitchCase="_Page.BookInfo">
    <div class="page-header">
      <sui-icon
        style="font-size: 30px"
        name="앱_도서 리스트"
        type="solid"
      ></sui-icon>
      <div class="title"><span>목차 보기</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">책 제목과 저자를 확인할 수 있습니다.</div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          '책 상세 보기' 버튼을 눌러 책 상세 페이지로 이동할
          <br class="desktop" />수 있습니다.
        </div>
      </div>
      <div class="li">
        <div class="number">3.</div>
        <div class="text">
          목차를 확인하고 해당 페이지로 이동할 수 있습니다.
        </div>
      </div>
      <div class="li" *ngIf="!_isApp">
        <div class="number">4.</div>
        <div class="text">
          부록이 있는 책이라면 부록 목록도 표시됩니다.
          <br class="desktop" />원하는 부록을 선택하여 바로 열람할 수 있습니다.
        </div>
      </div>
    </div>
  </div>
  <!-- 페이지 모아보기 -->
  <div class="page" *ngSwitchCase="_Page.Thumbnail">
    <div class="page-subheader">
      EPUB 도서는 페이지 모아 보기를 지원하지 않습니다.<br class="desktop" />
      전자책 파일 형식은 책 상세 페이지에서 확인하실 수 있습니다.
    </div>
    <div class="page-header">
      <sui-icon
        style="font-size: 30px"
        name="페이지 모아보기"
        type="solid"
      ></sui-icon>
      <div class="title"><span>페이지 모아보기</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          PDF 도서의 전체 페이지를 한눈에 모아 볼 수 있습니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          원하는 페이지를 선택하면 해당 페이지로 이동합니다.
        </div>
      </div>
    </div>
  </div>
  <!-- 설정 -->
  <div class="page settings" *ngSwitchCase="_Page.Settings">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="환경설정" type="solid"></sui-icon>
      <div class="title"><span>뷰어 설정</span></div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="column-item">
          <div class="icon">
            <sui-icon name="테마" style="font-size: 30px"></sui-icon>
          </div>
          <div class="text">테마(다크/세피아/라이트) 설정</div>
        </div>
        <div class="column-item" *ngIf="!_data.isPDFBook">
          <div class="icon">
            <sui-icon name="폰트" style="font-size: 30px"></sui-icon>
          </div>
          <div>폰트 설정</div>
        </div>
        <div class="column-item" *ngIf="!_data.isPDFBook">
          <div class="icon">
            <sui-icon name="글자 사이즈" style="font-size: 30px"></sui-icon>
          </div>
          <div>글자 크기 설정</div>
        </div>
        <div class="column-item">
          <div class="icon">
            <sui-icon name="하이라이트" style="font-size: 24px"></sui-icon>
          </div>
          <div>
            하이라이트 컬러 선택<br /><span class="description"
              >최대 5개의 컬러를 선택할 수 있으며, 여기서 선택한 컬러들이 본문
              드래그 시 하이라이트 컬러 기본 옵션으로 제공됩니다.</span
            >
          </div>
        </div>
      </div>
      <div class="column">
        <div class="column-item" *ngIf="!_data.isPDFBook">
          <div class="icon">
            <sui-icon
              name="스크롤"
              style="font-size: 24px"
              type="solid"
            ></sui-icon>
          </div>
          <div>
            좌우로 책장을 넘기는 방식이 아닌 스크롤 방식으로 책을 열람합니다.
          </div>
        </div>
        <div class="column-item">
          <div class="icon">
            <sui-icon
              name="두페이지 보기"
              style="font-size: 24px"
              type="solid"
            ></sui-icon>
          </div>
          <div>한 화면에 두 페이지를 띄워 열람합니다.</div>
        </div>
        <div class="column-item">
          <div class="icon">
            <sui-icon
              name="페이지 넘김 효과"
              style="font-size: 24px"
              type="solid"
            ></sui-icon>
          </div>
          <div>책장을 넘기듯 페이지를 부드럽게 전환합니다.</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 필기 -->
  <div class="page drawing" *ngSwitchCase="_Page.Drawing">
    <div class="page-subheader mobile">
      필기 기능은 펜슬 사용이 가능한 모바일 기기에서 북이오 앱으로 PDF 도서를
      열람할 때 사용하실 수 있습니다.
    </div>
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="펜" type="solid"></sui-icon>
      <div class="title"><span>필기</span></div>
      <div class="subtitle desktop">
        필기 기능은 펜슬 사용이 가능한 모바일 기기에서<br />북이오 앱으로 PDF
        도서를 열람할 때 사용하실 수 있습니다.
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="tag">기능 안내</div>
        <div class="ol">
          <div class="li">
            <div class="number">1.</div>
            <div class="text">
              필기 기능은 '한 페이지 보기' 상태에서만 사용하실 수 있습니다. '두
              페이지 보기' 상태에서 필기 아이콘을 선택하면 '한 페이지 보기'로
              자동 전환됩니다.
            </div>
          </div>
          <div class="li">
            <div class="number">2.</div>
            <div class="text">
              필기는 작성자 본인만 볼 수 있으며, 함께읽기 모드에서도 다른
              그룹원에게 공유되지 않습니다.
            </div>
          </div>
          <div class="li">
            <div class="number">3.</div>
            <div class="text">
              필기는 기기 단위로 저장됩니다. 동일한 기기라면 읽기모드와 그룹에
              관계 없이 내 필기를 확인할 수 있습니다.
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="tag">툴바 안내</div>
        <div class="ol">
          <div class="li">
            <div class="number">1.</div>
            <div class="text">
              두 종류의 펜<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#282828" />
                <path
                  d="M13.6378 5.45313L4.54688 5.45312L4.54687 14.544L13.6378 14.544L13.6378 5.45313Z"
                  fill="#282828"
                />
                <path
                  d="M5.51953 14.4953L10.0877 14.4953C10.6938 14.4953 11.2582 14.196 11.5953 13.6922C11.9324 13.1922 12.0006 12.5559 11.7733 11.9953L10.5498 8.96875L7.75059 8.96875L5.51953 14.4953Z"
                  fill="white"
                />
                <path
                  d="M9.15341 5.50391L7.9375 8.51527L10.3693 8.51527L9.15341 5.50391Z"
                  fill="white"
                />
                <path
                  d="M14.166 9.54474L14.166 5.72656L14.7823 5.72656L14.7823 9.54474L14.166 9.54474ZM13.4023 6.28838L13.4023 5.72656L14.7278 5.72656L14.7278 6.28838L13.4023 6.28838Z"
                  fill="white"
                />
              </svg>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#282828" />
                <path
                  d="M13.6339 5.45313L4.54297 5.45312L4.54297 14.544L13.6339 14.544L13.6339 5.45313Z"
                  fill="#282828"
                />
                <path
                  d="M5.51562 14.4953L10.0838 14.4953C10.6899 14.4953 11.2543 14.196 11.5914 13.6922C11.9285 13.1922 11.9967 12.5559 11.7694 11.9953L10.5459 8.96875L7.74669 8.96875L5.51562 14.4953Z"
                  fill="white"
                />
                <path
                  d="M9.1495 5.50391L7.93359 8.51527L10.3654 8.51527L9.1495 5.50391Z"
                  fill="white"
                />
                <path
                  d="M13.4102 9.17369L14.8174 7.67915C14.9338 7.55551 15.0283 7.4446 15.1011 7.34642C15.1738 7.24824 15.2265 7.15551 15.2592 7.06824C15.292 6.98097 15.3083 6.89188 15.3083 6.80097C15.3083 6.63006 15.252 6.49733 15.1392 6.40278C15.0302 6.3046 14.8902 6.25551 14.7192 6.25551C14.5374 6.25551 14.3774 6.30097 14.2392 6.39188C14.1011 6.48278 13.9774 6.6246 13.8683 6.81733L13.432 6.45733C13.5811 6.19915 13.7647 6.0046 13.9829 5.87369C14.2011 5.73915 14.4538 5.67188 14.7411 5.67188C14.9774 5.67188 15.1847 5.71915 15.3629 5.81369C15.5411 5.90824 15.6792 6.04097 15.7774 6.21188C15.8756 6.37915 15.9247 6.57369 15.9247 6.79551C15.9247 6.95551 15.9047 7.09915 15.8647 7.22642C15.8283 7.35006 15.7647 7.47551 15.6738 7.60278C15.5829 7.72642 15.4574 7.87188 15.2974 8.03915L14.2174 9.15733L13.4102 9.17369ZM13.4102 9.5446V9.17369L13.9502 8.98278L16.0011 8.98278V9.5446L13.4102 9.5446Z"
                  fill="white"
                /></svg
              >, 지우개
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#282828" />
                <path
                  d="M11.1657 13.3756L13.0331 11.5082C13.6127 10.9286 13.9044 10.3226 13.9044 9.69756C13.9044 9.07256 13.6127 8.4665 13.0331 7.88695L11.1657 6.01953L6.09375 11.0915L8.37784 13.3756L6.22254 13.3756L6.22254 13.9816L13.9044 13.9816V13.3756L11.1657 13.3756ZM9.2339 13.3756L6.94981 11.0915L8.05208 9.98923L10.8741 12.8112L10.3097 13.3756H9.2339Z"
                  fill="white"
                /></svg
              >, 자(직선 긋기)<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#282828" />
                <path
                  d="M14.544 5.45313L5.45312 5.45312L5.45312 14.544L14.544 14.544L14.544 5.45313Z"
                  fill="#282828"
                />
                <path
                  d="M11.4622 8.22916L13.2388 10.0057C14.1441 8.96023 14.0797 7.82765 13.0456 6.79735L11.9357 5.6875L6.12891 11.4943L8.94708 14.3125L9.93572 13.3239L8.15163 11.5398L8.4736 11.2178L10.2577 13.0019L11.4281 11.8314L10.2425 10.6458L10.5645 10.3239L11.7501 11.5095L12.9206 10.339L11.1365 8.55492L11.4584 8.23296L11.4622 8.22916Z"
                  fill="white"
                />
              </svg>
              등의 툴을 선택하여 사용할 수 있습니다. 펜 툴을 두 번 탭하면 컬러
              선택 및 두께 · 투명도 조정이 가능합니다.
            </div>
          </div>
          <div class="li">
            <div class="number">2.</div>
            <div class="text">
              이전
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#F5F5F7" />
                <path
                  d="M14.544 5.45313L5.45312 5.45312L5.45312 14.544L14.544 14.544L14.544 5.45313Z"
                  fill="#F5F5F7"
                />
                <path
                  d="M11.4348 8.58629L8.07493 8.58629L9.32872 7.51054L8.76054 6.84766L6.22266 9.0219L8.76054 11.1961L9.32872 10.5333L8.07493 9.4575L11.4461 9.4575C12.234 9.4575 12.9121 10.0825 12.9083 10.8704C12.9083 11.234 12.7681 11.5749 12.5181 11.8363C12.2416 12.1242 11.8514 12.2719 11.4499 12.2719L8.54463 12.2719C8.14311 12.2719 7.81736 12.5977 7.81736 12.9992V13.1431L11.503 13.1431C11.8855 13.1431 12.2681 13.056 12.6015 12.8666C13.3704 12.4272 13.8022 11.6507 13.7833 10.8098C13.7568 9.58629 12.6658 8.58629 11.4386 8.58629H11.4348Z"
                  fill="#282828"
                /></svg
              >혹은 다음<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#F5F5F7" />
                <path
                  d="M5.45597 5.45312L14.5469 5.45312L14.5469 14.544L5.45597 14.544L5.45597 5.45312Z"
                  fill="#F5F5F7"
                />
                <path
                  d="M8.56522 8.58629L11.9251 8.58629L10.6713 7.51054L11.2395 6.84766L13.7773 9.0219L11.2395 11.1961L10.6713 10.5333L11.9251 9.4575L8.55387 9.4575C7.76599 9.4575 7.08794 10.0825 7.09173 10.8704C7.09173 11.234 7.23189 11.5749 7.48189 11.8363C7.75841 12.1242 8.14856 12.2719 8.55008 12.2719L11.4554 12.2719C11.8569 12.2719 12.1826 12.5977 12.1826 12.9992L12.1826 13.1431L8.49704 13.1431C8.11446 13.1431 7.73188 13.056 7.39855 12.8666C6.62961 12.4272 6.19779 11.6507 6.21673 10.8098C6.24324 9.58629 7.33415 8.58629 8.56143 8.58629H8.56522Z"
                  fill="#282828"
                /></svg
              >을 탭해 필기 상태를 한 단계 이전으로 되돌리거나 한 단계 이후로
              복구할 수 있습니다.
            </div>
          </div>
          <div class="li">
            <div class="number">3.</div>
            <div class="text">
              필기 기능 툴바에서 전체 삭제<svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#282828" />
                <path
                  d="M14.544 5.45313L5.45312 5.45312L5.45312 14.544L14.544 14.544L14.544 5.45313Z"
                  fill="#282828"
                />
                <path
                  d="M11.6005 7.41927L11.6005 6.96851C11.6005 6.55185 11.2596 6.21094 10.8429 6.21094L8.51337 6.21094L8.51337 7.41927L6.81641 7.41927L6.81641 13.7867L13.18 13.7867L13.18 7.41927L11.6005 7.41927ZM8.76336 12.4041H8.30883L8.30883 8.79806H8.76336L8.76336 12.4041ZM10.2066 12.4041L9.75201 12.4041L9.75201 8.79806L10.2066 8.79806L10.2066 12.4041ZM11.6497 12.4041L11.1952 12.4041L11.1952 8.79806L11.6497 8.79806L11.6497 12.4041Z"
                  fill="white"
                /></svg
              >를 선택하면 해당 페이지의 필기 전체가 삭제됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 북마크 -->
  <div class="page bookmark" *ngSwitchCase="_Page.Bookmark">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="북마크" type="solid"></sui-icon>
      <div class="title"><span>북마크</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          화면 우측 상단의 북마크 버튼을 누르면 지금 보고 있는 페이지가<br
            class="desktop"
          />북마크 목록에 저장됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          저장한 페이지는 화면 하단의 플로팅 버튼
          <div class="floating-button"><sui-icon name="플러스"></sui-icon></div>
          → 내 기록 전체 보기 <br class="desktop" />→ 북마크 탭에서 모아볼 수
          있습니다.
        </div>
      </div>
    </div>
  </div>
  <!-- 본문 검색 -->
  <div class="page" *ngSwitchCase="_Page.Search">
    <!-- <div class="page-subheader">
      검색 결과가 나오지 않는다면 구두점을 포함한<br class="desktop" />
      모든 문자가 정확하게 입력되었는지 확인해 주세요.
    </div> -->
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="검색"></sui-icon>
      <div class="title"><span>본문 검색</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">원하는 키워드로 본문을 검색합니다.</div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">검색어가 포함된 페이지로 이동할 수 있습니다.</div>
      </div>
    </div>
  </div>
  <!-- 읽기 모드 변경 -->
  <div class="page reading-mode" *ngSwitchCase="_Page.ReadingMode">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="뷰어 그룹 변경"></sui-icon>
      <div class="title"><span>읽기 모드 변경</span></div>
    </div>
    <div class="text">
      열람 중 읽기모드를 바꾸고 싶다면 화면 우측 상단(PC)/좌측 하단(모바일)의
      <br class="desktop" />
      <svg
        width="65"
        height="20"
        viewBox="0 0 65 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="65" height="20" rx="10" fill="#282828" />
        <path
          d="M9.40937 11.4922L5.73437 7.20469L6.66187 6.40552L8.79687 8.89635L8.79687 4.81885C8.79687 4.20052 9.29854 3.68719 9.91687 3.68135L10.0219 3.68135L10.0219 8.89635L12.1569 6.40552L13.0844 7.20469L9.40937 11.4922Z"
          fill="white"
        />
        <path
          d="M17.1256 13.5922L14.9906 11.1014L14.9906 15.1789C14.9906 15.7972 14.489 16.3106 13.8706 16.3164L13.7656 16.3164L13.7656 11.1014L11.6306 13.5922L10.7031 12.7931L14.3781 8.50557L18.0531 12.7931L17.1256 13.5922Z"
          fill="white"
        />
        <path
          d="M30.5449 12.9648V13.7363H22.5371V12.9648H26.0625V10.9434H23.4551V6.7832H29.5879V10.9434H26.9805V12.9648H30.5449ZM24.3535 10.2109H28.6797V7.52539H24.3535V10.2109ZM38.2695 10.2305V10.9922H32.1758V6.86133H38.1914V7.62305H33.0938V10.2305H38.2695ZM31.1797 13.6777V12.9062H39.1875V13.6777H31.1797ZM47.1172 6.08984V12.5547H46.1895V10.2793H44.2754V11.0996H40.2422V6.66602H41.1699V8.15039H43.3574V6.66602H44.2754V7.64258H46.1895V6.08984H47.1172ZM41.1699 10.3477H43.3574V8.86328H41.1699V10.3477ZM41.4043 14.6445V11.9199H42.3418V13.8926H47.3125V14.6445H41.4043ZM44.2754 9.52734H46.1895V8.4043H44.2754V9.52734ZM55.7598 6.08984V11.2656H54.832V10.2012H52.625V9.43945H54.832V8.375H52.7178C52.1514 9.78125 50.8379 10.7725 48.9434 11.3145L48.5723 10.5918C50.6426 10.001 51.8535 8.8877 52.0195 7.47656H49.0215V6.71484H53.0254C53.0254 7.03711 52.9912 7.34961 52.9326 7.64258H54.832V6.08984H55.7598ZM49.9297 13.0723C49.9297 12.0127 51.082 11.3682 52.8789 11.3633C54.6514 11.3682 55.8135 12.0127 55.8184 13.0723C55.8135 14.1367 54.6514 14.791 52.8789 14.791C51.082 14.791 49.9297 14.1367 49.9297 13.0723ZM50.8477 13.0723C50.8477 13.6875 51.6289 14.0439 52.8789 14.0488C54.1045 14.0439 54.8857 13.6875 54.8906 13.0723C54.8857 12.4473 54.1045 12.0908 52.8789 12.0957C51.6289 12.0908 50.8477 12.4473 50.8477 13.0723Z"
          fill="white"
        />
      </svg>
      버튼을 누르세요. 함께읽기 중이었다면 혼자읽기로,
      <br class="desktop" />혼자읽기 중이었다면 함께읽기로 모드가 전환됩니다.
    </div>
  </div>
  <!-- 하이라이트 -->
  <div class="page highlight" *ngSwitchCase="_Page.Highlight">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="하이라이트"></sui-icon>
      <div class="title"><span>하이라이트</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          본문을 드래그하면 나타나는 툴바에서 하이라이트
          <br class="desktop" />컬러 중 하나를 선택하세요. 드래그한 부분이
          선택한 <br class="desktop" />컬러로 하이라이트됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          미리 등록된 옵션 외의 컬러로 하이라이트하고 싶다면
          <br class="desktop" />
          컬러 더보기 버튼
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="20"
              width="20"
              height="20"
              rx="10"
              transform="rotate(90 20 0)"
              fill="#282828"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.5625 10.4375V13.5H10.4375V10.4375H13.5V9.5625H10.4375L10.4375 6.5H9.5625V9.5625H6.5V10.4375H9.5625Z"
              fill="white"
            />
            <path
              d="M7.96281 8.15C8.17281 7.77 8.45781 7.47 8.82281 7.255C9.18281 7.04 9.59781 6.93 10.0678 6.93C10.5378 6.93 10.9278 7.035 11.2728 7.245C11.6128 7.455 11.8828 7.75 12.0728 8.13C12.2628 8.51 12.3578 8.945 12.3628 9.44H13.2928V6.14H12.3078V7.255C12.2878 7.22 12.2678 7.185 12.2428 7.15C11.9928 6.79 11.6678 6.505 11.2728 6.305C10.8728 6.105 10.4228 6 9.91281 6C9.29281 6 8.73281 6.155 8.23781 6.46C7.73781 6.765 7.34781 7.18 7.06281 7.7C6.77781 8.215 6.63781 8.8 6.63281 9.445H7.64781C7.64781 8.96 7.75281 8.53 7.96281 8.15Z"
              fill="white"
              fill-opacity="0.3"
            />
            <path
              d="M13.2925 9.44531V12.7603H12.3075V11.6403C12.2875 11.6753 12.2675 11.7153 12.2425 11.7453C11.9925 12.1103 11.6675 12.3953 11.2725 12.5953C10.8725 12.7953 10.4275 12.9003 9.9275 12.9003C9.2975 12.9003 8.7375 12.7503 8.2375 12.4403C7.7375 12.1353 7.3475 11.7253 7.0625 11.2103C6.7775 10.6953 6.6325 10.1103 6.6325 9.46531C6.6325 9.46031 6.6325 9.45031 6.6325 9.44531H5.3125V14.0003H14.6775V9.44531H13.2875H13.2925Z"
              fill="white"
              fill-opacity="0.3"
            />
            <path
              d="M7.64844 9.45031C7.64844 9.94031 7.75344 10.3703 7.96344 10.7453C8.17344 11.1203 8.46344 11.4153 8.82844 11.6303C9.19344 11.8453 9.61344 11.9553 10.0834 11.9553C10.7684 11.9553 11.3184 11.7253 11.7384 11.2653C12.1584 10.8053 12.3634 10.2003 12.3634 9.45031C12.3634 9.45031 12.3634 9.45031 12.3634 9.44531H7.64844C7.64844 9.44531 7.64844 9.44531 7.64844 9.45031Z"
              fill="white"
              fill-opacity="0.3"
            />
          </svg>
          을 누르고 원하는 컬러를 <br class="desktop" />선택하세요.
        </div>
      </div>
    </div>
  </div>
  <!-- 메모 -->
  <div class="page" *ngSwitchCase="_Page.Memo">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="메모" type="solid"></sui-icon>
      <div class="title"><span>메모</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          본문을 드래그하면 나타나는 툴바에서 메모 버튼<sui-icon
            class="context-menu-icon"
            name="메모"
            type="solid"
          ></sui-icon
          >을 누릅니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          '메모 작성' 팝업에 원하는 내용을 입력하고 저장하세요.
        </div>
      </div>
    </div>
  </div>
  <!-- 복사 -->
  <div class="page" *ngSwitchCase="_Page.Copy">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="복사" type="solid"></sui-icon>
      <div class="title"><span>복사</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          본문을 드래그하면 나타나는 툴바에서 복사 버튼<sui-icon
            class="context-menu-icon"
            name="복사"
            type="solid"
          ></sui-icon
          >을 <br class="desktop" />누르면 드래그한 부분이 출처(저자, 책 제목)와
          함께 <br class="desktop" />복사됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">본문은 최대 500자까지만 복사할 수 있습니다.</div>
      </div>
    </div>
  </div>
  <!-- 본문 공유 -->
  <div class="page" *ngSwitchCase="_Page.Share">
    <div class="page-subheader mobile">
      2자부터 500자까지, 본문의 특정 부분에 링크를 생성할 수 있는 기능입니다.
    </div>
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="공유" type="solid"></sui-icon>
      <div class="title"><span>본문 공유</span></div>
      <div class="subtitle desktop">
        2자부터 500자까지, 본문의 특정 부분에<br class="desktop" />링크를 생성할
        수 있는 기능입니다.
      </div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">본문을 드래그합니다.</div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          툴바에서 본문 공유 버튼<sui-icon
            name="공유"
            type="solid"
            class="context-menu-icon"
          ></sui-icon
          >을 누르면 드래그한 부분에 대한 링크가 생성됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">3.</div>
        <div class="text">링크를 원하는 곳에 공유합니다.</div>
      </div>
      <div class="li">
        <div class="number">4.</div>
        <div class="text">
          공유받은 사람이 링크를 누르면 이 전자책의 드래그한 부분으로
          <br class="desktop" />
          이동하며, 드래그한 부분이 속한 페이지를 볼 수 있습니다.
        </div>
      </div>
    </div>
  </div>
  <!-- 본문 공유 -->
  <div class="page" *ngSwitchCase="_Page.Annotations">
    <div class="page-header">
      <sui-icon
        style="font-size: 30px"
        name="내 독서 기록"
        type="solid"
      ></sui-icon>
      <div class="title small"><span>내 독서 기록 보기</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          뷰어 최초 진입 시 내 하이라이트와 메모가 표시되도록 설정되어 있습니다.
          <br class="desktop" />
          내 하이라이트와 메모를 뷰어에서 숨기고 싶다면 플로팅 버튼
          <div class="floating-button"><sui-icon name="플러스"></sui-icon></div>
          을 누르고 '내 기록 보기'를 비활성화해 주세요.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          본문에 표시된 메모 아이콘을 누르면 해당 페이지에 작성된 메모의 전체
          목록이 나타납니다.
        </div>
      </div>
      <div class="li">
        <div class="number">3.</div>
        <div class="text">
          하이라이트와 메모뿐 아니라 북마크, 필기를 비롯한 모든 종류의 독서 기록
          전체를 보고 싶다면 플로팅 버튼을 누르고 '내 기록 전체 보기'를 선택해
          주세요. 별도의 레이어에서 모든 기록을 종류별로 모아 볼 수 있습니다.
        </div>
      </div>
      <div class="li">
        <div class="number">4.</div>
        <div class="text">
          각 기록 우측 상단의 더 보기 버튼
          <sui-icon
            name="더보기"
            style="font-size: 24px; vertical-align: middle; margin-top: -3px"
          ></sui-icon
          >을 누르면 수정, 삭제, 복사, 내보내기 등을 할 수 있습니다.
        </div>
      </div>
    </div>
  </div>
  <!-- 코멘터리 보기 -->
  <div class="page" *ngSwitchCase="_Page.Commentary">
    <div class="page-header">
      <sui-icon
        style="font-size: 30px"
        name="코멘터리북(채움)"
        type="solid"
      ></sui-icon>
      <div class="title"><span>코멘터리 보기</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          구매한 코멘터리는 '혼자읽기' 모드 에서만 열람이 가능합니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          코멘터리는 비활성화 상태가 기본 설정입니다.
          <br class="desktop" />플로팅 버튼
          <div class="floating-button"><sui-icon name="플러스"></sui-icon></div>
          을 누르고 '코멘터리 기록 보기'를 <br class="desktop" />활성화하면
          코멘터리가 표시됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">3.</div>
        <div class="text">
          하나의 책에 여러 개의 코멘터리를 구매한 경우에도 한 번에
          <br class="desktop" />코멘터리 하나씩만 볼 수 있습니다. '코멘터리 기록
          보기'를 활성화 <br class="desktop" />한 뒤 어떤 코멘테이터의
          코멘터리를 볼 것인지 선택해 주세요.
        </div>
      </div>
    </div>
  </div>
  <!-- 그룹원의 하이라이트, 메모 보기 -->
  <div class="page group-shared" *ngSwitchCase="_Page.GroupShared">
    <div class="page-header">
      <sui-icon style="font-size: 30px" name="앱_그룹" type="solid"></sui-icon>
      <div class="title desktop">
        <span>그룹원의 하이라이트, 메모 보기</span>
      </div>
      <div class="title small mobile"><span>그룹원의</span></div>
      <div class="title small mobile"><span>하이라이트, 메모 보기</span></div>
    </div>
    <div class="ol">
      <div class="li">
        <div class="number">1.</div>
        <div class="text">
          '함께읽기' 모드에서는 다른 그룹원들의 하이라이트, 메모 기록이 항상
          표시됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">2.</div>
        <div class="text">
          공유 하이라이트와 메모를 비활성화할 수는 없지만, 플로팅 메뉴
          <div class="floating-button"><sui-icon name="플러스"></sui-icon></div>
          에서 공유 하이라이트 <br class="desktop" />표시 기준을 선택할 수
          있습니다. 3명/5명/10명 이상이 하이라이트한 부분만 공유
          <br class="desktop" />하이라이트로 표시되도록 설정하여 많은 사람이
          중요하다고 생각한 부분을 확인해 보세요.
        </div>
      </div>
      <div class="li">
        <div class="number">3.</div>
        <div class="text">
          새로 추가된 공유 하이라이트, 메모는 페이지를 넘길 때 반영됩니다.
        </div>
      </div>
      <div class="li">
        <div class="number">4.</div>
        <div class="text">
          새로운 공유 기록이 추가되면 플로팅 버튼에 블루닷이 생깁니다. 페이지를
          넘기지 않고 <br class="desktop" />새 공유 하이라이트,메모를 보고
          싶다면 플로팅 버튼을 누르고 '공유 메모 동기화'를 실행하세요.
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #listItem let-item="item">
  <div class="list-item" (click)="_onPageListItemClick(item.page)">
    <div class="item-icon">
      <sui-icon
        [name]="item.iconName"
        [style.font-size.px]="item.iconSize"
        [type]="item.iconType"
      ></sui-icon>
    </div>
    <div class="item-title">{{ item.title }}</div>
    <sui-icon class="item-arrow" name="화살표_오른쪽"></sui-icon>
  </div>
</ng-template>
