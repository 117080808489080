export const Config = Object.freeze({
  debug: true,
  defaultIid: 'cover',
  supportedBookstore: /yes24|kyobo|bookpark|aladdin|farm|etc/,
  bookstoreScore: {
    aladdin: 3,
    kyobo: 1,
    bookpark: 2,
    yes24: 4,
    farm: 5,
    etc: 0,
  },
  ui: {
    topSpacing: 24,
    horizontalSpacing: 35,
    topMenuHeightDesktop: 70,
    topMenuHeightMobile: 56,
    bottomMenuHeightDesktop: 70,
    bottomMenuHeightMobile: 56,
    gapBetweenPagesAndMenuMobile: 19,
    gapBetweenPagesAndMenuDesktop: 24,
    gapBetweenPageBoxesMobile: 24,
    gapBetweenPageBoxesDesktop: 30,
  },
  highlightClass: Object.freeze({
    sharedBookmark: 'bukh-shared-bookmark', // 북마크 공유 -> permissin 페이지 찾기에 사용
    sharedHighlight: 'bukh-shared', // 하이라이트 공유 -> permission 페이지 찾기에 사용
    anchorHighlight: 'bukh-anchor', // 공유 주소에 대한 위치 찾기에 사용
    keywordHighlight: 'bukh-search', // 검색 키워드 하이라이팅에 사용
    bookmark: 'bukh-bookmark', // 사용자 북마크 위치 찾기에 사용
    startingHighlight: 'bukh-start',
    endingHighlight: 'bukh-end',
  }),
  mobileBreakpoint: 480,
  selectionLimitLength: 500,
  wideScreenBreakpoint: 1025,
});
