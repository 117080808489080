import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryResult } from './query-result';
import { LastBookOrder } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class LastOrderAPIService {
  constructor(private _http: HttpClient) {}

  get(): Observable<QueryResult<LastBookOrder>> {
    return this._http.get<QueryResult<LastBookOrder>>(
      `/api/v1/orders/lastbook`,
      {
        withCredentials: true,
        params: {
          offset: 0,
          limit: 2,
        },
      }
    );
  }
}
