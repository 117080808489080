<div>
  <div
    class="ic-backward paging{{ pagingFromJumped | abs }}"
    clickable
    (click)="jumpToPreviousPage()"
    [ngClass]="{ active: jumpedFrom }"
    id="{{ jumpedFrom == null ? 'null' : jumpedFrom.page }}"
  ></div>
  <div class="page-number">{{ currentPage }} / {{ totalPages }}</div>
</div>
