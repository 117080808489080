import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  DrawingHistoryChangeEvent,
  PDFDrawableOverlayView,
} from '../misc/pdf-drawable-overlay-view';
import { Observable, Subject, merge, of } from 'rxjs';
import { map, switchMap, throttleTime } from 'rxjs/operators';
import {
  PDFDrawingService as ViewerPDFDrawingService,
  DrawingTool,
  DrawingPenOption,
} from 'viewer';
import { Size } from '@bukio/viewer';

import { NativeBridgeService } from './native-bridge.service';
import '../misc/customize-fabric';
import { DrawingAPIService } from './drawing-api.service';
import { AppSettingsStoreService } from './app-settings-store.service';
import { NativeEnvironmentService } from './native-environment.service';

@Injectable({
  providedIn: 'root',
})
export class PDFDrawingService
  extends ViewerPDFDrawingService
  implements OnDestroy
{
  public isDrawingMode = false;
  public isDeviceSupportsStylus: boolean;

  private _resizeObserver: ResizeObserver;
  private _bid?: string;

  private _views: PDFDrawableOverlayView[] = [];
  private _viewIidMap = new Map<PDFDrawableOverlayView, string>();
  private _activeView?: PDFDrawableOverlayView;

  private _currentTool: DrawingTool = 'pen';
  private _currentToolOption?: DrawingPenOption = {
    color: 'red',
    width: 1,
    straight: false,
  };

  private _resize$ = new Subject<void>();

  public readonly historyChange$: Observable<DrawingHistoryChangeEvent>;

  private _isServerBackupSettingsOn = false;

  constructor(
    private _nativeBridgeService: NativeBridgeService,
    private _drawingAPIService: DrawingAPIService,
    private _settingsStoreService: AppSettingsStoreService,
    private _nativeEnvironmentService: NativeEnvironmentService,
    private _ngZone: NgZone
  ) {
    super();

    if (window.webkit?.messageHandlers.bukHybrid) {
      // not iphone
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.isDeviceSupportsStylus = !(
        /iPhone/.test(navigator.userAgent) && !(window as any).MSStream
      );
    } else if (window.bukAndroid) {
      this.isDeviceSupportsStylus = window.bukAndroid.isSupportsStylus();
    } else {
      this.isDeviceSupportsStylus = false;
    }

    this._settingsStoreService.settings$.subscribe((settings) => {
      this._isServerBackupSettingsOn = settings.backupDrawing;
    });

    this._resize$.pipe(throttleTime(300)).subscribe(() => {
      this._resize();
    });

    this._resizeObserver = new ResizeObserver(() => {
      this._resize$.next();
    });

    for (let i = 0; i < 2; i++) {
      const view = new PDFDrawableOverlayView();
      this._views.push(view);
    }

    this.historyChange$ = merge(
      ...this._views.map((view) => view.historyChange$)
    );

    window.bukApp.getDrawing = ():
      | { iid: string; drawing: string }
      | undefined => {
      if (!this._activeView) {
        return;
      }

      const iid = this._viewIidMap.get(this._activeView);

      if (!iid) {
        return;
      }

      const drawing = this._activeView.getDrawing();

      if (!drawing) {
        return;
      }

      return { iid, drawing };
    };
  }

  ngOnDestroy(): void {
    this._resize$.complete();
    this._resizeObserver.disconnect();
    delete window.bukApp.getDrawing;
  }

  get activeView(): PDFDrawableOverlayView | undefined {
    return this._activeView;
  }

  getViewFor(iid: string, pageSize: Size): Element {
    const newView = this._views.find((view) => view !== this._activeView)!;
    this._activeView = newView;
    this._viewIidMap.set(newView, iid);

    this._nativeBridgeService.getDrawing(iid).subscribe((drawing) => {
      newView.load(pageSize, drawing || undefined);
      newView.setTool(this._currentTool, this._currentToolOption);
    });
    this._resizeObserver.disconnect();
    this._resizeObserver.observe(newView.element);

    return newView.element;
  }

  createSVG(iid: string): Element | null {
    const div = document.createElement('div');

    this._nativeBridgeService.getDrawing(iid).subscribe((drawing) => {
      if (drawing) {
        div.innerHTML = drawing;
        const svgElem = div.firstElementChild as SVGElement;
        svgElem.style.setProperty('width', '100%');
        svgElem.style.setProperty('height', '100%');

        // safari 브라우저에서 페이지 이동 후에 svg가 표시되는 문제 수정
        svgElem.style.setProperty('transform', 'translate3d(0,0,0)');
      }
    });

    return div;
  }

  saveDrawingFromActiveView(): Observable<void> {
    if (!this._activeView) {
      return of(undefined);
    }

    const iid = this._viewIidMap.get(this._activeView);

    if (!iid) {
      return of(undefined);
    }

    const drawing = this._activeView.getDrawing();

    if (!drawing) {
      return of(undefined);
    }

    this._isServerBackupSettingsOn &&
      this._bid &&
      this._drawingAPIService.save(this._bid, iid, drawing).subscribe();

    return this._nativeBridgeService.setDrawing(iid, drawing);
  }

  private _resize(): void {
    this._activeView?.resize();
  }

  override loadDrawings(bid: string): Observable<void> {
    this._bid = bid;
    this._viewIidMap = new Map<PDFDrawableOverlayView, string>();
    this._activeView = undefined;
    this.isDrawingMode = false;

    return this._nativeBridgeService.loadDrawings(bid).pipe(
      map((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        return undefined;
      })
    );
  }

  override saveDrawings(): Observable<void> {
    return this.saveDrawingFromActiveView().pipe(
      switchMap(() => this._nativeBridgeService.saveDrawings())
    );
  }

  override clear(): void {
    this._activeView?.clearDrawing();
  }

  override setTool(
    tool: DrawingTool,
    option?: DrawingPenOption | undefined
  ): void {
    this._currentTool = tool;
    this._currentToolOption = option;
    this._activeView?.setTool(tool, option);
  }

  override undo(): void {
    this._activeView?.undo();
  }

  override redo(): void {
    this._activeView?.redo();
  }

  override getOverlayView(iid: string, pageSize: Size): Element | null {
    this._preventIOSMomentumScrolling();

    if (this.isDrawingMode) {
      return this.getViewFor(iid, pageSize);
    } else {
      return this.createSVG(iid);
    }
  }

  override willEndDisplayingOverlayView(element: Element): void {
    if (this._activeView?.element !== element) {
      return;
    }

    this.saveDrawingFromActiveView();

    if (!this.isDrawingMode) {
      this._activeView = undefined;
    }
  }

  override getDrawnItemList(): Observable<string[]> {
    return this._nativeBridgeService.getDrawnItemList().pipe(
      map((list) => {
        if (
          this.isDrawingMode &&
          this.activeView &&
          !this.activeView.isEmpty()
        ) {
          const iid = this._viewIidMap.get(this._activeView!);

          if (iid && list.indexOf(iid) === -1) {
            list.push(iid);
          }
        }

        return list;
      })
    );
  }

  override getDrawingURL(iid: string): string | null {
    if (
      this.isDrawingMode &&
      this.activeView &&
      this._viewIidMap.get(this.activeView) === iid
    ) {
      const drawing = this.activeView.getDrawing();

      if (!drawing) {
        return null;
      }

      return `data:image/svg+xml;charset=utf-8,${drawing}`;
    }

    return `bukapp://drawing/${iid}`;
  }

  _preventIOSMomentumScrolling(): void {
    if (this._nativeEnvironmentService.os !== 'ios') {
      return;
    }

    document.querySelectorAll('bukv-scroll-zoom').forEach((scrollContainer) => {
      if (scrollContainer.hasAttribute('bukapp-momentum-scrolling-disabled')) {
        return;
      }

      let scrollStarted = false;
      let overflow: string;

      scrollContainer.addEventListener('touchstart', (event) => {
        scrollStarted = (event as TouchEvent).touches.length === 1;
        overflow = (scrollContainer as HTMLElement).style.overflow;
      });

      const touchEndedHandler = (event: Event): void => {
        if (!scrollStarted || !this.isDrawingMode) {
          return;
        }

        (scrollContainer as HTMLElement).style.overflow = 'hidden';
        this._ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            (scrollContainer as HTMLElement).style.overflow = overflow;
          });
        });
      };

      scrollContainer.addEventListener('touchend', touchEndedHandler);
      scrollContainer.addEventListener('touchcancel', touchEndedHandler);

      scrollContainer.setAttribute('bukapp-momentum-scrolling-disabled', '');
    });
  }
}
