import { Component, OnInit } from '@angular/core';

import { ReadingGroup } from 'shared/models';

import { ReadingGroupsService } from '../../services/reading-groups.service';

import { ToastService, TypedDialog } from 'shared/ui';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { SharedUserService } from 'shared/services';
import { BookFeaturesStoreService } from '../../services/book-features-store.service';
import { EventBusService } from '../../services/event-bus.service';

@Component({
  selector: 'viewer-reading-mode-dialog',
  templateUrl: './reading-mode-dialog.component.html',
  styleUrl: './reading-mode-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class ReadingModeDialogComponent
  extends TypedDialog<void, void>
  implements OnInit
{
  public readonly _modes: [string, string[]][] = [
    [
      '혼자읽기',
      [
        '책 읽기에만 집중하며, 나만 볼 수 있는<br/>비공개 기록을 남길 수 있습니다.',
        '내 메모 중 공개하고싶은 메모를<br/>원하는 그룹에 공유할 수 있습니다.',
        '이 책에 대한 코멘터리를 구매했다면<br/>본문과 함께 열람할 수 있습니다.',
      ],
    ],
    [
      '함께읽기',
      [
        '하이라이트, 메모 기록을<br/>그룹원들과 서로 공유할 수 있습니다.',
        '도움이 되는 메모를<br/>추천할 수 있습니다.',
        '공유된 메모를 내 비공개 메모로<br/>복사할 수 있습니다.',
        '내 메모 중 공개하고싶은 메모를<br/>원하는 그룹에 공유할 수 있어요.',
      ],
    ],
  ];

  public _selectedModeIndex = -1;

  public _selectedGroupId?: number;
  public _currentGroupId?: number;
  public _groups: ReadingGroup[] = [];

  constructor(
    private _readingGroupsService: ReadingGroupsService,
    private _annotationsService: AnnotationsV2Service,
    private _userService: SharedUserService,
    private _toastService: ToastService,
    private _bookFeaturesStoreService: BookFeaturesStoreService,
    private _eventBusService: EventBusService
  ) {
    super();
  }

  ngOnInit(): void {
    this._currentGroupId = this._readingGroupsService.currentGroup?.id;

    this._selectedModeIndex = this._currentGroupId != null ? 1 : 0;
    this._selectedGroupId = undefined;

    this._groups = this._readingGroupsService.myGroups;
  }

  onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  _onSelectMode(index: number): void {
    if (index === 1) {
      // 함께 읽기 모드 선택됨

      // 함께 읽기 모드 이용 정지 유저
      const groupReaderDisabled =
        this._userService.getUser()?.group_reader_disabled;

      if (groupReaderDisabled != null) {
        const until = new Date(groupReaderDisabled);
        const since = new Date(groupReaderDisabled);
        since.setDate(until.getDate() + 1);

        this._toastService.openWarning(
          `신고 횟수 누적으로 ${until.getUTCFullYear()}.${
            until.getUTCMonth() + 1
          }.${until.getUTCDate()}일까지 혼자읽기 모드만 이용이 가능합니다. ${since.getUTCFullYear()}.${
            since.getUTCMonth() + 1
          }.${since.getUTCDate()}일부터 다시 함께읽기 모드를 이용할 수 있습니다.`
        );

        return;
      }

      // 함께 읽기 모드 이용 불가 콘텐츠
      if (!this._bookFeaturesStoreService.features.group_reading) {
        this._toastService.openWarning(
          `출판사 정책에 따라 본 책은 '함께읽기 모드' 이용이 불가합니다.`
        );
        return;
      }
    }

    this._selectedModeIndex = index;
    this._selectedGroupId = undefined;
  }

  _onOKButtonClick(): void {
    this._eventBusService.fire('ReadingModeDialogComponent:groupChanged', {
      exited: this._currentGroupId ?? null,
      entered: this._selectedGroupId ?? null,
    });

    this._annotationsService.setState({ groupId: this._selectedGroupId });
    this._dialogRef.close();
  }
}
