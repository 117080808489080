<div class="header">
  <button
    [class.is-hidden]="!_canGoBack"
    [disabled]="!_canGoBack"
    (click)="_onBackButtonClick()"
    sui-icon-button
  >
    <sui-icon name="원형 이동_왼쪽"></sui-icon>
  </button>
  {{ _pageTitles[_currentPage] }}
  <button sui-icon-button (click)="_onCloseButtonClick()">
    <sui-icon name="엑스"></sui-icon>
  </button>
</div>
<div class="divider" *ngIf="_showHeaderDivider"></div>
<div
  class="page mode-selection"
  [class.is-hidden]="_currentPage !== _Page.modeSelection"
>
  <lib-reading-mode-selection
    (modeSelected)="_onReadingModeSelected($event)"
    [bid]="_data.bid"
  ></lib-reading-mode-selection>
</div>
<div class="page" *ngIf="_currentPage === _Page.groupCreation">
  <lib-reading-group-edit
    [contentId]="_data.bid"
    (groupCreated)="_onGroupCreated()"
  ></lib-reading-group-edit>
</div>
<div class="page" [class.is-hidden]="_currentPage !== _Page.groupSelection">
  <lib-reading-group-selection
    [contentId]="_data.bid"
    (createGroupButtonClick)="_goToPage(_Page.groupCreation)"
    (groupDetailsButtonClick)="_onGroupDetailsButtonClick($event)"
    (enterGroupButtonClick)="_onEnterGroupButtonClick($event)"
  ></lib-reading-group-selection>
</div>
<div class="page" *ngIf="_currentPage === _Page.groupDetails">
  <lib-reading-group-details
    *ngIf="detailsReadingGroup"
    [group]="detailsReadingGroup"
    (editGroupButtonClick)="_onEditGroupButtonClick()"
    (groupExited)="_onGroupExited()"
    (groupJoined)="_onGroupJoined()"
    (inviteGroupMemberButtonClick)="_goToPage(_Page.invitation)"
    (enterGroupButtonClick)="_onEnterGroupButtonClick($event)"
  >
  </lib-reading-group-details>
</div>
<div class="page" *ngIf="_currentPage === _Page.groupEdit">
  <lib-reading-group-edit
    [initialValue]="detailsReadingGroup"
    (groupEdited)="_onGroupEdited()"
  ></lib-reading-group-edit>
</div>
<div class="page" *ngIf="_currentPage === _Page.invitation">
  <lib-reading-group-invitation
    *ngIf="detailsReadingGroup"
    [groupId]="detailsReadingGroup.id"
  ></lib-reading-group-invitation>
</div>
