<div class="preview" [attr.data-ratio]="ratio" #preview>
  <div class="image" [attr.data-ratio]="ratio" #image>
    <div class="background-image">
      <canvas #bgCanvas></canvas>
    </div>
    <div class="main">
      <div class="quote" [attr.data-ratio]="ratio">
        <div class="text-box">
          <div class="text">
            {{ memo.text }}
          </div>
          <sui-icon class="quote-icon start" name="따옴표"></sui-icon>
          <sui-icon class="quote-icon end" name="따옴표"></sui-icon>
        </div>
        <div class="memo">
          <div class="dummy-text">
            <div class="dummy-text-content">
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파가
              가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타파하가나다라마바사아자차카타
            </div>
          </div>
          <div class="paper"></div>
          <div class="memo-content">
            <img class="logo" src="assets/images/logo-symbol.svg" />
            <div class="content">
              {{ memo.content }}
            </div>
            <div class="info">
              <div
                class="counts"
                [class.is-hidden]="readingMode === 'single' || hideCounts"
              >
                <div class="count">
                  <sui-icon name="추천" type="solid" class="icon"></sui-icon>
                  {{ memo.like_count }}
                </div>
                <div class="count">
                  <sui-icon name="복사" type="solid" class="icon"></sui-icon>
                  {{ memo.copy_count }}
                </div>
              </div>
              <div class="author">
                <canvas #avatarCanvas class="avatar"></canvas>
                <div class="nickname">{{ author.nickname }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <canvas class="cover" #coverCanvas></canvas>
        <b>북이오에서 &lt;</b><b class="title">{{ bookTitle }}</b
        ><b>&gt;</b
        ><span
          >&nbsp;책 {{ readingMode === 'single' ? '' : '함께' }}읽기 중</span
        >
      </div>
    </div>
  </div>
</div>
