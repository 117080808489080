import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[libIntersectionListener]',
})
export class IntersectionListenerDirective implements AfterViewInit, OnInit {
  @Output() libIntersectionListener = new EventEmitter<boolean>();
  observer!: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.intersectionObserver();
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  intersectionObserver(): void {
    const options = {
      root: this.element.nativeElement.parentElement,
      rootMargin: '100px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.libIntersectionListener.emit(true);
      }
    }, options);
  }
}
