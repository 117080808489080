<sui-dialog-header class="header" (closeButtonClick)="_onCloseButtonClick()"
  >메모 / 회원 신고하기</sui-dialog-header
>
<div class="body">
  <form [formGroup]="_reportFormGroup" (ngSubmit)="_onSubmit()">
    <section>
      <h2>메모 작성 회원</h2>
      <div class="author">
        <sui-reading-group-member-avatar
          class="avatar"
          [avatarImageURL]="_data.authorAvatarImageURL"
        ></sui-reading-group-member-avatar>
        <span class="nickname">{{ _data.authorName }}</span>
      </div>
    </section>
    <section>
      <h2>신고 사유 선택</h2>
      <div class="radio-group">
        <div class="radio-button" *ngFor="let reason of _reasons">
          <sui-radio-button>
            {{ reason }}
            <input type="radio" formControlName="reason" [value]="reason" />
          </sui-radio-button>
        </div>
      </div>
    </section>
    <section>
      <h2>신고 내용 입력</h2>
      <div class="input">
        <textarea
          (keydown)="$event.stopPropagation()"
          formControlName="details"
        ></textarea>
      </div>
    </section>
    <div class="actions">
      <div class="block-user">
        <div class="text">해당 이용자의 모든 기록을 볼 수 없게됩니다.</div>
        &nbsp;&nbsp;
        <sui-checkbox>
          {{ _data.authorName }}님 차단하기
          <input type="checkbox" formControlName="block" />
        </sui-checkbox>
      </div>
      <button
        sui-solid-button
        varSize="medium40::large"
        type="submit"
        [disabled]="_reportFormGroup.invalid"
      >
        신고하기
      </button>
    </div>
  </form>
</div>
