import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mark',
  pure: true,
})
export class MarkPipe implements PipeTransform {
  transform(value: string, marker: string, markClass: string): string {
    const words = marker
      .replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
      .split(/\s+/)
      .filter((n) => n && n.length > 0);

    return value.replace(
      new RegExp(words.join('|'), 'gi'),
      (m) => `<span class="${markClass}">${m}</span>`
    );
  }
}
