import { Pipe, PipeTransform } from '@angular/core';

function getLocaleCurrencyNumberString(
  number: number,
  currency: string
): string {
  try {
    return number.toLocaleString('ko', {
      currency: currency === 'STEEM' || currency === 'SBD' ? 'USD' : currency,
    });
  } catch (error) {
    return number.toString();
  }
}

@Pipe({
  name: 'currencyTranslate',
  pure: true,
})
export class CurrencyTranslatePipe implements PipeTransform {
  transform(value: number, currency: string, numWrapClass?: string): string {
    let price = getLocaleCurrencyNumberString(value, currency);

    if (numWrapClass) {
      price = `<span class="${numWrapClass}">${price}</span>`;
    }

    switch (currency) {
      case 'USD':
        return `$${price} (USD)`;
      case 'THB':
        return `฿${price} (THB)`;
      case 'GBP':
        return `£${price} (GBP)`;
      case 'KRW':
        return `${price} 원`;
      case 'SBD':
        return `$${price} (SBD)`;
      case 'STEEM':
        return `${price} (STEEM)`;
      default:
        return price;
    }
  }
}
