<sui-dialog-header (closeButtonClick)="_dialogRef.close()"
  >백업할 필기 기록 선택</sui-dialog-header
>
<div class="body">
  <div class="description" *ngIf="_books && _books.length > 0">
    백업 및 복원은 많은 저장공간을 필요로 합니다.<br />저장 공간을 여유있게
    확보한 후 작업을 진행해 주세요.
  </div>
  <div class="list-container">
    <div class="list">
      <div
        class="book"
        *ngFor="let book of _books; index as i"
        (click)="_onBookClick(i)"
        [class.is-selected]="book.selected"
      >
        <sui-book layout="mobile">
          <img sui-book-cover [src]="book.coverImageURL" />
          <sui-book-title>{{ book.title }}</sui-book-title>
          <sui-book-author>{{ book.author }}</sui-book-author>
          <div class="sui-book__cover-overlay">
            <div class="check-circle">
              <sui-icon name="체크"></sui-icon>
            </div>
          </div>
        </sui-book>
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="_books && _books.length > 0">
    <button
      class="button"
      varSize="large"
      sui-solid-button
      [disabled]="_selectedBookCount === 0"
      (click)="_onBackupButtonClick()"
    >
      백업하기
    </button>
  </div>
  <div class="loader" *ngIf="!_books">
    <sui-loader></sui-loader>
  </div>
  <div class="placeholder" *ngIf="_books?.length === 0">
    필기 기록이 없습니다.
  </div>
</div>
