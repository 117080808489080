import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawingAPIService {
  constructor(private _http: HttpClient) {}

  save(bid: string, iid: string, data: string): Observable<void> {
    return this._http.post<void>(
      '/api/v1/drawing',
      {
        bid,
        iid,
        data,
      },
      {
        withCredentials: true,
      }
    );
  }
}
