import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  constructor(private _authService: AuthService) {}

  _onLoginButtonClick(): void {
    this._authService.login(location.origin);
  }
}
