<bukv-viewer
  [config]="_libConfig"
  (bookLoad)="_onBookLoad($event)"
  (bookLoadError)="_onBookLoadError($event)"
  (addressChange)="_onAddressChange($event)"
  (settingsChange)="_onSettingsChange($event)"
  (itemLoadStart)="_onItemLoadStart($event)"
  (itemLoad)="_onItemLoad($event)"
  (itemLoadError)="_onItemLoadError($event)"
  (pageInfoChange)="_onPageInfoChange($event)"
  (bookmarkStateChange)="_onBookmarkStateChange($event)"
  (pageTap)="_onPageTap($event)"
  (selectionChange)="_selectionChange$.next($event)"
  (highlightClick)="_onHighlightClick($event)"
  (annotationCreated)="_onAnnotationCreated($event)"
  (annotationChanged)="_onAnnotationChanged($event)"
  (annotationRemoved)="_onAnnotationRemoved($event)"
  (pageChangeCanceled)="_onPageChangeCanceled($event)"
  (zoomScaleChange)="_onZoomScaleChange($event)"
  (pageTransitionEnd)="_onPageTransitionEnd()"
></bukv-viewer>
