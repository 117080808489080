import { Component, OnInit, Input, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BroadcastService } from '../../services/broadcast.service';

@Component({
  selector: 'viewer-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() name!: string;
  @Input() opaque = false;
  @Input() defaultActive = false;

  // @HostBinding('class.active') active = false;

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  constructor(private $: BroadcastService, private element: ElementRef) {}

  ngOnInit(): void {
    this.setActive(this.defaultActive);
    if (this.opaque) {
      this.element.nativeElement.classList.add('opaque');
    }

    if (this.name) {
      this.$.on<boolean>(`loader.show.${this.name}`)
        .pipe(takeUntil(this.unsubscribeBroadcast))
        .subscribe((opaque) => {
          this.setActive(true);
          if (opaque) {
            this.element.nativeElement.classList.add('opaque');
          }
        });

      this.$.on(`loader.hide.${this.name}`)
        .pipe(takeUntil(this.unsubscribeBroadcast))
        .subscribe(() => {
          this.setActive(false);
          if (
            !this.opaque &&
            this.element.nativeElement.classList.contains('opaque')
          ) {
            this.element.nativeElement.classList.remove('opaque');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  private setActive(active: boolean): void {
    if (active) {
      this.element.nativeElement.classList.add('active');
    } else {
      this.element.nativeElement.classList.remove('active');
    }
  }
}
