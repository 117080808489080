import { ReadingGroup } from 'shared/models';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

export interface ReadingGroupSearchAPIResponse {
  total: number;
  offset: number;
  limit: number;
  items: ReadingGroup[];
  status: 'normal' | 'invited' | 'kicked';
}

@Injectable({
  providedIn: 'root',
})
export class ReadingGroupSearchAPIService {
  constructor(private _http: HttpClient) {}

  getJoinableGroups(
    q: string,
    offset: number,
    limit: number,
    public_only: number,
    order_by: 'member_count' | 'highlight_count' | 'memo_count'
  ): Observable<ReadingGroupSearchAPIResponse> {
    const params = {
      q,
      offset,
      limit,
      public_only,
      order_by,
    };

    return this._http.get<ReadingGroupSearchAPIResponse>(
      `/api/v1/search/groups/joinable`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  getJoinedGroups(
    q: string,
    offset: number,
    limit: number,
    public_only: number,
    order_by: 'member_count' | 'highlight_count' | 'memo_count'
  ): Observable<ReadingGroupSearchAPIResponse> {
    const params = {
      q,
      offset,
      limit,
      public_only,
      order_by,
    };

    return this._http.get<ReadingGroupSearchAPIResponse>(
      `/api/v1/search/groups/joined`,
      {
        params,
        withCredentials: true,
      }
    );
  }

  getUnavailableGroups(
    q: string,
    offset: number,
    limit: number,
    public_only: number,
    order_by: 'member_count' | 'highlight_count' | 'memo_count'
  ): Observable<ReadingGroupSearchAPIResponse> {
    const params = {
      q,
      offset,
      limit,
      public_only,
      order_by,
    };

    return this._http.get<ReadingGroupSearchAPIResponse>(
      `/api/v1/search/groups/unavailable`,
      {
        params,
        withCredentials: true,
      }
    );
  }
}
