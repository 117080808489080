import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Optional,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CdkMenuTrigger } from '@angular/cdk/menu';

import { Direction } from '@bukio/viewer';

import { PDFDrawingService } from '../../services/pdf-drawing.service';
import { UIStateService } from '../../services/ui-state.service';
import { EventBusService } from '../../services/event-bus.service';

enum Tool {
  Pen1 = 'pen1',
  Pen2 = 'pen2',
  Eraser = 'eraser',
}

interface PenConfig {
  width: number;
  opacity: number;
  colorIndex: number;
  isStraightMode: boolean;
}

const DEFAULT_PALETTE = [
  '#F8A425',
  '#021523',
  '#D9D9E0',
  '#93A9C0',
  '#F5402B',
  '#01B66F',
  '#1D62A5',
  '#F41BEC',
  '#1DC8E2',
  '#844FFB',
];

const PALETTE_LOCALSTORAGE_KEY = 'viewer-palette';

function alphaToHex(alpha: number): string {
  const alphaValue = Math.round(alpha * 255);
  const hex = alphaValue.toString(16).padStart(2, '0');
  return hex.toUpperCase();
}

@Component({
  selector: 'viewer-drawing-tool-bar',
  templateUrl: './drawing-tool-bar.component.html',
  styleUrl: './drawing-tool-bar.component.scss',
})
export class DrawingToolBarComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-active') isActive = false;
  @ViewChildren(CdkMenuTrigger)
  colorPickerMenuTriggers?: QueryList<CdkMenuTrigger>;

  private unsubscriber = new Subject<void>();

  public _Tool = Tool;
  public _selectedTool?: Tool;

  public _canUndo = false;
  public _canRedo = false;

  /**
   * 팔레트 UI가 리렌더링이 되면 컬러 피커가 닫히기 때문에 색상 변경을 해도 컬러 피커가 닫히지 않게 하기 위해
   * 문자열의 배열이 아닌 객체의 배열로 취급하여 색상이 변경되어도 팔레트 UI가 리랜더링이 일어나지 않도록 함
   */
  public _colors: { hex: string }[] = [];

  public _penConfigs: { [pen: string]: PenConfig } = {
    [Tool.Pen1]: {
      width: 1.5,
      opacity: 1,
      colorIndex: 1,
      isStraightMode: false,
    },
    [Tool.Pen2]: {
      width: 4,
      opacity: 0.3,
      colorIndex: 0,
      isStraightMode: false,
    },
  };

  public _selectedPenConfig?: PenConfig;

  public _isColorPickerOpened = false;

  constructor(
    private _uiStateService: UIStateService,
    private _eventBusService: EventBusService,
    @Optional() private _pdfDrawingService?: PDFDrawingService
  ) {
    if (this._loadPalette()) {
      this._penConfigs[Tool.Pen1].colorIndex = 0;
      this._penConfigs[Tool.Pen2].colorIndex = 0;
    }
  }

  ngOnInit(): void {
    this._uiStateService.isDrawingMode$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((isDrawingMode) => {
        this.isActive = isDrawingMode;

        if (this.isActive && !this._selectedTool) {
          this._onSelectTool(Tool.Pen1);
        }
      });

    this._pdfDrawingService?.historyChange$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data) => {
        this._canUndo = data.canUndo;
        this._canRedo = data.canRedo;
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  get _isRulerOn(): boolean {
    if (this._selectedTool !== Tool.Pen1 && this._selectedTool !== Tool.Pen2) {
      return false;
    }

    return this._penConfigs[this._selectedTool].isStraightMode;
  }

  _onSelectTool(tool: Tool): void {
    if (tool === Tool.Pen1 || tool === Tool.Pen2) {
      if (this._selectedTool === tool) {
        if (this._selectedPenConfig) {
          this._selectedPenConfig = undefined;
        } else {
          this._selectedPenConfig = this._penConfigs[tool];
        }
      } else {
        this._selectedPenConfig = undefined;
      }
    } else if (tool === Tool.Eraser) {
      this._selectedPenConfig = undefined;
    }

    if (!this._selectedPenConfig) {
      this._isColorPickerOpened = false;
    }

    this._selectedTool = tool;
    this._applyTool();
  }

  _toggleRuler(): void {
    if (this._selectedTool !== Tool.Pen1 && this._selectedTool !== Tool.Pen2) {
      return;
    }

    this._penConfigs[this._selectedTool].isStraightMode =
      !this._penConfigs[this._selectedTool].isStraightMode;

    this._applyTool();
  }

  _onClearButtonClick(): void {
    this._pdfDrawingService?.clear();
  }

  _onCloseButtonClick(): void {
    this._uiStateService.isDrawingMode = false;
  }

  _onPenColorClick(index: number): void {
    if (this._selectedPenConfig!.colorIndex === index) {
      return;
    }

    this._selectedPenConfig!.colorIndex = index;
    this._onPenConfigChanged();
  }

  _onPenConfigChanged(): void {
    if (!this._selectedTool) {
      return;
    }

    this._applyTool();
  }

  private _applyTool(): void {
    if (this._selectedTool === Tool.Eraser) {
      this._pdfDrawingService?.setTool('eraser');
    } else if (
      this._selectedTool === Tool.Pen1 ||
      this._selectedTool === Tool.Pen2
    ) {
      this._pdfDrawingService?.setTool('pen', {
        width: this._penConfigs[this._selectedTool].width,
        color:
          this._colors[this._penConfigs[this._selectedTool].colorIndex].hex +
          alphaToHex(this._penConfigs[this._selectedTool].opacity),
        straight: this._penConfigs[this._selectedTool].isStraightMode,
      });
    }
  }

  _onUndoButtonClick(): void {
    this._canUndo && this._pdfDrawingService?.undo();
  }

  _onRedoButtonClick(): void {
    this._canRedo && this._pdfDrawingService?.redo();
  }

  _colorChanged(index: number, color: string): void {
    this._colors[index].hex = color;
  }

  _colorPickerClosed(): void {
    this._applyTool();
    this._savePalette();
  }

  _onToolBarDragStarted(): void {
    this.colorPickerMenuTriggers?.forEach((trigger) => trigger.close());
  }

  _onChangePageButtonClick(direction: 1 | -1): void {
    this._eventBusService.fire('DrawingToolBarComponent:pageChange', {
      direction: direction === 1 ? Direction.Next : Direction.Prev,
    });
  }

  private _savePalette(): void {
    localStorage.setItem(
      PALETTE_LOCALSTORAGE_KEY,
      JSON.stringify(this._colors.map(({ hex }) => hex))
    );
  }

  private _loadPalette(): boolean {
    const saved = localStorage.getItem(PALETTE_LOCALSTORAGE_KEY);
    let palette: string[];

    if (!saved) {
      palette = DEFAULT_PALETTE;
    } else {
      try {
        palette = JSON.parse(saved);
      } catch (error) {
        palette = DEFAULT_PALETTE;
      }
    }

    this._colors = palette.map((color) => ({ hex: color }));
    return !!saved;
  }
}
