import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { APIError, BookFeaturesAPIService } from 'shared/services';
import { ToastService } from 'shared/ui';

export enum ReadingMode {
  single = 'single',
  group = 'group',
}

@Component({
  selector: 'lib-reading-mode-selection',
  templateUrl: './reading-mode-selection.component.html',
  styleUrl: './reading-mode-selection.component.scss',
})
export class ReadingModeSelectionComponent implements OnInit {
  @Output() modeSelected = new EventEmitter<ReadingMode>();

  @Input() bid!: string;

  public _isLoadingModeAvailability = true;
  public _canSelectGroupReadingMode = false;

  constructor(
    private _bookFeaturesAPIService: BookFeaturesAPIService,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getBookFeatures();
  }

  getBookFeatures(): void {
    this._isLoadingModeAvailability = true;

    this._bookFeaturesAPIService.get(this.bid).subscribe({
      next: (features) => {
        this._isLoadingModeAvailability = false;
        this._canSelectGroupReadingMode = features.group_reading;
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  _onSelectSingleMode(): void {
    this.modeSelected.emit(ReadingMode.single);
  }

  _onSelectGroupMode(): void {
    if (!this._canSelectGroupReadingMode) {
      this._toastService.openWarning(
        `출판사 정책에 따라 본 책은 '함께읽기 모드' 이용이 불가합니다.`
      );
      return;
    }

    this.modeSelected.emit(ReadingMode.group);
  }
}
