import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Bookshelf, UserProfile } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';

@Component({
  selector: 'app-bookshelf',
  templateUrl: './bookshelf.component.html',
  styleUrl: './bookshelf.component.scss',
})
export class BookshelfComponent implements OnInit {
  @Output() changePageType = new EventEmitter<string>();
  @Input() userId?: string;
  @Input() parentElement?: HTMLDivElement;

  @ViewChild('bookshelf') private bookshelf?: ElementRef;

  public _bookshelvesList?: Bookshelf[];
  public _userProfile?: UserProfile;

  public _currentPage = 1;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(private _bookshelfAPIService: BookshelfAPIService) {}

  ngOnInit(): void {
    this._getBookshelf(0);
  }

  private _getBookshelf(page: number): void {
    this._isLoading = true;

    this._bookshelfAPIService
      .getOthersBookshelvesList(
        page * this._itemCountPerPage,
        this._itemCountPerPage,
        this.userId
      )
      .subscribe((response) => {
        this._isLoading = false;

        if (this.bookshelf) {
          this.bookshelf.nativeElement.scrollTop = 0;
        }

        if (this.parentElement) {
          this.parentElement.scrollTop = 0;
        }

        this._totalItemCount = response.total;
        this._currentPage = page;
        this._bookshelvesList = response.items;
      });
  }

  public _onClickBookshelfves(id: string): void {
    this.changePageType.emit(id);
  }

  public _onPageChange(page: number): void {
    this._currentPage = page;
    this._getBookshelf(page);
  }
}
