import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BookFeatures } from 'shared/models';
import { catchAndRethrowAPIError } from '../api-error';

const GET_BOOK_FEATURES_ERROR_MSG_MAP = {
  'Book not found': '존재하지 않는 책입니다.',
};

@Injectable({
  providedIn: 'root',
})
export class BookFeaturesAPIService {
  constructor(private _http: HttpClient) {}

  get(bid: string): Observable<BookFeatures> {
    return this._http
      .get<BookFeatures>(`/api/v1/books/${bid}/features`)
      .pipe(catchAndRethrowAPIError(GET_BOOK_FEATURES_ERROR_MSG_MAP));
  }
}
