import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelfBook } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

interface SubscriptionItem {
  id: string;
  title: string;
  description: string;
  logo_image_url: string;
  book_count: number;
}

@Component({
  selector: 'lib-add-book',
  templateUrl: './add-book.component.html',
  styleUrl: './add-book.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class AddBookComponent
  extends TypedDialog<
    { bookshelf_id: string; bookshelfTitle: string },
    { canceled: boolean }
  >
  implements OnInit, OnDestroy
{
  @ViewChild('listContainer') private listContainer?: ElementRef;

  private _bookListSubject = new Subject<{ page: number }>();
  private _subscriptionListSubject = new Subject<void>();
  private _bookListInLibrarySubject = new Subject<{ page: number }>();

  public _bookList?: BookshelfBook[];
  public _subscriptionList?: SubscriptionItem[];
  public _bookListInLibrary?: BookshelfBook[];
  public _gnbNavbarType: 'ebook' | 'library' = 'ebook';
  public _isSelectLibrary: boolean = false;
  public _selectedLibrary: {
    value: string;
    id: string;
  } = {
    value: '',
    id: '',
  };
  public _checkedBookList: string[] = [];
  public _checkedBookListInLibrary: string[] = [];

  public _total: number = 0;
  public _currentPage: number = 0;
  public _itemCountPerPage: number = 30;
  public _isLoading: boolean = true;

  constructor(
    private _toastService: ToastService,
    private _bookshelfAPIService: BookshelfAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._bookListSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._bookshelfAPIService.getOwnedBooks(
            page * this._itemCountPerPage,
            this._itemCountPerPage,
            false
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }

        this._total = result.total;
        this._bookList = result.items;
      });

    this._subscriptionListSubject
      .pipe(
        switchMap(() => {
          this._isLoading = true;
          return this._bookshelfAPIService.getSubscriptionList(0, 1000);
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        this._subscriptionList = result.items;
      });

    this._bookListInLibrarySubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;

          return this._bookshelfAPIService.getBooks(
            this._selectedLibrary.id,
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }

        this._total = result.total;
        this._bookListInLibrary = result.items;

        if (this._bookListInLibrary.length === 0) {
          this._bookListInLibrary = undefined;
        }
      });

    this._bookListSubject.next({ page: 0 });
  }

  ngOnDestroy(): void {
    this._bookListSubject.complete();
    this._bookListInLibrarySubject.complete();
    this._subscriptionListSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close({ canceled: true });
  }

  public _changeGnbNavbar(type: 'ebook' | 'library'): void {
    this._gnbNavbarType = type;
    this._total = 0;
    this._currentPage = 0;

    if (this._gnbNavbarType === 'library') {
      this._subscriptionListSubject.next();
      this._bookListInLibrary = undefined;
      this._selectedLibrary.value = '';
      this._isSelectLibrary = false;
    } else {
      this._bookListSubject.next({ page: 0 });
    }
  }

  public _changeIsSelectLibrary(): void {
    this._isSelectLibrary = !this._isSelectLibrary;
  }

  public _changeLibrary(library_value: string, library_id: string): void {
    this._selectedLibrary = {
      value: library_value,
      id: library_id,
    };
    this._bookListInLibrarySubject.next({ page: 0 });

    this._changeIsSelectLibrary();
  }

  public _onClickBookItem(book_id: string): void {
    if (this._checkedBookList.includes(book_id)) {
      this._checkedBookList = this._checkedBookList.filter((id) => {
        return id !== book_id;
      });
    } else {
      this._checkedBookList.push(book_id);
    }
  }

  public _onClickBookItemInLibrary(book_id: string): void {
    if (this._checkedBookListInLibrary.includes(book_id)) {
      this._checkedBookListInLibrary = this._checkedBookListInLibrary.filter(
        (id) => {
          return id !== book_id;
        }
      );
    } else {
      this._checkedBookListInLibrary.push(book_id);
    }
  }

  public _onClickInsertButton(): void {
    this._bookshelfAPIService
      .insertBooks(this._data.bookshelf_id, [
        ...this._checkedBookList,
        ...this._checkedBookListInLibrary,
      ])
      .subscribe({
        next: () => {
          this._toastService.open('선택한 책이 책장에 추가되었습니다.');
          this._dialogRef.close({ canceled: false });
        },
        error: (error: HttpErrorResponse) => {
          this._toastService.open(
            `오류가 발생했습니다. 잠시 후 다시 시도해주세요. (${error.status})`
          );
        },
      });
  }

  public _onPageChange(page: number): void {
    if (this._gnbNavbarType === 'ebook') {
      this._bookListSubject.next({ page });
    } else {
      this._bookListInLibrarySubject.next({ page });
    }
  }
}
