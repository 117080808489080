import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, filter, switchMap, takeUntil, merge } from 'rxjs';

import { APIError, MemosAPIService } from 'shared/services';
import { Memo } from 'shared/models';
import { ToastService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { BookService } from '../../services/book.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';
import { OnlineReadingMemberService } from '../../services/online-reading-member.service';
import { MemoReadStatusService } from '../../services/memo-read-status.service';
import { UIStateService } from '../../services/ui-state.service';

import { HeaderMenuItem } from '../header/header.component';
import { RightPanelComponent } from '../right-panel/right-panel.component';
import { FooterMenuItem } from '../footer/footer.component';

@Component({
  selector: 'viewer-reading-group-memo-list',
  templateUrl: './reading-group-memo-list.component.html',
  styleUrl: './reading-group-memo-list.component.scss',
})
export class ReadingGroupMemoListComponent implements OnInit {
  @ViewChild('scrollContainer', { static: true })
  private _scrollContainerElem!: ElementRef<HTMLDivElement>;

  private _unsubscriber = new Subject<void>();

  public readonly _itemCountPerPage = 10;

  public _total = 0;
  public _memos?: Memo[];

  public _currentPage = 1;

  private _load$ = new Subject<number>();

  public _onlineUserMap: { [userId: string]: boolean } = {};
  public _unreadMemoCount = 0;

  public _activeMemo?: Memo;

  constructor(
    private _eventBus: EventBusService,
    private _rightPanelComponent: RightPanelComponent,
    private _memosAPIService: MemosAPIService,
    private _bookService: BookService,
    private _readingGroupsService: ReadingGroupsService,
    private _onlineReadingMemberService: OnlineReadingMemberService,
    private _memoReadStatusService: MemoReadStatusService,
    private _toastService: ToastService,
    private _uiStateService: UIStateService
  ) {}

  ngOnInit(): void {
    merge(
      this._eventBus
        .on('HeaderComponent:menuClick')
        .pipe(filter(({ menu }) => menu === HeaderMenuItem.GroupMemos)),
      this._eventBus
        .on('FooterComponent:menuClick')
        .pipe(filter(({ menu }) => menu === FooterMenuItem.GroupMemos))
    )
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this._open();
      });

    this._eventBus
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this._memos = undefined;
        this._currentPage = 1;
      });

    this._eventBus
      .on('ContentsComponent:addressChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ address }) => {
        if (!this._activeMemo) {
          return;
        }

        if (address.toString() !== this._activeMemo.url) {
          this._activeMemo = undefined;
        }
      });

    this._eventBus
      .on('RightPanelComponent:closed')
      .pipe(
        filter(({ activePanel }) => activePanel === 'groupMemo'),
        takeUntil(this._unsubscriber)
      )
      .subscribe(() => {
        this._readingGroupsService.refreshGroupInfo();
      });

    this._load$
      .pipe(
        switchMap((page) => {
          return this._memosAPIService.get(
            this._bookService.book!.meta.bid,
            (page - 1) * this._itemCountPerPage,
            this._itemCountPerPage,
            {
              group_id: this._readingGroupsService.currentGroup!.id,
              order_by: 'created_at',
            }
          );
        })
      )
      .subscribe((response) => {
        if (response.offset > 0 && response.items.length === 0) {
          this._load$.next(1);
          return;
        }

        const firstMemoInPrevList = this._memos?.[0]?.id;

        this._memos = response.items;
        this._total = response.total;
        this._currentPage = response.offset / this._itemCountPerPage + 1;

        this._onlineUserMap = {};
        this._memos.forEach((m) => {
          this._onlineUserMap[m.author.id] =
            this._onlineReadingMemberService.isOnline(m.author.id);
        });

        if (
          firstMemoInPrevList &&
          firstMemoInPrevList !== this._memos?.[0]?.id
        ) {
          this._scrollContainerElem.nativeElement.scrollTop = 0;
        }
      });
  }

  private _open(): void {
    const group = this._readingGroupsService.currentGroup;

    if (!this._bookService.book || !group) {
      return;
    }

    this._rightPanelComponent.open('groupMemo');

    this._unreadMemoCount = group.memo_unread_count;

    /**
     * 안 읽은 메모가 있다면 1 페이지를 불러옴
     * 안 읽은 메모가 없다면 이전에 보던 페이지를 불러옴 (내가 추가, 수정 또는 삭제한 메모가 있을 수 있기 때문에 새로 불러와야함)
     */
    this._load$.next(this._unreadMemoCount > 0 ? 1 : this._currentPage);
  }

  private _close(): void {
    if (!this._rightPanelComponent.isOpened('groupMemo')) {
      return;
    }

    this._rightPanelComponent.close(false);
  }

  public _refresh(): void {
    this._load$.next(this._currentPage);
  }

  public _onPageChanged(page: number): void {
    this._load$.next(page);
  }

  public _onMemoRead(memo: Memo): void {
    !memo.is_read && this._memoReadStatusService.read(memo.id);
  }

  public _readAllButtonClick(): void {
    const group = this._readingGroupsService.currentGroup;

    if (!group) {
      return;
    }

    this._memosAPIService.updateReadStatusAll(group.id).subscribe({
      next: () => {
        this._unreadMemoCount = 0;
        this._memos?.forEach((memo) => (memo.is_read = 1));
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  public _onMemoClick(memo: Memo): void {
    if (!this._uiStateService.canUseMemoSidebar()) {
      this._close();
    }

    this._activeMemo = memo;

    this._eventBus.fire('ReadingGroupMemoListComponent:memoClick', {
      url: memo.url,
    });
  }

  public _onMemoUpdated(memo: Memo, updated: Partial<Memo>): void {
    Object.keys(updated).forEach((key) => {
      (memo as any)[key] = updated[key as keyof Memo];
    });
  }
}
