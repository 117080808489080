import {
  FocusMonitor,
  FocusTrapFactory,
  InteractivityChecker,
} from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  // ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  NgZone,
  OnDestroy,
  Optional,
  // ViewEncapsulation,
  ANIMATION_MODULE_TYPE,
} from '@angular/core';
import { CdkDialogContainer, DialogRef } from '@angular/cdk/dialog';

import { ComponentPortal } from '@angular/cdk/portal';

import { DialogConfig } from '../dialog/dialog-config';

interface LegacyDialogAnimationEvent {
  state: 'opened' | 'opening' | 'closing' | 'closed';
  totalTime: number;
}

@Component({
  selector: 'sui-alert-container',
  templateUrl: './alert-container.component.html',
  styleUrl: './alert-container.component.scss',
})
export class AlertContainerComponent
  extends CdkDialogContainer<DialogConfig>
  implements OnDestroy
{
  /** Emits when an animation state changes. */
  _animationStateChanged = new EventEmitter<LegacyDialogAnimationEvent>();

  /** Whether animations are enabled. */
  _animationsEnabled: boolean = this._animationMode !== 'NoopAnimations';

  /** Number of actions projected in the dialog. */
  protected _actionSectionCount = 0;

  /** Duration of the dialog open animation. */
  private _enterAnimationDuration = 200;
  /** Duration of the dialog close animation. */
  private _exitAnimationDuration = 0;
  /** Current timer for dialog animations. */
  private _animationTimer: ReturnType<typeof setTimeout> | null = null;

  constructor(
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) _document: any,
    dialogConfig: DialogConfig,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    private _dialogRef: DialogRef,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) private _animationMode?: string,
    focusMonitor?: FocusMonitor
  ) {
    super(
      elementRef,
      focusTrapFactory,
      _document,
      dialogConfig,
      interactivityChecker,
      ngZone,
      overlayRef,
      focusMonitor
    );
  }

  protected override _contentAttached(): void {
    // Delegate to the original dialog-container initialization (i.e. saving the
    // previous element, setting up the focus trap and moving focus to the container).
    super._contentAttached();

    // Note: Usually we would be able to use the MDC dialog foundation here to handle
    // the dialog animation for us, but there are a few reasons why we just leverage
    // their styles and not use the runtime foundation code:
    //   1. Foundation does not allow us to disable animations.
    //   2. Foundation contains unnecessary features we don't need and aren't
    //      tree-shakeable. e.g. background scrim, keyboard event handlers for ESC button.
    //   3. Foundation uses unnecessary timers for animations to work around limitations
    //      in React's `setState` mechanism.
    //      https://github.com/material-components/material-components-web/pull/3682.
    this._startOpenAnimation();
  }

  /** Starts the dialog open animation if enabled. */
  private _startOpenAnimation(): void {
    this._animationStateChanged.emit({
      state: 'opening',
      totalTime: this._enterAnimationDuration,
    });

    // this._hostElement.style.setProperty(
    //   TRANSITION_DURATION_PROPERTY,
    //   `${this._enterAnimationDuration}ms`
    // );

    // We need to give the `setProperty` call from above some time to be applied.
    // One would expect that the open class is added once the animation finished, but MDC
    // uses the open class in combination with the opening class to start the animation.
    // this._requestAnimationFrame(() =>
    //   this._hostElement.classList.add(OPENING_CLASS, OPEN_CLASS)
    // );
    this._waitForAnimationToComplete(
      this._enterAnimationDuration,
      this._finishDialogOpen
    );
  }

  /**
   * Starts the exit animation of the dialog if enabled. This method is
   * called by the dialog ref.
   */
  _startExitAnimation(): void {
    this._animationStateChanged.emit({
      state: 'closing',
      totalTime: this._exitAnimationDuration,
    });
    // this._hostElement.classList.remove(OPEN_CLASS);

    if (this._animationsEnabled) {
      // this._hostElement.style.setProperty(
      //   TRANSITION_DURATION_PROPERTY,
      //   `${this._exitAnimationDuration}ms`
      // );

      // We need to give the `setProperty` call from above some time to be applied.
      // this._requestAnimationFrame(() =>
      //   this._hostElement.classList.add(CLOSING_CLASS)
      // );
      this._waitForAnimationToComplete(
        this._exitAnimationDuration,
        this._finishDialogClose
      );
    } else {
      // This subscription to the `OverlayRef#backdropClick` observable in the `DialogRef` is
      // set up before any user can subscribe to the backdrop click. The subscription triggers
      // the dialog close and this method synchronously. If we'd synchronously emit the `CLOSED`
      // animation state event if animations are disabled, the overlay would be disposed
      // immediately and all other subscriptions to `DialogRef#backdropClick` would be silently
      // skipped. We work around this by waiting with the dialog close until the next tick when
      // all subscriptions have been fired as expected. This is not an ideal solution, but
      // there doesn't seem to be any other good way. Alternatives that have been considered:
      //   1. Deferring `DialogRef.close`. This could be a breaking change due to a new microtask.
      //      Also this issue is specific to the MDC implementation where the dialog could
      //      technically be closed synchronously. In the non-MDC one, Angular animations are used
      //      and closing always takes at least a tick.
      //   2. Ensuring that user subscriptions to `backdropClick`, `keydownEvents` in the dialog
      //      ref are first. This would solve the issue, but has the risk of memory leaks and also
      //      doesn't solve the case where consumers call `DialogRef.close` in their subscriptions.
      // Based on the fact that this is specific to the MDC-based implementation of the dialog
      // animations, the defer is applied here.
      Promise.resolve().then(() => this._finishDialogClose());
    }
  }

  // /**
  //  * Updates the number action sections.
  //  * @param delta Increase/decrease in the number of sections.
  //  */
  // _updateActionSectionCount(delta: number): void {
  //   this._actionSectionCount += delta;
  //   this._changeDetectorRef.markForCheck();
  // }

  /**
   * Completes the dialog open by clearing potential animation classes, trapping
   * focus and emitting an opened event.
   */
  private _finishDialogOpen = (): void => {
    this._clearAnimationClasses();
    this._openAnimationDone(this._enterAnimationDuration);
  };

  /**
   * Completes the dialog close by clearing potential animation classes, restoring
   * focus and emitting a closed event.
   */
  private _finishDialogClose = (): void => {
    this._clearAnimationClasses();
    this._animationStateChanged.emit({
      state: 'closed',
      totalTime: this._exitAnimationDuration,
    });
  };

  /** Clears all dialog animation classes. */
  private _clearAnimationClasses(): void {
    // this._hostElement.classList.remove(OPENING_CLASS, CLOSING_CLASS);
  }

  private _waitForAnimationToComplete(
    duration: number,
    callback: () => void
  ): void {
    if (this._animationTimer !== null) {
      clearTimeout(this._animationTimer);
    }

    // Note that we want this timer to run inside the NgZone, because we want
    // the related events like `afterClosed` to be inside the zone as well.
    this._animationTimer = setTimeout(callback, duration);
  }

  /** Runs a callback in `requestAnimationFrame`, if available. */
  private _requestAnimationFrame(callback: () => void): void {
    this._ngZone.runOutsideAngular(() => {
      if (typeof requestAnimationFrame === 'function') {
        requestAnimationFrame(callback);
      } else {
        callback();
      }
    });
  }

  protected override _captureInitialFocus(): void {
    //
  }

  /**
   * Callback for when the open dialog animation has finished. Intended to
   * be called by sub-classes that use different animation implementations.
   */
  protected _openAnimationDone(totalTime: number): void {
    this._animationStateChanged.next({ state: 'opened', totalTime });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this._animationTimer !== null) {
      clearTimeout(this._animationTimer);
    }
  }

  override attachComponentPortal<T>(
    portal: ComponentPortal<T>
  ): ComponentRef<T> {
    // When a component is passed into the dialog, the host element interrupts
    // the `display:flex` from affecting the dialog title, content, and
    // actions. To fix this, we make the component host `display: contents` by
    // marking its host with the `mat-mdc-dialog-component-host` class.
    //
    // Note that this problem does not exist when a template ref is used since
    // the title, contents, and actions are then nested directly under the
    // dialog surface.
    const ref = super.attachComponentPortal(portal);
    // ref.location.nativeElement.classList.add('mat-mdc-dialog-component-host');
    return ref;
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close(false);
  }
}
