import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsAPIService {
  constructor(private _http: HttpClient) {}

  getNewCount(): Observable<{ count: number }> {
    return this._http.get<{ count: number }>(`/api/v1/notifications/new`, {
      withCredentials: true,
    });
  }
}
