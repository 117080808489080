<div class="message">
  <svg
    *ngIf="data.type === 'warning'"
    class="mark"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.9987" cy="9.9987" r="6.16667" stroke="black" />
    <path
      d="M10.5746 6L10.4978 11.6986H9.5773L9.50059 6H10.5746ZM9.3362 13.2877C9.32524 12.9041 9.65401 12.5863 10.0376 12.5863C10.4266 12.5863 10.7444 12.9041 10.7389 13.2877C10.7444 13.6767 10.4266 13.9945 10.0376 14C9.65401 13.9945 9.32524 13.6767 9.3362 13.2877Z"
      fill="black"
    />
  </svg>
  <p>
    {{ data.message }}
    <button
      *ngFor="let action of data.actions"
      class="action"
      (click)="_onActionButtonClick(action)"
    >
      {{ action }}
    </button>
  </p>
</div>
<button class="close-button" (click)="_onCloseButtonClick()">
  <svg
    width="28"
    height="30"
    viewBox="0 0 28 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-1.90735e-06 4.32663V22.8672C7.63636 24.1258 15.2727 25.3845 22.9091 26.6431C25.7021 27.1029 27.4001 28.2219 28 30V3.90761C20.3129 2.63955 12.6241 1.37307 4.93698 0.105016C1.71072 -0.427002 -1.90735e-06 1.07489 -1.90735e-06 4.32663Z"
      fill="#282828"
    />
    <path
      d="M18.3333 8.96571L17.3676 8L13.6695 11.7038L9.96571 8L9 8.96571L12.7038 12.6695L9 16.3676L9.96571 17.3333L13.6695 13.6295L17.3676 17.3333L18.3333 16.3676L14.6295 12.6695L18.3333 8.96571Z"
      fill="white"
    />
  </svg>
</button>
