import { Directive, Input } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';

@Directive({
  selector: '[viewerCustomMatMenuTriggerFor]',
  exportAs: 'viewerCustomMatMenuTrigger',
})
export class CustomMatMenuTriggerDirective extends MatMenuTrigger {
  @Input() customMatMenuDisabled = false;

  override openMenu(): void {
    if (this.customMatMenuDisabled) {
      return;
    }

    super.openMenu();
  }

  @Input('viewerCustomMatMenuTriggerFor')
  override get menu(): MatMenuPanel | null {
    return super.menu;
  }
  override set menu(menu: MatMenuPanel) {
    super.menu = menu;
  }
}
