import { Component, OnInit } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DialogService } from 'shared/ui';
import { NotesDialogComponent, NotificationDialogComponent } from 'user';
import { NotesAPIService, NotificationsAPIService } from 'shared/services';

const EXACT_ROUTER_MATCH_OPTION: IsActiveMatchOptions = {
  paths: 'exact',
  queryParams: 'ignored',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  public _newNotificationAlertCount = 0;
  public _newMessageAlertCount = 0;

  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _notesAPIService: NotesAPIService,
    private _notificationsAPIService: NotificationsAPIService
  ) {}

  ngOnInit(): void {
    this._notificationsAPIService.getNewCount().subscribe((result) => {
      this._newNotificationAlertCount = result.count;
    });

    this._notesAPIService.getNewCount().subscribe((result) => {
      this._newMessageAlertCount = result.count;
    });
  }

  get _isBookshelfPageActive(): boolean {
    return (
      this._router.isActive('/bookshelf', EXACT_ROUTER_MATCH_OPTION) ||
      this._router.isActive('/bookshelf/detail', EXACT_ROUTER_MATCH_OPTION)
    );
  }

  public _onClickNotification(): void {
    this._newNotificationAlertCount = 0;

    this._dialogService.open(NotificationDialogComponent, {
      data: {
        isApp: true,
        serviceOrigin: environment.serviceOrigin,
      },
    });
  }

  public _onClickMessage(): void {
    this._newMessageAlertCount = 0;

    this._dialogService.open(NotesDialogComponent, {
      responsiveBreakpoint: 768,
    });
  }

  _onClickPurchaseButton(): void {
    window.open(environment.serviceOrigin, '_blank');
  }
}
