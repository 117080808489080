<section>
  <header>
    <div class="inner-left">
      <sui-icon name="원형 이동_왼쪽" (click)="_onClickBackButton()"></sui-icon>
      <h2>책장 이동하기</h2>
    </div>
    <sui-icon name="엑스" (click)="_closeDialog()"></sui-icon>
  </header>
  <main>
    <span class="title">나의 책장 목록</span>
    <div class="bookshelf-list" #listContainer>
      <div class="empty" *ngIf="_bookshelfList?.length === 0">
        <p class="content">이동 가능한 책장이 없습니다.</p>
      </div>
      <div class="bookshelf-list-container">
        <article
          *ngFor="let bookshelf of _bookshelfList; index as i"
          [class.selected]="_selectedBookshelf === bookshelf.id"
          (click)="_onSelectedBookshelf(bookshelf.id)"
        >
          <div class="bookshelf-title">
            <sui-icon
              type="solid"
              name="잠금"
              *ngIf="bookshelf.is_private === 1"
            ></sui-icon>
            <h4>
              <span class="title">
                {{ bookshelf?.title }}
              </span>
              <span class="total"> ({{ bookshelf?.content_count }})</span>
            </h4>
          </div>
          <div class="content">
            <div
              class="content-inner"
              [class.empty]="bookshelf.content_count === 0"
            >
              <ul
                class="book-list"
                [class.empty]="bookshelf.content_count === 0"
              >
                <li *ngFor="let book of bookshelf.covers | slice : 0 : 6">
                  <img src="{{ book }}" alt="" />
                </li>
              </ul>
              <p *ngIf="!bookshelf.covers" class="placeholder">
                책장에 담긴 책이 없습니다.
              </p>
            </div>
          </div>
        </article>
      </div>
      <sui-paginator
        *ngIf="_bookshelfList && _bookshelfList.length !== 0"
        [page]="_currentPage"
        [totalItemCount]="_total"
        [itemCountPerPage]="_itemCountPerPage"
        (pageChange)="_onPageChange($event)"
      ></sui-paginator>
    </div>
  </main>
  <div class="button-container">
    <button
      sui-solid-button
      varSize="large"
      [disabled]="_selectedBookshelf === ''"
      (click)="_onSaveButton()"
    >
      저장하기
    </button>
  </div>
  <div class="loader-container" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</section>
