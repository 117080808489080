<div class="controls">
  <button
    class="zoom-button"
    sui-icon-button
    (click)="zoomButtonClick(-1)"
    vtid="zoom"
  >
    <sui-icon name="마이너스"></sui-icon>
  </button>
  <button class="zoom-value" (click)="resetZoomButtonClick()">
    {{ currentScale }}%
  </button>
  <button
    class="zoom-button"
    sui-icon-button
    (click)="zoomButtonClick(1)"
    vtid="zoom"
  >
    <sui-icon name="플러스"></sui-icon>
  </button>
</div>
