import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import domtoimage from 'dom-to-image';
import { Memo } from 'shared/models';

export type MemoSharingImageRatio = '1:1' | '4:5' | '9:16';

const HEIGHT_MULTIPLIER: Record<MemoSharingImageRatio, number> = {
  '1:1': 1,
  '4:5': 1.25,
  '9:16': 1.77,
};

const DEFAULT_AVATAR_IMAGE = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR5SURBVHgB7Zu9b9NQFMWPQ7+ggMICI6mYoekIC+kAa2FHIkxILC0SYm1hREK0f0GLxE6ZO5AJxgZ2qDvCAAHRgqDU3PPyHCWpk9jOe04c85NSx46T+p537/u8z4FldjwU5FDKAbMe1Hu+8vrYjCuvGo9y3zs5VvmacdR1aziwgBhNgxfEkBs4amhUXHnIyiHwXMSowDDGBBCj82L0ohi9hHoJ24De8UiOFVOe0bcACRneTk0efEO8Yq1fIfoSYNdThq8gOcPbUR4hImwgJrEEYMUmX1yXtyUMBxRiPo435BARlroYv43hMZ6wQLalYJYifi+aB4jxz3SsDy1i0Mp5R1WUYe8Phxi/LsaXkQJYQYoId0Le2xsJMLp8EemiKnEx1+umnnUASx7pM54U9bN3pasA8gPLaXH7IPjsrLe63dMxBHQbv4oRQOy4L01koC2BAuh2nnE/qA6OaWoiwlxQPyFQAKn0dtD/IKYjDqYxhes4hrPq/C8+4zfeyPETLFKRUp3HkWdpQ0q/rHt5xqHhp/EAk7gc+PlPbGEfL6wJERQKLQJo138NC6V/DOcknp6oYzdofA0PbYnAUJgREWr+hZZWgKM6WHL9MMYTXyh6i5XHaOvJNgRg6dtq8qZwLZTxPrz3BG7CBuLhixzC++fNHlCCpVr/uJoYigZFs0SLFzQEEGWWYQEHJzGOC4gKvSCK10TBqYe6QgnAOTxYiv2cnVjul7y2uS6A/LmN7KHiUgngWZzcYHPmYQ9R4fdsdowkDBZ4zOl5+wIsso+XiMofvIdlCrSdHmB9qLuPzUhewJLfkx5hApQSEcDDD3yV3l0YEXjPNzy2PS7wKeYkFmaRAAf4gC+419UwfkaheG9CFJxBTHdN4gomcBFjun9Ag3/hbRJx307VsT30HXJcCuAhw4zBIKdwV3V9TXEolecBPkp4bMEWRgWYkNi20X+fxi18x1MrdUTkpbFBQFHPyBzBOC7BNBTARUrIy4DV8ESJSwFqSAk03vBESS1VHkBMhoE0f7s5/kGKMFzJVukBVWQXJUAF2aWa08tFLrKHS9v9GaFXyBiO9ny/I7SJjMHESx6VADoDMzX9AQO4ftZpoyssYbCGjKCzTRXNYwGummbBC2hjxT9pjAa5YiqzpGv9rBBx8tP2QkicKfbW76uka9c/b18ez8sFzhCNSmZIO0cySluGw/SC5vgYNXResdt8rVOKDJMkShgt3IKDmfaLgRMiohSzLEepQqRnzwd9ECgA3WSUQiHI9X06TokxmWgU+gba+I75jj1zhaVl2HBSunyum7xyt3v+J0sjBPwhTw8e0gCfNYzxJPS0OF0pDRWjV99IVQ57f6R1AfnhFWZbYjibyJrOBI20oyXSlhkfmxmlMamw75LIpinCf8Rele4wuRgcqtSZBB13/2AsD2hG5xgt6aYysY2Tuo+y2pz3G4e+BfDxN0nr4XQBdjBmuI8xAZrRSYhMu78KA5un9aTt5lBvnu6E9gx2ooo6H4nnvbbPc7WKCzbGNkl34h8nMWO4SEfFDgAAAABJRU5ErkJggg==`;
const IMAGE_CACHE_BUSTING_QUERY = '4233164377657568'; // random number

@Component({
  selector: 'viewer-memo-sharing-image',
  templateUrl: './memo-sharing-image.component.html',
  styleUrl: './memo-sharing-image.component.scss',
})
export class MemoSharingImageComponent implements AfterViewInit, OnInit {
  @Input() memo!: {
    text: Memo['text'];
    content: Memo['content'];
    like_count: Memo['like_count'];
    copy_count: Memo['copy_count'];
  };
  @Input() author!: Omit<Memo['author'], 'id'>;
  @Input() bookTitle!: string;
  @Input() coverImageURL!: string;

  @Input() hideCounts = false;
  @Input() readingMode: 'single' | 'group' = 'single';

  private readonly _imageWidth = 1080;
  private get _imageHeight(): number {
    return this._imageWidth * HEIGHT_MULTIPLIER[this.ratio];
  }
  private readonly _coverImageWidth = 155;

  private _coverImage?: HTMLImageElement;

  private _ratio: MemoSharingImageRatio = '1:1';

  @ViewChild('preview', { static: true })
  private _previewElem!: ElementRef<HTMLDivElement>;
  @ViewChild('image', { static: true })
  private _imageElem!: ElementRef<HTMLDivElement>;
  @ViewChild('bgCanvas', { static: true })
  private _bgCanvasElem!: ElementRef<HTMLCanvasElement>;
  @ViewChild('coverCanvas', { static: true })
  private _coverCanvasElem!: ElementRef<HTMLCanvasElement>;
  @ViewChild('avatarCanvas', { static: true })
  private _avatarCanvasElem!: ElementRef<HTMLCanvasElement>;

  constructor(private _renderer: Renderer2) {}

  @Input() set ratio(value: MemoSharingImageRatio) {
    this._ratio = value;
    this._updateBackgroundImage();
  }

  get ratio(): MemoSharingImageRatio {
    return this._ratio;
  }

  ngOnInit(): void {
    this._loadCoverImage();
    this._loadAvatarImage();
  }

  private _loadCoverImage(): void {
    const image = new Image();
    image.crossOrigin = 'annonymous';
    image.onload = (): void => {
      this._coverImage = image;
      this._updateBackgroundImage();
      this._updateCoverImage();
      image.onload = null;
    };

    const imageURL = new URL(this.coverImageURL);
    imageURL.searchParams.append(IMAGE_CACHE_BUSTING_QUERY, '');
    image.src = imageURL.toString();
  }

  private _loadAvatarImage(): void {
    const image = new Image();
    image.crossOrigin = 'annonymous';
    image.onload = (): void => {
      this._setAvatarImage(image);
      image.onload = null;
    };

    const imageURL = new URL(
      this.author.avatar_image_url || DEFAULT_AVATAR_IMAGE
    );
    imageURL.searchParams.append(IMAGE_CACHE_BUSTING_QUERY, '');
    image.src = imageURL.toString();
  }

  private _setAvatarImage(image: HTMLImageElement): void {
    const ctx = this._avatarCanvasElem.nativeElement.getContext('2d');

    if (!ctx) {
      return;
    }

    const imgWidth = image.naturalWidth;
    const imgHeight = image.naturalHeight;

    const dpr = 2;

    const canvasWidth = this._avatarCanvasElem.nativeElement.offsetWidth;
    const canvasHeight = this._avatarCanvasElem.nativeElement.offsetHeight;

    this._avatarCanvasElem.nativeElement.width = canvasWidth * dpr;
    this._avatarCanvasElem.nativeElement.height = canvasHeight * dpr;

    const imgRatio = imgWidth / imgHeight;
    const canvasRatio = canvasWidth / canvasHeight;

    let drawWidth, drawHeight;

    if (canvasRatio > imgRatio) {
      // 캔버스가 이미지보다 더 넓은 경우
      drawWidth = canvasWidth;
      drawHeight = canvasWidth / imgRatio;
    } else {
      // 캔버스가 이미지보다 더 높은 경우
      drawWidth = canvasHeight * imgRatio;
      drawHeight = canvasHeight;
    }

    // 이미지를 중앙에 배치하기 위한 오프셋 계산
    const offsetX = (canvasWidth - drawWidth) / 2;
    const offsetY = (canvasHeight - drawHeight) / 2;

    ctx.scale(dpr, dpr);
    ctx.drawImage(image, offsetX, offsetY, drawWidth, drawHeight);
  }

  ngAfterViewInit(): void {
    this._updateImageScale();

    const resizeObserver = new ResizeObserver(() => {
      this._updateImageScale();
    });
    resizeObserver.observe(this._previewElem.nativeElement);
  }

  private _updateImageScale(): void {
    const width = this._previewElem.nativeElement.offsetWidth;
    const scale = width / this._imageWidth;
    this._renderer.setStyle(
      this._imageElem.nativeElement,
      'transform',
      `scale(${scale})`
    );
  }

  toBlob(type?: string, quality?: number): Promise<Blob> {
    return domtoimage
      .toCanvas(this._imageElem.nativeElement, {
        width: this._imageElem.nativeElement.clientWidth,
        height: this._imageElem.nativeElement.clientHeight,
        style: {
          transform: 'none',
        },
        bgcolor: 'white',
      })
      .then((canvas) => {
        return new Promise((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('toBlob failed'));
              }
            },
            type,
            quality
          );
        });
      });
  }

  private _updateBackgroundImage(): void {
    if (!this._coverImage) {
      return;
    }

    const ctx = this._bgCanvasElem.nativeElement.getContext('2d');

    if (!ctx) {
      return;
    }

    const canvasWidth = 100; // 사파리 브라우저에서 이미지가 큰 경우 캡쳐가 안되는 이슈가 있어 작은 작게 그린 후 css scale 활용하여 맞춤
    const canvasHeight =
      (canvasWidth * this._coverImage.height) / this._coverImage.width;

    this._bgCanvasElem.nativeElement.width = canvasWidth;
    this._bgCanvasElem.nativeElement.height = canvasHeight;

    ctx.drawImage(this._coverImage, 0, 0, canvasWidth, canvasHeight);
    ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    const canvasRatio = canvasWidth / canvasHeight;
    const previewRatio = this._imageWidth / this._imageHeight;

    let scale;

    if (canvasRatio > previewRatio) {
      // 이미지가 프리뷰보다 가로로 더 길다 => 이미지 세로를 프리뷰에 맞춤
      scale = this._imageHeight / canvasHeight;
    } else {
      // 프리뷰가 이미지보다 가로로 더 길다 => 이미지 가로를 프리뷰에 맞춤
      scale = this._imageWidth / canvasWidth;
    }

    scale = Math.round(scale * 100) / 100 + 0.5; // 딱 맞게 하면 블러 시 흰섹 테두리 생겨서 0.5 더해줌

    this._bgCanvasElem.nativeElement.style.transform = `translate(-50%, -50%) scale(${scale})`;
  }

  private _updateCoverImage(): void {
    if (!this._coverImage) {
      return;
    }

    const ctx = this._coverCanvasElem.nativeElement.getContext('2d');

    if (!ctx) {
      return;
    }

    const dpr = 1;

    const canvasWidth = this._coverImageWidth;
    const canvasHeight =
      (canvasWidth * this._coverImage.height) / this._coverImage.width;

    this._coverCanvasElem.nativeElement.style.width = `${canvasWidth}px`;
    this._coverCanvasElem.nativeElement.style.height = `${canvasHeight}px`;

    this._coverCanvasElem.nativeElement.width = canvasWidth * dpr;
    this._coverCanvasElem.nativeElement.height = canvasHeight * dpr;

    ctx.scale(dpr, dpr);
    ctx.drawImage(this._coverImage, 0, 0, canvasWidth, canvasHeight);
  }
}
