import { Injectable, OnDestroy } from '@angular/core';
import {
  Subject,
  filter,
  map,
  pairwise,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { SocketService } from 'shared/services';
import { AnnotationsV2Service } from './annotations-v2.service';

@Injectable()
export class OnlineReadingMemberService implements OnDestroy {
  private _unsubscriber = new Subject<void>();

  private _onlineUserIds: string[] = [];

  private _onlineStatusChange$ = new Subject<{
    userId: string;
    isOnline: boolean;
  }>();
  public onlineStatusChange$ = this._onlineStatusChange$.asObservable();

  constructor(
    private _socketService: SocketService,
    private _annotationsService: AnnotationsV2Service
  ) {
    this._socketService
      .message()
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        if (data.cmd === 'exit') {
          this._onUserExited(data.user_id);
        } else if (data.cmd === 'enter') {
          this._onUserEntered(data.user_id);
        }
      });

    this._annotationsService.state$
      .pipe(
        takeUntil(this._unsubscriber),
        map((state) => state.groupId),
        startWith(null),
        pairwise(),
        filter(([g1, g2]) => g1 !== g2)
      )
      .subscribe(([g1, g2]) => {
        if (g1 != null) {
          console.warn('exit group', g1);
          this._socketService
            .getSocket()
            .emit('message', JSON.stringify({ cmd: 'exit', group_id: g1 }));
        }

        if (g2 != null) {
          this._onlineUserIds = [];

          console.warn('enter group', g2);
          this._socketService
            .getSocket()
            .emit('message', JSON.stringify({ cmd: 'enter', group_id: g2 }));
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();

    const state = this._annotationsService.getState();
    if (state.groupId != null) {
      console.warn('exit');

      this._socketService
        .getSocket()
        .emit(
          'message',
          JSON.stringify({ cmd: 'exit', group_id: state.groupId })
        );
    }
  }

  isOnline(userId: string): boolean {
    return this._onlineUserIds.indexOf(userId) !== -1;
  }

  private _onUserExited(userId: string): void {
    const index = this._onlineUserIds.indexOf(userId);

    if (index === -1) {
      return;
    }

    this._onlineUserIds.splice(index, 1);
    this._onlineStatusChange$.next({ userId, isOnline: false });
  }

  private _onUserEntered(userId: string): void {
    const index = this._onlineUserIds.indexOf(userId);

    if (index !== -1) {
      return;
    }

    this._onlineUserIds.push(userId);
    this._onlineStatusChange$.next({ userId, isOnline: true });
  }
}
