import { Component, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[suiFormFieldSuffix]',
})
export class FormFieldSuffixDirective {}

@Component({
  selector: 'sui-form-field-hint',
  template: `<ng-content></ng-content
    ><sui-icon *ngIf="type === 'valid'" name="체크"></sui-icon>`,
})
export class FormFieldHintComponent {
  @Input() type: 'normal' | 'error' | 'valid' = 'normal';
  @HostBinding('class') get className(): string {
    return `sui-form-field-hint-type-${this.type}`;
  }
}

@Component({
  selector: 'sui-form-field',
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.scss',
})
export class FormFieldComponent {
  @Input() label?: string;
  @Input() appearance: 'underline' | 'outline' | 'fill' = 'outline';
}
