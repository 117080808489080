import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelvesList } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-move-bookshelf',
  templateUrl: './move-bookshelf.component.html',
  styleUrl: './move-bookshelf.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MoveBookshelfComponent
  extends TypedDialog<void, void>
  implements OnInit, OnDestroy
{
  @Output() _backButtonClick = new EventEmitter<void>();
  @Output() bookMoved = new EventEmitter<void>();

  @Input() _checkedBookList: string[] = [];
  @Input() _bookshelf_id_now: string = '';

  @ViewChild('listContainer') private listContainer?: ElementRef;

  private _bookshelfListSubject = new Subject<{ page: number }>();

  public _bookshelfList?: BookshelvesList['items'];
  public _selectedBookshelf: string = '';

  public _total: number = 0;
  public _currentPage: number = 0;
  public _itemCountPerPage: number = 30;
  public _isLoading: boolean = true;

  constructor(
    private _toastService: ToastService,
    private _bookshelfAPIService: BookshelfAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._bookshelfListSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._bookshelfAPIService.getBookshelvesList(
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result: BookshelvesList) => {
        this._isLoading = false;
        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }

        this._total = result.total;

        this._bookshelfList = result.items.filter((item) => {
          return item.id !== this._bookshelf_id_now;
        });
      });

    this._bookshelfListSubject.next({ page: 0 });
  }

  ngOnDestroy(): void {
    this._bookshelfListSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close();
  }

  public _onClickBackButton(): void {
    this._backButtonClick.emit();
  }

  public _onSelectedBookshelf(bookshelf_id: string): void {
    if (this._selectedBookshelf === bookshelf_id) {
      this._selectedBookshelf = '';
    } else {
      this._selectedBookshelf = bookshelf_id;
    }
  }

  public _onSaveButton(): void {
    this._bookshelfAPIService
      .deleteBooks(this._bookshelf_id_now, this._checkedBookList)
      .pipe(
        switchMap(() => {
          return this._bookshelfAPIService.insertBooks(
            this._selectedBookshelf,
            this._checkedBookList
          );
        })
      )
      .subscribe({
        next: () => {
          this._toastService.open('책장 이동이 완료되었습니다.');
          this.bookMoved.emit();
        },
        error: (error: HttpErrorResponse) => {
          this._toastService.open(
            `오류가 발생하였습니다. 잠시 후 다시 시도해주세요. (${error.status})`
          );
        },
      });
  }

  public _onPageChange(page: number): void {
    this._bookshelfListSubject.next({ page });
  }
}
