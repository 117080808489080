import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'viewer-pen-opacity-slider',
  templateUrl: './pen-opacity-slider.component.html',
  styleUrl: './pen-opacity-slider.component.scss',
})
export class PenOpacitySliderComponent {
  @Input() opacity: number = 1;
  @Output() opacityChange = new EventEmitter<number>();
}
