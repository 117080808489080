import {
  ModuleWithProviders,
  NgModule,
  Provider,
  InjectionToken,
} from '@angular/core';
import {
  CommonModule,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenuModule } from '@angular/cdk/menu';

import { TranslateModule } from '@ngx-translate/core';
import {
  ViewerAPIService,
  ViewerContentsService,
  ViewerModule as BukViewerModule,
  PDFTextInfo,
  ViewerPDFOverlayViewProviderService,
} from '@bukio/viewer';

import { TooltipModule } from 'ng2-tooltip-directive';

import { SharedUIModule } from 'shared/ui';
import { UserModule } from 'user';

// main components
import { BukComponent } from './buk/buk.component';
import { UnsupportedBrowserRootComponent } from './components/unsupported-browser-root/unsupported-browser-root.component';

// components
import { PagerComponent } from './components/pager/pager.component';
import { SidebarContainerComponent } from './components/sidebar-container/sidebar-container.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNumberComponent } from './components/page-number/page-number.component';
import { GuidePopupComponent } from './components/guide-popup/guide-popup.component';
import { FullscreenLoaderComponent } from './components/fullscreen-loader/fullscreen-loader.component';
import { FixedlayoutPageControlComponent } from './components/fixedlayout-page-control/fixedlayout-page-control.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { PDFPageSliderComponent } from './components/pdf-page-slider/pdf-page-slider.component';
import { ContentsComponent } from './components/contents/contents.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { HighlightFilterToggleComponent } from './components/highlight-filter-toggle/highlight-filter-toggle.component';
import { PushableSideMenuComponent } from './components/pushable-side-menu/pushable-side-menu.component';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { ItemMemoListComponent } from './components/item-memo-list/item-memo-list.component';
import { MemoComponent } from './components/memo/memo.component';
import { MemoPopupComponent } from './components/memo-popup/memo-popup.component';
import { AnnotationSettingsFabComponent } from './components/annotation-settings-fab/annotation-settings-fab.component';
import { ReadingModeMemoComponent } from './components/reading-mode-memo-component/reading-mode-memo.component';
import { FocusedMemberPopupComponent } from './components/focused-member-popup/focused-member-popup.component';
import { TabComponent } from './components/tab/tab.component';
import { AnnotationsComponent } from './components/annotations/annotations.component';
import { HighlightListComponent } from './components/highlight-list/highlight-list.component';
import { MemoListComponent } from './components/memo-list/memo-list.component';
import { MenuComponent } from './components/menu/menu.component';
import { BookmarkListComponent } from './components/bookmark-list/bookmark-list.component';
import { DrawingToolBarComponent } from './components/drawing-tool-bar/drawing-tool-bar.component';
import { PenOpacitySliderComponent } from './components/pen-opacity-slider/pen-opacity-slider.component';
import { PenWidthSliderComponent } from './components/pen-width-slider/pen-width-slider.component';
import { IconCheckboxComponent } from './components/icon-checkbox/icon-checkbox.component';
import { ReadingGroupSummaryComponent } from './components/reading-group-summary/reading-group-summary.component';
import { ReadingGroupSelectComponent } from './components/reading-group-select/reading-group-select.component';
import { CommentarySelectComponent } from './components/commentary-select/commentary-select.component';
import { OverlappingCountSelectComponent } from './components/overlapping-count-select/overlapping-count-select.component';
import { StaticMemoPopupComponent } from './components/static-memo-popup/static-memo-popup.component';
import { CommentaryEditorMemoComponent } from './components/commentary-editor-memo/commentary-editor-memo.component';
import { DrawingListComponent } from './components/drawing-list/drawing-list.component';
import { PageBackButtonComponent } from './components/page-back-button/page-back-button.component';
import { MemoSharingImageComponent } from './components/memo-sharing-image/memo-sharing-image.component';
import { BukPageSliderComponent } from './components/buk-page-slider/buk-page-slider.component';
import { DocumentPageSliderComponent } from './components/document-page-slider/document-page-slider.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ReadingGroupMemoListComponent } from './components/reading-group-memo-list/reading-group-memo-list.component';

// services
import { LibContentsService } from './services/lib-contents.service';
import { LibAPIService } from './services/lib-api.service';

// dialogs
import { BookInfoDialogComponent } from './dialogs/book-info-dialog/book-info-dialog.component';
import { SettingsDialogComponent } from './dialogs/settings-dialog/settings-dialog.component';
import { ThumbnailDialogComponent } from './dialogs/thumbnail-dialog/thumbnail-dialog.component';
import { SearchDialogComponent } from './dialogs/search-dialog/search-dialog.component';
import { MemoDialogComponent } from './dialogs/memo-dialog/memo-dialog.component';
import { MemoCopyDialogComponent } from './dialogs/memo-copy-dialog/memo-copy-dialog.component';
import { MemoReportDialogComponent } from './dialogs/memo-report-dialog/memo-report-dialog.component';
import { MemoExportDialogComponent } from './dialogs/memo-export-dialog/memo-export-dialog.component';
import { ReadingModeDialogComponent } from './dialogs/reading-mode-dialog/reading-mode-dialog.component';
import { ReadingGroupMembersDialogComponent } from './dialogs/reading-group-members-dialog/reading-group-members-dialog.component';
import { UserDetailsDialogComponent } from './dialogs/user-details-dialog/user-details-dialog.component';
import { PreviewEndDialogComponent } from './dialogs/preview-end-dialog/preview-end-dialog.component';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';
import { CommentatorDetailsDialogComponent } from './dialogs/commentator-details-dialog/commentator-details-dialog.component';
import { CommentarySubmissionDialogComponent } from './dialogs/commentary-submission-dialog/commentary-submission-dialog.component';
import { MemoSharingDialogComponent } from './dialogs/memo-sharing-dialog/memo-sharing-dialog.component';
import { NewFeature1AlertComponent } from './dialogs/new-feature-1-alert/new-feature-1-alert.component';

//directives
import { VtidDirective } from './directives/vtid.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { CustomVirtualScrollDirective } from './directives/custom-virtual-scroll-strategy.directive';
import { CustomMatMenuTriggerDirective } from './directives/custom-mat-menu-trigger.directive';
import {
  MemoTagDirective,
  MemoActionsDirective,
  MemoAuthorComponent,
  MemoTypeComponent,
  MemoTopButtonDirective,
} from './components/memo/memo.component';

// pipes
import { FloorPipe } from './pipes/floor.pipe';
import { AbsPipe } from './pipes/abs.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { DetectLinkPipe } from './pipes/detect-link.pipe';
import { EscapeHtmlPipe } from './pipes/escape-html.pipe';

// misc
import { CallbackWorker } from './misc/callback-worker';
import { PDFOverlayViewProviderService } from './services/pdf-overlay-view-provider.service';

export interface ContentsDecryptionWorkers {
  pdfText: CallbackWorker<
    [text: ArrayBuffer, bid: string, rand: string],
    PDFTextInfo[]
  >;
  pdfImage: CallbackWorker<ArrayBuffer, Blob>;
  bukHTML: CallbackWorker<[html: ArrayBuffer, nonce: string], string>;
}

export interface ViewerModuleConfig {
  userService: Provider;
  analyticsService: Provider;
  authService: Provider;
  imageSharingService: Provider;
  pdfDrawingService?: Provider;
  pageTitleService?: Provider;
  contentsDecryptionWorkers: ContentsDecryptionWorkers;
  environment: ViewerModuleEnvironment;
}

export interface ViewerModuleEnvironment {
  production: boolean;
  serverOrigin: string;
  dashboardOrigin: string;
  isApp: boolean;
}

export const VIEWER_ENVIRONMENT = new InjectionToken('VIEWER_ENVIRONMENT');
export const VIEWER_CONTENTS_DECRYPTION_WORKERS = new InjectionToken(
  'VIEWER_CONTENTS_DECRYPTION_WORKERS'
);

@NgModule({
  imports: [
    /* angular modules */
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    /* external library modules */
    TranslateModule,
    /* buk.io app wide modules */
    UserModule,
    /* cdk modules */
    CdkMenuModule,
    A11yModule,
    /*  */
    BukViewerModule.forRoot({
      customAPIService: {
        provide: ViewerAPIService,
        useExisting: LibAPIService,
      },
      customContentsService: {
        provide: ViewerContentsService,
        useExisting: LibContentsService,
      },
      pdfOverlayViewProviderService: {
        provide: ViewerPDFOverlayViewProviderService,
        useExisting: PDFOverlayViewProviderService,
      },
      enableEpubObfuscation: false,
    }),
    ScrollingModule,
    DragDropModule,
    SharedUIModule,
    TooltipModule,
  ],
  declarations: [
    // main components
    BukComponent,
    UnsupportedBrowserRootComponent,
    // components
    PagerComponent,
    SidebarContainerComponent,
    LoaderComponent,
    HeaderComponent,
    PageNumberComponent,
    GuidePopupComponent,
    FullscreenLoaderComponent,
    FixedlayoutPageControlComponent,
    LanguageSelectorComponent,
    FooterComponent,
    PDFPageSliderComponent,
    ContentsComponent,
    ContextMenuComponent,
    HighlightFilterToggleComponent,
    PushableSideMenuComponent,
    RightPanelComponent,
    ItemMemoListComponent,
    MemoComponent,
    MemoPopupComponent,
    AnnotationSettingsFabComponent,
    ReadingModeMemoComponent,
    FocusedMemberPopupComponent,
    AnnotationsComponent,
    HighlightListComponent,
    TabComponent,
    MemoListComponent,
    MenuComponent,
    BookmarkListComponent,
    DrawingToolBarComponent,
    PenOpacitySliderComponent,
    PenWidthSliderComponent,
    IconCheckboxComponent,
    MemoAuthorComponent,
    MemoTypeComponent,
    ReadingGroupSummaryComponent,
    ReadingGroupSelectComponent,
    CommentarySelectComponent,
    OverlappingCountSelectComponent,
    StaticMemoPopupComponent,
    CommentaryEditorMemoComponent,
    DrawingListComponent,
    PageBackButtonComponent,
    MemoSharingImageComponent,
    BukPageSliderComponent,
    DocumentPageSliderComponent,
    ColorPickerComponent,
    ReadingGroupMemoListComponent,
    // dialogs
    SearchDialogComponent,
    BookInfoDialogComponent,
    SettingsDialogComponent,
    MemoDialogComponent,
    ThumbnailDialogComponent,
    MemoCopyDialogComponent,
    MemoReportDialogComponent,
    MemoExportDialogComponent,
    ReadingModeDialogComponent,
    ReadingGroupMembersDialogComponent,
    UserDetailsDialogComponent,
    PreviewEndDialogComponent,
    HelpDialogComponent,
    CommentatorDetailsDialogComponent,
    CommentarySubmissionDialogComponent,
    MemoSharingDialogComponent,
    NewFeature1AlertComponent,
    // directives
    VtidDirective,
    StopPropagationDirective,
    CustomVirtualScrollDirective,
    CustomMatMenuTriggerDirective,
    MemoTagDirective,
    MemoActionsDirective,
    MemoTopButtonDirective,
    // pipes
    SafePipe,
    AbsPipe,
    FloorPipe,
    DetectLinkPipe,
    EscapeHtmlPipe,
  ],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    LibAPIService,
    LibContentsService,
  ],
  exports: [BukComponent, UnsupportedBrowserRootComponent],
})
export class ViewerModule {
  static forRoot(
    config: ViewerModuleConfig
  ): ModuleWithProviders<ViewerModule> {
    const providers = [
      config.userService,
      config.analyticsService,
      config.authService,
      config.imageSharingService,
      {
        provide: VIEWER_CONTENTS_DECRYPTION_WORKERS,
        useValue: config.contentsDecryptionWorkers,
      },
      {
        provide: VIEWER_ENVIRONMENT,
        useValue: config.environment,
      },
    ];

    if (config.pageTitleService) {
      providers.push(config.pageTitleService);
    }

    if (config.pdfDrawingService) {
      providers.push(config.pdfDrawingService);
    }

    return {
      ngModule: ViewerModule,
      providers,
    };
  }
}
