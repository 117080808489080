<div class="list-header">
  <div class="left">
    <div class="title">
      이 책에 남긴 <span class="count">{{ _items.length }}</span
      >개의 북마크가 있습니다.
    </div>
  </div>
  <div class="right">
    <button
      class="select-all-button"
      [class.is-active]="
        _selectedItemCount > 0 && _selectedItemCount === _items.length
      "
      (click)="_onSelectAllButtonClick()"
    >
      전체 선택<sui-icon name="체크"></sui-icon>
    </button>
    <select
      sui-select
      [(ngModel)]="_selectedSortType"
      (ngModelChange)="_onSortTypeChange()"
    >
      <option *ngFor="let sortType of _sortTypes" [value]="sortType">
        {{ sortType }}
      </option>
    </select>
  </div>
</div>
<div
  class="list"
  [ngClass]="book.meta.type"
  [class.selection-mode]="_selectedItemCount > 0"
>
  <div class="list-item" *ngFor="let item of _items; index as i">
    <viewer-icon-checkbox class="checkbox"
      ><input
        type="checkbox"
        [(ngModel)]="item.selected"
        (ngModelChange)="_onItemSelectionChange()"
    /></viewer-icon-checkbox>
    <div
      class="box"
      [class.is-selected]="item.selected"
      (click)="_onItemClick(item)"
    >
      <img
        *ngIf="item.thumbnail"
        class="thumbnail"
        src="{{ item.thumbnail }}"
      />
      <div *ngIf="!item.thumbnail" class="text">{{ item.text }}</div>
      <div class="footer">
        <div class="date">
          {{ item.createdDate | date : 'y.MM.dd. HH:mm' }}
        </div>
        <button
          class="delete-button"
          sui-icon-button
          (click)="_onDeleteButtonClick(item); $event.stopPropagation()"
        >
          <sui-icon name="휴지통"></sui-icon>
        </button>
      </div>
    </div>
    <div class="page" *ngIf="item.page !== undefined">{{ item.page }} P</div>
  </div>
</div>
<div class="bottom-menu" [class.is-active]="_selectedItemCount > 0">
  <div class="selection-count">
    총 <span>{{ _selectedItemCount }}</span
    >개 선택
  </div>
  <div class="buttons">
    <button sui-solid-button (click)="_onDeleteSelectedItemsButtonClick()">
      삭제하기
    </button>
  </div>
</div>
