import { Pattern } from './pattern';

export const Url = Object.freeze({
  /* buk.io api */
  bukJson: (bid: string) => {
    if (Pattern.cardBid.test(bid)) {
      return `/${bid}/buk.json`;
    } else {
      return `/@${bid}/buk.json`;
    }
  },
  bookmark: '/bookmark',
  userLibrary: '/api/1.0/library',
  libraryItems: '/api/1.0/library/list',
  history: '/history',
  publisherFollow: '/api/1.0/imprint/follow',
  purchaseStat: '/stat/purchase',
  subscribePublishNoti: '/book/subscribe',
  unsubscribePublishNoti: '/book/unsubscribe',
  openhouseInfo: '/api/1.0/imprint/openhouse',
  bookRecommendation: '/api/1.0/book/recommend',
  bookLibrary: '/api/1.0/book/libraries',
  /* sns share popup url */
  shareTwitter: (url: string) =>
    `https://twitter.com/intent/tweet?url=${url}&text=%23buk`,
  shareFacebook: (url: string, text: string) =>
    `https://www.facebook.com/dialog/share?app_id=1122818604411565&display=popup&href=${url}&quote=${text}`,
});
