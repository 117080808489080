import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Highlight, Memo } from 'shared/models';
import { stripUndefinedParams } from 'shared/utils';
import { catchAndRethrowAPIError } from './api-error';

interface AnnotationsQuery {
  group_id?: number;
  member_id?: string;
  commentary_id?: number;
  order_by?: 'created_at' | 'position'; // default - created_at
  filter?: 'mine' | 'theirs' | 'all';
}

export interface ItemAnnotations {
  iid: string;
  highlights: Highlight[];
  memos: Memo[];
}

const GET_ANNOTATIONS_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
};

@Injectable({
  providedIn: 'root',
})
export class AnnotationsAPIService {
  constructor(private _http: HttpClient) {}

  get(
    bid: string,
    iids: string[],
    query?: AnnotationsQuery
  ): Observable<{ items: ItemAnnotations[]; timestamp: number }> {
    return this._http
      .get<{ items: ItemAnnotations[]; timestamp: number }>(
        `/api/v1/annotations`,
        {
          params: stripUndefinedParams({
            bid,
            iid: iids.length === 0 ? undefined : iids.join(','),
            ...query,
          }),
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(GET_ANNOTATIONS_ERROR_MSG_MAP));
  }

  getUpdatesOfGroup(
    group_id: number,
    iid: string[],
    baseTimestamp: number
  ): Observable<{
    items: { iid: string; highlights: number; memos: number }[];
    timestamp: number;
  }> {
    return this._http.get<{
      items: { iid: string; highlights: number; memos: number }[];
      timestamp: number;
    }>(`/api/v1/annotations/update`, {
      params: {
        group_id,
        iid: iid.join(','),
        timestamp: baseTimestamp,
      },
      withCredentials: true,
    });
  }
}
