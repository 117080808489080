<section *ngIf="_isPageLocation === 'edit'">
  <sui-dialog-header (click)="_closeDialog()">
    책 목록 편집하기
  </sui-dialog-header>
  <main>
    <div class="book-count">
      <b>{{ _checkedBookList.length }}</b> / {{ _total }}개
    </div>
    <div class="book-list" #listContainer>
      <div class="empty" *ngIf="_bookList?.length === 0">
        <p class="content">추가 가능한 책이 없습니다.</p>
      </div>
      <ul>
        <li *ngFor="let book of _bookList" (click)="_onClickBookItem(book.bid)">
          <div
            class="img-container"
            [class.selected]="_checkedBookList.includes(book.bid)"
          >
            <img src="{{ book.cover }}" alt="" />
            <div class="title-mobile">{{ book.title }}</div>
            <div class="author-mobile">{{ book.author }}</div>
            <div
              class="checkIcon"
              [class.selected]="_checkedBookList.includes(book.bid)"
            >
              <sui-icon name="체크"></sui-icon>
            </div>
          </div>
          <div class="title">{{ book.title }}</div>
          <div class="author">{{ book.author }}</div>
        </li>
      </ul>
      <sui-paginator
        *ngIf="_bookList && _bookList.length !== 0"
        [page]="_currentPage"
        [totalItemCount]="_total"
        [itemCountPerPage]="_itemCountPerPage"
        (pageChange)="_onPageChange($event)"
      ></sui-paginator>
    </div>
  </main>

  <div class="loader-container" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
  <div class="buttons">
    <button
      (click)="_changePageLocation('move')"
      sui-solid-button
      varSize="medium40:medium54"
      [disabled]="_checkedBookList.length === 0"
    >
      책장 이동하기
    </button>
    <button
      sui-solid-button
      varSize="medium40:medium54"
      [disabled]="_checkedBookList.length === 0"
      (click)="_onClickDeleteBookItem()"
    >
      책장에서 제거하기
    </button>
  </div>
</section>
<lib-move-bookshelf
  (_backButtonClick)="_changePageLocation('edit')"
  (bookMoved)="_onBookMoved()"
  [_checkedBookList]="_checkedBookList"
  [_bookshelf_id_now]="_data.bookshelf_id"
  *ngIf="_isPageLocation === 'move'"
></lib-move-bookshelf>
