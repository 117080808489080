<p>
  북이오 앱이 새로운 버전으로 업데이트 되었어요!<br />
  더 나은 서비스 이용 환경을 위해 최신 버전의 앱으로 업데이트해 주세요.
</p>
<div class="actions">
  <button
    class="button"
    sui-solid-button
    varSize="large"
    (click)="_onUpdateButtonClick()"
  >
    업데이트하러 가기
  </button>
</div>
