import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Cookie } from 'ng2-cookies';

import { RawUser, User } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user = new BehaviorSubject<User | null | undefined>(undefined);
  public readonly user = this._user
    .asObservable()
    .pipe(filter((user) => user !== undefined)) as Observable<User | null>;

  constructor(private http: HttpClient) {
    this.fetchUser(); // initialize user
  }

  fetchUser(): void {
    this.http
      .get<RawUser>('/user/profile', { withCredentials: true })
      .subscribe(
        (rawUser) => {
          this._user.next(new User(rawUser));
          Cookie.set('provider', rawUser.provider, undefined, '/', 'buk.io');
        },
        (error) => {
          this._user.next(null);
        }
      );
  }

  getUser(): User | null | undefined {
    return this._user.value;
  }
}
