import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';
import { Address, BookItem, BookType } from '@bukio/viewer';
import { Book } from '../../models/book.model';

interface PageHistory {
  url: string;
  displayNumber?: number;
}

interface PageInfo {
  url: string;
  iid: string;
  page: number;
  pageCount: number;
}

@Component({
  selector: 'viewer-page-back-button',
  templateUrl: './page-back-button.component.html',
  styleUrl: './page-back-button.component.scss',
})
export class PageBackButtonComponent implements OnInit, OnDestroy {
  private _unsubscriber = new Subject<void>();

  private _histories: PageHistory[] = [];
  private _isMultiColumnViewer = false;

  private _book?: Book;
  private _isFixedLayoutBook = false;
  private _prevPage?: PageInfo;

  constructor(private _eventBusService: EventBusService) {}

  ngOnInit(): void {
    this._eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        // const pagingMode =
        //   data.settings.pagingMode.value === PagingMode.Scroll
        //     ? 'scroll'
        //     : 'page';
        // if (this.pagingMode !== pagingMode) {
        //   this.pagingMode = pagingMode;
        //   this.disabled = this.pagingMode !== 'page';
        //   this.resetPageJump();
        // }
        this._isMultiColumnViewer = data.settings.multiColumn.value;
      });

    this._eventBusService
      .on('ContentsComponent:itemLoad')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(() => this._isFixedLayoutBook)
      )
      .subscribe((data) => {
        this._onFixedLayoutPageChange(data.address, data.items);
      });

    this._eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(() => !this._isFixedLayoutBook)
      )
      .subscribe((data) => {
        this._onReflowableLayoutPageChange(
          data.address,
          data.page,
          data.pageCount
        );
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;
        this._isFixedLayoutBook = book.items.every(
          (item) => item.layout === 'fixed'
        );
        this._reset();
      });

    this._eventBusService
      .on('LangaugeSelectorComponent:change')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this._reset();
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  get _latestHistory(): PageHistory | undefined {
    return this._histories[this._histories.length - 1];
  }

  private _onReflowableLayoutPageChange(
    address: Address,
    page: number,
    pageCount: number
  ): void {
    if (!this._book) {
      return;
    }

    const currentPage = {
      url: address.toString(),
      iid: address.iid ?? this._book.items[0].iid,
      page,
      pageCount,
    };

    if (this._latestHistory?.url === address.toString()) {
      this._histories.pop();
    } else if (this._prevPage && this._prevPage.url !== currentPage.url) {
      if (this._prevPage.iid !== currentPage.iid) {
        if (!this._isPageChangedAtItemBoundary(currentPage, this._prevPage)) {
          this._addHistory(this._prevPage);
        }
      } else if (this._prevPage.page !== currentPage.page) {
        if (Math.abs(this._prevPage.page - currentPage.page) > 1) {
          this._addHistory(this._prevPage);
        }
      }
    }

    this._prevPage = currentPage;
  }

  private _onFixedLayoutPageChange(address: Address, items: BookItem[]): void {
    if (!this._book) {
      return;
    }

    const rightItemIndex = Math.max(
      ...items.map((item) => this._book!.getIndexOfItem(item.iid))
    );

    const currentPage = {
      url: address.toString(),
      iid: this._book.items[rightItemIndex].iid,
      page: 1,
      pageCount: 1,
    };

    if (this._latestHistory?.url === address.toString()) {
      this._histories.pop();
    } else if (this._prevPage) {
      const maxPageGap = this._isMultiColumnViewer ? 2 : 1;
      const prevRightItemIndex = this._book.getIndexOfItem(this._prevPage.iid);
      if (Math.abs(prevRightItemIndex - rightItemIndex) > maxPageGap) {
        this._addHistory(this._prevPage);
      }
    }

    this._prevPage = currentPage;
  }

  private _isPageChangedAtItemBoundary(
    currentPage: PageInfo,
    prevPage: PageInfo
  ): boolean {
    if (!this._book) {
      return false;
    }

    const currentItemIndex = this._book.getIndexOfItem(currentPage.iid);
    const prevItemIndex = this._book.getIndexOfItem(prevPage.iid);

    if (Math.abs(prevItemIndex - currentItemIndex) === 1) {
      if (prevItemIndex < currentItemIndex) {
        return prevPage.page === prevPage.pageCount && currentPage.page === 1;
      }

      if (prevItemIndex > currentItemIndex) {
        return (
          prevPage.page === 1 && currentPage.page === currentPage.pageCount
        );
      }

      return false;
    } else {
      return false;
    }
  }

  private _addHistory(pageInfo: PageInfo): void {
    if (!this._book) {
      return;
    }

    this._histories.push({
      url: pageInfo.url,
      displayNumber:
        this._book.meta.type === BookType.PDF
          ? this._book.getIndexOfItem(pageInfo.iid) + 1
          : undefined,
    });
  }

  private _reset(): void {
    this._histories = [];
    this._prevPage = undefined;
  }

  _onBackButtonClick(): void {
    if (!this._latestHistory) {
      return;
    }

    this._eventBusService.fire('PageBackButtonComponent:click', {
      url: this._latestHistory.url,
    });
  }
}
