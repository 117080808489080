import { Component } from '@angular/core';
import { AlertService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-note-send-dialog',
  templateUrl: './note-send-dialog.component.html',
  styleUrl: './note-send-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class NoteSendDialogComponent extends TypedDialog<
  { userId: string; nickname?: string },
  void
> {
  constructor(private _alertService: AlertService) {
    super();
  }

  _onCloseButtonClick(): void {
    this._alertService
      .openConfirm(undefined, '쪽지 보내기를 취소하시겠습니까?', '취소하기')
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this._dialogRef.close();
        }
      });
  }

  _onNoteSent(): void {
    this._dialogRef.close();
  }
}
