import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { Note } from 'shared/models';

import { catchAndRethrowAPIError } from './api-error';
import { QueryResult } from './query-result';

const SEND_NOTE_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  'User not found': '사용자가 존재하지 않습니다.',
  'Parameter missing': '잘못된 요청입니다.',
};

const GET_INBOX_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
};

const GET_SENT_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
};

const DELETE_NOTE_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Parameter missing': '잘못된 요청입니다.',
};

@Injectable({
  providedIn: 'root',
})
export class NotesAPIService {
  constructor(private _http: HttpClient) {}

  send(target_user_id: string, content: string): Observable<void> {
    return this._http
      .post<void>(
        `/api/v1/notes`,
        { target_user_id, content },
        { withCredentials: true }
      )
      .pipe(catchAndRethrowAPIError(SEND_NOTE_ERROR_MSG_MAP));
  }

  getInbox(offset: number, limit: number): Observable<QueryResult<Note>> {
    return this._http
      .get<QueryResult<Note>>('/api/v1/notes/inbox', {
        params: {
          offset,
          limit,
        },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(GET_INBOX_ERROR_MSG_MAP));
  }

  getSent(offset: number, limit: number): Observable<QueryResult<Note>> {
    return this._http
      .get<QueryResult<Note>>('/api/v1/notes/sent', {
        params: {
          offset,
          limit,
        },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(GET_SENT_ERROR_MSG_MAP));
  }

  checkInbox(): Observable<boolean> {
    return this._http
      .head('/api/v1/notes/inbox', {
        withCredentials: true,
        observe: 'response',
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  delete(ids: number[] | number): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._http
      .delete<void>(`/api/v1/notes`, {
        params: { note_ids: ids.join(',') },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(DELETE_NOTE_ERROR_MSG_MAP));
  }
}
