import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Config } from '../constants/config';

export class Broadcaster<EventDataMap> {
  private broadcaster = new Subject<{ key: any; data?: any }>();

  fire<T extends keyof EventDataMap>(key: T, data?: EventDataMap[T]): void {
    if (Config.debug) {
      console.log(`>> ${this.constructor.name}.${key.toString()}\n`, data);
    }

    this.broadcaster.next({ key, data });
  }

  on<T extends keyof EventDataMap>(key: T): Observable<EventDataMap[T]> {
    return this.broadcaster.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => event.data)
    );
  }
}
