<section>
  <div class="top-buttons">
    <div class="release-button" [routerLink]="['exited']">
      <span>내보내기된 그룹</span>
      <sui-icon name="오른쪽 이동"></sui-icon>
    </div>
  </div>
  <div class="empty" *ngIf="_readingGroup?.length === 0">
    <p class="content">가입한 그룹이 없습니다.</p>
  </div>
  <lib-group-item-box
    *ngFor="let myGroup of _readingGroup"
    [readingGroup]="myGroup"
    (bookClick)="_routeDetailBookPage(myGroup.content.id)"
  >
    <button sui-box-button size="normal" (click)="_onClickDetailPopup(myGroup)">
      상세보기
    </button>
    <button
      sui-solid-button
      size="normal"
      [routerLink]="['/@' + myGroup.content.id + '/cover']"
      [queryParams]="{ gId: myGroup.id }"
    >
      함께읽기 시작하기
    </button>
  </lib-group-item-box>

  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</section>
