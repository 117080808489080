<div class="main">
  <div class="empty" *ngIf="_booksWishList?.items?.length === 0">
    <p>찜 목록이 없습니다.</p>
  </div>
  <sui-book
    *ngFor="let book of _booksWishList?.items; index as i"
    [routerLink]="'/@' + book.bid"
  >
    <div
      class="delete-button"
      (click)="_deleteWishList(book.bid); $event.stopPropagation()"
    >
      <sui-icon name="엑스"></sui-icon>
    </div>
    <img sui-book-cover [src]="book.cover" />
    <sui-book-title>{{ book.title }}</sui-book-title>
    <sui-book-author>{{ book.author }}</sui-book-author>
  </sui-book>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</div>
<div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
  <sui-paginator
    [itemCountPerPage]="_itemCountPerPage"
    [totalItemCount]="_totalItemCount"
    [page]="_currentPage"
    (pageChange)="_onPageChange($event)"
  ></sui-paginator>
</div>
