import { Route, UrlMatchResult, UrlSegment } from '@angular/router';

import { authGuard } from './guards/auth.guard';

import { MyBookshelfPageComponent } from './pages/my-bookshelf-page/my-bookshelf-page.component';
import { MyBookshelfDetailPageComponent } from './pages/my-bookshelf-detail-page/my-bookshelf-detail-page.component';

import { SearchPageComponent } from './pages/search-page/search-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { ViewerPageComponent } from './pages/viewer-page/viewer-page.component';
import { LibraryPageComponent } from './pages/library-page/library-page.component';
import {
  MyBookshelfAllBooksPageComponent,
  MyBookshelfAllLibrariesPageComponent,
  MyBookshelfAllPageComponent,
  MyBookshelfExitedGroupsPageComponent,
  MyBookshelfGroupsPageComponent,
  MyBookshelfMainPageComponent,
  MyBookshelfLikesPageComponent,
} from 'bookshelf';

import { canOpenBookWithURL } from 'viewer';
import { SettingsMainPageComponent } from './pages/settings-main-page/settings-main-page.component';
import { SettingsDrawingPageComponent } from './pages/settings-drawing-page/settings-drawing-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LibraryResolver } from './resolver/library.resolver';

const isEditionBid = (eid: string): boolean =>
  /^@?103\.\d{1,3}\.\d{1,3}.\d{1,3}/.test(eid);

export function viewerUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length < 2) {
    return null;
  }

  if (
    canOpenBookWithURL(url[0].toString()) &&
    !isEditionBid(url[0].toString())
  ) {
    return { consumed: url };
  }

  return null;
}

export function libraryUrlMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 0) {
    return null;
  }

  const reg = /@[ljc][a-zA-Z][0-9]{4}\b/;
  const param = url[0].toString();

  if (param.match(reg)) {
    return { consumed: [url[0]] };
  }

  return null;
}

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'bookshelf',
      },
      {
        path: 'bookshelf',
        component: MyBookshelfPageComponent,
        children: [
          {
            data: {
              isApp: true,
            },
            path: '',
            component: MyBookshelfMainPageComponent,
          },

          {
            path: 'groups',
            component: MyBookshelfGroupsPageComponent,
          },
          {
            path: 'groups/exited',
            component: MyBookshelfExitedGroupsPageComponent,
          },
          {
            path: 'all',
            component: MyBookshelfAllPageComponent,
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'books',
              },
              {
                path: 'books',
                component: MyBookshelfAllBooksPageComponent,
              },
              {
                data: {
                  isApp: true,
                },
                path: 'libraries',
                component: MyBookshelfAllLibrariesPageComponent,
              },
            ],
          },

          {
            path: 'likes',
            component: MyBookshelfLikesPageComponent,
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsPageComponent,
        children: [
          {
            path: '',
            component: SettingsMainPageComponent,
            data: {
              title: '설정',
            },
          },
          {
            path: 'drawing',
            component: SettingsDrawingPageComponent,
            data: {
              title: '백업 / 복원 하기',
            },
          },
        ],
      },
      {
        data: {
          isApp: true,
        },
        path: 'bookshelf/detail',
        component: MyBookshelfDetailPageComponent,
      },
      {
        path: 'search',
        component: SearchPageComponent,
      },

      {
        path: 'settings',
        component: SettingsPageComponent,
      },

      {
        matcher: libraryUrlMatcher,
        component: LibraryPageComponent,
        resolve: {
          library: LibraryResolver,
        },
      },
      {
        matcher: viewerUrlMatcher,
        component: ViewerPageComponent,
      },
    ],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
];
