<sui-reading-group-member-avatar
  class="avatar"
  [avatarImageURL]="member.avatar_image_url"
  [isBlocked]="member.is_blocked"
  [isOnline]="isOnline"
></sui-reading-group-member-avatar>
<svg
  *ngIf="member.is_me"
  width="14"
  height="14"
  viewBox="0 0 14 14"
  fill="none"
  style="margin-right: 6px"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM10.994 7.09992V5.9843H9.86094V2.99414H8.53439V10.9941H9.86094V7.09992H10.994ZM2.99414 3.7548V9.0619H4.14942C5.38385 9.06627 6.37959 9.06849 7.78444 8.79745L7.64626 7.61801C6.48092 7.83659 5.5259 7.86498 4.49414 7.88684V3.7548H2.99414Z"
    fill="#282828"
  />
</svg>
<span>{{ member.nickname }}</span>
