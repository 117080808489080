type CallbackFn<T> = (error?: Error, result?: T) => void;

export class CallbackWorker<Data, Result> {
  private _callbacks: {
    [id: string]: CallbackFn<Result>;
  } = {};

  constructor(private _worker: Worker) {
    this._worker.onmessage = ({ data: [id, error, result] }): void => {
      if (this._callbacks[id]) {
        this._callbacks[id](error, result);
        delete this._callbacks[id];
      }
    };
  }

  post(data: Data, callback: CallbackFn<Result>): void {
    const id = Math.floor(Math.random() * 10000000000).toString();

    this._callbacks[id] = callback;

    this._worker.postMessage([id, data]);
  }
}
