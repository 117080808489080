import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Book } from '../models/book.model';

import { EventBusService } from './event-bus.service';

@Injectable()
export class BookService {
  private readonly _book$ = new BehaviorSubject<Book | null>(null);
  readonly book$ = this._book$.asObservable();

  constructor(eventBusService: EventBusService) {
    eventBusService.on('ContentsComponent:bookLoad').subscribe(({ book }) => {
      this._book$.next(book);
    });

    eventBusService.on('ContentsComponent:bookLoadError').subscribe(() => {
      this._book$.next(null);
    });
  }

  get book(): Book | null {
    return this._book$.getValue();
  }
}
