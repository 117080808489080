import {
  Component,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  AfterContentInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { Subject, fromEvent as ObservableFromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

@Component({
  selector: 'viewer-sidebar-container',
  templateUrl: './sidebar-container.component.html',
  styleUrls: ['./sidebar-container.component.scss'],
})
export class SidebarContainerComponent implements AfterContentInit, OnDestroy {
  @ViewChild('clickPanel', { static: true }) clickPanel!: ElementRef;

  @Input() immediate = false;
  @Input() dim = false;
  @Input() disableClose = false;

  @Output() opened = new EventEmitter<string[]>();
  @Output() closed = new EventEmitter<string[]>();

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  private sidebars: { [key: string]: HTMLElement } = {};

  constructor(
    private element: ElementRef,
    private eventBusService: EventBusService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  ngAfterContentInit(): void {
    const positions: string[] = [];

    Array.prototype.forEach.call(
      this.element.nativeElement.children,
      (childElement: HTMLElement) => {
        if (childElement.classList.contains('sidebar')) {
          positions.push(childElement.getAttribute('position')!);
          this.sidebars[childElement.getAttribute('position')!] = childElement;
        }
      }
    );

    if (this.dim) {
      this.clickPanel.nativeElement.classList.add('dimmed');
    }

    let events;

    if (this.immediate) {
      events = CrossBrowsing.touchDevice
        ? ['touchstart']
        : ['mousedown' /*, 'mousewheel'*/];
    } else {
      events = ['click' /*, 'mousewheel'*/];
    }

    events.forEach((eventType) => {
      ObservableFromEvent<Event>(this.clickPanel.nativeElement, eventType)
        .pipe(takeUntil(this.unsubscribeBroadcast))
        .subscribe((event: Event) => {
          if (event.target !== this.clickPanel.nativeElement) {
            return true;
          }
          if (
            event.type === 'mousedown' &&
            (event as MouseEvent).button !== 0
          ) {
            return true;
          }

          this.close(positions);

          return undefined;
        });
    });
  }

  open(positions: string | string[]): void {
    if (typeof positions === 'string') {
      positions = [positions];
    }

    positions.forEach((p) => {
      if (!this.sidebars[p]) {
        return;
      }

      if (!this.isOpened(p)) {
        this.sidebars[p].classList.add('animating');
        this.sidebars[p].classList.add('active');

        this.eventBusService.fire('SidebarContainerComponent:opened', {
          position: p,
        });

        setTimeout(() => {
          this.sidebars[p].classList.remove('animating');
        }, 400);
      }
    });

    this.opened.emit(positions);
  }

  close(positions: string | string[]): void {
    if (typeof positions === 'string') {
      positions = [positions];
    }

    positions.forEach((p) => {
      if (!this.sidebars[p]) {
        return;
      }

      this.sidebars[p].classList.add('animating');
      this.sidebars[p].classList.remove('active');

      this.eventBusService.fire('SidebarContainerComponent:closed', {
        position: p,
      });

      setTimeout(() => {
        this.sidebars[p].classList.remove('animating');
      }, 400);
    });

    this.closed.emit(positions);
  }

  toggle(positions: string | string[]): void {
    if (typeof positions === 'string') {
      positions = [positions];
    }

    positions.forEach((p) => {
      if (!this.sidebars[p]) {
        return;
      }

      if (this.isOpened(p)) {
        this.close(p);
      } else {
        this.open(p);
      }
    });
  }

  isOpened(position: string): boolean {
    return this.sidebars[position].classList.contains('active');
  }

  setMode(mode: 'over' | 'side', positions: string | string[]): void {
    if (typeof positions === 'string') {
      positions = [positions];
    }

    positions.forEach((p) => {
      if (!this.sidebars[p]) {
        return;
      }

      this.sidebars[p].dataset.mode = mode;
    });
  }

  getMode(position: string): 'over' | 'side' {
    return this.sidebars[position].dataset.mode as 'over' | 'side';
  }
}
