<div class="wrapper" #listContainer>
  <div class="main-container" *ngIf="_pageType === 'main'">
    <sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
      <ng-container>
        <div class="nickname-container">
          <span>'</span>
          <span>{{ _userProfile?.nickname }} </span>
          <span> '&nbsp;님의 서재 </span>
        </div>
      </ng-container>
    </sui-dialog-header>
    <section class="header-container">
      <div class="main">
        <div class="user-info">
          <div class="inner-left">
            <sui-reading-group-member-avatar
              class="profile-img-container"
              *ngIf="_userProfile"
              [avatarImageURL]="_userProfile.avatar_image_url"
              [isBlocked]="_userProfile.is_blocked"
            ></sui-reading-group-member-avatar>
            <div class="profile-info">
              <span class="profile-name">{{ _userProfile?.nickname }}</span>
              <div class="profile-follow">
                <span (click)="_onClickFollower()"
                  >팔로워 &nbsp; {{ _userProfile?.follower_count }}</span
                >
                <span (click)="_onClickFollowing()"
                  >팔로잉 &nbsp; {{ _userProfile?.following_count }}</span
                >
              </div>
            </div>
          </div>
          <div class="inner-right">
            <button
              class="follow unfollow"
              *ngIf="_isFollow"
              (click)="_onClickUnFollow()"
            >
              언팔로우
            </button>
            <button
              class="follow"
              *ngIf="!_isFollow"
              (click)="_onClickFollow()"
            >
              팔로우
            </button>
            <button class="message" (click)="_onClickMessageButton()">
              <div class="icon-container">
                <sui-icon type="solid" name="메일"></sui-icon>
              </div>
              <span>쪽지</span>
            </button>
          </div>
        </div>
        <nav class="navbar-mobile">
          <ul>
            <li
              (click)="_changeNavbar('책장')"
              [class.selected]="
                _selectedType === '책장' || _selectedType === '책장 상세 페이지'
              "
            >
              책장 ({{ _totalBookshelf }})
            </li>
            <li
              (click)="_changeNavbar('그룹')"
              [class.selected]="_selectedType === '그룹'"
            >
              그룹 목록 ({{ _totalGroup }})
            </li>
          </ul>
        </nav>
      </div>
    </section>
    <app-bookshelf
      *ngIf="_selectedType === '책장'"
      [userId]="_userId"
      [parentElement]="listContainer"
      (changePageType)="_onChangePageType($event)"
    ></app-bookshelf>
    <app-group-list
      [userId]="_userId"
      [parentElement]="listContainer"
      *ngIf="_selectedType === '그룹'"
      (readTogetherButtonClick)="_groupEnterButtonClick()"
    ></app-group-list>
  </div>
  <app-others-bookshelf-detail-dialog
    *ngIf="_pageType === 'detail' && _selectedBookshelfId"
    [bookshelfId]="_selectedBookshelfId"
    (backPage)="_onChangePageType($event)"
    (closeButtonClick)="_dialogRef.close()"
    [parentElement]="listContainer"
  ></app-others-bookshelf-detail-dialog>
</div>
