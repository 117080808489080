<sui-dialog-header class="header" (closeButtonClick)="_onCloseButtonClick()"
  >메모 내보내기</sui-dialog-header
>
<div class="body">
  <section class="memo">
    <viewer-memo [limitHeight]="true" [content]="_data.content">
      <viewer-memo-type type="mine"></viewer-memo-type>
    </viewer-memo>
  </section>
  <section class="group-selection">
    <div class="header">
      <h2 class="header-title">내보내기 선택</h2>
      <sui-icon
        name="도움말"
        type="solid"
        tooltip="내 메모를 내보내기할 수 있는 모드 / 그룹을 선택할 수 있어요."
        [options]="{ tooltipClass: 'viewer-tooltip' }"
      ></sui-icon>
    </div>
    <div class="group-list">
      <div class="group" *ngIf="_currentGroupId !== undefined">
        <div class="title-box">
          <div class="title">혼자 읽기</div>
        </div>
        <div class="member"></div>
        <ng-container *ngIf="_isGroupSelected(-1); else selectedBtn">
          <button
            varSize="medium40"
            sui-solid-button
            (click)="_onSelectGroup(-1)"
          >
            선택완료
          </button>
        </ng-container>

        <ng-template #selectedBtn>
          <button
            varSize="medium40"
            sui-box-button
            (click)="_onSelectGroup(-1)"
          >
            선택하기
          </button>
        </ng-template>
      </div>
      <ng-container *ngFor="let group of _groups">
        <div class="group" *ngIf="group.id !== _currentGroupId">
          <div class="title-box">
            <div class="title">
              {{ group.name }}
            </div>
          </div>
          <sui-flag-tag [color]="group.type === 'public' ? 'point' : 'gray'">
            {{ group.type === 'public' ? '공개' : '비공개' }}
          </sui-flag-tag>
          <div class="member">
            정원: {{ group.member_count }}명 / {{ group.max_member_count }}명
          </div>
          <ng-container *ngIf="_isGroupSelected(group.id); else selectedBtn">
            <button
              sui-solid-button
              varSize="medium40"
              (click)="_onSelectGroup(group.id)"
            >
              선택완료
            </button>
          </ng-container>

          <ng-template #selectedBtn>
            <button
              varSize="medium40"
              sui-box-button
              (click)="_onSelectGroup(group.id)"
            >
              선택하기
            </button>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </section>
  <section class="footer">
    <div class="selection-count">
      <ng-container *ngIf="_selectedGroupIds.length > 0">
        총 <span class="number">{{ _selectedGroupIds.length }}</span
        >개의 메모 내보내기 선택
      </ng-container>
      &nbsp;
    </div>
    <button
      sui-solid-button
      varSize="large"
      [disabled]="_selectedGroupIds.length === 0"
      (click)="_onExportButtonClick()"
    >
      내보내기
    </button>
  </section>
</div>
