<div class="row selection-title" (click)="_isOptionsOpened = !_isOptionsOpened">
  <div class="text">{{ selectedValue }}{{ _optionSuffix }}</div>
  <sui-icon
    class="dropdown icon"
    name="닫힘"
    [class.is-opened]="_isOptionsOpened"
  ></sui-icon>
</div>
<div class="options" [class.is-opened]="_isOptionsOpened">
  <ng-container *ngFor="let value of _valueOptions">
    <div class="row option" (click)="_onOptionClick(value)">
      <div class="text">{{ value }}{{ _optionSuffix }}</div>
      <sui-icon
        class="check icon"
        [class.is-hidden]="selectedValue !== value"
        name="체크"
      ></sui-icon>
    </div>
  </ng-container>
</div>
