import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { USER_HIGHLIGHT_STYLES } from '../contents/contents.component';

@Component({
  selector: 'viewer-highlight-filter-toggle',
  templateUrl: './highlight-filter-toggle.component.html',
  styleUrls: ['./highlight-filter-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HighlightFilterToggleComponent,
    },
  ],
})
export class HighlightFilterToggleComponent implements ControlValueAccessor {
  public readonly _HighlightFilter: string[];
  public readonly _HighlightStyles: string[];

  public _selectedFilter: string | null = null;

  onChange = (filter: string | null): void => {};

  onTouched = (): void => {};

  touched = false;

  disabled = false;

  constructor() {
    this._HighlightFilter = USER_HIGHLIGHT_STYLES.map((s) => s.styleClass);
    this._HighlightStyles = USER_HIGHLIGHT_STYLES.map((s) => s.style);
  }

  writeValue(filter: string | null): void {
    this._selectedFilter = filter;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  _onFilterClick(filter: string | null): void {
    this._selectedFilter = filter;
    this.onChange(this._selectedFilter);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
