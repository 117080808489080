import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';

import scrollIntoView from 'scroll-into-view-if-needed';
import { Memo } from 'shared/models';

import { UIStateService } from '../../services/ui-state.service';
import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { OnlineReadingMemberService } from '../../services/online-reading-member.service';

import { getMemoGroupType } from '../../utils/memo';

import { RightPanelComponent } from '../right-panel/right-panel.component';

interface MemoGroupView {
  text: string;
  url: string;
  memos: Memo[];
  type: 'mine' | 'others' | 'mixed';
}

@Component({
  selector: 'viewer-item-memo-list',
  templateUrl: './item-memo-list.component.html',
  styleUrls: ['./item-memo-list.component.scss'],
})
export class ItemMemoListComponent implements OnInit, OnDestroy {
  public _memoGroups?: MemoGroupView[][];
  public _activeGroupIndex: number = -1;
  public _bid?: string;
  public _currentIid?: string;
  public _pageIids: string[] = [];

  public _showMine = false;
  public _canShowMine = true;

  public _onlineUserMap: { [userId: string]: boolean } = {};

  private _unsubscriber = new Subject<void>();

  public _isCommentaryEditorMode = false;

  @ViewChildren('group') groupElemQueryList!: QueryList<
    ElementRef<HTMLDivElement>
  >;

  constructor(
    private _element: ElementRef,
    private _eventBus: EventBusService,
    private _annotationsService: AnnotationsV2Service,
    private _uiStateService: UIStateService,
    private _rightPanelComponent: RightPanelComponent,
    private _onlineReadingMemberService: OnlineReadingMemberService
  ) {}

  get _totalMemoCount(): number {
    return (
      this._memoGroups?.reduce(
        (result, group) =>
          result +
          group.reduce((result, group) => result + group.memos.length, 0),
        0
      ) ?? 0
    );
  }

  ngOnInit(): void {
    this._annotationsService.state$
      .pipe(
        takeUntil(this._unsubscriber),
        map((state) => state.showMine),
        distinctUntilChanged()
      )
      .subscribe((showMine) => {
        if (!showMine) {
          this._showMine = false;
          this._canShowMine = false;
        } else {
          this._canShowMine = true;
        }
      });

    this._eventBus
      .on('ContentsComponent:memoIndicatorClick')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ iid, groupIndex }) => {
        if (this._uiStateService.canUseMemoSidebar()) {
          this._open(iid, groupIndex);
        } else {
          this.close(false);
        }
      });

    this._eventBus
      .on('ContentsComponent:itemLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ items, address }) => {
        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;

        this._memoGroups = undefined;
        this._bid = address.bid;
        this._pageIids = items.map((item) => item.iid).reverse();
      });

    this._eventBus
      .on('MemoPopupComponent:seeMemoListButtonClick')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ iid, groupIndex }) => {
        this._open(iid, groupIndex);
      });

    this._onlineReadingMemberService.onlineStatusChange$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._onlineUserMap[data.userId] = data.isOnline;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  private _open(iid: string, groupIndex: number): void {
    this._rightPanelComponent.open('memo');

    this._onlineUserMap = {};

    this._memoGroups = this._pageIids.map((iid) => {
      return this._annotationsService.getMemosOfItem(iid).map((group) => {
        group.forEach((m) => {
          this._onlineUserMap[m.author.id] =
            this._onlineReadingMemberService.isOnline(m.author.id);
        });

        const shortMemo = group.reduce((prev, current) =>
          prev && prev.text.length < current.text.length ? prev : current
        );

        return {
          text: shortMemo.text,
          url: shortMemo.url,
          memos: group,
          type: getMemoGroupType(group),
        };
      });
    });

    this._currentIid = iid;
    this._activeGroupIndex = groupIndex;
    this._showMine = false;

    setTimeout(() => {
      this._scrollIntoActiveGroup();
    });
  }

  private _scrollIntoActiveGroup(): void {
    if (!this._memoGroups) {
      return;
    }

    const currGroupIndex = this._pageIids.findIndex(
      (iid) => iid === this._currentIid
    );

    const groupElem = this.groupElemQueryList.get(
      (currGroupIndex > 0 ? this._memoGroups![currGroupIndex - 1].length : 0) +
        this._activeGroupIndex
    );

    if (!groupElem) {
      return;
    }

    scrollIntoView(groupElem.nativeElement, {
      // behavior: 'smooth',
      block: 'center',
      boundary: this._element.nativeElement,
    });
  }

  close(emitEvent: boolean = true): void {
    if (!this._rightPanelComponent.isOpened('memo')) {
      return;
    }

    this._rightPanelComponent.close(emitEvent);
  }

  _onMemoDeleted(
    itemIndex: number,
    groupIndex: number,
    memoIndex: number
  ): void {
    const group = this._memoGroups?.[itemIndex][groupIndex];

    if (!group) {
      return;
    }

    if (group.memos.length === 1) {
      this.close();
    } else {
      group.memos.splice(memoIndex, 1);
      group.memos = Array.from(group.memos);
    }
  }

  _onMemoBlinded(memo: Memo): void {
    this.close();
  }

  _onMemoUserBlocked(memo: Memo): void {
    this.close();
  }

  _onGroupClick(itemIndex: number, index: number): void {
    const group = this._memoGroups?.[itemIndex][index];

    if (!group) {
      return;
    }

    this._currentIid = this._pageIids[itemIndex];
    this._activeGroupIndex = index;

    this._eventBus.fire('ItemMemoListComponent:groupClicked', {
      url: group.url,
      type: group.type === 'mixed' ? 'double' : 'single',
    });

    if (!this._uiStateService.canUseMemoSidebar()) {
      this.close(false);
    }
  }

  _onMemoSeeDeatilsButtonClicked(): void {
    if (!this._uiStateService.canUseMemoSidebar()) {
      this.close(false);
    }
  }
}
