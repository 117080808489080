<section>
  <div [class.hidden]="!_isLoading" class="dialog-loader">
    <sui-loader></sui-loader>
  </div>
  <div class="header">
    <div class="inner-left" *ngIf="!_isSearchButton">
      <sui-icon
        name="화살표_왼쪽"
        [routerLink]="['/bookshelf/all/libraries']"
      ></sui-icon>
      <h2>
        {{ _pageName }}
        <span class="total">({{ _totalItemCount }})</span>
      </h2>
    </div>
    <div class="inner-right">
      <div class="searchAndAddContainer">
        <div class="search-bar" *ngIf="_isSearchButton">
          <sui-icon name="검색"></sui-icon>
          <input
            #searchInput
            type="text"
            placeholder="검색어를 입력해주세요."
            [(ngModel)]="_currSearchValue"
            (keyup)="_searchBookItem()"
          />
          <div class="cancel-container">
            <button
              class="cancel-button"
              *ngIf="_currSearchValue"
              (click)="_clearCurrSearchValue()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
                  fill="#CCCCCC"
                />
              </svg>
            </button>
          </div>
        </div>
        <sui-icon
          *ngIf="_isSearchButton"
          class="cancel"
          name="엑스"
          (click)="_clearCurSearchValueAndCloseSearchBar()"
        ></sui-icon>
        <sui-icon
          *ngIf="!_isSearchButton"
          class="search"
          name="검색"
          (click)="_onClickSearchButton()"
        ></sui-icon>
      </div>
    </div>
  </div>
  <div class="main" *ngIf="_bookList?.length !== 0">
    <sui-book
      *ngFor="let book of _bookList; index as i"
      (click)="onBookClick(i)"
    >
      <img sui-book-cover [src]="book.cover" />
      <sui-book-title>{{ book.title }}</sui-book-title>
      <sui-book-author>{{ book.author }}</sui-book-author>
      <sui-book-tail-tag
        class="sui-book__tail-tags"
        *ngIf="book.sales_type.includes('rent')"
        >대여</sui-book-tail-tag
      >
      <sui-book-tail-tag
        class="sui-book__tail-tags"
        *ngIf="book.sales_type.includes('free')"
        >무료</sui-book-tail-tag
      >

      <sui-book-tag *ngIf="book.is_commentarybook" theme="green"
        ><sui-icon name="코멘터리"></sui-icon>코멘터리북</sui-book-tag
      >
      <div
        class="sui-book__overlay sui-book--desktop"
        *ngIf="book.has_subcontent"
      >
        <sui-book-sub-content-tag></sui-book-sub-content-tag>
      </div>
      <div
        class="sui-book__cover-overlay sui-book--mobile"
        *ngIf="book.has_subcontent"
      >
        <sui-book-sub-content-tag></sui-book-sub-content-tag>
      </div>
    </sui-book>
  </div>
  <div
    class="empty"
    *ngIf="!_isLoading && _bookList.length === 0 && _currSearchValue === ''"
  >
    <p>라이브러리에 책이 없습니다.</p>
  </div>
  <div
    class="empty"
    *ngIf="!_isLoading && _bookList.length === 0 && _currSearchValue !== ''"
  >
    <p>검색 결과가 없습니다.</p>
  </div>
  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
</section>
