import { Injectable } from '@angular/core';

import { encode } from 'bs58';
import * as Url from 'url-parse';

import { SharedAuthService } from 'shared/services';

import { environment } from '../../environments/environment';

function encode58(obj: any): string {
  return encode(new Buffer(JSON.stringify(obj), 'binary'));
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends SharedAuthService {
  override login(
    redirectTo: string,
    params?: unknown,
    encodedParams?: unknown
  ): void {
    const loginUrl = new Url(`${environment.serverOrigin}/login`);

    if (encodedParams) {
      loginUrl.set(
        'pathname',
        loginUrl.pathname + '/' + encode58(encodedParams)
      );
    }

    loginUrl.set('query', Object.assign({ redirect_to: redirectTo }, params));

    location.href = loginUrl.toString();
  }

  logout(redirectTo: string): void {
    const logutUrl = new Url(`${environment.serverOrigin}/logout`);

    logutUrl.set('query', { redirect_to: redirectTo });

    location.href = logutUrl.toString();
  }
}
