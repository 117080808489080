import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as crypto from 'crypto-js';

import { BukJSON, SeriesInfo } from '../models/book.model';

import { Pattern } from '../constants/pattern';
import { Config } from '../constants/config';
import {
  Address,
  isWikiBid,
  PDFTextInfo,
  renderWikiBukJSON,
  WikiAPIService,
} from '@bukio/viewer';

import { ContentsDecryptionService } from './contents-decryption.service';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(
    private _http: HttpClient,
    private _wikiAPIService: WikiAPIService,
    private _d: ContentsDecryptionService
  ) {}

  getBukJSON(
    address: Address,
    addParams?: Partial<{ service: string; key: string }>
  ): Observable<BukJSON> {
    if (isWikiBid(address.bid)) {
      return this._getWikiBukJSON(address);
    } else {
      return this._getBukJSON(address, addParams);
    }
  }

  getPDFText(bid: string): Observable<PDFTextInfo[]> {
    return this._http
      .get(`/api/v1/pdf/texts/${bid}`, {
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        switchMap((res) => {
          if (!res.body) {
            return throwError('empty body');
          }

          const rand = res.headers.get('x-timestamp');

          if (!rand) {
            return throwError('no rand value');
          }

          return this._d._1(res.body, bid, rand);
        })
      );
  }

  private _getBukJSON(
    address: Address,
    addParams?: Partial<{ service: string; key: string }>
  ): Observable<BukJSON> {
    const reqURL = Pattern.cardBid.test(address.bid)
      ? `/${address.bid}/buk.json`
      : `/@${address.bid}/buk.json`;

    return this._http
      .get<any>(reqURL, {
        withCredentials: true,
        params: addParams,
      })
      .pipe(
        map((response) => {
          // 출판사 배열 사용 x
          if (response.meta.publisher) {
            response.meta.publisher = response.meta.publisher[0];
          }

          // 할인하지 않는 경우 discounted가 null인데 이를 정가로 채워줌
          ['buy', 'rent', 'rent2', 'paper'].forEach((pricingType) => {
            if (response.meta.pricing[pricingType].discounted == null) {
              response.meta.pricing[pricingType].discounted =
                response.meta.pricing[pricingType].price;
            }
          });

          // 종이책 링크
          if (!response.meta.paperlink) {
            response.meta.paperlink = [];
          }

          // 뷰어에서 표시할 종이책 링크 필터링, 순서 정리
          // TODO: paperlink를 사용하는 쪽에서 해야할 일?
          response.meta.paperlink = response.meta.paperlink
            .filter((item: any) => {
              if (
                response.meta.publisher.length > 0 &&
                response.meta.publisher[0].country !== 'kr'
              ) {
                return item.site === 'unknown';
              }
              return Config.supportedBookstore.test(item.site);
            })
            .sort((site1: any, site2: any) => {
              return (
                (Config.bookstoreScore as any)[site1.site] -
                (Config.bookstoreScore as any)[site2.site]
              );
            });

          // permission 복호화
          response.permission = JSON.parse(
            crypto.AES.decrypt(response.permission, 'permission').toString(
              crypto.enc.Utf8
            )
          );

          return response as BukJSON;
        })
      );
  }

  private _getWikiBukJSON(address: Address): Observable<BukJSON> {
    return this._wikiAPIService
      .getMeta(address.bid, address.iid!, address.query?.version)
      .pipe(
        map((response) => {
          const bukJSON = renderWikiBukJSON(address, response);

          return {
            meta: {
              ...bukJSON.meta,
              is_subcontent: 0,
              isbn: null,
              paperlink: [],
              pricing: {
                buy: {
                  price: 0,
                  discounted: 0,
                  published: 0,
                },
                rent: {
                  price: 0,
                  discounted: 0,
                  published: 0,
                },
                rent2: {
                  price: 0,
                  discounted: 0,
                  published: 0,
                },
                paper: {
                  price: 0,
                  discounted: 0,
                  published: 0,
                },
              },
              category: [],
              book_info: {},
              paper_info: {},
              additional_contents: [],
              subcontents: [],
              commentaries: [],
            },
            page_list: bukJSON.page_list,
            items: bukJSON.items,
            toc: bukJSON.toc,
            permission: {
              read: [],
              share: [],
              type: 'library',
              date: null,
              purchased_at: null,
            },
          };
        })
      );
  }

  getPermissionItems(
    referer: string
  ): Observable<{ items?: { iid: string; url: string }[]; code: number }> {
    return this._http.get<{ items: any[]; code: number }>('/permission', {
      params: { url: referer },
    });
  }

  getSeriesInfo(bid: string): Observable<SeriesInfo> {
    return this._http
      .get<SeriesInfo | { error: { code: number; message: string } }>(
        '/api/1.0/book/series',
        {
          params: { bid },
        }
      )
      .pipe(
        map((response) => {
          const error = (response as any).error;

          if (error) {
            throw new Error(error.message);
          }

          return response as SeriesInfo;
        })
      );
  }
}
