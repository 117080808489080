<div class="main">
  <div class="empty" *ngIf="_bookList?.items?.length === 0">
    <p class="content">구매한 책이 없습니다.</p>
  </div>
  <sui-book *ngFor="let book of _bookList?.items; index as i">
    <img sui-book-cover [src]="book.cover" (click)="onBookClick(i)" />
    <div *ngIf="book.expired" class="sui-book__overlay">
      <sui-book-expired-overlay
        (deleteButtonClick)="_onClickDeleteBook(book.bid)"
      ></sui-book-expired-overlay>
    </div>
    <sui-book-title (click)="onBookClick(i)">{{ book.title }}</sui-book-title>
    <sui-book-author (click)="onBookClick(i)">{{
      book.author
    }}</sui-book-author>
    <div class="expired" *ngIf="book.expiryDate">
      만료 시간 {{ book.expiryDate | date : 'yyyy.MM.dd. HH:mm' }}
    </div>
    <button
      *ngIf="book.has_subcontent && !book.expired"
      (click)="_onClickAppendix($event, book.bid)"
      class="sui-book--desktop appendix-button"
    >
      <sui-icon name="부록"></sui-icon>
      <span>부록보기</span>
    </button>

    <!-- <sui-book-tail-tag class="sui-book__tail-tags" *ngIf="book.can_submit"
      >독서기록 제출 대기 중</sui-book-tail-tag
    > -->
    <sui-book-tag *ngIf="book.type === 'ticket'" theme="yellowgreen"
      ><sui-icon name="북티켓" type="solid"></sui-icon>북티켓</sui-book-tag
    >
    <sui-book-tag *ngIf="book.is_commentarybook" theme="green"
      ><sui-icon name="코멘터리"></sui-icon>코멘터리북</sui-book-tag
    >
  </sui-book>
</div>
<div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
  <sui-paginator
    [itemCountPerPage]="_itemCountPerPage"
    [totalItemCount]="_totalItemCount"
    [page]="_currentPage"
    (pageChange)="_onPageChange($event)"
  ></sui-paginator>
</div>
<div class="loader" *ngIf="_isLoading">
  <sui-loader></sui-loader>
</div>
