import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReadingGroupDialogService } from 'reading-group';
import { Subject, map, switchMap } from 'rxjs';
import { BookshelfBook } from 'shared/models';
import { BookshelfAPIService, QueryResult } from 'shared/services';
import { AlertService, DialogService } from 'shared/ui';
import * as Url from 'url-parse';
import { AppendixComponent } from '../../components/appendix/appendix.component';

function getBookURL(book: {
  bid: string;
  url: string;
  expired: number;
}): string {
  // 책 페이지로 이동, /@bid 주소가 뷰어로 가는 책은 고려되지 않음.
  if (book.expired) {
    return `/@${book.bid}`;
  }

  if (!book.url) {
    return `/@${book.bid}/cover`;
  }

  if (/\/@[^plwj][a-z]\d{4}$/.test(book.url)) {
    return `${book.url}/cover`;
  }

  return book.url;
}

@Component({
  selector: 'lib-my-bookshelf-all-books-page',
  templateUrl: './my-bookshelf-all-books-page.component.html',
  styleUrl: './my-bookshelf-all-books-page.component.scss',
})
export class MyBookshelfAllBooksPageComponent implements OnInit, OnDestroy {
  private _myOwnBooksSubject = new Subject<{ page: number }>();

  public _bookList?: QueryResult<BookshelfBook>;

  public _currentPage = 1;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookshelfAPIService: BookshelfAPIService,
    private _dialogService: DialogService,
    private _alertService: AlertService,
    private _readingGroupDialogService: ReadingGroupDialogService
  ) {}

  ngOnInit(): void {
    this._myOwnBooksSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._bookshelfAPIService.getOwnedBooks(
            page * this._itemCountPerPage,
            this._itemCountPerPage,
            true
          );
        })
      )
      .subscribe((result) => {
        window.scrollTo(0, 0);
        this._isLoading = false;
        this._totalItemCount = result.total;
        this._bookList = result;
      });

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }
        this._currentPage = page - 1;

        this._myOwnBooksSubject.next({ page: this._currentPage });
      });
  }

  ngOnDestroy(): void {
    this._myOwnBooksSubject.complete();
  }

  // 만료된 책 책장에서 삭제 로직
  public _onClickDeleteBook(id: string): void {
    this._alertService
      .openConfirm(
        '대여 / 구독 기간이 만료되었습니다.',
        '책을 삭제하시겠습니까?',
        '삭제하기'
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._bookshelfAPIService.removeBook(id).subscribe(() => {
            this._onPageChange(0);
          });
        }
      });
  }

  public _onClickAppendix(event: Event, id: string): void {
    event.stopPropagation();
    this._dialogService.open(AppendixComponent, {
      data: {
        book_id: id,
      },
    });
  }

  onBookClick(index: number): void {
    const book = this._bookList?.items[index];

    if (!book) {
      return;
    }

    if (book.expired) {
      return;
    }

    if (book.book_type === 'edition') {
      this.goToBook(book);
      return;
    }

    const dialog = this._readingGroupDialogService.openReadingModeSelection(
      book.bid
    );

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.canceled) {
        return;
      }

      this.goToBook(book, result.groupId);
    });
  }

  goToBook(book: BookshelfBook, groupId?: number): void {
    const [path, query] = getBookURL(book).split('?');
    const queryParams = query ? Url.qs.parse(query) : undefined;

    this._router.navigate([path], {
      queryParams: Object.assign(
        { ...queryParams },
        groupId && {
          gId: groupId,
        },
        {
          bookshelf: true,
        }
      ),
    });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }
}
