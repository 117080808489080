import { Component } from '@angular/core';

import { TypedDialog } from '../dialog/typed-dialog';

@Component({
  selector: 'sui-simple-alert',
  templateUrl: './simple-alert.component.html',
  styleUrl: './simple-alert.component.scss',
})
export class SimpleAlertComponent extends TypedDialog<
  { title?: string; message?: string; okButtonLabel?: string },
  boolean
> {
  _onOKButtonClick(): void {
    this._dialogRef.close(true);
  }
}
