import { Pipe, PipeTransform } from '@angular/core';
// import * as linkify from 'linkifyjs';
import linkifyHtml from 'linkify-html';

@Pipe({
  name: 'detectLink',
  pure: true,
})
export class DetectLinkPipe implements PipeTransform {
  transform(value: string): string {
    return linkifyHtml(value, { target: '_blank' });
  }
}
