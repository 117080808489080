import { Component, Inject } from '@angular/core';
import { TypedDialog } from 'shared/ui';

import {
  VIEWER_ENVIRONMENT,
  ViewerModuleEnvironment,
} from '../../viewer.module';

enum Page {
  Main,

  // basic
  BookInfo,
  Thumbnail,
  Settings,
  Drawing,
  Bookmark,
  Search,

  // reading mode
  ReadingMode,

  // notes
  Highlight,
  Memo,
  Copy,
  Share,

  // notes list
  Annotations,
  Commentary,
  GroupShared,
}

@Component({
  selector: 'viewer-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrl: './help-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class HelpDialogComponent extends TypedDialog<
  { isPDFBook: boolean },
  void
> {
  public _Page = Page;
  public _currentPage = Page.Main;

  public _pageListBasic = [
    {
      iconName: '앱_도서 리스트',
      iconSize: 22,
      iconType: 'solid',
      title: '목차',
      page: Page.BookInfo,
    },
    {
      iconName: '페이지 모아보기',
      iconSize: 19,
      iconType: 'solid',
      title: '페이지 모아보기',
      page: Page.Thumbnail,
    },
    {
      iconName: '환경설정',
      iconSize: 22,
      iconType: 'solid',
      title: '뷰어 설정',
      page: Page.Settings,
    },
    {
      iconName: '펜',
      iconSize: 18,
      iconType: 'solid',
      title: '필기',
      page: Page.Drawing,
    },
    {
      iconName: '북마크',
      iconSize: 18,
      iconType: 'solid',
      title: '북마크',
      page: Page.Bookmark,
    },
    {
      iconName: '검색',
      iconSize: 18,
      iconType: 'outline',
      title: '본문 검색',
      page: Page.Search,
    },
  ];

  public _pageListReadingMode = [
    {
      iconName: '뷰어 그룹 변경',
      iconSize: 22,
      iconType: 'outline',
      title: '읽기 모드 변경',
      page: Page.ReadingMode,
    },
  ];

  public _pageListNotes = [
    {
      iconName: '하이라이트',
      iconSize: 20,
      iconType: 'outline',
      title: '하이라이트',
      page: Page.Highlight,
    },
    {
      iconName: '메모',
      iconSize: 18,
      iconType: 'solid',
      title: '메모',
      page: Page.Memo,
    },
    {
      iconName: '복사',
      iconSize: 18,
      iconType: 'solid',
      title: '복사',
      page: Page.Copy,
    },
    {
      iconName: '공유',
      iconSize: 18,
      iconType: 'solid',
      title: '본문 공유',
      page: Page.Share,
    },
  ];

  public _pageListNoteList = [
    {
      iconName: '내 독서 기록',
      iconSize: 18,
      iconType: 'solid',
      title: '내 독서 기록 보기',
      page: Page.Annotations,
    },
    {
      iconName: '코멘터리북(채움)',
      iconSize: 18,
      iconType: 'solid',
      title: '코멘터리 보기',
      page: Page.Commentary,
    },
    {
      iconName: '앱_그룹',
      iconSize: 18,
      iconType: 'solid',
      title: '그룹원의 하이라이트, 메모 기록 보기',
      page: Page.GroupShared,
    },
  ];

  public _isApp: boolean;

  constructor(
    @Inject(VIEWER_ENVIRONMENT) environment: ViewerModuleEnvironment
  ) {
    super();
    this._isApp = environment.isApp;
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  _onPageListItemClick(page: Page): void {
    this._currentPage = page;
  }

  _onBackButtonClick(): void {
    this._currentPage = Page.Main;
  }
}
