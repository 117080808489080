<header class="header">
  <div class="header-inner">
    <button sui-icon-button class="back-button" (click)="_onBackButtonClick()">
      <sui-icon name="화살표_왼쪽"></sui-icon>
    </button>
    {{ _pageTitle }}
  </div>
</header>
<main class="main">
  <router-outlet></router-outlet>
</main>
