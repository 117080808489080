<section>
  <div [class.hidden]="!_isLoading" class="dialog-loader">
    <sui-loader></sui-loader>
  </div>
  <div class="header">
    <div class="inner-left" *ngIf="!(_screenWidth <= 768 && _isSearchButton)">
      <sui-icon name="화살표_왼쪽" [routerLink]="['/bookshelf']"></sui-icon>
      <sui-icon
        type="solid"
        name="잠금"
        *ngIf="_bookshelf?.is_private === 1"
      ></sui-icon>
      <h2>
        {{ _bookshelf?.title }}
        <span class="total">({{ _totalItemCount }})</span>
      </h2>
    </div>
    <div class="inner-right">
      <div class="more-container">
        <sui-icon
          *ngIf="!(_screenWidth <= 1200 && _isSearchButton)"
          class="more-button"
          name="더보기"
          (click)="_onClickDetailButton()"
        ></sui-icon>
      </div>
      <div class="search-and-add-container">
        <div class="add-book-button" (click)="_onClickBookAddButton()">
          <sui-icon name="플러스"></sui-icon>
          <span>책 추가하기</span>
        </div>
        <div class="search-bar" *ngIf="_isSearchButton">
          <sui-icon name="검색"></sui-icon>
          <input
            #searchInput
            type="text"
            placeholder="검색어를 입력해주세요."
            [(ngModel)]="_currSearchValue"
            (keyup)="_searchBookItem()"
          />
          <div class="cancel-container">
            <button
              class="cancel-button"
              *ngIf="_currSearchValue"
              (click)="_clearCurrSearchValue()"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
                  fill="#CCCCCC"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          class="search-bar-close-button-container"
          *ngIf="_isSearchButton"
          (click)="_clearCurSearchValueAndCloseSearchBar()"
        >
          <sui-icon class="cancel" name="엑스"></sui-icon>
        </div>
        <sui-icon
          *ngIf="!_isSearchButton"
          class="search"
          name="검색"
          (click)="_onClickSearchButton()"
        ></sui-icon>
      </div>
    </div>
    <div class="inner-selectBox" *ngIf="_isDetailButton">
      <ul>
        <li (click)="_onClickEditBooklist()">책 목록 편집하기</li>
        <li
          (click)="_onClickEditBookshelf()"
          *ngIf="_bookshelf!.title !== '내가 읽고 있는 책'"
        >
          책장 이름 변경하기
        </li>
        <li
          (click)="_onClickDeleteBookshelf()"
          *ngIf="_bookshelf!.title !== '내가 읽고 있는 책'"
        >
          책장 삭제하기
        </li>
      </ul>
    </div>
  </div>
  <div
    class="main"
    *ngIf="_bookshelvesBookList?.length !== 0"
    [ngClass]="{ app: _isApp }"
  >
    <sui-book *ngFor="let book of _bookshelvesBookList; index as i">
      <img sui-book-cover [src]="book.cover" (click)="onBookClick(i)" />
      <div *ngIf="book.expired" class="sui-book__overlay">
        <sui-book-expired-overlay
          (deleteButtonClick)="_onClickDeleteBook(book.bid)"
        ></sui-book-expired-overlay>
      </div>
      <sui-book-title (click)="onBookClick(i)">{{ book.title }}</sui-book-title>
      <sui-book-author (click)="onBookClick(i)">{{
        book.author
      }}</sui-book-author>
      <div class="expired" *ngIf="book.expiryDate">
        만료 시간 {{ book.expiryDate | date : 'yyyy.MM.dd. hh:mm' }}
      </div>
      <button
        *ngIf="book.has_subcontent && !book.expired"
        (click)="_onClickAppendix($event, book.bid)"
        class="sui-book--desktop appendix-button"
      >
        <sui-icon name="부록"></sui-icon>
        <span>부록보기</span>
      </button>

      <!-- <sui-book-tail-tag class="sui-book__tail-tags" *ngIf="book.can_submit"
        >독서기록 제출 대기 중</sui-book-tail-tag
      > -->
      <sui-book-tag *ngIf="book.type === 'ticket'" theme="yellowgreen"
        ><sui-icon name="북티켓" type="solid"></sui-icon>북티켓</sui-book-tag
      >
      <sui-book-tag *ngIf="book.is_commentarybook" theme="green"
        ><sui-icon name="코멘터리"></sui-icon>코멘터리북</sui-book-tag
      >
    </sui-book>
  </div>
  <div
    class="empty"
    *ngIf="
      !_isLoading &&
      _bookshelvesBookList.length === 0 &&
      _currSearchValue === ''
    "
  >
    <p>책장에 담긴 책이 없습니다.</p>
  </div>
  <div
    class="empty"
    *ngIf="
      !_isLoading &&
      _bookshelvesBookList.length === 0 &&
      _currSearchValue !== ''
    "
  >
    <p>검색 결과가 없습니다.</p>
  </div>
  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
</section>
