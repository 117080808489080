import {
  AfterViewInit,
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import iro from '@jaames/iro';

@Component({
  selector: 'viewer-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
})
export class ColorPickerComponent implements AfterViewInit {
  @ViewChild('picker', { static: true }) picker!: ElementRef<HTMLDivElement>;

  @Input() initialColor?: string;
  @Output() colorChange = new EventEmitter<string>();

  constructor() {}

  ngAfterViewInit(): void {
    const colorPicker = new (iro.ColorPicker as any)(
      this.picker.nativeElement,
      {
        width: 160,
        color: this.initialColor,
      }
    );

    colorPicker.on('color:change', (color: iro.Color) => {
      this.colorChange.emit(color.hexString);
    });
  }
}
