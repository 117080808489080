<div class="main">
  <div class="empty" *ngIf="_libraryList?.items?.length === 0">
    <p class="content">구독한 라이브러리가 없습니다.</p>
  </div>
  <article
    *ngFor="let library of _libraryList?.items"
    (click)="_onClickLibrary(library.id, library.custom_domains)"
  >
    <h4 class="title">{{ library.title }}</h4>
    <div class="content">
      <p class="description">{{ library.description }}</p>
      <div class="img-container">
        <img src="{{ library.logo_image_url }}" alt="" />
      </div>
    </div>
    <span class="cp-name">{{ library.cp_name }}</span>
    <span class="books-count">전체 {{ library.book_count }}권</span>
  </article>
</div>
<div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
  <sui-paginator
    [itemCountPerPage]="_itemCountPerPage"
    [totalItemCount]="_totalItemCount"
    [page]="_currentPage"
    (pageChange)="_onPageChange($event)"
  ></sui-paginator>
</div>
<div class="loader" *ngIf="_isLoading">
  <sui-loader></sui-loader>
</div>
