import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-commentator-avatar',
  templateUrl: './commentator-avatar.component.html',
  styleUrl: './commentator-avatar.component.scss',
})
export class CommentatorAvatarComponent {
  @Input() avatarImageURL!: string;
}
