<header class="header">
  <sui-icon
    name="원형 이동_왼쪽"
    class="back-button"
    (click)="_routePreviousPage()"
  ></sui-icon>
  <div class="search-bar">
    <sui-icon name="검색"></sui-icon>
    <input
      #searchInput
      type="text"
      placeholder="검색어를 입력해주세요."
      [(ngModel)]="_inputValue"
      (input)="onInputChange($event)"
      (keyup.enter)="_searchBook()"
    />
    <div
      class="cancel-container"
      (click)="_clearSearchValue()"
      *ngIf="!_isEmptyValue"
    >
      <button class="cancel-button">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99999 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 7.99999 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 7.99999 16ZM8.04193 7.29066L10.6607 4.67188L11.3678 5.37898L8.74903 7.99777L11.3708 10.6196L10.6637 11.3267L8.04193 8.70487L5.37821 11.3686L4.6711 10.6615L7.33482 7.99777L4.67411 5.33705L5.38121 4.62995L8.04193 7.29066Z"
            fill="#CCCCCC"
          />
        </svg>
      </button>
    </div>
  </div>
</header>
<div class="page">
  <div
    class="empty"
    *ngIf="_searchResultBook.length === 0 && _inputValue === '' && !_isLoading"
  >
    검색어를 입력해주세요.
  </div>
  <div
    class="empty"
    *ngIf="_searchResultBook.length === 0 && _inputValue !== '' && !_isLoading"
  >
    검색 결과가 없습니다.
  </div>
  <div class="book-list" *ngIf="_searchResultBook.length !== 0">
    <ul>
      <sui-book
        *ngFor="let book of _searchResultBook; index as i"
        (click)="onBookClick(i)"
      >
        <img sui-book-cover [src]="book.cover" />
        <sui-book-title>{{ book.title }}</sui-book-title>
        <sui-book-author>{{ book.author }}</sui-book-author>
        <button
          *ngIf="book.has_subcontent"
          (click)="_onClickAppendix($event, book.bid)"
          class="sui-book--desktop appendix-button"
        >
          <sui-icon name="부록"></sui-icon>
          <span>부록보기</span>
        </button>
        <!-- <sui-book-tail-tag *ngIf="book.can_submit"
          >독서기록 제출 대기 중</sui-book-tail-tag
        > -->
        <sui-book-tag *ngIf="book.type === 'ticket'" theme="yellowgreen"
          ><sui-icon name="북티켓" type="solid"></sui-icon>북티켓</sui-book-tag
        >
        <sui-book-tag *ngIf="book.is_commentarybook" theme="green"
          ><sui-icon name="코멘터리"></sui-icon>코멘터리북</sui-book-tag
        >
      </sui-book>
    </ul>
    <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
      <sui-paginator
        [itemCountPerPage]="_itemCountPerPage"
        [totalItemCount]="_totalItemCount"
        [page]="_currentPage"
        (pageChange)="_onPageChange($event)"
      ></sui-paginator>
    </div>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
</div>
