export function isViewerEmbedded(): boolean {
  return self !== top;
}

export function isShortcutKeyboardEvent(event: KeyboardEvent): boolean {
  return navigator.platform.indexOf('Mac') !== -1
    ? event.metaKey
    : event.ctrlKey;
}

export function createPermissionAnchor(): string {
  return `1.${Math.random().toFixed(7).split('.')[1]}`;
}

export function getISO639CodeFromBookLanguage(
  language: string
): string | undefined {
  if (!language) {
    return undefined;
  }

  return language.split('-')[0];
}

export function removeReplacementCharacter(text: string): string {
  return text.replace(/\uFFFD/g, '');
}
