import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { BookType, parseBukURL } from '@bukio/viewer';

import { APIError } from 'shared/services';
import { ToastService } from 'shared/ui';

import { Book } from '../../models/book.model';
import { BookmarksService } from '../../services/bookmarks.service';

import { AnnotationListComponent } from '../annotation-list/annotation-list.component';

import { Bookmark } from '../../models/bookmark.model';

export interface AnnotationView extends Bookmark {
  thumbnail?: string;
  page?: number;
}
@Component({
  selector: 'viewer-bookmark-list',
  templateUrl: './bookmark-list.component.html',
  styleUrls: [
    '../annotation-list/annotation-list.component.scss',
    './bookmark-list.component.scss',
  ],
})
export class BookmarkListComponent extends AnnotationListComponent<AnnotationView> {
  @Input() book!: Book;

  constructor(
    private _toastService: ToastService,
    private _bookmarksService: BookmarksService
  ) {
    super();
  }

  public override loadItems(): void {
    this._items = this._bookmarksService.bookmarks.map((bookmark) => {
      const address = parseBukURL(bookmark.url);

      return {
        ...bookmark,
        selected: false,
        page:
          this.book.meta.type === BookType.PDF
            ? this.book.getIndexOfItem(address.iid) + 1
            : undefined,
        thumbnail: this.book.getItem(address.iid)?.thumbnail,
        updatedDateTime: new Date(bookmark.createdDate).getTime(),
      };
    });

    this._sortItems();
  }

  _onDeleteButtonClick(bookmark: AnnotationView): void {
    this._deleteItems([bookmark.id]);
  }

  _onDeleteSelectedItemsButtonClick(): void {
    const ids = this._getSelectedItems().map((item) => item.id);
    this._deleteItems(ids);
  }

  _onItemClick(item: AnnotationView): void {
    this.itemClicked.emit(item.url);
  }

  private _deleteItems(ids: number[]): void {
    this._bookmarksService.removeById(ids).subscribe({
      next: () => {
        this.loadItems();
        this._toastService.open('북마크가 삭제되었습니다.');
      },
      error: (error) => {
        if (error instanceof HttpErrorResponse) {
          APIError.createGeneralErrorMessage(error.status);
        }
      },
    });
  }
}
