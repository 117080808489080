import { Component } from '@angular/core';

import { APIError } from 'shared/services';

import { ToastService, TypedDialog } from 'shared/ui';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';

@Component({
  selector: 'viewer-memo-copy-dialog',
  templateUrl: './memo-copy-dialog.component.html',
  styleUrl: './memo-copy-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MemoCopyDialogComponent extends TypedDialog<
  {
    text: string;
    content: string;
    author: string;
    memoId: string;
  },
  boolean
> {
  public readonly _now = new Date();

  constructor(
    private _toastService: ToastService,
    private _annotationsService: AnnotationsV2Service
  ) {
    super();
  }

  _onCloseButtonClick(): void {
    this._close(false);
  }

  private _close(copied: boolean): void {
    this._dialogRef.close(copied);
  }

  _onCopyButtonClick(): void {
    this._annotationsService.copyMemo(this._data.memoId).subscribe(
      () => {
        // this._eventBusService.fire('MemoCopyDialogComponent:copied', {
        //   id: this._data.memoId,
        // });

        this._toastService.open('내 메모로 복사가 완료되었습니다.');
        this._close(true);
      },
      (error: APIError) => {
        this._toastService.openWarning(error.message);
      }
    );
  }
}
