import { HttpErrorResponse } from '@angular/common/http';

import { Observable, OperatorFunction, catchError, throwError } from 'rxjs';

export class APIError {
  constructor(
    public code: number,
    public message: string,
    public rawMessage: string
  ) {}

  static create(
    errorOrResponse: HttpErrorResponse | Error,
    messageMap?: { [serverMessage: string]: string }
  ): APIError {
    if (errorOrResponse instanceof HttpErrorResponse) {
      const serverMessage = errorOrResponse.error?.error?.message;
      const message =
        messageMap?.[serverMessage] ??
        APIError.createGeneralErrorMessage(errorOrResponse.status);

      return new APIError(
        errorOrResponse.status,
        message,
        serverMessage ?? errorOrResponse.message
      );
    } else {
      return new APIError(-1, '알 수 없는 오류가 발생하였습니다.', 'Unknown');
    }
  }

  static createGeneralErrorMessage(code: number): string {
    return `오류가 발생하였습니다. 잠시 후 다시 시도해주세요. (${code})`;
  }
}

export function catchAndRethrowAPIError<T>(messageMap?: {
  [serverMessage: string]: string;
}): OperatorFunction<T, T> {
  return catchError<T, Observable<never>>((error) =>
    throwError(() => APIError.create(error, messageMap))
  );
}
