export function getBaseURI(): string {
  let baseURI = document.baseURI;

  if (!baseURI) {
    const baseEls = document.getElementsByTagName('base');
    for (let i = 0; i < baseEls.length; i++) {
      if (!!baseEls[i].href) {
        baseURI = baseEls[i].href;
        break;
      }
    }
  }

  return baseURI || document.documentURI;
}
