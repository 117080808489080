import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { BookFeatures } from 'shared/models';

import { EventBusService } from './event-bus.service';
import { BookFeaturesAPIService } from 'shared/services';

const FALLBACK_VALUE = {
  text_selection: false,
  group_reading: false,
  text_search: false,
};

@Injectable({
  providedIn: 'root',
})
export class BookFeaturesStoreService {
  private readonly _features$ = new BehaviorSubject<BookFeatures>(
    FALLBACK_VALUE
  );
  readonly features$ = this._features$.asObservable();

  constructor(
    private _apiService: BookFeaturesAPIService,
    private _eventBusService: EventBusService
  ) {
    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(
        switchMap(({ book }) => {
          return this._apiService
            .get(book.meta.bid)
            .pipe(catchError(() => of(FALLBACK_VALUE)));
        })
      )
      .subscribe((features) => {
        this._features$.next(features);
      });
  }

  get features(): BookFeatures {
    return this._features$.getValue();
  }
}
