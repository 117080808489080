import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Input,
  Inject,
} from '@angular/core';
import { Subject } from 'rxjs';
import scrollIntoView from 'scroll-into-view-if-needed';
import { createBukURL, TOCItem } from '@bukio/viewer';

import { SharedUserService } from 'shared/services';
import { User } from 'shared/models';
import { TypedDialog } from 'shared/ui';

import { Book } from '../../models/book.model';
import {
  VIEWER_ENVIRONMENT,
  ViewerModuleEnvironment,
} from '../../viewer.module';

import { hasBookInfoPage } from '../../utils/book';

type TOCView = TOCItem & { locked: boolean; shared: boolean };

// function getBookSaleType(book: Book): string {
//   if (book?.isOwnedByUser || book?.meta.type === BookType.Wiki) {
//     return 'owned';
//   } else if (book && !book.isOnSale) {
//     return 'not-sale';
//   } else if (
//     Object.values(book.meta.pricing)
//       .filter((pricing) => pricing.published)
//       .every((pricing) => pricing.discounted === 0)
//   ) {
//     return 'free';
//   } else {
//     return 'sale';
//   }
// }

@Component({
  selector: 'viewer-book-info-dialog',
  templateUrl: './book-info-dialog.component.html',
  styleUrls: ['./book-info-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mobile-full-height',
  },
})
export class BookInfoDialogComponent
  extends TypedDialog<{ book: Book; currentTOCIndex: number }, { url: string }>
  implements OnInit, OnDestroy
{
  @ViewChild('scrollContainer')
  private _scrollContainerElem?: ElementRef<HTMLDivElement>;

  private unsubscriber = new Subject<void>();

  private _user?: User | null;

  public _toc: TOCView[] = [];

  // public _dashboardURL?: string;

  public _hasTextToc = false;
  public _hasBookInfoPage = false;

  public _isApp: boolean;

  constructor(
    private userService: SharedUserService,
    @Inject(VIEWER_ENVIRONMENT) private _environment: ViewerModuleEnvironment
  ) {
    super();
    this._isApp = _environment.isApp;
  }

  ngOnInit(): void {
    this._dialogRef.afterOpened().subscribe(() => {
      this._scrollToCurrentTOCItem();
    });

    this._user = this.userService.getUser();

    this._toc = this._data.book.toc.map((item) => {
      const iid = item.address.split('/')[0];
      return {
        ...item,
        locked: !this._data.book.canReadItem(iid, true),
        shared: false,
      };
    });

    this._hasTextToc = this._toc.some(
      (tocItem) => !/^\d+$/.test(tocItem.title)
    );

    this._hasBookInfoPage = hasBookInfoPage(this._data.book.meta.bid);

    // this._dashboardURL = this.getDashboardURL();
    // this._bookSaleType = getBookSaleType(this._data.book);
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  _onTOCItemClick(item: TOCView): void {
    // this.$.fire('track.event', {
    //   action: 'book-info-dialog',
    //   label: this._book?.meta.type === BookType.PDF ? 'thumbnail' : 'toc',
    // });

    if (item.shared) {
      // this.eventBusService.fire('BookInfoDialogComponent:tocItemClick', {
      //   url: this._book!.permission.referer!.toString(),
      // });
    } else if (item.locked) {
      // this.eventBusService.fire('BookInfoDialogComponent:lockedTocItemClick');
    } else {
      this._dialogRef.close({
        url: createBukURL(this._data.book.meta.bid) + '/' + item.address,
      });
    }
  }

  _onCoverClick(): void {
    // this.$.fire('track.event', {
    //   action: 'book-info-dialog',
    //   label: 'cover',
    // });

    this._dialogRef.close({
      url: createBukURL(this._data.book.meta.bid, this._data.book.items[0].iid),
    });
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  _onBookInfoButtonClick(): void {
    window.open('/@' + this._data.book.meta.bid, '_blank');
  }

  private _scrollToCurrentTOCItem(): void {
    const activeElem = this._scrollContainerElem?.nativeElement.querySelector(
      '.toc-item.is-active'
    );

    activeElem &&
      scrollIntoView(activeElem, {
        block: 'center',
        scrollMode: 'if-needed',
        boundary: this._scrollContainerElem!.nativeElement,
      });
  }

  getDashboardURL(): string | undefined {
    let result;

    if (this._user?.publishers && this._data.book?.meta.publisher) {
      this._user.publishers.forEach((publisher) => {
        if (publisher.id === this._data.book!.meta.publisher?.id) {
          result = `${this._environment.dashboardOrigin}/cp/books/${
            this._data.book!.meta.bid
          }`;
        }
      });
    }

    return result;
  }
}
