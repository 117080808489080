import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { Address } from '@bukio/viewer';

import { AlertService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { BookService } from '../../services/book.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

import { PageSliderBaseComponent } from '../page-slider-base/page-slider-base.component';
import { PreviewEndDialogComponent } from '../../dialogs/preview-end-dialog/preview-end-dialog.component';

@Component({
  selector: 'viewer-buk-page-slider',
  templateUrl: './buk-page-slider.component.html',
  styleUrls: [
    '../page-slider-base/page-slider-base.component.scss',
    './buk-page-slider.component.scss',
  ],
})
export class BukPageSliderComponent
  extends PageSliderBaseComponent
  implements OnDestroy, AfterViewInit, OnInit
{
  @ViewChild('barContainer')
  protected override _barContainerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;

  private _currentValue = 0;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private _eventBusService: EventBusService,
    private _bookService: BookService,
    private _alertService: AlertService
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this._eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._value = this._bookService.book!.getPagePercent(
          data.address.iid ?? this._bookService.book!.items[0].iid,
          data.page,
          data.pageCount
        );
        this._currentValue = this._value;
      });
  }

  ngAfterViewInit(): void {
    this._setEventListeners();
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onPageInputBlur(value: string): void {
    if (!this._bookService.book) {
      return;
    }

    let _value = parseInt(value);

    if (isNaN(_value)) {
      _value = this._currentValue;
    } else if (_value < 0) {
      _value = 0;
    } else if (_value > 100) {
      _value = 100;
    }

    const address = this._bookService.book.getAddressFromPagePercent(
      _value / 100
    );

    if (!address) {
      this._inputElement.nativeElement.value = (
        this._currentValue * 100
      ).toFixed(0);
      return;
    }

    this._inputElement.nativeElement.value = _value.toString();

    if (
      !this._requestPageChange(
        address,
        CrossBrowsing.keyboardChangesViewport ? 500 : 0
      )
    ) {
      this._inputElement.nativeElement.value = (
        this._currentValue * 100
      ).toFixed(0);
    }
  }

  protected override _onDragStart(): void {
    // nothing to do
  }

  protected override _onDragEnd(newPercent: number): void {
    if (!this._bookService.book) {
      return;
    }

    const address =
      this._bookService.book.getAddressFromPagePercent(newPercent);

    if (!address) {
      this._value = this._currentValue;
      return;
    }

    if (!this._requestPageChange(address)) {
      this._value = this._currentValue;
    }
  }

  private _requestPageChange(address: Address, delay: number = 0): boolean {
    if (!this._bookService.book!.canReadItem(address.iid, false)) {
      this._alertService.open(PreviewEndDialogComponent, {
        data: {
          book: this._bookService.book!,
        },
      });

      return false;
    }

    window.setTimeout(() => {
      this._eventBusService.fire('BukPageSliderComponent:pageChange', {
        url: address.toString(),
      });
    }, delay);

    return true;
  }
}
