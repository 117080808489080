import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { Note } from 'shared/models';
import {
  APIError,
  NotesAPIService,
  OthersBookshelfOpenerService,
} from 'shared/services';
import { AlertService, MenuPositionBottomLeft, ToastService } from 'shared/ui';

enum Tab {
  Inbox,
  Sent,
}

@Component({
  selector: 'lib-note-list',
  templateUrl: './note-list.component.html',
  styleUrl: './note-list.component.scss',
})
export class NoteListComponent implements OnInit {
  @ViewChild('listElem') private _listElem?: ElementRef<HTMLDivElement>;

  @Output() responseMenuClick = new EventEmitter<{
    userId: string;
    nickname: string;
  }>();

  public _inboxNotes?: Note[];
  public _inboxTotal?: number;

  public _sentNotes?: Note[];
  public _sentTotal?: number;

  public _selectedNoteIndex = 0;

  private _isLoading = false;

  public readonly _moreMenuPosition = MenuPositionBottomLeft;

  constructor(
    private _notesAPIService: NotesAPIService,
    private _toastService: ToastService,
    private _alertService: AlertService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService
  ) {}

  public readonly _Tab = Tab;
  public _currentTab = Tab.Inbox;

  get _notes(): Note[] | undefined {
    return this._currentTab === Tab.Inbox ? this._inboxNotes : this._sentNotes;
  }

  get _notesTotal(): number | undefined {
    return this._currentTab === Tab.Inbox ? this._inboxTotal : this._sentTotal;
  }

  ngOnInit(): void {
    this._getInbox(true);
    this._getSent(true);
  }

  private _getInbox(reset: boolean): void {
    this._isLoading = true;

    this._notesAPIService
      .getInbox(reset ? 0 : this._inboxNotes?.length ?? 0, 30)
      .subscribe((response) => {
        this._isLoading = false;
        this._inboxNotes = (reset ? [] : this._inboxNotes ?? []).concat(
          response.items
        );
        this._inboxTotal = response.total;
      });
  }

  private _getSent(reset: boolean): void {
    this._isLoading = true;

    this._notesAPIService
      .getSent(reset ? 0 : this._sentNotes?.length ?? 0, 30)
      .subscribe((response) => {
        this._isLoading = false;
        this._sentNotes = (reset ? [] : this._sentNotes ?? []).concat(
          response.items
        );
        this._sentTotal = response.total;
      });
  }

  refresh(): void {
    this._getInbox(true);
    this._getSent(true);

    if (this._listElem) {
      this._listElem.nativeElement.scrollTop = 0;
    }
  }

  _onScrollEnd(): void {
    if (this._isLoading) {
      return;
    }

    if (this._notes && this._notes.length === this._notesTotal) {
      return;
    }

    this._currentTab === Tab.Inbox
      ? this._getInbox(false)
      : this._getSent(false);
  }

  _onSelectTab(tab: Tab): void {
    if (this._currentTab === tab) {
      return;
    }

    this._currentTab = tab;
    this._selectedNoteIndex = 0;

    if (this._listElem) {
      this._listElem.nativeElement.scrollTop = 0;
    }
  }

  _onResponseMenuClick(): void {
    const note = this._notes?.[this._selectedNoteIndex];

    if (!note) {
      return;
    }

    this.responseMenuClick.emit({
      userId: note.user.id,
      nickname: note.user.nickname,
    });
  }

  _onDeleteMenuClick(): void {
    const note = this._notes?.[this._selectedNoteIndex];

    if (!note) {
      return;
    }

    this._alertService
      .openConfirm(undefined, '쪽지를 삭제하시겠습니까?', '삭제하기')
      .afterClosed()
      .pipe(
        filter((ok) => !!ok),
        switchMap(() => {
          return this._notesAPIService.delete(note.id);
        })
      )
      .subscribe({
        next: () => {
          this._sentNotes = this._sentNotes?.filter((n) => n.id !== note.id);
          this._inboxNotes = this._inboxNotes?.filter((n) => n.id !== note.id);
          this._sentTotal = this._sentNotes?.length;
          this._inboxTotal = this._inboxNotes?.length;

          if (!this._notes?.[this._selectedNoteIndex]) {
            this._selectedNoteIndex = 0;
          }
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onUserClick(): void {
    const note = this._notes?.[this._selectedNoteIndex];

    if (!note) {
      return;
    }

    this._othersBookshelfOpenerService.open(note.user.id);
  }
}
