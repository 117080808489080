<div class="header">
  <div class="title">
    <ng-container *ngIf="_activePanel">{{
      _panels[_activePanel]
    }}</ng-container>
    <button sui-icon-button (click)="_onCloseButtonClick()">
      <sui-icon name="엑스"></sui-icon>
    </button>
  </div>
</div>
<div class="panel" [class.is-active]="_activePanel === 'itemMemo'">
  <viewer-item-memo-list></viewer-item-memo-list>
</div>
<div class="panel" [class.is-active]="_activePanel === 'annotations'">
  <viewer-annotations></viewer-annotations>
</div>
<div class="panel" [class.is-active]="_activePanel === 'groupMemo'">
  <viewer-reading-group-memo-list></viewer-reading-group-memo-list>
</div>
