import { Component, ViewChild } from '@angular/core';
import { AlertService, TypedDialog } from 'shared/ui';
import { NoteListComponent } from '../../components/note-list/note-list.component';

enum Page {
  List,
  Send,
}

@Component({
  selector: 'lib-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrl: './notes-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class NotesDialogComponent extends TypedDialog<void, void> {
  @ViewChild(NoteListComponent) private _noteListComp!: NoteListComponent;

  public _Page = Page;
  public _currentPage = Page.List;

  public _sendTargetUserId?: string;
  public _sendTargetUserNickname?: string;

  constructor(private _alertService: AlertService) {
    super();
  }

  _onResponseMenuClick(event: { userId: string; nickname: string }): void {
    this._sendTargetUserId = event.userId;
    this._sendTargetUserNickname = event.nickname;
    this._currentPage = Page.Send;
  }

  _onBackButtonClick(): void {
    this._alertService
      .openConfirm(undefined, '쪽지 보내기를 취소하시겠습니까?', '취소하기')
      .afterClosed()
      .subscribe((ok) => {
        if (ok) {
          this._currentPage = Page.List;
        }
      });
  }

  _onCloseButtonClick(): void {
    if (this._currentPage === Page.Send) {
      this._alertService
        .openConfirm(undefined, '쪽지 보내기를 취소하시겠습니까?', '취소하기')
        .afterClosed()
        .subscribe((ok) => {
          if (ok) {
            this._dialogRef.close();
          }
        });
    } else {
      this._dialogRef.close();
    }
  }

  _onNoteSent(): void {
    this._noteListComp.refresh();
    this._currentPage = Page.List;
  }
}
