import {
  Address,
  BookItem as ViewerBookItem,
  BookMeta as ViewerBookMeta,
  BukJSON as ViewerBukJSON,
  parseBukURL,
} from '@bukio/viewer';

import {
  isBookOnSale,
  isBookOwnedByUser,
  isHorizontalPDFBook,
  calculatePPageFromPage,
} from '../utils/book';

import { roundTo } from 'shared/utils';

export type PermissionType =
  | 'admin'
  | 'library'
  | 'sample'
  | 'subscribe'
  | 'temp' // 코드 구독
  | 'ecobag';

export interface SeriesItem {
  bid: string;
  cover: string;
  title: string;
  author: string;
}

export interface SeriesInfo {
  seires: {
    count: number;
    eid: string;
    title: string;
  };
  next?: SeriesItem;
  prev?: SeriesItem;
}

export interface BookPricing {
  published?: number; // 1 or 0
  period?: string;
  price: number;
  discounted: number;
}

export interface Commentary {
  id: number;
  title: string;
  author: string;
  pricing: {
    type: 'buy' | 'rent';
    period?: string;
    sale_price: number;
    regular_price: number;
    purchased: number; // boolean
  }[];
  owned: number; // boolean
  read: number;
  published_at: string;
}

export interface BookMeta extends ViewerBookMeta {
  is_subcontent: number; // boolean
  category?: {
    name: string;
    id: string;
  }[];

  isbn: string | null;

  publisher?: {
    id: number;
    code: string;
    name: string;
    logo: string;
    currency: string;
    country: string;
    sharePage: number;
  };
  // price: {
  //   price?: number;
  //   discounted?: number;
  //   published: boolean;
  //   url?: string;
  //   site?: string;
  //   deferred?: boolean;
  //   owned?: boolean;
  //   disabledMethods?: string[];
  //   currency?: any;
  // };
  paperlink: {
    site: string;
    url: string;
  }[];

  /* 이 책이 포함된 구독중인 라이브러리 */
  libraries?: { eid: string; linkeid: string }[];
  ecobag?: { eid: string; title: string; logo: string; admin_email: string };

  /* 새로운 가격 필드 */
  pricing: {
    buy: BookPricing;
    rent: BookPricing;
    rent2: BookPricing;
    paper: BookPricing;
  };

  commentaries: Commentary[];

  /* 책 추가 정보 */
  book_info: Partial<{
    book_intro: string;
    author_intro: string;
    toc: string;
    publisher_review: string;
    in_book: string;
  }>;

  paper_info: Partial<{
    published_at: string;
    page: string;
    size: string;
    isbn: string;
  }>;

  // 판매시 추가 제공되는 콘텐츠
  additional_contents: {
    order_type: 'paper' | 'buy' | 'rent'; // 판매유형
    title: string; // '번역서 전자책', '슬라이드 요약본'
    description: string; // 설명
    period?: string; // 제공 기간

    // 콘텐츠 정보
    content: {
      id: string; // ba1001, la1234
      type: string; // book, library, video, ...
      title: string; // 독도북, 책 제목, 라이브러리 이름
      description: string; // 신용하 , 저자 또는 라이브러리 설명
      image_url: string; // 책표지, 라이브러리 로고
    };
  }[];

  // 부록
  subcontents: {
    title: string; // '번역서 전자책', '슬라이드 요약본'
    description: string; // 어쩌고저쩌고 요약해놓음

    // 콘텐츠 정보
    content: {
      id: string; // ba1001, la1234
      type: string; // book, library, video, ...
      title: string; // 독도북, 책 제목, 라이브러리 이름
      description: string; // 신용하 , 저자 또는 라이브러리 설명
      image_url: string; // 책표지, 라이브러리 로고
    };
  }[];

  referral?: {
    id: string;
    code: string;
    department: string;
    professor: string;
    school: string;
    subject: string;
  };
}

export interface BookItem extends ViewerBookItem {
  page?: number;
}

export interface BukJSON extends ViewerBukJSON {
  meta: BookMeta;
  items: BookItem[];
  permission: {
    purchased_at: string | null;
    read: string[];
    share: string[];
    type: PermissionType;
    date: null | string;

    // 서버에서 주지 않는 값
    referer?: string;
    code?: number;
  };
}

export class Book implements BukJSON {
  public readonly meta: BukJSON['meta'];
  public readonly items: BukJSON['items'];
  public readonly toc: BukJSON['toc'];
  public readonly page_list: BukJSON['page_list'];
  public readonly permission: BukJSON['permission'];

  public readonly isHorizontalPDF: boolean;
  public readonly isOnSale: boolean;
  public readonly isOwnedByUser: boolean;

  public readonly permissionReferer?: Address;

  public readonly totalPageCount: number;
  public readonly subtotalPageCounts: number[];

  constructor(bukJSON: BukJSON) {
    this.meta = bukJSON.meta;
    this.items = bukJSON.items;
    this.toc = bukJSON.toc;
    this.page_list = bukJSON.page_list;
    this.permission = bukJSON.permission;

    if (this.permission.referer) {
      this.permissionReferer = parseBukURL(this.permission.referer);
    }

    this.isHorizontalPDF = isHorizontalPDFBook(bukJSON);
    this.isOnSale = isBookOnSale(bukJSON);
    this.isOwnedByUser = isBookOwnedByUser(bukJSON);

    this.totalPageCount = this.items.reduce(
      (total, item) => total + (item.page ?? 0),
      0
    );

    this.subtotalPageCounts = [];

    this.items.forEach((item) => {
      this.subtotalPageCounts.push(
        (this.subtotalPageCounts[this.subtotalPageCounts.length - 1] ?? 0) +
          (item.page ?? 0)
      );
    });
  }

  getItem(iid: string = ''): BookItem | undefined {
    return this.items.find((item) => item.iid === iid);
  }

  getIndexOfItem(iid: string = ''): number {
    return this.items.findIndex((item) => item.iid === iid);
  }

  getNextItem(baseIid: string = ''): BookItem | undefined {
    return this._getNextOrPrevItem(baseIid, 1);
  }

  getPrevItem(baseIid: string = ''): BookItem | undefined {
    return this._getNextOrPrevItem(baseIid, -1);
  }

  isSharedItem(iid: string = ''): boolean {
    if (!this.permissionReferer) {
      return false;
    }

    return (this.permissionReferer.iid ?? '') === iid;
  }

  canReadItem(iid: string = '', includePermissionItem?: boolean): boolean {
    let result = this.permission.read.indexOf(iid) === -1;

    if (includePermissionItem) {
      result = result || !!this.getItem(iid)?.url;
    }

    return result;
  }

  canShareItem(iid: string = ''): boolean {
    return this.permission.share.indexOf(iid) === -1;
  }

  private _getNextOrPrevItem(
    baseIid: string,
    direction: 1 | -1
  ): BookItem | undefined {
    return this.items[this.getIndexOfItem(baseIid) + direction];
  }

  getPagePercent(
    iid: string,
    pageInItem: number,
    pageCountInItem: number
  ): number {
    const itemIndex = this.getIndexOfItem(iid);

    const startPercent =
      (this.subtotalPageCounts[itemIndex - 1] ?? 0) / this.totalPageCount;

    const itemPercent = (this.items[itemIndex].page ?? 0) / this.totalPageCount;

    return roundTo(
      4,
      startPercent + (itemPercent / pageCountInItem) * pageInItem
    );
  }

  getAddressFromPagePercent(percent: number): Address | null {
    const page = Math.max(1, Math.round(this.totalPageCount * percent));

    let itemIndex = -1;

    for (let i = 0; i < this.items.length; i++) {
      if (
        (this.subtotalPageCounts[i - 1] ?? 0) < page &&
        this.subtotalPageCounts[i] >= page
      ) {
        itemIndex = i;
        break;
      }
    }

    if (itemIndex === -1) {
      return null;
    }

    const item = this.items[itemIndex];
    const pageInItem = page - (this.subtotalPageCounts[itemIndex - 1] ?? 0);
    const pPage = calculatePPageFromPage(pageInItem, item.page ?? 0);

    return new Address(
      this.meta.bid,
      item.iid,
      pPage === 0 ? undefined : pPage
    );
  }
}
