import { Directive, HostListener, ElementRef } from '@angular/core';

import { AnalyticsService } from '../services/analytics.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[vtid]',
})
export class VtidDirective {
  constructor(
    private _element: ElementRef,
    private _analyticsService: AnalyticsService
  ) {}

  @HostListener('click') onClick(): void {
    const buttonTrackingId = this._element.nativeElement.getAttribute('vtid');
    if (buttonTrackingId && buttonTrackingId.trim().length > 0) {
      this._analyticsService.trackEvent('button', buttonTrackingId);
    }
  }
}
