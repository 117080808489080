<div class="dot large"></div>
<div class="slider-box">
  <div class="slider">
    <input
      class="viewer-pen-width-slider"
      type="range"
      step="1"
      min="2"
      max="14"
      [(ngModel)]="width"
      (change)="widthChange.emit(width)"
    />
    <div class="handle" [style.bottom.px]="7 * (width - 2)">
      <svg
        width="24"
        height="52"
        viewBox="0 0 24 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0013 44.75L9.08464 41.25L14.918 41.25L12.0013 44.75Z"
          fill="#282828"
        />
        <circle
          cx="12"
          cy="26"
          [attr.r]="width / 2"
          transform="rotate(-90 12 26)"
          fill="#282828"
        />
        <path
          d="M11.9987 7.25L14.9154 10.75L9.08203 10.75L11.9987 7.25Z"
          fill="#282828"
        />
      </svg>
    </div>
  </div>
</div>
<div class="dot small"></div>
