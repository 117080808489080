import { Component, Input } from '@angular/core';
import { Bookshelf } from 'shared/models';

@Component({
  selector: 'lib-bookshelf-list-item',
  templateUrl: './bookshelf-list-item.component.html',
  styleUrl: './bookshelf-list-item.component.scss',
})
export class BookshelfListItemComponent {
  @Input() bookshelf!: Bookshelf;
}
