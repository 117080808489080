<viewer-sidebar-container
  #rightSidebarContainer
  class="horizontal"
  [disableClose]="true"
  (opened)="_onHorizontalSidebarOpened()"
  (closed)="_onHorizontalSidebarClosed()"
>
  <div class="sidebar-outer-wrapper">
    <viewer-sidebar-container
      #verticalSidebarContainer
      class="vertical"
      [immediate]="true"
      [disableClose]="true"
      (opened)="_onVerticalSidebarOpened()"
      (closed)="_onVerticalSidebarClosed()"
    >
      <div class="sidebar" position="top" data-mode="over">
        <viewer-header></viewer-header>
      </div>
      <div class="sidebar-outer-wrapper">
        <viewer-contents></viewer-contents>
        <viewer-page-number hide-when="invalid"></viewer-page-number>
        <viewer-pager [direction]="'left'"></viewer-pager>
        <viewer-pager [direction]="'right'"></viewer-pager>
        <viewer-fixedlayout-page-control></viewer-fixedlayout-page-control>
        <viewer-pushable-side-menu [position]="'bottom'">
          <viewer-language-selector></viewer-language-selector>
        </viewer-pushable-side-menu>
        <viewer-annotation-settings-fab></viewer-annotation-settings-fab>
        <viewer-drawing-tool-bar></viewer-drawing-tool-bar>
        <viewer-context-menu></viewer-context-menu>
        <viewer-loader [name]="'page'"></viewer-loader>
        <viewer-pushable-side-menu
          [position]="'bottom'"
          [isEnabledOn]="'mobile'"
        >
          <viewer-page-back-button></viewer-page-back-button>
        </viewer-pushable-side-menu>
      </div>
      <div class="sidebar" position="bottom" data-mode="over">
        <viewer-footer></viewer-footer>
      </div>
    </viewer-sidebar-container>
  </div>
  <div class="sidebar" position="right" data-mode="over">
    <viewer-right-panel></viewer-right-panel>
  </div>
</viewer-sidebar-container>
<viewer-pushable-side-menu [position]="'top'" [isEnabledOn]="'mobile'">
  <viewer-focused-member-popup></viewer-focused-member-popup>
</viewer-pushable-side-menu>
<viewer-guide-popup></viewer-guide-popup>
<viewer-fullscreen-loader>
  <div class="logo-loader">
    <div class="logo-b"></div>
    <div class="logo-u"></div>
    <div class="logo-k"></div>
  </div>
</viewer-fullscreen-loader>
<viewer-memo-popup></viewer-memo-popup>
<viewer-static-memo-popup></viewer-static-memo-popup>
