import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subscription, finalize } from 'rxjs';
import { MyCommentatorInfo } from 'shared/models';
import {
  APIError,
  CommentariesAPIService,
  ImageAPIService,
  UsersAPIService,
} from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

function lengthWithoutWhitespace(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.trim();
    return value.length <= max && value.length >= min
      ? null
      : { lengthWithoutWhitespace: true };
  };
}

@Component({
  selector: 'viewer-commentary-submission-dialog',
  templateUrl: './commentary-submission-dialog.component.html',
  styleUrl: './commentary-submission-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class CommentarySubmissionDialogComponent
  extends TypedDialog<
    {
      bid: string;
      myCommentatorInfo: MyCommentatorInfo;
    },
    {
      submitted: boolean;
    }
  >
  implements OnInit
{
  public _shouldCheckNickname = true;
  public _isValidNickname = false;

  public _formGroup!: FormGroup;
  public _tagFormControl = new FormControl('', [
    Validators.pattern(/^[^,]{1,20}$/),
    lengthWithoutWhitespace(1, 20),
  ]);
  public _tags: string[] = [];

  private _nicknameCheckSubscription?: Subscription;
  private _imageUploadSubscrption?: Subscription;
  public _isSubmitting = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _usersAPIService: UsersAPIService,
    private _toastService: ToastService,
    private _imageAPIService: ImageAPIService,
    private _commentariesAPIService: CommentariesAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._formGroup = this._formBuilder.group({
      bid: [this._data.bid, Validators.required],
      avatar_image_url: [
        this._data.myCommentatorInfo.profile?.avatar_image_url ?? '',
      ],
      nickname: [
        this._data.myCommentatorInfo.profile?.nickname ?? '',
        [
          Validators.required,
          lengthWithoutWhitespace(2, 20),
          (control: AbstractControl): ValidationErrors | null => {
            if (this._data.myCommentatorInfo.profile) {
              return null;
            }

            if (this._shouldCheckNickname) {
              return { notCheckedNickname: true };
            }

            return this._isValidNickname
              ? null
              : { nicknameAlreadyExists: true };
          },
        ],
      ],
      tags: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(150)]],
    });

    this._nicknameFormControl.valueChanges.subscribe(() => {
      this._nicknameCheckSubscription?.unsubscribe();
      this._shouldCheckNickname = true;
    });
  }

  get _nicknameFormControl(): FormControl {
    return this._formGroup.get('nickname') as FormControl;
  }

  get _descriptionFormControl(): FormControl {
    return this._formGroup.get('description') as FormControl;
  }

  get _tagsFormControl(): FormControl {
    return this._formGroup.get('tags') as FormControl;
  }

  _onCheckDuplicationOfNicknameButtonClick(): void {
    if (
      this._nicknameFormControl.hasError('minlength') ||
      this._nicknameFormControl.hasError('maxlength')
    ) {
      return;
    }

    this._nicknameCheckSubscription?.unsubscribe();

    this._nicknameCheckSubscription = this._usersAPIService
      .checkNicknameDuplication(this._nicknameFormControl.value.trim())
      .pipe(
        finalize(() => {
          this._nicknameCheckSubscription = undefined;
        })
      )
      .subscribe({
        next: (isDuplicated) => {
          this._shouldCheckNickname = false;
          this._isValidNickname = !isDuplicated;
          this._nicknameFormControl.updateValueAndValidity();
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close({ submitted: false });
  }

  _onAddTag(): void {
    const value = this._tagFormControl.value!.trim();

    if (this._tags.indexOf(value) !== -1) {
      this._toastService.openWarning('이미 추가된 태그입니다.');
      return;
    }

    this._tags.push(value);
    this._tagFormControl.reset('');
    this._onTagsChange();
  }

  _onRemoveTagButtonClick(index: number): void {
    this._tags.splice(index, 1);
    this._onTagsChange();
  }

  private _onTagsChange(): void {
    this._tagsFormControl.setValue(this._tags.join(','));
    this._tagsFormControl.markAsDirty();

    if (this._tags.length < 3) {
      this._tagFormControl.enable();
    } else {
      this._tagFormControl.disable();
    }
  }

  _onSelectAvatarImage(event: any): void {
    this._imageUploadSubscrption?.unsubscribe();

    this._imageUploadSubscrption = this._imageAPIService
      .upload(event.target.files)
      .pipe(
        finalize(() => {
          this._imageUploadSubscrption = undefined;
        })
      )
      .subscribe({
        next: (response) => {
          if (response.length === 0) {
            this._toastService.openWarning(
              `이미지 업로드 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요.`
            );
          } else {
            this._setAvatarImageURL(response[0].url);
          }
        },
        error: (error: HttpErrorResponse) => {
          this._toastService.openWarning(
            `이미지 업로드 중 오류가 발생하였습니다. 잠시 후 다시 시도해주세요. (${error.status})`
          );
        },
      });
  }

  _onClearAvatarImageButtonClick(): void {
    this._imageUploadSubscrption?.unsubscribe();
    this._imageUploadSubscrption = undefined;

    this._setAvatarImageURL('');
  }

  private _setAvatarImageURL(value: string): void {
    this._formGroup.patchValue({ avatar_image_url: value });
  }

  get _isUploadingImage(): boolean {
    return !!this._imageUploadSubscrption;
  }

  _onSubmitButtonClick(): void {
    this._isSubmitting = true;

    const value = this._formGroup.value;
    value.nickname = value.nickname.trim();

    this._commentariesAPIService
      .create(value)
      .pipe(
        finalize(() => {
          this._isSubmitting = false;
        })
      )
      .subscribe({
        next: () => {
          this._toastService.open('내 독서기록 제출이 완료되었습니다.');
          this._dialogRef.close({ submitted: true });
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }
}
