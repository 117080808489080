import { Component, OnInit } from '@angular/core';

import { ReadingGroup } from 'shared/models';
import { APIError, MemosAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

import { ReadingGroupsService } from '../../services/reading-groups.service';

@Component({
  selector: 'viewer-memo-export-dialog',
  templateUrl: './memo-export-dialog.component.html',
  styleUrl: './memo-export-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MemoExportDialogComponent
  extends TypedDialog<{ id: string; content: string }, void>
  implements OnInit
{
  public _currentGroupId?: number;

  public _groups: ReadingGroup[] = [];

  public _selectedGroupIds: number[] = [];

  constructor(
    private _memosAPIService: MemosAPIService,
    private _readingGroupsService: ReadingGroupsService,
    private _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this._currentGroupId = this._readingGroupsService.currentGroup?.id;
    this._groups = this._readingGroupsService.myGroups;
  }

  _isGroupSelected(groupId: number): boolean {
    return this._selectedGroupIds.indexOf(groupId) !== -1;
  }

  _onCloseButtonClick(): void {
    this._close();
  }

  _onSelectGroup(groupId: number): void {
    const selIndex = this._selectedGroupIds.indexOf(groupId);

    if (selIndex !== -1) {
      this._selectedGroupIds.splice(selIndex, 1);
    } else {
      this._selectedGroupIds.push(groupId);
    }
  }

  _onExportButtonClick(): void {
    this._memosAPIService
      .export(this._data.id, this._selectedGroupIds)
      .subscribe({
        next: () => {
          this._close();
          this._toastService.open('메모 내보내기가 완료되었습니다.');
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  private _close(): void {
    this._dialogRef.close();
  }
}
