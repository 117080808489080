import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';

const EXACT_ROUTER_MATCH_OPTION: IsActiveMatchOptions = {
  paths: 'exact',
  queryParams: 'ignored',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrl: './tab-bar.component.scss',
})
export class TabBarComponent {
  constructor(private _router: Router) {}

  get _isBookshelfPageActive(): boolean {
    return (
      this._router.isActive('/bookshelf', EXACT_ROUTER_MATCH_OPTION) ||
      this._router.isActive('/bookshelf/detail', EXACT_ROUTER_MATCH_OPTION) ||
      this._router.isActive('/search', EXACT_ROUTER_MATCH_OPTION)
    );
  }
}
