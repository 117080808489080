import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { finalize } from 'rxjs';

import {
  ReadingGroup,
  ReadingGroupJoinStatus,
  ReadingGroupMember,
  ReadingGroupType,
} from 'shared/models';
import {
  APIError,
  OthersBookshelfOpenerService,
  ReadingGroupMembersAPIService,
  ReadingGroupsAPIService,
} from 'shared/services';
import { AlertService, MenuPositionBottomLeft, ToastService } from 'shared/ui';
import { ReadingGroupPasscodeDialogComponent } from '../../dialogs/reading-group-passcode-dialog/reading-group-passcode-dialog.component';
import { ReadingGroupExitDialogComponent } from '../../dialogs/reading-group-exit-dialog/reading-group-exit-dialog.component';

enum Tab {
  description,
  joinedMembers,
  kickedMembers,
  invitation,
}

function createInvitationTextHTML(readingGroup: ReadingGroup): string {
  return `아래 링크를 통해 책을 구매한 후 ‘${readingGroup.name}’ 그룹에 가입하여 함께 책을 읽고 서로의 지식과 경험을 공유해 보세요.
<span class="link">https://buk.io/@${readingGroup.content.id}?invitedGroupId=${readingGroup.id}</span>`;
}

@Component({
  selector: 'lib-reading-group-details',
  templateUrl: './reading-group-details.component.html',
  styleUrl: './reading-group-details.component.scss',
})
export class ReadingGroupDetailsComponent implements OnInit {
  @ViewChild('copyText') private _copyTextElem?: ElementRef<HTMLDivElement>;

  @Input() group!: ReadingGroup;
  @Input() isUserLoggedIn = true;

  @Output() joinGroupButtonClicked = new EventEmitter<void>();
  @Output() editGroupButtonClick = new EventEmitter<void>();
  @Output() enterGroupButtonClick = new EventEmitter<number>();
  @Output() inviteGroupMemberButtonClick = new EventEmitter<void>();
  @Output() groupExited = new EventEmitter<void>();
  @Output() groupJoined = new EventEmitter<void>();

  public _JoinStatus = ReadingGroupJoinStatus;
  public _Tab = Tab;
  public _joinedMembers?: ReadingGroupMember[];
  public _kickedMembers?: ReadingGroupMember[];
  public _openedAccordion!: Tab;

  public _invitationText!: string;

  public _isJoining = false;

  public readonly _moreMenuPosition = MenuPositionBottomLeft;

  constructor(
    private _clipboard: Clipboard,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _readingGroupMembersAPIService: ReadingGroupMembersAPIService,
    private _alertService: AlertService,
    private _toastService: ToastService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService
  ) {}

  get _isGroupMember(): boolean {
    return (
      this.group.status === ReadingGroupJoinStatus.joined ||
      this.group.status === ReadingGroupJoinStatus.owned
    );
  }

  get _canInviteMember(): boolean {
    return (
      this.group.status === ReadingGroupJoinStatus.joined ||
      this.group.status === ReadingGroupJoinStatus.owned
    );
  }

  get _canKickMember(): boolean {
    return this.group.status === ReadingGroupJoinStatus.owned;
  }

  ngOnInit(): void {
    this._openedAccordion = this._canInviteMember
      ? Tab.invitation
      : Tab.description;

    this._invitationText = createInvitationTextHTML(this.group);
  }

  _onCopyInvitationTextButtonClick(): void {
    const text = this._copyTextElem?.nativeElement.innerText;
    text && this._clipboard.copy(text);
    this._toastService.open('복사가 완료되었습니다.');
  }

  _onOpenAccordion(tab: Tab): void {
    // if (this._selectedTab === tab) {
    //   return;
    // }

    this._openedAccordion = tab;

    if (tab === Tab.joinedMembers) {
      this._loadJoinedMembers();
    } else if (tab === Tab.kickedMembers) {
      this._loadKickedMembers();
    }
  }

  _onJoinGroupButtonClick(): void {
    this.joinGroupButtonClicked.emit();

    if (!this.isUserLoggedIn) {
      return;
    }

    if (
      this.group.type === ReadingGroupType.private &&
      this.group.status !== ReadingGroupJoinStatus.invited
    ) {
      this._openPasscodeDialog();
      return;
    }

    this._isJoining = true;

    this._readingGroupsAPIService
      .join(this.group.id)
      .pipe(finalize(() => (this._isJoining = false)))
      .subscribe({
        next: () => {
          this._onGroupJoined();
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onPasscodeDialogResult(joined: boolean): void {
    joined && this._onGroupJoined();
  }

  _onEnterGroupButtonClick(): void {
    this.enterGroupButtonClick.emit(this.group.id);
  }

  _onEditGroupButtonClick(): void {
    this.editGroupButtonClick.emit();
  }

  _onExitGroupButtonClick(): void {
    this._alertService
      .open(ReadingGroupExitDialogComponent, {
        data: {
          groupId: this.group.id,
          status: this.group.status,
        },
      })
      .afterClosed()
      .subscribe((exited) => {
        exited && this.groupExited.emit();
      });
  }

  _onKickUserButtonClick(index: number): void {
    const member = this._joinedMembers?.[index];

    if (!member) {
      return;
    }

    this._alertService
      .openConfirm(
        `${member.nickname}님을 그룹에서 내보내시겠습니까?`,
        '내보낸 그룹원은 그룹 내 공유 기록에 더 이상 접근할 수 없으며, 그룹장이 내보내기 상태를 해제하기 전까지 재가입이 불가능합니다.',
        '내보내기'
      )
      .afterClosed()
      .subscribe((ok) => {
        if (!ok) {
          return;
        }

        this._readingGroupMembersAPIService
          .kick(this.group.id, member.id)
          .subscribe({
            next: () => {
              this._loadJoinedMembers();
              this._loadKickedMembers();
            },
            error: (error: APIError) => {
              this._toastService.openWarning(error.message);
            },
          });
      });
  }

  _onUnkickUserButtonClick(index: number): void {
    const member = this._kickedMembers?.[index];

    if (!member) {
      return;
    }

    this._alertService
      .openConfirm(
        undefined,
        `${member.nickname}님을 그룹 내보내기에서 해제하시겠습니까?`
      )
      .afterClosed()
      .subscribe((ok) => {
        if (!ok) {
          return;
        }

        this._readingGroupMembersAPIService
          .unkick(this.group.id, member.id)
          .subscribe({
            next: () => {
              this._loadKickedMembers();
              this._loadJoinedMembers();
            },
            error: (error: APIError) => {
              this._toastService.openWarning(error.message);
            },
          });
      });
  }

  _onInviteGroupMemberButtonClick(): void {
    this.inviteGroupMemberButtonClick.emit();
  }

  private _loadJoinedMembers(): void {
    this._readingGroupMembersAPIService
      .getJoined(this.group.id)
      .subscribe((members) => {
        this._joinedMembers = members;
      });
  }

  private _loadKickedMembers(): void {
    this._readingGroupMembersAPIService
      .getKicked(this.group.id)
      .subscribe((members) => {
        this._kickedMembers = members;
      });
  }

  private _onGroupJoined(): void {
    this.group = {
      ...this.group,
      status: ReadingGroupJoinStatus.joined,
      member_count: this.group.member_count + 1,
    };

    this._toastService.open('가입이 완료되었습니다.');

    this.groupJoined.emit();

    if (this._openedAccordion === Tab.joinedMembers) {
      this._loadJoinedMembers();
    }
  }

  private _openPasscodeDialog(): void {
    const dialogRef = this._alertService.open(
      ReadingGroupPasscodeDialogComponent,
      {
        data: {
          groupId: this.group.id,
        },
      }
    );

    dialogRef.afterClosed().subscribe((joined) => {
      joined !== undefined && this._onPasscodeDialogResult(joined);
    });
  }

  _onReadingGroupMemberClick(member: ReadingGroupMember): void {
    if (member.is_me) {
      return;
    }

    this._othersBookshelfOpenerService.open(member.id);
  }
}
