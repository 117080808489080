import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'sui-book-tag',
  template: `<svg
      class="sui-book-tag--tail"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
    >
      <path d="M10,10L0,10L0.065,10C5.554,9.965 10,5.498 10,0L10,10Z" />
    </svg>
    <ng-content></ng-content>`,
})
export class BookTagComponent {
  @Input() theme: 'yellowgreen' | 'green' | 'gray' = 'gray';

  @HostBinding('class') get className(): string {
    return `sui-book-tag--theme-${this.theme}`;
  }
}

@Component({
  selector: 'sui-book-tail-tag',
  template: `<ng-content></ng-content>`,
})
export class BookTailTagComponent {}

@Component({
  selector: 'sui-book-title',
  template: `<ng-content></ng-content>`,
})
export class BookTitleComponent {}

@Component({
  selector: 'sui-book-author',
  template: `<ng-content></ng-content>`,
})
export class BookAuthorComponent {}

@Component({
  selector: 'sui-book-cover, [sui-book-cover]',
  template: `<ng-content></ng-content>`,
  host: {
    class: 'sui-book-cover',
  },
})
export class BookCoverComponent {}

@Component({
  selector: 'sui-book',
  templateUrl: './book.component.html',
  styleUrl: './book.component.scss',
})
export class BookComponent {
  @Input() layout?: 'desktop' | 'mobile';

  @HostBinding('class.sui-book--layout-desktop')
  get isDesktopLayout(): boolean {
    return this.layout === 'desktop';
  }

  @HostBinding('class.sui-book--layout-mobile') get isMobileLayout(): boolean {
    return this.layout === 'mobile';
  }
}
