import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, map } from 'rxjs';

import {
  InviteableReadingGroupMember,
  ReadingGroupMember,
} from 'shared/models';
import { stripUndefinedParams } from 'shared/utils';

import { catchAndRethrowAPIError } from './api-error';
import { QueryResult } from './query-result';

const KICK_MEMBER_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
  'Member not found': '해당 그룹원이 존재하지 않습니다.',
  'Already kicked': '이미 내보내진 그룹원입니다.',
};

const UNKICK_MEMBER_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
  'User not found': '해당 사용자가 존재하지 않습니다.',
  'User not kicked': '내보내진 그룹원이 아닙니다.',
};

@Injectable({
  providedIn: 'root',
})
export class ReadingGroupMembersAPIService {
  constructor(private _http: HttpClient) {}

  getJoined(groupId: number): Observable<ReadingGroupMember[]> {
    return this._http
      .get<{ items: ReadingGroupMember[] }>(
        `/api/v1/groups/${groupId}/members`,
        { withCredentials: true }
      )
      .pipe(
        map((response) => response.items),
        catchAndRethrowAPIError()
      );
  }

  getInviteableToGroup(
    groupId: number,
    offset: number = 0,
    limit: number = 30,
    query: {
      q?: string;
      target_group_id?: number;
      followings?: boolean;
    }
  ): Observable<QueryResult<InviteableReadingGroupMember>> {
    return this._http
      .get<QueryResult<InviteableReadingGroupMember>>(
        `/api/v1/groups/${groupId}/invite/members`,
        {
          params: stripUndefinedParams({
            offset,
            limit,
            ...query,
            followings:
              query.followings != null ? (query.followings ? 1 : 0) : undefined,
          }),
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError());
  }

  getKicked(groupId: number): Observable<ReadingGroupMember[]> {
    return this._http
      .get<{ items: ReadingGroupMember[] }>(
        `/api/v1/groups/${groupId}/members/kicked`,
        { withCredentials: true }
      )
      .pipe(
        map((response) => response.items),
        catchAndRethrowAPIError()
      );
  }

  kick(groupId: number, memberId: string): Observable<void> {
    return this._http
      .delete<void>(`/api/v1/groups/${groupId}/members/${memberId}`, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(KICK_MEMBER_ERROR_MSG_MAP));
  }

  unkick(groupId: number, memberId: string): Observable<void> {
    return this._http
      .post<void>(
        `/api/v1/groups/${groupId}/members/${memberId}/unkick`,
        null,
        {
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(UNKICK_MEMBER_ERROR_MSG_MAP));
  }
}
