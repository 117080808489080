<p>
  오프라인 상태입니다.<br />
  네트워크 연결 후 다시 접속해 주세요.
</p>
<div class="actions">
  <button
    class="retry-button"
    sui-solid-button
    varSize="large"
    (click)="_onRetryButtonClick()"
  >
    확인
  </button>
</div>
