<div
  class="tab-item"
  *ngFor="let tabItem of tabItems"
  [class.is-active]="selectedTab === tabItem.name"
  (click)="_onTabClick(tabItem.name)"
>
  <sui-icon
    [name]="tabItem.icon"
    [type]="tabItem.iconType ?? 'outline'"
  ></sui-icon
  >{{ tabItem.name }}
</div>
