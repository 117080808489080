export interface Reader {
  id: string;
  nickname: string;
  avatar_image_url: string;
  is_blocked: number; // boolean
}

export interface FollowableReader extends Reader {
  is_follow: number;
  is_me?: number;
}
