<sui-dialog-header class="header" (closeButtonClick)="_onCloseButtonClick()"
  >내 메모 공유하기</sui-dialog-header
>
<div class="body">
  <div class="image-container">
    <div class="image-inner" [attr.data-ratio]="_imageRatio">
      <viewer-memo-sharing-image
        class="image"
        [memo]="_data.memo"
        [author]="_data.author"
        [bookTitle]="_bookTitle"
        [coverImageURL]="_coverImageURL"
        [readingMode]="_readingMode"
        [hideCounts]="_hideCounts"
        [ratio]="_imageRatio"
      ></viewer-memo-sharing-image>
    </div>
  </div>
  <div class="controls">
    <div class="options">
      <div>
        <button
          class="image-ratio-button"
          [class.is-active]="ratio === _imageRatio"
          *ngFor="let ratio of _imageRatioList"
          (click)="_imageRatio = ratio"
        >
          <div class="ratio-rect" [attr.data-ratio]="ratio"></div>
          {{ ratio }}
        </button>
      </div>
      <sui-checkbox
        class="counts-checkbox"
        type="boxFirst"
        *ngIf="_readingMode === 'group'"
      >
        <input type="checkbox" [(ngModel)]="_hideCounts" />
        추천 및 내 메모로 복사 수 숨김</sui-checkbox
      >
    </div>
    <div class="app buttons" *ngIf="_isApp">
      <button class="share-button" (click)="_onShareToSNSButtonClick($event)">
        <sui-icon class="icon share" name="공유-1" type="solid"></sui-icon>SNS로
        공유하기
      </button>
      <button class="share-button" (click)="_onSaveButtonClick()">
        <sui-icon class="icon download" name="내려받기"></sui-icon>이미지
        저장하기
      </button>
    </div>
    <div class="web buttons" *ngIf="!_isApp">
      <button
        sui-solid-button
        varSize="medium40"
        (click)="_onSaveButtonClick()"
      >
        이미지 저장하기
      </button>
    </div>
  </div>
  <div class="loader" [class.is-active]="_isGeneratingImage">
    <sui-loader></sui-loader>
  </div>
</div>
