<sui-dialog-header (closeButtonClick)="onCloseButtonClick()"
  >메모 작성</sui-dialog-header
>
<div class="body" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div class="highlight">
    {{ _text }}
  </div>
  <div class="memo">
    <div class="input">
      <button
        sui-icon-button
        class="memo-more"
        *ngIf="!!_originalMemo"
        [cdkMenuTriggerFor]="removeMenu"
        [cdkMenuPosition]="_moreMenuPosition"
      >
        <sui-icon name="더보기"></sui-icon>
      </button>
      <textarea
        #textarea
        cdkFocusInitial
        [formControl]="_memoFormControl"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
      ></textarea>
    </div>
    <div class="input-footer">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 30V0H30V0.000371774C13.5729 0.0815255 0.251217 13.4386 0 30Z"
          fill="#F5F5F7"
        />
      </svg>
      <div class="hint">
        <span
          class="length"
          [class.is-warning]="_memoFormControl.hasError('maxlength')"
          >{{ _memoFormControl.value.length || 0 }}</span
        >/2000
      </div>
    </div>
  </div>
  <div class="buttons">
    <div class="pin-option" *ngIf="_canPinMemo">
      같은 위치의 메모들 중 해당 메모가 상단에 고정됩니다.<br />
      <sui-checkbox>
        메모 고정하기
        <input type="checkbox" [(ngModel)]="_pinMemo" />
      </sui-checkbox>
    </div>
    <button
      class="desktop"
      sui-solid-button
      varSize="medium40"
      (click)="onSaveButtonClick()"
      [disabled]="
        _memoFormControl.invalid ||
        (_canPinMemo
          ? _memoFormControl.pristine && _wasPinnedMemo === _pinMemo
          : _memoFormControl.pristine) ||
        _hasEmptyValue ||
        _isSaving
      "
    >
      저장하기
    </button>
    <button
      class="mobile circle-save-button"
      (click)="onSaveButtonClick()"
      [disabled]="
        _memoFormControl.invalid ||
        (_canPinMemo
          ? _memoFormControl.pristine && _wasPinnedMemo === _pinMemo
          : _memoFormControl.pristine) ||
        _hasEmptyValue ||
        _isSaving
      "
    >
      <sui-icon name="체크"></sui-icon>
    </button>
  </div>
</div>

<ng-template #removeMenu>
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onRemoveButtonClick()">
      메모 삭제
    </button>
  </sui-menu>
</ng-template>
