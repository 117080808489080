import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';
import {
  mergeMap,
  takeUntil,
  switchMap,
  first,
  map,
  scan,
} from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';

import { Book } from '../../models/book.model';

import { CrossBrowsing } from '../../constants/cross-browsing';

function calculatePercent(numerator: number, denominator: number): number {
  return Math.round((numerator / denominator) * 100 * 10000) / 10000;
}

function getClientXFromMouseOrTouchEvent(
  event: MouseEvent | TouchEvent
): number {
  return event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
}

interface DragState {
  elementRect: DOMRect;
  clientX: number;
}

@Component({
  selector: 'viewer-pdf-page-slider',
  templateUrl: './pdf-page-slider.component.html',
  styleUrls: ['./pdf-page-slider.component.scss'],
})
export class PDFPageSliderComponent implements OnInit, AfterViewInit {
  @ViewChild('barContainer') barContainerElement!: ElementRef<HTMLDivElement>;

  @Output() pageChange = new EventEmitter<number>();

  private unsubscriber = new Subject<void>();

  public _isDragging = false;
  public _percent = 0;
  public _crumbPercent?: number;
  public _previewPage = 1;
  public _previewThumbImageURL = '';
  public _isPreviewPageLocked = false;

  private _totalPageCount?: number;
  private _currentPage?: number;

  private _book?: Book;

  @Input()
  set totalPageCount(totalPageCount: number | undefined) {
    this._totalPageCount = totalPageCount;
    this.resetPercentToCurrentPage();
  }
  get totalPageCount(): number | undefined {
    return this._totalPageCount;
  }

  @Input()
  set currentPage(page: number | undefined) {
    this._currentPage = page;
    this.resetPercentToCurrentPage();
  }
  get currentPage(): number | undefined {
    return this._currentPage;
  }

  constructor(
    private eventBusService: EventBusService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;
      });
  }

  ngAfterViewInit(): void {
    const mousedown$ = fromEvent<MouseEvent | TouchEvent>(
      this.barContainerElement.nativeElement,
      CrossBrowsing.touchDevice ? 'touchstart' : 'mousedown'
    );

    const mousemove$ = fromEvent<MouseEvent | TouchEvent>(
      this.barContainerElement.nativeElement,
      CrossBrowsing.touchDevice ? 'touchmove' : 'mousemove'
    );

    const mouseup$ = fromEvent<MouseEvent | TouchEvent>(
      window,
      CrossBrowsing.touchDevice ? 'touchend' : 'mouseup'
    );

    const dragstart$ = mousedown$.pipe(
      map((event) => {
        return (state: DragState): DragState =>
          Object.assign({}, state, {
            elementRect: (
              event.currentTarget as HTMLElement
            ).getBoundingClientRect(),
            clientX: getClientXFromMouseOrTouchEvent(event),
          });
      })
    );

    const dragging$ = mousedown$.pipe(
      mergeMap(() => {
        return mousemove$.pipe(
          takeUntil(mouseup$),
          map((event) => {
            return (state: DragState): DragState =>
              Object.assign({}, state, {
                clientX: getClientXFromMouseOrTouchEvent(event),
              });
          })
        );
      })
    );

    merge(dragstart$, dragging$)
      .pipe(
        scan((state, changeFn) => changeFn(state), {
          elementRect: {} as any,
          clientX: 0,
        }),
        map((state) => {
          const offsetX = state.clientX - state.elementRect.left;
          return calculatePercent(offsetX, state.elementRect.width);
        })
      )
      .subscribe((newPercent) => {
        this._onDrag(newPercent);
      });

    // 실제로 움직임이 있는 drag start
    mousedown$
      .pipe(switchMap(() => mousemove$.pipe(first(), takeUntil(mouseup$))))
      .subscribe(() => {
        this._onDragStart();
      });

    mousedown$
      .pipe(switchMap(() => mouseup$.pipe(first())))
      .subscribe((event) => {
        this._onDragEnd(this._percent);
      });
  }

  resetPercentToCurrentPage(): void {
    if (this.currentPage == null || this.totalPageCount == null) {
      return;
    }

    const pageFromPercent = this._calculatePageFromPercent(this._percent);

    if (pageFromPercent !== this.currentPage) {
      this._percent = this._calculatePercentFromPage(this.currentPage);
    }
  }

  clearCrumb(): void {
    this._crumbPercent = undefined;
  }

  setCrumbToCurrentPage(): void {
    this._crumbPercent = this._calculatePercentFromPage(this.currentPage!);
  }

  _onCrumbClick(): void {
    this._percent = this._crumbPercent!;
    this.pageChange.emit(this._calculatePageFromPercent(this._crumbPercent!));
    this.clearCrumb();
  }

  private _onDragStart(): void {
    this._isDragging = true;
  }

  private _onDrag(newPercent: number): void {
    if (!this._book) {
      return;
    }

    this._percent = newPercent > 100 ? 100 : newPercent < 0 ? 0 : newPercent;
    this._previewPage = this._calculatePageFromPercent(this._percent);

    const newItem = this._book.items[this._previewPage - 1];

    this._previewThumbImageURL = newItem.thumbnail!;
    this._isPreviewPageLocked = !this._book.canReadItem(newItem.iid, true);

    this.changeDetectorRef.detectChanges();
  }

  private _onDragEnd(newPercent: number): void {
    this._isDragging = false;

    const newPage = this._calculatePageFromPercent(newPercent);

    if (newPage !== this.currentPage) {
      this._crumbPercent = this._calculatePercentFromPage(this.currentPage!);
    }

    this.pageChange.emit(newPage);
  }

  private _calculatePageFromPercent(percent: number): number {
    let page = Math.round((percent / 100) * this.totalPageCount!);

    if (page < 1) {
      page = 1;
    } else if (page > this.totalPageCount!) {
      page = this.totalPageCount!;
    }

    return page;
  }

  private _calculatePercentFromPage(page: number): number {
    return calculatePercent(page, this.totalPageCount!);
  }
}
