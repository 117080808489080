import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageAPIService {
  constructor(private _http: HttpClient) {}

  upload(imageFiles: FileList): Observable<
    {
      mimetype: string;
      originalname: string;
      size: number;
      url: string;
    }[]
  > {
    const formData: FormData = new FormData();

    for (let i = 0; i < imageFiles.length; i++) {
      formData.append('file', imageFiles[0]);
    }

    return this._http.post<
      {
        mimetype: string;
        originalname: string;
        size: number;
        url: string;
      }[]
    >(`/api/2.0/image`, formData, {
      withCredentials: true,
    });
  }
}
