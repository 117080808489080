<sui-dialog-header class="header" (closeButtonClick)="_onCloseButtonClick()"
  >내 메모로 복사하기</sui-dialog-header
>
<div class="body">
  <div class="text-container">
    <div class="title">본문 지문</div>
    <div class="text-box">
      <div class="text">
        {{ _data.text }}
      </div>
    </div>
    <div class="description">
      내 메모로 복사한 메모는 혼자읽기 모드에서도 확인할 수 있습니다.
    </div>
  </div>

  <viewer-memo [limitHeight]="true" [content]="_data.content">
    <viewer-memo-type type="mine"></viewer-memo-type>
    <viewer-memo-tag
      >{{ _now | date : 'YYYY.MM.dd. HH:mm' }} {{ _data.author }}님의 메모
      복사</viewer-memo-tag
    >
  </viewer-memo>
  <div class="actions">
    <button
      sui-solid-button
      varSize="medium40::large"
      (click)="_onCopyButtonClick()"
    >
      복사하기
    </button>
  </div>
</div>
