import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subcontent } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';
import { TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-appendix',
  templateUrl: './appendix.component.html',
  styleUrl: './appendix.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class AppendixComponent
  extends TypedDialog<{ book_id: string }, void>
  implements OnInit
{
  @ViewChild('listContainer') private listContainer?: ElementRef;

  public _subcontents?: Subcontent[];

  public _total: number = 0;
  public _currentPage: number = 0;
  public _itemCountPerPage: number = 20;
  public _isLoading: boolean = true;

  constructor(
    private _bookshelfAPIService: BookshelfAPIService,
    private _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this._bookshelfAPIService
      .getSubcontents(this._data.book_id, 0, this._itemCountPerPage)

      .subscribe((response) => {
        this._isLoading = false;

        this._total = response.total;
        this._subcontents = response.items;
      });
  }

  public _routeSubcontent(id: string): void {
    this._dialogRef.close();
    this._router.navigate(['/@' + id, 'cover']);
  }

  public _closeDialog(): void {
    this._dialogRef.close();
  }

  public _onPageChange(page: number): void {
    this._isLoading = true;
    this._bookshelfAPIService
      .getSubcontents(
        this._data.book_id,
        (page - 1) * this._itemCountPerPage,
        this._itemCountPerPage
      )
      .subscribe((response) => {
        this._isLoading = false;
        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }
        this._subcontents = response.items;
      });
  }
}
