<sui-dialog-header (closeButtonClick)="_onCloseClick()">
  뷰어 설정
</sui-dialog-header>
<div class="body">
  <div class="items">
    <div
      class="item"
      [class.disabled]="
        _currentSettings.theme.isDisabled || _isForceDisabled('theme')
      "
    >
      <div class="icon">
        <sui-icon name="테마"></sui-icon>
      </div>
      <div class="colors">
        <div
          *ngFor="let theme of _themes"
          class="color-circle theme {{ theme }}"
          [class.is-selected]="_currentSettings.theme.value === theme"
          (click)="_onChangeSetting('theme', theme)"
        >
          <sui-icon name="체크"></sui-icon>
        </div>
      </div>
    </div>
    <div
      class="item"
      [class.disabled]="
        _currentSettings.fontFace.isDisabled || _isForceDisabled('fontFace')
      "
    >
      <div class="icon">
        <sui-icon name="폰트"></sui-icon>
      </div>
      <div class="box font">
        <select
          class="font-select"
          [(ngModel)]="_currentSettings.fontFace.value"
          (ngModelChange)="
            _onChangeSetting('fontFace', _currentSettings.fontFace.value)
          "
        >
          <option [value]="'default'">기본 폰트</option>
          <option
            *ngFor="let font of _availableFonts"
            [value]="font.fontFamily"
          >
            {{ font.displayName }}
          </option>
        </select>
      </div>
    </div>
    <div
      class="item"
      [class.disabled]="
        _currentSettings.fontSize.isDisabled || _isForceDisabled('fontSize')
      "
    >
      <div class="icon">
        <sui-icon name="글자 사이즈"></sui-icon>
      </div>
      <div class="box font-size">
        <button sui-icon-button (click)="_onFontSizeButtonClick(-1)">
          <sui-icon name="마이너스"></sui-icon>
        </button>
        <button class="number" (click)="_onFontSizeButtonClick(0)">
          {{ _currentSettings.fontSize.value }}%
        </button>
        <button sui-icon-button (click)="_onFontSizeButtonClick(1)">
          <sui-icon name="플러스"></sui-icon>
        </button>
      </div>
    </div>
    <div class="item has-hint" [class.disabled]="_highlightColors.length === 0">
      <div class="icon">
        <sui-icon name="하이라이트" class="small"></sui-icon>
      </div>
      <div class="colors">
        <div
          class="color-circle highlight"
          *ngFor="let color of _highlightColors"
          [attr.style]="color.style"
          [class.is-selected]="color.isSelected"
          (click)="_onHighlightColorClick(color)"
        >
          <sui-icon name="체크"></sui-icon>
        </div>
        <div class="hint">3 - 5 개 선택</div>
      </div>
    </div>
    <div
      class="item"
      [class.disabled]="
        _currentSettings.pagingMode.isDisabled || _isForceDisabled('pagingMode')
      "
    >
      <div class="icon">
        <sui-icon name="스크롤" type="solid" class="small"></sui-icon>
      </div>
      <div class="text">스크롤 보기</div>
      <sui-slide-toggle>
        <input
          type="checkbox"
          [checked]="_currentSettings.pagingMode.value === 'scroll'"
          (change)="
            _onChangeSetting(
              'pagingMode',
              $any($event.target).checked ? 'scroll' : 'page'
            )
          "
        />
      </sui-slide-toggle>
    </div>
    <div
      class="item"
      [class.disabled]="
        _currentSettings.multiColumn.isDisabled ||
        _isForceDisabled('multiColumn')
      "
    >
      <div class="icon">
        <sui-icon name="두페이지 보기" type="solid" class="small"></sui-icon>
      </div>
      <div class="text">두 페이지 보기</div>
      <sui-slide-toggle>
        <input
          type="checkbox"
          [checked]="_currentSettings.multiColumn.value"
          (change)="_onChangeMultiColumnSetting($event)"
        />
      </sui-slide-toggle>
    </div>
    <div
      class="item"
      [class.disabled]="
        _currentSettings.pageAnimation.isDisabled ||
        _isForceDisabled('pageAnimation')
      "
    >
      <div class="icon">
        <sui-icon name="페이지 넘김 효과" type="solid" class="small"></sui-icon>
      </div>
      <div class="text">페이지 넘김 효과</div>
      <sui-slide-toggle>
        <input
          type="checkbox"
          [checked]="_currentSettings.pageAnimation.value"
          (change)="
            _onChangeSetting('pageAnimation', $any($event.target).checked)
          "
        />
      </sui-slide-toggle>
    </div>
  </div>
</div>
