import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyCommentatorInfo } from 'shared/models';

@Injectable({
  providedIn: 'root',
})
export class CommentatorsAPIService {
  constructor(private _httpClient: HttpClient) {}

  getMyInfo(): Observable<MyCommentatorInfo> {
    return this._httpClient.get<MyCommentatorInfo>('/api/v1/commentators/me', {
      withCredentials: true,
    });
  }
}
