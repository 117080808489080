import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IconType } from 'shared/ui';

export interface TabItem {
  icon: string;
  iconType?: IconType;
  name: string;
}

@Component({
  selector: 'viewer-tab',
  templateUrl: './tab.component.html',
  styleUrl: './tab.component.scss',
})
export class TabComponent {
  @Input() tabItems: TabItem[] = [];

  @Input() selectedTab!: string;
  @Output() selectedTabChange = new EventEmitter<string>();

  _onTabClick(name: string): void {
    this.selectedTab = name;
    this.selectedTabChange.emit(this.selectedTab);
  }
}
