<article class="bookshelf-item">
  <div class="header">
    <div class="inner-left">
      <sui-icon
        *ngIf="bookshelf?.is_private"
        type="solid"
        name="잠금"
      ></sui-icon>
      <h3>
        <span class="title">
          {{ bookshelf?.title }}
        </span>
        <span class="total"> ({{ bookshelf?.content_count }})</span>
      </h3>
    </div>
    <sui-icon name="화살표_오른쪽"></sui-icon>
  </div>
  <div class="content" [class.empty]="bookshelf?.books?.length === 0">
    <div class="content-inner">
      <ul *ngIf="bookshelf?.books?.length !== 0">
        <li *ngFor="let book of bookshelf?.books | slice : 0 : 6">
          <img src="{{ book.cover }}" alt="책 이미지" />
        </li>
      </ul>
      <p *ngIf="bookshelf?.books?.length === 0" class="empty-bookshelf">
        책장에 담긴 책이 없습니다.
      </p>
    </div>
  </div>
</article>
