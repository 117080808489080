import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'viewer-pen-width-slider',
  templateUrl: './pen-width-slider.component.html',
  styleUrl: './pen-width-slider.component.scss',
})
export class PenWidthSliderComponent {
  @Input() width: number = 2;
  @Output() widthChange = new EventEmitter<number>();
}
