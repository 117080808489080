import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[suiIntersectionListener]',
})
export class IntersectionListenerDirective implements AfterViewInit, OnInit {
  @Input() rootMargin = '100px';

  @Output() suiIntersectionListener = new EventEmitter<boolean>();

  observer!: IntersectionObserver;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.intersectionObserver();
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.element.nativeElement);
  }

  intersectionObserver(): void {
    const options = {
      root: this.element.nativeElement.parentElement,
      rootMargin: this.rootMargin,
      threshold: 0,
    };

    this.observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.suiIntersectionListener.emit(true);
      }
    }, options);
  }
}
