<div class="popup paging-mode" [class.is-active]="_showPageModeGuide">
  <div class="box">
    <ng-container *ngIf="_pagingMode === 'page'">
      <img class="arrow-page" src="assets/images/viewer-page-mode-arrow.svg" />
      <div class="description">
        현재 <b>가로 보기를</b> 이용 중입니다.<br />책장을 넘기듯 화면을 넘기며
        읽어보세요.
      </div>
    </ng-container>
    <ng-container *ngIf="_pagingMode === 'scroll'">
      <img
        class="arrow-scroll"
        src="assets/images/viewer-scroll-mode-arrow.svg"
      />
      <div class="description">
        현재 <b>세로 보기를</b> 이용 중입니다.<br />
        화면을 스크롤하며 편하게 읽어보세요.
      </div>
    </ng-container>
  </div>
  <div class="tail"></div>
</div>

<div class="popup tutorial" [class.is-active]="_showTutorial">
  <img
    class="tutorial-image desktop"
    src="assets/images/viewer-tutorial-desktop{{ _tutorialStep }}.jpg"
  />
  <img
    class="tutorial-image tablet"
    src="assets/images/viewer-tutorial-tablet{{ _tutorialStep }}.jpg"
  />
  <img
    class="tutorial-image phone"
    src="assets/images/viewer-tutorial-phone{{ _tutorialStep }}.jpg"
  />
  <div class="image-title">
    <div class="title-end" *ngIf="_tutorialStep === 6">
      튜토리얼을 모두 보셨습니다.<br />이제 북이오 뷰어를 이용해 보세요.
    </div>
    <div class="progress">
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 1"></div>
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 2"></div>
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 3"></div>
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 4"></div>
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 5"></div>
      <div class="progress-bar" [class.is-active]="_tutorialStep >= 6"></div>
    </div>
    <div class="title" *ngIf="_tutorialStep < 6">북이오 튜토리얼!</div>
  </div>

  <div class="controls">
    <button
      class="button close"
      [class.is-active]="_tutorialStep === 6"
      (click)="_closeTutorialButtonClick()"
    >
      <sui-icon name="엑스"></sui-icon>
    </button>

    <sui-checkbox class="checkbox" type="boxFirst">
      다시 보지 않기
      <input type="checkbox" [(ngModel)]="_closeTutorialPermanently" />
    </sui-checkbox>
  </div>

  <button
    class="button left page"
    (click)="_tutorialStep = _tutorialStep - 1"
    *ngIf="_tutorialStep > 1"
  >
    <sui-icon name="화살표_왼쪽"></sui-icon>
  </button>

  <button
    class="button right page"
    (click)="_tutorialStep = _tutorialStep + 1"
    *ngIf="_tutorialStep < 6"
  >
    <sui-icon name="화살표_오른쪽"></sui-icon>
  </button>
</div>
