<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  <div class="header">
    <button sui-icon-button (click)="_onBackButtonClick()" *ngIf="_canGoBack">
      <sui-icon name="원형 이동_왼쪽"></sui-icon>
    </button>
    {{ _pageTitles[_currentPage] }}
  </div>
</sui-dialog-header>
<div class="page" *ngIf="_currentPage === _Page.groupDetails">
  <lib-reading-group-details
    *ngIf="detailsReadingGroup"
    [group]="detailsReadingGroup"
    [isUserLoggedIn]="_data.isUserLoggedIn"
    (joinGroupButtonClicked)="_onJoinGroupButtonClicked()"
    (editGroupButtonClick)="_onEditGroupButtonClick()"
    (groupExited)="_onGroupExited()"
    (groupJoined)="_onGroupJoined()"
    (inviteGroupMemberButtonClick)="_goToPage(_Page.invitation)"
    (enterGroupButtonClick)="_onEnterGroupButtonClick($event)"
  >
  </lib-reading-group-details>
</div>
<div class="page" *ngIf="_currentPage === _Page.groupEdit">
  <lib-reading-group-edit
    [initialValue]="detailsReadingGroup"
    (groupEdited)="_onGroupEdited()"
  ></lib-reading-group-edit>
</div>
<div class="page" *ngIf="_currentPage === _Page.invitation">
  <lib-reading-group-invitation
    *ngIf="detailsReadingGroup"
    [groupId]="detailsReadingGroup.id"
  ></lib-reading-group-invitation>
</div>
<div class="page loader" *ngIf="!detailsReadingGroup">
  <sui-loader></sui-loader>
</div>
