import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, Directive, Input } from '@angular/core';

export const MenuPositionBottomLeft: ConnectedPosition[] = [
  {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
];

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'button[sui-menu-item]',
})
export class MenuItemDirective {}

@Component({
  selector: 'sui-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.sui-menu-size-regular]': 'size == "regular"',
    '[class.sui-menu-size-small]': 'size == "small"',
  },
})
export class MenuComponent {
  @Input() size: 'regular' | 'small' = 'regular';
}
