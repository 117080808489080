import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, map, switchMap } from 'rxjs';
import { ExitedReadingGroup, UserProfile } from 'shared/models';
import { ReadingGroupsAPIService, UsersAPIService } from 'shared/services';
import { AlertService } from 'shared/ui';

@Component({
  selector: 'lib-my-bookshelf-exited-groups-page',
  templateUrl: './my-bookshelf-exited-groups-page.component.html',
  styleUrl: './my-bookshelf-exited-groups-page.component.scss',
})
export class MyBookshelfExitedGroupsPageComponent implements OnInit, OnDestroy {
  private _readingGroupSubject = new Subject<{ page: number }>();
  private _deleteReadingGroupSubject = new Subject<number>();

  public _readingGroup: ExitedReadingGroup[] = [];
  public _checkedList: number[] = [];
  public _userInfo!: UserProfile;

  public _currentPage = 1;
  public readonly _itemCountPerPage = 20;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _alertService: AlertService,
    private _usersAPIService: UsersAPIService
  ) {}

  ngOnInit(): void {
    this._usersAPIService.getUsersProfile().subscribe((result) => {
      this._userInfo = result;
    });

    this._readingGroupSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._readingGroupsAPIService.getExitedGroupList(
            page * this._itemCountPerPage,
            this._itemCountPerPage,
            'excited_at'
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        window.scrollTo(0, 0);

        this._totalItemCount = result.total;
        this._readingGroup = result.items;
      });

    this._deleteReadingGroupSubject
      .pipe(
        switchMap((id: number) => {
          return this._readingGroupsAPIService
            .deleteExitedGroup(0, id)
            .pipe(map((result) => ({ id, result })));
        })
      )
      .subscribe(({ id }) => {
        this._readingGroup = this._readingGroup.filter((group) => {
          return group.id !== id;
        });
      });

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }
        this._currentPage = page - 1;

        this._readingGroupSubject.next({ page: this._currentPage });
      });
  }

  ngOnDestroy(): void {
    this._readingGroupSubject.complete();
    this._deleteReadingGroupSubject.complete();
  }

  public _onClickCheckbot(id: number): void {
    if (this._checkedList.includes(id)) {
      this._checkedList = this._checkedList.filter(
        (item: number) => item !== id
      );
    } else {
      this._checkedList.push(id);
    }
  }

  public _onClickDeleteButton(): void {
    if (this._checkedList.length === 0) {
      return;
    }

    this._alertService
      .openConfirm(
        '',
        `삭제 시 그룹 내 ${this._userInfo?.nickname}...님의 모든 기록이 삭제되며 삭제된 기록은 복원이 불가능합니다. 선택한 그룹을 목록에서 삭제하시겠습니까?`,
        '삭제하기'
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._checkedList.forEach((id: number) => {
            this._deleteReadingGroupSubject.next(id);
          });

          this._checkedList = [];
        }
      });
  }

  // 내 기록 불러오기 로직
  public _onClickCallMyHistory(group_id: number, book_id: string): void {
    this._alertService
      .openConfirm(
        '',
        '그룹 내 나의 메모, 하이라이트 기록 불러오기가 가능하며, 해당 기록은 ‘혼자읽기’ 모드의 기록으로 추가됩니다. 내 기록을 불러오시겠습니까?',
        '불러오기'
      )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this._readingGroupsAPIService
            .deleteExitedGroup(1, group_id)
            .subscribe((result) => {
              this._alertService
                .openConfirm(
                  '',
                  `하이라이트 ${result.highlight_count}개, 메모 ${result.memo_count}개를 ‘혼자 읽기 모드’로 불러왔습니다. 뷰어를 실행해 확인하시겠습니까?`,
                  '뷰어 열기'
                )
                .afterClosed()
                .subscribe((result) => {
                  if (result) {
                    this._router.navigate(['/@' + book_id + '/cover']);
                  }
                });
            });
        }
      });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }

  public _routeDetailBookPage(id: string): void {
    this._router.navigate(['/@' + id]);
  }
}
