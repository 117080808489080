<div
  class="select-box dropdown"
  [cdkMenuTriggerFor]="option"
  [cdkMenuPosition]="_optionMenuPosition"
  [class.is-disabled]="!groups || groups.length === 0"
  #selectBox
>
  <ng-container *ngIf="_selectedGroup">
    {{ _selectedGroup.type === 'private' ? '비공개' : '공개' }}그룹 |
    {{ _selectedGroup.name }}
  </ng-container>
  <ng-container *ngIf="!_selectedGroup && groups && groups.length > 0"
    >함께읽기 그룹을 선택해 주세요.</ng-container
  >
  <ng-container *ngIf="!_selectedGroup && (!groups || groups.length === 0)"
    >선택 가능한 함께읽기 그룹이 없습니다.</ng-container
  >
</div>

<ng-template #option>
  <div cdkMenu class="options" [style.width.px]="_selectBoxWidth">
    <div cdkMenuItem [cdkMenuItemDisabled]="true" class="option dropdown">
      함께읽기 그룹을 선택해 주세요.
    </div>
    <div class="options-inner">
      <div
        cdkMenuItem
        class="option"
        [class.is-active]="group.id === selectedGroupId"
        [class.is-disabled]="group.id === currentGroupId"
        *ngFor="let group of groups"
        (click)="group.id !== currentGroupId && _onOptionClick(group)"
        [cdkMenuItemDisabled]="group.id === currentGroupId"
      >
        {{ group.type === 'private' ? '비공개' : '공개' }}그룹 |
        {{ group.name }}
      </div>
    </div>
  </div>
</ng-template>
