import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';

import { Subject, fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

import { EventBusService } from '../../services/event-bus.service';

export interface StaticMemo {
  text: string;
  nickname: string;
  avatarImageURL?: string;
  content: string;
}

@Component({
  selector: 'viewer-static-memo-popup',
  templateUrl: './static-memo-popup.component.html',
  styleUrl: './static-memo-popup.component.scss',
})
export class StaticMemoPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popup', { static: true }) _popupElem!: ElementRef<HTMLDivElement>;
  @ViewChild('dragOverlay', { static: true })
  _dragOverlayElem!: ElementRef<HTMLDivElement>;
  @ViewChild(CdkDrag, { static: true }) _cdkDrag!: CdkDrag;

  private _unsubscriber = new Subject<void>();

  public _memo?: StaticMemo;

  public _isOpened = false;

  public _contentsScale = 1;

  constructor(private _eventBus: EventBusService) {}

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._unsubscriber), throttleTime(500))
      .subscribe(() => {
        this.close();
      });

    this._eventBus
      .on('ContentsComponent:zoomScaleChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((event) => {
        this._contentsScale = event.scale / 100;
      });

    this._eventBus
      .on('ContentsComponent:commentaryPreviewMemoIndicatorClick')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ memo, trigger }) => {
        this._memo = memo;
        this._setPositionWithTrigger(trigger);
        this._isOpened = true;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  private _setPositionWithTrigger(trigger: HTMLElement): void {
    const triggerRect = trigger.getBoundingClientRect();

    let top = Math.max(triggerRect.top * this._contentsScale, 0);
    let left = Math.max(triggerRect.left * this._contentsScale, 0);
    const triggerHeight = trigger.offsetHeight * this._contentsScale;

    const frameRect =
      trigger.ownerDocument.defaultView?.frameElement?.getBoundingClientRect();
    if (frameRect) {
      top += frameRect.top;
      left += frameRect.left;
    }

    const popupMaxHeight = 360;
    const popupWidth = Math.min(window.innerWidth * 0.8, 290);

    if (top + triggerHeight + popupMaxHeight > window.innerHeight) {
      // 인디케이터 상단에
      this._popupElem.nativeElement.style.bottom =
        window.innerHeight - top + 5 * this._contentsScale + 'px';
      this._popupElem.nativeElement.style.removeProperty('top');
    } else {
      // 인디케이터 하단에
      this._popupElem.nativeElement.style.top =
        top + triggerHeight + 10 * this._contentsScale + 'px';
      this._popupElem.nativeElement.style.removeProperty('bottom');
    }

    if (left + popupWidth < window.innerWidth) {
      // 인디케이터와 왼쪽 정렬
      this._popupElem.nativeElement.style.left = left + 'px';
      this._popupElem.nativeElement.style.removeProperty('right');
    } else if (left - popupWidth > 0) {
      // 인디케이터와 오른쪽 정렬
      // this._popupElem.nativeElement.style.right =
      // window.innerWidth - left + 'px';
      this._popupElem.nativeElement.style.right = '10px';
      this._popupElem.nativeElement.style.removeProperty('left');
    } else {
      // 센터에
      this._popupElem.nativeElement.style.left =
        (window.innerWidth - popupWidth) / 2 + 'px';
      this._popupElem.nativeElement.style.removeProperty('right');
    }

    this._cdkDrag.reset();
  }

  _onCloseButtonClick(): void {
    this.close();
  }

  get isOpened(): boolean {
    return this._isOpened;
  }

  close(): void {
    if (!this._isOpened) {
      return;
    }

    this._isOpened = false;
    this._onDragReleased();
    this._eventBus.fire('MemoPopupComponent:closed');
  }

  _onDragStarted(): void {
    this._dragOverlayElem.nativeElement.style.display = 'block';
  }

  _onDragReleased(): void {
    this._dragOverlayElem.nativeElement.style.display = 'none';
  }
}
