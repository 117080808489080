import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { Memo, UserProfile } from 'shared/models';
import { APIError, MemosAPIService, UsersAPIService } from 'shared/services';
import {
  DialogService,
  MenuPositionBottomLeft,
  ToastService,
  TypedDialog,
} from 'shared/ui';
import {
  FollowerComponent,
  FollowingComponent,
  NoteSendDialogComponent,
} from 'user';

import { MemoCopyDialogComponent } from '../memo-copy-dialog/memo-copy-dialog.component';

import { MemoReportDialogComponent } from '../memo-report-dialog/memo-report-dialog.component';

import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { EventBusService } from '../../services/event-bus.service';
import { OnlineReadingMemberService } from '../../services/online-reading-member.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';
import { MemoReadStatusService } from '../../services/memo-read-status.service';

enum SortType {
  date = '최신 순',
  likes = '추천 많은 순',
  position = '목차 순',
}

function sortByDate(memo1: Memo, memo2: Memo): number {
  return (
    new Date(memo2.created_at).getTime() - new Date(memo1.created_at).getTime()
  );
}

function sortByLikes(memo1: Memo, memo2: Memo): number {
  return memo2.like_count - memo1.like_count;
}

function sortByPosition(memo1: Memo, memo2: Memo): number {
  return memo1.position - memo2.position;
}

@Component({
  selector: 'viewer-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrl: './user-details-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class UserDetailsDialogComponent
  extends TypedDialog<
    {
      bid: string;
      userId: string;
      isUserBlocked: boolean;
      groupId: number;
    },
    { userBlocked: boolean; seeDetailsButtonClicked?: boolean }
  >
  implements OnInit, OnDestroy
{
  public _profile?: UserProfile;
  public _memos?: Memo[];
  public _isUserBlocked = false;
  public _selectedSortType = SortType.date;
  public readonly _sortTypes = [
    SortType.date,
    SortType.likes,
    SortType.position,
  ];
  public readonly _moreMenuPosition = MenuPositionBottomLeft;
  public _selectedMemoIndex = 0;

  public _isUserOnline = false;
  private _unsubscriber = new Subject<void>();

  constructor(
    private _memosAPIService: MemosAPIService,
    private _usersAPIService: UsersAPIService,
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService,
    private _toastService: ToastService,
    private _dialogService: DialogService,
    private _onlineReadingGroupMemberService: OnlineReadingMemberService,
    private _readingGroupsService: ReadingGroupsService,
    private _memoReadStatusService: MemoReadStatusService
  ) {
    super();
    this._isUserBlocked = this._data.isUserBlocked;
  }

  ngOnInit(): void {
    this._loadProfile();
    !this._isUserBlocked && this._loadMemoList();

    this._isUserOnline = this._onlineReadingGroupMemberService.isOnline(
      this._data.userId
    );

    this._onlineReadingGroupMemberService.onlineStatusChange$
      .pipe(
        takeUntil(this._unsubscriber),
        filter((data) => data.userId === this._data.userId)
      )
      .subscribe((data) => {
        this._isUserOnline = data.isOnline;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  private _loadProfile(): void {
    this._usersAPIService
      .getUserProfile(this._data.userId)
      .subscribe((profile) => {
        this._profile = profile;
      });
  }

  private _loadMemoList(): void {
    this._memosAPIService
      .get(this._data.bid, 0, 999, {
        group_id: this._data.groupId,
        member_id: this._data.userId,
      })
      .subscribe((response) => {
        this._memos = response.items;
        this._sort();

        if (this._memos.length > 0) {
          this._onMemoRead(this._memos[0]);
        }
      });
  }

  private _close(result: {
    userBlocked: boolean;
    seeDetailsButtonClicked?: boolean;
  }): void {
    this._readingGroupsService.refreshGroupInfo();
    this._dialogRef.close(result);
  }

  _onLikeButtonClick(memo: Memo): void {
    const delta = memo.is_liked === 1 ? -1 : 1;

    memo.like_count += delta;
    memo.is_liked = memo.is_liked ? 0 : 1;

    const request$ =
      delta > 0
        ? this._annotationsService.likeMemo(memo.id)
        : this._annotationsService.unlikeMemo(memo.id);

    request$.subscribe({
      error: () => {
        memo.is_liked = memo.is_liked ? 0 : 1;
        memo.like_count -= delta;
      },
    });
  }

  _onCopyToSingleModeButtonClick(memo: Memo): void {
    this._dialogService
      .open(MemoCopyDialogComponent, {
        data: {
          text: memo.text,
          content: memo.content,
          author: this._profile!.nickname,
          memoId: memo.id,
        },
      })
      .afterClosed()
      .subscribe((copied) => {
        if (copied) {
          memo.copy_count++;
        }
      });
  }

  _onSeeDetailsButtonClick(memo: Memo): void {
    this._eventBusService.fire(
      'UserDetailsDialogComponent:seeDetailsButtonClick',
      {
        url: memo.url,
      }
    );

    this._close({
      userBlocked: this._isUserBlocked,
      seeDetailsButtonClicked: true,
    });
  }

  _onReportMenuClick(memo: Memo): void {
    if (!this._profile) {
      return;
    }

    this._dialogService
      .open(MemoReportDialogComponent, {
        data: {
          authorName: this._profile.nickname,
          authorAvatarImageURL: this._profile.avatar_image_url,
          memoId: memo.id,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.blocked) {
          this._close({ userBlocked: true });
        }
      });
  }

  _onBlindMenuClick(memo: Memo): void {
    this._annotationsService.blockMemo(memo.id).subscribe(() => {
      this._toastService.open('차단이 완료되었습니다.');

      const index = this._memos!.indexOf(memo);

      if (index !== -1) {
        this._memos!.splice(index, 1);
      }
    });
  }

  _onCloseButtonClick(): void {
    this._close({ userBlocked: this._isUserBlocked });
  }

  _onBlockUserButtonClick(): void {
    this._usersAPIService.block(this._data.userId).subscribe(() => {
      this._eventBusService.fire('UserDetailsDialogComponent:userBlocked');

      this._toastService.open(`${this._profile?.nickname}님을 차단하였습니다.`);
      this._isUserBlocked = true;

      this._close({ userBlocked: true });
    });
  }

  _onUnblockUserButtonClick(): void {
    this._usersAPIService.unblock(this._data.userId).subscribe(() => {
      this._eventBusService.fire('UserDetailsDialogComponent:userUnblocked');

      this._toastService.open(`차단 해제가 완료되었습니다.`);
      this._isUserBlocked = false;

      this._loadMemoList();
    });
  }

  _onSendNoteButtonClick(): void {
    if (!this._profile) {
      return;
    }

    this._dialogService.open(NoteSendDialogComponent, {
      data: {
        userId: this._profile.id,
        nickname: this._profile.nickname,
      },
    });
  }

  _onToggleFollowButtonClick(): void {
    const delta = this._profile!.is_following ? -1 : 1;

    this._profile!.follower_count += delta;
    this._profile!.is_following = this._profile!.is_following ? 0 : 1;

    const request$ =
      delta > 0
        ? this._usersAPIService.follow(this._data.userId)
        : this._usersAPIService.unfollow(this._data.userId);

    request$.subscribe({
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
        this._profile!.is_following = this._profile!.is_following ? 0 : 1;
        this._profile!.follower_count -= delta;
      },
    });
  }

  _onShowFollowingsButtonClick(): void {
    if (!this._profile) {
      return;
    }

    if (this._profile.following_count === 0) {
      return;
    }

    this._dialogService.open(FollowingComponent, {
      data: {
        user_id: this._profile.id,
      },
    });
  }

  _onShowFollowersButtonClick(): void {
    if (!this._profile) {
      return;
    }

    if (this._profile.follower_count === 0) {
      return;
    }

    this._dialogService.open(FollowerComponent, {
      data: {
        user_id: this._profile.id,
      },
    });
  }

  _onSelectSortType(): void {
    this._sort();
  }

  _sort(): void {
    this._memos = this._memos!.sort((memo1, memo2) => {
      switch (this._selectedSortType) {
        case SortType.date:
          return sortByDate(memo1, memo2);
        case SortType.likes:
          return sortByLikes(memo1, memo2);
        case SortType.position:
          return sortByPosition(memo1, memo2);
      }
    });

    this._selectedMemoIndex = 0;
  }

  _onMemoRead(memo: Memo): void {
    !memo.is_read && this._memoReadStatusService.read(memo.id);
  }
}
