import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'periodTranslate',
  pure: true,
})
export class PeriodTranslatePipe implements PipeTransform {
  transform(value: string): string {
    const match = /(\d+)(y|m|w|d)/.exec(value);

    if (!match) {
      throw new Error('invalid period');
    }

    const number = match[1];
    const unit = match[2] + (number !== '1' ? 's' : '');

    switch (unit) {
      case 'y':
      case 'ys':
        return `${number}년`;
      case 'm':
      case 'ms':
        return `${number}개월`;
      case 'w':
      case 'ws':
        return `${number}주`;
      case 'd':
      case 'ds':
        return `${number}일`;
      default:
        throw new Error(`invalid period unit: ${unit}`);
    }
  }
}
