import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelfBook } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-edit-booklist',
  templateUrl: './edit-booklist.component.html',
  styleUrl: './edit-booklist.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class EditBooklistComponent
  extends TypedDialog<{ bookshelf_id: string }, { canceled: boolean }>
  implements OnInit, OnDestroy
{
  @ViewChild('listContainer') private listContainer?: ElementRef;

  private _bookListSubject = new Subject<{ page: number }>();

  public _isPageLocation: 'edit' | 'move' = 'edit';
  public _bookList?: BookshelfBook[];
  public _checkedBookList: string[] = [];

  public _total: number = 0;
  public _currentPage: number = 0;
  public _itemCountPerPage: number = 28;
  public _isLoading: boolean = true;

  constructor(
    private _toastService: ToastService,
    private _bookshelfAPIService: BookshelfAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._bookListSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;

          return this._bookshelfAPIService.getBooks(
            this._data.bookshelf_id,
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }

        this._total = result.total;
        this._bookList = result.items;
      });

    this._bookListSubject.next({ page: 0 });
  }

  ngOnDestroy(): void {
    this._bookListSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close({ canceled: true });
  }

  public _changePageLocation(type: 'edit' | 'move'): void {
    this._isPageLocation = type;
    if (this._isPageLocation === 'edit') {
      this._bookListSubject.next({ page: 0 });
      this._checkedBookList = [];
    }
  }

  public _onClickBookItem(book_id: string): void {
    if (this._checkedBookList.includes(book_id)) {
      this._checkedBookList = this._checkedBookList.filter((id) => {
        return id !== book_id;
      });
    } else {
      this._checkedBookList.push(book_id);
    }
  }

  public _onClickDeleteBookItem(): void {
    this._bookshelfAPIService
      .deleteBooks(this._data.bookshelf_id, this._checkedBookList)
      .subscribe({
        next: () => {
          this._toastService.open('선택한 책이 책장에서 제거되었습니다.');
          this._dialogRef.close({ canceled: false });
        },
        error: (error: HttpErrorResponse) => {
          this._toastService.open(
            `오류가 발생하였습니다. 잠시 후 다시 시도해주세요. (${error.status})`
          );
        },
      });
  }

  public _onBookMoved(): void {
    this._dialogRef.close({ canceled: false });
  }

  public _onPageChange(page: number): void {
    this._bookListSubject.next({ page });
  }
}
