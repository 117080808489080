<div class="tabs">
  <div class="tab-item" [class.is-active]="_currentTab === _Tab.Inbox">
    <div class="number">
      <div class="highlight"></div>
      <button (click)="_onSelectTab(_Tab.Inbox)">
        {{ _inboxTotal | number : '2.0-0' }}
      </button>
    </div>
    <div class="label">받은 쪽지</div>
  </div>
  <div class="tab-item" [class.is-active]="_currentTab === _Tab.Sent">
    <div class="number">
      <div class="highlight"></div>
      <button (click)="_onSelectTab(_Tab.Sent)">
        {{ _sentTotal | number : '2.0-0' }}
      </button>
    </div>
    <div class="label">보낸 쪽지</div>
  </div>
</div>
<div class="body">
  <div class="placeholder" *ngIf="!_notes">
    <sui-loader></sui-loader>
  </div>
  <div class="placeholder" *ngIf="_notes?.length === 0">쪽지가 없습니다.</div>
  <ng-container *ngIf="_notes && _notes.length > 0">
    <div class="list" #listElem>
      <div
        class="list-item"
        *ngFor="let note of _notes; index as i"
        (click)="_selectedNoteIndex = i"
        [class.is-selected]="i === _selectedNoteIndex"
      >
        <div class="info">
          <div class="user">
            <sui-reading-group-member-avatar
              class="avatar"
              [avatarImageURL]="note.user.avatar_image_url"
            ></sui-reading-group-member-avatar>
            <span>{{ note.user.nickname }}</span>
          </div>
          <div class="date">
            {{ note.created_at | date : 'y.MM.dd. HH:mm' }}
          </div>
        </div>
        <div class="content">
          {{ note.content }}
        </div>
      </div>
      <div *ngIf="_notes" (suiIntersectionListener)="_onScrollEnd()"></div>
    </div>
    <div class="divider"></div>
    <div class="detail" *ngIf="_notes[_selectedNoteIndex] as _selectedNote">
      <div class="user">
        <sui-reading-group-member-avatar
          class="avatar"
          [avatarImageURL]="_selectedNote.user.avatar_image_url"
          (click)="_onUserClick()"
        ></sui-reading-group-member-avatar>
        <span (click)="_onUserClick()">{{ _selectedNote.user.nickname }}</span>
      </div>
      <div class="note">
        <button
          class="more-button"
          sui-icon-button
          [cdkMenuTriggerFor]="moreMenu"
          [cdkMenuPosition]="_moreMenuPosition"
        >
          <sui-icon name="더보기"></sui-icon>
        </button>
        <div class="content">
          {{ _selectedNote.content }}
        </div>
        <div class="footer">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 30V0H30V0.000371774C13.5729 0.0815255 0.251217 13.4386 0 30Z"
              fill="#F5F5F7"
            />
          </svg>
          <div class="date">
            {{ _selectedNote.created_at | date : 'y.MM.dd. HH:mm' }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #moreMenu>
  <sui-menu cdkMenu>
    <button
      sui-menu-item
      cdkMenuItem
      (click)="_onResponseMenuClick()"
      *ngIf="_currentTab === _Tab.Inbox"
    >
      답장하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteMenuClick()">
      삭제하기
    </button>
  </sui-menu>
</ng-template>
