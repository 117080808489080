import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CdkAccordionModule } from '@angular/cdk/accordion';

import { SharedUIModule } from 'shared/ui';

// components
import { ReadingGroupSelectionComponent } from './components/reading-group-selection/reading-group-selection.component';
import { ReadingGroupEditComponent } from './components/reading-group-edit/reading-group-edit.component';
import { ReadingGroupDetailsComponent } from './components/reading-group-details/reading-group-details.component';
import { ReadingGroupInvitationComponent } from './components/reading-group-invitation/reading-group-invitation.component';
import { ReadingModeSelectionComponent } from './components/reading-mode-selection/reading-mode-selection.component';
import { ReadingGroupListItemComponent } from './components/reading-group-list-item/reading-group-list-item.component';
import { ReadingGroupFormFieldComponent } from './components/reading-group-form-field/reading-group-form-field.component';
import { ReadingGroupFormFieldHintComponent } from './components/reading-group-form-field/reading-group-form-field-hint.component';
import { ReadingGroupSummaryComponent } from './components/reading-group-summary/reading-group-summary.component';
import { GroupItemBoxComponent } from './components/group-item-box/group-item-box.component';

// directives
import { IntersectionListenerDirective } from './directives/intersection-listener.directive';

// dialogs
import { ReadingModeDialogComponent } from './dialogs/reading-mode-dialog/reading-mode-dialog.component';
import { ReadingGroupPasscodeDialogComponent } from './dialogs/reading-group-passcode-dialog/reading-group-passcode-dialog.component';
import { ReadingGroupExitDialogComponent } from './dialogs/reading-group-exit-dialog/reading-group-exit-dialog.component';
import { ReadingGroupDetailsDialogComponent } from './dialogs/reading-group-details-dialog/reading-group-details-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    CdkMenuModule,
    CdkAccordionModule,
    SharedUIModule,
  ],
  declarations: [
    ReadingGroupSelectionComponent,
    ReadingGroupEditComponent,
    ReadingGroupDetailsComponent,
    ReadingGroupPasscodeDialogComponent,
    ReadingGroupExitDialogComponent,
    ReadingGroupInvitationComponent,
    ReadingModeSelectionComponent,
    IntersectionListenerDirective,
    ReadingModeDialogComponent,
    ReadingGroupListItemComponent,
    ReadingGroupFormFieldComponent,
    ReadingGroupFormFieldHintComponent,
    ReadingGroupSummaryComponent,
    ReadingGroupDetailsDialogComponent,
    GroupItemBoxComponent,
  ],
  exports: [
    ReadingGroupListItemComponent,
    ReadingGroupSelectionComponent,
    ReadingGroupEditComponent,
    ReadingGroupDetailsComponent,
    ReadingGroupInvitationComponent,
    ReadingModeSelectionComponent,
    ReadingGroupFormFieldComponent,
    ReadingGroupFormFieldHintComponent,
    GroupItemBoxComponent,
  ],
})
export class ReadingGroupModule {}
