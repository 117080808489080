<sui-dialog-header (closeButtonClick)="_closeDialog()">
  <span>
    '{{ _data.bookshelfTitle }}' <b class="lightly">책장에 책 추가하기</b></span
  >
</sui-dialog-header>
<section>
  <main>
    <nav class="gnb-navbar">
      <ul>
        <li class="book">
          <span
            [class.selected]="_gnbNavbarType === 'ebook'"
            (click)="_changeGnbNavbar('ebook')"
            >단권 구매 전자책</span
          >
          <span>소장/대여</span>
        </li>
        <li class="book-mobile" (click)="_changeGnbNavbar('ebook')">
          <span [class.selected]="_gnbNavbarType === 'ebook'"
            >단권 구매(소장/대여) 전자책</span
          >
        </li>
        <li (click)="_changeGnbNavbar('library')" class="library">
          <span [class.selected]="_gnbNavbarType === 'library'">
            구독 중 라이브러리
          </span>
        </li>
      </ul>
    </nav>
    <!-- 단권 구매 전자책 tab -->
    <div class="book-list-container" *ngIf="_gnbNavbarType === 'ebook'">
      <div class="book-count" *ngIf="_bookList?.length !== 0">
        <b>{{ _checkedBookList.length }}</b> / {{ _total }}개
      </div>
      <div class="book-list-box" #listContainer>
        <div class="empty" *ngIf="_bookList?.length === 0">
          <p class="content">추가 가능한 책이 없습니다.</p>
        </div>
        <ul class="book-list">
          <sui-book
            *ngFor="let book of _bookList"
            (click)="_onClickBookItem(book.bid)"
          >
            <img sui-book-cover [src]="book.cover" />
            <sui-book-title>{{ book.title }}</sui-book-title>
            <sui-book-author>{{ book.author }}</sui-book-author>
            <div
              class="sui-book__overlay"
              [class.selected]="_checkedBookList.includes(book.bid)"
            >
              <div class="check-icon">
                <sui-icon name="체크"></sui-icon>
              </div>
            </div>
          </sui-book>
        </ul>
        <sui-paginator
          *ngIf="_bookList && _bookList.length !== 0"
          [page]="_currentPage"
          [totalItemCount]="_total"
          [itemCountPerPage]="_itemCountPerPage"
          (pageChange)="_onPageChange($event)"
        ></sui-paginator>
      </div>
    </div>

    <!-- 구독중인 라이브러리에 포함된 책 tab-->
    <div class="library-list-container" *ngIf="_gnbNavbarType === 'library'">
      <div class="select-box" (click)="_changeIsSelectLibrary()">
        <p *ngIf="_selectedLibrary.value !== ''">
          {{ _selectedLibrary.value }}
        </p>
        <p *ngIf="_selectedLibrary.value === ''">라이브러리를 선택해주세요.</p>
        <sui-icon name="펼침"></sui-icon>
      </div>
      <div class="clicked-select-box" *ngIf="_isSelectLibrary === true">
        <ul>
          <li (click)="_changeIsSelectLibrary()">
            <p>라이브러리를 선택해주세요.</p>
            <sui-icon name="닫힘"></sui-icon>
          </li>
          <li
            (click)="_changeLibrary(library.title, library.id)"
            *ngFor="let library of _subscriptionList"
          >
            {{ library.title }}
          </li>
        </ul>
      </div>
      <div class="book-count" *ngIf="_selectedLibrary.value !== ''">
        <b>{{ _checkedBookListInLibrary.length }}</b> / {{ _total }}개
      </div>
      <div class="book-list-box" #listContainer>
        <div
          class="empty"
          *ngIf="!_bookListInLibrary && _selectedLibrary.value !== ''"
        >
          <p class="content">추가 가능한 책이 없습니다.</p>
        </div>
        <ul class="book-list" *ngIf="_selectedLibrary.value !== ''">
          <sui-book
            *ngFor="let book of _bookListInLibrary"
            (click)="_onClickBookItem(book.bid)"
          >
            <img sui-book-cover [src]="book.cover" />
            <sui-book-title>{{ book.title }}</sui-book-title>
            <sui-book-author>{{ book.author }}</sui-book-author>
            <div
              class="sui-book__overlay"
              [class.selected]="_checkedBookList.includes(book.bid)"
            >
              <div class="check-icon">
                <sui-icon name="체크"></sui-icon>
              </div>
            </div>
          </sui-book>
        </ul>
        <sui-paginator
          *ngIf="_bookListInLibrary && _bookListInLibrary.length !== 0"
          [page]="_currentPage"
          [totalItemCount]="_total"
          [itemCountPerPage]="_itemCountPerPage"
          (pageChange)="_onPageChange($event)"
        ></sui-paginator>
      </div>
    </div>
  </main>

  <div class="loader-container" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
  <div class="add-button-container">
    <button
      sui-solid-button
      size="large"
      class="possible"
      [disabled]="
        _checkedBookList.length === 0 && _checkedBookListInLibrary.length === 0
      "
      (click)="_onClickInsertButton()"
    >
      추가하기
    </button>
  </div>
</section>
