<h1>그룹 비밀번호를 입력해 주세요.</h1>

<div class="form">
  <div class="input">
    <input
      [(ngModel)]="_passcode"
      (ngModelChange)="_passcodeNotMatched = false"
      maxlength="4"
      placeholder="비밀번호 숫자 4자리를 입력해 주세요."
      [type]="_showPasscode ? 'text' : 'password'"
      autocomplete="off"
    />
    <button sui-icon-button (click)="_showPasscode = !_showPasscode">
      <sui-icon name="뷰 오프" *ngIf="!_showPasscode"></sui-icon>
      <sui-icon name="뷰 온" *ngIf="_showPasscode"></sui-icon>
    </button>
  </div>
  <div>
    <div *ngIf="_passcodeNotMatched" class="form-error">
      <sui-icon name="!"></sui-icon> 그룹 비밀번호가 일치하지 않습니다.
    </div>
  </div>
</div>
<div class="actions">
  <button
    varSize="large"
    sui-solid-button
    [disabled]="!_passcode || _isJoining"
    (click)="_onOKClick()"
  >
    확인
  </button>
</div>
