import { Component } from '@angular/core';
import { DialogService } from 'shared/ui';
import { DrawingBackupDialogComponent } from '../../dialogs/drawing-backup-dialog/drawing-backup-dialog.component';
import { NativeBridgeService } from '../../services/native-bridge.service';

@Component({
  templateUrl: './settings-drawing-page.component.html',
  styleUrl: './settings-drawing-page.component.scss',
})
export class SettingsDrawingPageComponent {
  constructor(
    private _dialogService: DialogService,
    private _nativeBridgeService: NativeBridgeService
  ) {}

  _onBackupClick(): void {
    this._dialogService.open(DrawingBackupDialogComponent);
  }

  _onRestoreClick(): void {
    this._nativeBridgeService.restoreDrawings();
  }
}
