<div class="list-header">
  <div class="left">
    <div class="title">
      이 책에 남긴 <span class="count">{{ _items.length }}</span
      >개의 메모가 있습니다.
    </div>
  </div>
  <div class="right">
    <button
      class="select-all-button"
      [class.is-active]="
        _selectedItemCount > 0 && _selectedItemCount === _items.length
      "
      (click)="_onSelectAllButtonClick()"
    >
      전체 선택<sui-icon name="체크"></sui-icon>
    </button>
    <select
      sui-select
      [(ngModel)]="_selectedSortType"
      (ngModelChange)="_onSortTypeChange()"
    >
      <option *ngFor="let sortType of _sortTypes" [value]="sortType">
        {{ sortType }}
      </option>
    </select>
  </div>
</div>
<div class="list" [class.selection-mode]="_selectedItemCount > 0">
  <div
    class="list-item"
    *ngFor="let item of _items; index as i"
    [class.is-selected]="item.selected"
  >
    <div class="text">
      {{ item.text }}
    </div>
    <div class="memo">
      <viewer-memo
        [menu]="menu"
        [menuData]="{ item: item }"
        [content]="item.content"
        (contentClick)="_onItemClick(item)"
      >
        <viewer-memo-tag *ngIf="item.source"
          >{{ item.copied_at | date : 'y.MM.dd. HH:mm' }} |
          {{ item.source }}님의 메모 복사</viewer-memo-tag
        >
        <viewer-memo-actions *ngIf="_groupId !== undefined">
          <button class="icon-button" disabled>
            <sui-icon name="추천"></sui-icon>
            {{ item.like_count }}
          </button>
          <button class="icon-button" disabled>
            <sui-icon name="복사" type="solid"></sui-icon>
            {{ item.copy_count }}
          </button>
        </viewer-memo-actions>
      </viewer-memo>
      <viewer-icon-checkbox class="checkbox"
        ><input
          type="checkbox"
          [(ngModel)]="item.selected"
          (ngModelChange)="_onItemSelectionChange()"
      /></viewer-icon-checkbox>
    </div>
  </div>
</div>
<div class="bottom-menu" [class.is-active]="_selectedItemCount > 0">
  <div class="selection-count">
    총 <span>{{ _selectedItemCount }}</span
    >개 선택
  </div>
  <div class="buttons">
    <button
      sui-box-button
      varSize="medium48::medium54"
      (click)="_onCopySelectedItemsButtonClick()"
    >
      복사하기</button
    ><button
      sui-solid-button
      varSize="medium48::medium54"
      (click)="_onDeleteSelectedItemsButtonClick()"
    >
      삭제하기
    </button>
  </div>
</div>

<ng-template #menu let-item="item">
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onEditMenuClick(item)">
      수정하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteMenuClick(item)">
      삭제하기
    </button>
    <button
      sui-menu-item
      cdkMenuItem
      (click)="_onExportMenuClick(item)"
      *ngIf="!_isCommentaryEditorMode"
    >
      내보내기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onCopyMenuClick(item)">
      복사하기
    </button>
  </sui-menu>
</ng-template>
