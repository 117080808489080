import { Component, OnInit } from '@angular/core';
import { ReadingGroup } from 'shared/models';
import { ReadingGroupsAPIService } from 'shared/services';
import { AlertService, TypedDialog } from 'shared/ui';

enum Page {
  groupEdit,
  groupDetails,
  invitation,
}

@Component({
  selector: 'lib-reading-group-details-dialog',
  templateUrl: './reading-group-details-dialog.component.html',
  styleUrl: './reading-group-details-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class ReadingGroupDetailsDialogComponent
  extends TypedDialog<
    {
      groupId: number;
      isUserLoggedIn: boolean;
    },
    Partial<{
      canceled: boolean;
      joinButtonClickedByGuest: boolean;
      groupId: number;
      joined: boolean;
      exited: boolean;
    }>
  >
  implements OnInit
{
  public readonly _Page = Page;
  public _currentPage = Page.groupDetails;
  public _pageTitles = ['정보 수정하기', '상세보기', '그룹원 초대하기'];
  public pageStack: Page[] = [];
  public detailsReadingGroup?: ReadingGroup;
  private _newlyJoined = false;

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _alertService: AlertService
  ) {
    super();
  }

  ngOnInit(): void {
    this._readingGroupsAPIService
      .getById(this._data.groupId)
      .subscribe((group) => {
        this.detailsReadingGroup = group;
      });
  }

  private _setCurrentPage(page: Page): void {
    this._currentPage = page;
  }

  _onJoinGroupButtonClicked(): void {
    if (!this._data.isUserLoggedIn) {
      this._dialogRef.close({ joinButtonClickedByGuest: true });
    }
  }

  _goToPage(page: Page): void {
    this.pageStack.push(this._currentPage);
    this._setCurrentPage(page);
  }

  _goBack(skipCheckPage?: boolean): void {
    if (!skipCheckPage && this._currentPage === Page.groupEdit) {
      const dialogRef = this._alertService.openConfirm(
        undefined,
        '뒤로가기 시, 작성중인 내용은 저장되지 않습니다.<br/>뒤로가기 하시겠습니까?'
      );

      dialogRef.afterClosed().subscribe((ok) => {
        if (ok) {
          this._goBack(true);
        }
      });

      return;
    }

    const prevPage = this.pageStack.pop();
    prevPage !== undefined && this._setCurrentPage(prevPage);
  }

  get _canGoBack(): boolean {
    return this.pageStack.length > 0;
  }

  _onCloseButtonClick(): void {
    this._dialogRef.close({ canceled: true, joined: this._newlyJoined });
  }

  _onBackButtonClick(): void {
    this._goBack();
  }

  _onEnterGroupButtonClick(groupId: number): void {
    this._dialogRef.close({ groupId });
  }

  _onGroupDetailsButtonClick(group: ReadingGroup): void {
    this.detailsReadingGroup = group;
    this._goToPage(Page.groupDetails);
  }

  _onEditGroupButtonClick(): void {
    this._goToPage(Page.groupEdit);
  }

  _onGroupJoined(): void {
    this._newlyJoined = true;
  }

  _onGroupExited(): void {
    this._dialogRef.close({ canceled: true, exited: true });
  }

  _onGroupEdited(): void {
    if (!this.detailsReadingGroup) {
      return;
    }

    this._readingGroupsAPIService
      .getById(this.detailsReadingGroup.id)
      .subscribe((group) => {
        this.detailsReadingGroup = group;
        this._goBack(true);
      });
  }
}
