export interface Note {
  id: number;
  content: string;
  created_at: string;
  user: {
    id: string;
    nickname: string;
    avatar_image_url: string;
  };
}
