/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  fromEvent,
  merge,
  Observable,
  of,
  partition,
  Subject,
  SubscriptionLike,
} from 'rxjs';
import {
  debounceTime,
  filter,
  take,
  takeUntil,
  delay,
  map,
  scan,
  throttleTime,
  pairwise,
  catchError,
} from 'rxjs/operators';
import {
  AddressChangeEvent,
  AnnotationChangedEvent,
  AnnotationCreatedEvent,
  AnnotationRemovedEvent,
  BookLoadErrorEvent,
  BookLoadEvent,
  BookmarkStateChangeEvent,
  HighlightClickEvent,
  ItemLoadEvent,
  ItemLoadStartEvent,
  LibConfig,
  PageInfoChangeEvent,
  PageTapEvent,
  SelectionChangeEvent,
  SettingsChangeEvent,
  ViewerComponent,
  AnnotationType,
  Annotation,
  ItemLoadErrorEvent,
  ItemLoadErrorCode,
  Theme,
  PagingMode,
  SettingValues,
  PageChangeCanceledEvent,
  Direction,
  PageChangeCancelCode,
  Address,
  BookItem,
  ZoomScaleChangeEvent,
  parseBukURL,
  BookType,
  Settings,
} from '@bukio/viewer';
import { Clipboard } from '@angular/cdk/clipboard';

import { clamp } from 'shared/utils';
import { createSNSShareUrl, createSNSShareWindowFeatures } from 'shared/utils';
import {
  APIError,
  SharedAuthService,
  SharedUserService,
} from 'shared/services';
import { AlertService, DialogService, ToastService } from 'shared/ui';

import { IntervalLoggerService } from '../../services/interval-logger.service';
import { BroadcastService } from '../../services/broadcast.service';
import { MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR } from '../../services/annotations.service';
import { EventBusService } from '../../services/event-bus.service';
import { BukViewerSearchService } from '../../services/buk-viewer-search.service';
import { StorageService } from '../../services/storage.service';

import { getActiveIframes, getIIDFromRange } from '../../utils/buk-viewer';

import {
  ContextMenuItem,
  HighlightAction,
  SelectionContext,
} from '../context-menu/context-menu.component';

import { Book, BukJSON } from '../../models/book.model';
import { createSharingURL, isSharedAddress } from '../../utils/address';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import {
  fromKeydownEvent,
  fromPageChangeKeydownEvent,
  fromUserScrollEvent,
  scanNavigationChangeResult,
} from '../../utils/rxjs';
import {
  createPermissionAnchor,
  isShortcutKeyboardEvent,
} from '../../utils/misc';
import { canOpenBookWithURL } from '../../utils/url';
import {
  VIEWER_ENVIRONMENT,
  ViewerModuleEnvironment,
} from '../../viewer.module';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import {
  BookmarksService,
  convertBookmarkToViewerAnnotation,
} from '../../services/bookmarks.service';
import { AnalyticsService } from '../../services/analytics.service';

import { MemoDialogComponent } from '../../dialogs/memo-dialog/memo-dialog.component';
import { HeaderMenuItem } from '../header/header.component';
import { SettingsDialogComponent } from '../../dialogs/settings-dialog/settings-dialog.component';
import {
  HIGHLIGHT_COLORS,
  HighlightColor,
} from '../../constants/highlight-colors';
import { BookInfoDialogComponent } from '../../dialogs/book-info-dialog/book-info-dialog.component';
import { FooterMenuItem } from '../footer/footer.component';
import { getMemoGroupType } from '../../utils/memo';
import { PreviewEndDialogComponent } from '../../dialogs/preview-end-dialog/preview-end-dialog.component';
import { ReadingModeDialogComponent } from '../../dialogs/reading-mode-dialog/reading-mode-dialog.component';
import { UIStateService } from '../../services/ui-state.service';
import { PDFDrawingService } from '../../services/pdf-drawing.service';
import { BookFeaturesStoreService } from '../../services/book-features-store.service';
import { SearchDialogComponent } from '../../dialogs/search-dialog/search-dialog.component';
import {
  extendSelectionToWord,
  isRangeAtPageBoundary,
  modifyRangeWithinViewport,
} from '../../utils/range';
import { CrossBrowsing } from '../../constants/cross-browsing';

export const USER_HIGHLIGHT_STYLES = Object.entries(HIGHLIGHT_COLORS).map(
  ([key, color]) => ({
    styleClass: key,
    style: `background: ${color}`,
  })
);

export const USER_HIGHLIGHT_STYLE_CLASSES = USER_HIGHLIGHT_STYLES.map(
  (s) => s.styleClass
);
export const SHARED_HIGHLIGHT_STYLE_CLASS = 'bukh-color-shared';

export const MOMO_MEMO_STYLE_CLASS = 'bukh-color-momo-m';

const MEMO_INDICATOR_CLASS = 'buk-memo-indicator';
const MEMO_INDICATOR_TYPE_MINE = 'mine';
const MEMO_INDICATOR_TYPE_SHARED = 'shared';
const MEMO_INDICATOR_TYPE_MINE_SHARED = 'mine_shared';
const MEMO_INDICATOR_TYPE_COMMENTARY = 'commentary';
const MEMO_INDICATOR_TYPE_MINE_COMMENTARY = 'mine_commentary';

export const MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY =
  'bukh-color-momo-commentary';
export const MOMO_HIGHLIGHT_STYLE_CLASS_MEMBER = 'bukh-color-momo-member';
export const MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP3 = 'bukh-color-momo-overlap3';
export const MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP5 = 'bukh-color-momo-overlap5';
export const MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP10 = 'bukh-color-momo-overlap10';

const FOCUSED_MEMO_CLASS_SINGLE = 'buk-memo-focused-single';
const FOCUSED_MEMO_CLASS_DOUBLE = 'buk-memo-focused-double';

export const VIEWER_CONFIG: LibConfig = {
  contentsBaseURL: 'https://contents.buk.io',
  fonts: {
    en: {
      fontFaces: [
        'https://fonts.googleapis.com/css?family=Noto+Serif%7COpen+Sans%7CTinos%7CRoboto&subset=latin,latin-ext,latin,latin-ext,latin,latin-ext,latin,latin-ext',
      ],
      fontFamilies: ['Noto Serif', 'Roboto', 'Open Sans', 'Tinos'],
    },
    ko: {
      fontFaces: [
        'https://cdn.buk.io/css/fonts/kopubbatang.css',
        'https://cdn.buk.io/css/fonts/nanumgothic.css',
        'https://cdn.buk.io/css/fonts/nanummyeongjo.css',
      ],
      fontFamilies: ['Nanum Gothic', 'Nanum Myeongjo', 'KoPub Batang'],
    },
  },
  highlightStyles: [
    ...USER_HIGHLIGHT_STYLES,
    {
      styleClass: SHARED_HIGHLIGHT_STYLE_CLASS,
      style: 'background: rgba(94, 255, 255, 0.3);cursor: auto;',
    },
    {
      styleClass: MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY,
      style:
        'box-shadow: inset 0 -2px rgba(0, 224, 255, 0.6); cursor: auto;' /*, 0px 2px rgba(94, 255, 255, 0.4)'*/,
    },
    {
      styleClass: MOMO_HIGHLIGHT_STYLE_CLASS_MEMBER,
      style: 'box-shadow: inset 0 -2px rgba(20, 255, 0, 0.6); cursor: auto;',
    },
    {
      styleClass: MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP3,
      style: 'box-shadow: inset 0 -2px rgba(20, 255, 0, 0.6); cursor: auto;',
    },
    {
      styleClass: MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP5,
      style: 'box-shadow: inset 0 -2px rgba(20, 255, 0, 0.6); cursor: auto;',
    },
    {
      styleClass: MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP10,
      style: 'box-shadow: inset 0 -2px rgba(20, 255, 0, 0.6); cursor: auto;',
    },
    {
      styleClass: MOMO_MEMO_STYLE_CLASS,
      style: 'cursor: auto;',
    },
  ],
  contentsStyles: [
    `[data-bukv-theme="light"] { --bukv-bg-color: #fff }`,
    `[data-bukv-theme="sepia"] { --bukv-bg-color: #e6e5dc }`,
    `[data-bukv-theme="dark"] { --bukv-bg-color: #282828 }`,
    `:root:not([data-bukv-is-cover="true"]) {padding-top: 10px;}`,
    `::selection {background-color: rgba(0, 122, 255, 0.28);}`,
  ],
  enablePinchZoom: true,
};

const DEFAULT_SETTINGS: SettingValues = {
  theme: Theme.Light,
  pagingMode: PagingMode.Page,
  pageAnimation: true,
  multiColumn: false,
  fontFace: 'default',
  fontSize: 100,
  lineHeight: 100,
  clickToPlayMediaOverlay: false,
};

function getGATrackingLabelOfContextMenuItem(menu: ContextMenuItem): string {
  switch (menu) {
    case ContextMenuItem.CopyText:
      return 'copy-text';
    case ContextMenuItem.CopyURL:
      return 'copy-url';
    case ContextMenuItem.ShareTwitter:
    case ContextMenuItem.ShareFacebook:
      return 'share';
    case ContextMenuItem.MediaOverlay:
      return 'play-media-overlay';
    case ContextMenuItem.Memo:
      return 'memo';
    case ContextMenuItem.SelectContinuously:
      return 'select-continuously';
  }
}

const OVERRIDABLE_SETTING_KEYS: (keyof SettingValues)[] = [
  'theme',
  'fontSize',
  'pageAnimation',
  'pagingMode',
  'multiColumn',
];

function settingValuesFromObject(values: {
  [key: string]: string;
}): Partial<SettingValues> {
  const result = {} as any;

  OVERRIDABLE_SETTING_KEYS.forEach((key) => {
    const value = values[key];

    if (value == null) {
      return;
    }

    let normalizedValue: any;

    switch (key) {
      case 'theme': {
        if (/^(light|sepia|dark)$/.test(value)) {
          normalizedValue = value;
        }
        break;
      }
      case 'fontSize': {
        if (/^\d+$/.test(value)) {
          normalizedValue = clamp(50, parseInt(value), 200);
        }
        break;
      }
      case 'pagingMode': {
        if (/^(page|scroll)$/.test(value)) {
          normalizedValue = value;
        }
        break;
      }
      case 'pageAnimation':
      case 'multiColumn': {
        if (/^(true|false)$/.test(value)) {
          normalizedValue = value === 'true';
        }
        break;
      }
    }

    if (normalizedValue != null) {
      result[key] = normalizedValue;
    }
  });

  return result;
}

function shouldPushState(currAddr: Address, prevAddr?: Address): boolean {
  if (!prevAddr) {
    return false;
  }

  if (prevAddr.bid !== currAddr.bid) {
    return true;
  }

  return false;
}

function onCopyContentDocument(event: ClipboardEvent): void {
  event.preventDefault();
}

@Component({
  selector: 'viewer-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss'],
})
export class ContentsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ViewerComponent) _viewerComp!: ViewerComponent;
  @HostBinding('attr.data-book-type')
  get _bookType(): string | undefined {
    return this._book?.meta.type;
  }

  private unsubscriber: Subject<void> = new Subject<void>();
  private _locationChangeSubscription!: SubscriptionLike;

  public readonly _libConfig = VIEWER_CONFIG;
  public _selectionChange$ = new Subject<SelectionChangeEvent>();

  private _replaceBrowserURL$ = new Subject<{
    path: string;
    query?: string;
  } | null>();

  private _isContextMenuShowing = false;
  private _hideContextMenuTimeoutId?: number;

  private _pendingAnnotActions = new Map<
    string,
    (annotation: Annotation, error?: Error | APIError) => void
  >();

  private _book?: Book;
  private _isItemLoading = false;

  private _loadedItems!: BookItem[];

  private _sharedPageInfo: { pageCount: number; page: number } | null = null;
  private _shouldFixPagingMode = false;

  private _prevWindowSize?: { w: number; h: number };

  private _overrideSettings?: Partial<SettingValues>;

  private _address?: Address;

  private _currentTOCIndex = -1;

  private _frameBodyMutationObserver?: MutationObserver;
  private _isListeningZoomScrollEvent = false;

  private _referer?: Address;

  private _continuouslySelectedRange?: Range;

  constructor(
    private _element: ElementRef,
    @Inject(DOCUMENT) private _document: Document,
    private _ngZone: NgZone,
    private _location: Location,
    private _router: Router,
    private _clipboard: Clipboard,
    private _$: BroadcastService,
    private _eventBusService: EventBusService,
    private _userService: SharedUserService,
    private _bukViewerSearchService: BukViewerSearchService,
    private _storageService: StorageService,
    private _loggerService: IntervalLoggerService,
    private _annotationsService: AnnotationsV2Service,
    private _bookmarksService: BookmarksService,
    @Inject(VIEWER_ENVIRONMENT) _environemnt: ViewerModuleEnvironment,
    private _dialogService: DialogService,
    private _toastService: ToastService,
    private _alertService: AlertService,
    private _analyticsService: AnalyticsService,
    private _authService: SharedAuthService,
    private _uiStateService: UIStateService,
    private _bookFeaturesStoreService: BookFeaturesStoreService,
    @Optional() private _pdfDrawingService?: PDFDrawingService
  ) {
    this._libConfig.bukJSONBaseURL = _environemnt.serverOrigin;

    if (self === top) {
      this._libConfig.initialSettings =
        this._storageService.loadSettings() ?? undefined;
    } else {
      this._libConfig.initialSettings = DEFAULT_SETTINGS;
    }

    this._libConfig.canChangeAddress = (newAddress): boolean => {
      return this._canChangeAddress(newAddress);
    };

    this._libConfig.contentsBaseURL = `https://${
      location.hostname.replace(/\./g, '') + location.port
    }.contents.buk.io`;

    this._replaceBrowserURL$
      .pipe(throttleTime(500, undefined, { leading: false, trailing: true }))
      .subscribe((url) => {
        url && this._location.replaceState(url.path, url.query);
      });

    fromEvent(window, 'resize')
      .pipe(debounceTime(500))
      .subscribe(() => {
        this._updateMemoIndicator();
      });

    fromEvent<KeyboardEvent>(document.documentElement, 'keydown')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((event) => {
        this._onKeydown(event);
      });
  }

  private _updateBrowserURL(newAddress: Address): void {
    const address = newAddress.clone();

    if (!address.query) {
      address.query = {};
    }

    if (this._annotationsService.getState().commentaryId) {
      address.query!['cId'] = this._annotationsService.getState().commentaryId;
      delete address.query.gId;
    } else {
      delete address.query.cId;
    }

    if (this._annotationsService.getState().groupId) {
      address.query!['gId'] = this._annotationsService.getState().groupId;
      delete address.query.cId;
    } else {
      delete address.query.gId;
    }

    if (this._uiStateService.isCommentaryEditor) {
      address.query!['commentaryEditor'] = true;
    }

    const viewerURL = address.toString();
    const browserURL = this._location.path();

    if (browserURL !== viewerURL) {
      const [path, query] = viewerURL.split('?');

      if (shouldPushState(newAddress, this._address)) {
        this._replaceBrowserURL$.next(null);
        this._location.go(path, query);
      } else {
        this._replaceBrowserURL$.next({ path, query });
      }
    } else {
      this._replaceBrowserURL$.next(null);
    }
  }

  ngOnInit(): void {
    this._annotationsService.state$
      .pipe(
        map((state) => [state.groupId, state.commentaryId]),
        pairwise(),
        filter(
          ([state1, state2]) =>
            state1[0] !== state2[0] || state1[1] !== state2[1]
        )
      )
      .subscribe((state) => {
        if (
          this._referer?.query?.cPreview &&
          (state[0] != null || state[1] != null)
        ) {
          this._referer = undefined;
        }

        if (this._address) {
          this._updateBrowserURL(this._address);
        }
      });

    // 북마크 동기화
    this._annotationsService.itemsAnnotations$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(() => {
        this._syncAnnotations();

        setTimeout(() => {
          this._updateMemoIndicator();
        });
      });

    this._bookmarksService.bookmarks$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(() => {
        this._syncAnnotations();
      });

    // 선택, 선택 해제
    const [deselect$, select$] = partition(
      this._selectionChange$,
      (event) => !event.selection || !event.selection.range.toString().trim()
    );

    deselect$.subscribe(() => {
      this._onDeselectText();
    });

    select$.pipe(debounceTime(500)).subscribe((event) => {
      this._onSelectText(event);
    });

    // 페이지 이동
    merge(
      fromPageChangeKeydownEvent(this._document.documentElement).pipe(
        filter(({ event }) => (event.target as any).tagName !== 'BUKV-VIEWER'),
        map(({ direction }) => ({ direction }))
      ),
      this._eventBusService.on('PdfMenuComponent:pageChange'),
      this._eventBusService.on('PagerComponent:pageChange')
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ direction }) => {
        if (direction === Direction.Next) {
          this._viewerComp?.nextPage();
        } else if (direction === Direction.Prev) {
          this._viewerComp?.prevPage();
        }
      });

    // 컨텍스트 메뉴
    this._eventBusService
      .on('ContextMenuComponent:highlight')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((event) => {
        this._onHighlightMenuClick(
          event.action,
          event.context,
          event.styleClass
        );
      });

    this._eventBusService
      .on('ContextMenuComponent:menuClick')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((event) => {
        this._onContextMenuClick(event.menu, event.context);
      });

    this._eventBusService
      .on('ContextMenuComponent:visibilityChange')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ isVisible }) => {
        if (!isVisible) {
          this._onHideContextMenu();
        } else {
          this._onShowContextMenu();
        }
      });

    merge(
      this._eventBusService.on('HeaderComponent:menuClick'),
      this._eventBusService.on('FooterComponent:menuClick')
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ menu }) => {
        switch (menu) {
          case HeaderMenuItem.Settings:
          case FooterMenuItem.Settings:
            this._openSettingsDialog();
            break;
          case HeaderMenuItem.TOC:
          case FooterMenuItem.TOC:
            this._openBookInfoDialog();
            break;
          case HeaderMenuItem.Bookmark:
            this._viewerComp?.toggleBookmark();
            break;
          case HeaderMenuItem.ReadingMode:
          case FooterMenuItem.ReadingMode:
            this._openReadingModeDialog();
            break;
        }
      });

    merge(
      this._eventBusService.on('PageNumberComponent:backButtonClick'),
      this._eventBusService.on('PdfMenuComponent:pageInputBlurred'),
      this._eventBusService.on('BookEndDialogComponent:otherSeriesClick'),
      this._eventBusService.on('FooterComponent:pageChange'),
      this._eventBusService.on('ThumbnailDialogComponent:thumbnailClick'),
      this._eventBusService.on('AnnotationsComponent:itemClick'),
      this._eventBusService.on('PageBackButtonComponent:click')
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ url }) => {
        this._openBook(url);
      });

    this._eventBusService
      .on('SearchDialogComponent:resultClick')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ url }) => {
        const address = parseBukURL(url);
        address.query = { highlight: 'rgba(255, 255, 0, 0.4)' };
        this._openBook(address);
      });

    merge(
      this._eventBusService.on('FixedlayoutPageControlComponent:changeZoom'),
      this._eventBusService.on('PdfMenuComponent:changeZoom')
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ scale }) => {
        this._viewerComp.zoom(scale);
      });

    this._eventBusService
      .on('BookEndToastComponent:goToCoverClick')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(() => {
        const address = new Address(
          this._book!.meta.bid,
          this._book!.items[0].iid,
          undefined,
          this._address?.query?.l ? { l: this._address.query.l } : undefined
        );

        this._openBook(address);
      });

    this._eventBusService
      .on('LangaugeSelectorComponent:change')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ langs }) => {
        const address = this._viewerComp?.getCurrentAddress()?.clone();

        if (!address) {
          return;
        }

        if (!address.query) {
          address.query = {};
        }

        address.query.l = langs.join(',');

        this._openBook(address);
      });

    // 책 내 검색
    this._bukViewerSearchService.search$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ searchId, keyword }) => {
        const cancelFn = this._viewerComp.search(keyword, (results) => {
          this._eventBusService.fire('ContentsComponent:searchDone', {
            searchId,
            keyword,
            results,
          });
        });

        this._eventBusService.fire('ContentsComponent:searchStart', {
          searchId,
          keyword,
          cancelFn,
        });
      });

    this._eventBusService
      .on('ReadingModeMemoComponent:seeDetailsButtonClick')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ url }) => {
        this._blinkMemo(url);
      });

    merge(
      this._eventBusService.on(
        'UserDetailsDialogComponent:seeDetailsButtonClick'
      ),
      this._eventBusService.on(
        'CommentatorDetailsDialogComponent:seeDetailsButtonClick'
      )
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ url }) => {
        const address = parseBukURL(url);

        if (!address.query) {
          address.query = {};
        }

        address.query.highlight = 'rgba(224, 255, 0, 0.2)';

        this._openBook(address);
      });

    merge(
      this._eventBusService.on('MemoPopupComponent:closed'),
      this._eventBusService
        .on('RightPanelComponent:closed')
        .pipe(filter(({ activePanel }) => activePanel === 'memo'))
    )
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(() => {
        this._clearFocusedMemo();
      });

    this._eventBusService
      .on('ItemMemoListComponent:groupClicked')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ url, type }) => {
        this._focusMemo(url, type, true);
      });

    this._uiStateService.isDrawingMode$
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((value) => {
        this._onDrawingModeChanged(value);
      });
  }

  private _onDrawingModeChanged(isDrawingMode: boolean): void {
    if (!this._address) {
      return;
    }

    if (
      isDrawingMode &&
      this._viewerComp.getCurrentSettings().multiColumn.value
    ) {
      this._toastService.open(
        '필기 기능 이용을 위해 한 페이지 보기 모드로 변경되었습니다.'
      );
      this._viewerComp.updateSettings('multiColumn', false);
    } else {
      const address = this._address.clone();
      address.query = Object.assign(address.query ?? {}, { force: true });
      this._viewerComp.openBookWithURL(address.toString());
    }

    if (!isDrawingMode) {
      this._pdfDrawingService?.saveDrawings().subscribe();
    }
  }

  ngAfterViewInit(): void {
    // detect URL change
    this._userService.user
      .pipe(takeUntil(this.unsubscriber), take(1), delay(0))
      .subscribe(() => {
        this._initWithURL(this._location.path());
      });

    this._router.events
      .pipe(
        scan(scanNavigationChangeResult, {}),
        takeUntil(this.unsubscriber),
        filter(
          (result) =>
            !!result.url &&
            canOpenBookWithURL(result.url) &&
            !result.isTriggeredByPopstate
        ),
        map((result) => result.url),
        delay(0)
      )
      .subscribe((url) => {
        this._initWithURL(url!);
      });

    this._locationChangeSubscription = this._location.subscribe(() => {
      const path = this._location.path();
      if (canOpenBookWithURL(path)) {
        this._initWithURL(path);
      }
    });
    /////////////////
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();

    this._locationChangeSubscription.unsubscribe();

    this._destroyFrameBodyMutationObserver();
  }

  _initWithURL(url: string): void {
    const address = parseBukURL(url);

    let gId: number | undefined;
    let cId: number | undefined;

    if (address.query?.gId) {
      const groupReaderDisabled =
        this._userService.getUser()?.group_reader_disabled;

      if (groupReaderDisabled) {
        const until = new Date(groupReaderDisabled);
        const since = new Date(groupReaderDisabled);
        since.setDate(until.getDate() + 1);

        this._toastService.openWarning(
          `신고 횟수 누적으로 ${until.getUTCFullYear()}.${
            until.getUTCMonth() + 1
          }.${until.getUTCDate()}일까지 혼자읽기 모드만 이용이 가능합니다. ${since.getUTCFullYear()}.${
            since.getUTCMonth() + 1
          }.${since.getUTCDate()}일부터 다시 함께읽기 모드를 이용할 수 있습니다.`
        );
      } else {
        gId = parseInt(address.query.gId);

        if (isNaN(gId)) {
          gId = undefined;
        }
      }
    }

    if (address.query?.cId) {
      cId = parseInt(address.query.cId);

      if (isNaN(cId) || gId != null) {
        cId = undefined;
      }
    }

    this._uiStateService.isCommentaryEditor =
      cId != null && address.query?.commentaryEditor != null;

    this._annotationsService.setState({
      groupId: gId,
      commentaryId: cId,
      showMine: !this._uiStateService.isCommentaryEditor,
    });

    const drawingLoading$ =
      this._pdfDrawingService?.loadDrawings(address.bid) ?? of(undefined);

    drawingLoading$
      .pipe(catchError<void, Observable<Error>>((error) => of(error)))
      .subscribe((error) => {
        if (error) {
          this._uiStateService.canUseDrawingMode = false;

          this._toastService.open(
            `필기를 불러오는데 실패하였습니다. 필기 기능 이용이 제한됩니다. (${error.message})`
          );
        } else {
          this._uiStateService.canUseDrawingMode = true;
        }

        this._viewerComp.openBookWithURL(url);
      });
  }

  _openBook(addressOrURL: Address | string): void {
    // let address;

    // if (typeof addressOrURL === 'string') {
    //   address = parseBukURL(addressOrURL);
    // } else {
    //   address = addressOrURL.clone();
    // }

    // if (!address.query) {
    //   address.query = {};
    // }

    // if (this._annotationsService.getState().commentaryId) {
    //   address.query!['cId'] = this._annotationsService.getState().commentaryId;
    //   delete address.query.gId;
    // } else {
    //   delete address.query.cId;
    // }

    // if (this._annotationsService.getState().groupId) {
    //   address.query!['gId'] = this._annotationsService.getState().groupId;
    //   delete address.query.cId;
    // } else {
    //   delete address.query.gId;
    // }

    // this._viewerComp.openBookWithURL(address.toString());
    if (typeof addressOrURL === 'string') {
      this._viewerComp.openBookWithURL(addressOrURL);
    } else {
      this._viewerComp.openBook(
        addressOrURL.bid,
        addressOrURL.iid,
        addressOrURL.anchor,
        addressOrURL.query
      );
    }
  }

  _syncAnnotations(): void {
    const annotations = this._annotationsService.getItemsAnnotations();
    const bookmarks = this._bookmarksService.bookmarks.map(
      convertBookmarkToViewerAnnotation
    );

    this._viewerComp?.setAnnotations(annotations.concat(bookmarks));
  }

  _onBookLoad(event: BookLoadEvent): void {
    this._book = new Book(event.book as BukJSON);

    if (this._uiStateService.isCommentaryEditor) {
      if (this._userService.getUser()?.isAdmin) {
        this._uiStateService.canEditCommentary = true;
      } else {
        this._uiStateService.canEditCommentary =
          this._book.meta.commentaries.find(
            (c) => c.id === this._annotationsService.getState().commentaryId
          )?.published_at === null;
      }
    } else {
      this._uiStateService.canEditCommentary = false;
    }

    this._referer = this._book.permissionReferer;

    this._destroyFrameBodyMutationObserver();
    this._isListeningZoomScrollEvent = false;

    //////
    const savedSettings = this._storageService.loadSettings();

    this._shouldFixPagingMode = !this._book.isOwnedByUser;
    this._overrideSettings = settingValuesFromObject(
      this._viewerComp.getCurrentAddress()?.query ?? {}
    );

    if (this._shouldFixPagingMode) {
      this._overrideSettings.pagingMode = PagingMode.Page;
    }

    OVERRIDABLE_SETTING_KEYS.forEach((key) => {
      const overrideValue =
        this._overrideSettings && (this._overrideSettings[key] as any);
      const userValue = savedSettings && (savedSettings[key] as any);

      const value = overrideValue ?? userValue;
      value && this._viewerComp.updateSettings(key, value);
    });

    this._eventBusService.fire('ContentsComponent:settingsChanged', {
      settings: this._viewerComp.getCurrentSettings(),
      availableFonts: this._viewerComp.getFontsCurrentlyAvailable(),
      forceDisabledSettings: this._shouldFixPagingMode ? ['pagingMode'] : [],
    });
    ////////

    this._eventBusService.fire('ContentsComponent:bookLoad', {
      book: this._book,
    });

    this._canChangeAddress(this._viewerComp.getCurrentAddress()!);
  }

  _onBookLoadError(event: BookLoadErrorEvent): void {
    this._eventBusService.fire('ContentsComponent:bookLoadError', event);
  }

  _onAddressChange(event: AddressChangeEvent): void {
    // 뷰어 내에서만 쓰는 쿼리 삭제
    if (event.address.query) {
      delete event.address.query.force;
      delete event.address.query.highlight;
      delete event.address.query.expand;
      delete event.address.query.t;
      delete event.address.query.bookshelf;

      if (event.address.query.cPreview) {
        delete event.address.query.cPreview;
        delete event.address.query.memo;
        delete event.address.query.text;
        delete event.address.query.avatar;
        delete event.address.query.nickname;
      }

      delete event.address.query.mId;
      // delete event.address.query.commentaryEditor;
    }

    this._updateBrowserURL(event.address);

    this._address = event.address;
    this._eventBusService.fire('ContentsComponent:addressChange', event);
  }

  _onSettingsChange(event: SettingsChangeEvent): void {
    if (self === top) {
      this._storageService.saveSettings(
        event.settings,
        this._overrideSettings && (Object.keys(this._overrideSettings) as any)
      );
    }

    this._eventBusService.fire('ContentsComponent:settingsChanged', {
      settings: event.settings,
      availableFonts: this._viewerComp?.getFontsCurrentlyAvailable(),
      forceDisabledSettings: this._shouldFixPagingMode ? ['pagingMode'] : [],
    });
  }

  _onItemLoadStart(event: ItemLoadStartEvent): void {
    this._$.fire('loader.show.page');
    this._isItemLoading = true;
  }

  _onItemLoad(event: ItemLoadEvent): void {
    this._isItemLoading = false;
    this._loadedItems = event.items;
    this._continuouslySelectedRange = undefined;

    this._highlightRefererRange();

    const currWindowSize = { w: window.innerWidth, h: window.innerHeight };

    if (
      this._book?.isSharedItem(event.address.iid || this._book.items[0].iid)
    ) {
      if (
        this._prevWindowSize &&
        (this._prevWindowSize.w !== currWindowSize.w ||
          this._prevWindowSize.h !== currWindowSize.h)
      ) {
        this._viewerComp.openBookWithURL(
          this._book.permissionReferer!.toString()
        );
      }
    }

    this._prevWindowSize = currWindowSize;
    this._eventBusService.fire('ContentsComponent:itemLoad', event);

    setTimeout(() => {
      this._processContentDocument();
      this._updateMemoIndicator();
    });

    if (!this._isListeningZoomScrollEvent) {
      this._listenZoomScrollEvent();
    }

    this._$.fire('loader.hide.page');
  }

  private _listenZoomScrollEvent(): void {
    this._isListeningZoomScrollEvent = true;

    fromUserScrollEvent(
      this._element.nativeElement.querySelectorAll('bukv-scroll-zoom')
    )
      .pipe(throttleTime(300))
      .subscribe(() => {
        this._eventBusService.fire('ContentsComponent:pdfPageScrolled');
      });
  }

  _onItemLoadError(event: ItemLoadErrorEvent): void {
    this._isItemLoading = false;

    if (event.code === ItemLoadErrorCode.ItemNotFound) {
      this._eventBusService.fire('ContentsComponent:itemLoadError', event);
    }
  }

  _onPageTransitionEnd(): void {
    this._eventBusService.fire('ContentsComponent:pageTransitionEnd');
  }

  _onPageInfoChange(event: PageInfoChangeEvent): void {
    const currentAddress = this._viewerComp.getCurrentAddress()!;
    const currentIid = currentAddress.iid || this._book?.items[0].iid;

    if (
      this._book?.isSharedItem(currentIid) &&
      this._book.permissionReferer!.anchor === currentAddress.anchor
    ) {
      this._sharedPageInfo = {
        pageCount: event.pageCount,
        page: event.page,
      };
    }

    if (this._book!.meta.type !== BookType.Wiki) {
      this._loggerService.setBookMetrics(
        this._loadedItems.map((item) => ({
          bid: currentAddress.bid,
          iid: item.iid,
          page: event.page,
          pageCount: event.pageCount,
        }))
      );
    }

    this._currentTOCIndex = event.tocIndex;

    if (this._book?.canReadItem(currentIid, true)) {
      this._eventBusService.fire('ContentsComponent:pageInfoChange', {
        ...event,
        address: currentAddress,
      });
    }
  }

  _onBookmarkStateChange(event: BookmarkStateChangeEvent): void {
    this._eventBusService.fire('ContentsComponent:bookmarkStateChange', event);
  }

  _onPageTap(event: PageTapEvent): void {
    if (!event.isPageChangeGesture && !this._isContextMenuShowing) {
      this._eventBusService.fire('ContentsComponent:pageClick');
    } else {
      this._eventBusService.fire('ContentsComponent:pageClick2');
    }
  }

  private _onEndOfBook(direction: Direction): void {
    this._eventBusService.fire('ContentsComponent:endOfBook', {
      direction,
    });
  }

  _onHighlightClick(event: HighlightClickEvent): void {
    let userHighlight;

    if (this._uiStateService.isCommentaryEditor) {
      userHighlight = event.highlights.find(
        (h) => h.annotation.styleClass === MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY
      );
    } else {
      userHighlight = event.highlights.find(
        (h) =>
          USER_HIGHLIGHT_STYLE_CLASSES.indexOf(h.annotation.styleClass!) !== -1
      );
    }

    if (!userHighlight) {
      return;
    }

    const canPlayMediaOverlay = this._viewerComp.canPlayMediaOverlayFromRange(
      userHighlight.range
    );

    this._eventBusService.fire('ContentsComponent:highlightClick', {
      context: {
        url: userHighlight.annotation.url,
        selectedRange: userHighlight.range,
        annotation: userHighlight.annotation,
        canPlayMediaOverlay,
        isAtPageBoundary: false,
      },
      offset: { x: event.offsetX, y: event.offsetY },
    });
  }

  _onDeselectText(): void {
    this._eventBusService.fire('ContentsComponent:deselect');
  }

  _onTextSelectionLimit(): void {
    this._toastService.openWarning('문구 선택은 500자 이하로 제한됩니다.');
    this._viewerComp.clearSelection();
  }

  _onSelectText(event: SelectionChangeEvent): void {
    const url = event.selection!.url;

    if (!url) {
      return;
    }

    if (modifyRangeWithinViewport(event.selection!.range)) {
      return;
    }

    const annotation = this._viewerComp
      .getHighlightsFromRange(event.selection!.range)
      .find((h) => {
        return (
          h.annotation.styleClass &&
          USER_HIGHLIGHT_STYLE_CLASSES.indexOf(h.annotation.styleClass) !== -1
        );
      })?.annotation;

    const canPlayMediaOverlay = this._viewerComp.canPlayMediaOverlayFromRange(
      event.selection!.range
    );

    this._eventBusService.fire('ContentsComponent:select', {
      rects: event.selection!.rects,
      context: {
        url,
        selectedRange: event.selection!.range,
        canPlayMediaOverlay,
        annotation,
        isAtPageBoundary: isRangeAtPageBoundary(event.selection!.range),
      },
    });
  }

  _onAnnotationCreated(event: AnnotationCreatedEvent): void {
    if (event.annotation.type === AnnotationType.Highlight) {
      if (this._uiStateService.isCommentaryEditor) {
        event.annotation.styleClass = HighlightColor.Yellow;
      }

      this._annotationsService.createHighlight(event.annotation).subscribe({
        next: () => {
          this._annotationsService
            .deleteHighlights(event.mergedAnnotations)
            .subscribe(() => {
              this._eventBusService.fire('ContentsComponent:highlightUpdated');
            });
          this._doPendingAnnotAction(event.annotation);
        },
        error: (error: Error | APIError) => {
          event.annotation.type === AnnotationType.Highlight &&
            this._viewerComp?.removeHighlight(
              event.annotation.url,
              event.annotation.styleClass!,
              { emitEvent: false }
            );
          this._onCreateAnnotationError(error);
          this._doPendingAnnotAction(event.annotation, error);
        },
      });
    } else {
      if (this._book!.meta.type === BookType.PDF) {
        const address = parseBukURL(event.annotation.url);
        address.anchor = createPermissionAnchor();
        event.annotation.url = address.toString();
      }

      if (event.annotation.text.trim().length === 0) {
        event.annotation.text =
          this._book!.toc[this._currentTOCIndex]?.title ?? '';
      }

      event.annotation.text = event.annotation.text.substr(0, 100);

      this._bookmarksService.create(event.annotation).subscribe();
    }
  }

  _onAnnotationChanged(event: AnnotationChangedEvent): void {
    this._annotationsService
      .updateHighlight(event.annotation, event.annotation.styleClass!)
      .subscribe({
        next: () => {
          this._eventBusService.fire('ContentsComponent:highlightUpdated');
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onAnnotationRemoved(event: AnnotationRemovedEvent): void {
    // FIXME
    if (event.annotations[0].type === AnnotationType.Highlight) {
      this._annotationsService
        .deleteHighlights(event.annotations)
        .subscribe(() => {
          this._eventBusService.fire('ContentsComponent:highlightUpdated');
        });
    } else {
      this._bookmarksService.remove(event.annotations).subscribe();
    }
  }

  _onPageChangeCanceled(event: PageChangeCanceledEvent): void {
    switch (event.code) {
      case PageChangeCancelCode.NoNextItem:
        this._onEndOfBook(event.direction);
        break;
    }
  }

  _onZoomScaleChange(event: ZoomScaleChangeEvent): void {
    this._eventBusService.fire('ContentsComponent:zoomScaleChange', event);
  }

  private _onOutOfPreviewBounds(address: Address): void {
    if (!this._book) {
      return;
    }

    const openedDialog =
      this._alertService.openDialogs[this._alertService.openDialogs.length - 1];

    if (openedDialog?.componentInstance instanceof PreviewEndDialogComponent) {
      return;
    }

    if (!this._loadedItems) {
      const readableItem = this._book.items.find((item) =>
        this._book?.canReadItem(item.iid)
      );

      if (readableItem) {
        this._openBook(new Address(address.bid, readableItem.iid));
      }
    }

    this._alertService.open(PreviewEndDialogComponent, {
      data: { book: this._book },
    });
  }

  private _canChangeAddress(newAddress: Address): boolean {
    if (this._book?.meta.bid !== newAddress.bid) {
      return true;
    }

    // const currAddress = this._viewerComp.getCurrentAddress()!;
    const currIid = newAddress.iid || this._book.items[0].iid;

    if (
      this._book.canReadItem(currIid, this._book.meta.type === BookType.PDF)
    ) {
      return true;
    }

    if (!this._book.isSharedItem(currIid)) {
      this._onOutOfPreviewBounds(newAddress);

      if (isSharedAddress(newAddress) && this._book.permission.code === 405) {
        this._toastService.openWarning(
          '저작권자의 요청으로 본 챕터는 내용의 공유가 제한됩니다.'
        );
      }

      return false;
    }

    const item = this._book.getItem(newAddress.iid);

    if (item && !item.url) {
      this._onOutOfPreviewBounds(newAddress);
    }

    if (newAddress.range) {
      return this._book.permissionReferer?.anchor === newAddress.range;
    }

    if (!this._sharedPageInfo || newAddress.fragment) {
      this._onOutOfPreviewBounds(newAddress);
      return false;
    }

    const page = Math.min(
      Math.floor((newAddress.page || 0) * this._sharedPageInfo.pageCount) + 1,
      this._sharedPageInfo.pageCount
    );

    let additionalPage = 1; // this._book.meta.publisher?.sharePage || 1;

    if (window.innerWidth < 415) {
      additionalPage *= 2;
    }

    if (page > this._sharedPageInfo.page + additionalPage) {
      this._onOutOfPreviewBounds(newAddress);
      return false;
    }

    if (page < this._sharedPageInfo.page - additionalPage) {
      this._onOutOfPreviewBounds(newAddress);
      return false;
    }

    return true;
  }

  private _onCreateAnnotationError(error: Error | APIError): void {
    if (error instanceof APIError) {
      this._toastService.openWarning(error.message);
    } else {
      if (error.name === MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR) {
        this._onTextSelectionLimit();
      } else {
        this._toastService.openWarning(
          '오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.'
        );
      }
    }

    this._syncAnnotations();
  }

  private _doPendingAnnotAction(
    annotation: Annotation,
    error?: Error | APIError
  ): void {
    this._pendingAnnotActions.get(annotation.url)?.(annotation, error);
    this._pendingAnnotActions.delete(annotation.url);
  }

  private _onShowContextMenu(): void {
    window.clearTimeout(this._hideContextMenuTimeoutId);
    this._isContextMenuShowing = true;
  }

  private _onHideContextMenu(): void {
    this._ngZone.runOutsideAngular(() => {
      this._hideContextMenuTimeoutId = window.setTimeout(() => {
        this._isContextMenuShowing = false;
      }, 500);
    });
  }

  private _createHighlight(
    fromAction: 'highlight' | 'share' | 'memo',
    range: Range,
    styleClass?: string,
    emitEvent: boolean = true
  ): { annotation: Annotation; mergedAnnotations: Annotation[] } | null {
    let canHighlight = true;

    const isLoggedIn = !!this._userService.getUser();

    if (!isLoggedIn) {
      this._toastService
        .open('로그인 후 이용할 수 있습니다.', '로그인하기')
        .onAction()
        .subscribe(() => {
          this._authService.login(location.href);
        });
      canHighlight = false;
    } else if (!this._book?.isOwnedByUser) {
      this._toastService.open('해당 기능 사용을 위해서는 구매가 필요합니다.');
      canHighlight = false;
    }

    if (!canHighlight) {
      this._viewerComp.clearSelection();
      this._eventBusService.fire('ContentsComponent:deselect');
      return null;
    }

    if (!styleClass) {
      styleClass = this._libConfig.highlightStyles![0].styleClass;
    }

    return this._viewerComp.createHighlight(range, styleClass, {
      mergeWith: fromAction === 'memo' ? [] : USER_HIGHLIGHT_STYLE_CLASSES,
      emitEvent,
    });
  }

  private _onHighlightMenuClick(
    action: HighlightAction,
    context: SelectionContext,
    styleClass?: string
  ): void {
    if (this._uiStateService.isCommentaryEditor) {
      if (!this._uiStateService.canEditCommentary) {
        this._toastService.openWarning(
          '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
        );

        return;
      }

      styleClass = MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY;
    } else if (!this._annotationsService.getState().showMine) {
      this._toastService.openWarning(
        '내 기록 보기 옵션이 꺼져있습니다. 먼저 옵션을 켜주세요.'
      );
      this._eventBusService.fire(
        'ContentsComponent:contextMenuClickWithoutRequiredSettings'
      );
      return;
    }

    switch (action) {
      case HighlightAction.Add:
        this._createHighlight('highlight', context.selectedRange, styleClass);
        break;
      case HighlightAction.Change:
        this._viewerComp.changeHighlight(
          context.annotation!.url,
          context.annotation!.styleClass!,
          styleClass!
        );
        break;
      case HighlightAction.Remove:
        this._viewerComp.removeHighlight(
          context.annotation!.url,
          context.annotation!.styleClass!
        );
        break;
    }
  }

  private _onContextMenuClick(
    menu: ContextMenuItem,
    context: SelectionContext
  ): void {
    if (
      this._uiStateService.isCommentaryEditor &&
      !this._uiStateService.canEditCommentary
    ) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    if (
      menu === ContextMenuItem.CopyText ||
      menu === ContextMenuItem.CopyURL ||
      menu === ContextMenuItem.ShareFacebook ||
      menu === ContextMenuItem.ShareTwitter ||
      menu === ContextMenuItem.Memo
    ) {
      if (
        !this._uiStateService.isCommentaryEditor &&
        !this._annotationsService.getState().showMine
      ) {
        this._toastService.openWarning(
          `내 기록 보기 옵션이 꺼져있습니다. 먼저 옵션을 켜주세요.`
        );
        this._eventBusService.fire(
          'ContentsComponent:contextMenuClickWithoutRequiredSettings'
        );
        return;
      }
    }

    switch (menu) {
      case ContextMenuItem.CopyText:
        this._copySelectionText(context);
        break;
      case ContextMenuItem.CopyURL:
        this._copySelectionURL(context);
        break;
      case ContextMenuItem.ShareTwitter:
        this._shareSelection(context, 'twitter');
        break;
      case ContextMenuItem.ShareFacebook:
        this._shareSelection(context, 'facebook');
        break;
      case ContextMenuItem.MediaOverlay:
        this._viewerComp.playMediaOverlayFromRange(context.selectedRange);
        break;
      case ContextMenuItem.Memo:
        this._openMemoDialog(context);
        break;
      case ContextMenuItem.SelectContinuously:
        this._extendSelection(context.selectedRange);
        break;
    }

    this._analyticsService.trackEvent(
      'context-menu',
      getGATrackingLabelOfContextMenuItem(menu)
    );
  }

  private _extendSelection(range: Range): void {
    this._viewerComp.nextPage();

    setTimeout(() => {
      const doc = range.endContainer.ownerDocument!;
      const docSelection = doc.getSelection();

      if (!docSelection) {
        return;
      }

      docSelection.addRange(range);

      extendSelectionToWord(docSelection, 3);

      if (CrossBrowsing.noTextSelectionHandle) {
        this._continuouslySelectedRange = docSelection
          .getRangeAt(0)
          .cloneRange();
      }
    }, 500);
  }

  private _openMemoDialog(context: SelectionContext): void {
    // TODO: 같은 범위에 하는 메모 컨트롤 필요?

    const highlightResult = this._createHighlight(
      'memo',
      context.selectedRange,
      `buk-memo-temp-${Math.random()}`,
      false
    );

    if (highlightResult) {
      this._dialogService
        .open(MemoDialogComponent, {
          data: {
            annotation: highlightResult.annotation,
            mergedAnnotations: highlightResult.mergedAnnotations,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (!result) {
            return;
          }

          if (result.canceled) {
            this._viewerComp?.removeHighlight(
              highlightResult.annotation.url,
              highlightResult.annotation.styleClass!,
              { emitEvent: false }
            );
          } else {
            this._eventBusService.fire('ContentsComponent:memoUpdated');
          }
        });
    }
  }

  private _makeShareURL(annotURL: string): string {
    const user = this._userService.getUser();

    if (!user) {
      throw new Error('Cannot make share url without user');
    }

    return createSharingURL(parseBukURL(annotURL), user);
  }

  private _copySelectionText(context: SelectionContext): void {
    const isLoggedIn = !!this._userService.getUser();

    if (!isLoggedIn) {
      this._clipboard.copy(context.selectedRange.toString().trim());
      this._toastService.open('문구가 복사되었습니다.');

      return;
    }

    const copyAnnotationText = (annot: Annotation): void => {
      const text = annot.text.trim();

      this._clipboard.copy(text);
      this._toastService.open('문구가 복사되었습니다.');
    };

    if (context.annotation) {
      copyAnnotationText(context.annotation);
    } else {
      const annotation = this._createHighlight(
        'share',
        context.selectedRange
      )?.annotation;

      if (!annotation) {
        // show error
        return;
      }

      this._pendingAnnotActions.set(annotation.url, (_, error) => {
        if (!error) {
          copyAnnotationText(annotation);
        }
      });
    }
  }

  private _copySelectionURL(context: SelectionContext): void {
    const copyAnnotationURL = (annot: Annotation): void => {
      const shareURL = this._makeShareURL(annot.url);

      this._clipboard.copy(shareURL);
      this._toastService.open('인용 주소가 복사되었습니다.');
    };

    if (context.annotation) {
      copyAnnotationURL(context.annotation);
    } else {
      const annotation = this._createHighlight(
        'share',
        context.selectedRange
      )?.annotation;

      if (!annotation) {
        // show error
        return;
      }

      this._pendingAnnotActions.set(annotation.url, (_, error) => {
        if (!error) {
          copyAnnotationURL(annotation);
        }
      });
    }
  }

  private _shareSelection(
    context: SelectionContext,
    sns: 'twitter' | 'facebook'
  ): void {
    const rangeIID = getIIDFromRange(context.selectedRange);
    const canShareItem = this._book!.canShareItem(rangeIID);

    if (!canShareItem) {
      this._toastService.open(
        '저작권자의 요청으로 본 챕터는 내용의 공유가 제한됩니다.'
      );
      return;
    }

    if (context.annotation) {
      window.open(
        createSNSShareUrl(
          this._makeShareURL(context.annotation.url),
          context.annotation.text,
          sns
        ),
        undefined,
        createSNSShareWindowFeatures()
      );
    } else {
      const annotation = this._createHighlight(
        'share',
        context.selectedRange
      )?.annotation;

      if (!annotation) {
        // show error
        return;
      }

      const shareWindow = window.open(
        undefined,
        undefined,
        createSNSShareWindowFeatures()
      );

      shareWindow?.document.write('Loading...');

      this._pendingAnnotActions.set(annotation.url, (_, error) => {
        if (!error) {
          shareWindow?.location.assign(
            createSNSShareUrl(
              this._makeShareURL(annotation.url),
              annotation.text,
              sns
            )
          );
        } else {
          shareWindow?.close();
        }
      });
    }
  }

  private _onKeydown(event: KeyboardEvent): void {
    if (isShortcutKeyboardEvent(event) && event.key === 'f') {
      event.preventDefault();
      this._onSearchShortcut();
    }
  }

  private _onSearchShortcut(): void {
    if (!this._book) {
      return;
    }

    if (!this._bookFeaturesStoreService.features.text_search) {
      return;
    }

    this._dialogService.open(SearchDialogComponent, {
      data: {
        book: this._book,
      },
    });
  }

  private _processContentDocument(): void {
    getActiveIframes(this._element.nativeElement).forEach((iframe) => {
      if (!iframe.contentDocument) {
        console.log(
          `contentDocument is null, origin: ${iframe.contentWindow?.location.origin}`
        );
      }

      if (iframe.contentDocument!.documentElement.dataset.bukInit == null) {
        fromKeydownEvent(iframe.contentDocument!.documentElement).subscribe(
          (event) => {
            this._onKeydown(event);
          }
        );

        let continuouslySelectedRange: Range | undefined;

        iframe.contentDocument?.addEventListener('selectstart', () => {
          if (this._continuouslySelectedRange) {
            continuouslySelectedRange = this._continuouslySelectedRange;
            this._continuouslySelectedRange = undefined;
          }
        });

        iframe.contentDocument?.addEventListener('selectionchange', () => {
          if (!continuouslySelectedRange) {
            return;
          }

          const selection = iframe.contentDocument?.getSelection();

          if (selection && selection.rangeCount > 0) {
            const currentRange = selection.getRangeAt(0);

            try {
              if (
                currentRange.compareBoundaryPoints(
                  Range.END_TO_START,
                  continuouslySelectedRange
                ) < 1
              ) {
                selection.setBaseAndExtent(
                  continuouslySelectedRange.startContainer,
                  continuouslySelectedRange.startOffset,
                  currentRange.endContainer,
                  currentRange.endOffset
                );
              }
            } catch (error) {
              console.warn(error);
            }
          }

          continuouslySelectedRange = undefined;
        });

        iframe.contentDocument!.addEventListener('copy', onCopyContentDocument);

        // 트랙패드 핀치 줌 disable
        iframe.contentDocument!.documentElement.addEventListener(
          'wheel',
          (event) => {
            event.ctrlKey && event.preventDefault();
          },
          { passive: false }
        );

        iframe.contentDocument!.head.insertAdjacentHTML(
          'beforeend',
          `<style>
          x.bukh-color-shared {
            cursor: default !important;
          }

          x {
            position: relative;
          }

          .${MEMO_INDICATOR_CLASS} {
            display: inline;
            position: absolute;
            cursor: pointer;
            background-size: 100%;

            border-radius: 7px;
            box-shadow: 1px 1px 1px 0px #0000001A;
            overflow: visible;

            -webkit-user-select: none;
            user-select: none;
          }

          .${MEMO_INDICATOR_CLASS}[data-type="${MEMO_INDICATOR_TYPE_MINE}"] {
            width: 14px;
            height: 14px;
            transform: translate(-7px, -8px);
            background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH0SURBVHgBvZa/T8JAFMdfa4Md+B0HxjrByOzUxKWTcXAgcSFxcXLzH/AvcNcYEh2IMhAmFhImEzfc2MRFiQ5WwQRIAO8LB1Zs6VEK3+S10DvuQ9+7d+9JJKCnIWnslmKGe5SZyoc6zJrc6tsSNdzWklxAaXbTOUREJrMqA9doESADAZBhliBvAjjHwKYrkL+VQb9u8yq4u8igdUcggyFOGfJXRauLZQsMbtwn/2Xwtf8CmbK0vBvthDWnXlNw4XH7txNvL7aSd5cxw23FcKxv7uy2a0enb48OUxJgwLUKf6DbzWqZstr+2nBNCcwpXMW179aGenL2+uAwTWdWk3lSi+bZXFVKYf29qTiFJQoWYpgin9TrSmrpOp6cMyUFoNfk9iJNEQUGNoedvcOPst1Y6SZm9HqSyA6PAiiUCnBXuRDRbcfEYJCq0BLqdWR1AdhIAOLMc/0RXGocfFatz+4rwfTLc0AjcXUAxInuGke4lOXaskdfE7u0QevTCFin9aku87bApNXLBGuyS6tkU5pCkUE3GO4v9GdC4UHXYaiKy7QAs3PumFZ36uDtzvHBWg/zNE4Rv4U1c5MvUyBveMrkv4rWZsquiUL1QDz9aKLKsy3jvDYxS97rJBrjvFCbOANeTyNsA9ZoXKgT3KytPiANEmz1fwCjG5k/WdzTfAAAAABJRU5ErkJggg==');
          }

          .${MEMO_INDICATOR_CLASS}[data-type="${MEMO_INDICATOR_TYPE_SHARED}"] {
            width: 14px;
            height: 14px;
            transform: translate(-7px, -8px);
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIhSURBVHgBvVY9b9NAGH7uYtU2qlI3AoSYvLVjf0Iklo4MDEgsjPwB/gC/gB1UIcHAwFAxdUHqyha2jmEBC5A46IfiSvH1fVw7tRqnviRuniF3vju9T+699+NRcMADi3gMbCvkYyRjwHULjDSQyDTpAEeJwrDJlrpp857FjhjtyzSCG4wYPPytMMA8hJFFtAY8zeRyWAxmHXg3VDCNhLyVEO2WblsUdLcP7P9QOJpJ+NBi+1xuhhYhBPtVF+tyQjemwGO0DHortlcxMCH0gOfLurEOtHlW8ZrHnyIapyIxfHN3K3y7udtodXNs0kcng9OXv77VbTP4yEHX5oRF6E9BGx3o/53mlJAz4V4vVsed4OTVz691RwqOgWZSwz3PboT/udvXiTfrWSJyaVYQtASVqiB839uatU8uBs2iyT03xK2xxwdVLod9Oxo9+3tQtxd+kMA6V40RzsD0XFOB7vI/bfRrNx3IchvC5WEJ6JEOXMlKeKx5LrekS9Mn/w6ra/6X9R39fS2GI8jlSR8zLl0hj8C93lKlj71TC+sQq0Oi2amxIpBLF7LA4PZhyJVHKWWBrWlNdiNLs+54rj9ju1lat06OYrzEfYsX2e1VHfNH4TUnk34onf4jwxYtgzapb8rvCaHILSMfB2gZ1DVVMTVVRqlrKDXaEFG8wHXJOFMmUnJgwT7JBL8jT+QkE6tYmRC+jlLqc1q0s4nUZ2lktXKV+hei6rHz/RqHsAAAAABJRU5ErkJggg==');
          }

          .${MEMO_INDICATOR_CLASS}[data-type="${MEMO_INDICATOR_TYPE_MINE_SHARED}"] {
            width: 28px;
            height: 14px;
            transform: translate(-14px, -8px);
            background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAcCAYAAAA0u3w+AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAOVSURBVHgB3Vg/aBNhFH93SZpLzJ9rTW06mQ7SCEq7iOIUcDCT7WBFcSkURRzcXBUKTiJ0KYiKKCpo20E7ZSkUCoKLpJMRUdPBNtpar39zuSZ3vpdL0mt7uVzTO9v4g+O7+977ku/H+/d9jwET+K5ABIcoPjTy+HBQJ05B/VAARBYgg68ZB0Aqw0C61hrGSIjEunGIgUrKEuyFoA4EJDA5z0CymoIuQSRGhC7jEwaLYTHBMgQfwLM0A8J2wQ6CJavFYQ9uaASbCBbd1w3wdpaBlHae1X4gOYqzXrCJnJ1AS3ESel2raqAKKgRLbtkLDQ4ZvS+ibOYMp0bWDwfAcofuH+mqJpMjkpDtE2YMlhctua7mj4el70rcVbXeyONQ5+iT5jjUQKC5IJw9t5ocuP1ruppOrRgMHY/eVdryGYWTxe0yRmQ5JctwuYtLk2sG/1HUxXik7Fq2YMxIeUVgudVlR81SQTpjT1siaysO7tbg3AeoE8tDP95sdGcFPZn3USjqHT7cI/N5MXtt8XO131BUTklnqYhbVucIE+OB2JWb89Ot4bxoRp/c0pX0RMrfvsHwecWz1YJIOE1WW7++kFKQnOdBa48RQQQfRm5kwShYDCnHcOMvWjoHarhRGUTO+dFTyX6OT+4deyK5e8LXvZj49jx7SUgfutfG4Tq+mqUJBeRGWdTyYm4X2JmmiO9O+2l6lzFGnV/cQSN9dNOiBXdFsMmtiBeu/knoycZfNsclibE1Ezu/NpneLxLkieCuNkTulxgLxnRlFpCTA4UdLsdi9oQ6fptKhhMsgIQbsMpyha5sKn9Mymjn2FkX7074Y1AHiCBlK9ObIxeNYx3Szr3H4J/F+AAL4JrynXFNgSWg8ykRJJcw7dfkoljrbDvS5fqERP6kWLGgdzgUZ34660qEdHckgmk4QJnUPcrH3aPV5exvB091sxiXtZGhMpGCBgI75wpTPZTbNzJySyFnpEu3fmcHXvvxNENuaulpxi7kT4gp4dXMOxOqArU0yll0EgwO2/6gnPPppG8j+ANyDvYRjMpp80aPVrwB/yAW9W4TnhE+gqeUoInlIB+VluioVkNNWGBgiF60BMlFiaStJxG7WhZlUGnw412w3J+p3Og71IkENDioL6NtPm3pyXSo7bfXoBb/hgJZjtFpOhm1DfvBhsxqh4tSQfeiYUy1DbX4bxu/26Fp3YdLz7617rF4Czimzbbu/wIauT6d1+VwUgAAAABJRU5ErkJggg==');
          }

          .${MEMO_INDICATOR_CLASS}[data-type="${MEMO_INDICATOR_TYPE_COMMENTARY}"] {
            width: 14px;
            height: 14px;
            transform: translate(-7px, -8px);
            background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJzSURBVHgBvZbBattAEIZHW9URdiEyKdRtDxa9RPEh5A1qyEXHXgKlUDCBQCF5hhxyzKkvEDD0UvAlR51C8wLBEEhkCkXuoTZpjA2m7do4VmfkjSJiy9ooSn4Q69Va++3M7syOAhKqeJ4xAjAVAAMfHV9pYoh7AG16VACnqihu3FxKDGjtCqAsILFCcO8JwDcE1+8ERJCOoPc4WIAEEuAqgnuxwA9oFbrHghu3JRVnAIcIdSKBaJk5RssgRQloPdQPYDrC3kHKwjktmnsKiHtWgfu7cZa0q5DXfJfSaZSx7rJWK/46OChHjbNslr/e3j7Or6+3p8aEa1XqiKMfK+66Om82jXn/+b6zY77Z26s+39hoht8TA5s6o6CWjTNZ9U9Pp8KJGMQiC82oD3tHRy9Gnc7Mfc0tLzt/Gg1TKxZdspraYatVGA+H2rjfjzoLpoqmFqLc+XN/3wq78NXm5qG/2oUFnl1ddQmYW1nxQU+XlnqjblcnYMR05FZDTZJNvMFA+12rUXKAjm2Xqe2fnKzFfUdupbB4iFCIkqZCArFMhjNN45HjudwgaoyA9KGUlWxxcZC3rONnpVLr79mZvxXZUqn9cmurAXLiKmV22X3M5PP/Ls/Pi13bfnv9bnhxUZcFMro38Rpxx5LAH7u7Fbif2nRoHHg8OYzKAnIrPLCIQSz/lFJZMCt5i2Ceuxg8kRwkRAxqgyTz0fM+JS0p4kTWfVGUz/SbhVbwFSYhkrY41TfXnQBIBQ92bEhZ4h4MtmUqb4u6hvYzjSLKvl0yzisTK0nvSSqMaYukysRb4McphGeADZhc1AWRlYJSn01i2AXJUv8/ZZ7z0e98+Y0AAAAASUVORK5CYII=');
          }

          .${MEMO_INDICATOR_CLASS}[data-type="${MEMO_INDICATOR_TYPE_MINE_COMMENTARY}"] {
            width: 28px;
            height: 14px;
            transform: translate(-14px, -8px);
            background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAcCAYAAAA0u3w+AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM+SURBVHgB5Zg/SBtxFMffXdJ4JJo/KjRth6RdEjNI5i4NuGQqDhWELmIRCkq3rhYcnToKrUWwQ2kcxCmLokuhi6QIGim0lw4m+IdcG20vh8n1vdwlvaSXXGIutdEv/Pzp/X738z733u+9+z0GmtBXGfzYBbFR78bGwQX1AmRoQyLenaFmBUguMQxvdAPTaBDBwthFQIEyRW0CVglXEiwAmwiaqDdHFxDBCGgcmxdMlpmAZamgSwgq1I6xtRdUqz2FDsB1SmgldxGfeUKWg7VjVYAIRxNGoY09doniEHIcIcPaixVA1S1HocuFkFGErMQMrQUnoDstVyuuoMSPkqz0Q913dSPl+1eDgdhrTxQM5PQUhPsjp4knzw8/QRs6jsV8B4uLkXrjrN0u3pme3vKMjGT0xnFPeslVKbpa1WsRaKCcwHKnPyyGqYLmrLzp95/lLNyzufRHuKBEnneLqZS/0ZzPMzPBe3NzS4NjYym98YLClGDVJG5aniOtrzkjRxlrx909t7NTN9JTZEUr+smCQTBZUp7h1pb7A826qrCxcfP85ET3hTgCgeTZ/n6Q8/l4sir1UjrtLUoSV8zljF5ikAAvPd99m5+Pal3y9uTkKvVMT49oHx7mCdAxNFQCuzEwIJxns24CNFoX3dTfMqCtRxYfPs7G9cbW3nqiksSY5ppyPs8dxWKl4HYSj0eoz21vh5u9n9yUAFt6IHK/+IorojtmIpxJ4qxggiSR5ToBx9psuDIn1h13OPJgIAKkBZp+OHLR6KPvm9prH9Z7wwcpmx9MEuty5T3R6FZvKJT+ubtb2kL2UChza2pqH1qTSID0Bd70PiQXxVzX0U86m8fz63hvz5eNxx9U/u/hYaJVQFY5NwIP/9nJ4cvs7ASYowx9iybh6irJ3mVKFhTgiokOwVTSKEfRTWhwVOpzFfO9zkJLL6HPWTSMcGWpybvh+hgxRWhBFoXpT8kCv0n/ySm+EyWLWpH1lhnmJf2uPQ++AyVldLtEqs+U/6gA4l4kF4lDlwuBVrXFp6qaDEJS+a1bLSmqcFVZ4a+qGkLShAXoosgqK/luQa8+ej0Lv7XSlO69aru00j2reBYPTZbufwMNgiw+0YPB0wAAAABJRU5ErkJggg==');
          }

          .${FOCUSED_MEMO_CLASS_SINGLE} {
            background: rgba(224, 255, 0, 0.2);
          }

          .${FOCUSED_MEMO_CLASS_DOUBLE} {
            background: rgba(224, 255, 0, 0.4);
          }

          @keyframes blink{
            0% {background: transparent;}
            100% {background: rgba(203, 308, 132, 0.58);}
          }

          .bukh-color-momo-m, .bukh-color-momo-h {
            cursor: auto !important;
          }

          .bukh-color-momo-m.animation {
            animation: blink 0.2s ease-in-out 4 alternate;
          }
        </style>`
        );
      }

      // pdf text load 이벤트가 없어서 body 감시함
      if (iframe.contentDocument!.body.innerHTML.length === 0) {
        if (!this._frameBodyMutationObserver) {
          this._frameBodyMutationObserver = new MutationObserver(() => {
            this._onPDFTextLoadAfterItemLoad();
            this._destroyFrameBodyMutationObserver();
          });
        }

        this._frameBodyMutationObserver.observe(iframe.contentDocument!.body, {
          childList: true,
        });
      } else {
        this._destroyFrameBodyMutationObserver();
      }

      iframe.contentDocument!.documentElement.dataset.bukInit = '';
    });
  }

  private _updateMemoIndicator(): void {
    const updateMemoIndicator = (
      iframe: HTMLIFrameElement,
      appendToBody: boolean
    ): void => {
      const baseBukURL = iframe.contentDocument!.documentElement.getAttribute(
        'data-bukv-base-buk-url'
      );

      if (!baseBukURL) {
        return;
      }

      const indicators = iframe.contentDocument!.querySelectorAll(
        '.' + MEMO_INDICATOR_CLASS
      );

      indicators.forEach((indicator) => {
        indicator.remove();
      });

      const iid = parseBukURL(baseBukURL).iid;

      const isSingleMode =
        this._annotationsService.getState().groupId === undefined;

      const typeMixed = isSingleMode
        ? MEMO_INDICATOR_TYPE_MINE_COMMENTARY
        : MEMO_INDICATOR_TYPE_MINE_SHARED;
      const typeOthers = isSingleMode
        ? MEMO_INDICATOR_TYPE_COMMENTARY
        : MEMO_INDICATOR_TYPE_SHARED;

      const memoGroupInfoMap = this._annotationsService
        .getMemosOfItem(iid!)
        .reduce((map, group, groupIndex) => {
          const memo = group[0];
          const range = parseBukURL(memo.url).range;
          const type = getMemoGroupType(group);

          if (range) {
            const key = `${range}.${MOMO_MEMO_STYLE_CLASS}`;
            map.set(key, {
              index: groupIndex,
              type:
                type === 'mine'
                  ? MEMO_INDICATOR_TYPE_MINE
                  : type === 'others'
                  ? typeOthers
                  : typeMixed,
              focus:
                this._referer?.query?.mId &&
                group.findIndex((m) => m.id === this._referer?.query?.mId) !==
                  -1,
            });
          }

          return map;
        }, new Map<string, { index: number; type: string; focus: boolean }>());

      if (memoGroupInfoMap.size > 0) {
        const querySelector = Array.from(memoGroupInfoMap.keys())
          .map((hId) => `x.bukh-start[highlight-id="${hId}"]`)
          .join(',');

        iframe
          .contentDocument!.querySelectorAll(querySelector)
          .forEach((start) => {
            const div = start.ownerDocument.createElement('div');
            div.className = 'buk-memo-indicator';
            const info = memoGroupInfoMap.get(
              start.getAttribute('highlight-id')!
            )!;
            div.dataset.groupIdx = info.index.toString();
            div.dataset.type = info.type;

            div.addEventListener('click', (event) => {
              (event as any).bukvEventHandled = true;
              this._onMemoIndicatorClick(event.target as HTMLElement);
            });

            if (appendToBody) {
              const rect = (start as HTMLElement).getClientRects()[0];

              div.style.left = rect.left + 'px';
              div.style.top = rect.top + 'px';

              start.ownerDocument.body.appendChild(div);
            } else {
              div.style.left = '0px';
              div.style.top = '0px';

              start.appendChild(div);
            }

            if (info.focus) {
              this._onMemoIndicatorClick(div);
              this._referer = undefined;
            }
          });
      }

      if (
        this._referer?.query?.cPreview != null &&
        this._referer?.query?.memo != null &&
        this._referer.iid === iid
      ) {
        iframe
          .contentDocument!.querySelectorAll(
            `x.bukh-start[highlight-id="${this._referer.anchor}.${MOMO_MEMO_STYLE_CLASS}"]`
          )
          .forEach((start) => {
            const div = start.ownerDocument.createElement('div');
            div.className = 'buk-memo-indicator';
            div.dataset.type = MEMO_INDICATOR_TYPE_COMMENTARY;

            div.addEventListener('click', (event) => {
              (event as any).bukvEventHandled = true;
              this._onCommentaryPreviewMemoIndicatorClick(event);
            });

            // z index 내 메모랑 겹쳤을 때
            if (appendToBody) {
              const rect = (start as HTMLElement).getClientRects()[0];

              div.style.left = rect.left + 'px';
              div.style.top = rect.top + 'px';

              start.ownerDocument.body.appendChild(div);
            } else {
              div.style.left = '0px';
              div.style.top = '0px';

              start.appendChild(div);
            }
          });
      }
    };

    getActiveIframes(this._element.nativeElement, 'bukv-pdf-page').forEach(
      (iframe) => {
        updateMemoIndicator(iframe, true);
      }
    );

    getActiveIframes(this._element.nativeElement, 'bukv-page').forEach(
      (iframe) => {
        updateMemoIndicator(iframe, false);
      }
    );
  }

  private _blinkMemo(url: string): void {
    const address = parseBukURL(url);

    if (!address.range) {
      return;
    }

    getActiveIframes(
      this._element.nativeElement,
      this._book?.meta.type === BookType.PDF ? 'bukv-pdf-page' : 'bukv-page'
    ).forEach((iframe) => {
      const baseBukURL = iframe.contentDocument!.documentElement.getAttribute(
        'data-bukv-base-buk-url'
      );

      if (!baseBukURL) {
        return;
      }

      if (url.indexOf(baseBukURL) === -1) {
        return;
      }

      const selector = `x[highlight-id="${address.range}.${MOMO_MEMO_STYLE_CLASS}"]`;
      const elem = iframe.contentDocument?.querySelectorAll(selector);

      if (elem) {
        elem.forEach((el) => {
          el.classList.add('animation');
        });

        setTimeout(() => {
          elem.forEach((el) => {
            el.classList.remove('animation');
          });
        }, 900);

        address.query = {
          highlight: 'transparent',
        };
        this._openBook(address);
      }
    });
  }

  private _clearFocusedMemo(): void {
    getActiveIframes(
      this._element.nativeElement,
      this._book?.meta.type === BookType.PDF ? 'bukv-pdf-page' : 'bukv-page'
    ).forEach((iframe) => {
      iframe.contentDocument
        ?.querySelectorAll(
          `.${FOCUSED_MEMO_CLASS_SINGLE}, .${FOCUSED_MEMO_CLASS_DOUBLE}`
        )
        .forEach((elem) => {
          elem.classList.remove(
            FOCUSED_MEMO_CLASS_SINGLE,
            FOCUSED_MEMO_CLASS_DOUBLE
          );
        });
    });
  }

  private _focusMemo(
    url: string,
    type: 'single' | 'double',
    scroll?: boolean
  ): void {
    const address = parseBukURL(url);

    if (!address.range) {
      return;
    }

    this._clearFocusedMemo();

    getActiveIframes(
      this._element.nativeElement,
      this._book?.meta.type === BookType.PDF ? 'bukv-pdf-page' : 'bukv-page'
    ).forEach((iframe) => {
      const baseBukURL = iframe.contentDocument!.documentElement.getAttribute(
        'data-bukv-base-buk-url'
      );

      if (!baseBukURL) {
        return;
      }

      if (url.indexOf(baseBukURL) === -1) {
        return;
      }

      const selector = `x[highlight-id="${address.range}.${MOMO_MEMO_STYLE_CLASS}"]`;
      const elem = iframe.contentDocument?.querySelectorAll(selector);

      if (elem) {
        elem.forEach((el) => {
          el.classList.add(
            type === 'single'
              ? FOCUSED_MEMO_CLASS_SINGLE
              : FOCUSED_MEMO_CLASS_DOUBLE
          );
        });

        if (scroll) {
          address.query = {
            highlight: 'transparent',
          };
          this._openBook(address);
        }
      }
    });
  }

  private _onMemoIndicatorClick(indicatorElem: HTMLElement): void {
    const baseBukURL = indicatorElem.ownerDocument.documentElement.getAttribute(
      'data-bukv-base-buk-url'
    );

    if (!baseBukURL) {
      return;
    }

    const iid = parseBukURL(baseBukURL).iid!;

    const groupIndex = parseInt(indicatorElem.dataset.groupIdx!);

    const memos = this._annotationsService.getMemosOfItem(iid)[groupIndex];
    const shortestMemo = memos.reduce((prev, current) =>
      prev && prev.text.length < current.text.length ? prev : current
    );

    const type =
      indicatorElem.dataset.type === MEMO_INDICATOR_TYPE_MINE_COMMENTARY ||
      indicatorElem.dataset.type === MEMO_INDICATOR_TYPE_MINE_SHARED
        ? 'double'
        : 'single';

    this._focusMemo(shortestMemo.url, type);

    this._ngZone.run(() => {
      this._eventBusService.fire('ContentsComponent:memoIndicatorClick', {
        iid,
        groupIndex,
        trigger: indicatorElem,
      });
    });
  }

  private _onCommentaryPreviewMemoIndicatorClick(event: MouseEvent): void {
    if (!this._referer?.query?.cPreview) {
      return;
    }

    this._focusMemo(this._referer.toString(), 'single');

    this._ngZone.run(() => {
      this._eventBusService.fire(
        'ContentsComponent:commentaryPreviewMemoIndicatorClick',
        {
          memo: {
            avatarImageURL: this._referer!.query!.avatar,
            nickname: this._referer!.query!.nickname,
            text: this._referer!.query!.text,
            content: this._referer!.query!.memo!,
          },
          trigger: event.currentTarget! as HTMLElement,
        }
      );
    });
  }

  private _onPDFTextLoadAfterItemLoad(): void {
    this._highlightRefererRange();
    this._updateMemoIndicator();
  }

  private _highlightRefererRange(): void {
    if (!this._referer?.range) {
      return;
    }

    // 책장에서 넘어온 경우, 내 메모 포커스인 경우 하이라이트 안함
    if (
      this._referer.query?.bookshelf != null ||
      this._referer.query?.mId != null
    ) {
      return;
    }

    const viewerAddress = this._viewerComp.getCurrentAddress();

    if (!viewerAddress) {
      return;
    }

    const currentIid = viewerAddress.iid ?? this._book?.items[0].iid;

    if (this._referer.iid !== currentIid) {
      return;
    }

    // 기본 색상: 푸른색
    let highlightStyleClass = SHARED_HIGHLIGHT_STYLE_CLASS;

    // 코멘터리 미리보기 시 코멘터리 메모 또는 하이라이트 스타일 적용
    if (this._referer.query?.cPreview) {
      highlightStyleClass =
        this._referer.query.memo != null
          ? MOMO_MEMO_STYLE_CLASS
          : MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY;
    }

    try {
      this._viewerComp.createHighlight(
        this._referer.toString(),
        highlightStyleClass,
        { expandToWord: false, emitEvent: false }
      );
    } catch (error) {
      console.log(error);
    }
  }

  private _destroyFrameBodyMutationObserver(): void {
    this._frameBodyMutationObserver?.disconnect();
    this._frameBodyMutationObserver = undefined;
  }

  private _onSettingsChanged(property: keyof Settings, value: unknown): void {
    if (!this._viewerComp) {
      return;
    }

    if (property === 'fontFace' || property === 'fontSize') {
      this._sharedPageInfo = null;
    }

    this._viewerComp.updateSettings(property, value);
  }

  private _openSettingsDialog(): void {
    this._dialogService.open(SettingsDialogComponent, {
      data: {
        forceDisableSettingsKeys: this._shouldFixPagingMode
          ? ['pagingMode']
          : undefined,
        currentSettings: this._viewerComp.getCurrentSettings(),
        availableFonts: this._viewerComp.getFontsCurrentlyAvailable(),
        onChange: (property: keyof Settings, value: unknown): void => {
          this._onSettingsChanged(property, value);
        },
      },
    });
  }

  private _openBookInfoDialog(): void {
    this._dialogService
      .open(BookInfoDialogComponent, {
        data: {
          book: this._book!,
          currentTOCIndex: this._currentTOCIndex,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        result && this._openBook(result.url);
      });
  }

  private _openReadingModeDialog(): void {
    if (!this._book) {
      return;
    }

    if (this._book.meta.is_subcontent) {
      this._toastService.openWarning(
        '부록은 혼자읽기 모드로만 읽기가 가능합니다'
      );
      return;
    }

    if (!this._book.isOwnedByUser) {
      this._toastService.openWarning(
        '미리보기 중에는 읽기 모드 변경이 불가능합니다.'
      );
      return;
    }

    this._dialogService.open(ReadingModeDialogComponent);
  }
}
