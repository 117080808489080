import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import {
  CallbackWorker,
  PDFDrawingService as ViewerPDFDrawingService,
  ViewerModule as SharedViewerModule,
  ImageSharingService as ViewerImageSharingService,
} from 'viewer';
import { PDFTextInfo } from '@bukio/viewer';
import * as Sentry from '@sentry/angular';
import { Angulartics2Module } from 'angulartics2';

import { environment } from '../environments/environment';

import { appRoutes } from './app.routes';

import { OriginInterceptor } from './origin.interceptor';

// libs
import { ReadingGroupModule } from 'reading-group';
import { UserModule } from 'user';
import { BookshelfModule } from 'bookshelf';
import { DialogService, SharedUIModule } from 'shared/ui';

// Page
import { AppComponent } from './app.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { MyBookshelfPageComponent } from './pages/my-bookshelf-page/my-bookshelf-page.component';
import { MyBookshelfDetailPageComponent } from './pages/my-bookshelf-detail-page/my-bookshelf-detail-page.component';

// Component
import { HeaderComponent } from './components/header/header.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';

// Dialogs
import { OthersBookshelfDialogComponent } from './dialogs/others-bookshelf-dialog/others-bookshelf-dialog.component';
import {
  AMPLITUDE_API_KEY,
  AmplitudeService,
  SharedAnalyticsService,
  SharedAuthService,
  SharedUserService,
} from 'shared/services';
import { UserService } from './services/user.service';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './services/auth.service';
import { ViewerPageComponent } from './pages/viewer-page/viewer-page.component';
import { PDFDrawingService } from './services/pdf-drawing.service';
import { SettingsDrawingPageComponent } from './pages/settings-drawing-page/settings-drawing-page.component';
import { SettingsMainPageComponent } from './pages/settings-main-page/settings-main-page.component';
import { DrawingBackupDialogComponent } from './dialogs/drawing-backup-dialog/drawing-backup-dialog.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LibraryPageComponent } from './pages/library-page/library-page.component';
import { OfflineDialogComponent } from './dialogs/offline-dialog/offline-dialog.component';
import { BookshelfComponent } from './components/bookshelf/bookshelf.component';
import { GroupListComponent } from './components/group-list/group-list.component';
import { OthersBookshelfDetailDialogComponent } from './dialogs/others-bookshelf-detail-dialog/others-bookshelf-detail-dialog.component';
import { ImageSharingService } from './services/image-sharing.service';
import { UpdateNotificationDialogComponent } from './dialogs/update-notification-dialog/update-notification-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    SettingsPageComponent,
    SearchPageComponent,
    TabBarComponent,
    HeaderComponent,
    MyBookshelfPageComponent,
    MyBookshelfDetailPageComponent,
    OthersBookshelfDialogComponent,
    ViewerPageComponent,
    SettingsDrawingPageComponent,
    SettingsMainPageComponent,
    DrawingBackupDialogComponent,
    LoginPageComponent,
    LibraryPageComponent,
    OfflineDialogComponent,
    BookshelfComponent,
    GroupListComponent,
    OthersBookshelfDetailDialogComponent,
    UpdateNotificationDialogComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      resolveNavigationPromiseOnError: true,
      scrollPositionRestoration: 'top',
    }),
    Angulartics2Module.forRoot(),
    SharedUIModule,
    ReadingGroupModule,
    BookshelfModule,
    UserModule,
    BrowserAnimationsModule,
    SharedViewerModule.forRoot({
      userService: {
        provide: SharedUserService,
        useExisting: UserService,
      },
      analyticsService: {
        provide: SharedAnalyticsService,
        useExisting: AnalyticsService,
      },
      authService: {
        provide: SharedAuthService,
        useExisting: AuthService,
      },
      pdfDrawingService: {
        provide: ViewerPDFDrawingService,
        useExisting: PDFDrawingService,
      },
      imageSharingService: {
        provide: ViewerImageSharingService,
        useExisting: ImageSharingService,
      },
      contentsDecryptionWorkers: {
        pdfText: new CallbackWorker<
          [text: ArrayBuffer, bid: string, rand: string],
          PDFTextInfo[]
        >(
          new Worker(
            new URL('./workers/pdf-text-decryption.worker.ts', import.meta.url)
          )
        ),
        pdfImage: new CallbackWorker<ArrayBuffer, Blob>(
          new Worker(
            new URL('./workers/pdf-image-decryption.worker.ts', import.meta.url)
          )
        ),
        bukHTML: new CallbackWorker<[html: ArrayBuffer, nonce: string], string>(
          new Worker(
            new URL('./workers/buk-html-decryption.worker.ts', import.meta.url)
          )
        ),
      },
      environment: {
        production: environment.production,
        serverOrigin: environment.serverOrigin,
        dashboardOrigin: environment.dashboardOrigin,
        isApp: true,
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => (): void => {
        //
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: OriginInterceptor, multi: true },
    {
      provide: AMPLITUDE_API_KEY,
      useValue: environment.amplitudeAPIKey,
    },
    AmplitudeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
