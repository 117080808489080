<h1 class="title"><b>필기기능</b> 업데이트 안내</h1>
<section>
  <div class="section-title">
    <span class="num">01</span>
    <h2><b>펜 색상 직접 지정 기능</b>이 추가되었어요!</h2>
  </div>
  <div class="section-content">
    <p>
      색상 팔레트에서 색상 옵션을 선택한 후, 한번 더 선택하면 색상 스펙트럼
      휠에서 원하는 색상을 직접 지정할 수 있어요.<br />
      <img class="image-1" src="assets/images/viewer-noti-1-img1.png" />
    </p>
  </div>
</section>
<section>
  <div class="section-title">
    <span class="num">02</span>
    <h2><b>필기 시, 페이지 이동 기능이</b> 변경되었어요!</h2>
  </div>
  <div class="section-content">
    <p>
      필기 중 기기에 손이 닿아 페이지 넘김으로 인식되는 경우를 방지하기 위해
      필기 중에는 페이지 위에서의 제스쳐가 아닌 필기 툴바의 페이지 이동 버튼을
      통해서만 페이지를 이동할 수 있도록 변경되었어요.<br />
      <img class="image-2" src="assets/images/viewer-noti-1-img2.png" />
    </p>
  </div>
</section>
<section>
  <p>
    필기기능에 관해 궁금한 점이 있으시면 언제든 고객센터(support&#64;buk.io)로
    문의해 주세요.<br /><br />
    감사합니다.
  </p>
</section>
