import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Memo } from 'shared/models';
import { DialogService, ToastService } from 'shared/ui';
import { finalize } from 'rxjs';

import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';

import { MemoType } from '../memo/memo.component';
import { MemoExportDialogComponent } from '../../dialogs/memo-export-dialog/memo-export-dialog.component';
import { MemoDialogComponent } from '../../dialogs/memo-dialog/memo-dialog.component';
import { MemoCopyDialogComponent } from '../../dialogs/memo-copy-dialog/memo-copy-dialog.component';
import { MemoReportDialogComponent } from '../../dialogs/memo-report-dialog/memo-report-dialog.component';
import { APIError } from 'shared/services';
import { UserDetailsDialogComponent } from '../../dialogs/user-details-dialog/user-details-dialog.component';
import { CommentatorDetailsDialogComponent } from '../../dialogs/commentator-details-dialog/commentator-details-dialog.component';

@Component({
  selector: 'viewer-reading-mode-memo',
  templateUrl: './reading-mode-memo.component.html',
  styleUrl: './reading-mode-memo.component.scss',
})
export class ReadingModeMemoComponent {
  @Output() memoDeleted = new EventEmitter<void>();
  @Output() memoBlinded = new EventEmitter<void>();
  @Output() memoUserBlocked = new EventEmitter<void>();
  @Output() memoSeeDetailsButtonClicked = new EventEmitter<void>();

  @Input() bid!: string;
  @Input() text!: string;
  @Input() limitHeight = false;
  @Input() memo!: Memo;
  @Input() isAuthorOnline?: boolean;

  private _groupId?: number;
  private _commentaryId?: number;

  private _isLikeRequesting = false;

  constructor(
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService,
    private _readingGroupsService: ReadingGroupsService,
    private _toastService: ToastService,
    private _dialogService: DialogService
  ) {
    this._groupId = this._annotationsService.getState().groupId;
    this._commentaryId = this._annotationsService.getState().commentaryId;
  }

  get _singleMode(): boolean {
    return this._groupId == null;
  }

  get type(): MemoType {
    if (this.memo.is_mine) {
      return 'mine';
    }

    return this._singleMode ? 'commentary' : 'group';
  }

  _onSeeDetailsButtonClick(): void {
    this.memoSeeDetailsButtonClicked.emit();

    this._eventBusService.fire(
      'ReadingModeMemoComponent:seeDetailsButtonClick',
      {
        url: this.memo.url,
      }
    );
  }

  _onEditMenuClick(): void {
    this._dialogService
      .open(MemoDialogComponent, {
        data: {
          id: this.memo.id,
          text: this.text,
          content: this.memo.content,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.deleted) {
          this.memoDeleted.emit();
        } else if (result?.updatedMemo) {
          // this.memo.content = result.updatedMemo;
          // this._content = result.updatedMemo;
        }
      });
  }

  _onDeleteMenuClick(): void {
    this._annotationsService.deleteMemosById(this.memo.id).subscribe({
      next: () => {
        this.memoDeleted.emit();
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  _onExportMenuClick(): void {
    if (this._readingGroupsService.myGroups.length === 0) {
      this._toastService.openWarning('메모 내보내기가 가능한 그룹이 없습니다.');
    } else {
      this._dialogService.open(MemoExportDialogComponent, {
        data: {
          id: this.memo.id,
          content: this.memo.content,
        },
      });
    }
  }

  _onReportMenuClick(): void {
    this._dialogService
      .open(MemoReportDialogComponent, {
        data: {
          authorName: this.memo.author.nickname,
          authorAvatarImageURL: this.memo.author.avatar_image_url,
          memoId: this.memo.id,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.blocked) {
          this.memoUserBlocked.emit();
        }
      });
  }

  _onBlindMenuClick(): void {
    this._annotationsService.blockMemo(this.memo.id).subscribe(() => {
      this.memoBlinded.emit();
    });
  }

  _onLikeButtonClick(): void {
    if (this._isLikeRequesting) {
      return;
    }

    this._isLikeRequesting = true;

    const delta = this.memo.is_liked === 1 ? -1 : 1;

    // this._likeCount += delta;

    const request$ =
      delta > 0
        ? this._annotationsService.likeMemo(this.memo.id)
        : this._annotationsService.unlikeMemo(this.memo.id);

    request$
      .pipe(
        finalize(() => {
          this._isLikeRequesting = false;
        })
      )
      .subscribe({
        next: () => {
          if (delta === 1) {
            this._toastService.open('메모 추천이 완료되었습니다.');
          }
        },
        error: () => {
          // this._likeCount -= delta;
        },
      });
  }

  _onCopyToSingleModeButtonClick(): void {
    this._dialogService
      .open(MemoCopyDialogComponent, {
        data: {
          text: this.text,
          content: this.memo.content,
          author: this.memo.author.nickname,
          memoId: this.memo.id,
        },
      })
      .afterClosed()
      .subscribe((copied) => {
        // this._copyCount++;
      });
  }

  _onAuthorClick(): void {
    if (this._commentaryId != null) {
      this._dialogService.open(CommentatorDetailsDialogComponent, {
        data: {
          bid: this.bid,
          avatarImageURL: this.memo.author.avatar_image_url,
          nickname: this.memo.author.nickname,
          commentaryId: this._commentaryId,
        },
      });
    } else if (this._groupId != null) {
      this._dialogService.open(UserDetailsDialogComponent, {
        data: {
          bid: this.bid,
          userId: this.memo.author.id,
          isUserBlocked: false,
          groupId: this._groupId,
        },
      });
    }
  }
}
