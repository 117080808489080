import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Subject, takeUntil } from 'rxjs';

import { UserMemo } from 'shared/models';
import { APIError, MemosAPIService } from 'shared/services';
import { DialogService, ToastService } from 'shared/ui';

import { AnnotationsV2Service } from '../../services/annotations-v2.service';

import { AnnotationListComponent } from '../annotation-list/annotation-list.component';
import { MemoExportDialogComponent } from '../../dialogs/memo-export-dialog/memo-export-dialog.component';
import { MemoDialogComponent } from '../../dialogs/memo-dialog/memo-dialog.component';
import { EventBusService } from '../../services/event-bus.service';
import { UIStateService } from '../../services/ui-state.service';

function createCopyText(memo: UserMemo): string {
  return `${memo.text}\n:${memo.content}`;
}

function createMultipleCopyText(memos: UserMemo[]): string {
  return memos.map((memo) => '- ' + createCopyText(memo)).join('\n\n');
}

@Component({
  selector: 'viewer-memo-list',
  templateUrl: './memo-list.component.html',
  styleUrls: [
    '../annotation-list/annotation-list.component.scss',
    './memo-list.component.scss',
  ],
})
export class MemoListComponent
  extends AnnotationListComponent<UserMemo>
  implements OnInit, OnDestroy
{
  private _unsubscriber = new Subject<void>();

  public _groupId?: number;

  public _isCommentaryEditorMode = false;

  constructor(
    private _clipboard: Clipboard,
    private _memosAPIService: MemosAPIService,
    private _toastService: ToastService,
    private _dialogService: DialogService,
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService,
    private _uiStateService: UIStateService
  ) {
    super();
  }

  @Input() bid?: string;

  override ngOnInit(): void {
    this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;

    this._eventBusService
      .on('ContentsComponent:memoUpdated')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this.loadItems();
      });

    this._annotationsService.state$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((state) => {
        this._groupId = state.groupId;
      });

    super.ngOnInit();
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  public override loadItems(): void {
    if (!this.bid) {
      return;
    }

    this._memosAPIService
      .get(
        this.bid,
        0,
        999,
        this._isCommentaryEditorMode
          ? {
              commentary_id: this._annotationsService.getState().commentaryId,
              filter: 'theirs',
            }
          : {
              group_id: this._groupId,
              filter: 'mine',
            }
      )
      .subscribe((memos) => {
        this._items = memos.map((memo) => ({
          ...memo,
          selected: false,
          updatedDateTime: new Date(memo.created_at).getTime(),
        }));
        this._sortItems();
      });
  }

  _onItemClick(item: UserMemo): void {
    this.itemClicked.emit(item.url);
  }

  _onCopySelectedItemsButtonClick(): void {
    this._clipboard.copy(createMultipleCopyText(this._getSelectedItems()));
    this._toastService.open('메모가 복사되었습니다.');
  }

  _onDeleteSelectedItemsButtonClick(): void {
    const ids = this._getSelectedItems().map((item) => item.id);
    this._deleteItems(ids);
  }

  _onEditMenuClick(memo: UserMemo): void {
    if (
      this._uiStateService.isCommentaryEditor &&
      !this._uiStateService.canEditCommentary
    ) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    this._dialogService
      .open(MemoDialogComponent, {
        data: {
          id: memo.id,
          text: memo.text,
          content: memo.content,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.deleted) {
          this._onItemsDeleted([memo.id]);
        } else if (result?.updatedMemo) {
          memo.content = result.updatedMemo;
        }
      });
  }

  _onDeleteMenuClick(memo: UserMemo): void {
    this._deleteItems([memo.id]);
  }

  _onExportMenuClick(memo: UserMemo): void {
    this._dialogService.open(MemoExportDialogComponent, {
      data: {
        id: memo.id,
        content: memo.content,
      },
    });
  }

  _onCopyMenuClick(memo: UserMemo): void {
    this._clipboard.copy(createCopyText(memo));
    this._toastService.open('메모가 복사되었습니다.');
  }

  private _deleteItems(ids: string[]): void {
    if (
      this._uiStateService.isCommentaryEditor &&
      !this._uiStateService.canEditCommentary
    ) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    this._annotationsService.deleteMemosById(ids).subscribe({
      next: () => {
        this._onItemsDeleted(ids);
        this._toastService.open('메모가 삭제되었습니다.');
      },
      error: (error: APIError) => {
        this._toastService.open(error.message);
      },
    });
  }

  private _onItemsDeleted(ids: string[]): void {
    this._items = this._items.filter((item) => ids.indexOf(item.id) === -1);
    this._onItemSelectionChange();
  }
}
