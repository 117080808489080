<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  책 정보
</sui-dialog-header>
<div class="body" #scrollContainer>
  <!-- <div class="admin-bar" *ngIf="_dashboardURL">
    {{ 'viewer.bookInfoDialog.messages.admin' | translate }}
    &nbsp;<a href="{{ _dashboardURL }}" target="_blank">{{
      'viewer.bookInfoDialog.actions.goToBookDashboard' | translate
    }}</a>
  </div> -->
  <div class="top">
    <div class="cover-container">
      <img class="cover" src="{{ _data.book.meta.cover }}" />
    </div>
    <div class="info">
      <div class="title">{{ _data.book.meta.title }}</div>
      <!-- <div *ngIf="_data.book.meta.subtitle" class="subtitle info-row">
        {{ _data.book.meta.subtitle }}
      </div> -->
      <div *ngIf="_data.book.meta.author" class="author">
        {{ _data.book.meta.author }}
      </div>
      <div class="spacer"></div>
      <button
        sui-box-button
        varSize="medium44"
        *ngIf="_hasBookInfoPage"
        (click)="_onBookInfoButtonClick()"
      >
        책 정보 보기
        <sui-icon name="오른쪽 이동"></sui-icon>
      </button>
    </div>
  </div>
  <div class="bottom">
    <div
      class="subcontents"
      *ngIf="!_isApp && _data.book.meta.subcontents.length > 0"
    >
      <div class="section-title">
        <sui-icon name="부록"></sui-icon><span>이 책의 부록 보기</span>
      </div>
      <div class="list">
        <a
          href="/@{{ subcontent.content.id }}/cover"
          target="_blank"
          class="subcontent"
          *ngFor="let subcontent of _data.book.meta.subcontents"
        >
          <div class="title">{{ subcontent.title }}</div>
          <sui-icon class="icon" name="오른쪽 이동"></sui-icon>
        </a>
      </div>
    </div>
    <div class="toc">
      <ul
        *ngIf="
          _data.book.meta.type !== 'pdf' || _hasTextToc;
          else tocPlaceholderTpl
        "
      >
        <li
          class="toc-item"
          *ngFor="let t of _toc; index as i"
          (click)="_onTOCItemClick(t)"
          [class.is-active]="_data.currentTOCIndex === i"
          [class.is-locked]="t.locked"
        >
          <span
            class="toc-item-text"
            [innerHtml]="t.title"
            [style.padding-left.em]="t.depth * 0.5"
          ></span>
          <sui-icon class="icon" name="잠금" *ngIf="t.locked"></sui-icon>
          <sui-icon
            class="icon"
            name="오른쪽 이동"
            *ngIf="!t.locked"
          ></sui-icon>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #tocPlaceholderTpl>
  <div class="toc-placeholder">목차가 없습니다.</div>
</ng-template>
