import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil, tap, debounceTime } from 'rxjs/operators';

import { Address, BookType } from '@bukio/viewer';

import { User } from 'shared/models';
import { SharedUserService } from 'shared/services';
import { AlertService } from 'shared/ui';

import { VIEWER_ENVIRONMENT, ViewerModuleEnvironment } from '../viewer.module';

import { Url } from '../constants/url';
import { EventBusService } from './event-bus.service';
import { Book } from '../models/book.model';
import { PDFDrawingService } from './pdf-drawing.service';

interface History {
  bukId: string;
  type: string;
  url: string;
}

function createHistory(
  book: Book,
  address: Address,
  commentaryId?: number
): History | null {
  const historyAddress = address.clone();

  if (historyAddress.query) {
    const query = historyAddress.query;

    historyAddress.query = {};

    if (query.version) {
      historyAddress.query.version = query.version;
    }

    if (query.l) {
      historyAddress.query.l = query.l;
    }
  }

  if (commentaryId != null) {
    if (!historyAddress.query) {
      historyAddress.query = {};
    }

    historyAddress.query.cId = commentaryId;
  }

  if (book.meta.type !== BookType.Wiki) {
    return {
      bukId: address.bid,
      type: book.meta.type,
      url: historyAddress.toString(),
    };
  } else if (address.iid && address.iid !== 'cover') {
    return {
      bukId: address.bid + '/' + address.iid.replace(/_/g, ' '), // 예전 포맷 맞춤
      type: 'mediawiki',
      url: historyAddress.toString(),
    };
  }

  return null;
}

@Injectable({
  providedIn: 'root',
})
export class BukHistoryService implements OnDestroy {
  private user?: User | null;
  private histories: History[] = [];

  private address?: Address;
  private book?: Book;
  private isFixedLayoutBook = false;
  private isMultiColumnViewer = false;
  public commentaryId?: number;

  private isAlertShowing = false;

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpClient,
    private userService: SharedUserService,
    private eventBusService: EventBusService,
    @Inject(VIEWER_ENVIRONMENT) private _environment: ViewerModuleEnvironment,
    private alertService: AlertService,
    @Optional() private _pdfDrawingService: PDFDrawingService
  ) {
    this.userService.user
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe((user) => (this.user = user));

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ book }) => {
        this.book = book;
        this.isFixedLayoutBook = book.items.every(
          (item) => item.layout === 'fixed'
        );
        this.postHistory(true);
      });

    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ address }) => {
        this.address = address;
      });

    eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        debounceTime(300), // page.settled 대신
        tap(() => this.pushHistory()),
        debounceTime(2000)
      )
      .subscribe(() => {
        this.postHistories(false);
        this.histories = [];
      });

    eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ settings }) => {
        this.isMultiColumnViewer = settings.multiColumn.value;
      });

    window.addEventListener('unload', () => {
      this.postHistories(false);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  postHistory(init: boolean): void {
    if (!this.address || !this.book) {
      return;
    }

    const postData = createHistory(this.book, this.address, this.commentaryId);

    if (postData) {
      this.http
        .post(
          Url.history,
          {
            items: [postData],
            init,
          },
          {
            observe: 'response',
            withCredentials: true,
          }
        )
        .subscribe(
          (res) => {
            console.log('post history succeed');
            const exceedViewerCount = res.headers.get('x-exceed-viewer-count');
            if (exceedViewerCount) {
              this.handlePostHistoryExceedViewerCountError();
            }
          },
          (err) => {
            this.handlePostHistoryError(err);
            console.log('post history failed', err);
          }
        );
    }
  }

  postHistories(init: boolean): void {
    this.http
      .post(
        Url.history,
        {
          init,
          items: this.histories,
        },
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .subscribe(
        (res) => {
          console.log('post history succeed');
          const exceedViewerCount = res.headers.get('x-exceed-viewer-count');
          if (exceedViewerCount) {
            this.handlePostHistoryExceedViewerCountError();
          }
        },
        (error: HttpErrorResponse) => {
          this.handlePostHistoryError(error);
          console.log('post histories failed', error);
        }
      );
  }

  private handlePostHistoryExceedViewerCountError(): void {
    if (this.isAlertShowing) {
      return;
    }

    this.isAlertShowing = true;

    this._pdfDrawingService?.saveDrawings().subscribe();

    this.alertService
      .openConfirm(
        undefined,
        '이용 중인 계정의 동시 접속 가능 디바이스 개수가 초과되어 뷰어를 종료합니다.'
      )
      .afterClosed()
      .subscribe(() => {
        if (this._environment.isApp) {
          location.href = '/';
        } else {
          location.href = '/@' + this.book?.meta.bid || '/';
        }
      });
  }

  private handlePostHistoryError(error: HttpErrorResponse): void {
    if (
      !this.user ||
      error.status !== 401 ||
      isCustomDomain === true ||
      this.isAlertShowing
    ) {
      return;
    }

    this.isAlertShowing = true;

    this._pdfDrawingService?.saveDrawings().subscribe();

    this.alertService
      .openConfirm(
        undefined,
        '최대 동시 접속 가능 디바이스 개수를 초과하여 로그아웃 되었습니다.'
      )
      .afterClosed()
      .subscribe(() => {
        location.reload();
      });
  }

  pushHistory(): void {
    if (!this.book || !this.address) {
      return;
    }

    let history = createHistory(this.book, this.address, this.commentaryId);

    history && this.histories.push(history);

    if (this.isMultiColumnViewer && this.isFixedLayoutBook) {
      const item = this.book.getItem(this.address.iid);

      if (item?.pageSpread === 'right') {
        const prevItem = this.book.getPrevItem(this.address.iid);

        if (prevItem && prevItem.url && prevItem.pageSpread === 'left') {
          history = createHistory(
            this.book,
            new Address(this.book.meta.bid, prevItem.iid),
            this.commentaryId
          );

          history && this.histories.push(history);
        }
      }
    }
  }
}
