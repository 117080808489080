import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WritersAPIService {
  constructor(private _http: HttpClient) {}

  getMyProfile(): Observable<any> {
    return this._http.get<any>(`/api/v1/writers/me`, {
      withCredentials: true,
    });
  }
}
