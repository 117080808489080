export interface LibraryPricingItem {
  cartItem: {
    eid: string;
    options: any;
    purchaseOptions: { [key: number]: number }[];
  };
  description: string;
  enable: number; // boolean
  price: number;
  regularPrice: number;
  title: string;
}

export interface PurchaseOption {
  countable: number; // boolean
  items: PurchaseOptionItem[];
  name: string;
  optionId: number;
  value: string;
}

export interface PurchaseOptionItem {
  itemId: number;
  name: string;
  value: string;
  price: number;
  regularPrice: number;
}

export interface Library {
  id: number;
  name: string;
  eid: string;
  description: string;
  logo: string | null;
  bigLogo: string | null;
  emptyLogo?: boolean;
  type: 'public' | 'private';
  salesNote: string | null;
  currency: string;
  // closedDate: null; // FIXME: 타입
  contact: string;
  regMessage: string | null;
  promotion: string;
  preview: string; // json
  listOpen: number | null; // boolean
  published: number; // boolean
  paperId: string | null;
  codeEnabled: number; // boolean
  emailEnabled: number; // boolean
  publisherId: number;
  headerLayout: string | null;
  headerBackground: string | null;
  useArticle: number; // 사용 x
  useBook: number; // boolean
  useCustomHome: number; // boolean
  phone: string | null;
  email: string | null;
  return_policy: string | null;
  service: 'library' | 'ecobag';
  subscribers: number;
  closed: number | null; // boolean
  subscribed: number; // boolean
  years: string[];
  libraries: string[];
  purchaseOptions: PurchaseOption[];
  cp: {
    id: number;
    name: string;
  };
  pricing: {
    readable: string;
    period: string;
    complex: LibraryPricingItem;
    digital: LibraryPricingItem;
    paper: LibraryPricingItem;
  }[];
  tabs: { tabId: number; tabName: string }[];
  is_curation: number; // boolean

  total?: number;
}
