<nav class="nav">
  <ol class="items">
    <li class="item">
      <a
        class="tab"
        routerLink="/bookshelf"
        [class.is-active]="_isBookshelfPageActive"
      >
        <sui-icon name="내 책장-1"></sui-icon>
        <span>내 책장</span>
        <div class="dot"></div>
      </a>
    </li>
    <li>
      <a
        class="tab"
        routerLink="/bookshelf/groups"
        routerLinkActive="is-active"
      >
        <sui-icon type="solid" name="앱_그룹"></sui-icon>
        <span>내 그룹</span>
        <div class="dot"></div>
      </a>
    </li>
    <li>
      <a class="tab" routerLink="/bookshelf/all" routerLinkActive="is-active">
        <sui-icon type="solid" name="앱_내그룹-1"></sui-icon>
        <span>내 모든 책</span>
        <div class="dot"></div>
      </a>
    </li>
    <li>
      <a class="tab" routerLink="/bookshelf/likes" routerLinkActive="is-active">
        <sui-icon type="solid" name="좋아요"></sui-icon>
        <span>찜</span>
        <div class="dot"></div>
      </a>
    </li>
  </ol>
</nav>
