<div class="header">
  <viewer-tab
    [tabItems]="_tabItems"
    [(selectedTab)]="_selectedTab"
    (selectedTabChange)="_onTabChange()"
  ></viewer-tab>
</div>
<div class="body" *ngIf="_book" [ngSwitch]="_selectedTab">
  <viewer-highlight-list
    #annotationList
    [bid]="_book.meta.bid"
    (itemClicked)="_onItemClicked($event)"
    *ngSwitchCase="_Tab.highlight"
  ></viewer-highlight-list>
  <viewer-memo-list
    #annotationList
    [bid]="_book.meta.bid"
    (itemClicked)="_onItemClicked($event)"
    *ngSwitchCase="_Tab.memo"
  ></viewer-memo-list>
  <viewer-bookmark-list
    #annotationList
    [book]="_book"
    (itemClicked)="_onItemClicked($event)"
    *ngSwitchCase="_Tab.bookmark"
  ></viewer-bookmark-list>
  <viewer-drawing-list
    #drawingList
    [book]="_book"
    (itemClicked)="_onItemClicked($event)"
    *ngSwitchCase="_Tab.drawing"
  ></viewer-drawing-list>
</div>
