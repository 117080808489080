import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subject, filter, takeUntil } from 'rxjs';

import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { ToastService } from 'shared/ui';

@Component({
  selector: 'viewer-focused-member-popup',
  templateUrl: './focused-member-popup.component.html',
  styleUrl: './focused-member-popup.component.scss',
})
export class FocusedMemberPopupComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-disabled') isDisabled = true;
  @HostBinding('class.is-hidden') isHidden = false;

  private _unsubscriber = new Subject<void>();

  public _nickname = '';
  public _avatarImageURL: string | null = null;

  constructor(
    private _eventBusService: EventBusService,
    private _annotationsService: AnnotationsV2Service,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._annotationsService.state$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((state) => {
        if (state.memberId === undefined) {
          this.isDisabled = true;
        }
      });

    this._eventBusService
      .on('ReadingGroupMembersDialogComponent:memberDeselected')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this.isDisabled = true;
      });

    this._eventBusService
      .on('ReadingGroupMembersDialogComponent:memberSelected')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ member }) => {
        this.isDisabled = false;

        this._nickname = member.nickname;
        this._avatarImageURL = member.avatar_image_url;
      });

    this._eventBusService
      .on('SidebarContainerComponent:opened')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(({ position }) => position === 'top')
      )
      .subscribe(() => {
        this.isHidden = false;
      });

    this._eventBusService
      .on('SidebarContainerComponent:closed')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(({ position }) => position === 'top')
      )
      .subscribe(() => {
        this.isHidden = true;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onCloseButtonClick(): void {
    this.isDisabled = true;
    this._annotationsService.setState({ memberId: undefined });

    this._toastService.open(
      '‘그룹원들의 중복 하이라이트 보기’ 모드로 변경되었습니다.'
    );
  }
}
