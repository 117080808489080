<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()"
  >내 독서 기록 제출하기</sui-dialog-header
>
<div class="body" (keydown)="$event.stopPropagation()">
  <div
    class="top"
    [ngClass]="_data.myCommentatorInfo.profile ? 'readonly' : 'form'"
  >
    <div class="profile">
      <ng-container
        *ngIf="_data.myCommentatorInfo.profile as profile; else avatarFormTpl"
      >
        <sui-reading-group-member-avatar
          class="avatar"
          [avatarImageURL]="profile.avatar_image_url"
        ></sui-reading-group-member-avatar>
        <span class="nickname">{{ profile.nickname }}</span>
      </ng-container>
    </div>

    <div class="note">
      비속어 / 욕설 사용 시, 서비스 이용이 제한될 수 있습니다.
    </div>
  </div>
  <section class="section">
    <div class="section-title">
      코멘테이터 정보
      <span class="note">
        &nbsp;&nbsp;비속어 / 욕설 사용 시, 서비스 이용이 제한될 수 있습니다.
      </span>
    </div>
    <div class="form-field" *ngIf="!_data.myCommentatorInfo.is_commentator">
      <div class="input">
        <input
          placeholder="코멘테이터 닉네임"
          [formControl]="_nicknameFormControl"
        />
      </div>
      <button
        sui-box-button
        varSize="medium40"
        (click)="_onCheckDuplicationOfNicknameButtonClick()"
      >
        중복 확인
      </button>
      <div class="hint left">
        <div
          class="hint-item"
          [class.is-valid]="
            _nicknameFormControl.dirty &&
            !_nicknameFormControl.hasError('lengthWithoutWhitespace')
          "
          [class.is-invalid]="
            _nicknameFormControl.dirty &&
            _nicknameFormControl.hasError('lengthWithoutWhitespace')
          "
        >
          2-20자 이내 <sui-icon class="check-icon" name="체크"></sui-icon>
        </div>
        <div
          class="hint-item"
          *ngIf="
            _nicknameFormControl.pristine ||
            _nicknameFormControl.hasError('notCheckedNickname')
          "
        >
          중복 확인이 필요합니다.
        </div>
        <div
          class="hint-item is-invalid"
          *ngIf="
            !_nicknameFormControl.hasError('notCheckedNickname') &&
            _nicknameFormControl.hasError('nicknameAlreadyExists')
          "
        >
          이미 사용중인 닉네임입니다.
        </div>
        <div
          class="hint-item is-valid"
          *ngIf="
            !_nicknameFormControl.hasError('notCheckedNickname') &&
            !_nicknameFormControl.hasError('nicknameAlreadyExists')
          "
        >
          사용 가능한 닉네임입니다.
        </div>
      </div>
    </div>
    <div class="form-field">
      <div class="input" [class.is-disabled]="_tagFormControl.disabled">
        <input
          placeholder="소개 태그"
          [formControl]="_tagFormControl"
          (keydown.enter)="!$any($event).isComposing && _onAddTag()"
        />
      </div>
      <button
        sui-box-button
        varSize="medium40"
        (click)="_onAddTag()"
        [disabled]="_tagFormControl.disabled"
      >
        추가
      </button>
      <div class="hint left">
        <div
          class="hint-item"
          [class.is-valid]="_tags.length > 0"
          [class.is-invalid]="_tags.length === 0 && _tagsFormControl.dirty"
        >
          태그 1-3개 이내 <sui-icon class="check-icon" name="체크"></sui-icon>
        </div>
        <div
          class="hint-item"
          [class.is-valid]="_tagFormControl.dirty && _tagFormControl.valid"
          [class.is-invalid]="_tagFormControl.dirty && _tagFormControl.invalid"
        >
          각 태그당 20자 이내 & 콤마(,) 입력 불가
          <sui-icon class="check-icon" name="체크"></sui-icon>
        </div>
      </div>
    </div>
    <div class="tag-list" *ngIf="_tags.length > 0">
      <div class="tag" *ngFor="let tag of _tags; index as i">
        {{ tag }}
        <button class="tag-remove-button" (click)="_onRemoveTagButtonClick(i)">
          <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 1.73065V9.14688C3 9.65033 6 10.1538 9 10.6572C10.0973 10.8412 10.7643 11.2888 11 12V1.56304C7.98005 1.05582 4.95948 0.549228 1.93953 0.0420063C0.672071 -0.170801 0 0.429955 0 1.73065Z"
              fill="#CCCCCC"
            />
            <path
              d="M7.50755 3.47205L7.0355 3L5.22782 4.81046L3.41736 3L2.94531 3.47205L4.75577 5.28251L2.94531 7.09019L3.41736 7.56224L5.22782 5.75178L7.0355 7.56224L7.50755 7.09019L5.6971 5.28251L7.50755 3.47205Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="section-title">독서 기록 소개</div>
    <div class="form-field">
      <div class="input">
        <textarea
          placeholder="독서기록 소개를 입력해주세요."
          [formControl]="_descriptionFormControl"
        ></textarea>
      </div>
      <div class="hint right">
        <div class="hint-item">
          <span
            [class.warning-text]="_descriptionFormControl.hasError('maxlength')"
            >{{ _descriptionFormControl.value.length }}</span
          >/150
        </div>
      </div>
    </div>
  </section>
  <div class="actions">
    <div class="note">
      내 독서 기록 제출은 1회만 가능합니다. <br />
      입력된 정보는 코멘터리 발행 시 코멘터리 상세 정보에 반영됩니다.
    </div>
    <button
      sui-solid-button
      varSize="medium40::large"
      [disabled]="_formGroup.invalid || _isSubmitting"
      (click)="_onSubmitButtonClick()"
    >
      제출하기
    </button>
  </div>
</div>

<ng-template #avatarFormTpl>
  <div class="avatar-container">
    <sui-reading-group-member-avatar
      class="avatar"
      [avatarImageURL]="_formGroup.get('avatar_image_url')!.value"
    ></sui-reading-group-member-avatar>
    <div class="loader" [class.is-active]="_isUploadingImage">
      <sui-loader></sui-loader>
    </div>
  </div>
  <input
    #input
    type="file"
    accept="image/*"
    (change)="_onSelectAvatarImage($event)"
    style="display: none"
  />
  <button class="text-button" (click)="input.click()">사진 선택</button>
  <button
    class="text-button"
    *ngIf="_formGroup.get('avatar_image_url')!.value !== ''"
    (click)="_onClearAvatarImageButtonClick()"
  >
    사진 삭제
  </button>
</ng-template>
