import { Component, Input } from '@angular/core';

@Component({
  selector: 'sui-flag-tag',
  templateUrl: './flag-tag.component.html',
  styleUrl: './flag-tag.component.scss',
})
export class FlagTagComponent {
  @Input() color: 'gray' | 'point' = 'gray';
}
