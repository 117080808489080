import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const SETTINGS_KEY = 'BukAppSettings_v1';

const DEFAULT_SETTINGS: AppSettings = {
  keepScreenOn: false,
  backupDrawing: true,
};

export interface AppSettings {
  keepScreenOn: boolean;
  backupDrawing: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppSettingsStoreService {
  private _settings$: BehaviorSubject<AppSettings>;
  public settings$: Observable<AppSettings>;

  constructor() {
    this._settings$ = new BehaviorSubject(this._load());
    this.settings$ = this._settings$.asObservable();
  }

  update<T extends keyof AppSettings>(key: T, value: AppSettings[T]): void {
    const settings = { ...this._settings$.getValue() };
    settings[key] = value;

    this._settings$.next(settings);
    this._save();
  }

  get(): AppSettings {
    return this._settings$.getValue();
  }

  private _load(): AppSettings {
    try {
      const value = localStorage.getItem(SETTINGS_KEY);
      if (!value) {
        return DEFAULT_SETTINGS;
      } else {
        return JSON.parse(value);
      }
    } catch (error) {
      return DEFAULT_SETTINGS;
    }
  }

  private _save(): void {
    try {
      localStorage.setItem(
        SETTINGS_KEY,
        JSON.stringify(this._settings$.getValue())
      );
    } catch (error) {
      //
    }
  }
}
