import { Reader } from './reader.model';

export enum ReadingGroupType {
  public = 'public',
  private = 'private',
}

export enum ReadingGroupJoinStatus {
  none = 'none',
  joined = 'joined',
  owned = 'owned',
  invited = 'invited',
  kicked = 'kicked',
}

export interface ReadingGroup {
  id: number;
  name: string;
  description: string;
  type: ReadingGroupType;
  member_count: number;
  max_member_count: number;
  highlight_count: number;
  memo_count: number;
  passcode: string | null; // 그룹장이 아닌 경우 null
  leader_nickname: string;
  created_at: string;
  expired_at: string;
  status: ReadingGroupJoinStatus;
  is_hidden: number;
  content: {
    id: string;
    title: string;
    author: string;
    image_url: string;
  };
}

export interface ReadingGroupWithMyActivityStats extends ReadingGroup {
  my_highlights_count: number;
  my_memo_copy_count: number;
  my_memo_count: number;
  my_memo_like_count: number;
  memo_unread_count: number;
}

export interface PopularReadingGroup extends ReadingGroup {
  period: string;
  book_description1: string;
  book_description2: string;
}

export interface ExitedReadingGroup extends ReadingGroup {
  exited_at: string;
}

export interface ReadingGroupMember extends Reader {
  is_owner: number; // boolean
  is_me: number; // boolean
}

export interface InviteableReadingGroupMember extends Reader {
  is_inviteable: number; // boolean;
}
