import { ConnectedPosition } from '@angular/cdk/overlay';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ReadingGroup } from 'shared/models';
import { Subject, takeUntil } from 'rxjs';

const MENU_POSITION_TOP: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'bottom',
  },
];

const MENU_POSITION_BOTTOM: ConnectedPosition[] = [
  {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'top',
  },
];

@Component({
  selector: 'viewer-reading-group-select',
  templateUrl: './reading-group-select.component.html',
  styleUrl: './reading-group-select.component.scss',
})
export class ReadingGroupSelectComponent implements OnInit, OnDestroy {
  @ViewChild('selectBox', { static: true })
  private _selectBoxElem!: ElementRef<HTMLDivElement>;

  @Input() groups?: ReadingGroup[];
  @Input() currentGroupId?: number;

  public _selectedGroup?: ReadingGroup;
  private _selectedGroupId?: number;

  public _selectBoxWidth!: number;

  @Output() selectedGroupIdChange = new EventEmitter<number | undefined>();

  public _optionMenuPosition: ConnectedPosition[] = MENU_POSITION_TOP;

  private _unsubscriber = new Subject<void>();

  @Input() set selectedGroupId(value: number | undefined) {
    this._selectedGroupId = value;
    this._selectedGroup = this.groups?.find((g) => g.id === value);
  }

  get selectedGroupId(): number | undefined {
    return this._selectedGroupId;
  }

  constructor(private _breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this._breakpointObserver
      .observe(`(max-width: 767px)`)
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((state) => {
        this._optionMenuPosition = state.matches
          ? MENU_POSITION_TOP
          : MENU_POSITION_BOTTOM;
      });

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this._selectBoxWidth = entry.borderBoxSize[0].inlineSize;
      }
    });

    observer.observe(this._selectBoxElem.nativeElement);

    this._selectedGroup = this.groups?.find(
      (g) => g.id === this._selectedGroupId
    );
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onOptionClick(group: ReadingGroup): void {
    this.selectedGroupId = group.id;
    this.selectedGroupIdChange.emit(this.selectedGroupId);
  }
}
