import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { pairwise, startWith, takeUntil, tap, map } from 'rxjs/operators';

import {
  Address,
  Annotation as ViewerAnnotation,
  AnnotationType,
  BookType,
  createBukURL,
  parseBukURL,
} from '@bukio/viewer';
import { Highlighter } from '@bukio/highlighter';

import { Config } from '../constants/config';

import { Bookmark } from '../models/bookmark.model';

import { BookmarksAPIService } from './bookmarks-api.service';
import { Book, BukJSON } from '../models/book.model';
import { EventBusService } from './event-bus.service';
import { addQueryToAddress, isWikiBid } from '../utils/address';

export type Annotation = Bookmark;

const highlighter = new Highlighter();

const ITEM_POSITION_MULTIPLIER = 10000000;

export const MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR =
  'MaxAnnotationLengthExceededError';

export function toViewerAnnotation(annotation: Annotation): ViewerAnnotation {
  return {
    type:
      annotation.type === 'bookmark'
        ? AnnotationType.Bookmark
        : AnnotationType.Highlight,
    url: annotation.url,
    text: annotation.text,
    styleClass: annotation.type === 'bookmark' ? null : annotation.class,
  };
}

function getStartOffsetFromURL(url: string): number {
  const address = parseBukURL(url);
  return (
    (address.range && highlighter.parseAddress(address.range).startOffset) ?? 0
  );
}

function calculateBukURLPositionInBook(book: BukJSON, url: string): number {
  let itemIndex = -1;

  if (book.meta.type === BookType.Document) {
    itemIndex = 0;
  } else {
    const address = parseBukURL(url);
    itemIndex = book.items.findIndex((item) => item.iid === address.iid);
  }

  if (itemIndex === -1) {
    throw new Error('faild to calculate highlight position');
  }

  return itemIndex * ITEM_POSITION_MULTIPLIER + getStartOffsetFromURL(url);
}

function normalizeBookmark(bookmark: Bookmark): Bookmark {
  let url = bookmark.url;
  const address = parseBukURL(url);

  if (
    bookmark.type === 'bookmark' &&
    address.fragment &&
    /^\d+\.(\d{7})$/.test(address.fragment)
  ) {
    const start = parseInt(/^(\d+)\.(\d{7})$/.exec(address.fragment)![1]);
    const rangeAnchor = `${start}-${start + 1}`;

    url = createBukURL(address.bid, address.iid, rangeAnchor, address.query);
  }

  return { ...bookmark, url };
}

function normalizeWikiBookmark(bookmark: Bookmark): Bookmark {
  const result = { ...bookmark };

  if (bookmark.version) {
    const address = parseBukURL(bookmark.url);
    addQueryToAddress(address, 'version', bookmark.version);
    result.url = address.toString();
  }

  return result;
}

function createBukId(address: Address): string {
  if (!isWikiBid(address.bid)) {
    return address.bid;
  }

  if (!address.iid || address.iid === 'cover') {
    return address.bid;
  }

  return `${address.bid}/${address!.iid.replace(/_/g, ' ')}`;
}

@Injectable({
  providedIn: 'root',
})
export class AnnotationsService implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  private _bukId!: string;
  private _bukType!: 'mediawiki' | 'buk';
  private _version?: string;

  private _book?: Book;

  private _annotations$ = new BehaviorSubject<Annotation[]>([]);
  public readonly annotations$ = this._annotations$.asObservable();

  constructor(
    private _apiService: BookmarksAPIService,
    private _eventBusService: EventBusService
  ) {
    this._eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(
        map(({ address }) => address),
        startWith(undefined),
        pairwise()
      )
      .subscribe(([addr1, addr2]) => {
        if (addr1?.bid !== addr2?.bid) {
          this._bukId = createBukId(addr2!);
          this._bukType = isWikiBid(addr2!.bid) ? 'mediawiki' : 'buk';
          this._version = addr2?.query?.version;

          if (this._bukType === 'mediawiki') {
            this._get(normalizeWikiBookmark);
          }
        } else if (addr1?.query?.version !== addr2?.query?.version) {
          this._version = addr2?.query?.version;
        }
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;

        if (this._bukType === 'buk') {
          this._get(
            book.meta.type !== BookType.PDF ? normalizeBookmark : undefined
          );
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private set _annotations(annotations: Annotation[]) {
    this._annotations$.next(annotations);
  }

  private get _annotations(): Annotation[] {
    return this._annotations$.getValue();
  }

  getAnnotations(): Annotation[] {
    return this._annotations;
  }

  getAnnotationFromViewerAnnotation(
    viewerAnnot: ViewerAnnotation
  ): Annotation | undefined {
    return this._annotations.find(
      (a) => a.url === viewerAnnot.url && a.type === viewerAnnot.type
    );
  }

  getAnnotationById(id: number): Annotation | undefined {
    return this._annotations.find((a) => a.id === id);
  }

  create(viewerAnnot: ViewerAnnotation, memo: string = ''): Observable<void> {
    if (!this._canCreateAnnotation(viewerAnnot)) {
      const error = new Error('max annotation text length exceeded');
      error.name = MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR;
      return throwError(error);
    }

    const annotation = this._createAnnotation(viewerAnnot, memo);

    return this._apiService.create(annotation).pipe(
      tap(() => {
        this._annotations = [...this._annotations, annotation];
      })
    );
  }

  update(
    viewerAnnot: ViewerAnnotation,
    props: Partial<{
      className: string;
      memo: string;
    }>
  ): Observable<void> {
    const annotation = this.getAnnotationFromViewerAnnotation(viewerAnnot);

    if (!annotation) {
      return of();
    }

    return this._apiService.update(annotation.id, props).pipe(
      tap(() => {
        props.className != null && (annotation.class = props.className);
        props.memo != null && (annotation.memo = props.memo);
        annotation.updatedDate = new Date().toISOString();
        this._annotations = [...this._annotations];
      })
    );
  }

  remove(viewerAnnots: ViewerAnnotation[]): Observable<void> {
    const ids = viewerAnnots.reduce((_ids, viewerAnnot) => {
      const id = this.getAnnotationFromViewerAnnotation(viewerAnnot)?.id;

      if (id != null) {
        _ids.push(id);
      }

      return _ids;
    }, [] as number[]);

    if (ids.length === 0) {
      return of();
    }

    return this.removeById(ids);
  }

  removeById(ids: number | number[]): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._apiService.delete(ids).pipe(
      tap(() => {
        this._annotations = this._annotations.filter(
          (a) => (ids as number[]).indexOf(a.id) === -1
        );
      })
    );
  }

  private _get(normalizer?: (bookmark: Bookmark) => Bookmark): void {
    this._apiService.getAll(this._bukId).subscribe(
      (bookmarks) => {
        bookmarks.forEach((bookmark) => {
          if (bookmark.memo) {
            bookmark.class = 'bukh-memo';
          }
        });
        this._annotations = normalizer ? bookmarks.map(normalizer) : bookmarks;
      },
      () => {
        this._annotations = [];
      }
    );
  }

  private _canCreateAnnotation(viewerAnnot: ViewerAnnotation): boolean {
    return (
      viewerAnnot.type !== AnnotationType.Highlight ||
      viewerAnnot.text.trim().length <= Config.selectionLimitLength
    );
  }

  private _createAnnotation(
    annotation: ViewerAnnotation,
    memo: string = ''
  ): Annotation {
    const createdDate = new Date().toISOString();

    return {
      id: new Date().getTime(),
      bukId: this._bukId,
      bukType: this._bukType,
      url: annotation.url,
      type: annotation.type,
      text: annotation.text,
      class:
        annotation.type === AnnotationType.Bookmark
          ? 'bbdbukmark'
          : annotation.styleClass,
      position: this._calculateBukURLPositionInBook(annotation.url),
      version: this._version ?? null,
      memo,
      createdDate, // FIXME
      updatedDate: createdDate,
    };
  }

  private _calculateBukURLPositionInBook(url: string): number {
    if (this._book) {
      return calculateBukURLPositionInBook(this._book, url);
    } else {
      return getStartOffsetFromURL(url);
    }
  }
}
