import { Component } from '@angular/core';
import { finalize } from 'rxjs';
import { ReadingGroupJoinStatus } from 'shared/models';
import { APIError, ReadingGroupsAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-reading-group-exit-dialog',
  templateUrl: './reading-group-exit-dialog.component.html',
  styleUrl: './reading-group-exit-dialog.component.scss',
})
export class ReadingGroupExitDialogComponent extends TypedDialog<
  { groupId: number; status: ReadingGroupJoinStatus },
  boolean
> {
  public _JoinStatus = ReadingGroupJoinStatus;

  public _exitCheckboxCopy = false;
  public _exitCheckboxConfirm = false;
  public _isExiting = false;

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _toastService: ToastService
  ) {
    super();
  }

  _onOKClick(): void {
    this._isExiting = true;

    this._readingGroupsAPIService
      .exit(this._data.groupId, this._exitCheckboxCopy)
      .pipe(finalize(() => (this._isExiting = false)))
      .subscribe({
        next: () => {
          this._dialogRef.close(true);
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }
}
