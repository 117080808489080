import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Config } from '../constants/config';

interface BroadcastEvent {
  key: any;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private broadcaster: BehaviorSubject<BroadcastEvent>;

  constructor() {
    this.broadcaster = new BehaviorSubject<BroadcastEvent>({ key: '' });
  }

  fire(key: any, data?: any) {
    if (Config.debug) console.info(`>> ${key}\n`, data);
    this.broadcaster.next({ key, data });
  }

  on<T>(key: any): Observable<T> {
    return this.broadcaster.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => <T>event.data)
    );
  }
}
