<header class="header">
  <div class="top">
    <button
      class="button"
      [class.is-active]="_newNotificationAlertCount !== 0"
      sui-icon-button
      (click)="_onClickNotification()"
    >
      <sui-icon type="solid" name="알림"></sui-icon>
      <div class="new-alert-count-badge">
        {{
          _newNotificationAlertCount >= 100 ? '99+' : _newNotificationAlertCount
        }}
      </div>
    </button>
    <div class="divider"></div>
    <button
      class="button"
      [class.is-active]="_newMessageAlertCount !== 0"
      sui-icon-button
      (click)="_onClickMessage()"
    >
      <sui-icon type="solid" name="메세지"></sui-icon>
      <div class="new-alert-count-badge">
        {{ _newMessageAlertCount >= 100 ? '99+' : _newMessageAlertCount }}
      </div>
    </button>
    <div class="divider"></div>
    <button class="button" sui-icon-button routerLink="/settings">
      <sui-icon name="환경설정"></sui-icon>
    </button>
    <div class="divider"></div>
    <button class="button" sui-icon-button routerLink="/search">
      <sui-icon name="검색"></sui-icon>
    </button>
  </div>
  <div class="bottom">
    <ol>
      <li
        class="item"
        routerLink="/bookshelf"
        [class.is-active]="_isBookshelfPageActive"
      >
        <sui-icon type="solid" name="앱_내그룹-1"></sui-icon
        ><span class="title">내 책장</span>
      </li>
      <li
        class="item"
        routerLink="/bookshelf/groups"
        routerLinkActive="is-active"
      >
        <sui-icon type="solid" name="앱_그룹"></sui-icon
        ><span class="title">내 그룹</span>
      </li>
      <li class="item" routerLink="/bookshelf/all" routerLinkActive="is-active">
        <sui-icon type="solid" name="앱_내그룹-1"></sui-icon>
        <span class="title">내 모든 책</span>
      </li>
      <li
        class="item"
        routerLink="/bookshelf/likes"
        routerLinkActive="is-active"
      >
        <sui-icon type="solid" name="좋아요"></sui-icon>
        <span class="title">찜</span>
      </li>
    </ol>
  </div>
  <div class="buy-book">
    <button (click)="_onClickPurchaseButton()" *ngIf="_isBookshelfPageActive">
      <sui-icon type="solid" name="앱_책 구매하러 가기"></sui-icon>
      <span class="text">책 구매하러 가기</span>
    </button>
  </div>
</header>
