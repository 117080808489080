import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { pairwise, startWith, takeUntil, tap, map } from 'rxjs/operators';

import {
  Address,
  Annotation as ViewerAnnotation,
  AnnotationType,
  BookType,
  createBukURL,
  parseBukURL,
} from '@bukio/viewer';
import { Bookmark } from '../models/bookmark.model';

import { BookmarksAPIService } from './bookmarks-api.service';
import { Book } from '../models/book.model';
import { EventBusService } from './event-bus.service';
import { isWikiBid } from '../utils/address';
import { calculateBukURLPositionInBook } from '../utils/book';

export function convertBookmarkToViewerAnnotation(
  bookmark: Bookmark
): ViewerAnnotation {
  return {
    type: AnnotationType.Bookmark,
    url: bookmark.url,
    text: bookmark.text,
    styleClass: null,
  };
}

function normalizeBookmark(bookmark: Bookmark): Bookmark {
  let url = bookmark.url;
  const address = parseBukURL(url);

  if (
    bookmark.type === 'bookmark' &&
    address.fragment &&
    /^\d+\.(\d{7})$/.test(address.fragment)
  ) {
    const start = parseInt(/^(\d+)\.(\d{7})$/.exec(address.fragment)![1]);
    const rangeAnchor = `${start}-${start + 1}`;

    url = createBukURL(address.bid, address.iid, rangeAnchor, address.query);
  }

  return { ...bookmark, url };
}

function createBukId(address: Address): string {
  if (!isWikiBid(address.bid)) {
    return address.bid;
  }

  if (!address.iid || address.iid === 'cover') {
    return address.bid;
  }

  return `${address.bid}/${address!.iid.replace(/_/g, ' ')}`;
}

@Injectable({
  providedIn: 'root',
})
export class BookmarksService implements OnDestroy {
  private unsubscriber: Subject<void> = new Subject<void>();

  private _bukId!: string;

  private _book?: Book;

  private _bookmarks$ = new BehaviorSubject<Bookmark[]>([]);
  public readonly bookmarks$ = this._bookmarks$.asObservable();

  constructor(
    private _apiService: BookmarksAPIService,
    private _eventBusService: EventBusService
  ) {
    this._eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(
        map(({ address }) => address),
        startWith(undefined),
        pairwise()
      )
      .subscribe(([addr1, addr2]) => {
        if (addr1?.bid !== addr2?.bid) {
          this._bukId = createBukId(addr2!);
        }
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;

        this._get(
          book.meta.type !== BookType.PDF ? normalizeBookmark : undefined
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private set _bookmarks(bookmarks: Bookmark[]) {
    this._bookmarks$.next(bookmarks);
  }

  private get _bookmarks(): Bookmark[] {
    return this._bookmarks$.getValue();
  }

  get bookmarks(): Bookmark[] {
    return this._bookmarks;
  }

  getBookmarkFromViewerAnnotation(
    viewerAnnot: ViewerAnnotation
  ): Bookmark | undefined {
    return this._bookmarks.find(
      (a) => a.url === viewerAnnot.url && a.type === viewerAnnot.type
    );
  }

  getBookmarkById(id: number): Bookmark | undefined {
    return this._bookmarks.find((a) => a.id === id);
  }

  create(viewerAnnot: ViewerAnnotation): Observable<void> {
    const annotation = this._createBookmark(viewerAnnot);

    return this._apiService.create(annotation).pipe(
      tap(() => {
        this._bookmarks = [...this._bookmarks, annotation];
      })
    );
  }

  remove(viewerAnnots: ViewerAnnotation[]): Observable<void> {
    const ids = viewerAnnots.reduce((_ids, viewerAnnot) => {
      const id = this.getBookmarkFromViewerAnnotation(viewerAnnot)?.id;

      if (id != null) {
        _ids.push(id);
      }

      return _ids;
    }, [] as number[]);

    if (ids.length === 0) {
      return of();
    }

    return this.removeById(ids);
  }

  removeById(ids: number | number[]): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._apiService.delete(ids).pipe(
      tap(() => {
        this._bookmarks = this._bookmarks.filter(
          (a) => (ids as number[]).indexOf(a.id) === -1
        );
      })
    );
  }

  private _get(normalizer?: (bookmark: Bookmark) => Bookmark): void {
    this._apiService.getAll(this._bukId).subscribe({
      next: (bookmarks) => {
        this._bookmarks = normalizer ? bookmarks.map(normalizer) : bookmarks;
      },
      error: () => {
        this._bookmarks = [];
      },
    });
  }

  private _createBookmark(annotation: ViewerAnnotation): Bookmark {
    if (!this._book) {
      throw new Error('invalid operation');
    }

    const createdDate = new Date().toISOString();

    return {
      id: new Date().getTime(),
      bukId: this._bukId,
      bukType: 'buk',
      url: annotation.url,
      type: annotation.type,
      text: annotation.text,
      class: 'bbdbukmark',
      position: calculateBukURLPositionInBook(this._book, annotation.url),
      version: null,
      memo: '',
      createdDate, // FIXME
      updatedDate: createdDate,
    };
  }
}
