import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { parseBukURL, Annotation as ViewerAnnotation } from '@bukio/viewer';

import { Memo } from 'shared/models';
import { generateRandomUUID, stripUndefinedParams } from 'shared/utils';
import { catchAndRethrowAPIError } from './api-error';
import { QueryResult } from './query-result';

const CREATE_MEMO_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'The group leader leaves the group':
    '그룹장이 ‘나가기’한 그룹 입니다. 그룹원들은 새로운 기록을 공유할 수 없습니다.',
  'Duplicate memo ID': '잘못된 요청입니다. (1)',
  'Parameter missing': '잘못된 요청입니다. (2)',
};

const UPDATE_MEMO_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Memo not found': '메모를 찾을 수 없습니다.',
};

const UPDATE_MEMO_READ_STATUS_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
};

const DELETE_MEMO_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Memo not found': '메모를 찾을 수 없습니다.',
  'Parameter missing': '잘못된 요청입니다.',
};

const COPY_TO_SINGLE_MODE_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Memo not found': '메모를 찾을 수 없습니다.',
};

const REPORT_MEMO_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Memo not found': '메모를 찾을 수 없습니다.',
  'Already reported': '이미 신고한 메모입니다.',
};

const EXPORT_MEMO_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Memo not found': '메모를 찾을 수 없습니다.',
  'Parameter missing': '잘못된 그룹입니다.',
};

interface MemoQuery {
  group_id?: number;
  member_id?: string;
  commentary_id?: number;
  filter?: 'mine' | 'theirs' | 'all';
  order_by?: 'created_at' | 'position'; // default - position
}

@Injectable({
  providedIn: 'root',
})
export class MemosAPIService {
  constructor(private _http: HttpClient) {}

  get(
    bid: string,
    offset: number,
    limit: number,
    query?: MemoQuery
  ): Observable<QueryResult<Memo>> {
    return this._http.get<QueryResult<Memo>>('/api/v1/memos', {
      params: stripUndefinedParams({
        bid,
        offset,
        limit,
        ...query,
      }),
      withCredentials: true,
    });
  }

  create(
    highlight: ViewerAnnotation,
    position: number,
    memo: string,
    is_pinned: boolean,
    groupId?: number,
    commentaryId?: number
  ): Observable<Memo> {
    const address = parseBukURL(highlight.url);

    if (!address.iid) {
      throw new Error('invalid highlight');
    }

    const body = {
      id: generateRandomUUID(),
      bid: address.bid,
      iid: address.iid,
      url: highlight.url,
      text: highlight.text,
      position,
      content: memo,
      is_pinned,
    } as any;

    if (groupId != null) {
      body.group_id = groupId;
    }

    if (commentaryId != null) {
      body.commentary_id = commentaryId;
    }

    return this._http
      .post<Memo>('/api/v1/memos', body, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(CREATE_MEMO_ERROR_MSG_MAP));
  }

  update(
    memoId: string,
    content: string,
    is_pinned: boolean
  ): Observable<Memo> {
    return this._http
      .put<Memo>(
        `/api/v1/memos/${memoId}`,
        { content, is_pinned },
        { withCredentials: true }
      )
      .pipe(catchAndRethrowAPIError(UPDATE_MEMO_ERROR_MSG_MAP));
  }

  updateReadStatus(memo_ids: string[]): Observable<void> {
    return this._http
      .put<void>(`/api/v1/memos`, { memo_ids }, { withCredentials: true })
      .pipe(catchAndRethrowAPIError(UPDATE_MEMO_READ_STATUS_ERROR_MSG_MAP));
  }

  updateReadStatusAll(group_id: number): Observable<void> {
    return this._http
      .put<void>(`/api/v1/memos`, { group_id }, { withCredentials: true })
      .pipe(catchAndRethrowAPIError(UPDATE_MEMO_READ_STATUS_ERROR_MSG_MAP));
  }

  delete(memoIds: string[]): Observable<void> {
    return this._http
      .delete<void>(`/api/v1/memos`, {
        params: {
          memo_ids: memoIds.join(','),
        },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(DELETE_MEMO_ERROR_MSG_MAP));
  }

  copyToSingleMode(memoId: string): Observable<void> {
    return this._http
      .post<void>(`/api/v1/memos/${memoId}/copy`, undefined, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(COPY_TO_SINGLE_MODE_ERROR_MSG_MAP));
  }

  block(memoId: string): Observable<void> {
    return this._http.put<void>(`/api/v1/memos/${memoId}/block`, undefined, {
      withCredentials: true,
    });
  }

  report(
    memoId: string,
    body: { reason: string; details: string; block: boolean }
  ): Observable<void> {
    return this._http
      .put<void>(`/api/v1/memos/${memoId}/report`, body, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(REPORT_MEMO_ERROR_MSG_MAP));
  }

  like(memoId: string): Observable<void> {
    return this._http.put<void>(`/api/v1/memos/${memoId}/like`, undefined, {
      withCredentials: true,
    });
  }

  unlinke(memoId: string): Observable<void> {
    return this._http.delete<void>(`/api/v1/memos/${memoId}/like`, {
      withCredentials: true,
    });
  }

  export(memoId: string, group_ids: number[] | number): Observable<void> {
    if (!Array.isArray(group_ids)) {
      group_ids = [group_ids];
    }

    return this._http
      .post<void>(
        `/api/v1/memos/${memoId}/export`,
        {
          group_ids,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(EXPORT_MEMO_ERROR_MSG_MAP));
  }
}
