import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Library, SearchedLibrary } from 'shared/models';
import { QueryResult } from './query-result';

@Injectable({
  providedIn: 'root',
})
export class LibraryAPIService {
  constructor(private _http: HttpClient) {}

  get(eid: string): Observable<{ item: Library; result: string }> {
    return this._http.get<{ item: Library; result: string }>(
      `/api/1.0/library`,
      {
        params: { eid },
        withCredentials: true,
      }
    );
  }

  getSearchLibraryResult(
    offset: number,
    limit: number,
    q: string
  ): Observable<QueryResult<SearchedLibrary>> {
    return this._http.get<QueryResult<SearchedLibrary>>(
      `/api/v1/search/libraries`,
      {
        params: {
          q,
          offset,
          limit,
        },
      }
    );
  }
}
