import { Component, ViewChild } from '@angular/core';

import { EventBusService } from '../../services/event-bus.service';

import { SidebarContainerComponent } from '../sidebar-container/sidebar-container.component';
import { ItemMemoListComponent } from '../item-memo-list/item-memo-list.component';

export type PanelType = 'annotations' | 'memo';

@Component({
  selector: 'viewer-right-panel',
  templateUrl: './right-panel.component.html',
  styleUrls: ['./right-panel.component.scss'],
})
export class RightPanelComponent {
  @ViewChild(ItemMemoListComponent) memoListComp?: ItemMemoListComponent;

  public readonly _panels: { [key in PanelType]: string } = {
    annotations: '내 기록 전체보기',
    memo: '메모 목록',
  };

  public _activePanel?: PanelType;

  constructor(
    private _sidebarContainerComponent: SidebarContainerComponent,
    private _eventBusService: EventBusService
  ) {}

  close(emitEvent: boolean = true): void {
    this._sidebarContainerComponent.close('right');

    emitEvent &&
      this._eventBusService.fire('RightPanelComponent:closed', {
        activePanel: this._activePanel,
      });
  }

  open(type: PanelType): void {
    this._activePanel = type;
    this._sidebarContainerComponent.open('right');
  }

  isOpened(type: PanelType): boolean {
    return (
      this._sidebarContainerComponent.isOpened('right') &&
      this._activePanel === type
    );
  }

  _onCloseButtonClick(): void {
    this.close();
  }
}
