import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  of,
  throwError,
  interval,
  Subscription,
  Subject,
  merge,
} from 'rxjs';
import { switchMap, filter, tap, map, catchError } from 'rxjs/operators';

import {
  AnnotationType,
  Annotation as ViewerAnnotation,
  createBukURL,
  parseBukURL,
} from '@bukio/viewer';

import {
  ItemAnnotations,
  AnnotationsAPIService,
  HighlightsAPIService,
  MemosAPIService,
  APIError,
} from 'shared/services';

import { EventBusService } from './event-bus.service';

import { Highlight, Memo } from 'shared/models';
import {
  MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY,
  MOMO_HIGHLIGHT_STYLE_CLASS_MEMBER,
  MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP3,
  MOMO_MEMO_STYLE_CLASS,
} from '../components/contents/contents.component';
import {
  getOverlappingRangesAndCount,
  getRangeFromBukURL,
  mergeOverlappingRanges,
} from '../utils/annotation-range';
import { MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR } from './annotations.service';
import { calculateBukURLPositionInBook } from '../utils/book';
import { BukJSON } from '../models/book.model';
import { Config } from '../constants/config';
import { PageVisibilityStateService } from './page-visibility-state.service';
import { BukHistoryService } from './buk-history.service';
import { UIStateService } from './ui-state.service';
import { normalizeAnnotationText } from '../utils/annotation';

interface HighlightsState {
  bid?: string;
  iids?: string[];
  groupId?: number;
  memberId?: string;
  minOverlappingCount: number;
  commentaryId?: number;
  showMine: boolean;
}

function convertHighlightToViewerAnnotation(
  highlight: Highlight,
  styleClass?: string
): ViewerAnnotation {
  return {
    type: AnnotationType.Highlight,
    url: highlight.url,
    text: highlight.text,
    styleClass: styleClass ?? highlight.style,
  };
}

function convertMemoToViewerAnnotation(memo: Memo): ViewerAnnotation {
  return {
    type: AnnotationType.Highlight,
    url: memo.url,
    text: memo.text,
    styleClass: MOMO_MEMO_STYLE_CLASS,
  };
}

function getMyHighlights(annotations: ItemAnnotations[]): Highlight[] {
  return annotations.reduce((result, annotations) => {
    const myHighlights = annotations.highlights.filter((h) => h.is_mine);
    return result.concat(myHighlights);
  }, [] as Highlight[]);
}

function getOverlappingRanges(
  annotations: ItemAnnotations[],
  minOverlappingCount: number
): {
  [iid: string]: [number, number][];
} {
  return annotations.reduce((result, annotations) => {
    const ranges = annotations.highlights.reduce((result, highlight) => {
      const range = getRangeFromBukURL(highlight.url);

      range && result.push(range);

      return result;
    }, [] as [number, number][]);

    const availableRanges = getOverlappingRangesAndCount(ranges).filter(
      ([, , count]) => count >= minOverlappingCount
    );

    result[annotations.iid] = mergeOverlappingRanges(availableRanges as any);

    return result;
  }, {} as { [iid: string]: [number, number][] });
}

function getMemoGroupsByRangeStart(annotations: ItemAnnotations[]): {
  [iid: string]: Memo[][];
} {
  return annotations.reduce((result, annotations) => {
    const startMemoMap = new Map<number, Memo[]>();

    annotations.memos.forEach((memo) => {
      const range = getRangeFromBukURL(memo.url);
      if (!range) {
        return;
      }

      if (!startMemoMap.has(range[0])) {
        startMemoMap.set(range[0], []);
      }

      startMemoMap.get(range[0])?.push(memo);
    });

    const memos = Array.from(startMemoMap.entries())
      .sort(([start1], [start2]) => start1 - start2)
      .map(([, memos]) => memos);

    result[annotations.iid] = memos;

    return result;
  }, {} as { [iid: string]: Memo[][] });
}

const UPDATE_CHECK_PERIOD = 60 * 1000;

@Injectable()
export class AnnotationsV2Service {
  private _state$ = new BehaviorSubject<HighlightsState>({
    minOverlappingCount: 2,
    showMine: true,
  });
  public readonly state$ = this._state$
    .asObservable()
    .pipe(filter((state) => !!state)) as Observable<HighlightsState>;

  private _book?: BukJSON;

  private _rawItemsAnnotations?: ItemAnnotations[];

  private _itemsAnnotations$ = new BehaviorSubject<ViewerAnnotation[]>([]);
  public itemsAnnotations$ = this._itemsAnnotations$.asObservable();

  private _hasUpdate$ = new BehaviorSubject<boolean>(false);
  public hasUpdate$ = this._hasUpdate$.asObservable();

  private _syncSignal = new Subject<HighlightsState>();

  // 그룹 하이라이트 또는 코멘터리 하이라이트
  private _sharedHighlights: ViewerAnnotation[] = [];

  private _itemsMemos: { [iid: string]: Memo[][] } = {};

  private _timestamp?: number;
  private _checkUpdateIntervalSubscription: Subscription | null = null;

  constructor(
    private _eventBusService: EventBusService,
    private _annotationsAPIService: AnnotationsAPIService,
    private _highlightsAPIService: HighlightsAPIService,
    private _memosAPIService: MemosAPIService,
    private _pageVisibilityStateService: PageVisibilityStateService,
    private _historyService: BukHistoryService,
    private _uiStateService: UIStateService
  ) {
    merge(this.state$, this._syncSignal)
      .pipe(
        filter((state) => !!state.bid && !!state.iids),
        switchMap((state) => {
          return this._annotationsAPIService
            .get(state.bid!, state.iids!, {
              group_id: state.groupId,
              commentary_id: state.commentaryId,
              filter: state.groupId ? 'all' : state.showMine ? 'all' : 'theirs',
              member_id: state.memberId,
            })
            .pipe(
              catchError((error: APIError) => {
                return of(error);
              })
            );
        })
      )
      .subscribe(
        (
          response: APIError | { items: ItemAnnotations[]; timestamp: number }
        ) => {
          const state = this.getState();

          if (response instanceof APIError) {
            if (state.groupId != null || state.commentaryId != null) {
              this.setState({
                groupId: undefined,
                commentaryId: undefined,
                memberId: undefined,
              });
            }
          } else {
            this._timestamp = response.timestamp;
            this._hasUpdate$.next(false);

            if (state.groupId != null && !state.showMine) {
              response.items.forEach((item) => {
                item.memos = item.memos.filter((memo) => !memo.is_mine);
              });
            }

            this._rawItemsAnnotations = response.items;

            this._updateSharedHighlights(
              state.groupId != null && !state.memberId
            );
            this._updateMemos();

            this._onAnnotationsChanged();
          }
        }
      );

    this.state$.subscribe((state) => {
      if (state.groupId != null && state.iids) {
        !this._isCheckingUpdate && this._startCheckUpdate();
      } else {
        this._stopCheckUpdate();
      }

      this._historyService.commentaryId = state.commentaryId;
    });

    this._pageVisibilityStateService.state$.subscribe((visibilityState) => {
      if (visibilityState === 'hidden') {
        this._stopCheckUpdate();
      } else {
        const state = this.getState();

        if (state.groupId != null && state.iids) {
          this._startCheckUpdate();
        }
      }
    });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .subscribe((event) => {
        this._book = event.book;
        this.setState({ bid: event.book.meta.bid });
      });

    this._eventBusService
      .on('ContentsComponent:itemLoad')
      .subscribe((event) => {
        this.setState({ iids: event.items.map((item) => item.iid) });
      });

    // this._eventBusService
    //   .on('MemoCopyDialogComponent:copied')
    //   .subscribe(({ id }) => {
    //     this._rawItemsAnnotations?.forEach((itemAnnotations) => {
    //       const index = itemAnnotations.memos.findIndex(
    //         (memo) => memo.id === id
    //       );

    //       if (index === -1) {
    //         return;
    //       }

    //       itemAnnotations.memos[index] = {
    //         ...itemAnnotations.memos[index],
    //         copy_count: itemAnnotations.memos[index].copy_count + 1,
    //       };

    //       this._updateMemos();
    //       this._onAnnotationsChanged();
    //     });
    //   });

    merge(
      this._eventBusService.on('MemoReportDialogComponent:userBlocked'),
      this._eventBusService.on('UserDetailsDialogComponent:userBlocked'),
      this._eventBusService.on('UserDetailsDialogComponent:userUnblocked')
    ).subscribe(() => {
      this.syncUpdates();
    });
  }

  syncUpdates(): void {
    this._syncSignal.next(this.getState());
  }

  setState(state: Partial<HighlightsState>): void {
    this._state$.next({ ...this._state$.value, ...state });
  }

  getState(): HighlightsState {
    return this._state$.value;
  }

  getItemsAnnotations(): ViewerAnnotation[] {
    return this._itemsAnnotations$.value;
  }

  getMemosOfItem(iid: string): Memo[][] {
    return this._itemsMemos?.[iid] ?? [];
  }

  createHighlight(viewerAnnot: ViewerAnnotation): Observable<void> {
    if (!this._book) {
      throw new Error('invalid function call');
    }

    if (!this._canCreateAnnotation(viewerAnnot)) {
      return throwError(() => {
        const error = new Error('max annotation text length exceeded');
        error.name = MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR;
      });
    }

    // 추출한 텍스트에서 대체 문자 삭제
    viewerAnnot.text = normalizeAnnotationText(viewerAnnot.text);

    const position = calculateBukURLPositionInBook(this._book, viewerAnnot.url);
    const groupId = this.getState().groupId;
    const commentaryId = this._uiStateService.isCommentaryEditor
      ? this.getState().commentaryId
      : undefined;

    return this._highlightsAPIService
      .create(viewerAnnot, position, groupId, commentaryId)
      .pipe(
        tap((addedHighlight) => {
          if (this._uiStateService.isCommentaryEditor) {
            addedHighlight.is_mine = 0;
          }

          const iid = parseBukURL(viewerAnnot.url).iid;

          this._rawItemsAnnotations
            ?.find((itemAnnotations) => itemAnnotations.iid === iid)
            ?.highlights.push(addedHighlight);

          if (this._uiStateService.isCommentaryEditor) {
            this._updateSharedHighlights(false);
            this._onAnnotationsChanged();
          } else {
            this._onMyHighlightChanged();
          }
        }),
        map(() => undefined)
      );
  }

  updateHighlight(
    viewerAnnot: ViewerAnnotation,
    styleClass: string
  ): Observable<void> {
    const highlight = this.getHighlightFromViewerAnnotation(viewerAnnot);

    if (!highlight) {
      return of(undefined);
    }

    return this._highlightsAPIService.update(highlight.id, styleClass).pipe(
      tap(() => {
        highlight.style = styleClass;
        this._onMyHighlightChanged();
      })
    );
  }

  deleteHighlights(viewerAnnots: ViewerAnnotation[]): Observable<void> {
    const ids = viewerAnnots.reduce((_ids, viewerAnnot) => {
      const id = this.getHighlightFromViewerAnnotation(viewerAnnot)?.id;

      if (id != null) {
        _ids.push(id);
      }

      return _ids;
    }, [] as string[]);

    if (ids.length === 0) {
      return of(undefined);
    }

    return this.deleteHighlightsById(ids);
  }

  deleteHighlightsById(ids: string | string[]): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._highlightsAPIService.delete(ids).pipe(
      tap(() => {
        this._rawItemsAnnotations?.forEach((itemAnnotations) => {
          itemAnnotations.highlights = itemAnnotations.highlights.filter(
            (highlight) => ids.indexOf(highlight.id) === -1
          );
        });

        if (this._uiStateService.isCommentaryEditor) {
          this._updateSharedHighlights(false);
          this._onAnnotationsChanged();
        } else {
          this._onMyHighlightChanged();
        }
      })
    );
  }

  createMemo(viewerAnnot: ViewerAnnotation, memo: string): Observable<void> {
    if (!this._book) {
      throw new Error('invalid function call');
    }

    if (!this._canCreateAnnotation(viewerAnnot)) {
      return throwError(() => {
        const error = new Error('max annotation text length exceeded');
        error.name = MAX_ANNOTATION_LENGTH_EXCEEDED_ERROR;
      });
    }

    // 추출한 텍스트에서 대체 문자 삭제
    viewerAnnot.text = normalizeAnnotationText(viewerAnnot.text);

    const position = calculateBukURLPositionInBook(this._book, viewerAnnot.url);
    const groupId = this.getState().groupId;
    const commentaryId = this._uiStateService.isCommentaryEditor
      ? this.getState().commentaryId
      : undefined;

    return this._memosAPIService
      .create(viewerAnnot, position, memo, groupId, commentaryId)
      .pipe(
        tap((addedMemo) => {
          if (this._uiStateService.isCommentaryEditor) {
            addedMemo.is_mine = 0;
          }

          this._rawItemsAnnotations
            ?.find((itemAnnotations) => itemAnnotations.iid === addedMemo.iid)
            ?.memos.unshift(addedMemo);

          this._onMyMemoChanged();
        }),
        map(() => undefined)
      );
  }

  updatePropertyOfMemo(memoId: string, updateFn: (memo: Memo) => void): void {
    if (!this._rawItemsAnnotations) {
      return;
    }

    for (const itemAnnotations of this._rawItemsAnnotations) {
      for (const memo of itemAnnotations.memos) {
        if (memo.id === memoId) {
          updateFn(memo);
          this._updateMemos();
          this._onAnnotationsChanged();
          return;
        }
      }
    }
  }

  updateMemo(memoId: string, content: string): Observable<void> {
    return this._memosAPIService.update(memoId, content).pipe(
      tap(() => {
        this.updatePropertyOfMemo(memoId, (memo) => (memo.content = content));
      })
    );
  }

  likeMemo(memoId: string): Observable<void> {
    return this._memosAPIService.like(memoId).pipe(
      tap(() => {
        this.updatePropertyOfMemo(memoId, (memo) => {
          memo.like_count++;
          memo.is_liked = 1;
        });
      })
    );
  }

  unlikeMemo(memoId: string): Observable<void> {
    return this._memosAPIService.unlinke(memoId).pipe(
      tap(() => {
        this.updatePropertyOfMemo(memoId, (memo) => {
          memo.like_count--;
          memo.is_liked = 0;
        });
      })
    );
  }

  copyMemo(memoId: string): Observable<void> {
    return this._memosAPIService.copyToSingleMode(memoId).pipe(
      tap(() => {
        this.updatePropertyOfMemo(memoId, (memo) => memo.copy_count++);
      })
    );
  }

  blockMemo(memoId: string): Observable<void> {
    return this._memosAPIService.block(memoId).pipe(
      tap(() => {
        this._rawItemsAnnotations?.forEach((itemAnnotations) => {
          itemAnnotations.memos = itemAnnotations.memos.filter(
            (memo) => memo.id !== memoId
          );
        });

        this._updateMemos();
        this._onAnnotationsChanged();
      })
    );
  }

  deleteMemos(viewerAnnots: ViewerAnnotation[]): Observable<void> {
    const ids = viewerAnnots.reduce((_ids, viewerAnnot) => {
      const id = this.getMemoFromViewerAnnotation(viewerAnnot)?.id;

      if (id != null) {
        _ids.push(id);
      }

      return _ids;
    }, [] as string[]);

    if (ids.length === 0) {
      return of(undefined);
    }

    return this.deleteMemosById(ids);
  }

  deleteMemosById(ids: string | string[]): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._memosAPIService.delete(ids).pipe(
      tap(() => {
        this._rawItemsAnnotations?.forEach((itemAnnotations) => {
          itemAnnotations.memos = itemAnnotations.memos.filter(
            (memo) => ids.indexOf(memo.id) === -1
          );
        });

        this._onMyMemoChanged();
      })
    );
  }

  getHighlightFromViewerAnnotation(
    viewerAnnot: ViewerAnnotation
  ): Highlight | undefined {
    const iid = parseBukURL(viewerAnnot.url).iid;

    if (this._uiStateService.isCommentaryEditor) {
      return this._rawItemsAnnotations
        ?.find((itemAnnotations) => itemAnnotations.iid === iid)
        ?.highlights.find((a) => a.url === viewerAnnot.url);
    } else {
      return this._rawItemsAnnotations
        ?.find((itemAnnotations) => itemAnnotations.iid === iid)
        ?.highlights.find((a) => a.url === viewerAnnot.url && a.is_mine);
    }
  }

  getMemoFromViewerAnnotation(viewerAnnot: ViewerAnnotation): Memo | undefined {
    const iid = parseBukURL(viewerAnnot.url).iid;

    if (this._uiStateService.isCommentaryEditor) {
      return this._rawItemsAnnotations
        ?.find((itemAnnotations) => itemAnnotations.iid === iid)
        ?.memos.find((a) => a.url === viewerAnnot.url);
    } else {
      return this._rawItemsAnnotations
        ?.find((itemAnnotations) => itemAnnotations.iid === iid)
        ?.memos.find((a) => a.url === viewerAnnot.url && a.is_mine);
    }
  }

  private _canCreateAnnotation(viewerAnnot: ViewerAnnotation): boolean {
    return (
      viewerAnnot.type !== AnnotationType.Highlight ||
      viewerAnnot.text.trim().length <= Config.selectionLimitLength
    );
  }

  private _updateSharedHighlights(calcOverlap: boolean): void {
    if (!this._rawItemsAnnotations) {
      this._sharedHighlights = [];
      return;
    }

    if (calcOverlap) {
      // 그룹 하이라이트
      const minOverlappingCount = this._state$.value.minOverlappingCount;
      const bid = this._state$.value.bid!;

      const overlappingRanges = getOverlappingRanges(
        this._rawItemsAnnotations,
        minOverlappingCount
      );

      const styleClass = MOMO_HIGHLIGHT_STYLE_CLASS_OVERLAP3;

      this._sharedHighlights = Object.entries(overlappingRanges).flatMap(
        ([iid, ranges]) => {
          return ranges.map(([start, end]) => {
            return {
              type: AnnotationType.Highlight,
              url: createBukURL(bid, iid, `${start}-${end}`),
              text: '',
              styleClass,
            };
          });
        }
      );
    } else {
      const styleClass =
        this._state$.value.commentaryId !== undefined
          ? MOMO_HIGHLIGHT_STYLE_CLASS_COMMENTARY
          : MOMO_HIGHLIGHT_STYLE_CLASS_MEMBER;
      this._sharedHighlights = this._rawItemsAnnotations.flatMap(
        (itemAnnotations) => {
          return itemAnnotations.highlights
            .filter((highlight) => !highlight.is_mine)
            .map((highlight) =>
              convertHighlightToViewerAnnotation(highlight, styleClass)
            );
        }
      );
    }
  }

  private _updateMemos(): void {
    if (!this._rawItemsAnnotations) {
      this._itemsMemos = {};
      return;
    }

    this._itemsMemos = getMemoGroupsByRangeStart(this._rawItemsAnnotations);
  }

  private _getMemos(): ViewerAnnotation[] {
    return Object.entries(this._itemsMemos).flatMap(([, memoGroups]) => {
      return memoGroups.flatMap((memos) => {
        return memos.map(convertMemoToViewerAnnotation);
      });
    });
  }

  private _getMyHighlights(): ViewerAnnotation[] {
    if (!this._rawItemsAnnotations) {
      return [];
    }

    if (!this._state$.value.showMine) {
      return [];
    }

    return getMyHighlights(this._rawItemsAnnotations).map((highlight) =>
      convertHighlightToViewerAnnotation(highlight)
    );
  }

  private _onMyHighlightChanged(): void {
    this._onAnnotationsChanged();
  }

  private _onMyMemoChanged(): void {
    this._updateMemos();
    this._onAnnotationsChanged();
  }

  private _onAnnotationsChanged(): void {
    let annotations = [] as ViewerAnnotation[];

    // 내 하이라이트
    annotations = annotations.concat(this._getMyHighlights());

    // 그룹 또는 코멘터리 하이라이트
    annotations = annotations.concat(this._sharedHighlights);

    // 그룹, 코멘터리 하이라이트 또는 내 메모
    annotations = annotations.concat(this._getMemos());

    this._itemsAnnotations$.next(annotations);
  }

  private _startCheckUpdate(): void {
    this._stopCheckUpdate();

    this._checkUpdateIntervalSubscription = interval(UPDATE_CHECK_PERIOD)
      .pipe(
        filter(() => !this._hasUpdate$.value),
        switchMap(() => {
          const state = this.getState();

          if (state.groupId == null || !state.iids) {
            throw new Error('cannot check updates');
          }

          return this._annotationsAPIService.getUpdatesOfGroup(
            state.groupId!,
            state.iids!,
            this._timestamp!
          );
        })
      )
      .subscribe((response) => {
        this._hasUpdate$.next(response.items.some((item) => item.memos > 0));
      });
  }

  private _stopCheckUpdate(): void {
    this._checkUpdateIntervalSubscription?.unsubscribe();
    this._checkUpdateIntervalSubscription = null;
  }

  private get _isCheckingUpdate(): boolean {
    return !!this._checkUpdateIntervalSubscription;
  }
}
