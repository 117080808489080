import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subject, switchMap } from 'rxjs';
import { Bookshelf, BookshelfBook } from 'shared/models';
import { BookshelfAPIService, QueryResult } from 'shared/services';
import { DialogService } from 'shared/ui';
import { AppendixComponent } from 'bookshelf';

@Component({
  selector: 'app-others-bookshelf-detail-dialog',
  templateUrl: './others-bookshelf-detail-dialog.component.html',
  styleUrl: './others-bookshelf-detail-dialog.component.scss',
})
export class OthersBookshelfDetailDialogComponent implements OnInit, OnDestroy {
  @Input() bookshelfId!: string;
  @Input() parentElement?: HTMLDivElement;
  @Output() closeButtonClick = new EventEmitter<void>();
  @Output() backPage = new EventEmitter<string>();

  @ViewChild('bookContainer') private bookContainer?: ElementRef;

  private _pageSubject = new Subject<number>();

  public _userId: string = this._router.url.split('/')[2];
  public _bookshelf?: Bookshelf;
  public _bookshelvesBookList?: QueryResult<BookshelfBook>;

  public _currentDate?: string;
  public _screenWidth: number = window.innerWidth;
  public _currSearchValue: string = '';
  public _isSearchButton: boolean = false;
  public _isDetailButton: boolean = false;

  public _currentPage = 0;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _bookshelfAPIService: BookshelfAPIService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this._pageSubject
      .pipe(
        switchMap((page) => {
          this._isLoading = true;

          return this._bookshelfAPIService.getBooks(
            this.bookshelfId,
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;

        if (this.bookContainer) {
          this.bookContainer.nativeElement.scrollTop = 0;
        }

        if (this.parentElement) {
          this.parentElement.scrollTop = 0;
        }

        this._totalItemCount = result.total;
        this._bookshelvesBookList = result;
      });

    this._bookshelfAPIService.getInfo(this.bookshelfId).subscribe((result) => {
      this._bookshelf = result;
    });

    this._pageSubject.next(0);
  }

  ngOnDestroy(): void {
    this._pageSubject.complete();
  }

  public _onClickSearchButton(): void {
    this._isSearchButton = !this._isSearchButton;
  }

  public _onClickDetailButton(): void {
    this._isDetailButton = !this._isDetailButton;
  }

  public _clearCurrSearchValue(): void {
    this._currSearchValue = '';
  }

  public _changeReadingBookTime(inputDate: Date): string {
    const dateString = inputDate;
    const dateObject = new Date(dateString);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();

    return `${year}. ${month}.${date} ${hours}:${minutes}`;
  }

  public _changeSalesType(type: string): string | void {
    switch (type) {
      case 'rent':
        return '대여';
      case 'free':
        return '무료';
      case 'paper':
        return '종이책';
      default:
        return;
    }
  }

  public _onClickAppendix(): void {
    this._dialogService.open(AppendixComponent);
  }

  public _onPageChange(pageIndex: number): void {
    this._pageSubject.next(pageIndex);
  }

  public _onCloseButtonClick(): void {
    this.closeButtonClick.emit();
  }

  public _routeBookDetailPage(id: string): void {
    window.open(`${environment.serviceOrigin}/@${id}`, '_blank');
  }

  public _backPage(): void {
    this.backPage.emit('back');
  }
}
