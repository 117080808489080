import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

export class OriginInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (/^(https?:)?\/\//.test(req.url)) {
      return next.handle(req);
    }

    return next.handle(
      req.clone({
        url: `${environment.serverOrigin}${req.url}`,
      })
    );
  }
}
