export const environment = {
  production: true,
  appVersion: '0.0.0',
  serverOrigin: 'https://dev.buk.io',
  serviceOrigin: 'https://dev.buk.io',
  dashboardOrigin: 'https://dashbaord.dev.buk.io',
  wsServerOrigin: `https://wsdev.buk.io`,
  minRequiredNativeAppVersion: '1.1.0',
  amplitudeAPIKey: 'a777c3912cb793c7c9024f997454fb65',
};
