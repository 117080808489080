<viewer-memo
  [menu]="memo.is_mine ? myMenu : _singleMode ? undefined : othersMenu"
  [limitHeight]="limitHeight"
  [content]="memo.content"
>
  <button
    sui-icon-button
    viewer-memo-top-button
    *ngIf="memo.is_mine"
    class="share-button"
    (click)="_onShareButtonClick(); $event.stopPropagation()"
  >
    <sui-icon name="공유-1" type="solid"></sui-icon>
  </button>

  <viewer-memo-type
    [type]="type"
    [isPinned]="!_singleMode && !!memo.pinned_at"
    [isUnread]="isUnread ?? false"
  ></viewer-memo-type>

  <viewer-memo-tag class="block-tag" *ngIf="memo.is_blinded">
    신고로 인해 숨김 처리된 메모입니다.
  </viewer-memo-tag>

  <viewer-memo-tag *ngIf="_singleMode && memo.copied_at && memo.is_mine">
    <div class="copy-tag">
      <div>{{ memo.copied_at | date : 'y.MM.dd. HH:mm' }} |&nbsp;</div>
      <div class="source-nickname">{{ memo.source }}</div>
      <div>님의 메모 복사</div>
    </div>
  </viewer-memo-tag>

  <viewer-memo-actions>
    <button
      class="icon-button"
      [disabled]="!!memo.is_mine && !_singleMode"
      [class.is-hidden]="memo.is_mine && _singleMode"
      (click)="_onLikeButtonClick()"
    >
      <sui-icon
        name="추천"
        [type]="memo.is_liked ? 'solid' : 'outline'"
      ></sui-icon>
      {{ memo.like_count }}
    </button>
    <button
      class="icon-button"
      [disabled]="!!memo.is_mine && !_singleMode"
      [class.is-hidden]="_singleMode"
      (click)="_onCopyToSingleModeButtonClick()"
    >
      <sui-icon name="복사" type="solid"></sui-icon>
      {{ memo.copy_count }}
    </button>
    <button class="text-button" (click)="_onSeeDetailsButtonClick()">
      본문 상세 확인
    </button>
  </viewer-memo-actions>

  <viewer-memo-author
    [avatarImageURL]="memo.author.avatar_image_url"
    [name]="memo.author.nickname"
    [isOnline]="isAuthorOnline"
    (authorClick)="!memo.is_mine && _onAuthorClick()"
    [class.is-clickable]="!memo.is_mine"
  >
  </viewer-memo-author>
</viewer-memo>

<ng-template #myMenu>
  <sui-menu class="viewer-memo-menu" cdkMenu>
    <button
      sui-menu-item
      cdkMenuItem
      *ngIf="!memo.is_blinded && !memo.copied_at"
      (click)="_onEditMenuClick()"
    >
      수정하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteMenuClick()">
      삭제하기
    </button>
    <button
      sui-menu-item
      cdkMenuItem
      *ngIf="!memo.is_blinded && !memo.copied_at"
      (click)="_onExportMenuClick()"
    >
      내보내기
    </button>
  </sui-menu>
</ng-template>

<ng-template #othersMenu>
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onReportMenuClick()">
      메모/회원 신고하기
    </button>
    <button sui-menu-item cdkMenuItem (click)="_onBlindMenuClick()">
      메모 차단하기
    </button>
  </sui-menu>
</ng-template>
