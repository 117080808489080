<div
  class="color-picker-overlay"
  [class.is-active]="_isColorPickerOpened"
></div>

<div
  class="tool-bar-container"
  cdkDrag
  cdkDragBoundary="viewer-root"
  (cdkDragStarted)="_onToolBarDragStarted()"
>
  <div class="tool-bar" (touchmove)="$event.preventDefault()">
    <button
      [class.is-on]="_selectedTool === _Tool.Pen1"
      (touchstart)="_onSelectTool(_Tool.Pen1)"
      (touchmove)="$event.preventDefault()"
    >
      <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.14258 19.8927L12.1926 19.8927C13.5259 19.8927 14.7676 19.2344 15.5092 18.126C16.2509 17.026 16.4009 15.626 15.9009 14.3927L13.2092 7.73438L7.05091 7.73438L2.14258 19.8927Z"
          fill="currentColor"
        />
        <path
          d="M10.1359 0.11084L7.46094 6.73584L12.8109 6.73584L10.1359 0.11084Z"
          fill="currentColor"
        />
        <path
          d="M21.1639 9L21.1639 0.599999L22.5199 0.599999L22.5199 9L21.1639 9ZM19.4839 1.836L19.4839 0.599999L22.3999 0.599999L22.3999 1.836L19.4839 1.836Z"
          fill="currentColor"
        />
      </svg>
    </button>
    <button
      [class.is-on]="_selectedTool === _Tool.Pen2"
      (touchstart)="_onSelectTool(_Tool.Pen2)"
      (touchmove)="$event.preventDefault()"
    >
      <svg
        width="26"
        height="20"
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.14258 19.8927L12.1926 19.8927C13.5259 19.8927 14.7676 19.2344 15.5092 18.126C16.2509 17.026 16.4009 15.626 15.9009 14.3927L13.2092 7.73438L7.05091 7.73438L2.14258 19.8927Z"
          fill="currentColor"
        />
        <path
          d="M10.1359 0.11084L7.46094 6.73584L12.8109 6.73584L10.1359 0.11084Z"
          fill="currentColor"
        />
        <path
          d="M19.5079 8.184L22.6039 4.896C22.8599 4.624 23.0679 4.38 23.2279 4.164C23.3879 3.948 23.5039 3.744 23.5759 3.552C23.6479 3.36 23.6839 3.164 23.6839 2.964C23.6839 2.588 23.5599 2.296 23.3119 2.088C23.0719 1.872 22.7639 1.764 22.3879 1.764C21.9879 1.764 21.6359 1.864 21.3319 2.064C21.0279 2.264 20.7559 2.576 20.5159 3L19.5559 2.208C19.8839 1.64 20.2879 1.212 20.7679 0.924C21.2479 0.628 21.8039 0.48 22.4359 0.48C22.9559 0.48 23.4119 0.584 23.8039 0.792C24.1959 1 24.4999 1.292 24.7159 1.668C24.9319 2.036 25.0399 2.464 25.0399 2.952C25.0399 3.304 24.9959 3.62 24.9079 3.9C24.8279 4.172 24.6879 4.448 24.4879 4.728C24.2879 5 24.0119 5.32 23.6599 5.688L21.2839 8.148L19.5079 8.184ZM19.5079 9L19.5079 8.184L20.6959 7.764L25.2079 7.764L25.2079 9L19.5079 9Z"
          fill="currentColor"
        />
      </svg>
    </button>
    <button
      [class.is-on]="_isRulerOn"
      (touchstart)="_toggleRuler()"
      (touchmove)="$event.preventDefault()"
    >
      <sui-icon name="자" type="solid"></sui-icon>
    </button>
    <button
      [class.is-on]="_selectedTool === _Tool.Eraser"
      (touchstart)="_onSelectTool(_Tool.Eraser)"
      (touchmove)="$event.preventDefault()"
    >
      <sui-icon name="지우개" type="solid"></sui-icon>
    </button>
    <div class="divider"></div>
    <button
      [disabled]="!_canRedo"
      (touchstart)="_onRedoButtonClick()"
      (touchmove)="$event.preventDefault()"
    >
      <sui-icon name="앞으로 가기"></sui-icon>
    </button>
    <button
      [disabled]="!_canUndo"
      (touchstart)="_onUndoButtonClick()"
      (touchmove)="$event.preventDefault()"
    >
      <sui-icon name="뒤로가기"></sui-icon>
    </button>
    <div class="divider"></div>
    <button (click)="_onClearButtonClick()">
      <sui-icon name="휴지통" type="solid"></sui-icon>
    </button>
    <div class="divider"></div>
    <button (click)="_onChangePageButtonClick(1)">
      <sui-icon name="페이지 앞으로" type="solid"></sui-icon>
    </button>
    <button (click)="_onChangePageButtonClick(-1)">
      <sui-icon name="페이지 뒤로" type="solid"></sui-icon>
    </button>
    <div class="divider"></div>
    <button (click)="_onCloseButtonClick()">
      <sui-icon name="엑스"></sui-icon>
    </button>
    <button class="handle" cdkDragHandle>
      <sui-icon name="옮기기" type="solid"></sui-icon>
    </button>
  </div>
  <div class="pen-controls" *ngIf="_selectedPenConfig">
    <div class="palette">
      <div class="colors">
        <ng-container *ngFor="let color of _colors; index as i">
          <div
            class="color"
            [class.is-selected]="_selectedPenConfig.colorIndex === i"
            [style.color]="color.hex"
            [style.border-color]="color.hex + '33'"
            (click)="_onPenColorClick(i)"
            [cdkMenuTriggerFor]="
              _selectedPenConfig.colorIndex === i ? colorPickerMenu : null
            "
            [cdkMenuTriggerData]="{ index: i }"
            (cdkMenuClosed)="_colorPickerClosed(); _isColorPickerOpened = false"
            (cdkMenuOpened)="_isColorPickerOpened = true"
          >
            <div class="circle"></div>
            <sui-icon name="체크"></sui-icon>
          </div>
          <br *ngIf="i === _colors.length / 2 - 1" />
        </ng-container>
      </div>
    </div>
    <div class="slider width">
      <viewer-pen-width-slider
        [(width)]="_selectedPenConfig.width"
        (widthChange)="_onPenConfigChanged()"
      ></viewer-pen-width-slider>
    </div>
    <div class="slider opacity">
      <viewer-pen-opacity-slider
        [(opacity)]="_selectedPenConfig.opacity"
        (opacityChange)="_onPenConfigChanged()"
      ></viewer-pen-opacity-slider>
    </div>
  </div>
</div>

<ng-template #colorPickerMenu let-index="index">
  <div class="color-picker" cdkMenu>
    <viewer-color-picker
      (click)="$event.stopPropagation()"
      [initialColor]="_colors[index].hex"
      (colorChange)="_colorChanged(index, $event)"
    ></viewer-color-picker>
  </div>
</ng-template>
