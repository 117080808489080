import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelfAPIService } from 'shared/services';
import { DialogService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-add-bookshelf',
  templateUrl: './add-bookshelf.component.html',
  styleUrl: './add-bookshelf.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class AddBookshelfComponent
  extends TypedDialog<void, { canceled: 'success' | 'failed' }>
  implements OnInit, OnDestroy
{
  private _addBookshelfSubject = new Subject<void>();

  public _inputBookshelfValue: string = '';
  public _isPublic: 0 | 1 = 0;

  constructor(
    private _dialogService: DialogService,
    private _bookshelfAPIService: BookshelfAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._addBookshelfSubject
      .pipe(
        switchMap(() => {
          return this._bookshelfAPIService.createBookshelf(
            this._inputBookshelfValue,
            this._isPublic
          );
        })
      )
      .subscribe(() => {
        this._dialogRef.close({ canceled: 'success' });
      });
  }

  ngOnDestroy(): void {
    this._addBookshelfSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close();
  }

  public _changeIsPublic(event: any): void {
    if (event.srcElement.checked) {
      this._isPublic = 1;
    } else {
      this._isPublic = 0;
    }
  }

  public _onClickSubmit(): void {
    this._addBookshelfSubject.next();
  }
}
