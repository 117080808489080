<img class="logo" src="assets/images/logo-font.svg" />
<div class="container">
  <div class="title">
    <img class="image" src="assets/images/reading-mode-selection-intro.png" />
    <div class="text">
      <div class="highlight"></div>
      <span><b>읽기 모드</b> 선택하기</span>
    </div>
  </div>
  <button
    class="button"
    sui-solid-button
    varSize="medium48"
    (click)="_onSelectSingleMode()"
  >
    혼자읽기
  </button>
  <button
    class="button"
    sui-solid-button
    varSize="medium48"
    [disabled]="_isLoadingModeAvailability"
    (click)="_onSelectGroupMode()"
  >
    함께읽기
  </button>
</div>
