import { Component, Input } from '@angular/core';
import { ReadingGroup } from 'shared/models';

interface ReadingGroupSummaryView {
  name: ReadingGroup['name'];
  type: ReadingGroup['type'];
  member_count: ReadingGroup['member_count'];
  max_member_count: ReadingGroup['max_member_count'];
  highlight_count: ReadingGroup['highlight_count'];
  memo_count: ReadingGroup['memo_count'];
  leader_nickname: ReadingGroup['leader_nickname'];
  status: ReadingGroup['status'];
  is_hidden: ReadingGroup['is_hidden'];
}

@Component({
  selector: 'lib-reading-group-summary',
  templateUrl: './reading-group-summary.component.html',
  styleUrl: './reading-group-summary.component.scss',
})
export class ReadingGroupSummaryComponent {
  @Input() group!: ReadingGroupSummaryView;
}
