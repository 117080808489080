import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { APIError, NotesAPIService } from 'shared/services';
import { ToastService } from 'shared/ui';

@Component({
  selector: 'lib-note-send',
  templateUrl: './note-send.component.html',
  styleUrl: './note-send.component.scss',
})
export class NoteSendComponent implements AfterViewInit {
  @ViewChild('textarea')
  private _textareaElem!: ElementRef<HTMLTextAreaElement>;

  @Output() noteSent = new EventEmitter<void>();

  @Input() targetUserId!: string;

  public _noteFormControl: UntypedFormControl;

  public _isSneding = false;
  public _hasEmptyValue = false;

  constructor(
    private _notesAPIService: NotesAPIService,
    private _toastService: ToastService
  ) {
    this._noteFormControl = new UntypedFormControl('', {
      validators: Validators.maxLength(2000),
      updateOn: 'change',
    });

    this._noteFormControl.valueChanges.subscribe((value) => {
      this._hasEmptyValue = !value.trim();
    });
  }

  ngAfterViewInit(): void {
    this._textareaElem.nativeElement.focus();
  }

  _onSendButtonClick(): void {
    this._isSneding = true;

    this._notesAPIService
      .send(this.targetUserId, this._noteFormControl.value)
      .subscribe({
        next: () => {
          this._toastService.open('쪽지 보내기가 완료되었습니다.');
          this.noteSent.emit();
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }
}
