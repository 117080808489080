<div class="container">
  <sui-flag-tag
    class="type-tag"
    [color]="group.type === 'private' ? 'gray' : 'point'"
    >{{ group.type === 'private' ? '비공개' : '공개' }}</sui-flag-tag
  >
  <div class="box">
    <div class="status-tags">
      <sui-rounded-tag *ngIf="group.status === 'invited'" color="point"
        >* 초대된 그룹입니다.</sui-rounded-tag
      >
      <sui-rounded-tag *ngIf="group.status === 'kicked'"
        >* 내보내기된 그룹입니다.</sui-rounded-tag
      >
    </div>

    <div class="top">
      <div class="name">
        {{ group.name }}
        <div class="actions phone">
          <ng-content select=".phone"></ng-content>
        </div>
      </div>
      <div class="info">
        그룹장: {{ group.leader_nickname }}<br class="desktop-br" />
        <span class="phone">&nbsp;&nbsp;&nbsp;</span>그룹원:
        <span
          class="member-count"
          [class.is-warning]="group.member_count === group.max_member_count"
          >{{ group.member_count }}</span
        >/{{ group.max_member_count }}명
      </div>
    </div>
    <div class="bottom">
      <div class="sharing-status">
        <div class="item">
          <sui-icon name="하이라이트"></sui-icon>공유 하이라이트 ({{
            group.highlight_count
          }})
        </div>
        <div class="item">
          <sui-icon name="메모"></sui-icon>공유 메모 ({{ group.memo_count }})
        </div>
      </div>
      <div class="actions desktop">
        <ng-content select=".desktop"></ng-content>
      </div>
    </div>
  </div>
</div>
