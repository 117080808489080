import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Memo } from 'shared/models';
import { DialogService, ToastService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';

import { MemoDialogComponent } from '../../dialogs/memo-dialog/memo-dialog.component';
import { APIError } from 'shared/services';
import { UIStateService } from '../../services/ui-state.service';

@Component({
  selector: 'viewer-commentary-editor-memo',
  templateUrl: './commentary-editor-memo.component.html',
  styleUrl: './commentary-editor-memo.component.scss',
})
export class CommentaryEditorMemoComponent {
  @Output() memoDeleted = new EventEmitter<void>();

  @Input() text!: string;
  @Input() limitHeight = false;
  @Input() memo!: Memo;

  constructor(
    private _annotationsService: AnnotationsV2Service,
    private _eventBusService: EventBusService,
    private _toastService: ToastService,
    private _dialogService: DialogService,
    private _uiStateService: UIStateService
  ) {}

  _onSeeDetailsButtonClick(): void {
    this._eventBusService.fire(
      'ReadingModeMemoComponent:seeDetailsButtonClick',
      {
        url: this.memo.url,
      }
    );
  }

  _onEditMenuClick(): void {
    if (!this._uiStateService.canEditCommentary) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    this._dialogService
      .open(MemoDialogComponent, {
        data: {
          id: this.memo.id,
          text: this.text,
          content: this.memo.content,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.deleted) {
          this.memoDeleted.emit();
        } else if (result?.updatedMemo) {
          // this.memo.content = result.updatedMemo;
          // this._content = result.updatedMemo;
        }
      });
  }

  _onDeleteMenuClick(): void {
    if (!this._uiStateService.canEditCommentary) {
      this._toastService.openWarning(
        '판매 설정이 완료된 코멘터리는 수정이 불가합니다.'
      );

      return;
    }

    this._annotationsService.deleteMemosById(this.memo.id).subscribe({
      next: () => {
        this.memoDeleted.emit();
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }
}
