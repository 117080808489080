<div class="row selection-title" (click)="_isOptionsOpened = !_isOptionsOpened">
  <div class="text">
    {{ _selectedCommentaryAuthor ?? '코멘테이터 선택' }}
  </div>
  <sui-icon
    class="dropdown icon"
    name="닫힘"
    [class.is-opened]="_isOptionsOpened"
  ></sui-icon>
</div>
<div class="options" [class.is-opened]="_isOptionsOpened">
  <ng-container *ngFor="let commentary of commentaries">
    <div
      class="row option"
      *ngIf="commentary.owned"
      (click)="_onOptionClick(commentary.id)"
    >
      <div class="text">
        {{ commentary.author }}
      </div>
      <sui-icon
        class="check icon"
        [class.is-hidden]="selectedCommentaryId !== commentary.id"
        name="체크"
      ></sui-icon>
    </div>
  </ng-container>
</div>
