import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';

import { Subject, fromEvent } from 'rxjs';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';

import { BookType } from '@bukio/viewer';

import { Memo } from 'shared/models';

import { OnlineReadingMemberService } from '../../services/online-reading-member.service';
import { UIStateService } from '../../services/ui-state.service';
import { EventBusService } from '../../services/event-bus.service';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { MemoReadStatusService } from '../../services/memo-read-status.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';

@Component({
  selector: 'viewer-memo-popup',
  templateUrl: './memo-popup.component.html',
  styleUrls: ['./memo-popup.component.scss'],
})
export class MemoPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popup', { static: true }) _popupElem!: ElementRef<HTMLDivElement>;
  @ViewChild('dragOverlay', { static: true })
  _dragOverlayElem!: ElementRef<HTMLDivElement>;
  @ViewChild(CdkDrag, { static: true }) _cdkDrag!: CdkDrag;

  private _unsubscriber = new Subject<void>();

  public _memos: Memo[] = [];
  public _text?: string;
  public _groupIndex = -1; // 사이드바에 넘기기 위함
  public _currentIid?: string; // 사이드바에 넘기기 위함
  public _isOpened = false;
  public _isPDF = false;

  public _onlineUserMap: { [userId: string]: boolean } = {};

  public _contentsScale = 1;

  public _isCommentaryEditorMode = false;

  constructor(
    private _eventBus: EventBusService,
    private _annotationsService: AnnotationsV2Service,
    private _uiStateService: UIStateService,
    private _onlineReadingMemberService: OnlineReadingMemberService,
    private _memoReadStatusService: MemoReadStatusService,
    private _readingGroupsService: ReadingGroupsService
  ) {}

  ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this._unsubscriber), throttleTime(500))
      .subscribe(() => {
        this.close();
      });

    this._eventBus
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((event) => {
        this._isPDF = event.book.meta.type === BookType.PDF;
        this._isCommentaryEditorMode = this._uiStateService.isCommentaryEditor;
      });

    this._eventBus
      .on('ContentsComponent:zoomScaleChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((event) => {
        this._contentsScale = event.scale / 100;
      });

    this._eventBus
      .on('ContentsComponent:memoIndicatorClick')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(() => !this._uiStateService.canUseMemoSidebar())
      )
      .subscribe(({ iid, groupIndex, trigger }) => {
        this._setPositionWithTrigger(trigger);
        this._isOpened = true;

        this._groupIndex = groupIndex;
        this._currentIid = iid;

        this._updateMemoList();
      });

    this._onlineReadingMemberService.onlineStatusChange$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        if (this.isOpened) {
          this._onlineUserMap[data.userId] = data.isOnline;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  private _setPositionWithTrigger(trigger: HTMLElement): void {
    const triggerRect = trigger.getBoundingClientRect();

    let top = Math.max(triggerRect.top * this._contentsScale, 0);
    let left = Math.max(triggerRect.left * this._contentsScale, 0);
    const triggerHeight = trigger.offsetHeight * this._contentsScale;

    const frameRect =
      trigger.ownerDocument.defaultView?.frameElement?.getBoundingClientRect();
    if (frameRect) {
      top += frameRect.top;
      left += frameRect.left;
    }

    const popupMaxHeight = 360;
    const popupWidth = Math.min(window.innerWidth * 0.8, 310);

    if (top + triggerHeight + popupMaxHeight > window.innerHeight) {
      // 인디케이터 상단에
      this._popupElem.nativeElement.style.bottom =
        window.innerHeight - top + 5 * this._contentsScale + 'px';
      this._popupElem.nativeElement.style.removeProperty('top');
    } else {
      // 인디케이터 하단에
      this._popupElem.nativeElement.style.top =
        top + triggerHeight + 10 * this._contentsScale + 'px';
      this._popupElem.nativeElement.style.removeProperty('bottom');
    }

    if (left + popupWidth < window.innerWidth) {
      // 인디케이터와 왼쪽 정렬
      this._popupElem.nativeElement.style.left = left + 'px';
      this._popupElem.nativeElement.style.removeProperty('right');
    } else if (left - popupWidth > 0) {
      // 인디케이터와 오른쪽 정렬
      // this._popupElem.nativeElement.style.right =
      // window.innerWidth - left + 'px';
      this._popupElem.nativeElement.style.right = '10px';
      this._popupElem.nativeElement.style.removeProperty('left');
    } else {
      // 센터에
      this._popupElem.nativeElement.style.left =
        (window.innerWidth - popupWidth) / 2 + 'px';
      this._popupElem.nativeElement.style.removeProperty('right');
    }

    this._cdkDrag.reset();
  }

  private _updateMemoList(): void {
    if (!this._currentIid) {
      return;
    }

    const group = this._annotationsService.getMemosOfItem(this._currentIid)[
      this._groupIndex
    ];

    if (!group) {
      return;
    }

    const shortMemo = group.reduce((prev, current) =>
      prev && prev.text.length < current.text.length ? prev : current
    );

    this._text = shortMemo.text;
    this._memos = group;

    this._onlineUserMap = {};

    group.forEach((m) => {
      this._onlineUserMap[m.author.id] =
        this._onlineReadingMemberService.isOnline(m.author.id);
    });
  }

  _onCloseButtonClick(): void {
    this.close();
  }

  get isOpened(): boolean {
    return this._isOpened;
  }

  close(): void {
    if (!this._isOpened) {
      return;
    }

    this._isOpened = false;
    this._onDragReleased();
    this._eventBus.fire('MemoPopupComponent:closed');

    this._readingGroupsService.refreshGroupInfo();
  }

  _seeMemoListButtonClick(): void {
    this._isOpened = false;
    this._eventBus.fire('MemoPopupComponent:seeMemoListButtonClick', {
      iid: this._currentIid!,
      groupIndex: this._groupIndex,
    });
  }

  _onMemoDeleted(): void {
    this._updateMemoList();

    if (this._memos.length === 0) {
      this.close();
    }
  }

  _onMemoBlinded(): void {
    this.close();
  }

  _onMemoUserBlocked(): void {
    this.close();
  }

  _onDragStarted(): void {
    this._dragOverlayElem.nativeElement.style.display = 'block';
  }

  _onDragReleased(): void {
    this._dragOverlayElem.nativeElement.style.display = 'none';
  }

  _onMemoPinOptionChanged(): void {
    this._updateMemoList();
  }

  _onMemoRead(memo: Memo): void {
    !memo.is_read && this._memoReadStatusService.read(memo.id);
  }
}
