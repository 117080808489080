import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket, io } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private _socket?: Socket;

  constructor() {}

  createSocket(serverURL: string): void {
    if (this._socket) {
      throw new Error('socket alread created');
    }

    this._socket = io(serverURL, { withCredentials: true });
  }

  getSocket(): Socket {
    if (!this._socket) {
      throw new Error('socket not created');
    }

    return this._socket;
  }

  message(): Observable<any> {
    return new Observable((observer) => {
      const next = (data: string): void => {
        try {
          observer.next(JSON.parse(data));
        } catch {
          console.log('socket message parsing error', data);
        }
      };

      this._socket?.on('message', next);

      return () => {
        this._socket?.off('message', next);
      };
    });
  }
}
