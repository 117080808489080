import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Annotation, HighlightStyle, Rect } from '@bukio/viewer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToastService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { StorageService } from '../../services/storage.service';

import { Book } from '../../models/book.model';

import { Config } from '../../constants/config';
import {
  HighlightColor,
  HIGHLIGHT_COLORS,
} from '../../constants/highlight-colors';
import { CrossBrowsing } from '../../constants/cross-browsing';

import { getIIDFromRange } from '../../utils/buk-viewer';

import { UIStateService } from '../../services/ui-state.service';
import {
  VIEWER_ENVIRONMENT,
  ViewerModuleEnvironment,
} from '../../viewer.module';

export enum HighlightAction {
  Add,
  Remove,
  Change,
}

export enum ContextMenuItem {
  CopyText,
  ShareTwitter,
  ShareFacebook,
  CopyURL,
  MediaOverlay,
  Memo,
  SelectContinuously,
}

const ACTIVE_STYLE_CLASS = 'is-active';

export interface SelectionContext {
  selectedRange: Range;
  canPlayMediaOverlay: boolean;
  url: string;
  annotation?: Annotation;
  isAtPageBoundary: boolean;
}

enum MenuType {
  TextSelection,
  Highlight,
  Share,
  Colors,
  Commentator,
  ContinueSelecting,
}

function rgbaToWhiteBackground(rgbaColor: string): string {
  const rgba = rgbaColor.slice(5, -1).split(',').map(Number);
  const white = [255, 255, 255];

  const result = [];
  for (let i = 0; i < 3; i++) {
    result.push(Math.round((1 - rgba[3]) * white[i] + rgba[3] * rgba[i]));
  }

  return 'rgb(' + result.join(', ') + ')';
}

@Component({
  selector: 'viewer-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  private unsubscriber: Subject<void> = new Subject<void>();

  public _defaultHighlightStyles!: HighlightStyle[];
  public _additionalHighlightStyles!: HighlightStyle[];

  public _book?: Book;

  public _context?: SelectionContext;
  public _canShareURL = false;

  private _menuSpacing = 10;
  public _type = MenuType.TextSelection;
  public _prevType?: MenuType;

  public readonly _MenuItem = ContextMenuItem;
  public readonly _MenuType = MenuType;

  constructor(
    private _element: ElementRef,
    private _renderer: Renderer2,
    private _eventBusService: EventBusService,
    private _storageService: StorageService,
    private _toastService: ToastService,
    private _uiStateService: UIStateService,
    @Inject(VIEWER_ENVIRONMENT) private _environment: ViewerModuleEnvironment
  ) {}

  ngOnInit(): void {
    if (!this._environment.isApp && CrossBrowsing.touchDevice) {
      this._renderer.addClass(this._element.nativeElement, 'mobile');
    }

    this._eventBusService
      .on('ContentsComponent:highlightClick')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ context, offset }) => {
        this.init(context);

        setTimeout(() => {
          this.updatePositionByOffsetAndRange(
            offset.x,
            offset.y,
            context.selectedRange
          );
          this.show();
        });
      });

    this._eventBusService
      .on('ContentsComponent:select')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ context, rects }) => {
        this.init(context);

        setTimeout(() => {
          this.updatePositionByRects(
            rects,
            context.selectedRange.startContainer.ownerDocument?.defaultView
              ?.frameElement ?? undefined
          );
          this.show();
        });
      });

    this._eventBusService
      .on('ContentsComponent:deselect')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(() => {
        this.hide();
      });

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(({ book }) => {
        this._book = book;
      });
  }

  show(): void {
    this._setActive(true);
  }

  hide(): void {
    this._setActive(false);
  }

  private _getInitialMenuType(context: SelectionContext): MenuType {
    return this._uiStateService.isCommentaryEditor
      ? MenuType.Commentator
      : context.annotation
      ? MenuType.Highlight
      : MenuType.TextSelection;
  }

  init(context: SelectionContext): void {
    this._context = context;

    if (context.isAtPageBoundary) {
      this._type = MenuType.ContinueSelecting;
      this._prevType = this._getInitialMenuType(context);
    } else {
      this._type = this._getInitialMenuType(context);
    }

    const rangeIID = getIIDFromRange(context.selectedRange);
    this._canShareURL = this._book!.canShareItem(rangeIID);

    const defaultHighlightColors =
      this._storageService.loadMiscSettings().highlightColors;

    this._defaultHighlightStyles = [];
    this._additionalHighlightStyles = [];

    Object.entries(HIGHLIGHT_COLORS).forEach(([key, color]) => {
      if (defaultHighlightColors.indexOf(key as HighlightColor) !== -1) {
        this._defaultHighlightStyles.push({
          styleClass: key,
          style: `color: ${rgbaToWhiteBackground(color)}`,
        });
      } else {
        this._additionalHighlightStyles.push({
          styleClass: key,
          style: `color: ${rgbaToWhiteBackground(color)}`,
        });
      }
    });
  }

  private _setActive(isActive: boolean): void {
    this._setActiveStyleClass(isActive);
    this._eventBusService.fire('ContextMenuComponent:visibilityChange', {
      isVisible: isActive,
    });
  }

  private _setActiveStyleClass(isActive: boolean): void {
    if (isActive) {
      this._renderer.addClass(this._element.nativeElement, ACTIVE_STYLE_CLASS);
    } else {
      this._renderer.removeClass(
        this._element.nativeElement,
        ACTIVE_STYLE_CLASS
      );
    }
  }

  // 하위 메뉴로 변경 시 컨텍스트 메뉴 너비가 달라지는데 이 때 위치를 다시 계산하지 않도록
  // translateX(-50%) 사용하기 때문에 left나 right가 center로 오게 계산해야함
  private _updatePosition(centerFromLeft: number, top: number): void {
    let centerFromRight, bottom;

    const menuRect = this._element.nativeElement.getBoundingClientRect();
    const left = centerFromLeft - menuRect.width / 2;

    if (left < this._menuSpacing) {
      centerFromLeft = this._menuSpacing + menuRect.width / 2;
      centerFromRight = undefined;
    } else if (left + menuRect.width + this._menuSpacing > window.innerWidth) {
      centerFromLeft = undefined as any;
      centerFromRight = this._menuSpacing - menuRect.width / 2;
    }

    top += this._menuSpacing;

    if (top < this._menuSpacing) {
      top = this._menuSpacing;
      bottom = undefined;
    } else if (top + menuRect.height + this._menuSpacing > window.innerHeight) {
      top = undefined as any;
      bottom = this._menuSpacing;
    }

    Object.entries({
      top,
      right: centerFromRight,
      bottom,
      left: centerFromLeft,
    }).forEach(([key, value]) => {
      if (value != null) {
        this._renderer.setStyle(this._element.nativeElement, key, value + 'px');
      } else {
        this._renderer.removeStyle(this._element.nativeElement, key);
      }
    });
  }

  updatePositionByOffsetAndRange(
    offsetX: number,
    offsetY: number,
    range: Range
  ): void {
    const rangeRects = Array.from(range.getClientRects());
    const maxLineHeight = Math.max(...rangeRects.map((rect) => rect.height));

    const top = offsetY + maxLineHeight * 0.5;

    this._updatePosition(offsetX, top);
  }

  updatePositionByRects(rects: Rect[], rectFrame?: Element): void {
    const menuRect = this._element.nativeElement.getBoundingClientRect();

    const pageLeft = rectFrame ? rectFrame.getBoundingClientRect().left : 0;
    const pageRight = rectFrame ? rectFrame.getBoundingClientRect().right : 0;

    const rectsInViewport = rects.filter((rect) => {
      return rect.x > pageLeft && rect.x <= pageRight;
    });

    const x = Math.min(...rectsInViewport.map((rect) => rect.x));
    const y = Math.min(...rectsInViewport.map((rect) => rect.y));

    const right = Math.max(
      ...rectsInViewport.map((rect) => rect.x + rect.width)
    );
    const width = right - x;
    const bottom = Math.max(
      ...rectsInViewport.map((rect) => rect.y + rect.height)
    );
    const height = bottom - y;

    const centerX = x + width / 2;

    let top = y + height;

    if (top > window.innerHeight) {
      top = y - menuRect.height;
    }

    this._updatePosition(centerX, top);
  }

  _onCommentaryHighlightClick(): void {
    this._onHighlightClick(HighlightColor.Yellow);
  }

  _onHighlightClick(styleClass: string): void {
    if (!this._context) {
      return;
    }

    if (!this._checkTextLength()) {
      return;
    }

    let action: HighlightAction;

    if (!this._context.annotation) {
      action = HighlightAction.Add;
    } else if (this._context.annotation.styleClass !== styleClass) {
      action = HighlightAction.Change;
    } else {
      this.hide();
      return;
    }

    this._eventBusService.fire('ContextMenuComponent:highlight', {
      action,
      context: this._context,
      styleClass,
    });

    this.hide();
  }

  _onRemoveClick(): void {
    if (!this._context) {
      return;
    }

    this._eventBusService.fire('ContextMenuComponent:highlight', {
      action: HighlightAction.Remove,
      context: this._context,
    });

    this.hide();
  }

  _onMenuClick(menu: ContextMenuItem): void {
    if (!this._context) {
      return;
    }

    if (!this._checkTextLength()) {
      return;
    }

    this._eventBusService.fire('ContextMenuComponent:menuClick', {
      menu,
      context: this._context,
    });

    this.hide();
  }

  private _checkTextLength(): boolean {
    if (
      !this._context ||
      this._context.selectedRange.toString().length <=
        Config.selectionLimitLength
    ) {
      return true;
    }

    this._toastService.openWarning('문구의 선택은 500자 이내로 제한합니다.');

    return false;
  }
}
