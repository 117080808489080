import { Injectable, Optional } from '@angular/core';
import { EventBusService } from './event-bus.service';
import { BehaviorSubject } from 'rxjs';
import { PDFDrawingService } from './pdf-drawing.service';

const RIGHT_SIDEBAR_WIDTH = 340;

@Injectable()
export class UIStateService {
  private _fixedLayoutBook = false;
  private _verticalLayoutBook = false;
  private _multiColumnSetting = false;

  public isCommentaryEditor = false;
  public canEditCommentary = false;

  private _isDrawingMode$ = new BehaviorSubject(false);
  public isDrawingMode$ = this._isDrawingMode$.asObservable();
  public canUseDrawingMode = false;

  constructor(
    private _eventBus: EventBusService,
    @Optional() private _pdfDrawingService?: PDFDrawingService
  ) {
    this._eventBus.on('ContentsComponent:bookLoad').subscribe(({ book }) => {
      this._fixedLayoutBook = book.meta.layout === 'fixed';
      this._verticalLayoutBook = book.items.every(
        (item) =>
          item.fixedWidth &&
          item.fixedHeight &&
          item.fixedHeight > item.fixedWidth
      );
    });

    this._eventBus
      .on('ContentsComponent:settingsChanged')
      .subscribe(({ settings }) => {
        this._multiColumnSetting = settings.multiColumn.value;
      });
  }

  canUseMemoSidebar(): boolean {
    if (this._fixedLayoutBook) {
      return this._multiColumnSetting || !this._verticalLayoutBook
        ? false
        : window.innerWidth > window.innerHeight && window.innerWidth > 1024;
    }

    const epubPageWidth =
      window.innerWidth > 900
        ? 900 * (this._multiColumnSetting ? 2 : 1)
        : window.innerWidth;

    return (window.innerWidth - epubPageWidth) / 2 > RIGHT_SIDEBAR_WIDTH;
  }

  set isDrawingMode(value: boolean) {
    if (this._pdfDrawingService) {
      this._pdfDrawingService.isDrawingMode = value;
    }

    this._isDrawingMode$.next(value);
  }

  get isDrawingMode(): boolean {
    return this._isDrawingMode$.getValue();
  }
}
