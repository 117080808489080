import { Injectable, Optional } from '@angular/core';
import { ViewerPDFOverlayViewProviderService } from '@bukio/viewer';

import { PDFDrawingService } from './pdf-drawing.service';

@Injectable({
  providedIn: 'root',
})
export class PDFOverlayViewProviderService extends ViewerPDFOverlayViewProviderService {
  constructor(@Optional() private pdfDrawingService?: PDFDrawingService) {
    super();
  }

  override getOverlayView(iid: string): Element | null {
    return this.pdfDrawingService?.getOverlayView(iid) ?? null;
  }

  override willEndDisplayingOverlayView(element: Element): void {
    return this.pdfDrawingService?.willEndDisplayingOverlayView(element);
  }
}
