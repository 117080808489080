<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  <div class="header">
    <b>'</b>
    <div class="nickname">{{ _data.nickname }}</div>
    <b>'</b>
    <div class="title">&nbsp;님에게 쪽지 보내기</div>
  </div>
</sui-dialog-header>
<lib-note-send
  [targetUserId]="_data.userId"
  (noteSent)="_onNoteSent()"
></lib-note-send>
