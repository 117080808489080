import { Component, OnInit } from '@angular/core';
import {
  NavigationError,
  PRIMARY_OUTLET,
  Route,
  Router,
  UrlMatchResult,
  defaultUrlMatcher,
} from '@angular/router';
import { filter, fromEvent } from 'rxjs';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { lt } from 'semver';

import { DialogRef, DialogService, TypedDialog } from 'shared/ui';
import { OthersBookshelfOpenerService, SocketService } from 'shared/services';

import { environment } from '../environments/environment';

import { NativeEnvironmentService } from './services/native-environment.service';

import { OthersBookshelfDialogComponent } from './dialogs/others-bookshelf-dialog/others-bookshelf-dialog.component';
import { OfflineDialogComponent } from './dialogs/offline-dialog/offline-dialog.component';
import { UpdateNotificationDialogComponent } from './dialogs/update-notification-dialog/update-notification-dialog.component';

const WEB_ROUTES: Route[] = [
  {
    path: 'bookshelf/:userId',
  },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private _offlineDialogRef?: DialogRef<TypedDialog<void, void>, void>;

  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService,
    private _socketService: SocketService,
    angulartics: Angulartics2GoogleGlobalSiteTag,
    private _nativeEnvironmentService: NativeEnvironmentService
  ) {
    environment.production && angulartics.startTracking();
  }

  ngOnInit(): void {
    this._socketService.createSocket(environment.wsServerOrigin);

    this._othersBookshelfOpenerService.isApp = true;

    this._router.events
      .pipe(
        // tap((event) => {
        //   console.warn(event);
        // }),
        filter(
          (event) =>
            event instanceof NavigationError && event.error?.code === 4002
        )
      )
      .subscribe((event) => {
        this._onNavigationError(event as NavigationError);
      });

    fromEvent(window, 'offline').subscribe(() => {
      if (this._offlineDialogRef) {
        return;
      }

      this._offlineDialogRef = this._dialogService.open(OfflineDialogComponent);

      this._offlineDialogRef.afterClosed().subscribe(() => {
        this._offlineDialogRef = undefined;
      });
    });

    fromEvent(window, 'online').subscribe(() => {
      this._offlineDialogRef?.close();
    });

    if (
      this._nativeEnvironmentService.version &&
      lt(
        this._nativeEnvironmentService.version,
        environment.minRequiredNativeAppVersion
      )
    ) {
      this.openAppUpdateNotiDialog();
    }
  }

  openAppUpdateNotiDialog(): void {
    this._dialogService.open(UpdateNotificationDialogComponent);
  }

  private _onNavigationError(event: NavigationError): void {
    const segmentGroup = this._router.parseUrl(event.url).root.children[
      PRIMARY_OUTLET
    ];

    for (const route of WEB_ROUTES) {
      const matchResult = defaultUrlMatcher(
        segmentGroup.segments,
        segmentGroup,
        route
      );

      if (matchResult) {
        this._handleWebRouteMatchResult(matchResult);
        return;
      }
    }

    window.open(environment.serviceOrigin + event.url, '_blank');
    return;
  }

  private _handleWebRouteMatchResult(matchResult: UrlMatchResult): void {
    // 타인의 서재
    if (
      matchResult.consumed.length >= 2 &&
      matchResult.consumed[0].path === 'bookshelf' &&
      matchResult.posParams?.['userId'] != null
    ) {
      this._openUserBookshelfDialog(matchResult.posParams['userId'].path);
      return;
    }
  }

  private _openUserBookshelfDialog(userId: string): void {
    this._dialogService.open(OthersBookshelfDialogComponent, {
      data: { userId },
    });
  }
}
