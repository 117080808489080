export interface SimpleChannel {
  id: string;
  name: string;
  description: string;
  logo_image_url: string;
  custom_domains: string[];
}

export interface Channel extends SimpleChannel {
  type: string;
  logo_image_url: string;
  contact_info: {
    phone?: string;
    email?: string;
    goods_refund_policy?: string;
  };
  created_at: string;
  primary_font: string;
  primary_theme: string;
  show_hero_banner: boolean;
  show_header_title: boolean;
  show_volume_list?: boolean;
  dayofweek?: {
    sun: number;
    mon: number;
    tue: number;
    wed: number;
    thu: number;
    fri: number;
    sat: number;
    finish: number;
  };
  home_url: string;
  header_title: string;
}
