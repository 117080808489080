<div class="header">
  <div>
    총 <span class="number">{{ _total }}</span
    >개의 전체 메모
  </div>
</div>
<div
  class="body"
  #scrollContainer
  suiListIntersectionObserver
  [observerOptions]="{ rootMargin: '0px 0px -160px 0px' }"
>
  <div class="memo" *ngFor="let memo of _memos" (click)="_onMemoClick(memo)">
    <div class="text">
      {{ memo.text }}
    </div>
    <viewer-reading-mode-memo
      class="memo-comp"
      [class.is-active]="memo.id === _activeMemo?.id"
      [memo]="memo"
      [text]="memo.text"
      (memoDeleted)="_refresh()"
      (memoBlinded)="_refresh()"
      (memoUserBlocked)="_refresh()"
      [isUnread]="!memo.is_read"
      [isAuthorOnline]="_onlineUserMap[memo.author.id]"
      suiListItemIntersectionObserver
      (intersected)="_onMemoRead(memo)"
      [stopAfterIntersection]="true"
      (memoUpdated)="_onMemoUpdated(memo, $event)"
    ></viewer-reading-mode-memo>
    <div class="divider"></div>
  </div>
  <div class="paginator" *ngIf="_total > 0">
    <sui-paginator
      [totalItemCount]="_total"
      [page]="_currentPage - 1"
      [itemCountPerPage]="_itemCountPerPage"
      (pageChange)="_onPageChanged($event + 1)"
    ></sui-paginator>
  </div>
</div>
<div class="footer">
  <div class="count">
    총 <b>{{ _unreadMemoCount }}</b
    >개의 새로운 메모
  </div>
  <button
    sui-solid-button
    varSize="medium48"
    (click)="_readAllButtonClick()"
    [disabled]="_unreadMemoCount === 0"
  >
    모두 읽음
  </button>
</div>
