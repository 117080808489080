<section>
  <div class="header">
    <div class="inner-left">
      <sui-icon
        name="화살표_왼쪽"
        [routerLink]="['/bookshelf/groups']"
      ></sui-icon>
      <h2>
        내보내기된 그룹
        <span class="total">({{ _totalItemCount }})</span>
      </h2>
    </div>
    <div class="inner-right">
      <p>
        * 불러오기는 1회만 가능하며 불러오기 완료 시, 아래 목록에서 삭제됩니다.
      </p>
      <div class="sidebar">
        <span class="select-info"
          >총 <b>{{ _checkedList.length }}</b
          >개 선택</span
        >
        <div class="delete-button" (click)="_onClickDeleteButton()">
          <sui-icon name="휴지통"></sui-icon>
          <span>선택 그룹 삭제하기</span>
        </div>
      </div>
    </div>
  </div>

  <lib-group-item-box
    *ngFor="let myGroup of _readingGroup"
    [readingGroup]="myGroup"
    [ngClass]="{ checked: _checkedList.includes(myGroup.id) }"
    (bookClick)="_routeDetailBookPage(myGroup.content.id)"
  >
    <sui-checkbox lib-group-item-box-checkbox
      ><input type="checkbox" (change)="_onClickCheckbot(myGroup.id)"
    /></sui-checkbox>
    <button
      sui-box-button
      varSize="medium48:medium40"
      (click)="_onClickCallMyHistory(myGroup.id, myGroup.content.id)"
    >
      내 기록 불러오기
    </button>
  </lib-group-item-box>

  <div class="paginator" *ngIf="_totalItemCount && _totalItemCount > 0">
    <sui-paginator
      [itemCountPerPage]="_itemCountPerPage"
      [totalItemCount]="_totalItemCount"
      [page]="_currentPage"
      (pageChange)="_onPageChange($event)"
    ></sui-paginator>
  </div>
  <div class="loader" *ngIf="_isLoading">
    <sui-loader></sui-loader>
  </div>
  <div class="empty" *ngIf="_readingGroup.length === 0">
    <p>내보내기된 그룹이 없습니다.</p>
  </div>
</section>
<div class="sidebar-mobile" *ngIf="_checkedList.length > 0">
  <span
    >총 <b>{{ _checkedList.length }}</b
    >개 선택</span
  >
  <button sui-solid-button (click)="_onClickDeleteButton()">
    선택 그룹 삭제하기
  </button>
</div>
