import { Component, OnInit } from '@angular/core';
import { FollowableReader } from 'shared/models';
import {
  APIError,
  OthersBookshelfOpenerService,
  UsersAPIService,
} from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-following',
  templateUrl: './following.component.html',
  styleUrl: './following.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class FollowingComponent
  extends TypedDialog<{ user_id: string } | undefined, { updated: boolean }>
  implements OnInit
{
  public _followersList?: FollowableReader[];
  private _updated = false;
  public _isLoading: boolean = true;
  private _total: number = 0;

  constructor(
    private _usersAPIService: UsersAPIService,
    private _toastService: ToastService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService
  ) {
    super();
  }

  ngOnInit(): void {
    this._getFollowingList();
  }

  public _closeDialog(): void {
    this._dialogRef.close({ updated: this._updated });
  }

  public _getFollowingList(): void {
    if (!this._data) {
      this._usersAPIService.getFollowings(0, 30).subscribe((result) => {
        this._isLoading = false;
        this._total = result.total;

        this._followersList = result.items.map((item) => ({
          ...item,
          is_follow: 1,
        }));
      });
    } else {
      this._usersAPIService
        .getOthersFollowings(0, 30, this._data.user_id)
        .subscribe((result) => {
          this._isLoading = false;
          this._total = result.total;
          this._followersList = result.items;
        });
    }
  }

  public _onClickUnFollowButton(reader: FollowableReader): void {
    reader.is_follow = 0;

    this._usersAPIService.unfollow(reader.id).subscribe({
      next: () => {
        this._updated = true;
      },
      error: (error: APIError) => {
        reader.is_follow = 1;
        this._toastService.openWarning(error.message);
      },
    });
  }

  public _onClickFollowButton(reader: FollowableReader): void {
    reader.is_follow = 1;

    this._usersAPIService.follow(reader.id).subscribe({
      next: () => {
        this._updated = true;
      },
      error: (error: APIError) => {
        reader.is_follow = 0;
        this._toastService.openWarning(error.message);
      },
    });
  }

  public _navigateOtherUsers(user_id: string): void {
    this._othersBookshelfOpenerService.open(user_id);
  }

  public _onFollowerListScrollEnd(): void {
    if (this._isLoading) {
      return;
    }

    if (!this._followersList || this._followersList!.length === this._total) {
      return;
    }

    this._getFollowingList();
  }
}
