import { Injectable } from '@angular/core';

import { SharedAnalyticsService } from 'shared/services';
import { EventBusService } from './event-bus.service';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private _unsubscriber = new Subject<void>();
  private _categorySuffix?: string;

  constructor(
    private _appAnalyticsService: SharedAnalyticsService,
    _eventBus: EventBusService
  ) {
    _eventBus
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ book }) => {
        this._categorySuffix = book.permission.referer
          ? 'from_link'
          : 'from_book';
      });
  }

  trackEvent(action: string, label?: string | undefined): void {
    this._appAnalyticsService.trackEvent(
      action,
      label,
      undefined,
      this._categorySuffix
    );
  }
}
