<div class="container">
  <div class="head">
    <lib-reading-group-summary [group]="group">
      <ng-container *ngIf="!_isGroupMember" ngProjectAs=".desktop">
        <button
          class="desktop"
          sui-solid-button
          varSize="medium40"
          [disabled]="group.status === _JoinStatus.kicked || _isJoining"
          (click)="_onJoinGroupButtonClick()"
        >
          가입하기
        </button>
      </ng-container>
      <ng-container *ngIf="_isGroupMember" ngProjectAs=".desktop">
        <button
          class="desktop"
          sui-solid-button
          varSize="medium40"
          (click)="_onEnterGroupButtonClick()"
        >
          함께읽기 시작하기
        </button>
        <button
          class="desktop"
          sui-box-button
          varSize="medium40"
          (click)="_onEditGroupButtonClick()"
          *ngIf="group.status === _JoinStatus.owned"
        >
          정보 수정하기
        </button>
        <button
          sui-text-icon-button
          class="exit-button"
          (click)="_onExitGroupButtonClick()"
        >
          그룹에서 나가기 <sui-icon name="오른쪽 이동"></sui-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="_isGroupMember" ngProjectAs=".phone">
        <button
          sui-icon-button
          class="mobile more-button"
          [cdkMenuTriggerFor]="exitMenu"
          [cdkMenuPosition]="_moreMenuPosition"
        >
          <sui-icon name="더보기"></sui-icon>
        </button>
      </ng-container>
    </lib-reading-group-summary>
  </div>
  <div class="body">
    <cdk-accordion>
      <cdk-accordion-item
        *ngIf="_canInviteMember"
        #accordionItem1="cdkAccordionItem"
        class="accordion-item invitation"
        [class.is-opened]="_openedAccordion === _Tab.invitation"
        [expanded]="_canInviteMember"
        (opened)="_onOpenAccordion(_Tab.invitation)"
      >
        <div class="accordion-item-header" (click)="accordionItem1.toggle()">
          그룹 초대 링크 <sui-icon name="펼침"></sui-icon>
        </div>
        <div class="accordion-item-body">
          <div class="note">
            '복사하기'를 누르시면 아래 초대 메시지와 책을 구매할 수 있는 링크가
            함께 복사됩니다. 이 책을 함께 읽고 싶은 친구에게 공유해 보세요.
          </div>
          <div class="copy-text" #copyText [innerHTML]="_invitationText"></div>
          <button sui-mini-button (click)="_onCopyInvitationTextButtonClick()">
            복사하기<sui-icon name="복사"></sui-icon>
          </button>
        </div>
      </cdk-accordion-item>
      <cdk-accordion-item
        #accordionItem2="cdkAccordionItem"
        class="accordion-item description"
        [class.is-opened]="_openedAccordion === _Tab.description"
        [expanded]="!_canInviteMember"
        (opened)="_onOpenAccordion(_Tab.description)"
      >
        <div class="accordion-item-header" (click)="accordionItem2.toggle()">
          그룹 소개 <sui-icon name="펼침"></sui-icon>
        </div>
        <div class="accordion-item-body">
          <div class="text">
            <sui-icon class="quotation-mark left" name="따옴표"></sui-icon>
            {{ group.description }}
            <sui-icon class="quotation-mark right" name="따옴표"></sui-icon>
          </div>
        </div>
      </cdk-accordion-item>
      <cdk-accordion-item
        #accordionItem3="cdkAccordionItem"
        class="accordion-item full-height joined-members"
        [class.is-opened]="_openedAccordion === _Tab.joinedMembers"
        (opened)="_onOpenAccordion(_Tab.joinedMembers)"
      >
        <div class="accordion-item-header" (click)="accordionItem3.toggle()">
          그룹원 <sui-icon name="펼침"></sui-icon>
        </div>
        <div class="accordion-item-body">
          <div class="member-list-item" *ngIf="_canInviteMember">
            <button
              class="invitation-button"
              (click)="_onInviteGroupMemberButtonClick()"
            >
              <div class="circle">
                <sui-icon name="플러스"></sui-icon>
              </div>
              <span>그룹원 초대</span>
            </button>
          </div>
          <div
            class="member-list-item joined"
            *ngFor="let member of _joinedMembers; index as i"
          >
            <sui-reading-group-member
              [member]="member"
              (click)="_onReadingGroupMemberClick(member)"
            ></sui-reading-group-member>
            <sui-rounded-tag *ngIf="member.is_owner">그룹장</sui-rounded-tag>
            <button
              sui-text-icon-button
              *ngIf="!member.is_me && group.status === _JoinStatus.owned"
              (click)="_onKickUserButtonClick(i)"
            >
              내보내기
              <sui-icon name="내보내기"></sui-icon>
            </button>
          </div>
        </div>
      </cdk-accordion-item>

      <cdk-accordion-item
        *ngIf="_canKickMember"
        #accordionItem4="cdkAccordionItem"
        class="accordion-item full-height kicked-members"
        [class.is-opened]="_openedAccordion === _Tab.kickedMembers"
        (opened)="_onOpenAccordion(_Tab.kickedMembers)"
      >
        <div class="accordion-item-header" (click)="accordionItem4.toggle()">
          내보낸 그룹원 <sui-icon name="펼침"></sui-icon>
        </div>
        <div class="accordion-item-body">
          <div class="note" *ngIf="_kickedMembers && _kickedMembers.length > 0">
            그룹장이 내보낸 그룹원은 재가입이 불가능합니다. 재가입을 허용하려면
            내보내기를 해제해 주세요.
          </div>
          <div class="list">
            <div
              class="member-list-item kicked"
              *ngFor="let member of _kickedMembers; index as i"
            >
              <sui-reading-group-member
                [member]="member"
              ></sui-reading-group-member>
              <button
                class="unkick-button"
                (click)="_onUnkickUserButtonClick(i)"
              >
                <sui-icon type="solid" name="내보내기 취소"></sui-icon> 해제하기
              </button>
            </div>
            <div class="placeholder" *ngIf="_kickedMembers?.length === 0">
              내보내기된 그룹원이 없습니다.
            </div>
          </div>
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
<div class="mobile-buttons">
  <ng-container *ngIf="!_isGroupMember">
    <button
      sui-solid-button
      varSize="large"
      [disabled]="group.status === _JoinStatus.kicked || _isJoining"
      (click)="_onJoinGroupButtonClick()"
    >
      가입하기
    </button>
  </ng-container>
  <ng-container *ngIf="_isGroupMember">
    <button
      sui-box-button
      varSize="medium54"
      (click)="_onEditGroupButtonClick()"
      *ngIf="group.status === _JoinStatus.owned"
    >
      정보 수정하기
    </button>
    <button
      sui-solid-button
      [varSize]="group.status === _JoinStatus.owned ? 'medium54' : 'large'"
      (click)="_onEnterGroupButtonClick()"
    >
      함께읽기 시작하기
    </button>
  </ng-container>
</div>

<ng-template #exitMenu>
  <sui-menu size="small" cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onExitGroupButtonClick()">
      그룹에서 나가기<sui-icon name="오른쪽 이동"></sui-icon>
    </button>
  </sui-menu>
</ng-template>
