import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

// https://www.bennadel.com/blog/3954-intersectionobserver-api-performance-many-vs-shared-in-angular-11-0-5.htm

@Directive({
  selector: '[suiListIntersectionObserver]',
})
export class ListIntersectionObserverDirective implements OnDestroy, OnInit {
  private mapping: Map<Element, (isIntersecting: boolean) => void>;
  private observer!: IntersectionObserver;

  @Input() observerOptions?: IntersectionObserverInit;

  constructor() {
    this.mapping = new Map();
  }

  ngOnInit(): void {
    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        for (const entry of entries) {
          const callback = this.mapping.get(entry.target);

          callback && callback(entry.isIntersecting);
        }
      },
      this.observerOptions
    );
  }

  public ngOnDestroy(): void {
    this.mapping.clear();
    this.observer.disconnect();
  }

  public add(
    element: HTMLElement,
    callback: (isIntersecting: boolean) => void
  ): void {
    this.mapping.set(element, callback);
    this.observer.observe(element);
  }

  public remove(element: HTMLElement): void {
    this.mapping.delete(element);
    this.observer.unobserve(element);
  }
}

@Directive({
  selector: '[suiListItemIntersectionObserver]',
  exportAs: 'intersectionObserver',
})
export class ListItemIntersectionObserverDirective
  implements OnDestroy, OnInit
{
  @Input() stopAfterIntersection = false;
  @Output() intersected = new EventEmitter<ElementRef>();

  public isIntersected = false;

  constructor(
    private parent: ListIntersectionObserverDirective,
    private elementRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.parent.add(this.elementRef.nativeElement, (isIntersecting) => {
      this.isIntersected = isIntersecting;

      if (isIntersecting) {
        this.stopAfterIntersection &&
          this.parent.remove(this.elementRef.nativeElement);
        this.intersected.emit(this.elementRef);
      }
    });
  }

  public ngOnDestroy(): void {
    this.parent.remove(this.elementRef.nativeElement);
  }
}
