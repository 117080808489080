<div class="description">
  백업 및 복원은 많은 저장공간을 필요로 합니다.<br />
  저장 공간을 여유있게 확보한 후 작업을 진행해 주세요.
</div>

<div class="sections">
  <div class="section" (click)="_onBackupClick()">
    백업할 필기 기록
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.0078 6.6875L16.5417 11.9908L16.5326 11.9995L16.5339 12.0007L11 17.304L9.67188 16.0312L13.8867 11.992L9.67968 7.96029L11.0078 6.6875Z"
        fill="#282828"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4636 12.0064L10.9297 6.70312L9.60156 7.97592L13.8086 12.0077L9.59375 16.0469L10.9219 17.3197L16.4558 12.0164L16.4545 12.0151L16.4636 12.0064Z"
        fill="white"
      />
    </svg>
  </div>
  <div class="section" (click)="_onRestoreClick()">
    복원할 필기 기록
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.0078 6.6875L16.5417 11.9908L16.5326 11.9995L16.5339 12.0007L11 17.304L9.67188 16.0312L13.8867 11.992L9.67968 7.96029L11.0078 6.6875Z"
        fill="#282828"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4636 12.0064L10.9297 6.70312L9.60156 7.97592L13.8086 12.0077L9.59375 16.0469L10.9219 17.3197L16.4558 12.0164L16.4545 12.0151L16.4636 12.0064Z"
        fill="white"
      />
    </svg>
  </div>
</div>
