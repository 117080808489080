<sui-dialog-header (closeButtonClick)="_onCloseButtonClick()">
  알림함
</sui-dialog-header>
<main>
  <div class="wrapper" #listContainer>
    <div class="empty" *ngIf="_notificationList?.length === 0">
      <p class="alert">알림이 없습니다.</p>
    </div>
    <div class="today-noti">
      <ul class="noti-history">
        <li *ngFor="let noti of _notificationList; index as i">
          <div class="info">
            <div class="inner" *ngIf="noti.type === 'message'">
              <p>{{ noti.message }}</p>
              <button
                *ngIf="noti.button && noti.url"
                class="action-button"
                (click)="_routeMesssageTypeUrl(noti.url)"
              >
                {{ noti.button }}
              </button>
              <span class="date">
                {{ noti.created_at | date : 'yyyy.MM.dd. HH:mm' }}</span
              >
            </div>
            <div class="inner" *ngIf="noti.type === 'group_invited'">
              <p>
                <a class="nickname" (click)="_routeBookshelf(noti.user_id!)">{{
                  noti.nickname
                }}</a
                >님이 {{ noti.group_name }}그룹에 나를 초대하였습니다.
              </p>
              <button
                class="action-button"
                (click)="_routeBookDetail(noti.bid!)"
              >
                그룹 책 보기
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'user_follow'">
              <p>
                <a class="nickname" (click)="_routeBookshelf(noti.user_id!)">{{
                  noti.nickname
                }}</a
                >님이 나를 팔로우하였습니다.
              </p>
              <button
                class="action-button follow"
                *ngIf="!noti.is_follow"
                (click)="_follow(noti.user_id!)"
              >
                맞팔로우
              </button>
              <button
                class="action-button unfollow"
                *ngIf="noti.is_follow"
                (click)="_unfollow(noti.user_id!)"
              >
                언팔로우
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'memo_copy'">
              <p>
                <a class="nickname" (click)="_routeBookshelf(noti.user_id!)">{{
                  noti.nickname
                }}</a
                >님이 내 메모를 복사하였습니다.
              </p>
              <button class="action-button" (click)="_routeMemo(noti.url!)">
                내 메모 확인
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'memo_like'">
              <p>
                <a class="nickname" (click)="_routeBookshelf(noti.user_id!)">{{
                  noti.nickname
                }}</a
                >님이 내 메모를 추천하였습니다.
              </p>
              <button class="action-button" (click)="_routeMemo(noti.url!)">
                내 메모 확인
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'memo_hide'">
              <p>
                커뮤니티 가이드 미준수로 인해 {{ noti.nickname }}님의 게시물이
                숨김 처리되었습니다.
              </p>
              <button class="action-button" (click)="_routeMemo(noti.url!)">
                내 메모 확인
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'memo_show'">
              <p>{{ noti.nickname }}님의 게시물이 숨김 해제 처리되었습니다.</p>
              <button class="action-button" (click)="_routeMemo(noti.url!)">
                내 메모 확인
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'group_kicked'">
              <p>
                {{ noti.group_name }} 그룹에서 그룹장이 나를 내보내기
                하였습니다.
              </p>
              <button class="action-button" (click)="_routeMyGroup()">
                퇴장된 그룹 목록
              </button>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div
              class="inner"
              *ngIf="noti.type === 'group_reader_disabled' && noti.until"
            >
              <p>{{ _getGroupReaderDisabledMessage(noti.until) }}</p>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
            <div class="inner" *ngIf="noti.type === 'group_reader_enabled'">
              <p>
                함께읽기 모드 이용 정지가 해제되었습니다. 다시 함께읽기 모드를
                이용할 수 있습니다.
              </p>
              <span class="date">{{
                noti.created_at | date : 'yyyy.MM.dd. HH:mm'
              }}</span>
            </div>
          </div>
          <button
            class="more-button"
            sui-icon-button
            [cdkMenuTriggerFor]="moreMenu"
            [cdkMenuPosition]="_moreMenuPosition"
            (click)="_changeIndex(i)"
          >
            <sui-icon name="더보기"></sui-icon>
          </button>
        </li>
      </ul>
      <sui-paginator
        *ngIf="_notificationList && _notificationList.length !== 0"
        [page]="_currentPage"
        [totalItemCount]="_total"
        [itemCountPerPage]="_itemCountPerPage"
        (pageChange)="_onPageChange($event)"
      ></sui-paginator>
    </div>

    <div class="loader-container" *ngIf="_isLoading">
      <sui-loader></sui-loader>
    </div>
  </div>
</main>

<ng-template #moreMenu>
  <sui-menu cdkMenu>
    <button sui-menu-item cdkMenuItem (click)="_onDeleteNoti()">
      삭제하기
    </button>
  </sui-menu>
</ng-template>
