export interface Annotation {
  id: string;
  bid: string;
  iid: string;
  url: string;
  text: string;
  position: number;
  created_at: string;
  is_mine: number; // boolean
}

export interface Highlight extends Annotation {
  style: string;
}

export interface Memo extends Annotation {
  content: string;
  like_count: number;
  copy_count: number;
  copied_at: string | null;
  updated_at: string | null;
  source: string | null;
  is_blinded: number; // boolean
  is_liked: number; // boolean
  pinned_at: string | null;
  is_read: number; // boolean
  author: {
    id: string;
    nickname: string;
    avatar_image_url: string;
  };
}
