import { Component } from '@angular/core';
import { APIError, SurveyAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrl: './survey-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class SurveyDialogComponent extends TypedDialog<
  { submissionId: number },
  { closedPermanently?: boolean; surveyError?: boolean }
> {
  public _surveyStep: 'consent' | 'recommentationLevel' | 'opinion' = 'consent';
  public _recommendationLevel?: 0 | 1 | 2;
  public _opinionContent = '';

  constructor(
    private _surveyAPIService: SurveyAPIService,
    private _toastService: ToastService
  ) {
    super();
  }

  public _onCloseButtonClick(): void {
    // 두번째 step까지 진행후 다이얼로그를 닫아도 사용자에게 영구적으로 설문조사를 묻지 않도록 조건으로 나눔
    if (this._surveyStep === 'opinion') {
      this._dialogRef.close({ closedPermanently: true });
    } else {
      this._dialogRef.close({ closedPermanently: false });
    }
  }

  public _moveNextStep(
    step: 'consent' | 'recommentationLevel' | 'opinion'
  ): void {
    this._surveyStep = step;

    // 두 번째 step인 recommendation 여부를 묻는 질문에 다음을 클릭하는 경우 서버에 설문조사 결과 제출
    if (step === 'opinion') {
      this._submitUserSurvey('recommentationLevel');
    }
  }

  public _onChangeRecommendationLevel(level: 0 | 1 | 2): void {
    this._recommendationLevel = level;
  }

  public _submitUserSurvey(
    step: 'consent' | 'recommentationLevel' | 'opinion'
  ): void {
    this._surveyAPIService
      .submit(
        this._data.submissionId,
        this._opinionContent,
        this._recommendationLevel
      )
      .subscribe({
        next: () => {
          if (step === 'consent') {
            this._dialogRef.close({ closedPermanently: true });
          }

          if (step === 'opinion') {
            this._toastService.open('의견 보내기가 완료되었습니다.');
            this._dialogRef.close({ closedPermanently: true });
          }
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }
}
