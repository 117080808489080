<section>
  <nav class="navbar">
    <ul>
      <li routerLinkActive="selected" [routerLink]="['/bookshelf/all/books']">
        전자책 ({{ _ebookTotal }})
      </li>
      <li
        routerLinkActive="selected"
        [routerLink]="['/bookshelf/all/libraries']"
      >
        구독 라이브러리 ({{ _libraryTotal }})
      </li>
    </ul>
  </nav>
  <router-outlet></router-outlet>
</section>
