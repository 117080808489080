import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { FollowableReader } from 'shared/models';
import {
  APIError,
  OthersBookshelfOpenerService,
  UsersAPIService,
} from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-follower',
  templateUrl: './follower.component.html',
  styleUrl: './follower.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class FollowerComponent
  extends TypedDialog<{ user_id: string } | undefined, { updated: boolean }>
  implements OnInit, OnDestroy
{
  private _followerSubject = new Subject<void>();
  public _followersList?: FollowableReader[];
  private _updated = false;

  public _isLoading: boolean = true;
  private _total: number = 0;

  constructor(
    private _usersAPIService: UsersAPIService,
    private _toastService: ToastService,
    private _othersBookshelfOpenerService: OthersBookshelfOpenerService
  ) {
    super();
  }

  ngOnInit(): void {
    this._followerSubject
      .pipe(
        switchMap(() => {
          this._isLoading = true;
          if (!this._data) {
            return this._usersAPIService.getFollowers(
              this._followersList?.length === undefined
                ? 0
                : this._followersList.length,
              30
            );
          } else {
            return this._usersAPIService.getOtherFollowers(
              this._followersList?.length,
              30,
              this._data.user_id
            );
          }
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        this._total = result.total;
        this._followersList = result.items;

        // if (result.items.length < result.total) {
        //   this._followerSubject.complete();
        // }
      });
    this._followerSubject.next();
  }

  ngOnDestroy(): void {
    this._followerSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close({ updated: this._updated });
  }

  public _onClickFollowButton(reader: FollowableReader): void {
    reader.is_follow = 1;

    this._usersAPIService.follow(reader.id).subscribe({
      next: () => {
        this._updated = true;
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
        reader.is_follow = 0;
      },
    });
  }

  public _onClickUnFollowButton(reader: FollowableReader): void {
    reader.is_follow = 0;

    this._usersAPIService.unfollow(reader.id).subscribe({
      next: () => {
        this._updated = true;
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
        reader.is_follow = 1;
      },
    });
  }

  public _navigateOtherUsers(user_id: string): void {
    this._othersBookshelfOpenerService.open(user_id);
  }

  public _onFollowerListScrollEnd(): void {
    if (this._isLoading) {
      return;
    }

    if (!this._followersList || this._followersList!.length === this._total) {
      return;
    }

    this._followerSubject.next();
  }
}
