import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map } from 'rxjs';
import { Bookshelf } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';
import { DialogService, ToastService } from 'shared/ui';
import { AddBookshelfComponent } from '../../components/add-bookshelf/add-bookshelf.component';

// 내 서재, 타인의 서재

@Component({
  selector: 'lib-my-bookshelf-main-page',
  templateUrl: './my-bookshelf-main-page.component.html',
  styleUrl: './my-bookshelf-main-page.component.scss',
})
export class MyBookshelfMainPageComponent implements OnInit {
  public _bookshelves?: Bookshelf[];

  public _currentPage = 1;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;
  public _isApp: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _toastService: ToastService,
    private _bookshelfAPIService: BookshelfAPIService
  ) {}

  ngOnInit(): void {
    this._isApp = this._activatedRoute.snapshot.data['isApp'];

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }

        this._getBookshelf(page - 1);
      });
  }

  private _getBookshelf(page: number): void {
    this._isLoading = true;

    this._bookshelfAPIService
      .getBookshelvesList(page * this._itemCountPerPage, this._itemCountPerPage)
      .pipe(finalize(() => (this._isLoading = false)))
      .subscribe((response) => {
        window.scrollTo(0, 0);

        this._totalItemCount = response.total;
        this._currentPage = page;
        this._bookshelves = response.items;
      });
  }

  public _onClickBookshelfAddButton(): void {
    this._dialogService
      .open(AddBookshelfComponent)
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.canceled === 'success') {
          this._onPageChange(this._currentPage);
          this._toastService.open('책장 추가가 완료됐습니다.');
          if (this._currentPage === 0) {
            this._getBookshelf(0);
          } else {
            this._onPageChange(0);
          }
        }
      });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }
}
