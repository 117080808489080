import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map, pairwise, startWith } from 'rxjs/operators';

import { PagingMode } from '@bukio/viewer';

import { EventBusService } from '../../services/event-bus.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

const TUTORIAL_LOCALSTORAGE_KEY = 'viewer-tutorial';

@Component({
  selector: 'viewer-guide-popup',
  templateUrl: './guide-popup.component.html',
  styleUrls: ['./guide-popup.component.scss'],
})
export class GuidePopupComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-active') private _isActive = false;

  public _tutorialStep = 1;
  public _showTutorial = false;
  public _showPageModeGuide = false;
  public _closeTutorialPermanently = false;

  private unsubscribeBroadcast = new Subject<void>();

  public readonly _isTouchDevice: boolean;

  private _currentBid?: string;
  private _isBookLoaded = true;
  private _isPagingModeChanged = true;

  private hideTimeout?: number;

  public _pagingMode?: 'page' | 'scroll';

  constructor(private eventBusService: EventBusService) {
    this._isTouchDevice = CrossBrowsing.touchDevice;
    this._showTutorial =
      localStorage.getItem(TUTORIAL_LOCALSTORAGE_KEY) === null;

    // if (this._showTutorial) {
    //   this.show();
    // }
  }

  ngOnInit(): void {
    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe((data) => {
        if (this._showTutorial) {
          if (data.book.permissionReferer) {
            this._showTutorial = false;
          } else {
            this.show();
          }
        }

        this._isBookLoaded = data.book.meta.bid !== this._currentBid;
        this._currentBid = data.book.meta.bid;
      });

    this.eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        map((data) => data.settings.pagingMode.value),
        startWith(''),
        pairwise()
      )
      .subscribe(([v1, v2]) => {
        if (v1 !== v2) {
          this._isPagingModeChanged = true;
          this._pagingMode = v2 === PagingMode.Page ? 'page' : 'scroll';
        }
      });

    this.eventBusService
      .on('ContentsComponent:itemLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        if (this._isBookLoaded || this._isPagingModeChanged) {
          if (!this._showTutorial) {
            this._showPageModeGuide = true;
            this.show();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  show(): void {
    this._isActive = true; // todo: opacity animation

    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }

    if (!this._showTutorial) {
      this.hide();
    }
  }

  hide(): void {
    this._isBookLoaded = false;
    this._isPagingModeChanged = false;

    this.hideTimeout = window.setTimeout(() => {
      this._isActive = false;
      this.hideTimeout = undefined;
    }, 2500);
  }

  _closeTutorialButtonClick(): void {
    if (this._closeTutorialPermanently) {
      localStorage.setItem(TUTORIAL_LOCALSTORAGE_KEY, '');
    }

    this._showTutorial = false;

    this._showPageModeGuide = this._isBookLoaded && this._isPagingModeChanged;

    if (this._showPageModeGuide) {
      this.hide();
    }
  }
}
