import { Address, isWikiBid as _isWikiBid } from '@bukio/viewer';
import { Cookie } from 'ng2-cookies';

import { User } from 'shared/models';

export function createSharingURL(address: Address, user?: User): string {
  address = address.clone();

  if (!address.query) {
    address.query = {};
  }

  if (user?.affiliation) {
    address.query.t = user.affiliation;
  }

  // FIXME
  return location.origin + address.toString();
}

export function logAffiliationToCookie(address: Address): void {
  const expireDate = new Date();
  expireDate.setDate(expireDate.getDate() + 10);
  if (address.query?.t) {
    Cookie.set('t', address.bid + '~' + address.query.t, expireDate, '/');
  }
  Cookie.set('u', address.toString(), expireDate, '/');
}

export function isSharedAddress(address: Address): boolean {
  return (
    !!address.range ||
    (typeof address.anchor === 'string' && /^1\.\d{7}$/.test(address.anchor))
  );
}

export function isWikiBid(bid: string): boolean {
  return bid === 'wikipedia' || _isWikiBid(bid);
}

export function isWikiCoverAddress(address: Address): boolean {
  return isWikiBid(address.bid) && (!address.iid || address.iid === 'cover');
}

export function addQueryToAddress(
  address: Address,
  key: string,
  value: string
): void {
  if (!address.query) {
    address.query = {};
  }

  address.query[key] = value;
}
