import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, map, switchMap } from 'rxjs';
import { WishList } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';

@Component({
  selector: 'lib-my-bookshelf-likes-page',
  templateUrl: './my-bookshelf-likes-page.component.html',
  styleUrl: './my-bookshelf-likes-page.component.scss',
})
export class MyBookshelfLikesPageComponent implements OnInit, OnDestroy {
  private _wishList = new Subject<{ page: number }>();

  public _booksWishList!: WishList;

  public _currentPage = 1;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookshelfAPIService: BookshelfAPIService
  ) {}

  ngOnInit(): void {
    this._wishList
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;

          return this._bookshelfAPIService.getWishList(
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        window.scrollTo(0, 0);

        this._totalItemCount = result.total;
        this._booksWishList = result;
      });

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }
        this._currentPage = page - 1;
        this._wishList.next({ page: this._currentPage });
      });
  }

  ngOnDestroy(): void {
    this._wishList.complete();
  }

  public _deleteWishList(bid: string): void {
    this._bookshelfAPIService.deleteFromWishList(bid).subscribe({
      next: () => {
        this._booksWishList.items = this._booksWishList.items.filter((book) => {
          return book.bid !== bid;
        });
      },
    });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }
}
