import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { EventBusService } from '../../services/event-bus.service';
import { ReadingGroupsService } from '../../services/reading-groups.service';
import { ReadingGroup, ReadingGroupMember } from 'shared/models';
import { AnnotationsV2Service } from '../../services/annotations-v2.service';
import { DialogService, ToastService, TypedDialog } from 'shared/ui';
import { UserDetailsDialogComponent } from '../user-details-dialog/user-details-dialog.component';
import { ReadingGroupMembersAPIService } from 'shared/services';
import scrollIntoView from 'scroll-into-view-if-needed';
import { OnlineReadingMemberService } from '../../services/online-reading-member.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'viewer-reading-group-members-dialog',
  templateUrl: './reading-group-members-dialog.component.html',
  styleUrl: './reading-group-members-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'mobile-full-height',
  },
})
export class ReadingGroupMembersDialogComponent
  extends TypedDialog<{ bid: string }, void>
  implements OnInit, OnDestroy
{
  @ViewChild('listElem', { static: true })
  private _listElem!: ElementRef<HTMLDivElement>;

  public _group!: ReadingGroup;

  public _members?: (ReadingGroupMember & { isOnline?: boolean })[];
  public _isSelectionMode = false;

  public _selectedMemberId?: string;
  public _selectedMemberName?: string;

  private _isDialogOpened = false;

  private _unsubscriber = new Subject<void>();

  constructor(
    private _eventBusService: EventBusService,
    private _readingGroupsService: ReadingGroupsService,
    private _annotationsService: AnnotationsV2Service,
    private _toastService: ToastService,
    private _dialogService: DialogService,
    private _readingGroupMembersAPIService: ReadingGroupMembersAPIService,
    private _onlineReadingGroupMemberService: OnlineReadingMemberService
  ) {
    super();
  }

  ngOnInit(): void {
    this._group = this._readingGroupsService.currentGroup!;
    this._selectedMemberId = this._annotationsService.getState().memberId;
    this._isSelectionMode = this._selectedMemberId !== undefined;

    this._loadMembers(this._group.id);

    if (this._selectedMemberId) {
      this._dialogRef.afterOpened().subscribe(() => {
        this._isDialogOpened = true;
        this._scrollToSelectedMember();
      });
    }

    this._onlineReadingGroupMemberService.onlineStatusChange$
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        const member = this._members?.find((m) => m.id === data.userId);

        if (!member) {
          return;
        }

        member.isOnline = data.isOnline;
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  onCloseButtonClick(): void {
    this._close();
  }

  _onSelectionModeToggleButtonClick(): void {
    this._isSelectionMode = !this._isSelectionMode;

    if (!this._isSelectionMode && this._selectedMemberId != null) {
      this._onDeselectMember();
    }
  }

  _onSelectMember(member: ReadingGroupMember): void {
    this._annotationsService.setState({ memberId: member.id });
    this._eventBusService.fire(
      'ReadingGroupMembersDialogComponent:memberSelected',
      { member }
    );
    this._toastService.open(
      '‘선택한 그룹원의 하이라이트 보기’로 모드가 변경되었습니다.'
    );

    this._close();
  }

  _onDeselectMember(): void {
    this._selectedMemberId = undefined;
    this._selectedMemberName = undefined;
    this._isSelectionMode = false;
    this._annotationsService.setState({ memberId: undefined });
    this._toastService.open(
      '‘그룹원들의 중복 하이라이트 보기’ 모드로 변경되었습니다.'
    );
    this._eventBusService.fire(
      'ReadingGroupMembersDialogComponent:memberDeselected'
    );
  }

  _onClickAvatar(member: ReadingGroupMember): void {
    this._dialogService
      .open(UserDetailsDialogComponent, {
        data: {
          bid: this._data.bid,
          userId: member.id,
          isUserBlocked: !!member.is_blocked,
          groupId: this._group.id,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        member.is_blocked = result.userBlocked ? 1 : 0;

        if (result.seeDetailsButtonClicked) {
          this._dialogRef.close();
        }
      });
  }

  private _close(): void {
    this._dialogRef.close();
  }

  private _onLoadMembers(): void {
    this._members?.forEach((member) => {
      member.isOnline = this._onlineReadingGroupMemberService.isOnline(
        member.id
      );
    });

    if (this._selectedMemberId) {
      this._selectedMemberName = this._members?.find(
        (member) => member.id === this._selectedMemberId
      )?.nickname;

      setTimeout(() => {
        this._scrollToSelectedMember();
      });
    }
  }

  private _loadMembers(groupId: number): void {
    if (this._members) {
      this._onLoadMembers();
      return;
    }

    this._readingGroupMembersAPIService
      .getJoined(groupId)
      .subscribe((response) => {
        this._members = response;
        this._onLoadMembers();
      });
  }

  private _scrollToSelectedMember(): void {
    if (!this._members || !this._isDialogOpened) {
      return;
    }

    const selectedMember = this._listElem.nativeElement.querySelector(
      '.list-item.is-selected'
    );

    selectedMember &&
      scrollIntoView(selectedMember, {
        block: 'center',
        scrollMode: 'if-needed',
        boundary: this._listElem.nativeElement,
      });
  }
}
