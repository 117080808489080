import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ReadingGroupDialogService } from 'reading-group';
import { BookshelfAPIService } from 'shared/services';
import { DialogService } from 'shared/ui';
import { BookshelfBook } from 'shared/models';
import { AppendixComponent } from 'bookshelf';

@Component({
  selector: 'app-library-page',
  templateUrl: './library-page.component.html',
  styleUrl: './library-page.component.scss',
})
export class LibraryPageComponent implements OnInit {
  @ViewChild('searchInput') searchInput?: ElementRef;
  public _bookList: BookshelfBook[] = [];
  public _currSearchValue: string = '';
  public _prevSearchValue: string = '';
  public _pageName?: string;
  public _libraryId?: string;

  public _isSearchButton: boolean = false;

  public _currentPage: number = 1;
  public readonly _itemCountPerPage: number = 30;
  public _totalItemCount?: number;
  public _isLoading: boolean = true;

  private _timer?: number;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _dialogService: DialogService,
    private _bookshelfAPIService: BookshelfAPIService,
    private _readingGroupDialogService: ReadingGroupDialogService
  ) {}

  ngOnInit(): void {
    const id = this._activatedRoute.snapshot.url[0].path.replace('@', '');

    this._activatedRoute.data.subscribe((result) => {
      if (!result.library.result) {
        this._pageName = result.library.name;
      } else {
        this._pageName = result.library.item.name;
      }
    });

    this._activatedRoute.queryParams.subscribe((params) => {
      const q = params['q'];
      this._currSearchValue = q;
      if (this._currSearchValue) {
        this._isSearchButton = true;
      }

      let p = parseInt(params['p']);
      if (isNaN(p)) {
        p = 1;
      }
      this._currentPage = p - 1;

      this._getBookList(id, q);
    });
  }

  public _getBookList(id: string, query: string): void {
    this._isLoading = true;

    this._bookshelfAPIService
      .getBooks(
        id,
        this._itemCountPerPage * this._currentPage,
        this._itemCountPerPage,
        query
      )
      .subscribe((result) => {
        this._isLoading = false;
        this._totalItemCount = result.total;
        this._bookList = result.items;
      });
  }

  public _onPageChange(page: number): void {
    const queryParams: {
      id?: string;
      p: number;
      q?: string;
    } = {
      id: this._libraryId,
      p: page + 1,
    };

    if (this._currSearchValue !== '') {
      queryParams.q = this._currSearchValue;
    }

    this._router.navigate([], {
      queryParams,
    });
  }

  onBookClick(index: number): void {
    const book = this._bookList[index];

    if (book.expired) {
      window.open(`${environment.serviceOrigin}/@${book.bid}`);
      return;
    }

    if (book.book_type === 'edition') {
      this._router.navigateByUrl(`/${book.bid}`);
      return;
    }

    const dialog = this._readingGroupDialogService.openReadingModeSelection(
      book.bid
    );

    dialog.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      if (result.canceled) {
        return;
      }

      this._router.navigate(['@' + book.bid + '/cover'], {
        queryParams: Object.assign(
          {},
          result.groupId && {
            gId: result.groupId,
          },
          {
            bookshelf: true,
          }
        ),
      });
    });
  }

  // 책장내에 책 검색 로직
  public _searchBookItem(): void {
    if (this._currSearchValue === this._prevSearchValue) {
      return;
    }

    this._prevSearchValue = this._currSearchValue;

    clearTimeout(this._timer);

    this._timer = window.setTimeout(() => {
      this._isLoading = true;
      this._onPageChange(0);
    }, 800);
  }

  public _clearCurSearchValueAndCloseSearchBar(): void {
    this._onClickSearchButton();
    this._clearCurrSearchValue();
  }

  public _onClickSearchButton(): void {
    this._isSearchButton = !this._isSearchButton;
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
  }

  public _clearCurrSearchValue(): void {
    this._currSearchValue = '';

    this._onPageChange(0);
  }

  public _onClickAppendix(event: Event, id: string): void {
    event.stopPropagation();
    this._dialogService.open(AppendixComponent, {
      data: {
        book_id: id,
      },
    });
  }
}
