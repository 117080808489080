<div class="drag-overlay" #dragOverlay></div>
<div
  #popup
  class="popup"
  cdkDrag
  (cdkDragStarted)="_onDragStarted()"
  (cdkDragReleased)="_onDragReleased()"
  [class.is-active]="_isOpened"
>
  <div class="header">
    <sui-icon name="옮기기" type="solid" cdkDragHandle></sui-icon>
    메모 목록
    <div class="expand"></div>
    <button sui-icon-button (click)="_onCloseButtonClick()">
      <sui-icon name="엑스"></sui-icon>
    </button>
  </div>
  <div class="body">
    <div class="text-box">
      <div class="text">{{ _text }}</div>
    </div>
    <div
      class="memos"
      [class.multiple]="_memos.length > 1"
      suiListIntersectionObserver
    >
      <ng-container *ngIf="_isCommentaryEditorMode">
        <div class="memo" *ngFor="let memo of _memos">
          <viewer-commentary-editor-memo
            class="memo-comp"
            [memo]="memo"
            [text]="_text!"
            [limitHeight]="true"
            (memoDeleted)="_onMemoDeleted()"
          ></viewer-commentary-editor-memo>
        </div>
      </ng-container>
      <ng-container *ngIf="!_isCommentaryEditorMode">
        <div class="memo" *ngFor="let memo of _memos">
          <viewer-reading-mode-memo
            class="memo-comp"
            [memo]="memo"
            [text]="_text!"
            [limitHeight]="true"
            (memoDeleted)="_onMemoDeleted()"
            (memoBlinded)="_onMemoBlinded()"
            (memoUserBlocked)="_onMemoUserBlocked()"
            (memoPinOptionChanged)="_onMemoPinOptionChanged()"
            [isAuthorOnline]="_onlineUserMap[memo.author.id]"
            suiListItemIntersectionObserver
            (intersected)="_onMemoRead(memo)"
            [stopAfterIntersection]="true"
          ></viewer-reading-mode-memo>
        </div>
      </ng-container>
    </div>
    <div class="footer">
      <button sui-text-icon-button (click)="_seeMemoListButtonClick()">
        {{ _isPDF ? '페이지' : '챕터' }} 메모 모아보기
        <sui-icon name="오른쪽 이동"></sui-icon>
      </button>
    </div>
  </div>
</div>
