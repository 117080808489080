import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, map, switchMap } from 'rxjs';
import { ReadingGroupsAPIService } from 'shared/services';
import { DialogService } from 'shared/ui';
import { ReadingGroupDetailsDialogComponent } from 'reading-group';
import { ReadingGroup } from 'shared/models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'lib-my-bookshelf-groups-page',
  templateUrl: './my-bookshelf-groups-page.component.html',
  styleUrl: './my-bookshelf-groups-page.component.scss',
})
export class MyBookshelfGroupsPageComponent implements OnInit, OnDestroy {
  private _readingGroupSubject = new Subject<{ page: number }>();
  public _readingGroup!: ReadingGroup[];

  public _currentPage = 1;
  public readonly _itemCountPerPage = 20;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this._readingGroupSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._readingGroupsAPIService.getJoined(
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        window.scrollTo(0, 0);
        this._isLoading = false;
        this._totalItemCount = result.total;
        this._readingGroup = result.items;
      });

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }
        this._currentPage = page - 1;

        this._readingGroupSubject.next({ page: this._currentPage });
      });
  }

  ngOnDestroy(): void {
    this._readingGroupSubject.complete();
  }

  public _onClickDetailPopup(readingGroup: ReadingGroup): void {
    this._dialogService
      .open(ReadingGroupDetailsDialogComponent, {
        data: {
          groupId: readingGroup.id,
          isUserLoggedIn: true,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.exited) {
          this._onPageChange(0);
        }

        if (result.groupId != null) {
          this._router.navigate(['/@' + readingGroup.content.id + '/cover'], {
            queryParams: {
              gId: result.groupId,
            },
          });
        }
      });
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }

  public _routeDetailBookPage(id: string): void {
    this._router.navigate(['/@' + id]);
  }
}
