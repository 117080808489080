import { Injectable } from '@angular/core';
import { SharedAnalyticsService } from 'shared/services';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends SharedAnalyticsService {
  override trackEvent(
    action: string,
    label?: string | undefined,
    customDimension?: any,
    categorySuffix?: string | undefined
  ): void {
    // throw new Error('Method not implemented.');
  }
}
