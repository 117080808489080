import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from 'shared/models';
import { UsersAPIService } from 'shared/services';
import { MenuPositionBottomLeft, TypedDialog } from 'shared/ui';
@Component({
  selector: 'lib-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrl: './notification-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class NotificationDialogComponent
  extends TypedDialog<
    { isApp: boolean; serviceOrigin: string },
    { didFollowAction: boolean }
  >
  implements OnInit
{
  @ViewChild('listContainer') private listContainer?: ElementRef;

  public readonly _moreMenuPosition = MenuPositionBottomLeft;

  public _notificationList?: Notification[];
  public _selectedIndex: number = 0;

  public _total: number = 0;
  public _currentPage: number = 0;
  public _itemCountPerPage: number = 20;
  public _isLoading: boolean = true;

  public _didFollowAction = false;

  constructor(
    private _usersAPIService: UsersAPIService,
    private _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this._getNotificationList();
  }

  public _getNotificationList(): void {
    this._usersAPIService
      .getNotificationList(
        this._currentPage * this._itemCountPerPage,
        this._itemCountPerPage
      )
      .subscribe((result) => {
        this._isLoading = false;

        this._total = result.total;
        this._notificationList = result.items;
      });
  }

  public _onCloseButtonClick(): void {
    this._close();
  }

  public _changeIndex(index: number): void {
    this._selectedIndex = index;
  }

  public _onDeleteNoti(): void {
    const notiId = this._notificationList![this._selectedIndex].id;

    this._usersAPIService.deleteNotification(notiId).subscribe(() => {
      if (this._notificationList?.length === 1) {
        this._onPageChange(this._currentPage - 1);
      } else {
        this._getNotificationList();
      }
    });
  }

  public _follow(user_id: string): void {
    this._didFollowAction = true;
    this._usersAPIService.follow(user_id).subscribe(() => {
      this._getNotificationList();
    });
  }
  public _unfollow(user_id: string): void {
    this._didFollowAction = true;
    this._usersAPIService.unfollow(user_id).subscribe(() => {
      this._getNotificationList();
    });
  }

  public _routeBookDetail(book_id: string): void {
    this._router.navigate(['/@' + book_id]);
    this._close();
  }

  public _routeMemo(url: string): void {
    this._close();
    this._router.navigateByUrl(url);
  }

  public _routeMesssageTypeUrl(url: string): void {
    this._close();

    if (this._data.isApp) {
      window.open(this._data.serviceOrigin + url, '_blank');
    } else {
      this._router.navigateByUrl(url);
    }
  }

  public _routeBookshelf(user_id: string): void {
    this._close();
    this._router.navigateByUrl('/bookshelf/' + user_id);
  }

  public _routeMyGroup(): void {
    this._close();
    this._router.navigate(['/bookshelf/groups/exited']);
  }

  public _onPageChange(page: number): void {
    if (page < 0) {
      page = 0;
    }

    this._currentPage = page;

    this._usersAPIService
      .getNotificationList(
        page * this._itemCountPerPage,
        this._itemCountPerPage
      )
      .subscribe((result) => {
        this._isLoading = false;

        if (this.listContainer) {
          this.listContainer.nativeElement.scrollTop = 0;
        }

        this._total = result.total;
        this._notificationList = result.items;
      });
  }

  public _getGroupReaderDisabledMessage(disabledUntilDate: string): string {
    const until = new Date(disabledUntilDate);
    const since = new Date(disabledUntilDate);
    since.setDate(until.getDate() + 1);

    return `신고 횟수 누적으로 ${until.getUTCFullYear()}.${
      until.getUTCMonth() + 1
    }.${until.getUTCDate()}일까지 혼자읽기 모드만 이용이 가능합니다. ${since.getUTCFullYear()}.${
      since.getUTCMonth() + 1
    }.${since.getUTCDate()}일부터 다시 함께읽기 모드를 이용할 수 있습니다.`;
  }

  public _close(): void {
    this._dialogRef.close({ didFollowAction: this._didFollowAction });
  }
}
