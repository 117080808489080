import { Component, Input, OnInit } from '@angular/core';
import {
  AnnotationListComponent,
  AnnotationSortType,
  AnnotationView2,
} from '../annotation-list/annotation-list.component';
import { Book } from '../../models/book.model';
import { UIStateService } from '../../services/ui-state.service';
import { PDFDrawingService } from '../../services/pdf-drawing.service';

interface DrawingItemView {
  iid: string;
  page: number;
  thumbnail: string;
  drawingURL: string | null;
  ratio: number;
}

@Component({
  selector: 'viewer-drawing-list',
  templateUrl: './drawing-list.component.html',
  styleUrls: [
    '../annotation-list/annotation-list.component.scss',
    './drawing-list.component.scss',
  ],
})
export class DrawingListComponent
  extends AnnotationListComponent<DrawingItemView>
  implements OnInit
{
  @Input() book!: Book;

  constructor(
    private _pdfDrawingService: PDFDrawingService,
    private _uiStateService: UIStateService
  ) {
    super();
    this._selectedSortType = AnnotationSortType.Position;
  }

  public override loadItems(): void {
    if (!this._uiStateService.canUseDrawingMode) {
      this._items = [];
    }

    this._pdfDrawingService.getDrawnItemList().subscribe((iids) => {
      this._items = iids.reduce((items, iid) => {
        const itemIndex = this.book.getIndexOfItem(iid);

        if (itemIndex === -1) {
          return items;
        }

        const item = this.book.getItem(iid);

        if (!item) {
          return items;
        }

        items.push({
          iid,
          page: itemIndex + 1,
          thumbnail: item?.thumbnail ?? '',
          ratio: Math.round((item.fixedWidth! / item.fixedHeight!) * 100) / 100,
          selected: false,
          updatedDateTime: 0,
          position: itemIndex + 1,
          drawingURL: this._pdfDrawingService.getDrawingURL(iid),
        });

        return items;
      }, [] as (AnnotationView2 & DrawingItemView)[]);

      this._sortItems();
    });
  }

  _onItemClick(item: DrawingItemView): void {
    this.itemClicked.emit(`/@${this.book.meta.bid}/${item.iid}`);
  }

  trackByItem(index: number, item: any): any {
    return item.iid;
  }
}
