import { Injectable } from '@angular/core';

import { BukJSON } from '../models/book.model';
import { Broadcaster } from '../classes/broadcaster';

import { EventBusService } from './event-bus.service';

interface EventDataMap {
  change: { prev: boolean; next: boolean };
}

@Injectable({
  providedIn: 'root',
})
export class PageChangeAvailabilityService extends Broadcaster<EventDataMap> {
  private _book?: BukJSON;
  private _currentIid?: string;

  constructor(private _eventBus: EventBusService) {
    super();

    this._eventBus.on('ContentsComponent:bookLoad').subscribe(({ book }) => {
      this._book = book;
    });

    this._eventBus
      .on('ContentsComponent:addressChange')
      .subscribe(({ address }) => {
        this._currentIid = address.iid;
      });

    this._eventBus.on('ContentsComponent:pageInfoChange').subscribe((data) => {
      this.checkAvailability(data.pageCount, data.page);
    });
  }

  checkAvailability(totalPageCount: number, currentPage: number): void {
    const currentIid = this._currentIid ?? this._book!.items[0].iid;
    const currentItemIndex = this._book!.items.findIndex(
      (item) => item.iid === currentIid
    );

    const prevItem = this._book!.items[currentItemIndex - 1];
    const nextItem = this._book!.items[currentItemIndex + 1];

    // let prevItem = this.permissionService.findPrevReadableItem(this.sharedStateService.address.iid, this.sharedStateService.book);
    // let nextItem = this.permissionService.findNextReadableItem(this.sharedStateService.address.iid, this.sharedStateService.book);

    this.fire('change', {
      prev: !!prevItem || currentPage > 1,
      next: !!nextItem || currentPage < totalPageCount,
    });
  }
}
