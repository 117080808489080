import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Highlighter } from '@bukio/highlighter';
import { parseBukURL } from '@bukio/viewer';

import { Url } from '../constants/url';
import { Bookmark } from '../models/bookmark.model';

function mapLegacyResponse<T extends { result: LegacyResultStr }>(
  response: T
): T {
  if (response.result === 'error') {
    throw new Error(JSON.stringify(response));
  }

  return response;
}

type LegacyResultStr = 'ok' | 'error';

const highlighter = new Highlighter();

function isLegacyBookmark(bookmark: Bookmark): boolean {
  const address = parseBukURL(bookmark.url);

  if (typeof address.anchor !== 'string') {
    return false;
  }

  const parsed = highlighter.parseAddress(address.anchor);

  return Object.keys(parsed).length === 0;
}

@Injectable({
  providedIn: 'root',
})
export class BookmarksAPIService {
  constructor(private _http: HttpClient) {}

  getAll(bukId: string): Observable<Bookmark[]> {
    return this._http
      .get<{ items: Bookmark[]; result: LegacyResultStr }>(Url.bookmark, {
        params: { bukId, wiki: 1 },
        withCredentials: true,
      })
      .pipe(
        map(mapLegacyResponse),
        map((response) => {
          return response.items.filter(
            (bookmark) =>
              !isLegacyBookmark(bookmark) && bookmark.type === 'bookmark'
          );
        })
      );
  }

  create(bookmark: Partial<Bookmark>): Observable<void> {
    return this._http
      .post<{ code: number; item: any; result: LegacyResultStr }>(
        Url.bookmark,
        bookmark,
        { withCredentials: true }
      )
      .pipe(
        map(mapLegacyResponse),
        map(() => undefined)
      );
  }

  update(
    id: number,
    props: Partial<{
      className: string;
      memo: string;
    }>
  ): Observable<void> {
    return this._http
      .put<{ code: number; result: LegacyResultStr }>(
        Url.bookmark,
        { id, class: props.className, memo: props.memo },
        { withCredentials: true }
      )
      .pipe(
        map(mapLegacyResponse),
        map(() => undefined)
      );
  }

  delete(ids: number | number[]): Observable<void> {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return this._http
      .delete<{ code: number; result: LegacyResultStr }>(Url.bookmark, {
        params: { id: ids.join(',') },
        withCredentials: true,
      })
      .pipe(
        map(mapLegacyResponse),
        map(() => undefined)
      );
  }
}
