import { Inject, Injectable, InjectionToken } from '@angular/core';
import { init, track } from '@amplitude/analytics-browser';

export const AMPLITUDE_API_KEY = new InjectionToken<string>('AmplitudeApiKey');

@Injectable()
export class AmplitudeService {
  constructor(@Inject(AMPLITUDE_API_KEY) private apiKey: string) {
    init(this.apiKey, {
      autocapture: true,
    });
  }

  public logEvent(eventName: string, eventProperty?: any): void {
    track(eventName, eventProperty);
  }
}
