import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';
import { DialogModule } from '@angular/cdk/dialog';
import { OverlayModule } from '@angular/cdk/overlay';

// components
import { IconComponent } from './icon/icon.component';
import { DialogContainerComponent } from './dialog/dialog-container.component';
import { AlertContainerComponent } from './alert/alert-container.component';
import { SimpleAlertComponent } from './alert/simple-alert.component';
import { ToastContainerComponent } from './toast/toast-container.component';
import { ToastComponent } from './toast/toast.component';
import { BoxButtonComponent } from './box-button/box-button.component';
import { SolidBoxButtonComponent } from './solid-box-button/solid-box-button.component';
import { SubBoxButtonComponent } from './sub-box-button/sub-box-button.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { LoaderComponent } from './loader/loader.component';
import { FlagTagComponent } from './flag-tag/flag-tag.component';
import { ReadingGroupMemberComponent } from './reading-group-member/reading-group-member.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { RoundedTagComponent } from './rounded-tag/rounded-tag.component';
import { SelectComponent } from './select/select.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TextIconButtonComponent } from './text-icon-button/text-icon-button.component';
import { MiniButtonComponent } from './mini-button/mini-button.component';
import { MenuComponent } from './menu/menu.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { ReadingGroupMemberAvatarComponent } from './reading-group-member-avatar/reading-group-member-avatar.component';
import { DialogHeaderComponent } from './dialog/dialog-header.component';
import { CommentatorAvatarComponent } from './commentator-avatar/commentator-avatar.component';
import { PaginatorComponent } from './paginator/paginator.component';
import {
  BookComponent,
  BookTagComponent,
  BookTailTagComponent,
  BookTitleComponent,
  BookAuthorComponent,
  BookCoverComponent,
} from './book/book.component';
import { BookSubContentTagComponent } from './book-sub-content-tag/book-sub-content-tag.component';
import { BookExpiredOverlayComponent } from './book-expired-overlay/book-expired-overlay.component';

// directives
import { MenuItemDirective } from './menu/menu.component';
import { IntersectionListenerDirective } from './intersection-listener.directive';
import {
  ListIntersectionObserverDirective,
  ListItemIntersectionObserverDirective,
} from './list-intersection-observer.directive';

// pipes
import { MarkPipe } from './mark.pipe';
import { PeriodTranslatePipe } from './period-translate.pipe';
import { CurrencyTranslatePipe } from './currency-translate.pipe';
import {
  FormFieldComponent,
  FormFieldHintComponent,
  FormFieldSuffixDirective,
} from './form-field/form-field.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PortalModule,
    DialogModule,
    OverlayModule,
  ],
  declarations: [
    // components
    IconComponent,
    DialogContainerComponent,
    AlertContainerComponent,
    SimpleAlertComponent,
    ToastContainerComponent,
    ToastComponent,
    BoxButtonComponent,
    SolidBoxButtonComponent,
    SubBoxButtonComponent,
    RadioButtonComponent,
    LoaderComponent,
    FlagTagComponent,
    ReadingGroupMemberComponent,
    IconButtonComponent,
    RoundedTagComponent,
    SelectComponent,
    CheckboxComponent,
    TextIconButtonComponent,
    MiniButtonComponent,
    MenuComponent,
    SlideToggleComponent,
    ReadingGroupMemberAvatarComponent,
    DialogHeaderComponent,
    CommentatorAvatarComponent,
    PaginatorComponent,
    BookComponent,
    BookTagComponent,
    BookTailTagComponent,
    BookTitleComponent,
    BookAuthorComponent,
    BookCoverComponent,
    BookSubContentTagComponent,
    BookExpiredOverlayComponent,
    FormFieldComponent,
    FormFieldHintComponent,
    // directives
    MenuItemDirective,
    IntersectionListenerDirective,
    ListIntersectionObserverDirective,
    ListItemIntersectionObserverDirective,
    FormFieldSuffixDirective,
    // pipes
    MarkPipe,
    PeriodTranslatePipe,
    CurrencyTranslatePipe,
  ],
  exports: [
    // components
    IconComponent,
    SimpleAlertComponent,
    BoxButtonComponent,
    SolidBoxButtonComponent,
    SubBoxButtonComponent,
    RadioButtonComponent,
    LoaderComponent,
    FlagTagComponent,
    ReadingGroupMemberComponent,
    IconButtonComponent,
    RoundedTagComponent,
    SelectComponent,
    CheckboxComponent,
    TextIconButtonComponent,
    MiniButtonComponent,
    MenuComponent,
    SlideToggleComponent,
    ReadingGroupMemberAvatarComponent,
    DialogHeaderComponent,
    CommentatorAvatarComponent,
    PaginatorComponent,
    BookComponent,
    BookTagComponent,
    BookTailTagComponent,
    BookTitleComponent,
    BookAuthorComponent,
    BookCoverComponent,
    BookSubContentTagComponent,
    BookExpiredOverlayComponent,
    FormFieldComponent,
    FormFieldHintComponent,
    // directives
    MenuItemDirective,
    IntersectionListenerDirective,
    ListIntersectionObserverDirective,
    ListItemIntersectionObserverDirective,
    FormFieldSuffixDirective,
    // pipes
    MarkPipe,
    PeriodTranslatePipe,
    CurrencyTranslatePipe,
  ],
})
export class SharedUIModule {}
