import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { BookshelfAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-edit-bookshelf',
  templateUrl: './edit-bookshelf.component.html',
  styleUrl: './edit-bookshelf.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class EditBookshelfComponent
  extends TypedDialog<
    { bookshelf_id: string; bookshelf_name: string; is_private: number },
    { canceled: 'success' | 'failed' }
  >
  implements OnInit, OnDestroy
{
  private _EditBookshelfSubject = new Subject<void>();

  public _inputBookshelfValue: string = '';
  public _isPublic: number = this._data.is_private;
  public _bookshelfName = this._data.bookshelf_name;

  constructor(
    private _bookshelfAPIService: BookshelfAPIService,
    private _toastService: ToastService
  ) {
    super();
  }

  ngOnInit(): void {
    this._EditBookshelfSubject
      .pipe(
        switchMap(() => {
          return this._bookshelfAPIService.changeBookshelf(
            this._data.bookshelf_id,
            this._inputBookshelfValue,
            this._isPublic
          );
        })
      )
      .subscribe(() => {
        this._toastService.open('책장명 변경이 완료되었습니다.');
        this._dialogRef.close({ canceled: 'success' });
      });
  }

  ngOnDestroy(): void {
    this._EditBookshelfSubject.complete();
  }

  public _closeDialog(): void {
    this._dialogRef.close();
  }

  public _changeIsPublic(event: any): void {
    if (event.srcElement.checked) {
      this._isPublic = 1;
    } else {
      this._isPublic = 0;
    }
  }

  public _onClickSubmit(): void {
    this._EditBookshelfSubject.next();
  }
}
