import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Highlight } from 'shared/models';

import { Book } from '../../models/book.model';
import { EventBusService } from '../../services/event-bus.service';

import { TabItem } from '../tab/tab.component';
import { RightPanelComponent } from '../right-panel/right-panel.component';
import { AnnotationListComponent } from '../annotation-list/annotation-list.component';
import { UIStateService } from '../../services/ui-state.service';
import { PDFDrawingService } from '../../services/pdf-drawing.service';

enum Tab {
  highlight = '하이라이트',
  memo = '메모',
  bookmark = '북마크',
  drawing = '필기',
}

@Component({
  selector: 'viewer-annotations',
  templateUrl: './annotations.component.html',
  styleUrl: './annotations.component.scss',
})
export class AnnotationsComponent implements OnInit {
  @ViewChild('annotationList')
  annotationListComp?: AnnotationListComponent<unknown>;
  @ViewChild('drawingList') drawingListComp?: AnnotationListComponent<unknown>;

  private _unsubscriber = new Subject<void>();

  public _tabItems: TabItem[] = [
    {
      icon: '하이라이트',
      name: Tab.highlight,
    },
    {
      icon: '메모',
      iconType: 'solid',
      name: Tab.memo,
    },
    {
      icon: '북마크',
      iconType: 'solid',
      name: Tab.bookmark,
    },
  ];

  public _Tab = Tab;
  public _selectedTab = Tab.highlight;

  public _book?: Book;

  constructor(
    private _rightPanelComponent: RightPanelComponent,
    private _eventBusService: EventBusService,
    private _uiStateService: UIStateService,
    @Optional() private _pdfDrawingService: PDFDrawingService
  ) {}

  ngOnInit(): void {
    if (this._pdfDrawingService) {
      this._tabItems.push({
        icon: '펜',
        iconType: 'solid',
        name: Tab.drawing,
      });
    }

    this._eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        if (this._uiStateService.isCommentaryEditor) {
          this._tabItems.pop();
        }
        this._book = data.book;
      });

    this._eventBusService
      .on('AnnotationSettingsFabComponent:anotationsButtonClick')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(() => {
        this._rightPanelComponent.open('annotations');
        this.annotationListComp?.loadItems();
        this.drawingListComp?.loadItems();
      });
  }

  _onTabChange(): void {}

  _onCloseButtonClick(): void {
    this._rightPanelComponent.close();
  }

  _onItemClicked(url: string): void {
    this._rightPanelComponent.close();
    this._eventBusService.fire('AnnotationsComponent:itemClick', { url });
  }
}
