<div class="list-header">
  <div class="left">
    <div class="title">
      이 책에 남긴 <span class="count">{{ _items.length }}</span
      >페이지의 필기 기록이 있습니다.
    </div>
  </div>
</div>
<div class="list">
  <div
    class="list-item"
    *ngFor="let item of _items; index as i; trackBy: trackByItem"
  >
    <div class="box" (click)="_onItemClick(item)">
      <div class="thumbnail">
        <img
          class="image"
          loading="lazy"
          [src]="item.thumbnail"
          [style.aspect-ratio]="item.ratio"
        />
        <img class="drawing" loading="lazy" [src]="item.drawingURL" />
      </div>
    </div>
    <div class="page">{{ item.page }} P</div>
  </div>
</div>
