import { Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { APIError, ReadingGroupsAPIService } from 'shared/services';
import { AlertService, DialogService, ToastService } from 'shared/ui';

import {
  ReadingGroupDetailsDialogComponent,
  ReadingGroupPasscodeDialogComponent,
} from 'reading-group';
import {
  ReadingGroup,
  ReadingGroupJoinStatus,
  ReadingGroupType,
} from 'shared/models';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrl: './group-list.component.scss',
})
export class GroupListComponent implements OnInit, OnDestroy {
  @Input() userId?: string;
  @Input() parentElement?: HTMLDivElement;
  @Output() readTogetherButtonClick = new EventEmitter<void>();

  @ViewChild('groupList') private groupList?: ElementRef;

  private _readingGroupSubject = new Subject<{ page: number }>();

  public _readingGroup?: ReadingGroup[];
  public _userId: string = this._router.url.split('/')[2];

  public _currentPage = 0;
  public readonly _itemCountPerPage = 20;
  public _totalItemCount?: number;
  public _isLoading = true;

  constructor(
    private _router: Router,
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private userService: UserService,
    private _dialogService: DialogService,
    private _toastService: ToastService,
    private _alertService: AlertService
  ) {}

  ngOnInit(): void {
    this._readingGroupSubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;

          return this._readingGroupsAPIService.getOthersGroupList(
            page * this._itemCountPerPage,
            this._itemCountPerPage,
            this.userId
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;

        if (this.groupList) {
          this.groupList.nativeElement.scrollTop = 0;
        }

        if (this.parentElement) {
          this.parentElement.scrollTop = 0;
        }

        this._totalItemCount = result.total;
        this._readingGroup = result.items;
      });

    this._readingGroupSubject.next({ page: 0 });
  }

  ngOnDestroy(): void {
    this._readingGroupSubject.complete();
  }

  public _onClickDetailPopup(readingGroup: ReadingGroup): void {
    this._dialogService
      .open(ReadingGroupDetailsDialogComponent, {
        data: {
          groupId: readingGroup.id,
          isUserLoggedIn: !!this.userService.getUser(),
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        if (result.groupId != null) {
          this._dialogService.closeAll();
          this._router.navigate(['/@' + readingGroup.content.id + '/cover'], {
            queryParams: {
              gId: result.groupId,
            },
          });
        }

        if (result.canceled) {
          if (result.exited) {
            readingGroup.member_count--;
            readingGroup.status = ReadingGroupJoinStatus.none;
          } else if (result.joined) {
            if (readingGroup.member_count < readingGroup.max_member_count) {
              readingGroup.member_count++;
            }
            readingGroup.status = ReadingGroupJoinStatus.joined;
          }
        }
      });
  }

  public _onClickReadTogether(myGroup: ReadingGroup): void {
    this.readTogetherButtonClick.emit();
    this._router.navigate(['/@' + myGroup.content.id + '/cover'], {
      queryParams: {
        gId: myGroup.id,
      },
    });
  }

  _onJoinGroupButtonClick(groupId: number, groupType: ReadingGroupType): void {
    if (groupType === 'private') {
      this._alertService
        .open(ReadingGroupPasscodeDialogComponent, {
          data: {
            groupId: groupId,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this._toastService.open('가입이 완료되었습니다.');
            this._readingGroup = this._readingGroup?.map((group) => {
              if (group.id === groupId) {
                group.status = ReadingGroupJoinStatus.joined;
              }

              return group;
            });
          }
        });
    } else {
      this._readingGroupsAPIService.join(groupId).subscribe({
        next: () => {
          this._toastService.open('가입이 완료되었습니다.');

          this._readingGroup = this._readingGroup?.map((group) => {
            if (group.id === groupId) {
              group.status = ReadingGroupJoinStatus.joined;
            }

            return group;
          });
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
    }
  }

  public _onPageChange(page: number): void {
    this._currentPage = page;
    this._readingGroupSubject.next({ page: this._currentPage });
  }

  public _routeDetailBookPage(id: string): void {
    this._router.navigate(['/@' + id]);
  }
}
