import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { parseBukURL, Annotation as ViewerAnnotation } from '@bukio/viewer';

import { Highlight } from 'shared/models';
import { generateRandomUUID, stripUndefinedParams } from 'shared/utils';

import { catchAndRethrowAPIError } from './api-error';

const CREATE_HIGHLIGHT_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'The group leader leaves the group':
    '그룹장이 ‘나가기’한 그룹 입니다. 그룹원들은 새로운 기록을 공유할 수 없습니다.',
  'Duplicate highlight ID': '잘못된 요청입니다. (1)',
  'Parameter missing': '잘못된 요청입니다. (2)',
};

const UPDATE_HIGHLIGHT_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Highlight not found': '하이라이트를 찾을 수 없습니다.',
};

const DELETE_HIGHLIGHT_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다.',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Highlight not found': '하이라이트를 찾을 수 없습니다.',
  'Parameter missing': '잘못된 요청입니다.',
};

@Injectable({
  providedIn: 'root',
})
export class HighlightsAPIService {
  constructor(private _http: HttpClient) {}

  get(
    bid: string,
    offset: number,
    limit: number,
    query?: {
      group_id?: number;
      member_id?: string;
      commentary_id?: number;
      filter?: 'mine' | 'theirs' | 'all';
    }
  ): Observable<Highlight[]> {
    return this._http
      .get<{ items: Highlight[] }>(`/api/v1/highlights`, {
        params: stripUndefinedParams({
          bid,
          offset,
          limit,
          ...query,
        }),
        withCredentials: true,
      })
      .pipe(map((response) => response.items));
  }

  create(
    highlight: ViewerAnnotation,
    position: number,
    groupId?: number,
    commentaryId?: number
  ): Observable<Highlight> {
    const address = parseBukURL(highlight.url);

    if (!address.iid) {
      throw new Error('invalid highlight');
    }

    const body = {
      id: generateRandomUUID(),
      bid: address.bid,
      iid: address.iid,
      url: highlight.url,
      text: highlight.text,
      position,
      style: highlight.styleClass,
    } as any;

    if (groupId != null) {
      body.group_id = groupId;
    }

    if (commentaryId != null) {
      body.commentary_id = commentaryId;
    }

    return this._http
      .post<Highlight>('/api/v1/highlights', body, {
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(CREATE_HIGHLIGHT_ERROR_MSG_MAP));
  }

  update(highlightId: string, style: string): Observable<void> {
    return this._http
      .put<void>(
        `/api/v1/highlights/${highlightId}`,
        { style },
        { withCredentials: true }
      )
      .pipe(catchAndRethrowAPIError(UPDATE_HIGHLIGHT_ERROR_MSG_MAP));
  }

  delete(highlightIds: string[]): Observable<void> {
    return this._http
      .delete<void>(`/api/v1/highlights`, {
        params: {
          highlight_ids: highlightIds.join(','),
        },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError(DELETE_HIGHLIGHT_ERROR_MSG_MAP));
  }
}
