export enum HighlightColor {
  Yellow = 'bukh-color1',
  Red = 'bukh-color2',
  Green = 'bukh-color3',
  Cyan = 'bukh-color4',
  Purple = 'bukh-color5',
  Gray = 'bukh-color6',
  Blue = 'bukh-color7',
}

export const HIGHLIGHT_COLORS = {
  [HighlightColor.Yellow]: 'rgba(255, 189, 47, 0.2)',
  [HighlightColor.Red]: 'rgba(255, 0, 0, 0.2)',
  [HighlightColor.Green]: 'rgba(24, 122, 0, 0.2)',
  [HighlightColor.Cyan]: 'rgba(0, 243, 144, 0.2)',
  [HighlightColor.Purple]: 'rgba(51, 45, 255, 0.2)',
  [HighlightColor.Gray]: 'rgba(183, 173, 145, 0.2)',
  [HighlightColor.Blue]: 'rgba(0, 163, 255, 0.2)',
};
