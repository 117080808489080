import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { UserProfile } from 'shared/models';
import {
  BookshelfAPIService,
  ReadingGroupsAPIService,
  UsersAPIService,
} from 'shared/services';
import { DialogService, TypedDialog } from 'shared/ui';
import {
  FollowerComponent,
  FollowingComponent,
  NoteSendDialogComponent,
} from 'user';

@Component({
  selector: 'app-others-bookshelf-dialog',
  templateUrl: './others-bookshelf-dialog.component.html',
  styleUrl: './others-bookshelf-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class OthersBookshelfDialogComponent
  extends TypedDialog<{ userId: string }, void>
  implements OnInit, OnDestroy
{
  @ViewChild('listContainer') private listContainer?: ElementRef;

  public _pageType: 'main' | 'detail' = 'main';
  public _selectedBookshelfId?: string;

  private _myFollowingListSubject = new Subject<void>();
  private _followSubject = new Subject<void>();
  private _unFollowSubject = new Subject<void>();

  public _userId: string = this._data.userId;
  public _userProfile?: UserProfile;
  public _selectedType: string = '책장';
  public _isFollow?: boolean;

  public _isLoading = true;
  public _totalBookshelf?: number;
  public _totalGroup?: number;

  constructor(
    private _dialogService: DialogService,
    private _usersAPIService: UsersAPIService,
    private _bookshelfAPIService: BookshelfAPIService,
    private _readingGroupsAPIService: ReadingGroupsAPIService
  ) {
    super();
  }

  ngOnInit(): void {
    this._myFollowingListSubject
      .pipe(
        switchMap(() => {
          return this._usersAPIService.getFollowings();
        })
      )
      .subscribe((result) => {
        for (let i = 0; i < result.items.length; i++) {
          if (result.items[i].id === this._data.userId) {
            this._isFollow = true;
            break;
          }
          this._isFollow = false;
        }
      });

    this._followSubject
      .pipe(
        switchMap(() => {
          return this._usersAPIService.follow(this._data.userId);
        })
      )
      .subscribe(() => {
        this._isFollow = true;
        this._myFollowingListSubject.next();
        this._getUsersProfile();
      });

    this._unFollowSubject
      .pipe(
        switchMap(() => {
          return this._usersAPIService.unfollow(this._data.userId);
        })
      )
      .subscribe(() => {
        this._isFollow = false;
        this._myFollowingListSubject.next();
        this._getUsersProfile();
      });

    this._getUsersProfile();

    this._readingGroupsAPIService
      .getOthersGroupList(0, 0, this._userId)
      .subscribe((result) => {
        this._totalGroup = result.total;
      });

    this._bookshelfAPIService
      .getOthersBookshelvesList(0, 0, this._userId)
      .subscribe((response) => {
        this._isLoading = false;

        this._totalBookshelf = response.total;
      });
  }

  ngOnDestroy(): void {
    this._myFollowingListSubject.complete();
    this._followSubject.complete();
    this._unFollowSubject.complete();
  }

  public _getUsersProfile(): void {
    this._usersAPIService
      .getUserProfile(this._data.userId)
      .subscribe((result) => {
        this._userProfile = result;
      });
  }

  public _onCloseButtonClick(): void {
    this._dialogRef.close();
  }

  public _groupEnterButtonClick(): void {
    this._dialogService.closeAll();
  }

  public _changeNavbar(type: string): void {
    this._selectedType = type;
  }

  public _onClickFollower(): void {
    this._dialogService
      .open(FollowerComponent, {
        responsiveBreakpoint: 768,
        data: {
          user_id: this._data.userId,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.updated) {
          this._getUsersProfile();
        }
      });
  }

  public _onClickFollowing(): void {
    this._dialogService
      .open(FollowingComponent, {
        responsiveBreakpoint: 768,
        data: {
          user_id: this._data.userId,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.updated) {
          this._getUsersProfile();
        }
      });
  }

  public _onClickMessageButton(): void {
    this._dialogService.open(NoteSendDialogComponent, {
      responsiveBreakpoint: 768,
      data: {
        userId: this._data.userId,
        nickname: this._userProfile?.nickname,
      },
    });
  }

  public _onClickFollow(): void {
    this._followSubject.next();
  }

  public _onClickUnFollow(): void {
    this._unFollowSubject.next();
  }

  public _onChangePageType(id: string): void {
    if (id !== 'back') {
      this._pageType = 'detail';
      this._selectedBookshelfId = id;
    } else {
      this._pageType = 'main';
    }
  }
}
