export function createSNSShareUrl(
  url: string,
  text: string,
  target: 'twitter' | 'facebook'
): string {
  url = encodeURIComponent(url);
  text = encodeURIComponent(text);

  switch (target) {
    case 'twitter':
      return `https://twitter.com/intent/tweet?url=${url}&text=%23buk`;
    case 'facebook':
      return `https://www.facebook.com/dialog/share?app_id=1122818604411565&display=popup&href=${url}&quote=${text}`;
  }
}

export function createSNSShareWindowFeatures(): string {
  const w = window.innerWidth > 600 ? 600 : window.innerWidth * 0.9;
  const h = window.innerHeight > 600 ? 600 : window.innerHeight * 0.9;
  const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const wTop = window.screenTop ? window.screenTop : window.screenY;

  const left = wLeft + window.innerWidth / 2 - w / 2;
  const top = wTop + window.innerHeight / 2 - h / 2;

  return `location=no, menubar=no, toolbar=no, resizable=yes, scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`;
}
