import { Component } from '@angular/core';
import { finalize } from 'rxjs';

import { APIError, ReadingGroupsAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

@Component({
  selector: 'lib-reading-group-passcode-dialog',
  templateUrl: './reading-group-passcode-dialog.component.html',
  styleUrl: './reading-group-passcode-dialog.component.scss',
})
export class ReadingGroupPasscodeDialogComponent extends TypedDialog<
  {
    groupId: number;
  },
  boolean
> {
  public _passcode?: string;
  public _passcodeNotMatched = false;
  public _isJoining = false;
  public _showPasscode = false;

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _toastService: ToastService
  ) {
    super();
  }

  public _onOKClick(): void {
    this._isJoining = true;

    this._readingGroupsAPIService
      .join(this._data.groupId, this._passcode)
      .pipe(finalize(() => (this._isJoining = false)))
      .subscribe({
        next: () => {
          this._dialogRef.close(true);
        },
        error: (error: APIError) => {
          this._passcodeNotMatched =
            error.code === 400 && error.rawMessage === 'Passcode not match';

          if (!this._passcodeNotMatched) {
            this._toastService.openWarning(error.message);
          }
        },
      });
  }
}
