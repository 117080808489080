import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, catchError, throwError } from 'rxjs';
import { ExitedReadingGroup, PopularReadingGroup } from 'shared/models';

import { ReadingGroup, ReadingGroupType } from 'shared/models';

import { APIError, catchAndRethrowAPIError } from './api-error';
import { QueryResult } from './query-result';

const CREATE_GROUP_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '해당 전자책에 대한 권한이 없습니다.',
  'Max group exceeded': '생성 가능한 최대 그룹 수를 초과하였습니다.',
  'Invalid group name': '그룹명이 올바르지 않습니다.',
  'Invalid group description': '그룹 소개가 올바르지 않습니다.',
  'Invalid group max_member_count': '그룹 최대 인원 수가 올바르지 않습니다',
  'Invalid group passcode': '그룹 비밀번호가 올바르지 않습니다.',
};

const UPDATE_GROUP_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '그룹을 수정할 수 있는 권한이 없습니다.',
  'Max member count less than current member count':
    '그룹 최대 인원 수가 현재 가입 인원 수보다 적습니다.',
  'Invalid group name': '그룹명이 올바르지 않습니다.',
  'Invalid group description': '그룹 소개가 올바르지 않습니다.',
  'Invalid group max_member_count': '그룹 최대 인원 수가 올바르지 않습니다.',
  'Invalid group passcode': '그룹 비밀번호가 올바르지 않습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
};

const EXIT_GROUP_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
};

const JOIN_GROUP_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden:
    '해당 전자책 소장/대여 또는 구독 라이브러리 구독 시, 가입 및 참여가 가능합니다.',
  'Passcode not match': '비밀번호가 일치하지 않습니다.',
  'No seats left': '그룹 최대 인원수를 초과하였습니다.',
  'Already joined': '이미 가입한 그룹입니다.',
  'Unable to join a group': '가입할 수 없습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
};

const INVITE_GROUP_MEMBER_ERROR_MSG_MAP = {
  Unauthorized: '로그인이 필요합니다',
  Forbidden: '해당 작업을 수행할 권한이 없습니다.',
  'Group not found': '그룹이 존재하지 않습니다.',
  'User not found': '해당 사용자가 존재하지 않습니다.',
  'Already invited': '이미 초대된 사용자입니다.',
  'Already joined': '이미 가입한 사용자입니다.',
  'Kicked member cannot be invited': '해당 그룹에서 내보내기된 사용자입니다.',
};

@Injectable({
  providedIn: 'root',
})
export class ReadingGroupsAPIService {
  constructor(private _http: HttpClient) {}

  create(group: {
    content_id: string;
    name: string;
    description: string;
    max_member_count: number;
    type: ReadingGroupType;
    passcode?: string;
  }): Observable<void> {
    return this._http
      .post<void>(`/api/v1/groups`, group, {
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          return throwError(() =>
            APIError.create(error, CREATE_GROUP_ERROR_MSG_MAP)
          );
        })
      );
  }

  update(
    groupId: number,
    group: {
      name: string;
      description: string;
      max_member_count: number;
      passcode?: string;
    }
  ): Observable<void> {
    return this._http
      .put<void>(`/api/v1/groups/${groupId}`, group, {
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          return throwError(() =>
            APIError.create(error, UPDATE_GROUP_ERROR_MSG_MAP)
          );
        })
      );
  }

  getById(groupId: number): Observable<ReadingGroup> {
    return this._http.get<ReadingGroup>(`/api/v1/groups/${groupId}`, {
      withCredentials: true,
    });
  }

  getOthersGroupList(
    offset: number,
    limit: number,
    user_id?: string
  ): Observable<QueryResult<ReadingGroup>> {
    return this._http.get<QueryResult<ReadingGroup>>(
      `/api/v1/users/${user_id}/groups`,
      {
        params: {
          offset,
          limit,
        },
        withCredentials: true,
      }
    );
  }

  getExitedGroupList(
    offset: number,
    limit: number,
    order_by: string
  ): Observable<QueryResult<ExitedReadingGroup>> {
    return this._http.get<QueryResult<ExitedReadingGroup>>(
      `/api/v1/groups/exited`,
      {
        params: {
          offset,
          limit,
          order_by,
        },
        withCredentials: true,
      }
    );
  }

  deleteExitedGroup(
    copy: number,
    group_id: number
  ): Observable<{ highlight_count: number; memo_count: number }> {
    return this._http.post<{ highlight_count: number; memo_count: number }>(
      `/api/v1/groups/${group_id}/hide`,
      {
        copy,
      },
      {
        withCredentials: true,
      }
    );
  }

  getJoined(
    offset: number = 0,
    limit: number = 100,
    content_id: string | undefined = '',
    order_by:
      | 'joined_at'
      | 'member_count'
      | 'highlight_count'
      | 'memo_count' = 'joined_at'
  ): Observable<
    QueryResult<ReadingGroup> & {
      owned_group_count: number;
    }
  > {
    return this._http
      .get<
        QueryResult<ReadingGroup> & {
          owned_group_count: number;
        }
      >(`/api/v1/groups`, {
        params: {
          content_id,
          offset,
          limit,
          order_by,
        },
        withCredentials: true,
      })
      .pipe(catchAndRethrowAPIError());
  }

  join(groupId: number, passcode?: string): Observable<void> {
    return this._http
      .post<void>(
        `/api/v1/groups/${groupId}/join`,
        passcode ? { passcode } : null,
        {
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(JOIN_GROUP_ERROR_MSG_MAP));
  }

  exit(groupId: number, copy: boolean): Observable<void> {
    return this._http
      .post<void>(
        `/api/v1/groups/${groupId}/exit`,
        {
          copy: copy ? 1 : 0,
        },
        {
          withCredentials: true,
        }
      )
      .pipe(catchAndRethrowAPIError(EXIT_GROUP_ERROR_MSG_MAP));
  }

  invite(groupId: number, member_id: string): Observable<void> {
    return this._http
      .post<void>(
        `/api/v1/groups/${groupId}/invite`,
        { member_id },
        { withCredentials: true }
      )
      .pipe(catchAndRethrowAPIError(INVITE_GROUP_MEMBER_ERROR_MSG_MAP));
  }

  // 메인 페이지에서 여럿이 모여서 읽기 딱 좋은 함께읽기 그룹 호출 API
  getPopular(
    offset: number,
    limit: number,
    pinned_only: number
  ): Observable<QueryResult<PopularReadingGroup>> {
    return this._http.get<QueryResult<PopularReadingGroup>>(
      `/api/v1/groups/popular`,
      {
        params: {
          offset,
          limit,
          pinned_only,
        },
        withCredentials: true,
      }
    );
  }
}
