import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BookType, PagingMode } from '@bukio/viewer';

import { EventBusService } from '../../services/event-bus.service';
import { BookService } from '../../services/book.service';

@Component({
  selector: 'viewer-page-number',
  templateUrl: './page-number.component.html',
  styleUrls: ['./page-number.component.scss'],
})
export class PageNumberComponent implements OnInit, OnDestroy {
  private _unsubscriber: Subject<void> = new Subject<void>();

  @HostBinding('class.disabled') disabled = false;

  public label = '';

  public pagingMode?: string;

  constructor(
    private eventBusService: EventBusService,
    private bookService: BookService
  ) {}

  ngOnInit(): void {
    this.eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        const pagingMode =
          data.settings.pagingMode.value === PagingMode.Scroll
            ? 'scroll'
            : 'page';
        if (this.pagingMode !== pagingMode) {
          this.pagingMode = pagingMode;
          this.disabled = this.pagingMode !== 'page';
        }
      });

    this.eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(
        takeUntil(this._unsubscriber),
        filter(() => {
          return this.pagingMode === 'page';
        })
      )
      .subscribe((data) => {
        if (!this.bookService.book) {
          this.label = '';
          return;
        }

        let percent: number | null = null;

        switch (this.bookService.book.meta.type) {
          case BookType.Buk:
            percent = this.bookService.book.getPagePercent(
              data.address.iid ?? this.bookService.book.items[0].iid,
              data.page,
              data.pageCount
            );
            break;
          case BookType.Document:
            percent = data.page / data.pageCount;
            break;
        }

        if (percent != null) {
          this.label = (percent * 100).toFixed(0) + '%';
        } else {
          this.label = '';
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }
}
