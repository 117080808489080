import {
  Component,
  OnInit,
  HostListener,
  Input,
  ElementRef,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Direction, PagingMode } from '@bukio/viewer';

import { CrossBrowsing } from '../../constants/cross-browsing';
import { EventBusService } from '../../services/event-bus.service';
import { PageChangeAvailabilityService } from '../../services/page-change-availability.service';

@Component({
  selector: 'viewer-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss'],
})
export class PagerComponent implements OnInit, OnDestroy {
  @Input() direction?: 'left' | 'right';

  @HostBinding('class.is-hidden') hidden = false;
  @HostBinding('class.is-disabled') disabled = false;

  private unsubscriber = new Subject<void>();

  private _direction!: Direction;

  private _isKeyboardShowing = false;

  constructor(
    private pageChangeAvailabilityService: PageChangeAvailabilityService,
    private element: ElementRef,
    private eventBusService: EventBusService
  ) {}

  @HostListener('touchstart') onTouchStart(): void {
    this._isKeyboardShowing =
      CrossBrowsing.keyboardChangesViewport &&
      document.activeElement?.tagName === 'INPUT';
  }

  @HostListener('click') onClick(): void {
    if (this.disabled) {
      return;
    }

    if (this._isKeyboardShowing) {
      this._isKeyboardShowing = false;
      setTimeout(() => {
        this.eventBusService.fire('PagerComponent:pageChange', {
          direction: this._direction,
        });
      }, 500);
    } else {
      this.eventBusService.fire('PagerComponent:pageChange', {
        direction: this._direction,
      });
    }
  }

  ngOnInit(): void {
    this.element.nativeElement.classList.add(this.direction);
    this.hidden = CrossBrowsing.touchDevice;

    switch (this.direction) {
      case 'left':
        this._direction = Direction.Prev;
        break;
      case 'right':
        this._direction = Direction.Next;
        break;
      default:
        break;
    }

    if (this.direction === 'left' || this.direction === 'right') {
      if (!CrossBrowsing.touchDevice) {
        this.eventBusService
          .on('ContentsComponent:settingsChanged')
          .pipe(
            takeUntil(this.unsubscriber),
            map((data) => data.settings.pagingMode.value)
          )
          .subscribe((pagingMode) => {
            this.hidden = pagingMode !== PagingMode.Page;
          });
      }

      this.pageChangeAvailabilityService
        .on('change')
        .pipe(
          takeUntil(this.unsubscriber),
          map((availability) =>
            this.direction === 'left' ? availability.prev : availability.next
          )
        )
        .subscribe((isAvailable) => {
          this.disabled = !isAvailable;
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
