import { Component, Input } from '@angular/core';

export interface ReadingGroupMemberView {
  nickname: string;
  avatar_image_url: string;
  is_blocked: number; // boolean
  is_me: number; // boolean
}

@Component({
  selector: 'sui-reading-group-member',
  templateUrl: './reading-group-member.component.html',
  styleUrl: './reading-group-member.component.scss',
})
export class ReadingGroupMemberComponent {
  @Input() member!: ReadingGroupMemberView;
  @Input() isOnline?: boolean;
}
