import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import * as Sentry from '@sentry/angular';

import { environment } from './environments/environment';

import { AppModule } from './app/app.module';

window.bukApp = {} as any;

(window as any).isCustomDomain = false;

const SENTRY_COMMON_IGNORE_ERRORS = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
];

Sentry.init({
  dsn: environment.production
    ? 'https://9fee6638171f2399b685dcadf05a6354@o1077493.ingest.us.sentry.io/4507496389410816'
    : undefined,
  release: 'appbukio-' + environment.appVersion,
  integrations: [],
  ignoreErrors: [
    'Http failure response for',
    'Handled unknown error',
    ...SENTRY_COMMON_IGNORE_ERRORS,
  ],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
