import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { parseBukURL } from '@bukio/viewer';

import { TypedDialog } from 'shared/ui';

import { BukViewerSearchService } from '../../services/buk-viewer-search.service';
import { EventBusService } from '../../services/event-bus.service';

import { Book } from '../../models/book.model';

import { removeReplacementCharacter } from '../../utils/misc';

interface SearchResultItemView {
  iid: string;
  title: string;
  url: string;
}

interface SearchResultGroup {
  itemIndex?: number;
  items: SearchResultItemView[];
}

@Component({
  selector: 'viewer-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class SearchDialogComponent
  extends TypedDialog<{ book: Book; keyword?: string }, void>
  implements OnInit
{
  @ViewChild('input') private _inputElem!: ElementRef<HTMLInputElement>;

  public _currSearchValue = '';
  public _results: SearchResultGroup[] = [];
  public _resultCount = 0;
  public _isLoading = false;

  public _keyword = '';

  constructor(
    private bukViewerSearchService: BukViewerSearchService,
    private eventBusService: EventBusService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this._data.keyword) {
      this._keyword = this._data.keyword;
    } else {
      this._dialogRef.afterOpened().subscribe(() => {
        this._inputElem.nativeElement.focus();
      });
    }
  }

  _onCloseButtonClick(): void {
    this._close();
  }

  /* search result */
  _onResultClick(
    result: SearchResultItemView,
    indexPath: [number, number]
  ): void {
    this._close();

    setTimeout(() => {
      this.eventBusService.fire('SearchDialogComponent:resultClick', {
        url: result.url,
      });
    }, 300);
  }

  _onSearch(): void {
    this._currSearchValue = this._keyword.trim();
    this._isLoading = this._currSearchValue.length > 0;

    this._bookKeywordChanged();
  }

  _onClearSearchKeywordButtonClick(): void {
    this._keyword = '';
    this._onSearch();
    this._inputElem.nativeElement.focus();
  }

  _onSarchBarKeydown(event: KeyboardEvent): void {
    event.stopPropagation();
  }

  private _bookKeywordChanged(): void {
    const requestedKeyword = this._currSearchValue;

    this._searchUsingViewer(this._currSearchValue)
      .pipe(filter(() => requestedKeyword === this._currSearchValue))
      .subscribe(
        (results) => {
          this._isLoading = false;
          this._resultCount = results.length;

          const group = results.reduce((_group, result) => {
            if (!_group[result.iid]) {
              _group[result.iid] = [];
            }

            _group[result.iid].push(result);

            return _group;
          }, {} as { [iid: string]: SearchResultItemView[] });

          this._results = Object.entries(group)
            .map<SearchResultGroup>(([iid, items]) => {
              return {
                items,
                itemIndex: this._data.book.getIndexOfItem(iid),
              };
            })
            .sort((g1, g2) => g1.itemIndex! - g2.itemIndex!);
        },
        () => {
          this._resultCount = 0;
          this._isLoading = false;
          this._results = [];
        }
      );
  }

  private _searchUsingViewer(
    keyword: string
  ): Observable<SearchResultItemView[]> {
    return this.bukViewerSearchService.search(keyword).pipe(
      map((results) => {
        return results.reduce((result, _result) => {
          const address = parseBukURL(_result.url);

          if (this._data.book.canReadItem(address.iid)) {
            result.push({
              iid: address.iid!,
              title: removeReplacementCharacter(_result.text),
              url: _result.url,
            });
          }

          return result;
        }, [] as SearchResultItemView[]);
      })
    );
  }

  private _close(): void {
    this._dialogRef.close();
  }

  // private _resetSearchResult(): void {
  //   this._searchBarComp.setValue('');
  //   this._currSearchValue = '';
  //   this._results = [];
  // }
}
