import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { roundTo } from 'shared/utils';
import {
  PageVisibilityState,
  PageVisibilityStateService,
} from './page-visibility-state.service';

interface BookMetrics {
  bid: string;
  iid: string;
  page: number;
  pageCount: number;
}
type RawBookMetrics = [string, string, number, number];
type MetricsContext = 'book';

function roundTo7(num: number): number {
  return roundTo(7, num);
}

function getRangeFromPageInfo(
  page: number,
  pageCount: number
): [number, number] {
  return [roundTo7((page - 1) / pageCount), roundTo7(page / pageCount)];
}

const INTERVAL_TIME = 3500;

@Injectable()
export class IntervalLoggerService implements OnDestroy {
  private _docVisibilityEvtSub: Subscription;

  private _intervalId?: number;
  private _isStarted = false;

  private _bookMetrics?: RawBookMetrics[];

  constructor(
    private _http: HttpClient,
    private _ngZone: NgZone,
    private _pageVisibilityStateService: PageVisibilityStateService
  ) {
    this._docVisibilityEvtSub =
      this._pageVisibilityStateService.state$.subscribe((state) =>
        this._onPageVisibilityChange(state)
      );
  }

  ngOnDestroy(): void {
    this._docVisibilityEvtSub.unsubscribe();
  }

  setBookMetrics(metrics: BookMetrics[] | BookMetrics): void {
    if (!Array.isArray(metrics)) {
      metrics = [metrics];
    }

    this._bookMetrics = (metrics as BookMetrics[]).map((m) => {
      const range = getRangeFromPageInfo(m.page, m.pageCount);
      return [m.bid, m.iid, range[0], range[1]];
    });
  }

  start(): void {
    this._isStarted = true;
    this._start();
  }

  stop(): void {
    this._isStarted = false;
    this._stop();
  }

  private _start(): void {
    if (this._intervalId != null) {
      return;
    }

    this._ngZone.runOutsideAngular(() => {
      this._intervalId = window.setInterval(() => {
        this._sendBookMetrics();
      }, INTERVAL_TIME);
    });
  }

  private _stop(): void {
    if (this._intervalId == null) {
      return;
    }

    window.clearInterval(this._intervalId);
    this._intervalId = undefined;
  }

  private _onPageVisibilityChange(state: PageVisibilityState): void {
    if (state === 'visible') {
      this._isStarted && this._start();
    } else if (state === 'hidden') {
      this._stop();
    }
  }

  private _sendBookMetrics(): void {
    if (!this._bookMetrics) {
      return;
    }

    return this._sendMetrics('book', this._bookMetrics);
  }

  private _sendMetrics(context: MetricsContext, metrics: unknown): void {
    this._http
      .post(`/api/v1/metrics/${context}`, metrics, { withCredentials: true })
      .subscribe(() => {
        // console.log(`metrics sent at ${new Date()}`, context, metrics);
      });
  }
}
