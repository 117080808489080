import {
  Component,
  OnInit,
  ElementRef,
  ViewEncapsulation,
  OnDestroy,
  HostBinding,
  ViewChild,
  Inject,
  Optional,
  EventEmitter,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { merge, Subject } from 'rxjs';
import { takeUntil, map, pairwise, filter } from 'rxjs/operators';

import { Theme } from '@bukio/viewer';

import {
  DialogService,
  SUI_DIALOG_DEFAULT_OPTIONS,
  ToastService,
} from 'shared/ui';
import { SharedPageTitleService } from 'shared/services';

import { VIEWER_ENVIRONMENT, ViewerModuleEnvironment } from '../viewer.module';

import { logAffiliationToCookie } from '../utils/address';
import { isViewerEmbedded } from '../utils/misc';

import { Book } from '../models/book.model';

import { IntervalLoggerService } from '../services/interval-logger.service';
import { EventBusService } from '../services/event-bus.service';
import { BukHistoryService } from '../services/buk-history.service';
import { UIStateService } from '../services/ui-state.service';
import { AnnotationsV2Service } from '../services/annotations-v2.service';
import { ReadingGroupsService } from '../services/reading-groups.service';

import { SidebarContainerComponent } from '../components/sidebar-container/sidebar-container.component';
import { MemoPopupComponent } from '../components/memo-popup/memo-popup.component';
import { RightPanelComponent } from '../components/right-panel/right-panel.component';
import { OnlineReadingMemberService } from '../services/online-reading-member.service';
import { StaticMemoPopupComponent } from '../components/static-memo-popup/static-memo-popup.component';

@Component({
  selector: 'viewer-root',
  templateUrl: './buk.component.html',
  styleUrls: ['./buk.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    DialogService,
    UIStateService,
    IntervalLoggerService,
    AnnotationsV2Service,
    ReadingGroupsService,
    OnlineReadingMemberService,
    {
      provide: SUI_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        responsiveBreakpoint: 768,
        dimmed: true,
      },
    },
  ],
})
export class BukComponent implements OnInit, OnDestroy {
  @HostBinding('attr.data-theme') _theme?: Theme;
  @ViewChild('verticalSidebarContainer', { read: SidebarContainerComponent })
  _verticalSidebarContainerComp!: SidebarContainerComponent;

  @ViewChild(MemoPopupComponent, { static: true })
  private _memoPopupComp!: MemoPopupComponent;
  @ViewChild(StaticMemoPopupComponent, { static: true })
  private _staticMemoPopupComp!: StaticMemoPopupComponent;
  @ViewChild(RightPanelComponent, { static: true })
  private _rightPanelComp!: RightPanelComponent;

  @Output() menuOpenedChange = new EventEmitter<boolean>();
  @Output() bookLoad = new EventEmitter<Book>();

  private unsubscribeBroadcast: Subject<void> = new Subject<void>();
  private book?: Book;

  private _isMenuOpened = false;
  private _embeddedSlideMode = false;
  private _isSidebarOpened = false;

  constructor(
    private element: ElementRef,
    private router: Router,
    private eventBusService: EventBusService,
    private intervalLoggerService: IntervalLoggerService,
    private bukHistoryService: BukHistoryService,
    private uiStateService: UIStateService,
    @Inject(VIEWER_ENVIRONMENT) private _environment: ViewerModuleEnvironment,
    private _toastService: ToastService,
    _onlineReadingMemberService: OnlineReadingMemberService,
    @Optional() private _pageTitleService?: SharedPageTitleService
  ) {}

  ngOnInit(): void {
    document.body.classList.add('viewer-fixed');

    this._environment.production && this.intervalLoggerService.start();

    if (isViewerEmbedded()) {
      this.element.nativeElement.style.border = '1px solid #e0e0e0';
    }

    merge(
      this.eventBusService.on('ContentsComponent:bookLoadError'),
      this.eventBusService.on('ContentsComponent:itemLoadError')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._toastService
          .openWarning('책 주소가 유효하지 않습니다.', '홈으로')
          .onAction()
          .subscribe(() => {
            this.router.navigateByUrl('/');
          });
      });

    this.eventBusService
      .on('ContentsComponent:settingsChanged')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        map((data) => data.settings.theme.value)
      )
      .subscribe((theme) => {
        this._theme = theme;
      });

    this.eventBusService
      .on('ContentsComponent:bookLoad')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(({ book }) => {
        this.book = book;

        this._embeddedSlideMode = isViewerEmbedded() && book.isHorizontalPDF;

        this._pageTitleService?.setPageTitle(book.meta.title);

        if (this.book.permissionReferer) {
          logAffiliationToCookie(this.book.permissionReferer);
        }

        this.bookLoad.emit(book);
      });

    this.eventBusService
      .on('ContentsComponent:pageClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        if (this._isSidebarOpened) {
          this._rightPanelComp.memoListComp?.close();
        } else if (
          this._memoPopupComp.isOpened ||
          this._staticMemoPopupComp.isOpened
        ) {
          this._memoPopupComp.close();
          this._staticMemoPopupComp.close();
        } else if (this._isMenuOpened) {
          this._closeMenu();
        } else {
          !this._embeddedSlideMode && this._openMenu();
        }
      });

    this.eventBusService
      .on('ContentsComponent:addressChange')
      .pipe(
        takeUntil(this.unsubscribeBroadcast),
        map((data) => data.address),
        pairwise(),
        filter(([addr1, addr2]) => {
          return (
            addr1.bid !== addr2.bid ||
            addr1.iid !== addr2.iid ||
            addr1.anchor !== addr2.anchor
          );
        })
      )
      .subscribe(() => {
        this._closeMenu();
      });

    merge(
      this.eventBusService.on('ContentsComponent:settingsChanged'),
      this.eventBusService.on('ContentsComponent:itemLoad'),
      this.eventBusService.on('ContentsComponent:highlightClick'),
      this.eventBusService.on('ContentsComponent:zoomScaleChange'),
      this.eventBusService.on('ContentsComponent:select')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._rightPanelComp.memoListComp?.close();
        this._memoPopupComp.close();
        this._staticMemoPopupComp.close();
      });

    merge(
      this.eventBusService.on('ContentsComponent:pageClick2'),
      this.eventBusService.on('ContentsComponent:pdfPageScrolled')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._memoPopupComp.close();
        this._staticMemoPopupComp.close();
      });

    merge(
      this.eventBusService.on(
        'AnnotationSettingsFabComponent:anotationsButtonClick'
      ),
      this.eventBusService.on('ContentsComponent:memoIndicatorClick'),
      this.eventBusService.on(
        'ContentsComponent:commentaryPreviewMemoIndicatorClick'
      ),
      this.eventBusService.on('ContentsComponent:select')
    )
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._closeMenu();
      });

    this.eventBusService
      .on('ContentsComponent:commentaryPreviewMemoIndicatorClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._rightPanelComp.memoListComp?.close();
        this._memoPopupComp.close();
      });

    this.eventBusService
      .on('ContentsComponent:memoIndicatorClick')
      .pipe(takeUntil(this.unsubscribeBroadcast))
      .subscribe(() => {
        this._staticMemoPopupComp.close();
      });
  }

  ngOnDestroy(): void {
    document.body.classList.remove('viewer-fixed');

    this.intervalLoggerService.stop();

    this.unsubscribeBroadcast.next();
    this.unsubscribeBroadcast.complete();
  }

  _onVerticalSidebarOpened(): void {
    this._isMenuOpened = true;
    this.menuOpenedChange.emit(true);
  }

  _onVerticalSidebarClosed(): void {
    this._isMenuOpened = false;
    this.menuOpenedChange.emit(false);
  }

  _onHorizontalSidebarOpened(): void {
    this._isSidebarOpened = true;
  }

  _onHorizontalSidebarClosed(): void {
    this._isSidebarOpened = false;
  }

  private _openMenu(): void {
    if (this._isMenuOpened || this._embeddedSlideMode) {
      return;
    }

    this._verticalSidebarContainerComp.open(['top', 'bottom']);
  }

  private _closeMenu(): void {
    if (!this._isMenuOpened) {
      return;
    }

    this._verticalSidebarContainerComp.close(['top', 'bottom']);
  }
}
