import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Observable, finalize } from 'rxjs';

import {
  ReadingGroup,
  ReadingGroupJoinStatus,
  ReadingGroupType,
} from 'shared/models';
import {
  APIError,
  BookReadingGroupsAPIService,
  ReadingGroupsAPIService,
  QueryResult,
} from 'shared/services';

import { ReadingGroupPasscodeDialogComponent } from '../../dialogs/reading-group-passcode-dialog/reading-group-passcode-dialog.component';
import { AlertService, ToastService } from 'shared/ui';

enum Tab {
  joined,
  all,
}

enum OrderType {
  memberCount = 'member_count',
  highlightCount = 'highlight_count',
  memoCount = 'memo_count',
}

@Component({
  selector: 'lib-reading-group-selection',
  templateUrl: './reading-group-selection.component.html',
  styleUrl: './reading-group-selection.component.scss',
})
export class ReadingGroupSelectionComponent implements OnInit {
  @ViewChild('groupList') private _groupListElem!: ElementRef<HTMLDivElement>;

  @Input() contentId!: string;

  @Output() createGroupButtonClick = new EventEmitter<void>();
  @Output() groupDetailsButtonClick = new EventEmitter<ReadingGroup>();
  @Output() enterGroupButtonClick = new EventEmitter<number>();

  public _Tab = Tab;
  public readonly _tabs: [Tab, string][] = [
    [Tab.joined, '함께읽기가 가능한 그룹'],
    [Tab.all, '모든 그룹'],
  ];
  public _selectedTab = Tab.joined;

  public _JoinStatus = ReadingGroupJoinStatus;

  public _myGroups?: ReadingGroup[];
  public _myGroupsTotal?: number;

  public _allGroups?: ReadingGroup[];
  public _allGroupsTotal?: number;

  private readonly _limit = 30;
  private _isLoading = false;
  public _searchKeyword?: string;

  public _showPublicGroupOnly = false;
  public _orderBy = OrderType.memberCount;
  public readonly _orderByOptions = [
    [OrderType.memberCount, '그룹원 많은 순'],
    [OrderType.highlightCount, '공유 하이라이트 많은 순'],
    [OrderType.memoCount, '공유 메모 많은 순'],
  ];

  private _ownedGroupCount?: number;

  private _isJoining = false;

  constructor(
    private _readingGroupsAPIService: ReadingGroupsAPIService,
    private _bookReadingGroupsAPIService: BookReadingGroupsAPIService,
    private _alertService: AlertService,
    private _toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._loadMyGroups(true, false);
    this._loadAllGroups(true);
  }

  public get _groupsTotal(): number | undefined {
    return this._selectedTab === Tab.joined
      ? this._myGroupsTotal
      : this._allGroupsTotal;
  }

  public get _groups(): ReadingGroup[] | undefined {
    return this._selectedTab === Tab.joined ? this._myGroups : this._allGroups;
  }

  public set _groups(groups: ReadingGroup[] | undefined) {
    if (this._selectedTab === Tab.joined) {
      this._myGroups = groups;
    } else {
      this._allGroups = groups;
    }
  }

  private get _listEnded(): boolean {
    return (
      this._groupsTotal != null && this._groupsTotal === this._groups?.length
    );
  }

  private _changeTab(tab: Tab): void {
    this._orderBy = OrderType.memberCount;
    this._showPublicGroupOnly = false;
    this._myGroups = undefined;
    this._allGroups = undefined;
    this._searchKeyword = undefined;
    this._selectedTab = tab;
    this._loadGroups(true);
  }

  updateGroup(updatedGroup: ReadingGroup): void {
    [this._allGroups, this._myGroups].forEach((groups) => {
      const group = groups?.find((g) => g.id === updatedGroup.id);
      if (!group) {
        return;
      }

      group.name = updatedGroup.name;
    });
  }

  refreshMyGroups(): void {
    this._myGroupsTotal = undefined;
    this._loadMyGroups(true, true);
  }

  resetTabs(): void {
    this._changeTab(Tab.joined);
    this._myGroupsTotal = undefined;
    this._allGroupsTotal = undefined;
    this._loadAllGroups(true);
  }

  _onSelectTab(tab: Tab): void {
    if (this._selectedTab === tab) {
      return;
    }

    this._changeTab(tab);
  }

  _onGroupDetailsButtonClick(index: number): void {
    const group = this._groups?.[index];

    if (!group) {
      return;
    }

    this.groupDetailsButtonClick.emit(group);
  }

  _onEnterGroupButtonClick(groupId: number): void {
    this.enterGroupButtonClick.emit(groupId);
  }

  _onJoinGroupButtonClick(index: number): void {
    const group = this._groups?.[index];

    if (!group) {
      return;
    }

    if (
      group.type === ReadingGroupType.private &&
      group.status !== ReadingGroupJoinStatus.invited
    ) {
      this._openPasscodeDialog(group.id);
      return;
    }

    if (this._isJoining) {
      return;
    }

    this._isJoining = true;

    this._readingGroupsAPIService
      .join(group.id)
      .pipe(finalize(() => (this._isJoining = false)))
      .subscribe({
        next: () => {
          this._onGroupJoined(group.id);
        },
        error: (error: APIError) => {
          this._toastService.openWarning(error.message);
        },
      });
  }

  _onCreateGroupButtonClick(): void {
    if (this._ownedGroupCount != null && this._ownedGroupCount >= 5) {
      this._toastService.open(
        '책 한 권에 한 사람당 최대 5개의 그룹을 만들 수 있습니다.'
      );
      return;
    }

    this.createGroupButtonClick.emit();
  }

  _onPasscodeDialogResult(joined: boolean, groupId: number): void {
    joined && this._onGroupJoined(groupId);
  }

  _onSearchOptionsChange(): void {
    this._loadGroups(true);
  }

  _onSearchBarKeyDown(event: KeyboardEvent): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const key = 'which' in event ? event.which : (event as any).keyCode;
    const isComposing = key === 229;

    if (event.key === 'Enter' && !isComposing) {
      (event.target as HTMLInputElement).blur();
      this._onSearchOptionsChange();
    }
  }

  _onClearSearchKeywordButtonClick(): void {
    this._searchKeyword = '';
    this._onSearchOptionsChange();
  }

  _onScrollEnd(): void {
    this._loadGroups(false);
  }

  private _loadGroups(reset: boolean): void {
    if (!reset && (this._isLoading || this._listEnded)) {
      return;
    }

    this._selectedTab === Tab.joined
      ? this._loadMyGroups(reset, false)
      : this._loadAllGroups(reset);
  }

  private _loadMyGroups(reset: boolean, keepScroll: boolean): void {
    this._isLoading = true;

    this._getJoinedReadingGroups(reset ? 0 : this._groups?.length).subscribe({
      next: (response) => {
        this._isLoading = false;
        if (this._myGroupsTotal == null) {
          this._myGroupsTotal = response.total;
        }
        this._myGroups = (
          response.offset === 0 || !this._groups ? [] : this._groups
        ).concat(response.items);

        this._ownedGroupCount = response.owned_group_count;

        if (reset && !keepScroll) {
          this._groupListElem.nativeElement.scrollTop = 0;
        }
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  private _loadAllGroups(reset: boolean): void {
    this._isLoading = true;

    this._getBookReadingGroups(reset ? 0 : this._groups?.length).subscribe({
      next: (response) => {
        this._isLoading = false;
        if (this._allGroupsTotal == null) {
          this._allGroupsTotal = response.total;
        }
        this._allGroups = (
          response.offset === 0 || !this._groups ? [] : this._groups
        ).concat(response.items);

        if (reset) {
          this._groupListElem.nativeElement.scrollTop = 0;
        }
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }

  private _getJoinedReadingGroups(offset: number = 0): Observable<
    QueryResult<ReadingGroup> & {
      owned_group_count: number;
    }
  > {
    return this._readingGroupsAPIService.getJoined(
      offset,
      this._limit,
      this.contentId,
      'joined_at'
    );
  }

  private _getBookReadingGroups(
    offset: number = 0
  ): Observable<QueryResult<ReadingGroup>> {
    return this._bookReadingGroupsAPIService.get(
      this.contentId,
      offset,
      this._limit,
      this._searchKeyword,
      this._orderBy,
      this._showPublicGroupOnly
    );
  }

  private _onGroupJoined(groupId: number): void {
    if (!this._allGroups) {
      return;
    }

    const group = this._allGroups.find((group) => group.id === groupId);

    if (!group) {
      return;
    }

    group.status = ReadingGroupJoinStatus.joined;
    group.member_count++;

    this.refreshMyGroups();

    this._toastService.open('가입이 완료되었습니다.');
  }

  private _openPasscodeDialog(groupId: number): void {
    const dialogRef = this._alertService.open(
      ReadingGroupPasscodeDialogComponent,
      {
        data: {
          groupId,
        },
      }
    );

    dialogRef.afterClosed().subscribe((joined) => {
      joined !== undefined && this._onPasscodeDialogResult(joined, groupId);
    });
  }
}
