import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';

@Component({
  templateUrl: './settings-page.component.html',
  styleUrl: './settings-page.component.scss',
})
export class SettingsPageComponent implements OnInit, OnDestroy {
  public _pageTitle = '설정';
  private _routerEventSubscription!: Subscription;

  constructor(private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit(): void {
    this._routerEventSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this._route.snapshot.firstChild?.data.title) {
          this._pageTitle = this._route.snapshot.firstChild?.data.title;
        }
      });
  }

  _onBackButtonClick(): void {
    history.back();
  }

  ngOnDestroy(): void {
    this._routerEventSubscription.unsubscribe();
  }
}
