import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsAPIService {
  constructor(private _http: HttpClient) {}

  checkNew(): Observable<boolean> {
    return this._http
      .head<void>(`/api/v1/notifications`, {
        withCredentials: true,
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }
}
