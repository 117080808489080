import { Component, OnInit } from '@angular/core';
import { BookshelfAPIService } from 'shared/services';

@Component({
  selector: 'lib-my-bookshelf-all-page',
  templateUrl: './my-bookshelf-all-page.component.html',
  styleUrl: './my-bookshelf-all-page.component.scss',
})
export class MyBookshelfAllPageComponent implements OnInit {
  public _ebookTotal!: number;
  public _libraryTotal!: number;

  constructor(private _bookshelfAPIService: BookshelfAPIService) {}

  ngOnInit(): void {
    this._bookshelfAPIService.getOwnedBooks(0, 6, true).subscribe((result) => {
      this._ebookTotal = result.total;
    });

    this._bookshelfAPIService.getMyOwnLibraryList(0, 6).subscribe((result) => {
      this._libraryTotal = result.total;
    });
  }
}
