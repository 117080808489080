import { Event, NavigationEnd, NavigationStart } from '@angular/router';
import { Direction } from '@bukio/viewer';
import { fromEvent, Observable } from 'rxjs';
import { HasEventTargetAddRemove } from 'rxjs/internal/observable/fromEvent';
import { filter, map, pairwise, timestamp } from 'rxjs/operators';
import { NEXT_KEYS, PREVIOUS_KEYS } from '../constants/keyboard';

type NavigationChangeResult = {
  isTriggeredByPopstate?: boolean;
  url?: string;
};

export function scanNavigationChangeResult(
  acc: NavigationChangeResult,
  event: Event
): NavigationChangeResult {
  if (event instanceof NavigationStart) {
    acc.isTriggeredByPopstate = event.navigationTrigger === 'popstate';
    acc.url = undefined;
  } else if (event instanceof NavigationEnd) {
    acc.url = event.urlAfterRedirects;
  }

  return acc;
}

export function fromKeydownEvent(
  target: HasEventTargetAddRemove<KeyboardEvent>
): Observable<KeyboardEvent> {
  return fromEvent(target, 'keydown');
}

export function fromPageChangeKeydownEvent(
  target: HasEventTargetAddRemove<KeyboardEvent>
): Observable<{
  direction: Direction;
  event: KeyboardEvent;
}> {
  return fromKeydownEvent(target).pipe(
    map((event) => {
      let direction: Direction;

      if (NEXT_KEYS.indexOf(event.key) !== -1) {
        direction = Direction.Next;
      } else if (PREVIOUS_KEYS.indexOf(event.key) !== -1) {
        direction = Direction.Prev;
      }

      return {
        event,
        direction: direction!, // FIXME: direction 이 undefined일 때 아래 filter 오퍼레이터에서 거르지만 타입 에러가 발생하는 문제를 해결하기 위해 non-null assertion 오퍼레이터 사용
      };
    }),
    filter((result) => result.direction !== undefined)
  );
}

export function fromUserScrollEvent(
  target:
    | HasEventTargetAddRemove<UIEvent>
    | ArrayLike<HasEventTargetAddRemove<UIEvent>>
): Observable<UIEvent> {
  return fromEvent(target, 'scroll', { passive: true }).pipe(
    timestamp(), //
    pairwise(), //
    filter(([v1, v2]) => v2.timestamp - v1.timestamp < 150), // 프로그램에 의한 scroll 무시
    map(([, v2]) => v2.value)
  );
}
