import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { createBukURL } from '@bukio/viewer';

import { roundTo } from 'shared/utils';
import { AlertService } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';
import { BookService } from '../../services/book.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

import { PageSliderBaseComponent } from '../page-slider-base/page-slider-base.component';
import { PreviewEndDialogComponent } from '../../dialogs/preview-end-dialog/preview-end-dialog.component';

@Component({
  selector: 'viewer-pdf-page-slider',
  templateUrl: './pdf-page-slider.component.html',
  styleUrls: [
    '../page-slider-base/page-slider-base.component.scss',
    './pdf-page-slider.component.scss',
  ],
})
export class PDFPageSliderComponent
  extends PageSliderBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('barContainer')
  protected override _barContainerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('pageInput')
  private _pageInputElement!: ElementRef<HTMLInputElement>;

  public _isDragging = false;
  public _previewPage = 1;
  public _previewThumbImageURL = '';
  public _isPreviewPageLocked = false;

  public _totalPageCount = 1;
  public _currentPage = 1;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private _eventBusService: EventBusService,
    private _bookService: BookService,
    private _alertService: AlertService
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this._bookService.book$.subscribe((book) => {
      this._totalPageCount = book?.items.length ?? 1;
      this._currentPage = 1;
      this._resetPercentToCurrentPage();
    });

    this._eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe(({ address }) => {
        this._currentPage =
          (this._bookService.book?.getIndexOfItem(
            address.iid ?? this._bookService.book.items[0].iid
          ) ?? 1) + 1;
        this._resetPercentToCurrentPage();
      });
  }

  ngAfterViewInit(): void {
    this._setEventListeners();
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onPageInputBlur(newPage: string): void {
    let _newPage = parseInt(newPage);

    if (isNaN(_newPage)) {
      _newPage = this._currentPage;
    } else if (_newPage < 1) {
      _newPage = 1;
    } else if (_newPage > this._totalPageCount) {
      _newPage = this._totalPageCount;
    }

    this._pageInputElement.nativeElement.value = _newPage.toString();

    const isPageChanged = this._requestPageChange(
      _newPage,
      CrossBrowsing.keyboardChangesViewport ? 500 : 0
    );

    if (!isPageChanged) {
      this._pageInputElement.nativeElement.value = this._currentPage.toString();
    }
  }

  protected override _onDragStart(): void {
    this._isDragging = true;
  }

  protected override _onDrag(newPercent: number): void {
    super._onDrag(newPercent);

    if (!this._bookService.book) {
      return;
    }

    this._previewPage = this._calculatePageFromPercent(newPercent);
    const newItem = this._bookService.book.items[this._previewPage - 1];

    this._previewThumbImageURL = newItem.thumbnail!;
    this._isPreviewPageLocked = !this._bookService.book.canReadItem(
      newItem.iid,
      true
    );
  }

  protected override _onDragEnd(newPercent: number): void {
    this._isDragging = false;
    const newPage = this._calculatePageFromPercent(newPercent);
    if (!this._requestPageChange(newPage)) {
      this._resetPercentToCurrentPage();
    }
  }

  private _resetPercentToCurrentPage(): void {
    if (this._currentPage == null || this._totalPageCount == null) {
      return;
    }

    const pageFromPercent = this._calculatePageFromPercent(this._value);

    if (pageFromPercent !== this._currentPage) {
      this._value = this._calculatePercentFromPage(this._currentPage);
    }
  }

  private _calculatePageFromPercent(percent: number): number {
    let page = Math.round(percent * this._totalPageCount!);

    if (page < 1) {
      page = 1;
    } else if (page > this._totalPageCount!) {
      page = this._totalPageCount!;
    }

    return page;
  }

  private _calculatePercentFromPage(page: number): number {
    return roundTo(4, page / this._totalPageCount!);
  }

  /**
   * 사용자(PDFPageSlider 또는 input)로부터 발생한 페이지 변경 요청 처리
   * @param newPage 이동할 페이지
   * @param delay 페이지 이동 전 대기 시간
   * @return 요청한 페이지로 이동 가능한지
   */
  private _requestPageChange(newPage: number, delay: number = 0): boolean {
    const newIid = this._bookService.book!.items[newPage - 1]?.iid;

    // 아이템 존재하지 않음
    if (!newIid) {
      return false;
    }

    // 권한 없음
    if (!this._bookService.book!.canReadItem(newIid, true)) {
      this._alertService.open(PreviewEndDialogComponent, {
        data: {
          book: this._bookService.book!,
        },
      });

      return false;
    }

    window.setTimeout(() => {
      this._eventBusService.fire('PDFPageSliderComponent:pageChange', {
        url: createBukURL(this._bookService.book!.meta.bid, newIid),
      });
    }, delay);

    return true;
  }
}
