import { Injectable } from '@angular/core';

function getOSByUserAgent(): 'android' | 'ios' {
  return (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    !(window as any).MSStream
    ? 'ios'
    : 'android';
}

@Injectable({
  providedIn: 'root',
})
export class NativeEnvironmentService {
  public readonly version?: string;
  public readonly os: 'android' | 'ios' = 'ios';

  constructor() {
    const matched = /BukHybridApp\/([^\s]+)(?: \((iOS|Android)\))?/.exec(
      navigator.userAgent
    );

    this.version = matched?.[1];

    if (matched?.[2]) {
      this.os = matched[2] === 'iOS' ? 'ios' : 'android';
    } else {
      this.os = getOSByUserAgent();
    }
  }
}
