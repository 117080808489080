import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

export interface AnnotationView2 {
  selected: boolean;
  updatedDateTime: number;
  position: number;
}

export enum AnnotationSortType {
  Date = '최신순',
  Position = '목차순',
}

function sortByDate(annot1: AnnotationView2, annot2: AnnotationView2): number {
  return annot2.updatedDateTime - annot1.updatedDateTime;
}

function sortByPosition(
  annot1: AnnotationView2,
  annot2: AnnotationView2
): number {
  return annot1.position - annot2.position;
}

@Component({
  template: '',
})
export abstract class AnnotationListComponent<T> implements OnInit {
  @Output() itemClicked = new EventEmitter<string>();

  public _menuPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  public _sortTypes = [AnnotationSortType.Date, AnnotationSortType.Position];
  public _selectedSortType = AnnotationSortType.Date;

  public _items: (AnnotationView2 & T)[] = [];
  public _selectedItemCount = 0;

  ngOnInit(): void {
    this.loadItems();
  }

  public abstract loadItems(): void;

  public _onItemSelectionChange(): void {
    this._selectedItemCount = this._getSelectedItems().length;
  }

  public _getSelectedItems(): (AnnotationView2 & T)[] {
    return this._items.filter((item) => item.selected);
  }

  public _sortItems(): void {
    let compareFn;

    switch (this._selectedSortType) {
      case AnnotationSortType.Date:
        compareFn = sortByDate;
        break;
      case AnnotationSortType.Position:
        compareFn = sortByPosition;
        break;
    }

    this._items = this._items.sort(compareFn);
    this._deselectAll();
  }

  public _onSortTypeChange(): void {
    this._sortItems();
  }

  protected _deselectAll(): void {
    this._items.forEach((item) => (item.selected = false));
    this._onItemSelectionChange();
  }

  protected _selectAll(): void {
    this._items.forEach((item) => (item.selected = true));
    this._onItemSelectionChange();
  }

  public _onSelectAllButtonClick(): void {
    if (this._selectedItemCount === this._items.length) {
      this._deselectAll();
    } else {
      this._selectAll();
    }
  }
}
