import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import OutlineIconMap from './icon-map-outline';
import SolidIconMap from './icon-map-solid';

export type IconType = 'solid' | 'outline';

@Component({
  selector: 'sui-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnChanges {
  @HostBinding('innerHTML') innerHTML?: SafeHtml;

  @Input() type: IconType = 'outline';
  @Input() name!: string;

  constructor(private _domSanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    this._updateIcon();
  }

  private _updateIcon(): void {
    const svg = this._getIconMap()[this.name];

    if (!svg) {
      console.warn('invalid icon name', this.name);
      return;
    }

    this.innerHTML = this._domSanitizer.bypassSecurityTrustHtml(svg);
  }

  private _getIconMap(): any {
    switch (this.type) {
      case 'outline':
        return OutlineIconMap;
      case 'solid':
        return SolidIconMap;
    }
  }
}
