import { Component } from '@angular/core';
import { TypedDialog } from 'shared/ui';
import { NativeEnvironmentService } from '../../services/native-environment.service';

@Component({
  selector: 'app-update-notification-dialog',
  templateUrl: './update-notification-dialog.component.html',
  styleUrl: './update-notification-dialog.component.scss',
})
export class UpdateNotificationDialogComponent extends TypedDialog<void, void> {
  constructor(private _nativeEnvironmentService: NativeEnvironmentService) {
    super();
  }
  public _onUpdateButtonClick(): void {
    window.open(
      this._nativeEnvironmentService.os === 'android'
        ? 'https://play.google.com/store/apps/details?id=io.buk.hybridapp&pcampaignid=web_share'
        : 'https://apps.apple.com/kr/app/%EB%B6%81%EC%9D%B4%EC%98%A4-bukio/id6504741320'
    );
  }
}
