import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { APIError, MemosAPIService } from 'shared/services';
import { ToastService, TypedDialog } from 'shared/ui';

import { EventBusService } from '../../services/event-bus.service';

@Component({
  selector: 'viewer-memo-report-dialog',
  templateUrl: './memo-report-dialog.component.html',
  styleUrl: './memo-report-dialog.component.scss',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'mobile-full-height' },
})
export class MemoReportDialogComponent extends TypedDialog<
  { authorName: string; authorAvatarImageURL?: string; memoId: string },
  { reported: boolean; blocked: boolean }
> {
  public readonly _reasons = [
    '홍보성 / 영리 목적',
    '욕설 / 인신공격',
    '같은 내용 반복 게시',
    '음란성 / 선정성',
    '개인정보 노출',
    '기타',
  ];

  public _reportFormGroup: FormGroup;

  constructor(
    private _eventBusService: EventBusService,
    private _formBuilder: FormBuilder,
    private _memosAPIService: MemosAPIService,
    private _toastService: ToastService
  ) {
    super();

    this._reportFormGroup = this._formBuilder.group({
      reason: [null, Validators.required],
      details: '',
      block: false,
    });
  }

  public _onCloseButtonClick(): void {
    this._dialogRef.close({ reported: false, blocked: false });
  }

  public _onSubmit(): void {
    const body = this._reportFormGroup.value;

    this._memosAPIService.report(this._data.memoId, body).subscribe({
      next: () => {
        this._toastService.open(
          '신고가 완료되었습니다. 콘텐츠가 커뮤니티 가이드를 위반한 것으로 확인되면 삭제됩니다.'
        );
        this._eventBusService.fire('MemoReportDialogComponent:userBlocked');
        this._dialogRef.close({ reported: true, blocked: body.block });
      },
      error: (error: APIError) => {
        this._toastService.openWarning(error.message);
      },
    });
  }
}
