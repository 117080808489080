import { Component, Inject } from '@angular/core';
import { ToastRef } from './toast-ref';
import { SUI_TOAST_DATA } from './toast-config';
import { ToastType } from './toast.service';

@Component({
  selector: 'sui-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
})
export class ToastComponent {
  constructor(
    private _toastRef: ToastRef<ToastComponent>,
    @Inject(SUI_TOAST_DATA)
    public data: { message: string; actions?: string[]; type: ToastType }
  ) {}

  _onCloseButtonClick(): void {
    this._toastRef.dismiss();
  }

  _onActionButtonClick(action: string): void {
    this._toastRef.dismissWithAction(action);
  }
}
