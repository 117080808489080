<div class="memo">
  <div class="input">
    <textarea
      #textarea
      placeholder="쪽지를 작성해보세요."
      [formControl]="_noteFormControl"
      (keyup)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
    ></textarea>
  </div>
  <div class="input-footer">
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 30V0H30V0.000371774C13.5729 0.0815255 0.251217 13.4386 0 30Z"
        fill="#F5F5F7"
      />
    </svg>
    <div class="hint">
      <span
        class="length"
        [class.is-warning]="_noteFormControl.hasError('maxlength')"
        >{{ _noteFormControl.value.length || 0 }}</span
      >/2000
    </div>
  </div>
</div>
<div class="buttons">
  <button
    class="desktop"
    sui-solid-button
    varSize="medium40"
    (click)="_onSendButtonClick()"
    [disabled]="
      _noteFormControl.invalid ||
      _noteFormControl.pristine ||
      _hasEmptyValue ||
      _isSneding
    "
  >
    보내기
  </button>
  <button
    class="mobile circle-save-button"
    (click)="_onSendButtonClick()"
    [disabled]="
      _noteFormControl.invalid ||
      _noteFormControl.pristine ||
      _hasEmptyValue ||
      _isSneding
    "
  >
    <sui-icon name="앱_보내기" type="solid"></sui-icon>
  </button>
</div>
