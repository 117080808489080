<form [formGroup]="_groupFormGroup" (ngSubmit)="_onSubmit()">
  <lib-reading-group-form-field label="그룹명" [required]="true">
    <input formControlName="name" placeholder="그룹명을 입력해 주세요." />
    <lib-reading-group-form-field-hint
      [isValid]="_groupFormGroup.get('name')!.valid"
      [isError]="
        _groupFormGroup.get('name')!.touched &&
        _groupFormGroup.get('name')!.invalid
      "
      >5 - 30자 이내</lib-reading-group-form-field-hint
    >
  </lib-reading-group-form-field>

  <lib-reading-group-form-field label="그룹 유형" [required]="true">
    <div class="radio-group">
      <sui-radio-button>
        공개 그룹
        <input
          type="radio"
          formControlName="type"
          name="type"
          [value]="'public'"
        />
      </sui-radio-button>
      <sui-radio-button>
        비공개 그룹
        <input
          type="radio"
          formControlName="type"
          name="type"
          [value]="'private'"
        />
      </sui-radio-button>
    </div>
  </lib-reading-group-form-field>

  <lib-reading-group-form-field
    *ngIf="_isPrivateGroup"
    label="그룹 비밀번호"
    [required]="true"
  >
    <input
      maxlength="4"
      formControlName="passcode"
      placeholder="그룹 비밀번호를 입력해주세요."
    />
    <lib-reading-group-form-field-hint
      [isValid]="_groupFormGroup.get('passcode')!.valid"
      [isError]="
        _groupFormGroup.get('passcode')!.touched &&
        _groupFormGroup.get('passcode')!.invalid
      "
      >숫자 4 자리</lib-reading-group-form-field-hint
    >
  </lib-reading-group-form-field>

  <lib-reading-group-form-field label="최대 그룹원 수" [required]="true">
    <input
      type="number"
      formControlName="max_member_count"
      placeholder="최대 {{
        _isPrivateGroup ? '300' : '100'
      }}명까지 설정할 수 있습니다."
    />
    <lib-reading-group-form-field-hint
      [isValid]="_groupFormGroup.get('max_member_count')!.valid"
      [isError]="
        _groupFormGroup.get('max_member_count')!.touched &&
        _groupFormGroup.get('max_member_count')!.invalid
      "
    >
      최소 2명 - 최대 {{ _isPrivateGroup ? '300' : '100' }}명 사이
    </lib-reading-group-form-field-hint>
  </lib-reading-group-form-field>

  <lib-reading-group-form-field label="그룹 소개" [required]="true">
    <textarea
      formControlName="description"
      placeholder="그룹 소개를 입력해 주세요."
    ></textarea>
    <lib-reading-group-form-field-hint
      [isValid]="_groupFormGroup.get('description')!.valid"
      [isError]="
        _groupFormGroup.get('description')!.touched &&
        _groupFormGroup.get('description')!.invalid
      "
      >5 - 200자 이내</lib-reading-group-form-field-hint
    >
    <lib-reading-group-form-field-hint
      ><span [class.is-warning]="_descriptionLength >= 200">{{
        _descriptionLength
      }}</span
      >/200</lib-reading-group-form-field-hint
    >
  </lib-reading-group-form-field>
  <div class="buttons desktop">
    <button
      type="submit"
      varSize="medium40"
      sui-solid-button
      [disabled]="_groupFormGroup.invalid || !isChanged || _isSubmitting"
    >
      {{ initialValue ? '저장하기' : '그룹 생성하기' }}
    </button>
  </div>
  <div class="mobile-buttons">
    <button
      type="submit"
      varSize="large"
      sui-solid-button
      [disabled]="_groupFormGroup.invalid || !isChanged || _isSubmitting"
    >
      {{ initialValue ? '저장하기' : '그룹 생성하기' }}
    </button>
  </div>
</form>
