<div class="header">
  <div>
    총 <span class="number">{{ _totalMemoCount }}</span
    >개의 전체 메모
  </div>
  <sui-checkbox type="boxFirst" *ngIf="_canShowMine">
    내 메모만 보기
    <input type="checkbox" [(ngModel)]="_showMine" />
  </sui-checkbox>
</div>
<div class="body" [class.show-mine]="_showMine">
  <ng-container *ngFor="let iid of _pageIids; index as itemIndex">
    <div
      class="group {{ group.type }}"
      #group
      [class.is-active]="
        groupIndex === _activeGroupIndex && iid === _currentIid
      "
      *ngFor="let group of _memoGroups?.[itemIndex]; index as groupIndex"
      (click)="_onGroupClick(itemIndex, groupIndex)"
    >
      <div class="text">{{ group.text }}</div>
      <ng-container *ngIf="_isCommentaryEditorMode">
        <viewer-commentary-editor-memo
          class="mine memo-comp"
          *ngFor="let memo of group.memos; index as memoIndex"
          [memo]="memo"
          [text]="group.text"
          (memoDeleted)="_onMemoDeleted(itemIndex, groupIndex, memoIndex)"
        ></viewer-commentary-editor-memo>
      </ng-container>
      <ng-container *ngIf="!_isCommentaryEditorMode">
        <viewer-reading-mode-memo
          class="{{ memo.is_mine ? 'mine' : 'others' }} memo-comp"
          *ngFor="let memo of group.memos; index as memoIndex"
          [memo]="memo"
          [text]="group.text"
          (memoDeleted)="_onMemoDeleted(itemIndex, groupIndex, memoIndex)"
          (memoBlinded)="_onMemoBlinded(memo)"
          (memoUserBlocked)="_onMemoUserBlocked(memo)"
          (memoSeeDetailsButtonClicked)="_onMemoSeeDeatilsButtonClicked()"
          [isAuthorOnline]="_onlineUserMap[memo.author.id]"
          [bid]="_bid!"
        ></viewer-reading-mode-memo>
      </ng-container>

      <div class="divider"></div>
    </div>
  </ng-container>
</div>
