import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, map, switchMap } from 'rxjs';
import { OwnLibraryList } from 'shared/models';
import { BookshelfAPIService } from 'shared/services';

@Component({
  selector: 'lib-my-bookshelf-all-libraries-page',
  templateUrl: './my-bookshelf-all-libraries-page.component.html',
  styleUrl: './my-bookshelf-all-libraries-page.component.scss',
})
export class MyBookshelfAllLibrariesPageComponent implements OnInit, OnDestroy {
  private _myOwnLibrarySubject = new Subject<{ page: number }>();

  public _libraryList?: OwnLibraryList;

  public _currentPage = 1;
  public readonly _itemCountPerPage = 30;
  public _totalItemCount?: number;
  public _isLoading = true;
  public _isApp: boolean = false;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookshelfAPIService: BookshelfAPIService
  ) {}

  ngOnInit(): void {
    this._isApp = this._activatedRoute.snapshot.data['isApp'];

    this._myOwnLibrarySubject
      .pipe(
        switchMap(({ page }) => {
          this._isLoading = true;
          return this._bookshelfAPIService.getMyOwnLibraryList(
            page * this._itemCountPerPage,
            this._itemCountPerPage
          );
        })
      )
      .subscribe((result) => {
        this._isLoading = false;
        window.scrollTo(0, 0);

        this._totalItemCount = result.total;
        this._libraryList = result;
      });

    this._activatedRoute.queryParamMap
      .pipe(
        map((map) => {
          return map.get('p') ?? '1';
        })
      )
      .subscribe((p) => {
        let page = parseInt(p);

        if (isNaN(page)) {
          page = 1;
        }

        this._currentPage = page - 1;
        this._myOwnLibrarySubject.next({ page: this._currentPage });
      });
  }

  ngOnDestroy(): void {
    this._myOwnLibrarySubject.complete();
  }

  public _onClickLibrary(libraryId: string, customDomains: string[]): void {
    if (customDomains.length > 0 && !this._isApp) {
      window.location.href = `https://${customDomains[0]}`;
    } else {
      this._router.navigate(['/@' + libraryId]);
    }
  }

  public _onPageChange(pageIndex: number): void {
    this._router.navigate([], {
      queryParams: { p: pageIndex + 1 },
      queryParamsHandling: 'merge',
    });
  }
}
