import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs';

import { Address } from '@bukio/viewer';

import { roundTo } from 'shared/utils';

import { EventBusService } from '../../services/event-bus.service';
import { BookService } from '../../services/book.service';

import { CrossBrowsing } from '../../constants/cross-browsing';

import { calculatePPageFromPage } from '../../utils/book';

import { PageSliderBaseComponent } from '../page-slider-base/page-slider-base.component';

@Component({
  selector: 'viewer-document-page-slider',
  templateUrl: './document-page-slider.component.html',
  styleUrls: [
    '../page-slider-base/page-slider-base.component.scss',
    './document-page-slider.component.scss',
  ],
})
export class DocumentPageSliderComponent
  extends PageSliderBaseComponent
  implements OnDestroy, AfterViewInit, OnInit
{
  @ViewChild('barContainer')
  protected override _barContainerElement!: ElementRef<HTMLDivElement>;
  @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;

  private _address?: Address;
  private _totalPageCount = 0;
  private _currentPage = 0;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private _eventBusService: EventBusService,
    private _bookService: BookService
  ) {
    super(changeDetectorRef);
  }

  ngOnInit(): void {
    this._eventBusService
      .on('ContentsComponent:pageInfoChange')
      .pipe(takeUntil(this._unsubscriber))
      .subscribe((data) => {
        this._address = data.address;
        this._totalPageCount = data.pageCount;
        this._currentPage = data.page;
        this._value = roundTo(4, this._currentPage / this._totalPageCount);
      });
  }

  ngAfterViewInit(): void {
    this._setEventListeners();
  }

  ngOnDestroy(): void {
    this._unsubscriber.next();
    this._unsubscriber.complete();
  }

  _onPageInputBlur(value: string): void {
    if (!this._bookService.book || !this._address) {
      return;
    }

    let _value = parseInt(value);

    if (isNaN(_value)) {
      _value = this._value;
    } else if (_value < 0) {
      _value = 0;
    } else if (_value > 100) {
      _value = 100;
    }

    const pPage = this._getPPageFromPercent(_value / 100);
    const address = this._address.clone();
    address.anchor = pPage;

    this._inputElement.nativeElement.value = _value.toString();

    this._requestPageChange(
      address,
      CrossBrowsing.keyboardChangesViewport ? 500 : 0
    );
  }

  protected override _onDragStart(): void {
    // nothing to do
  }

  protected override _onDragEnd(newPercent: number): void {
    if (!this._bookService.book || !this._address) {
      return;
    }

    const pPage = this._getPPageFromPercent(newPercent);
    const address = this._address?.clone();
    address.anchor = pPage;

    this._requestPageChange(address);
  }

  private _requestPageChange(address: Address, delay: number = 0): void {
    window.setTimeout(() => {
      this._eventBusService.fire('DocumentPageSliderComponent:pageChange', {
        url: address.toString(),
      });
    }, delay);
  }

  private _getPPageFromPercent(percent: number): number {
    const newPage = Math.max(1, Math.round(this._totalPageCount * percent));
    return calculatePPageFromPage(newPage, this._totalPageCount);
  }
}
