import { ComponentRef, Injectable, TemplateRef, Type } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { AlertContainerComponent } from './alert-container.component';
import { DialogConfig } from '@angular/cdk/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { DialogConfig as SuiDialogConfig } from '../dialog/dialog-config';
import { DialogRef as SuiDialogRef } from '../dialog/dialog-ref';
import { SimpleAlertComponent } from './simple-alert.component';
import { TypedDialog } from '../dialog/typed-dialog';

// Counter for unique dialog ids.
let uniqueId = 0;

@Injectable({
  providedIn: 'root',
})
export class AlertService extends DialogService {
  protected override readonly _dialogContainerType: Type<any> =
    AlertContainerComponent;

  openConfirm(
    title?: string,
    message?: string,
    buttonLabel?: string
  ): SuiDialogRef<
    TypedDialog<
      {
        title?: string;
        message?: string;
        okButtonLabel?: string;
      },
      boolean
    >,
    boolean
  > {
    return this.open(SimpleAlertComponent, {
      data: {
        title,
        message,
        okButtonLabel: buttonLabel,
      },
    });
  }

  override _open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: SuiDialogConfig<D>
  ): SuiDialogRef<T, R> {
    let dialogRef: SuiDialogRef<T, R>;
    config = {
      ...new SuiDialogConfig(),
      ...config,
    };
    config!.id = `sui-alert-${uniqueId++}`;

    const cdkRef = this._dialog.open<R, D, T>(componentOrTemplateRef, {
      ...config,
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      // Disable closing since we need to sync it up to the animation ourselves.
      disableClose: true,
      // Disable closing on destroy, because this service cleans up its open dialogs as well.
      // We want to do the cleanup here, rather than the CDK service, because the CDK destroys
      // the dialogs immediately whereas we want it to wait for the animations to finish.
      closeOnDestroy: false,
      // Disable closing on detachments so that we can sync up the animation.
      // The Material dialog ref handles this manually.
      closeOnOverlayDetachments: false,
      container: {
        type: this._dialogContainerType,
        providers: () => [
          // Provide our config as the CDK config as well since it has the same interface as the
          // CDK one, but it contains the actual values passed in by the user for things like
          // `disableClose` which we disable for the CDK dialog since we handle it ourselves.
          { provide: this.dialogConfigClass, useValue: config },
          { provide: DialogConfig, useValue: config },
        ],
      },
      templateContext: () => ({ dialogRef }),
      providers: (ref, cdkConfig, dialogContainer) => {
        dialogRef = new this._dialogRefConstructor(
          ref,
          config,
          dialogContainer
        );
        return [
          { provide: this._dialogContainerType, useValue: dialogContainer },
          { provide: this._dialogDataToken, useValue: cdkConfig.data },
          { provide: this._dialogRefConstructor, useValue: dialogRef },
        ];
      },
      backdropClass: 'sui-alert-backdrop',
    });

    // This can't be assigned in the `providers` callback, because
    // the instance hasn't been assigned to the CDK ref yet.
    (dialogRef! as { componentRef: ComponentRef<T> }).componentRef =
      cdkRef.componentRef!;
    dialogRef!.componentInstance = cdkRef.componentInstance!;

    this.openDialogs.push(dialogRef!);
    this.afterOpened.next(dialogRef!);

    dialogRef!.afterClosed().subscribe(() => {
      const index = this.openDialogs.indexOf(dialogRef);

      if (index > -1) {
        this.openDialogs.splice(index, 1);

        if (!this.openDialogs.length) {
          this._getAfterAllClosed().next();
        }
      }
    });

    return dialogRef!;
  }
}
