import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Commentary } from '../../models/book.model';

@Component({
  selector: 'viewer-commentary-select',
  templateUrl: './commentary-select.component.html',
  styleUrl: './commentary-select.component.scss',
})
export class CommentarySelectComponent implements OnInit {
  @Input() commentaries!: Commentary[];

  public _isOptionsOpened = false;

  public _selectedCommentaryAuthor?: string;
  private _selectedCommentaryId?: number;

  @Output() selectedCommentaryIdChange = new EventEmitter<number | undefined>();

  @Input() set selectedCommentaryId(value: number | undefined) {
    this._selectedCommentaryId = value;
    this._selectedCommentaryAuthor = this.commentaries.find(
      (c) => c.id === value
    )?.author;

    if (this._selectedCommentaryId == null) {
      this._isOptionsOpened = false;
    }
  }

  get selectedCommentaryId(): number | undefined {
    return this._selectedCommentaryId;
  }

  ngOnInit(): void {
    this._selectedCommentaryAuthor = this.commentaries.find(
      (c) => c.id === this._selectedCommentaryId
    )?.author;
  }

  _onOptionClick(id: number): void {
    this._isOptionsOpened = false;

    if (this.selectedCommentaryId === id) {
      return;
    }

    this.selectedCommentaryId = id;
    this._selectedCommentaryAuthor = this.commentaries.find(
      (c) => c.id === id
    )?.author;
    this.selectedCommentaryIdChange.emit(id);
  }
}
