import { inject } from '@angular/core';
import { DialogRef } from './dialog-ref';
import { SUI_DIALOG_DATA } from './dialog.service';

export abstract class TypedDialog<DialogData, DialogResult> {
  protected _data: DialogData = inject(SUI_DIALOG_DATA);
  protected _dialogRef: DialogRef<
    TypedDialog<DialogData, DialogResult>,
    DialogResult
  > = inject(DialogRef);
}
