/* eslint-disable @angular-eslint/component-selector */
import { Component } from '@angular/core';
import {
  ButtonBaseComponent,
  SUI_BUTTON_PREFIX,
} from '../button-base/box-base.component';

@Component({
  selector: `button[sui-sub-button]`,
  templateUrl: './sub-box-button.component.html',
  styleUrls: ['./sub-box-button.component.scss'],
  host: {
    class: SUI_BUTTON_PREFIX,
  },
})
export class SubBoxButtonComponent extends ButtonBaseComponent {}
